import React, {useState} from "react";
import {
    ConnectedFormPage,
    FormSection,
    SelectInput,
    usePreview,
    useMixpanel,
} from "@cuda-react/core";
import apiResources from "../../../apiResources";
import {parseJson} from "../../../utils/jsonUtils";
import AzureLogAnalyticsOMS from "./AzureLogAnalyticsOMS/AzureLogAnalyticsOMS";
import AzureLogAnalyticsDaemon from "./AzureLogAnalyticsDaemon/AzureLogAnalyticsDaemon";
import {useTranslation} from "react-i18next";

export const AzureMonitor = () => {
    const [translate] = useTranslation();
    const registerAction = useMixpanel("Log Analytics");
    const isAzureDaemon = usePreview("azureDaemon");
    const azureMonitorChoices = [
        {key: "disabled", name: translate("tesseract.integration.azureMonitor.azureMonitorChoices.disabled")},
        ...(isAzureDaemon ? [{key: "azureLogAnalyticsDaemon", name: translate("tesseract.integration.azureMonitor.azureMonitorChoices.azureLogAnalyticsDaemon")}] : []),
        {key: "azureLogAnalyticsOms", name: translate("tesseract.integration.azureMonitor.azureMonitorChoices.azureLogAnalyticsOms")},
    ];
    const [selectedAzureMonitorChoice, setAzureMonitorChoice] = useState("disabled");

    return (
        <ConnectedFormPage
            title="tesseract.integration.pageTitle"
            subtitle="tesseract.integration.azureMonitor.title"
            resource={apiResources.settings}
            formatRequestData={(data) => ({
                settings: {
                    oms_workspace: JSON.stringify(data?.azureMonitor === "azureLogAnalyticsOms" ? {...data?.settings?.oms_workspace, enabled: true} : {enabled: false}),
                    azure_log_analytics: JSON.stringify(data?.azureMonitor === "azureLogAnalyticsDaemon" ? {...data?.settings?.azure_log_analytics, enabled: true} : {enabled: false})
                }
            })}
            formatResourceData={(data) => {
                const azureLogAnalyticsDaemonEnabled = parseJson(data?.settings?.azure_log_analytics)?.enabled;
                const azureLogAnalyticsOmsEnabled = parseJson(data?.settings?.oms_workspace)?.enabled;
                const azureLogAnalyticsData = parseJson(data?.settings?.azure_log_analytics || "{\"enabled\":false}");
                const azureMonitor = azureLogAnalyticsDaemonEnabled ? "azureLogAnalyticsDaemon" : azureLogAnalyticsOmsEnabled ? "azureLogAnalyticsOms" : "disabled";
                return {
                    azureMonitor,
                    settings: {
                        oms_workspace: parseJson(data?.settings?.oms_workspace || "{\"enabled\":false,\"id\":\"\",\"key\":\"\"}"),
                        azure_log_analytics: azureLogAnalyticsData.enabled ? azureLogAnalyticsData : {
                            enabled: false,
                            clientId: "",
                            clientSecret: "",
                            tenantId: "",
                            immutableId: "",
                            resourceGroup: "",
                            stream: "",
                            logsUrl: ""
                        }
                    }
                };
            }}
            params={{filter: {type: "virtualWan"}}}
            onSubmitSuccess={(response) => {
                registerAction("Update", {
                    oms_enabled: parseJson(response.settings.oms_workspace).enabled,
                    azure_log_analytics: parseJson(response.settings.azure_log_analytics).enabled,
                });
            }}
            onChange={(data) => setAzureMonitorChoice(data.azureMonitor)}
            clearOnSuccess
            canReset
        >
            <FormSection title="tesseract.integration.azureMonitor.title" newStyle hideBorder>
                <SelectInput
                    source="azureMonitor"
                    label="tesseract.integration.azureMonitor.title"
                    choices={azureMonitorChoices}
                    additionalInfoLabel={selectedAzureMonitorChoice === "azureLogAnalyticsOms" ? "tesseract.settings.tabs.oms.descriptions.oms" : undefined}
                    newStyle
                />
                {isAzureDaemon && selectedAzureMonitorChoice === "azureLogAnalyticsDaemon" ?
                    <>
                        <AzureLogAnalyticsDaemon/>
                    </>
                : null}
                {selectedAzureMonitorChoice === "azureLogAnalyticsOms" ?
                    <AzureLogAnalyticsOMS/> : null
                }
            </FormSection>
        </ConnectedFormPage>
    );
};

export default AzureMonitor;