import React, {useState} from "react";
import apiResources from "../../../../apiResources";
import {
    ConnectedFormPage,
    CustomField,
    FileInput,
    FormSection,
    TableInput,
    TextField,
    TextInput, useGlobalParam,
    useMixpanel
} from "@cuda-react/core";

const UnattendedEnrollment: React.FC = () => {
    const registerAction = useMixpanel("Endpoint Zero Trust Unattended Enrollment");
    const [virtualWanId] = useGlobalParam("filter.virtualWanId");
    const [rightLabel, setRightLabel] = useState(undefined);

    return (
        <ConnectedFormPage
            resource={apiResources.zeroTrustUnattendedEnrollment}
            title="tesseract.endpoint.pageTitle"
            subtitle="tesseract.endpoint.zeroTrustUnattendedEnrollment.title"
            onSubmitSuccess={() => {
                registerAction("Update");
            }}
            formatRequestData={(data) => ({
                ...data,
                virtualWanId,
                certificates: data?.certificates.map((cert: any) => ({
                    ...cert,
                    content: cert?.certificateData?.data
                }))
            })}
            onChange={(data) => setRightLabel(data?.domainSuffix)}
            canReset
        >
            <FormSection title="tesseract.endpoint.zeroTrustUnattendedEnrollment.globalUnattendedEnrollmentSettings" newStyle hideBorder>
                <TextInput
                    source="domain"
                    label="tesseract.endpoint.zeroTrustUnattendedEnrollment.domain"
                    additionalRightLabel={"." + rightLabel}
                    newStyle
                />
                <TableInput
                    source="certificates"
                    labels={{
                        addTitle: "tesseract.endpoint.zeroTrustUnattendedEnrollment.addCertificate",
                        addButtonText: "tesseract.endpoint.zeroTrustUnattendedEnrollment.addCertificate",
                        editTitle: "tesseract.endpoint.zeroTrustUnattendedEnrollment.editCertificate",
                        deleteTitle: "tesseract.endpoint.zeroTrustUnattendedEnrollment.deleteCertificate"
                    }}
                    formChildren={[
                        <FileInput
                            key="certificateData"
                            source="certificateData"
                            label="tesseract.endpoint.zeroTrustUnattendedEnrollment.uploadCertificate"
                            description="tesseract.endpoint.zeroTrustUnattendedEnrollment.descriptions.uploadCertificate"
                            isRequired
                            accept="*"
                            readType="base64"
                        />
                    ]}
                    addButtonIcon
                    label="tesseract.endpoint.zeroTrustUnattendedEnrollment.certificateTitle"
                    smallSize
                    optionValue="key"
                    formatInput={(data) => (data.map((value: any, index: number) => ({
                        ...value,
                        key: value?.uuid || index
                    })))}
                    noEdit
                    newStyle
                >
                    <CustomField
                        source="name"
                        label="tesseract.endpoint.zeroTrustUnattendedEnrollment.certificateName"
                        render={(value, data) => value ? value : data?.certificateData?.filename}
                    />
                    <TextField
                        source="issuer"
                        label="tesseract.endpoint.zeroTrustUnattendedEnrollment.issuer"
                    />
                    <TextField
                        source="expiry"
                        label="tesseract.endpoint.zeroTrustUnattendedEnrollment.expiry"
                    />
                </TableInput>
            </FormSection>
        </ConnectedFormPage>
    );
};

export default UnattendedEnrollment;