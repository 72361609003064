import React from 'react';
import classNames from "classnames";
import {SvgIcon, useTokens} from "@barracuda-internal/bds-core";

export const Large = ({className}: { className?: string })=> {
    const tokens = useTokens();
    return (
        <SvgIcon
            className={classNames(className, "cuda-react-graphic")}
            id="cuda-gridsize-large"
            viewBox="0 0 60 60"
        >
            <path style={{fill: tokens.aliasesColorTextStaticNeutral, strokeWidth: 0}} d="M7.7,7.7v44.5h44.5V7.7H7.7Z"/>
        </SvgIcon>
    );
};