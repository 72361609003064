import React, {useRef} from "react";
import {
    ActionButtonsField,
    Boolean,
    ChipArrayField,
    CrudTypes,
    DeleteDialog,
    getArrayDataContent,
    getDataContent,
    InputLabel,
    PropGateway,
    StatusIconField,
    TableAction,
    TablePage,
    TextField,
    useCrudFetch,
    useCrudProps,
    useCrudSubscription,
    useMixpanel
} from "@cuda-react/core";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import {makeStyles} from "@mui/styles";
import CreateEditButtonDialog from "../../../../../components/CreateEditButtonDialog";
import CreateEditMalwareRule from "./CreateEditMalwareRule";
import {useSourceDestinationField} from "../../../../../hooks/sourceAndDestinationHooks";
import SourceFilter from "../../../../../components/sourceAndDestination/SourceFilter";
import DestinationFilter from "../../../../../components/sourceAndDestination/DestinationFilter";
import {
    formatSourceDestinationFilters,
    formatUserGroupInTableData
} from "../../../../../components/sourceAndDestination/tableUtils";
import {scanIcons} from "../../../../../components/iconMapping";
import apiResources from "../../../../../apiResources";
import DefaultActionSelector from "../../../../../components/security/DefaultActionSelector";

export const styles = (theme: { spacing: (arg0: number) => string; }) => ({
    cardActions: {
        paddingRight: "16px",
        width: "calc(100% - 16px)"
    },
    orderCell: {
        minWidth: 70
    },
    actionCell: {
        minWidth: 70,
        paddingLeft: 0
    },
    defaultActionContainer: {
        display: "inline-flex"
    },
    defaultAction: {
        display: "inline-flex",
        paddingTop: "8px"
    }
});
const useStyles = makeStyles(styles);


export const MalwareProtection = () => {
    const registerAction = useMixpanel("Malware");
    const tableRefreshRef = useRef<any>();
    // @ts-ignore
    const actionChoices = getArrayDataContent(useCrudProps(apiResources.malwareActions, {}, {cache: true})[0]?.data);
    const [enabledData, enabledLoading, refreshEnabled] = useCrudSubscription(CrudTypes.GET, apiResources.malwareEnabled);
    const [, enabledUpdating, updateEnabled] = useCrudFetch(CrudTypes.UPDATE, apiResources.malwareEnabled);
    const enabled = getDataContent(enabledData);
    const loadingEnabled = typeof enabled !== "boolean" || enabledLoading || enabledUpdating;
    const handleUpdateEnabled = (newValue: boolean) => {
        if (!loadingEnabled && newValue !== enabled) {
            registerAction("Default Update", {enabled: newValue});
            return updateEnabled({enabled: newValue ? "enable" : "disable"}).then(refreshEnabled);
        }
    };
    const [, , callMalwareUpdate] = useCrudFetch(CrudTypes.CREATE, apiResources.malware, {});
    const handleOrderClick = (id: any, direction: string, data: any) => callMalwareUpdate({
        id,
        changeOrder: direction,
        data
    }).then(tableRefreshRef.current);
    const generateChipArrayProps = useSourceDestinationField();
    const classes = useStyles();

    return (
        <TablePage
            title="tesseract.securityAndAccess.pageTitle"
            subtitle={["tesseract.securityAndAccess.security", "tesseract.securityAndAccess.malware"]}
            authenticated
            resource={apiResources.malware}
            exportResource={{resource: apiResources.malwareExport, filename: "malware"}}
            actions={[
                <TableAction key="defaultAction" left>
                    <div className={classes.defaultActionContainer}>
                        <InputLabel label="tesseract.security.protection.settings.inputs.enabled" minimised>
                            <Boolean
                                id="enabled"
                                // @ts-ignore
                                onChange={handleUpdateEnabled}
                                options={{disabled: !!loadingEnabled}}
                                value={typeof enabled !== "boolean" ? false : enabled}
                            />
                        </InputLabel>
                        <DefaultActionSelector
                            actionChoicesApi={apiResources.malwareActions}
                            defaultActionApi={apiResources.malwareDefault}
                            registerAction={registerAction}
                        />
                    </div>
                </TableAction>,
                <CreateEditButtonDialog key="create" create component={CreateEditMalwareRule}
                                        onSuccess={tableRefreshRef.current}/>
            ]}
            params={formatSourceDestinationFilters}
            classes={{cardActions: classes.cardActions}}
            minCellWidth={128}
            formatData={formatUserGroupInTableData}
            refreshRef={tableRefreshRef}
            flat
        >
            <ActionButtonsField
                left
                label="tesseract.security.protection.settings.order"
                cellClassName={classes.orderCell}
                width={96}
                source="id"
            >
                <TextField
                    source="order"
                />
                {/**@ts-ignore not sure why TS doesn't like this component*/}
                <ArrowUpwardIcon
                    onClick={(event: any, data: { id: any; }) => handleOrderClick(data.id, "increase", data)}
                    disabled={(data: { index: number; }) => data.index <= 0}
                    id="cuda-icon-up"
                />
                {/**@ts-ignore not sure why TS doesn't like this component*/}
                <ArrowDownwardIcon
                    onClick={(event: any, data: { id: any; }) => handleOrderClick(data.id, "decrease", data)}
                    disabled={(data: { index: number; total: number; }) => data.index >= (data.total - 1)}
                    id="cuda-icon-down"
                />
            </ActionButtonsField>
            <TextField
                source="name"
                label="tesseract.security.protection.settings.name"
                filter="text"
            />
            <TextField
                source="description"
                label="tesseract.security.protection.settings.description"
                filter="text"
            />
            <StatusIconField
                source="action"
                text={(action) => (actionChoices.find((item) => item.key === action) || {}).name}
                label="tesseract.security.protection.settings.action"
                iconMap={scanIcons}
                cellClassName={classes.actionCell}
                filter="select"
                filterProps={{
                    choices: actionChoices
                }}
            />
            <PropGateway
                source="source"
                label="tesseract.security.protection.settings.source"
                editProps={generateChipArrayProps("source")}
                filter="custom"
                filterProps={{
                    component: SourceFilter,
                    filterSources: ["application", "network", "userOrGroup"]
                }}
            >
                <ChipArrayField source="source"/>
            </PropGateway>
            <PropGateway
                source="destination"
                label="tesseract.security.protection.settings.destination"
                editProps={generateChipArrayProps("destination")}
                filter="custom"
                filterProps={{
                    component: DestinationFilter,
                    filterSources: ["application", "network", "domain", "site"],
                    table: "MalwareProtection",
                }}
            >
                <ChipArrayField source="destination"/>
            </PropGateway>
            <ActionButtonsField
                source="id"
                width={96}
            >
                <CreateEditButtonDialog component={CreateEditMalwareRule} onSuccess={tableRefreshRef.current}/>
                <DeleteDialog
                    resource={apiResources.malware}
                    title="tesseract.security.protection.settings.delete.title"
                    message="tesseract.security.protection.settings.delete.body"
                    onSuccess={() => {
                        registerAction("Delete");
                        tableRefreshRef.current?.();
                    }}
                />
            </ActionButtonsField>
        </TablePage>
    );
};

export default MalwareProtection;