import {SvgIcon, useTokens} from "@barracuda-internal/bds-core";
import React from "react";
import classNames from "classnames";

export const PriorityHighIcon = ({className}: { className?: string }) => {
    const tokens = useTokens();
    return (
        <SvgIcon
            className={classNames(className, "cuda-react-icon")}
            id="cuda-icon-priority-high"
            viewBox="0 0 60 60"
        >
            <path style={{fill: tokens.aliasesColorTextStaticNeutral, strokeWidth: 0}}
                  d="M30,55c-13.8,0-25-11.2-25-25S16.2,5,30,5s25,11.2,25,25-11.2,25-25,25ZM30,9.9c-11.1,0-20.1,9-20.1,20.1s9,20.1,20.1,20.1,20.1-9,20.1-20.1-9-20.1-20.1-20.1Z"/>
            <path style={{fill: tokens.aliasesColorIconWarningNeutral, strokeWidth: 0}}
                  d="M28.7,34.6c1.3.4,2.6.3,3.9-.4s1.9-1.5,2.3-2.7c.4-1.2.4-2.4,0-3.6l6-7.1c.5-.5.7-1.2.6-1.9s-.4-1.3-.9-1.7c-.6-.5-1.2-.6-1.9-.6-.7,0-1.3.4-1.7,1l-6,7.1c-.5,0-1.1,0-1.6,0-.5.1-1.1.3-1.5.5-1.2.7-2,1.7-2.4,3-.4,1.3-.3,2.6.4,3.9.7,1.2,1.7,2,3,2.4Z"/>
        </SvgIcon>
    );
};

export default PriorityHighIcon;