import {
    ButtonDialog,
    CrudTypes,
    FormSection,
    InputLabel,
    useCrudSubscription
} from "@cuda-react/core";
import {makeStyles} from "@mui/styles";
import React from 'react';
import {Trans} from "react-i18next";
import {Typography} from "@barracuda-internal/bds-core";
import {Edit} from "@barracuda-internal/bds-core/dist/Icons/Core";
import SecurityUpdateDialogContent from "./SecurityUpdateWindowDialog";
import apiResources from "../../../../apiResources";
import {parseJson} from "../../../../utils/jsonUtils";

const useStyles = makeStyles((theme) => ({
    dateTimeInput: {
        width: 140
    },
    inputLabel: {
        marginTop: 16,
        paddingRight: 8,
        paddingLeft: 16
    },
    inputRoot: {
        width: 250
    },
    value: {
        paddingTop: 6,
        display: "flex",
        width: 600,
        marginTop: 16,
        marginLeft: 32,
        "& > *": {
            display: "flex"
        },
        "& p": {
            paddingTop: 2,
            width: "100%",
            display: "flex"
        }
    },
    midText: {
        display: "inline-block",
        color: "grey",
        marginRight: 10
    },
    message: {
        margin: "16px 32px 0 24px"
    },
    timeInput: {
        paddingTop: 16,
        width: 210
    },
    editButton: {
        paddingTop: "10px"
    },
    timeText: {
        borderStyle: "solid",
        //@ts-ignore TODO: this theme entry does exist. We should extend DefaultTheme to fix this properly (or BDS should do that).
        borderColor: theme.palette.customDivider?.border?.color,
        borderRadius: 5,
        margin: "-3px 30px 5px 0px"
    }
}));

const SecurityUpdates: React.FC = () => {
    const classes = useStyles();
    const [data, , refreshSecurityUpdateWindow] = useCrudSubscription(CrudTypes.GET, apiResources.settings, {filter: {type: "virtualWan"}});
    const {from, to} = parseJson(data?.data?.settings?.security_updates)?.intervals?.[0] || {};


    return (
        <FormSection title="tesseract.settings.tabs.updates.security.title" newStyle>
            <Typography className={classes.message}>
                <Trans i18nKey="tesseract.settings.tabs.updates.security.description"/>
            </Typography>
            <div className={classes.value}>
                <InputLabel
                    label="tesseract.settings.tabs.updates.security.title"
                    classes={{inputLabel: classes.inputLabel, root: classes.inputRoot}}
                    minimised
                    newStyle
                />
                <div className={classes.timeInput}>
                    <Typography variant="body1" style={{float: "right"}}>
                        <Trans
                            i18nKey="tesseract.settings.tabs.updates.security.setting"
                            values={{from: from?.time, to: to?.time}}
                        >
                            <div className={classes.midText}/>
                            <div className={classes.timeText}/>
                        </Trans>
                    </Typography>
                </div>
                <ButtonDialog
                    buttonIcon={<Edit id="security-window-edit-dialog-icon"/>}
                    buttonClasses={{buttonIcon: classes.editButton}}
                >
                    <SecurityUpdateDialogContent
                        onSuccess={refreshSecurityUpdateWindow}
                    />
                </ButtonDialog>
            </div>
        </FormSection>
    );
};


export default SecurityUpdates;