import {SvgIcon, useTokens} from "@barracuda-internal/bds-core";
import React from "react";
import classNames from "classnames";

export const PointOfPresenceGraphic = ({className}: { className?: string }) => {
    const tokens = useTokens();
    return (
        <SvgIcon
            className={classNames(className, "cuda-react-graphic")}
            id="cuda-graphic-pointofpresence"
            viewBox="0 0 96 96"
        >
            <g id="ArtBoard11">
                <g id="Ebene-1">
                    <path style={{fill: tokens.aliasesColorTextStaticNeutral, strokeWidth: 0}}
                          d="M43.9,25.5l3.1,3.1c2.7-2.7,6.2-3.9,9.8-3.9s7,1.2,9.8,3.9l3.1-3.1c-3.5-3.5-8.2-5.5-12.9-5.5s-9.4,2-12.9,5.5"/>
                    <path style={{fill: tokens.aliasesColorTextStaticNeutral, strokeWidth: 0}}
                          d="M56.7,15.8c5.9,0,11.7,2.3,16.4,6.6l3.1-3.1c-5.5-5.5-12.5-8.2-19.5-8.2s-14,2.7-19.5,8.2l3.1,3.1c4.7-4.3,10.5-6.6,16.4-6.6"/>
                    <rect style={{fill: tokens.aliasesColorTextStaticNeutral, strokeWidth: 0}} x="46.8" y="60.8" width="5.7" height="5.7"/>
                    <rect style={{fill: tokens.aliasesColorTextStaticNeutral, strokeWidth: 0}} x="33.1" y="60.8" width="5.7" height="5.7"/>
                    <rect style={{fill: tokens.aliasesColorTextStaticNeutral, strokeWidth: 0}} x="19.5" y="60.8" width="5.7" height="5.7"/>
                    <path style={{fill: tokens.globalWhite, strokeWidth: 0}} d="M74.5,63h-3.4v6.8h-6.8v3.4h6.8v6.8h3.4v-6.8h6.8v-3.4h-6.8v-6.8Z"/>
                    <path style={{fill: tokens.aliasesColorTextStaticNeutral, strokeWidth: 0}}
                          d="M53,72.5H13.8c-.6,0-1.1-.5-1.1-1.1v-15.6c0-.6.5-1.1,1.1-1.1h48.3c3.1-2,6.8-3.2,10.7-3.2s.6,0,.9,0c-1.2-1.5-3.1-2.6-5.2-2.6h-7.8c-.6,0-1.1-.5-1.1-1.1v-14.5h-5.7v14.5c0,.6-.5,1.1-1.1,1.1H13.8c-3.7,0-6.7,3-6.7,6.7v15.6c0,3.7,3,6.7,6.7,6.7h40.4c-.6-1.8-1-3.7-1.1-5.7"/>
                    <path style={{fill: tokens.aliasesColorIconSuccessNeutral, strokeWidth: 0}}
                          d="M72.8,54.5c-9.4,0-17,7.6-17,17s7.6,17,17,17,17-7.6,17-17-7.6-17-17-17ZM81.4,73.2h-6.8v6.8h-3.4v-6.8h-6.8v-3.4h6.8v-6.8h3.4v6.8h6.8v3.4Z"/>
                </g>
            </g>
        </SvgIcon>
    );
};

export default PointOfPresenceGraphic;