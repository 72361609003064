import React from "react";
import {Checkbox, List, ListItem, ListItemText, Typography} from "@barracuda-internal/bds-core";
import {makeStyles} from "@mui/styles";
import {Theme} from "@mui/material";
import {Trans} from "react-i18next";
import {Input, InputProps} from "@cuda-react/core/lib/components/inputs/Input/Input";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: "100%",
        padding: "16px 16px 16px 64px",
        "& li": {
            paddingLeft: 0,
            paddingRight: 0
        }
    },
    categoryLabel: {
        lineHeight: "19px",
        textAlign: "left",
        padding: 0,
        width: 202,
        fontWeight: "bold"
    },
    checkedStatus: {
        width: "16px"
    },
    checkedStatusText: {
        fontSize: "12px",
        fontWeight: 300
    }
}));

export interface SimpleBooleanCheckListProps {
    /**
     * if true, input gets disabled
     */
    disabled?: boolean,
    /**
     * callback to call when the input value has been changed.
     * provided automatically when component is rendered inside a [Input](/?path=/docs/core-components-inputs-input) component.
     * @function onChange
     */
    onChange: (event: any[]) => void,
    /**
     * callback to called when component stops being interacted with.
     * provided automatically when component is rendered inside a [Input](/?path=/docs/core-components-inputs-input) component.
     * @function onBlur
     */
    onBlur: (event: any[]) => void,
    /**
     * current value of that input.
     * provided automatically when component is rendered inside a [Input](/?path=/docs/core-components-inputs-input) component.
     */
    value?: any,
    /**
     * array containing key value pairs of categories to be rendered
     */
    categoryChoices: {key: string, name: string}[]
}

export const SimpleBooleanChecklist = ({categoryChoices, disabled, value, onChange, onBlur}: SimpleBooleanCheckListProps) => {
    const classes = useStyles();

    const updateCategory = (itemKey: string, checked: boolean, value: any, onChange: (event: any[]) => void, onBlur: (event: any[]) => void) => {
        let newValue = [...value].filter((item) => item !== itemKey);

        if (checked) {
            newValue.push(itemKey);
        }
        onChange(newValue);
        onBlur(newValue);
    };

    const renderCategories = () => categoryChoices.map((category) => (
        <React.Fragment key={category.key}>
                <ListItem>
                    <ListItemText className={classes.categoryLabel}>
                        <Trans i18nKey={category.name}/>
                    </ListItemText>
                    <Checkbox
                        disabled={disabled}
                        checked={value?.includes(category.key)}
                        onChange={(event) => updateCategory(category.key, event.target.checked, value, onChange, onBlur)}
                        color="primary"
                        id={"boolean-input-"+category.key}
                    />
                    {<div className={classes.checkedStatus}>{value?.includes(category.key) ? <Typography className={classes.checkedStatusText}><Trans i18nKey="tesseract.webMonitorPolicies.monitored"/></Typography> : null}</div>}
                </ListItem>
            </React.Fragment>
        )
    );

    return (
        <List className={classes.root} id="simple-boolean-checklist">
            {renderCategories()}
        </List>
    );
};
interface SimpleBooleanCheckListInputProps extends Omit<InputProps<typeof SimpleBooleanChecklist>, "component" | "noLabel"> {
}

const SimpleBooleanCheckListInput = (props: SimpleBooleanCheckListInputProps) => <Input component={SimpleBooleanChecklist} noLabel {...props}/>;

export default SimpleBooleanCheckListInput;