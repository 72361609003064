import React, {useState} from "react";
import {BasicPage, Tab,} from "@cuda-react/core";
import {CardsRecord,} from "../../../typesAndConstants";
import {BasicPageProps} from "@cuda-react/core/lib/components/pages/BasicPage/BasicPage";
import useDashboards, {ActiveDashboardCard, Dashboard} from "../../../hooks/useDashboards";
import DashboardMenu from "../DashboardMenu";
import DashboardContent, {DashboardContentProps} from "../DashboardContent";
import {useTranslation} from "react-i18next";
import {Skeleton} from "@barracuda-internal/bds-core";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles({
    loading: {
        height: "75vh",
        transform: "none",
        width: "100%",
        borderRadius: "8px !important"
    }
});

export interface DashboardPageProps extends Pick<BasicPageProps, "title" | "subtitle"> {
    /** Cards available for use within the dashboard */
    availableCards: CardsRecord,
    /** if true, displays provided filters at top of dashboard */
    filters?: DashboardContentProps["filters"]
    /** Unique ID, used for storing dashboard configuration state */
    id: string,
    /** the currently configured dashboards. If not provided, dashboards will be saved to localStorage instead. */
    remoteStoredDashboards?: Dashboard[],
    /** callback called when dashboards are edited. Used to update the configured dashboards if providing remoteStoredDashboards */
    onDashboardsChange?: (dashboards: Dashboard[]) => Promise<any>,
    /** default dashboard/s to display on first visit. They are also used as "templates" when creating new dashboards,
     *  if no other templates are provided */
    defaultDashboards: Dashboard[]
    /** Dashboard "templates" to use when creating new dashboards. */
    templates?: Dashboard[]
    /** optional object that can be used to send data to a card */
    additionalProps?: {[index: string]: any}
    /**
     * boolean, if true it is a one tab dashboard. This means that there are not tabs, the Add Dashboard button is not enable,
     * the dashboard can not be deleted or renamed, but just reset to default. Reset the dashboard dialog is a confirm dialog.
     */
    oneTabDashboard?: boolean
    /**
     * if using remoteStoredDashboards, use this prop to signify you are doing an initial load, so the default dashboards should not
     * be displayed (instead a loading animation is displayed).
     */
    loadingRemoteStoredDashboards?: boolean,
    /**
     * Maximum number of custom dashboard that can be added
     */
    maxDashboardsAmount?: number
}

export const DashboardPage = (props: DashboardPageProps) => {
    const {
        availableCards,
        title,
        remoteStoredDashboards,
        onDashboardsChange,
        defaultDashboards,
        filters,
        id,
        templates,
        additionalProps,
        oneTabDashboard,
        loadingRemoteStoredDashboards,
        maxDashboardsAmount
    } = props;
    const {
        activeDashboard = {name: "", cards: [], template: ""},
        addUpdateDashboard,
        setActiveDashboard,
        dashboards = [],
        deleteDashboard
    } = useDashboards(id, defaultDashboards, remoteStoredDashboards, onDashboardsChange);
    const [editMode, setEditMode] = useState(false);
    const [translate] = useTranslation();
    const activeCards = Array.isArray(activeDashboard?.cards) ?
        activeDashboard?.cards?.filter((card) => card.componentId in availableCards) : [];
    const [cardEditBuffer, setCardEditBuffer] = useState<Array<ActiveDashboardCard>>(activeCards || []);
    const handleDashboardUpdate = (newDashboardSettings: Dashboard, originalDashboardName?: string, reset?: boolean) => {
        if (newDashboardSettings.name !== originalDashboardName && dashboards.find(({name}) => name.toLowerCase() === newDashboardSettings.name.toLowerCase())) {
            return Promise.reject({name: translate("stratosReporting.dashboardPage.uniqueNameError")});
        }
        if (reset) {
            setCardEditBuffer([]);
        }
        if (originalDashboardName && !reset) {
            addUpdateDashboard(newDashboardSettings, originalDashboardName);
        } else {
            const matchingTemplate = (templates || defaultDashboards).find(({name}) => newDashboardSettings.template === name);
            addUpdateDashboard({
                ...newDashboardSettings,
                cards: matchingTemplate?.cards || [],
            }, originalDashboardName);
        }
        return Promise.resolve({});
    };
    const classes = useStyles(props);
    const maxDashboardsAmountReached = !!maxDashboardsAmount && dashboards?.length >= maxDashboardsAmount;

    // Ignoring the error because maxDashboardsAmount is a number but it pretends to have TOptions<TInterpolationMap>
    return !oneTabDashboard ? (
        <BasicPage title={title} subtitle={loadingRemoteStoredDashboards ? "" : activeDashboard.name}>
            {loadingRemoteStoredDashboards ? (
                <Skeleton className={classes.loading} />
            ) : (
                <DashboardMenu
                    onChange={(dashboardName) => {
                        if (!editMode || confirm(translate("stratosReporting.dashboardPage.changeDashboardMessage"))) {
                            setActiveDashboard(dashboardName as string);
                            setEditMode(false);
                        }
                    }}
                    addUpdateDashboard={handleDashboardUpdate}
                    deleteDashboard={(dashboard) => deleteDashboard(dashboard.name)}
                    activeDashboard={activeDashboard}
                    disabled={loadingRemoteStoredDashboards || editMode}
                    templates={templates || defaultDashboards}
                    maxDashboardsReached={maxDashboardsAmountReached}
                    maxDashboardsReachedMessage={maxDashboardsAmountReached ? translate("stratosReporting.dashboardPage.maxNumberOfDashboardReached", {maxDashboardsAmount}) : undefined}
                >
                    {dashboards.map((board) => (
                        <Tab label={board.name} key={board.name} value={board.name}>
                            <DashboardContent
                                key={board.name}
                                editMode={editMode}
                                onChange={(cards) => {
                                    board = {name: board.name, cards};
                                    addUpdateDashboard(board, board.name);
                                }}
                                setEditMode={setEditMode}
                                cardEditBuffer={activeDashboard.name === board.name ? cardEditBuffer : []}
                                setCardEditBuffer={setCardEditBuffer}
                                activeDashboard={board}
                                availableCards={availableCards}
                                filters={filters}
                                additionalProps={additionalProps}
                                disabled={editMode}
                                templates={templates || defaultDashboards}
                                oneTabDashboard={oneTabDashboard}
                                addUpdateDashboard={handleDashboardUpdate}
                            />
                        </Tab>
                    ))}
                </DashboardMenu>
            )}
        </BasicPage>
    ) : (
        <>
            {loadingRemoteStoredDashboards ? (
                <Skeleton className={classes.loading} />
            ) : dashboards.map((board) => (
                <DashboardContent
                    key={board.name}
                    editMode={editMode}
                    onChange={(cards) => {
                        board = {name: board.name, cards};
                        addUpdateDashboard(board, board.name);
                    }}
                    setEditMode={setEditMode}
                    cardEditBuffer={activeDashboard.name === board.name ? cardEditBuffer : []}
                    setCardEditBuffer={setCardEditBuffer}
                    activeDashboard={board}
                    availableCards={availableCards}
                    filters={filters}
                    additionalProps={additionalProps}
                    disabled={editMode}
                    templates={templates || defaultDashboards}
                    oneTabDashboard={oneTabDashboard}
                    addUpdateDashboard={handleDashboardUpdate}
                />
            ))}
        </>
    );
};