import {SvgIcon, useTokens} from "@barracuda-internal/bds-core";
import React from "react";
import classNames from "classnames";

export const WorkspaceIcon = ({className}: { className?: string }) => {
    const tokens = useTokens();
    return (
        <SvgIcon
            className={classNames(className, "cuda-react-icon")}
            id="cuda-icon-workspace"
            viewBox="0 0 60 60"
        >
            <path style={{fill: tokens.aliasesColorTextStaticNeutral, strokeWidth: 0}} d="M44.8,20.3v-5h-26.3c-1.8,0-3.3,1.5-3.3,3.3v26.3h5v-24.5h24.5Z"/>
            <path style={{fill: tokens.aliasesColorTextStaticNeutral, strokeWidth: 0}} d="M36.5,11.9v-5H10.2c-1.8,0-3.3,1.5-3.3,3.3v26.3h5V11.9h24.5Z"/>
            <path style={{fill: tokens.aliasesColorTextStaticNeutral, strokeWidth: 0}}
                  d="M49.8,23.5h-23c-1.8,0-3.3,1.5-3.3,3.3v23c0,1.8,1.5,3.3,3.3,3.3h23c1.8,0,3.3-1.5,3.3-3.3v-23c0-1.8-1.5-3.3-3.3-3.3ZM48,48.1h-19.5v-19.5h19.5v19.5Z"/>
        </SvgIcon>
    );
};

export default WorkspaceIcon;