import Gateways from "./gateways/Gateways";
import {Route, Switch} from "react-router";
import Sites from "./sites/Sites";
import VmActivation from "./vmActivation/VmActivation";
import React from "react";
import GeneralNetworkSettings from "./settings/general/GeneralSettings";
import IoT from "./sites/IoT";
import SdwanConnector from "./sdwanConnector/SdwanConnector";
import ConnectorSettings from "./settings/connector/Connector";
import UpdateWindows from "./settings/updates/UpdateWindows";
import Locations from "./locations/Locations";

export const Network = () => (
    <Switch>
        <Route path="/:account/:workspace/infrastructure/gateways">
            <Gateways/>
        </Route>
        <Route path="/:account/:workspace/infrastructure/sites">
            <Sites/>
        </Route>
        <Route path="/:account/:workspace/infrastructure/iot">
            <IoT/>
        </Route>
        <Route path="/:account/:workspace/infrastructure/connectors">
            <SdwanConnector/>
        </Route>
        <Route path="/:account/:workspace/infrastructure/vmactivation">
            <VmActivation/>
        </Route>
        <Route path="/:account/:workspace/infrastructure/locations">
            <Locations/>
        </Route>
        <Route path="/:account/:workspace/infrastructure/settings/general">
            <GeneralNetworkSettings/>
        </Route>
        <Route path="/:account/:workspace/infrastructure/settings/updatewindows">
            <UpdateWindows/>
        </Route>
        <Route path="/:account/:workspace/infrastructure/settings/connector">
            <ConnectorSettings/>
        </Route>
    </Switch>
);

export default Network;