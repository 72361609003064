import React from "react";
import WebFilterSettings from "./settings/WebFilterSettings";
import {Route, Switch} from "react-router";
import CustomCategories from "./customCategories/CustomCategories";
import WebMonitorPolicies from "./webMonitorPolicies/WebMonitorPolicies";
import ExplicitTable from "./policies/ExplicitTable";

const WebFilter = () => (
    <Switch>
        <Route path="/:account/:workspace/policies/webfilter/customcategories">
            <CustomCategories />
        </Route>
        <Route path="/:account/:workspace/policies/webfilter/urlfilter">
            <ExplicitTable />
        </Route>
        <Route path="/:account/:workspace/policies/webfilter/settings">
            <WebFilterSettings />
        </Route>
        <Route path="/:account/:workspace/policies/webfilter/policies">
            <WebMonitorPolicies />
        </Route>
    </Switch>
);

export default WebFilter;