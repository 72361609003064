import React, {useCallback} from "react";
import apiResources from "../../../apiResources";
import {
    CrudTypes,
    Form,
    HiddenInput,
    SearchableSelectInput,
    SelectArrayInput,
    useCrudFetch,
    useGlobalParam
} from "@cuda-react/core";
import WizardPage, {WizardPageProps} from "../WizardPage";
import {SavedFormData} from "../SetupWizard";
import DeviceAttributesInputs, {
    defaultDeviceAttributes
} from "../../securityAndAccess/endpoint/remoteAccessPolicies/createEdit/DeviceAttributesInputs";
import {User} from "@barracuda-internal/bds-core/dist/Icons/Core";
import {categoryIcons} from "../../../components/iconMapping";
import {get} from "lodash";
import {getRandomSuffix} from "./WizardStep1";
import {Trans, useTranslation} from "react-i18next";
import {formatError} from "../../securityAndAccess/endpoint/remoteAccessPolicies/createEdit/FormattingMethods";
import {Typography} from "@barracuda-internal/bds-core";
import {createStyles, makeStyles} from "@mui/styles";

interface WizardStep4Props extends Pick<WizardPageProps, "wizardStates"> {
    onSuccess?: () => void,
    savedFormData?: SavedFormData,
    updateSavedFormData: (values: SavedFormData) => void
}

const styles = createStyles({
    subtitle: {
        fontSize: 16,
        fontWeight: "bold",
        marginTop: 16,
        marginBottom: 16
    },
    description: {
        marginBottom: 16
    },
    form: {
        height: "100%"
    }
});
const useStyles = makeStyles(styles);

const WizardStep4: React.FC<WizardStep4Props> = (props: any) => {
    const [virtualWanId] = useGlobalParam("filter.virtualWanId");
    const [translate] = useTranslation();
    const [, saving, crudSubmit] = useCrudFetch(CrudTypes.CREATE, apiResources.zeroTrustPolicies, {});
    const registerFailAction = (error: any) => {
        props.registerStepAction("Fail", {error: JSON.stringify(error)});
    };
    const classes = useStyles();

    const save = useCallback((values) => {
        props.registerStepAction("Submit");
        return crudSubmit(
            {
                data: {
                    ...values,
                    virtualWanId,
                    name: "ZTNA-Policy-" + getRandomSuffix(),
                    accessCriteria: {
                        ...(values?.accessCriteria || {}),
                        securityPosture: true,
                        groups: []
                    },
                    resourceCriteria: {
                        ...(values?.resourceCriteria || {}),
                        applications: [values?.resourceCriteria?.applications]
                    },
                    deviceCriteria: {
                        ...(values?.deviceCriteria || {}),
                        minimumClientVersion: get(values, "deviceCriteria.appUpdates") ? get(values, "deviceCriteria.appMinimumVersion", "") : null,
                        minimumOperatingSystemVersions: (get(values, "deviceCriteria.osUpdates") && get(values, "deviceCriteria.osMinimumVersions") || [])
                            .reduce((versions: any, {platform, version}: any) => ({
                                ...versions,
                                [platform]: version
                            }), {})
                    }
                }
            },
            {formPromise: true}
        ).then((response: any) => {
            if (!response?.error) {
                props.updateSavedFormData({policyUsers: values?.accessCriteria?.users});
                props.onSuccess?.();
            }
            return response?.data;
        }).catch((error: any) => {
            registerFailAction(error);
            throw formatError(error, values);
        });
    }, [crudSubmit, virtualWanId, props.updateSavedFormData, props.onSuccess]);

    return (
        <Form
            noToolbar
            flat
            save={save}
            disabled={saving}
            initialValues={{
                accessCriteria: {
                    users: props.savedFormData?.users ?? [],
                },
                resourceCriteria: {
                    applications: props.savedFormData?.applicationId
                },
                ...defaultDeviceAttributes,
            }}
            // @ts-ignore prop does exist
            classes={{form: classes.form}}
        >
            <WizardPage
                title="tesseract.setupWizard.wizardStep4.pageTitle"
                textHeader="tesseract.setupWizard.wizardStep4.textHeader"
                textSubHeader="tesseract.setupWizard.wizardStep4.textSubHeader"
                activeStep={3}
                {...props}
            >
                <Typography className={classes.subtitle}>
                    <Trans i18nKey="tesseract.setupWizard.wizardStep4.applicationPolicy"/>
                </Typography>
                <HiddenInput source="resourceCriteria.type" hiddenValue="public"/>
                <SearchableSelectInput
                    label="tesseract.setupWizard.wizardStep4.application"
                    source="resourceCriteria.applications"
                    resource={apiResources.zeroTrustApplications}
                    optionValue="id"
                    filterKey="applicationId"
                    options={{dropdownAutoWidth: true}}
                    iconMap={categoryIcons}
                    optionIcon="categoryId"
                    newStyle
                    isRequired
                />
                <Typography className={classes.subtitle}>
                    <Trans i18nKey="tesseract.setupWizard.wizardStep4.usersAndGroups"/>
                </Typography>
                <Typography className={classes.description}>
                    <Trans i18nKey="tesseract.setupWizard.wizardStep4.usersAndGroupsText"/>
                </Typography>
                <SelectArrayInput
                    source="accessCriteria.users"
                    label="tesseract.endpoint.enrollEmailDialog.users"
                    resource={apiResources.users}
                    optionValue="name"
                    filterKey="id"
                    options={{
                        label: "tesseract.endpoint.enrollEmailDialog.addUsers",
                        dropdownAutoWidth: true,
                        optionSubText: "name",
                        filterKey: "partialMatch"
                    }}
                    icon={<User/>}
                    validate={(value: string[]) => value.length > 5 ? translate("tesseract.setupWizard.wizardStep4.maxUsers") : undefined}
                    newStyle
                />
                <Typography className={classes.subtitle}>
                    <Trans i18nKey="tesseract.setupWizard.wizardStep4.deviceAttributes"/>
                </Typography>
                <DeviceAttributesInputs newStyle/>
            </WizardPage>
        </Form>
    );
};


export default WizardStep4;