import {Route, Switch} from "react-router";
import React from "react";
import AuditLog from "./auditLog/AuditLog";
import NotificationList from "./notifications/NotificationList";
import Syslog from "./syslog/Syslog";

export const LogsAndReporting = () => (
    <Switch>
        <Route path="/:account/:workspace/logs/audit">
            <AuditLog/>
        </Route>
        <Route path="/:account/:workspace/logs/notifications">
            <NotificationList/>
        </Route>
        <Route path="/:account/:workspace/logs/syslog">
            <Syslog/>
        </Route>
    </Switch>
);

export default LogsAndReporting;