import React from "react";
import mainApiResources from "../../../mainApiResources";
import {DeleteDialog, useMixpanel, Workspace} from "@cuda-react/core";
import WorkspaceCreateEditDialog from "./WorkspaceCreateEditDialog";

export interface WorkspaceActionsProps {
    activeWorkspace?: Workspace,
    onAdd?: (workspace?: Workspace) => void,
    onChange?: () => void,
    onDelete?: () => void,
}

const WorkspaceActions = ({activeWorkspace, onChange, onDelete, onAdd}: WorkspaceActionsProps) => {
    const registerAction = useMixpanel("Workspace", false);
    return (
        <>
            {activeWorkspace ? (
                <DeleteDialog
                    data={activeWorkspace}
                    onSuccess={() => {
                        registerAction("Delete");
                        onDelete?.();
                    }}
                    resource={mainApiResources.virtualWans}
                    title="stratos.accounts.dialog.titleDelete"
                    message="stratos.accounts.dialog.messageDelete"
                />
            ) : null}
            <WorkspaceCreateEditDialog
                activeWorkspace={activeWorkspace}
                onChange={(workspace?: Workspace) => {
                    registerAction(activeWorkspace ? "Update" : "Create");
                    activeWorkspace ? onChange?.() : onAdd?.(workspace);
                }}
            />
        </>
    );
};

export default WorkspaceActions;