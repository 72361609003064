import {ButtonDialog, formatDateTime, InputLabel} from "@cuda-react/core";
import {makeStyles} from "@mui/styles";
import React from 'react';
import {Trans} from "react-i18next";
import {Typography} from "@barracuda-internal/bds-core";
import {Edit} from "@barracuda-internal/bds-core/dist/Icons/Core";
import UpdateDialogContent from "./dialog/UpdateDialogContent";

const useStyles = makeStyles((theme) => ({
    inputLabel: {
        marginTop: 16,
        paddingRight: 8
    },
    inputRoot: {
        width: 230
    },
    value: {
        paddingTop: 6,
        display: "flex",
        width: 800,
        marginTop: 16,
        "& > *": {
            display: "flex"
        },
        "& p": {
            paddingTop: 2,
            width: "100%",
            display: "flex"
        }
    },
    midText: {
        display: "inline-block",
        color: "grey",
        marginRight: 10
    },
    timeText: {
        borderStyle: "solid",
        //@ts-ignore TODO: this theme entry does exist. We should extend DefaultTheme to fix this properly (or BDS should do that).
        borderColor: theme.palette.customDivider?.border?.color,
        borderRadius: 5,
        margin: "-3px 30px 5px 0px"
    },
    dateTimeInput: {
        paddingTop: 16,
        width: 400
    },
    editButton: {
        paddingTop: 10,
        marginLeft: -25
    }
}));

interface DefaultUpdateWindowProps {
    data?: Record<string, any>;
    onSuccess?: () => void;
    availableUpdates: Record<string, any>;
    disabled?: boolean;
}

const DefaultUpdateWindow: React.FC<DefaultUpdateWindowProps> = ({data, onSuccess, availableUpdates, disabled}) => {
    const classes = useStyles();

    return (
        <div className={classes.value}>
            <InputLabel
                label="tesseract.settings.tabs.updates.firmware.default.label"
                classes={{inputLabel: classes.inputLabel, root: classes.inputRoot}}
                minimised
                newStyle
            />
            <div className={classes.dateTimeInput}>
                <Typography variant="body1">
                    <Trans
                        i18nKey={data ? "tesseract.settings.tabs.updates.firmware.default.setting" : "tesseract.settings.tabs.updates.firmware.noUpdatesMessage"}
                        values={{from: formatDateTime(data?.from), to: formatDateTime(data?.to)}}
                    >
                        <div className={classes.midText}/>
                        <div className={classes.timeText}/>
                    </Trans>
                </Typography>
            </div>
                {data && (
                    <ButtonDialog
                        buttonIcon={<Edit id="edit-dialog-icon"/>}
                        disabled={disabled}
                        buttonClasses={{buttonIcon: classes.editButton}}
                    >
                        <UpdateDialogContent
                            data={data}
                            title="tesseract.settings.tabs.updates.firmware.editDefault.dialogTitle"
                            defaultUpdateWindow
                            availableUpdates={availableUpdates}
                            onSuccess={onSuccess}
                        />
                    </ButtonDialog>
                )}
        </div>
    );
};


export default DefaultUpdateWindow;