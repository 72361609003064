import React from "react";
import {Route, Switch} from "react-router";
import Users from "./users/Users";
import DevicesTable from "./devices/DevicesTable";
import ZeroTrustSettings from "./zeroTrustSettings/general/ZeroTrustSettings";
import PointsOfEntry from "./pointsOfEntry/PointsOfEntry";
import AppCatalog from "./appCatalog/AppCatalog";
import UnattendedEnrollment from "./zeroTrustSettings/general/UnattendedEnrollment";

export const Endpoint = () => (
    <Switch>
        <Route path="/:account/:workspace/ztna/users">
            <Users/>
        </Route>
        <Route path="/:account/:workspace/ztna/devices">
            <DevicesTable/>
        </Route>
        <Route path="/:account/:workspace/ztna/pointsofentry">
            <PointsOfEntry/>
        </Route>
        <Route path="/:account/:workspace/ztna/appcatalog">
            <AppCatalog/>
        </Route>
        <Route path="/:account/:workspace/ztna/settings/general">
            <ZeroTrustSettings/>
        </Route>
        <Route path="/:account/:workspace/ztna/settings/unattendedEnrollment">
            <UnattendedEnrollment/>
        </Route>
    </Switch>
);

export default Endpoint;