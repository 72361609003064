import React, {useState} from "react";
import {
    ActionDialogContent,
    ButtonDialog,
    CrudTypes,
    getDataContent, useCrudFetch,
    useCrudSubscription, useDeepCompareEffect
} from "@cuda-react/core";
import {Typography} from "@barracuda-internal/bds-core";
import apiResources from "../../../../apiResources";
import {Trans} from "react-i18next";

interface ShutdownSiteDialogProps {
    id?: number | string,
    serials?: any
}

const ShutdownSiteDialog = (props: ShutdownSiteDialogProps) => {
    const {id, serials} = props;
    const [shutdownEnabled, setShutdownEnabled] = useState(false);

    const [shutdownAvailableSerial1, loadingAvailable1, refreshSerial1] = useCrudSubscription(
        CrudTypes.GET,
        serials && serials.length && apiResources.restartAvailable,
        serials && {siteId: id, id: serials[0]},
        {pollInterval: shutdownEnabled ? 300000 : 30000}
    );
    const [shutdownAvailableSerial2, loadingAvailable2, refreshSerial2] = useCrudSubscription(
        CrudTypes.GET,
        serials && serials.length > 1 && apiResources.restartAvailable,
        serials && {siteId: id, id: serials[1]},
        {pollInterval: shutdownEnabled ? 300000 : 30000}
    );
    const shutdownAvailable = [getDataContent(shutdownAvailableSerial1.data), getDataContent(shutdownAvailableSerial2.data)];

    useDeepCompareEffect(() => {
        setShutdownEnabled(Boolean(shutdownAvailable[0] || shutdownAvailable[1]));
    }, [shutdownAvailable]);

    const shutdownAll = (requestFunction: any) => () => {
        serials.forEach((serial: string, index: number) => {
            if (shutdownAvailable[index]) {
                requestFunction({serial}).then(index === 0 ? refreshSerial1 : refreshSerial2);
            }
        });
        return true;
    };

    const [, boxLoading, requestShutdownBox] = useCrudFetch(CrudTypes.CREATE, apiResources.shutdownBox, {id});

    const renderMessage = (i18nKey: string) => (
        <Typography>
            <Trans i18nKey={i18nKey} count={serials && serials.length}>
                <br/>
            </Trans>
        </Typography>
    );

    const restartAvailableDataLoading = loadingAvailable1 || loadingAvailable2;
    const canShutdown = shutdownEnabled && !restartAvailableDataLoading && !boxLoading;

    return (
        <ButtonDialog buttonText="tesseract.appliances.shutdown.buttonLabel" disabled={!canShutdown}>
            <ActionDialogContent
                message={renderMessage("tesseract.appliances.shutdown.box.confirmMessage")}
                onConfirm={shutdownAll(requestShutdownBox)}
                title="tesseract.appliances.shutdown.box.dialogTitle"
            />
        </ButtonDialog>
    );
};


export default ShutdownSiteDialog;