import {SvgIcon, useTokens} from "@barracuda-internal/bds-core";
import React from "react";
import classNames from "classnames";

export const SortIcon = ({className}: { className?: string }) => {
    const tokens = useTokens();
    return (
        <SvgIcon
            className={classNames(className, "cuda-react-icon")}
            id="cuda-icon-sort"
            viewBox="0 0 60 60"
        >
            <g>
                <path style={{fill: tokens.aliasesColorTextStaticNeutral, strokeWidth: 0}}
                      d="M25.1,44.8h9.9v-4.9h-9.9v4.9ZM7.8,15.2v4.9h44.4v-4.9H7.8ZM15.2,32.5h29.6v-4.9H15.2v4.9Z"/>
            </g>
        </SvgIcon>
    );
};

export default SortIcon;