import {
    CustomField,
    DateField,
    getArrayDataContent,
    StatusIconField,
    TableAction,
    TablePage,
    TextField,
    useCrudProps,
    useMixpanel
} from "@cuda-react/core";
import React from "react";
import apiResources from "../../../apiResources";
import {Typography} from "@barracuda-internal/bds-core";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@mui/styles";

export const createdRanges = [
    {key: "1", name: "tesseract.audit.filter.created.days1"},
    {key: "3", name: "tesseract.audit.filter.created.days3"},
    {key: "7", name: "tesseract.audit.filter.created.weeks1"},
    {key: "14", name: "tesseract.audit.filter.created.weeks2"},
    {key: "30", name: "tesseract.audit.filter.created.months1"},
    {key: "60", name: "tesseract.audit.filter.created.months2"},
    {key: "90", name: "tesseract.audit.filter.created.months3"},
    {key: "180", name: "tesseract.audit.filter.created.months6"},
    {key: "270", name: "tesseract.audit.filter.created.months9"},
    {key: "365", name: "tesseract.audit.filter.created.years1"}
];

const useStyles = makeStyles((theme) => ({
    message: {
        marginLeft: "24px",
        marginTop: "8px"
    }
}));

const AuditLog = () => {
    useMixpanel("Audit");
    const auditStatuses = useCrudProps(apiResources.auditStatuses, {}, {}, true)[0]?.data;
    const auditTypes = useCrudProps(apiResources.auditTypes, {}, {}, true)[0]?.data;
    const auditEvents = useCrudProps(apiResources.auditEvents, {}, {}, true)[0]?.data;
    const [translate] = useTranslation();
    const classes = useStyles();

    return (
        <TablePage
            authenticated
            exportResource={{resource: apiResources.auditEntriesExport, filename: "auditEntries"}}
            resource={apiResources.auditEntries}
            title="tesseract.logs.pageTitle"
            subtitle="tesseract.logs.audit"
            minCellWidth={140}
            pageMode="paginate"
            sortable
            actions={
                <TableAction left>
                    <Typography variant="body1" className={classes.message}>
                        {translate("tesseract.logs.auditLog.message")}
                    </Typography>
                </TableAction>
            }
        >
            <StatusIconField
                isAudit
                source="status"
                label="tesseract.audit.labels.status"
                width={96}
                filter="selectarray"
                filterProps={{
                    choices: getArrayDataContent(auditStatuses)
                }}
            />
            <DateField
                source="created"
                label="tesseract.audit.labels.created"
                dateOnly={false}
                width={160}
                filter="select"
                filterProps={{
                    choices: createdRanges
                }}
            />
            <CustomField
                source="type"
                label="tesseract.audit.labels.type"
                render={(value, record) => record.typeName}
                width={160}
                filter="selectarray"
                filterProps={{
                    choices: getArrayDataContent(auditTypes)
                }}
            />
            <CustomField
                source="event"
                label="tesseract.audit.labels.event"
                render={(value, record) => record.eventName}
                width={160}
                filter="selectarray"
                filterProps={{
                    choices: getArrayDataContent(auditEvents)
                }}
            />
            <TextField
                source="serial"
                label="tesseract.audit.labels.serial"
                width={150}
                filter="text"
            />
            <TextField
                source="description"
                label="tesseract.audit.labels.description"
                sortable={false}
            />
            <TextField
                source="remoteAddress"
                label="tesseract.audit.labels.remoteAddress"
                width={142}
                filter="text"
            />
            <TextField
                source="createdUsername"
                label="tesseract.audit.labels.createdUsername"
                width={200}
                filter="text"
            />
        </TablePage>
    );
};

export default AuditLog;