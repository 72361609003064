import React, {useState} from "react";
import {MenuButton} from "@cuda-react/core";
import {Dialog} from "@barracuda-internal/bds-core";
import CreateLocationTokenContent from "./createEdit/CreateLocationTokenContent";

interface LocationsTableMenuProps {
    data?: {
        uuid?: number,
        name?: string,
        type?: string
    }
}

const LocationsTableMenu: React.FC<LocationsTableMenuProps> = ({data}) => {
    const [tokenDialogOpen, setTokenDialogOpen] = useState(false);

    return (
        <React.Fragment>
            <MenuButton
                anchorOrigin={{horizontal: "left", vertical: "top"}}
                transformOrigin={{horizontal: "left", vertical: "top"}}
                menuItems={[
                    {
                        label: "tesseract.network.locations.table.ellipsisMenu.token",
                        disabled: data?.type === "static",
                        disabledTooltipText: "tesseract.network.locations.table.ellipsisMenu.disabledTokenButton",
                        onClick: () => setTokenDialogOpen(true)
                    },
                    {
                        label: "tesseract.network.locations.table.ellipsisMenu.downloadApp",
                        disabled: data?.type === "static",
                        disabledTooltipText: "tesseract.network.locations.table.ellipsisMenu.disabledDownloadApp",
                        onClick: () => {
                            const downloadLink = window.open("https://s3.amazonaws.com/bcs-files-production/ddns/Dynamic%20IP%20Updater.msi", '_blank', 'noopener,noreferrer');
                            if (downloadLink) downloadLink.opener = null;
                        }
                    }
                ]}
                iconButton
            />
            <Dialog open={tokenDialogOpen} maxWidth={false} onClose={() => (setTokenDialogOpen(false))}>
                <CreateLocationTokenContent uuid={data?.uuid} name={data?.name} onClose={() => (setTokenDialogOpen(false))}/>
            </Dialog>
        </React.Fragment>
    );
};

export default LocationsTableMenu;