import React from "react";
import {CrudTypes, getDataContent, LocationsCardContent, useCrudSubscription} from "@cuda-react/core";
import apiResources from "./../../../../apiResources";
import {BaseCard, DashboardCardProps, CardSizes} from "@stratos/reporting";
import {ReportingTheme} from "@stratos/reporting/lib/themes";

export interface DeploymentLocationsCardProps extends DashboardCardProps {
    additionalProps?: {
        details: {
            serials: string[];
        };
    },
    size: CardSizes,
    preview?: boolean
}

export const DeploymentLocationsCard = (props: DeploymentLocationsCardProps) => {
    const {additionalProps, size, preview} = props;
    const {details} = additionalProps || {};
    const {serials} = details || {};
    const dataSerial1 = getDataContent(useCrudSubscription(CrudTypes.GET, serials?.[0] ? apiResources.applianceDeploymentLocations : undefined, {id: serials?.[0]}, {pollInterval: 300000})[0].data);
    const dataSerial2 = getDataContent(useCrudSubscription(CrudTypes.GET, serials?.[1] ? apiResources.applianceDeploymentLocations : undefined, {id: serials?.[1]}, {pollInterval: 300000})[0].data);

    return (
        <BaseCard
            id="DeploymentLocationsCard"
            title="tesseract.appliances.dashboard.deployment.location.title"
            size={size}
            preview={preview}
        >
            <LocationsCardContent
                legendEnabled={false}
                series={(serials || ["", ""]).map((serial, index) => ({
                    label: serial,
                    color: index === 0 ? ReportingTheme.genericColors.blue : ReportingTheme.genericColors.darkBlue,
                    data: index === 0 ? [dataSerial1] : [dataSerial2]
                }))}
            />
        </BaseCard>
    );
};