import React from 'react';
import classNames from "classnames";
import {SvgIcon, useTokens} from "@barracuda-internal/bds-core";

export const Medium = ({className}: { className?: string })=> {
    const tokens = useTokens();
    return (
        <SvgIcon
            className={classNames(className, "cuda-react-graphic")}
            id="cuda-gridsize-medium"
            viewBox="0 0 60 60"
        >
            <rect style={{fill: tokens.aliasesColorTextStaticNeutral, strokeWidth: 0}} x="7.7" y="7.7" width="44.5" height="19.8"/>
            <path style={{fill: tokens.aliasesColorTextStaticNeutral, strokeWidth: 0}} d="M7.7,52.3h19.8v-19.8H7.7v19.8ZM12.7,37.4h9.9v9.9h-9.9v-9.9Z"/>
            <path style={{fill: tokens.aliasesColorTextStaticNeutral, strokeWidth: 0}} d="M32.5,52.3h19.8v-19.8h-19.8v19.8ZM37.4,37.4h9.9v9.9h-9.9v-9.9Z"/>
        </SvgIcon>
    );
};