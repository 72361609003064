import React, {useEffect} from "react";
import {Button, Divider, Grid, Typography} from "@barracuda-internal/bds-core";
import {Trans, useTranslation} from "react-i18next";
import {makeStyles} from "@mui/styles";
import {Success} from "@barracuda-internal/bds-core/dist/Icons/Feedback";
import {StepIcon} from "@mui/material";
import {CrudTypes, getDataContent, useCrudFetch, useGlobalParam} from "@cuda-react/core";
import apiResources from "../../../../../apiResources";
//@ts-ignore this package has no @types
import hexGenerator from "hex-generator";

const useStyles = makeStyles((theme) => ({
    imageContainer: {
        textAlign: "center"
    },
    icon: {
        //@ts-ignore TODO: this theme entry does exist. We should extend DefaultTheme to fix this properly (or BDS should do that).
        color: theme.palette.success.main,
        height: 80,
        width: 80,
    },
    divider: {
        marginTop: 18,
        marginBottom: 18,
        width: "100%"
    },
    secCreated: {
        textAlign: "center",
        marginBottom: "16px"
    },
    message: {
        marginTop: "8px",
        marginBottom: "8px",
        textAlign: "center"
    },
    sectionNextSteps: {
        marginTop: "8px",
        marginBottom: "8px",
    },
    sectionInfoSteps: {
        display: "flex"
    },
    stepText: {
        marginLeft: "8px",
    },
    sectionActionSteps: {
        marginBottom: "16px",
        marginLeft: "32px",
        marginTop: "8px"
    },
    sectionActionStepsButtons: {
        marginRight: "8px",
    }
}));

export interface SdwanConnectorResultStepProps {
    data?: any
    result?: any
}

export const SdwanConnectorResultStep: React.FC<SdwanConnectorResultStepProps> = ({data, result}) => {
    const classes = useStyles();
    const [translate] = useTranslation();

    const demo = useGlobalParam("userData.mode")[0] === "demo";
    const [tokenData, , fetchToken] = useCrudFetch(CrudTypes.CREATE, apiResources.sdwanConnectorRegisterToken, {id: result?.data?.id});
    const tokenValue = React.useMemo(() => demo ? hexGenerator(128) : (getDataContent(tokenData) || {}).otp, [demo, tokenData]);

    useEffect(() => {
        demo || fetchToken();
    }, []);

    return (
        <Grid container direction="column">
            <Grid item>
                <div className={classes.imageContainer}>
                    <Success className={classes.icon}/>
                </div>
                <Divider className={classes.divider}/>
                <Typography className={classes.secCreated} variant="h6">
                    {translate("tesseract.network.sdwanConnector.dialog.completed.sectionTitle", {connector: data?.name})}
                </Typography>
                <Typography className={classes.message} variant="body1">
                    <Trans i18nKey="tesseract.network.sdwanConnector.dialog.completed.sectionMessage">
                        <strong/>
                    </Trans>
                </Typography>
                <Divider className={classes.divider}/>
                <Typography variant="h6">
                    {translate("tesseract.network.sdwanConnector.dialog.completed.sectionTitleNextSteps", {connector: data?.name})}
                </Typography>
                <div className={classes.sectionNextSteps}>
                    <div className={classes.sectionInfoSteps}>
                        <StepIcon icon={1} active/>
                        <Typography className={classes.stepText} variant="body1">
                            {translate("tesseract.network.sdwanConnector.dialog.completed.sectionSteps")}
                        </Typography>
                    </div>
                    <div className={classes.sectionActionSteps}>
                        <Button
                            className={classes.sectionActionStepsButtons}
                            variant="contained"
                            bdsType="interactiveEmphasis"
                            href="https://dlportal.barracudanetworks.com/#/packages/0/Connector_Windows_latest"
                            //@ts-ignore not sure why its says this property is not accepted, as it does work correctly...
                            target="_blank"
                        >
                            {translate("tesseract.network.sdwanConnector.dialog.completed.downloadButtonWindows")}
                        </Button>
                        <Button
                            className={classes.sectionActionStepsButtons}
                            variant="contained"
                            bdsType="interactiveEmphasis"
                            href="https://dlportal.barracudanetworks.com/#/packages/0/Connector_Linux_latest"
                            //@ts-ignore not sure why its says this property is not accepted, as it does work correctly...
                            target="_blank"
                        >
                            {translate("tesseract.network.sdwanConnector.dialog.completed.downloadButtonLinux")}
                        </Button>
                    </div>
                </div>
                <div className={classes.sectionNextSteps}>
                    <div className={classes.sectionInfoSteps}>
                        <StepIcon icon={2} active/>
                        <Typography className={classes.stepText} variant="body1">
                            {translate("tesseract.network.sdwanConnector.dialog.completed.sectionSteps2")}
                            <div>
                                {tokenValue}
                            </div>
                        </Typography>
                    </div>
                </div>
                <div className={classes.sectionNextSteps}>
                    <div className={classes.sectionInfoSteps}>
                        <StepIcon icon={3} active/>
                        <Typography className={classes.stepText} variant="body1">
                            {translate("tesseract.network.sdwanConnector.dialog.completed.sectionSteps3")}
                        </Typography>
                    </div>
                </div>
            </Grid>
        </Grid>

    );
};

export default SdwanConnectorResultStep;