import React from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Checkbox,
    Chip,
    FormHelperText,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography
} from "@barracuda-internal/bds-core";
import {useTranslation} from "react-i18next";
import {makeOverrideableStyles} from "@cuda-react/theme";
import {Input, InputProps} from "../Input/Input";
import {Theme} from '@mui/material';
import {createStyles} from '@mui/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

type categoryArrayType = {
    key: string,
    name: string,
    group?: string,
    subCategories?: {
        [key: string]: string,
        name: string
    }[]
};

type categoryChoicesType = {
    key: string,
    name: string,
    group: string
};

type groupType = {
    key: string;
    name: string;
};

type GroupMap = {
    [group: string]: categoryChoicesType[];
};

type onChangeType = (event: any[]) => void;

const styles = (theme: Theme) => createStyles({
    root: {
        width: "100%",
        margin: "8px 0 8px 0",
        borderWidth: 1,
        borderTopStyle: "solid",
        borderBottomStyle: "solid",
        //@ts-ignore TODO: this theme entry does exist. We should extend DefaultTheme to fix this properly (or BDS should do that).
        borderColor: theme.palette.customDivider?.border?.color
    },
    rootSyslog: {
        margin: "20px 40px 16px 80px",
        borderWidth: 1,
        //@ts-ignore TODO: this theme entry does exist. We should extend DefaultTheme to fix this properly (or BDS should do that).
        borderColor: theme.palette.customDivider?.border?.color
    },
    accordion: {
        transition: "all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms",
        borderTopStyle: "solid",
        borderTopWidth: 1,
        //@ts-ignore TODO: this theme entry does exist. We should extend DefaultTheme to fix this properly (or BDS should do that).
        borderColor: theme.palette.customDivider?.border?.color,
        textTransform: theme.typography.button.textTransform,
        boxShadow: "none",
        '&:before': {
            display: 'none',
        }
    },
    accordionSummary: {
        flexDirection: "row-reverse",
        padding: "0 0 0 16px",
        margin: "0 0 0 16px"
    },
    accordionDetails: {
        padding: "0 32px 16px"
    },
    leftHeading: {
        padding: "8px 0 0 16px",
        float: "left",
        display: "inline-block",
        marginBottom: "8px",
        fontSize: 20,
        color: "#4E4D4D"
    },
    rightHeading: {
        padding: "8px 16px 0 0",
        float: "right",
        display: "inline-block",
    },
    listContainer: {
        width: "100%",
        display: "flex",
    },
    listContainerError: {
        border: "solid 1px " + theme.palette.error.main,
        borderRadius: 4
    },
    list: {
        margin: "0 8px",
        width: "100%"
    },
    category: {
        padding: "0 0 0 16px"
    },
    subCategory: {
        transition: "all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms",
        display: "contents",
    },
    listItemIcon: {
        minWidth: 24,
        margin: "0 8px 0 0"
    },
    checkbox: {
        padding: 0,
    },
    error: {
        marginLeft: "8px"
    },
    listItemText: {
        textTransform: "capitalize",

    },
    chip: {
        margin: "0 8px 8px 0",
        textTransform: "capitalize",
        "& svg": {
            width: 20,
            marginRight: -4
        }
    },
    categoryTitle: {
        display: "inline-block",
        margin: "0 8px 0 0",
        textTransform: "lowercase",
        "&:first-letter": {
            textTransform: "capitalize"
        },
        fontWeight: "bold",
        color: theme.palette.text.secondary,
        lineHeight: "19px",
    },
    checkCount: {
        display: "inline-block",
        fontSize: 12,
        textTransform: "none"
    }
});

export const useStyles = makeOverrideableStyles("BooleanCheckListInput", styles);

export interface BooleanCheckListProps {
    /**
     * if true, input gets disabled
     */
    disabled?: boolean,
    /**
     * callback to call when the input value has been changed.
     * provided automatically when component is rendered inside a [Input](/?path=/docs/core-components-inputs-input) component.
     * @function onChange
     */
    onChange: onChangeType,
    /**
     * callback to called when component stops being interacted with.
     * provided automatically when component is rendered inside a [Input](/?path=/docs/core-components-inputs-input) component.
     * @function onBlur
     */
    onBlur: onChangeType,
    /**
     * id of the component for unique identification. This value is prefixed with "boolean-checklist-input-".
     * provided automatically when component is rendered inside a [Input](/?path=/docs/core-components-inputs-input) component.
     */
    id?: string,
    /**
     * current value of that input.
     * provided automatically when component is rendered inside a [Input](/?path=/docs/core-components-inputs-input) component.
     */
    value?: any,
    /**
     * provided automatically when component is rendered inside a [Input](/?path=/docs/core-components-inputs-input) component.
     * error associated with this input.
     */
    error?: string,
    /**
     * Array of objects containing keys and names of the category items with the group fields pointing to the respective categories they belong to
     * */
    categoryChoices: categoryChoicesType[],
    /**
     * Array of objects containing keys and names of the categories
     * */
    groupChoices: groupType[],
    /**
     * Text to be displayed at the top-left of the component. Defaults to "Categories"
     * */
    title?: string,
    /**
     * This prop is only needed for doing some CSS adjustments specific to the Syslog page
     * */
    isSyslog?: boolean
}

const updateItem = (itemKey: string, checked: boolean, value: any, onChange: (event: any[]) => void, onBlur: (event: any[]) => void) => {
    let newValue = [...value].filter((item) => item !== itemKey);

    if (checked) {
        newValue.push(itemKey);
    }
    onChange(newValue);
    onBlur(newValue);
};

const updateCategory = (category: categoryArrayType, checked: boolean, value: any, onChange: onChangeType, onBlur: onChangeType) => {
    const categoryItems = category.subCategories;
    let newValue = [...value];
    (categoryItems || []).forEach(({key: itemKey}) => {
        newValue = newValue.filter((item) => item !== itemKey);

        if (checked) {
            newValue.push(itemKey);
        }
    });

    onChange(newValue);
    onBlur(newValue);
};

const alphabeticalNameSort = (objectA: any, objectB: any): -1 | 0 | 1 => {
    const nameA = objectA?.name;
    const nameB = objectB?.name;
    if (nameA > nameB) {
        return 1;
    }
    return nameB > nameA ? -1 : 0;
};

const categoryCheckedState = (category: categoryArrayType, inputValue: any) => {
    const categoryItems = category.subCategories;
    const allItems = (categoryItems || []).length;
    let minOneChecked = false;
    let allChecked = true;
    let checkedItems = 0;
    (categoryItems || []).forEach(({key: itemKey}) => {
        const itemChecked = inputValue && inputValue.includes(itemKey);
        if (itemChecked) {
            checkedItems++;
        }
        minOneChecked = minOneChecked || itemChecked;
        allChecked = allChecked && itemChecked;
    });
    return {checked: minOneChecked, partialChecked: minOneChecked && !allChecked, checkedItems, allItems};
};

export const BooleanCheckList = (props: BooleanCheckListProps) => {
    const {disabled, id, onChange, onBlur, value = [], error, categoryChoices, groupChoices, title, isSyslog} = props;
    const [translate] = useTranslation();
    const classes = useStyles();
    const categoryGroupMap = categoryChoices.reduce((groups: GroupMap, category) => ({
        ...groups,
        [category.group]: [...(groups[category.group] || []), category].sort(alphabeticalNameSort)
    }), {});
    const categories = groupChoices.map((group) => ({
        ...group,
        subCategories: categoryGroupMap[group.key] || undefined
    })).sort(alphabeticalNameSort);

    const renderCategory = (categories: categoryArrayType[]) => categories.map((category, index) => {
        const {checked, partialChecked, checkedItems, allItems} = categoryCheckedState(category, value);

        return (
            <React.Fragment key={"list-category-" + index}>
                <Accordion
                    className={classes.accordion}
                    id="boolean-checklist-category-expander"
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        className={classes.accordionSummary}
                    >
                        <ListItem
                            className={classes.category}
                        >
                            <ListItemText className={classes.listItemText}>
                                <Typography variant="body1" className={classes.categoryTitle}
                                            id="boolean-checklist-category-title">
                                    {translate(category.name)}
                                </Typography>
                                <Typography className={classes.checkCount}>
                                    {translate("cuda.inputs.booleanCheckListInput.selectedItemsStatus", {
                                        checkedItems,
                                        allItems
                                    })}
                                </Typography>
                            </ListItemText>
                            <ListItemIcon className={classes.listItemIcon}>
                                <Checkbox
                                    className={classes.checkbox}
                                    disabled={disabled}
                                    indeterminate={!!partialChecked}
                                    checked={!!checked && !partialChecked}
                                    color="primary"
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        updateCategory(category, (event.target as HTMLInputElement).checked, value, onChange, onBlur);
                                    }}
                                />
                            </ListItemIcon>
                        </ListItem>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>
                        <div style={{display: "flex", flexWrap: "wrap"}}>
                            {(category.subCategories || []).map((item, subIndex) => {
                                const itemChecked = value && value.includes(item.key);
                                return (
                                    <Chip
                                        key={"list-item-" + index + "-" + subIndex}
                                        label={translate(item.name)}
                                        className={classes.chip}
                                        onClick={() => updateItem(item.key, !itemChecked, value, onChange, onBlur)}
                                        // variant={!!itemChecked ? "filled" : "outlined"}
                                        bdsType={itemChecked ? "interactiveEmphasis" : "interactiveNeutral"}
                                        selected={itemChecked}
                                        endIcon={itemChecked ? (
                                            <CloseIcon id="boolean-checklist-remove-icon"/>
                                        ) : (
                                            <AddIcon id="boolean-checklist-add-icon"/>
                                        )}
                                        disabled={disabled}
                                        data-testid={"boolean-checklist-item-" + (itemChecked ? "selected" : "unselected")}
                                        clickable
                                    />
                                );
                            })}
                        </div>
                    </AccordionDetails>
                </Accordion>
            </React.Fragment>
        );
    });
    return (
        <div className={isSyslog ? classes.rootSyslog : classes.root} id={id && "boolean-checklist-input-" + id}>
            <Typography variant="body1" className={classes.leftHeading}>
                {translate(title ?? "cuda.inputs.booleanCheckListInput.categories")}
            </Typography>
            <Typography variant="body1" className={classes.rightHeading}>
                {translate("cuda.inputs.booleanCheckListInput.selectAll")}
            </Typography>
            <div className={classes.listContainer + (error ? " " + classes.listContainerError : "")}>
                <List className={classes.list}>
                    {renderCategory(categories)}
                </List>
            </div>
            {error && (
                <FormHelperText error className={classes.error}>
                    {error}
                </FormHelperText>
            ) || null}
        </div>
    );
};

export interface BooleanCheckListInputProps extends Omit<InputProps<typeof BooleanCheckList>, "component" | "showErrorOnDisabled" | "noLabel" | "inputLabelProps"> {
}


export const BooleanCheckListInput = (props: BooleanCheckListInputProps) => <Input
    {...props}
    showErrorOnDisabled={false}
    component={BooleanCheckList}
    noLabel={props.isSyslog}
    inputLabelProps={{fullWidth: true, autoWidth: true}}
    displayError={false}
/>;

export default BooleanCheckListInput;