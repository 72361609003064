import React from "react";
import {
    BooleanInput,
    ConnectedForm,
    CrudTypes,
    getDataContent,
    SelectInput,
    useCrudSubscription,
    useMixpanel,
    usePreview
} from "@cuda-react/core";
import apiResources from "../../../../apiResources";
import {Typography} from "@barracuda-internal/bds-core";
import {Trans} from "react-i18next";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    introduction: {
        padding: "16px 16px 16px 32px",
    },
    deviceLimitSelect: {
        width: 96
    },
    deviceLimitInfo: {
        marginLeft: 344,
        top: -30,
        position: "relative"
    }
}));

const UserSettings = ({details}: any) => {
    const classes = useStyles();
    const id = details?.id;
    const userDetails = getDataContent(useCrudSubscription(CrudTypes.GET, apiResources.zeroTrustUsers, {id})[0], {});
    const registerAction = useMixpanel("User Settings", undefined, userDetails?.fullName);
    const tpmEnforcement = usePreview("tpm");

    return (
        <ConnectedForm
            resource={apiResources.zeroTrustUsers}
            topToolbar
            params={{id, filter: {type: "virtualWan"}}}
            onSubmitSuccess={() => {
                registerAction("Update");
            }}
        >
            <Typography className={classes.introduction}>
                <Trans i18nKey="tesseract.users.settings.introduction"/>
            </Typography>
            <BooleanInput
                source="optionsOverride"
                label="tesseract.users.settings.global"
                additionalInfoLabel="tesseract.users.settings.descriptions.global"
                toggleEnabledText="tesseract.users.settings.overrideEnabled"
                toggleDisabledText="tesseract.users.settings.overrideDisabled"
                newStyle
            />
            <BooleanInput
                source="tamperProof"
                label="tesseract.users.settings.tamperProof"
                additionalInfoLabel="tesseract.users.settings.descriptions.tamperProof"
                disable={(value, data) => !data?.optionsOverride}
                toggleEnabledText="tesseract.users.settings.enabled"
                toggleDisabledText="tesseract.users.settings.disabled"
                newStyle
            />
            <BooleanInput
                source="devicePreConnect"
                label="tesseract.users.settings.devicePreConnect"
                additionalInfoLabel="tesseract.users.settings.descriptions.devicePreConnect"
                disable={(value, data) => !data?.optionsOverride}
                toggleEnabledText="tesseract.users.settings.enabled"
                toggleDisabledText="tesseract.users.settings.disabled"
                newStyle
            />
            <SelectInput
                label="tesseract.users.settings.deviceLimit"
                source="deviceLimit"
                choices={new Array(10).fill(null).map((item, index) => ({
                    key: index + 1,
                    name: `${index + 1}`
                }))}
                additionalInfoLabel="tesseract.users.settings.descriptions.deviceLimit"
                inputLabelProps={{
                    classes: {
                        inputAdditionalInfo: classes.deviceLimitInfo
                    }
                }}
                inputProps={{
                    classes: {
                        formControl: classes.deviceLimitSelect
                    }
                }}
                disable={(value, data) => !data?.optionsOverride}
                newStyle
            />
            <BooleanInput
                source="webFilter"
                label="tesseract.users.settings.webFiltering"
                additionalInfoLabel="tesseract.users.settings.descriptions.webFiltering"
                toggleEnabledText="tesseract.users.settings.enabled"
                toggleDisabledText="tesseract.users.settings.disabled"
                disable={(value, data) => !data?.optionsOverride}
                newStyle
            />
            {tpmEnforcement ?
                <BooleanInput
                    source="tpmEnforcement"
                    label="tesseract.users.settings.tpmEnforcement"
                    additionalInfoLabel="tesseract.users.settings.descriptions.tpmEnforcement"
                    toggleEnabledText="tesseract.users.settings.enabled"
                    toggleDisabledText="tesseract.users.settings.disabled"
                    disable={(value, data) => !data?.optionsOverride}
                    newStyle
                /> : null
            }
        </ConnectedForm>
    );
};

export default UserSettings;