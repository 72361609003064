import {SvgIcon, useTokens} from "@barracuda-internal/bds-core";
import React from "react";
import classNames from "classnames";

export const IpsGraphic = ({className}: { className?: string }) => {
    const tokens = useTokens();
    return (
        <SvgIcon
            className={classNames(className, "cuda-react-graphic")}
            id="cuda-graphic-ips"
            viewBox="0 0 96 96"
        >
            <g id="ArtBoard11">
                <g id="Ebene-1">
                    <path style={{fill: tokens.globalWhite, strokeWidth: 0}} d="M65.3,58.4h-3.4v6.8h-6.8v3.4h6.8v6.8h3.4v-6.8h6.8v-3.4h-6.8v-6.8Z"/>
                    <path style={{fill: tokens.aliasesColorTextStaticNeutral, strokeWidth: 0}}
                          d="M43.7,66.9c0-.4,0-.9,0-1.3-1.3.7-2.7,1.3-4.1,1.8h-.2c0,0-.1,0-.1,0v-26.5h-19c0-.5,0-1,0-1.5v-15h0s19-7.2,19-7.2v23.7h19.3c-.1,2.4-.5,4.8-1.2,7,1.7-.6,3.5-.9,5.3-1,.6-2.5.9-5,.9-7.6v-18.4l-24.2-9.1-24.2,9.1v18.4c0,15.4,10.4,29.6,24.2,33.2,1.6-.4,3.2-1,4.7-1.7-.3-1.3-.4-2.7-.4-4"/>
                    <path style={{fill: tokens.aliasesColorIconSuccessNeutral, strokeWidth: 0}}
                          d="M63.6,49.9c-9.4,0-17.1,7.6-17.1,17.1s7.6,17.1,17.1,17.1,17.1-7.6,17.1-17.1-7.6-17.1-17.1-17.1ZM72.2,68.7h-6.8v6.8h-3.4v-6.8h-6.8v-3.4h6.8v-6.8h3.4v6.8h6.8v3.4Z"/>
                </g>
            </g>
        </SvgIcon>
    );
};

export default IpsGraphic;