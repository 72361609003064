import React, {useEffect, useState, useMemo} from "react";
import {
    BooleanInput,
    ConnectedFormPage,
    FormSection,
    InputLabel,
    ReadOnlyInput,
    useCrudFetch,
    getDataContent,
    CrudTypes,
    TextField as CudaReactTextField,
    useGlobalParam,
    useMixpanel,
    useCrudProps,
    SelectedChoiceField,
    getArrayDataContent
} from "@cuda-react/core";
import {TextField, Typography} from "@barracuda-internal/bds-core";
import {InputAdornment} from "@mui/material";
import {makeStyles} from "@mui/styles";
import apiResources from "../../../apiResources";
// @ts-ignore There is no @types module for this
import hexGenerator from "hex-generator";
import {Trans, useTranslation} from "react-i18next";
import CopyToClipboardButton from "../../../components/CopyToClipboardButton";
import {SuccessIcon, WarningIcon} from "../../../components/iconMapping";
import {Help, OffIcon} from "@cuda-react/icons";

const useStyles = makeStyles((theme) => ({
    helpText: {
        padding: "8px 8px 32px 24px",
        //@ts-ignore TODO: this theme entry does exist. We should extend DefaultTheme to fix this properly (or BDS should do that).
        color: theme.palette.text.secondary
    },
    textField: {
        lineHeight: "19px"
    },
    statusWithIcon: {
        marginTop: "-8px"
    }
}));

const ATRConfiguration = () => {
    const registerAction = useMixpanel("AtrConfiguration");
    const classes = useStyles();
    const [currentAccount] = useGlobalParam("userData.currentAccount");
    const [virtualWanId] = useGlobalParam("filter.virtualWanId");
    const demo = useGlobalParam("userData.mode")[0] === "demo";
    const connectionStatesList = useCrudProps(apiResources.connectionStates)[0]?.data;
    const [connectionStatus , setConnectionStatus] = useState<string>();
    const [isPending, setIsPending] = useState(false);
    const [otpData, , fetchOtp] = useCrudFetch(CrudTypes.CREATE, apiResources.atrConfigurationOTP, {portalId: currentAccount});
    const demoOtp = useMemo(() => isPending && demo && hexGenerator(128), [isPending, demo]);
    const otp = ((isPending && !demo) ? getDataContent(otpData)?.otp : demoOtp) || "";
    const [translate] = useTranslation();

    useEffect(() => {
        if (isPending && !demo) {
            fetchOtp({data: {virtualWanId}});
        }
    }, [isPending, fetchOtp, virtualWanId, demo]);

    return (
        <ConnectedFormPage
            title="tesseract.integration.pageTitle"
            subtitle={["tesseract.xdr.pageTitle", "tesseract.xdr.atrConfiguration.pageTitle"]}
            resource={apiResources.updateATRconfig}
            create
            defaultResource={apiResources.atrConfigList}
            formatRequestData={(data) => ({enabled: data?.enabled})}
            formatResourceData={(data) => {
                const unpackedData = data?.[0] || {};
                setIsPending(unpackedData.enabled && unpackedData.connectionState === "pendingRegistration");
                setConnectionStatus(unpackedData.connectionState);
                return {
                    enabled: unpackedData.enabled ?? false,
                    accountName: unpackedData.accountName || translate("tesseract.xdr.atrConfiguration.noAccount"),
                    connectionStatus: unpackedData.connectionState ?? ""
                };
            }}
            params={{virtualWanId, filter: {serviceName: "xdr"}}}
            onSubmitSuccess={() => registerAction("Update")}
            canReset
        >
            <FormSection title="tesseract.xdr.atrConfiguration.pageTitle" newStyle>
                <Typography className={classes.helpText}>
                    <Trans i18nKey="tesseract.xdr.atrConfiguration.description" />
                </Typography>
                <BooleanInput
                    source="enabled"
                    label="tesseract.xdr.atrConfiguration.atr"
                    newStyle
                    toggleEnabledText="tesseract.xdr.atrConfiguration.enabled"
                    toggleDisabledText="tesseract.xdr.atrConfiguration.disabled"
                />
                <ReadOnlyInput
                    source="connectionStatus"
                    label="tesseract.xdr.atrConfiguration.status"
                    inputLabelProps={{nested: true}}
                    field={SelectedChoiceField}
                    options={{
                        choices: getArrayDataContent(connectionStatesList),
                        classes: {root: classes.statusWithIcon},
                        iconMap: {
                            "connected": <SuccessIcon />,
                            "disconnected": <WarningIcon />,
                            "pendingRegistration": <Help />,
                            "disabled": <OffIcon />
                        },
                        optionIcon: "key"
                    }}
                    newStyle
                />
                <ReadOnlyInput
                    source="accountName"
                    label="tesseract.xdr.atrConfiguration.xdrAccount"
                    inputLabelProps={{nested: true}}
                    field={CudaReactTextField}
                    options={{classes: {text: classes.textField}}}
                    newStyle
                />
                <InputLabel
                    label="tesseract.xdr.atrConfiguration.token"
                    additionalInfoLabel={`tesseract.xdr.atrConfiguration.token${connectionStatus === "disabled" ? "Disabled" : "Enabled"}`}
                    newStyle
                    nested
                >
                    <TextField
                        id="otp"
                        value={otp}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                       <CopyToClipboardButton
                                    value={otp || ""}
                                    iconOnly
                                />
                                </InputAdornment>
                            )
                        }}
                        variant="outlined"
                    />
                </InputLabel>
            </FormSection>
        </ConnectedFormPage>
    );
};
export default ATRConfiguration;