import AutoIcon from "@mui/icons-material/Build";
import BusinessIcon from "@mui/icons-material/Business";
import {
    AlertIcon,
    AllowIcon,
    BlockIcon,
    DoNotScanIcon,
    Help as HelpPendingIcon,
    LiveLogClosingConnectionIcon,
    LiveLogConnectionEstablishedIcon,
    LiveLogConnectionNotEstablishedIcon,
    LiveLogOneWayActiveIcon,
    LiveLogOneWayInactiveIcon,
    LiveLogTwoWayIcon,
    NetworkBulkIcon,
    NetworkServicesIcon,
    Office365Icon,
    OffIcon,
    OverrideIcon,
    PriorityHighIcon,
    PriorityLowIcon,
    PriorityMediumIcon,
    PriorityRealtimeIcon,
    SaasIcon,
    ScanIcon,
    VoipIcon,
    VpnIcon,
    WarnIcon,
    WebTrafficIcon
} from "@cuda-react/icons";
import {Description, Forward, Help} from "@barracuda-internal/bds-core/dist/Icons/Core";
import {Critical, Success, Warning} from "@barracuda-internal/bds-core/dist/Icons/Feedback";
import React from "react";
import {makeStyles} from "@mui/styles";
import {get} from "lodash";

const useStyles = makeStyles((theme) => ({
    fillGreen: {
        //@ts-ignore TODO: this theme entry does exist. We should extend DefaultTheme to fix this properly (or BDS should do that).
        color: theme.palette.success.main
    },
    fillRed: {
        //@ts-ignore TODO: this theme entry does exist. We should extend DefaultTheme to fix this properly (or BDS should do that).
        color: theme.palette.error.main
    },
    fillOrange: {
        //@ts-ignore TODO: this theme entry does exist. We should extend DefaultTheme to fix this properly (or BDS should do that).
        color: theme.palette.warning.main
    }
}));

export const BusinessIconResized = () => (
    // @ts-ignore not sure why this fails, as this prop does work...
    <BusinessIcon size={24}/>
);
export const AutoIconResized = () => (
    // @ts-ignore not sure why this fails, as this prop does work...
    <AutoIcon size={24}/>
);

export const categoryIcons = {
    office365: <Office365Icon/>,
    saasAndBusiness: <SaasIcon/>,
    voicevideo: <VoipIcon/>,
    remoteAccess: <BusinessIconResized/>,
    networkservices: <NetworkServicesIcon/>,
    networkbulk: <NetworkBulkIcon/>,
    webtraffic: <WebTrafficIcon/>
};

export const priorityIcons = {
    realtime: <PriorityRealtimeIcon/>,
    high: <PriorityHighIcon/>,
    medium: <PriorityMediumIcon/>,
    low: <PriorityLowIcon/>
};

export const SuccessIcon = () => {
    const classes = useStyles();
    return <Success id="sdwan-ui-ok-status-icon" className={classes.fillGreen}/>;
};

export const WarningIcon = () => {
    const classes = useStyles();
    return <Warning id="sdwan-ui-warning-status-icon" className={classes.fillOrange}/>;
};

export const ErrorIcon = () => {
    const classes = useStyles();
    return <Critical id="sdwan-ui-error-status-icon" className={classes.fillRed}/>;
};

export const fallbackIcons = {
    allow: <AllowIcon/>,
    drop: <BlockIcon/>
};

export const settingsIcons = {
    allow: <AllowIcon/>,
    block: <BlockIcon/>,
    warn: <WarnIcon/>,
    alert: <AlertIcon/>,
    override: <OverrideIcon/>
};

export const ipsIcons = {
    doNotScan: <AllowIcon/>,
    enforce: <BlockIcon/>,
    ignore: <AllowIcon/>,
    log: <Description/>
};

export const sslExplicitIcons = {
    doNotInspect: <AllowIcon/>,
    inspect: <BlockIcon/>
};

export const scanIcons = {
    doNotScan: <DoNotScanIcon/>,
    scan: <ScanIcon/>
};

export const trafficTypeIcons = {
    vpn: <VpnIcon/>,
    direct: <Forward/>,
    auto: <AutoIconResized/>
};

export const siteGatewayStatusIcons = {
    online: <SuccessIcon/>,
    offline: <ErrorIcon/>,
    connecting: <WarningIcon/>,
    pending: <HelpPendingIcon/>,
    degraded: <WarningIcon/>
};

export const applianceConfigurationStatusIcons = {
    completed: <SuccessIcon/>,
    connecting: <WarningIcon/>,
    pending: <HelpPendingIcon/>,
    failed: <ErrorIcon/>
};

export const logsIconMap = {
    ok: <SuccessIcon/>,
    error: <ErrorIcon/>,
    warning: <WarningIcon/>,
    unknown: <Help/>
};

export const logsTrafficStatesIconMap = {
    initiate: <LiveLogOneWayActiveIcon/>,
    fail: <LiveLogOneWayInactiveIcon/>,
    sync: <LiveLogTwoWayIcon/>,
    established: <LiveLogConnectionEstablishedIcon/>,
    httpDeny: <LiveLogConnectionNotEstablishedIcon/>,
    closing: <LiveLogClosingConnectionIcon/>,
};

export const siteGatewayApplianceStatusIcons = {...siteGatewayStatusIcons, ...applianceConfigurationStatusIcons};

export const getSiteGatewayStatusIcons = (value?: string) => get(siteGatewayStatusIcons, value || "", siteGatewayStatusIcons.pending);

export const getSiteGatewayApplianceStatusIcon = (value?: string) => get(siteGatewayApplianceStatusIcons, value || "", siteGatewayApplianceStatusIcons.pending);

export const hotfixUpdateStatusIcons = {
    ok: <SuccessIcon/>,
    warning: <Help/>,
    unknown: <Help/>,
    error: <ErrorIcon/>
};

export const vpnTunnelStatusIcons = {
    up: <SuccessIcon/>,
    down: <ErrorIcon/>,
    inactive: <OffIcon/>,
    fail: <ErrorIcon/>,
    standby: <WarningIcon/>,
    "down (passive)": <WarningIcon/>,
    "down (fail)": <ErrorIcon/>,
    "down (standby)": <WarningIcon/>,
    "down (disabled)": <OffIcon/>,
    pending: <HelpPendingIcon/>
};

export const dhcpStatusIcons = {
    disabled: <OffIcon/>,
    dhcpService: <SuccessIcon/>,
    dhcpRelay: <SuccessIcon/>
};
export const getVpnTunnelStatusIcon = (state?: string) => get(vpnTunnelStatusIcons, state?.toLowerCase?.() || "", vpnTunnelStatusIcons.pending);