import {SvgIcon, useTokens} from "@barracuda-internal/bds-core";
import React from "react";
import classNames from "classnames";

export const IPsecConnectionGraphic = ({className}: { className?: string }) => {
    const tokens = useTokens();
    return (
        <SvgIcon
            className={classNames(className, "curaphic")}
            id="cuda-graphic-getstarted"
            viewBox="0 0 96 96"
            width={512}
        >
            <g>
                <path style={{fill: tokens.aliasesColorIconSuccessNeutral, strokeWidth: 0}}
                      d="M68,47.4c-9.4,0-17,7.6-17,17s7.6,17,17,17,17-7.6,17-17-7.6-17-17-17ZM76.5,66h-6.8v6.8h-3.4v-6.8h-6.8v-3.4h6.8v-6.8h3.4v6.8h6.8v3.4Z"/>
                <g>
                    <path style={{fill: tokens.aliasesColorTextStaticNeutral, strokeWidth: 0}} d="M45,68.6l-1.2-5.3h-4.1l-1.1,5.3h6.5Z"/>
                    <path style={{fill: tokens.aliasesColorTextStaticNeutral, strokeWidth: 0}} d="M38.1,72.5l-1,5.2h9.7l-1.2-5.2h-7.5Z"/>
                    <rect style={{fill: tokens.aliasesColorTextStaticNeutral, strokeWidth: 0}} x="12.8" y="42.6" width="5.9" height="31.1"/>
                    <path style={{fill: tokens.aliasesColorTextStaticNeutral, strokeWidth: 0, fillRule: "evenodd"}}
                          d="M52.4,52.2c0-3.1,0-6.2-.2-9.3-.4-5-5.1-8.5-10.2-8.6-5.2,0-10,3.3-10.5,8.2-.5,5.5-.1,11.2-.1,16.9h17.5c.7-2.7,1.9-5.1,3.5-7.2Z"/>
                </g>
            </g>
            <path style={{fill: tokens.aliasesColorTextStaticNeutral, strokeWidth: 0}}
                  d="M27.9,25.5c-.6-.6-1.3-.9-2.1-.9v-3c0-1.6-.6-3-1.7-4.2-1.2-1.2-2.6-1.7-4.2-1.7s-3,.6-4.2,1.7c-1.2,1.2-1.7,2.6-1.7,4.2v3c-.8,0-1.5.3-2.1.9-.6.6-.9,1.3-.9,2.1v8.9c0,.8.3,1.5.9,2.1.6.6,1.3.9,2.1.9h11.8c.8,0,1.5-.3,2.1-.9.6-.6.9-1.3.9-2.1v-8.9c0-.8-.3-1.5-.9-2.1ZM22.9,24.7h-5.9v-3c0-.8.3-1.5.9-2.1.6-.6,1.3-.9,2.1-.9s1.5.3,2.1.9c.6.6.9,1.3.9,2.1v3Z"/>
            <path style={{fill: tokens.aliasesColorTextStaticNeutral, strokeWidth: 0}}
                  d="M41.8,14.7c-5,0-9.7,1.3-13.8,3.5,0,.2.1.3.2.5,0,0,0,0,0,0,.2.8.3,1.6.3,2.4v1.5c.6.3,1.2.6,1.7,1.1h0s0,0,0,0l1.2-.7c3.1-1.6,6.7-2.5,10.4-2.5,12.7,0,23.1,10.3,23.1,23.1v1.1c1-.2,2.1-.3,3.2-.3s1.9,0,2.8.2v-1c0-16-13-29-29-29Z"/>
        </SvgIcon>
    );
};

export default IPsecConnectionGraphic;