import React from "react";
import {UserDirectoryEntraId} from "../../UserDirectoriesTypes";
import {ConnectedForm, DialogBody, TextArrayInput, TextInput} from "@cuda-react/core";
import apiResources from "../../../../../../apiResources";

const i18nScope = "tesseract.identity.userDirectories.form";

type UserDirectoryEntraIDFormProps = {
    directory: UserDirectoryEntraId;
    create: boolean;
    id?: string;
    onClose: (refresh: boolean) => void;
}

export const UserDirectoryEntraIDForm: React.FC<UserDirectoryEntraIDFormProps> = ({
    onClose,
    create,
    directory,
    id,
}: UserDirectoryEntraIDFormProps) => (
    <DialogBody
        form
        title="tesseract.identity.userDirectories.createDirectoryDialogTitle"
        onClose={() => onClose(false)}
    >
        <ConnectedForm
            create={create}
            resource={apiResources.directories}
            params={create ? undefined : {id}}
            onSubmitSuccess={() => {
                onClose(true);
            }}
            onCancel={() => onClose(false)}
            initialValues={directory}
        >
            <TextInput
                source="displayName"
                label={`${i18nScope}.displayName`}
                isRequired
            />
            <TextInput
                source="options.tenantId"
                label={`${i18nScope}.tenantId`}
                isRequired
                disabled={!create}
            />
            <TextArrayInput
                bulkInputDisabled
                source="options.groupsIncluded"
                label={`tesseract.identity.userDirectories.form.groupsIncluded`}
            />
        </ConnectedForm>
    </DialogBody>
);