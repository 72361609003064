import {StyledEngineProvider, Theme} from "@mui/material/styles";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {createHashHistory, History} from "history";
import "isomorphic-fetch";
import React from "react";
import loginAppAuthClient from "../../clients/loginAppAuthClient";
import {BDSProvider} from "@barracuda-internal/bds-core";
import "@progress/kendo-theme-material/dist/all.css";
import AnalyticsProvider, {MixpanelConfig} from "../providers/AnalyticsProvider/AnalyticsProvider";
import {OneTrustProvider} from "../providers/OneTrustProvider/OneTrustProvider";
import {Router} from "react-router";
import {I18nextProvider, LanguageMessages} from "../../i18n";
import {AuthClient, AuthProvider, GlobalParamsProvider} from "../providers";
// eslint-disable-next-line import/named
import {FlagProvider, IConfig} from "@unleash/proxy-client-react";

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any
    }
}

export const CoreAppThemeDataKey = "@CUDA/CORE_APP_THEME";

export interface CoreAppThemeData {
    /** custom overrides to the CudaTheme **/
    customTheme?: any
    /** current theme mode (not currently used, but placeholder for future) **/
    mode?: "dark" | "light"
}

export interface CudaReactProviderProps {
    /**
     * Custom implementation of an authentication client. See [Auth Client](/?path=/docs/cudareactapp-authentication--page#auth-client) for more information.
     */
    authClient?: AuthClient,
    /**
     * Turns on BCC based authentication. See [BCC Authentication](/?path=/docs/cudareactapp-authentication--page#bcc-authentication) for more information.
     */
    bccAuthentication?: boolean,
    /**
     * Children to render within the providers context. Should be children suitable for the <Router> component.
     */
    children: any,
    /**
     * Additional i18n library messages to merge with the cuda-react i18n messages.
     */
    customMessages?: LanguageMessages,
    /**
     * Theme overrides and settings to merge with the default theme.
     */
    customTheme?: Partial<Theme>,
    /**
     * Router history to use when creating the react-router. If not provided, "createHashHistory" is used.
     */
    history?: History,
    /**
     * Configuration options for mixpanel tracking if no API key is set then no tracking will occur
     */
    mixpanelConfig?: MixpanelConfig,
    /**
     * Configuration options for unleash flag provider
     */
    unleashConfig?: IConfig
}

/**
 * Providers for the CudaReactApp. Sets up all required providers, and the top level Router.
 *
 * For a tutorial on how to get started with CudaReactApp, see the [Getting Started](/?path=/docs/cudareactapp-getting-started-creating-an-app--creating-an-app) guide.
 */
const CudaReactProvider = ({
                               customMessages,
                               children,
                               history,
                               bccAuthentication,
                               authClient: customAuthClient,
                               mixpanelConfig,
                               unleashConfig
                           }: CudaReactProviderProps): JSX.Element => {
    const routerHistory = history || createHashHistory();
    const authClient = customAuthClient || (bccAuthentication ? loginAppAuthClient : null);

    return (
        <OneTrustProvider>
            <FlagProvider config={unleashConfig} startClient={!!unleashConfig}>
                <GlobalParamsProvider>
                    <AnalyticsProvider mixpanelConfig={mixpanelConfig}/>
                    <StyledEngineProvider injectFirst>
                         <BDSProvider fontFamilyOverride="Manrope, sans-serif">
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <I18nextProvider messages={customMessages}>
                                    <Router history={routerHistory}>
                                        <AuthProvider authClient={authClient}/>
                                        {children}
                                    </Router>
                                </I18nextProvider>
                            </LocalizationProvider>
                        </BDSProvider>
                    </StyledEngineProvider>
                </GlobalParamsProvider>
            </FlagProvider>
        </OneTrustProvider>
    );
};

export default CudaReactProvider;