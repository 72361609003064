import {Button, CircularProgress, TextField, Typography} from "@barracuda-internal/bds-core";
import {InputAdornment} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {
    CrudTypes,
    DialogBody,
    getDataContent,
    Toolbar,
    useCrudFetch,
    useGlobalParam,
    useMixpanel
} from "@cuda-react/core";
// @ts-ignore There is no @types module for this
import hexGenerator from "hex-generator";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import apiResources from "../../../../apiResources";
import CopyToClipboardButton from "../../../../components/CopyToClipboardButton";

export const useStyles = makeStyles((theme) => ({
    dialogContent: {
        maxWidth: 400,
        padding: "16px"
    },
    inputRoot: {
        width: "calc(100% - 64px)",
        margin: "16px 32px 8px",
        "& .MuiOutlinedInput-multiline": {
            height: "inherit"
        }
    },
    completeMessage: {
        margin: "8px"
    }
}));

interface CreateSdwanConnectorTokenContentProps {
    onClose?: () => void,
    id?: number,
    sec?: string
}


export const CreateSdwanConnectorTokenContent: React.FC<CreateSdwanConnectorTokenContentProps> = ({id, sec, onClose}) => {
    useMixpanel("SD-WAN Connectors", "Create SD-WAN Connector Token");
    const [translate] = useTranslation();
    const classes = useStyles();
    const [currentAccount] = useGlobalParam("userData.currentAccount");
    const demo = useGlobalParam("userData.mode")[0] === "demo";
    const [data, , fetchToken] = useCrudFetch(CrudTypes.CREATE, apiResources.sdwanConnectorRegisterToken, {id, portalId: currentAccount});
    const token = React.useMemo(() => demo ? hexGenerator(128) : (getDataContent(data) || {}).otp, [demo, data]);

    useEffect(() => {
        demo || fetchToken();
    }, []);

    return (
        <React.Fragment>
            <DialogBody
                classes={{dialogContent: classes.dialogContent}}
                title="tesseract.network.sdwanConnector.dialog.token.dialogTitle"
                onClose={onClose}
            >
                <Typography variant="body1" className={classes.completeMessage}>
                    {translate("tesseract.network.sdwanConnector.dialog.token.message", {sec})}
                </Typography>
                <TextField
                    id="sec-otp"
                    className={classes.inputRoot}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">
                            {token ? (
                                <CopyToClipboardButton
                                    value={token || ""}
                                    iconOnly
                                />
                            ) : (
                                <CircularProgress size={24}/>
                            )}
                        </InputAdornment>
                    }}
                    fullWidth
                    label={translate("tesseract.network.sdwanConnector.dialog.token.subLabel")}
                    value={token || ""}
                    disabled={!token}
                    variant="outlined"
                />
            </DialogBody>
            <Toolbar>
                <Button variant="contained" bdsType="interactiveEmphasis" onClick={onClose}>
                    {translate("cuda.notification.ok")}
                </Button>
            </Toolbar>
        </React.Fragment>
    );
};


export default CreateSdwanConnectorTokenContent;