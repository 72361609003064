import React from "react";
import {CudaTheme, makeOverrideableStyles} from "@cuda-react/theme";
import ConnectedForm, {ConnectedFormProps} from "../../forms/ConnectedForm/ConnectedForm";
import BasicPage, {BasicPageProps} from "../BasicPage/BasicPage";
import FormButtonToolbar from "../../forms/FormButtonToolbar/FormButtonToolbar";
import {Card, Typography} from "@barracuda-internal/bds-core";
import {Theme} from "@mui/material";
import {createStyles} from "@mui/styles";
import {useTranslation} from "react-i18next";

export const styles = (theme: Theme & typeof CudaTheme) => createStyles({
    toolbar: {
        marginBottom: -12,
        marginRight: -16
    },
    titleNewStyle: {
        padding: "12px 28px 12px",
        marginBottom: "8px",
        fontSize: 20,
        fontWeight: "bold",
        color: "#4E4D4D"
    }
});
const useStyles = makeOverrideableStyles("ConnectedFormPage", styles);

export type ConnectedFormPageProps = BasicPageProps & ConnectedFormProps

const ConnectedFormPage = (props: ConnectedFormPageProps) => {
    const classes = useStyles(props);
    const {
        actions,
        children,
        title,
        subtitle,
        authenticated,
        authParams,
        showSeparateTitle,
        formButtonToolbarProps,
        ...connectedFormProps
    } = props;
    const arrayActions: React.ReactNode[] = (Array.isArray(actions) ? actions : [actions]).filter(Boolean);
    const [translate] = useTranslation();
    let pageTitle;
    if (typeof subtitle === "string" || Array.isArray(subtitle)) {
        pageTitle = (showSeparateTitle && subtitle) ? (!Array.isArray(subtitle) ? [subtitle] : subtitle)[subtitle.length - 1] : null;
    }

    return (
        <ConnectedForm flat noToolbar {...connectedFormProps}>
            <BasicPage
                actions={[...arrayActions,
                    <FormButtonToolbar {...formButtonToolbarProps} key="toolbar" buttonsOnly classes={{buttonContainer: classes.toolbar}}/>]}
                authenticated={authenticated}
                authParams={authParams}
                title={title}
                subtitle={subtitle}
            >
                <Card>
                    {pageTitle ? <Typography variant="h6"
                                             className={classes.titleNewStyle}>{translate(typeof pageTitle !== "string" ? pageTitle.text : pageTitle)}</Typography> : null}
                    {children}
                </Card>
            </BasicPage>
        </ConnectedForm>
    );
};

export default ConnectedFormPage;