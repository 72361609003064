import React, {useEffect, useState} from "react";
import {
    CrudTypes,
    getArrayDataContent,
    getDataContent,
    InputLabel,
    Select,
    useCrudFetch,
    useCrudProps,
    useCrudSubscription
} from "@cuda-react/core";
import {debounce} from "lodash";

interface DefaultActionSelectorPropTypes {
    actionChoicesApi: string,
    defaultActionApi: string,
    applianceType?: "site" | "gateway" | string;
    registerAction?: (action: string, additionalDetails: Record<string, string | number | boolean>) => any
}

const DefaultActionSelector = ({actionChoicesApi, defaultActionApi, applianceType, registerAction} : DefaultActionSelectorPropTypes) => {
    const defaultActionChoices = getArrayDataContent(useCrudProps(actionChoicesApi, {}, {}, true)[0]?.data);
    const [defaultActionData, defaultActionLoading, refreshDefaultAction] = useCrudSubscription(CrudTypes.GET, defaultActionApi, applianceType ? {filter: {type: applianceType}} : undefined);
    const [, defaultActionUpdating, updateDefaultAction] = useCrudFetch(CrudTypes.UPDATE, defaultActionApi);
    const defaultAction = getDataContent(defaultActionData);
    const defaultLoading = typeof defaultAction !== "string" || defaultActionLoading || defaultActionUpdating;
    const [disabled, setDisabled] = useState(true);

    useEffect(() => {
        const debouncedDisabled = debounce(
            () => setDisabled(typeof defaultAction !== "string" || defaultActionLoading || defaultActionUpdating), 100);
        debouncedDisabled();
        return () => {
            debouncedDisabled.cancel();
        };
    }, [defaultAction, defaultActionLoading, defaultActionUpdating]);
    const [defaultDropdownValue, setDefaultDropdownValue] = useState("");
    const handleUpdateDefaultAction = (action: string) => {
        if (action && !defaultLoading && action !== defaultAction) {
            registerAction?.("Default Update", {action});
            updateDefaultAction({action, filter: applianceType ? {type: applianceType} : undefined}).then(refreshDefaultAction);
        }
    };

    useEffect(() => {
        if(typeof defaultAction === "string" && defaultAction !== defaultDropdownValue) {
            setDefaultDropdownValue(defaultAction);
        }
    }, [defaultAction]);

    useEffect(() => {
        handleUpdateDefaultAction(defaultDropdownValue);
    }, [defaultDropdownValue]);

    return (
        <InputLabel
            key="defaultAction"
            label="tesseract.security.urlFiltering.settings.defaultAction"
            minimised
        >
            <Select
                id="defaultAction"
                value={defaultDropdownValue}
                onChange={(eventOrValue: any) => setDefaultDropdownValue(eventOrValue?.target?.value)}
                disabled={disabled}
                choices={defaultActionChoices}
                onBlur={() => {}}
            />
        </InputLabel>
    );
};

export default DefaultActionSelector;