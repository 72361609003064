import {Button, CircularProgress, TextField, Typography} from "@barracuda-internal/bds-core";
import {InputAdornment} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {CrudTypes, DialogBody, getDataContent, Toolbar, useCrudFetch, useGlobalParam} from "@cuda-react/core";
// @ts-ignore this module does not have a @types lib
import hexGenerator from "hex-generator";
import React, {useEffect, useMemo} from "react";
import {Trans, useTranslation} from "react-i18next";
import apiResources from "../../../apiResources";
import CopyToClipboardButton from "../../../components/CopyToClipboardButton";

interface CreateFirewallTokenContentProps {
    onClose?: () => void;
}

const useStyles = makeStyles((theme) => ({
    dialogContent: {
        maxWidth: 400,
        padding: "16px"
    },
    inputRoot: {
        width: `calc(100% - 64px)`,
        margin: "16px 32px 8px",
        "& .MuiOutlinedInput-multiline": {
            height: "inherit"
        }
    },
    completeMessage: {
        margin: "8px"
    }
}));
const CreateFirewallTokenContent: React.FC<CreateFirewallTokenContentProps> = ({onClose}) => {
    const [translate] = useTranslation();
    const classes = useStyles();
    const demo = useGlobalParam("userData.mode")[0] === "demo";
    const [data, , fetchToken] = useCrudFetch(CrudTypes.CREATE, apiResources.registerFirewall);
    const token = useMemo(() => (demo ? hexGenerator(128) : (getDataContent(data) || {}).otp), [demo, data]);
    useEffect(() => {
        if (!demo) {
            fetchToken();
        }
    }, []);
    return (
        <>
            <DialogBody classes={{dialogContent: classes.dialogContent}}
                        title="tesseract.firewalls.dialog.create.dialogTitle" onClose={onClose}>
                <Typography variant="body1" className={classes.completeMessage}>
                    <Trans i18nKey="tesseract.firewalls.dialog.result.message">
                        <a
                            href="https://campus.barracuda.com/doc/98223588/"
                            rel="noreferrer noopener"
                            target="_blank"
                        />
                    </Trans>
                </Typography>
                <TextField
                    id="gateway-otp"
                    className={classes.inputRoot}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">
                            {token ? (
                                <CopyToClipboardButton
                                    value={token || ""}
                                    iconOnly
                                />
                            ) : (
                                <CircularProgress size={24}/>
                            )}
                        </InputAdornment>
                    }}
                    fullWidth
                    label={translate("tesseract.firewalls.dialog.result.firewallText")}
                    value={token || ""}
                    disabled={!token}
                    variant="outlined"
                />
                <Typography variant="body1" className={classes.completeMessage}>
                    {translate("tesseract.firewalls.dialog.result.firewallOtp")}
                </Typography>
            </DialogBody>
            <Toolbar>
                <Button variant="contained" bdsType="interactiveEmphasis" onClick={onClose}>
                    {translate("cuda.notification.ok")}
                </Button>
            </Toolbar>
        </>
    );
};

export default CreateFirewallTokenContent;