export default {
  translation: {
    stratosReporting: {
      resourceStatisticsCard: {
        overview: "合計"
      },
      sdwanStatusCard: {
        title: "SD-WANステータス",
        overviewTitle: "合計",
        healthy: "正常",
        degraded: "低下",
        down: "停止",
        tableTitle: "SD-WANステータス アプライアンス トップ 10",
        name: "名前",
        location: "場所",
        type: "タイプ"
      },
      sdwanBandwidthCard: {
        tableTitle: "SD-WAN帯域 トップ 5",
        title: "SD-WAN帯域概要"
      },
      sdwanBandwidthChartCard: {
        title: "SD-WAN帯域",
        topTunnels: "上位 {{length}} トンネル"
      },
      osStatusCard: {
        title: "OS",
        others: "その他",
        name: "名前",
        location: "場所",
        type: "タイプ",
        ip: "IP",
        linux: "Linux",
        mac: "Mac OSX",
        windows: "Windows",
        other: "その他",
        android: "Android",
        ios: "iOS"
      },
      applianceMapCard: {
        title: "アプライアンスマップ"
      },
      baseBarCard: {
        total: "合計 {{total}}件のうち"
      },
      topApplicationCard: {
        title: "アプリケーション上位",
        topApplications: "トップ {{length}}アプリケーション"
      },
      sdwanSummaryCard: {
        title: "SD-WAN概要",
        topAppliances: "トップ {{length}}デバイス",
        inbound: "インバウンド",
        outbound: "アウトバウンド"
      },
      topWebRequest: {
        title: "ウェブリクエスト上位リスト",
        tableTitle: "ウェブリクエスト上位リスト",
        total: "合計",
        allowed: "許可",
        blocked: "ブロック",
        type: "タイプ",
        location: "場所",
        name: "名前"
      },
      storageCard: {
        title: "ストレージ概要",
        use: "{{totalSpace}}GB中 <0>{{spaceUsed}}GB</0>使用中",
        retention: "最大保持約 3日",
        name: "名前",
        lastChange: "前回の変更",
        fileSize: "ファイルサイズ",
        storageLow: "記憶領域が僅かになりました！",
        upgradeStorageNow: "今すぐストレージをグレードアップしましょう！",
        tableTitle: "ストレージの使用が高いデバイス"
      },
      topBlockedUserCard: {
        title: "アクセスブロックユーザー上位リスト",
        topUsers: "トップ {{length}}ユーザー",
        total: "合計 {{total}}件のうち",
        hits: "{{formattedCount}} 件",
        hits_one: "{{formattedCount}} 件",
        hits_other: "{{formattedCount}} 件"
      },
      sdwanHeatMap: {
        title: "SD-WANヒートマップ"
      },
      legendFooter: {
        online: "オンライン",
        offline: "オフライン",
        degraded: "ダウングレード"
      },
      applianceStatus: {
        title: "デバイスステータス",
        topUsers: "トップ {{length}}ユーザー",
        total: "合計 {{total}}件のうち"
      },
      previewDialog: {
        addWidgetButton: "ウィジェットを追加",
        title: "ウィジェットを追加",
        card: "ウィジェット",
        size: "サイズ",
        cancel: "キャンセル",
        add: "追加",
        small: "小",
        medium: "中",
        large: "大",
        addWidgetCard: "クリックしてウィジェットを追加してください！",
        ok: "OK",
        editTitle: "ウィジェットを編集"
      },
      reportTable: {
        title: "すべてのレポート",
        type: "タイプ",
        name: "名前",
        period: "期間",
        devices: "デバイス",
        schedule: "スケジュール",
        owner: "オーナー"
      },
      reportDialog: {
        create: {
          title: "レポートを作成",
          button: "作成"
        },
        edit: {
          title: "レポートを編集",
          button: "編集"
        },
        reportData: {
          label: "レポートデータ",
          application: {
            name: "アプリケーション"
          },
          protocol: {
            name: "プロトコル"
          },
          applicationRule: {
            name: "アプリケーションルール"
          },
          contentType: {
            name: "コンテンツタイプ"
          },
          outputInterface: {
            name: "出力インターフェース"
          },
          destination: {
            name: "宛先"
          }
        },
        datasource: {
          label: "データソース",
          activity: {
            name: "アクティビティ"
          },
          threat: {
            name: "脅威"
          },
          webFilter: {
            name: "ウェブフィルター"
          },
          sdwanTunnels: {
            name: "SD-WANトンネル"
          },
          sdwanTunnelsAppProtocol: {
            name: "SD-WANトンネルアプリケーションとプロトコル"
          }
        },
        output: {
          label: "出力",
          outputFormat: {
            label: "出力フォーマット"
          },
          deliveryOptions: {
            label: "デリバリーオプション",
            email: "メールアドレス",
            fileDownload: "ファイルダウンロード"
          },
          recipients: {
            label: "受信者"
          },
          sizeLimit: {
            label: "サイズ上限"
          },
          frequency: {
            label: "頻度",
            once: "1回",
            weekly: "毎週",
            monthly: "毎月"
          },
          status: {
            label: "ステータス",
            enabled: "有効",
            disabled: "無効"
          }
        },
        advanced: {
          label: "詳細設定",
          destination: {
            label: "宛先",
            domain: "ドメイン",
            category: "カテゴリー"
          },
          ipAnonymize: {
            label: "IP匿名化"
          },
          serialNumberBucketing: {
            label: "シリアル番号バケット作成"
          },
          domains: {
            label: "ドメイン"
          },
          exludeTimeframe: {
            label: "除外時間枠"
          },
          action: {
            label: "アクション"
          },
          chartType: {
            label: "チャートタイプ",
            horizontalBar: "横棒グラフ",
            verticalBar: "縦棒グラフ",
            pieChart: "円グラフ"
          },
          ddLimitLevels: {
            label: "ドリルダウン上限レベル"
          }
        },
        result: {
          label: "結果ステップ"
        },
        success: "正常に作成されました。"
      },
      userEnrollment: {
        title: "ユーザー登録",
        enrolledUsers: "ユーザー",
        enrolledDevices: "デバイス",
        pendingEnrollment: "保留中",
        name: "名前",
        location: "場所",
        device: "デバイス"
      },
      licensing: {
        title: "ライセンス",
        bandwidth: "帯域幅",
        bandwidthUsageMedium: "{{remain}} あとGbit/s (合計{{total}} Gbit/sの内)",
        bandwidthUsageLarge: "{{used}}使用 (合計{{total}} GB/sの内)",
        bandwidthExpire: "{{daysLeft}}日後に満了",
        users: "ユーザー",
        usersUsageMedium: "{{remain}} 残りユーザー(合計ユーザー {{total}}人の内)",
        usersUsageLarge: "{{used}}使用ユーザー(合計ユーザー {{total}} 人の内)",
        usersExpire: "{{remain}} 残りユーザー",
        storage: "ストレージ",
        storageUsageMedium: "{{remain}} 残りGB (合計{{total}} GBの内)",
        storageUsageLarge: "{{used}} GB/s使用(合計{{total}} GB/sの内)",
        storageRetention: "最大保持約{{remain}}日間",
        storageExpire: "{{daysLeft}}日後に満了",
        appliances: "アプライアンス",
        appliancesUsageMedium: "あと{{remain}}日(合計{{total}}日の内)",
        appliancesUsageLarge: "{{used}}日使用(合計{{total}}日の内)",
        appliancesExpire: "{{daysLeft}}日後に満了",
        percentage: "{{percentage}}"
      },
      dashboardPage: {
        done: "完了",
        cancel: "キャンセル",
        editDashboard: "ダッシュボードを編集",
        emptyMessage: "このダッシュボードには現時点ではウィジェットが含まれていません。",
        addMessage: "「追加」ボタンをクリックしてウィジェットを追加してください。",
        lastDay: "過去24時間",
        lastWeek: "先週",
        lastMonth: "先月",
        configureDashboard: "このダッシュボードを設定してください！",
        addDashboard: "ダッシュボードの作成",
        name: "名前",
        template: "テンプレート",
        reset: "ダッシュボードをリセット",
        rename: "ダッシュボードの名前を変更する。",
        delete: "ダッシュボードを削除",
        confirmDeletion: "'{{name}}'ダッシュボードを削除してもよろしいですか？",
        actions: "オプション",
        add: "ダッシュボードを追加",
        resetToTemplate: "テンプレートにリセット",
        validateName: "英数字か空白しか含めることはできません。",
        changeDashboardMessage: "変更を保存せずにダッシュボードを変更してもよいですか？",
        uniqueNameError: "一意でなければなりません。",
        resetToDefault: "ダッシュボードをデフォルト状態にリセットしますか？",
        resetToTemplateDescription: "ダッシュボードを選択されたテンプレートの元のウィジェットとレイアウトにリセットしてください。",
        options: "オプション",
        maxNumberOfDashboardReached: "ダッシュボードをこれ以上作成できません。最大{{maxDashboardsAmount}}個に達しました。"
      },
      domainCard: {
        title: "ドメイン"
      },
      dualTopBarBaseCard: {
        title: "ドメイン",
        overview: "合計",
        totalAllowedSessions: "許可セッション",
        totalBlockedSessions: "ブロックセッション",
        allowed: "許可",
        blocked: "ブロック"
      },
      topUrlCard: {
        title: "URLカテゴリー"
      },
      malwareCard: {
        title: "Advanced Threat Protection",
        hits: "{{formattedCount}} 件",
        hits_one: "{{formattedCount}} 件",
        hits_other: "{{formattedCount}} 件"
      },
      ipsIncidentCard: {
        title: "IPSインシデント",
        sessions: "{{sessions}} 件",
        sessions_one: "{{sessions}} 件",
        sessions_other: "{{sessions}} 件"
      },
      affectedUsers: {
        title: "影響を受けたユーザー"
      },
      totalChart: {
        total: "合計"
      },
      geoCard: {
        sourceTopCountries: {
          title: "アクセス元 国別 上位"
        },
        destinationTopCountries: {
          title: "アクセス先 国別 上位"
        }
      },
      topAllowedCategoriesSessions: {
        title: "許可アプリカテゴリー 上位 <0>(セッション当たり)</0>",
        menuEntry: "許可アプリカテゴリー 上位(セッション当たり)",
        footerKey: "アクセス上位 {{length}}",
        sessions: "{{formattedCount}} セッション",
        sessions_one: "{{formattedCount}} セッション",
        sessions_other: "{{formattedCount}} セッション"
      },
      topAllowedCategoriesTraffic: {
        title: "許可アプリカテゴリー 上位<0>(トラフィック当たり)</0>",
        menuEntry: "許可アプリカテゴリー 上位(トラフィック当たり)",
        footerKey: "アクセス上位 {{length}}"
      },
      blockedApplicationCategories: {
        title: "ブロックアプリカテゴリー　上位",
        footerKey: "アクセス上位 {{length}}"
      },
      allowedApplicationsSessions: {
        title: "アクセス許可アプリケーション 上位<0>(セッション当たり)</0>",
        menuEntry: "アクセス許可アプリケーション 上位(セッション当たり)",
        sessions: "{{formattedCount}} セッション",
        sessions_one: "{{formattedCount}} セッション",
        sessions_other: "{{formattedCount}} セッション",
        footerKey: "アクセス上位 {{length}}"
      },
      allowedApplicationsTraffic: {
        title: "アクセス許可アプリケーション 上位<0>(Traffic当たり)</0>",
        menuEntry: "アクセスアプリケーション 上位(Traffic当たり)",
        footerKey: "アクセス上位 {{length}}"
      },
      blockedApplications: {
        title: "アクセスブロックされたアプリケーション 上位",
        sessions: "{{formattedCount}} セッション",
        sessions_one: "{{formattedCount}} セッション",
        sessions_other: "{{formattedCount}} セッション",
        footerKey: "上位 {{length}}"
      },
      applicationRisk: {
        mediumHigh: "中 - 高",
        medium: "中",
        lowMedium: "低 - 中",
        low: "低",
        high: "高",
        title: "アプリケーションのリスク",
        risk: "リスク"
      },
      highRiskApplicationsCard: {
        title: "ハイリスクのアプリケーション",
        top: "アクセス上位 {{length}}"
      },
      topBlockedUrlCategoriesCard: {
        title: "アクセスブロックされたURLカテゴリー上位",
        topUrlCategories: "上位{{length}}ブロックされたURLカテゴリー",
        total: "{{total}}から",
        sessions: "{{formattedCount}} セッション",
        sessions_one: "{{formattedCount}} セッション",
        sessions_other: "{{formattedCount}} セッション"
      },
      applicationCategories: {
        title: "アプリケーションカテゴリー"
      },
      topApplications: {
        title: "上位アプリケーション"
      },
      topAllowedUrlCategoriesCard: {
        titlePerSession: "許可URLカテゴリー上位<0>(Session当たり)</0>",
        titlePerTraffic: "許可URLカテゴリー上位<0>(Traffic当たり)</0>",
        menuEntryPerSession: "許可URLカテゴリー(Session当たり)",
        menuEntryPerTraffic: "許可URLカテゴリー(Traffic当たり)",
        topAllowedUrlCategories: "トップ{{length}}許可URLカテゴリー",
        total: "{{total}}から",
        sessions: "{{formattedCount}} セッション",
        sessions_one: "{{formattedCount}} セッション",
        sessions_other: "{{formattedCount}} セッション"
      },
      topAllowedDomainsCard: {
        titlePerSession: "許可ドメイン上位<0>(Session当たり)</0>",
        titlePerTraffic: "許可ドメイン上位<0>(Traffic当たり)</0>",
        menuEntryPerSession: "許可ドメイン上位(Session当たり)",
        menuEntryPerTraffic: "許可ドメイン上位(Traffic当たり)",
        topAllowedDomains: "トップ{{length}}許可ドメイン",
        total: "以外 {{total}}",
        sessions: "{{formattedCount}} セッション",
        sessions_one: "{{formattedCount}} セッション",
        sessions_other: "{{formattedCount}} セッション"
      },
      topBlockedDomainsCard: {
        title: "最多阻止されたドメイン",
        topBlockedDomains: "最多{{length}}阻止されたドメイン",
        total: "{{total}}から",
        sessions: "{{formattedCount}} セッション",
        sessions_one: "{{formattedCount}} セッション",
        sessions_other: "{{formattedCount}} セッション"
      },
      topUsersCard: {
        title: "アクセスユーザー上位",
        topUsers: "トップ{{length}}のユーザー",
        total: "{{total}}から"
      },
      noData: {
        message: "このウィジェットのためのデータがありません！",
        unsupported: "ウィジェットは現在使用しているEdge Serviceでサポートされていません。"
      },
      barGrid: {
        noData: "マッチしたエントリーが見つかりませんでした！"
      },
      ZTNAResources: {
        title: "リソース",
        others: "その他",
        total: "合計リソース",
        custom: "カスタムリソース",
        inUse: "使用中",
        tableName: "アクセスポリシー",
        table: {
          name: "名前",
          description: "説明",
          users: "ユーザー",
          groups: "グループ",
          resources: "リソース",
          devicePosture: "端末の状態"
        }
      },
      ZTNAAgentInUse: {
        title: "利用中のOS種別",
        total: "合計",
        windows: "Windows",
        macos: "macOS",
        android: "Android",
        ios: "iOS",
        linux: "Linux",
        tableName: "最も利用されているOS",
        devices: "{{value}} デバイス",
        devices_plural: "{{value}} デバイス"
      },
      ZTNADeviceLocation: {
        title: "デバイスマップ",
        devices: "デバイス",
        noData: "デバイスが見つかりません。",
        user: "ユーザー",
        brand: "メーカー",
        model: "モデル",
        hardwareModel: "ハードウェアモデル",
        operatingSystem: "OS",
        operatingSystemVersion: "OSバージョン",
        agentVersion: "エージェントバージョン",
        enrollmentDate: "登録日",
        country: "国",
        noCountry: "場所無し"
      },
      ZTNAUserGeoLocation: {
        title: "ユーザー所在地",
        nonRoutableOrIP: "ルーティング不可またはプライベートIPアドレス",
        devices: "{{value}} デバイス",
        devices_plural: "{{value}} デバイス",
        nonRoutableOrIPUnknown: "不明"
      },
      userStatusCard: {
        title: "ユーザー",
        total: "合計",
        enrolled: "登録済",
        pending: "保留中",
        tableTitle: "ユーザー上位（デバイス台数別）",
        name: "名前",
        email: "メールアドレス",
        deviceCount: "デバイス数",
        enrollmentCompleted: {
          label: "登録完了",
          yes: "登録完了",
          no: "登録保留"
        }
      },
      userSummaryCard: {
        title: "要約",
        fullName: "フルネーム",
        email: "メールアドレス",
        deviceCount: "デバイス数",
        enrollmentCompleted: "登録完了"
      },
      userDevicesCard: {
        title: "デバイス",
        table: {
          userFullName: "フルネーム",
          userEmail: "メールアドレス",
          brand: "メーカー",
          hardwareModel: "モデル",
          operatingSystem: "OS",
          operatingSystemVersion: "OSバージョン",
          enrollmentDate: "登録日"
        }
      },
      accessDevicesAgentVersionCard: {
        title: "利用エージェントバージョン",
        total: "合計",
        version: "バージョン {{version}}",
        older: "古い",
        tableTitle: "利用バージョン上位",
        devices: "{{value}} デバイス",
        devices_plural: "{{value}} デバイス"
      },
      categories: {
        ztna: "ゼロトラストアクセス",
        security: "セキュリティ",
        webFilter: "ウェブフィルター"
      },
      filterTooltip: "クリックしてフィルターを作成"
    }
  }
} as const;