import React from "react";
import {Typography} from "@barracuda-internal/bds-core";
import {
    ChipArrayField,
    getArrayDataContent,
    SelectedChoiceField,
    TextField,
    useCrudProps,
    WizardSummary
} from "@cuda-react/core";
import {makeStyles} from "@mui/styles";
import {useTranslation} from "react-i18next";
import {get} from "lodash";
import apiResources from "../../../../../apiResources";
import {useFormState} from "react-hook-form";
import {SELECT_EMPTY_VALUE} from "../../../../../utils/constants";

interface SiteSubmitStepProps {
    applianceType?: "site" | "gateway";
    data?: object;
    series?: string;
}

const useStyles = makeStyles((theme) => ({
    summaryName: {
        display: "inline-block",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: 200
    },
    summaryChipArray: {
        maxWidth: 220
    },
    submitError: {
        paddingLeft: 0,
        paddingTop: "8px"
    }
}));

const renderWanLanName = (type: string) => (value: any, data: object) => {
    if (value) {
        const matchingWanLan = get(data, type + "s", [])
            .findIndex((wanLan: any) => wanLan.mode === value.mode && wanLan.port === value.port && wanLan.virtualLanId === value.virtualLanId && wanLan.name === value.name);
        if ((matchingWanLan >= 0 && matchingWanLan < get(data, type + "Total", 0))) {
            return value.name;
        }
    }
    return [];
};

const SiteSubmitStep: React.FC<SiteSubmitStepProps> = ({applianceType, data, series}) => {
    const gatewayResource = applianceType === "gateway" ? apiResources.gatewaysOnPremUpstream : apiResources.gatewayNames;
    const gatewaysData = getArrayDataContent(useCrudProps(gatewayResource)[0]?.data);
    const gatewayChoices = [
        {
            key: SELECT_EMPTY_VALUE,
            name: "tesseract.appliances.dialog.submit.noGateway"
        },
        ...gatewaysData
    ];
    const timezonesData = getArrayDataContent(useCrudProps(apiResources.siteTimezones)[0]?.data);
    const [translate] = useTranslation();
    const classes = useStyles();
    const isSc = series === "S";

    const {errors} = useFormState();
    const lanErrors = errors?.lans?.message;
    const wanErrors = errors?.wans?.message;

    return (
        <React.Fragment>
            <Typography>
                {translate("tesseract.appliances.dialog.submit.message", {context: applianceType})}
            </Typography>
            <WizardSummary columns={[{xs: 12, sm: 5}, {xs: 12, sm: 7}]} data={data}>
                <TextField
                    source="name"
                    label="tesseract.appliances.dialog.submit.name"
                    column={0}
                    className={classes.summaryName}
                />
                <ChipArrayField
                    source="serials"
                    render={(val) => val && val.serial}
                    label="tesseract.appliances.dialog.submit.serial"
                    column={0}
                    classes={{root: classes.summaryChipArray}}
                />
                <SelectedChoiceField
                    source={applianceType === "gateway" ? "upstream" : "gateway"}
                    label={applianceType === "gateway" ? "tesseract.appliances.dialog.submit.upstream_gateway" : "tesseract.appliances.dialog.submit.gateway"}
                    column={0}
                    choices={gatewayChoices}
                />
                {!isSc ? (
                    <SelectedChoiceField
                        source="timezone"
                        label="tesseract.appliances.dialog.submit.timezone"
                        column={0}
                        choices={timezonesData}
                    />
                ) : null}
                <ChipArrayField
                    source="wans"
                    render={renderWanLanName("wan")}
                    label="tesseract.appliances.dialog.submit.wan"
                    column={1}
                    classes={{root: classes.summaryChipArray}}
                />
                <ChipArrayField
                    source="lans"
                    render={renderWanLanName("lan")}
                    label="tesseract.appliances.dialog.submit.lan"
                    column={1}
                    classes={{root: classes.summaryChipArray}}
                />
                <TextField source="primaryDns" label="tesseract.appliances.dialog.submit.primaryDns" column={1}/>
                <TextField source="secondaryDns" label="tesseract.appliances.dialog.submit.secondaryDns" column={1}/>
            </WizardSummary>
            {lanErrors ? (
                <Typography color="error">
                    {lanErrors}
                </Typography>
            ) : null}
            {wanErrors ? (
                <Typography color="error">
                    {wanErrors}
                </Typography>
            ) : null}
        </React.Fragment>
    );
};

export default SiteSubmitStep;