import React from "react";
import {Button, Typography} from "@barracuda-internal/bds-core";
import {useTranslation} from "react-i18next";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import {Theme} from "@mui/material";
import {createStyles} from "@mui/styles";
import {AuthEventType} from "../../providers";
import {useAuthEvents} from "../../../hooks";

export const styles = (theme: Theme) => createStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
        // @ts-ignore Added by Cuda-react. Default (undefined), is fine
        backgroundColor: theme.palette.background.appbar,
        backgroundImage: "url('https://auth.barracudanetworks.com/img/bg_product-login.jpg')",
        backgroundSize: "cover",
        color: theme.palette.primary.contrastText
    },
    unauthorized: {
        fontSize: 32,
        color: theme.palette.primary.contrastText
    },
    unauthorizedMore: {
        paddingTop: "32px",
        fontSize: 16,
        color: theme.palette.primary.contrastText
    },
    logout: {
        marginTop: "32px"
    }
});

const useStyles = makeOverrideableStyles("BccUnauthorizedPage", styles);

export interface BccUnauthorizedPageProps extends StyledComponentProps<typeof styles> {
}

/**
 * Unauthorized user page to use alongside the BccRedirectAuthClient.
 *
 * This is the default page at '/#/unauthorized' when using CudaReactApp with bccAuthentication.
 * It renders a simple fullscreen page notifying the user of their lack of privileges.
 *
 * There are no props for this component.
 */
export const BccUnauthorizedPage = (props: BccUnauthorizedPageProps): JSX.Element => {
    const classes = useStyles(props);
    const [translate] = useTranslation();
    const postAuthEvent = useAuthEvents();

    return (
        <div className={classes.root} id="login-page">
            <Typography variant="h1" className={classes.unauthorized}>{translate("cuda.auth.unauthorized")}</Typography>
            <Typography variant="h4"
                        className={classes.unauthorizedMore}>{translate("cuda.auth.unauthorizedMore")}</Typography>
            <Button bdsType="interactiveEmphasis" size="small" onClick={() => postAuthEvent({type: AuthEventType.LOGOUT})}
                    variant="contained" className={classes.logout}>
                {translate("cuda.auth.logout")}
            </Button>
        </div>
    );
};

export default BccUnauthorizedPage;