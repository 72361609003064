import {SvgIcon, useTokens} from "@barracuda-internal/bds-core";
import React from "react";
import classNames from "classnames";

const LiveLogTwoWayIcon = ({className}: { className?: string }) => {
    const tokens = useTokens();
    return (
        <SvgIcon
            className={classNames(className, "cuda-react-icon")}
            id="cuda-icon-access-live-log-two-way"
            viewBox="0 0 60 60"
        >
            <polygon style={{fill: tokens.aliasesColorTextStaticNeutral, strokeWidth: 0}}
                     points="42.1 12.5 38.6 16 50 27.5 10.5 27.5 10 27.5 21.5 16 17.9 12.5 .4 30 17.9 47.5 21.4 44 10 32.5 10.5 32.5 50 32.5 38.5 44 42.1 47.5 59.6 30 42.1 12.5"/>
        </SvgIcon>
    );
};

export default LiveLogTwoWayIcon;