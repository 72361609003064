import {createStyles, makeStyles} from "@mui/styles";
import {Divider, Theme, Typography} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";
import {StyledComponentProps} from "@cuda-react/theme";

const styles = (theme: Theme) => createStyles({
    footerTextContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        gap: 5,
        paddingTop: 10,
        color: theme.palette.text.primary,
    },
    footerTextBold: {
        fontWeight: "bold",
        color: theme.palette.text.primary,
    }
});
const useStyles = makeStyles(styles);

export interface CardFooterProps extends StyledComponentProps<typeof styles> {
    /** translation key for the main footer message. Translation will be passed the "length" prop. */
    footerKey: string,
    /** total number of entries */
    total: number,
    /** Length of the displayed entries, passed to the footerKey translation message. */
    length: number
}

/** Footer section for use in a card, for describing the number of items displayed out of the total */
export const CardFooter = (props: CardFooterProps) => {
    const {footerKey, length, total} = props;
    const [translate] = useTranslation();
    const classes = useStyles(props);

    return (
        <>
            <Divider/>
            <div className={classes.footerTextContainer}>
                <Typography variant="caption" className={classes.footerTextBold}>
                    {translate(footerKey, {length})}
                </Typography>
                <Typography variant="caption">
                    {translate("stratosReporting.baseBarCard.total", {total})}
                </Typography>
            </div>
        </>
    );
};