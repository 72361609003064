import React, {useMemo, useState} from "react";
import {DialogBody, MenuButton, useCrudProps, useGlobalParam, useMixpanel} from "@cuda-react/core";
import apiResources from "../../../../../apiResources";
import {Box, Dialog} from "@barracuda-internal/bds-core";
import {IdentityProviderSAMLSetup} from "./IdentityProviderSAMLSetup";
import {CreatedIdentityProvider} from "../IdentityProviderTypes";
import useDemoApi from "../../../../../hooks/useDemoApi";

interface IdentityProviderMenuProps {
    data?: CreatedIdentityProvider;
}

export const IdentityProviderMenu = ({data: identityProvider}: IdentityProviderMenuProps) => {
    const registerAction = useMixpanel('Identity Providers', false);

    const isDemo = useGlobalParam("userData.mode")[0] === "demo";
    const testURL = useCrudProps(identityProvider?.id && !isDemo ? apiResources.identityProviderTestURL : undefined, {id: identityProvider?.id})[0].data?.testURL;
    const [showSAMLSetup, setShowSAMLSetup] = useState(false);

    const authUrl = useDemoApi(apiResources.identityProviderAuthURL, apiResources.demoAuthorizationURL);
    const authorizationURL = useCrudProps(identityProvider?.id ? authUrl : undefined, {id: identityProvider?.id})[0].data?.authorizationURL;

    const menuItems = useMemo(() => {
        const items = [{
            label: "tesseract.identity.identityProviders.menu.test",
            onClick: () => window.open(testURL, "_blank"),
            disabled: isDemo
        }];

        if (identityProvider?.type === 'entraid') {
            items.push({
                label: 'tesseract.identity.identityProviders.menu.grantPermissions',
                onClick: () => {
                    registerAction('Authorize Provider');
                    window.open(authorizationURL, '_blank');
                    return null;
                },
                disabled: isDemo
            });
        }

        if (identityProvider?.type === 'saml') {
            items.push({
                label: "tesseract.identity.identityProviders.menu.setupSaml",
                onClick: () => {
                    setShowSAMLSetup(true);
                    return null;
                },
                disabled: isDemo
            });
        }

        return items;
    }, [identityProvider, setShowSAMLSetup, testURL, isDemo, authorizationURL, registerAction]);

    return (
        <>
            <MenuButton
                anchorOrigin={{horizontal: "left", vertical: "bottom"}}
                transformOrigin={{horizontal: "left", vertical: "top"}}
                menuItems={menuItems}
                iconButton
            />
            {
                identityProvider?.type === "saml" ? (
                    <Dialog open={showSAMLSetup}>
                        <DialogBody title="tesseract.identity.identityProviders.menu.setupSaml" onClose={() => setShowSAMLSetup(false)}>
                            <Box p={3}>
                                <IdentityProviderSAMLSetup identityProvider={identityProvider as CreatedIdentityProvider} />
                            </Box>
                        </DialogBody>
                    </Dialog>
                ) : null
            }
        </>
    );
};