import React, {useMemo, useState} from "react";
import {
    ConnectedWizard,
    getArrayDataContent,
    InputStep,
    MultiStep,
    ResultStep,
    SubmitStep,
    useCrudProps,
    useGlobalParam,
    useMixpanel,
    validatePrivateIpv4,
} from "@cuda-react/core";
import {get, set} from "lodash";
import {useTranslation} from "react-i18next";
import {validateNames, validateNetworks,} from "../../../hooks/createEditSiteHooks";
import BasicStep from "./steps/common/BasicStep";
import SerialStep from "./steps/common/SerialStep";
import SelectLanWanLengthStep from "./steps/tvt/SelectLanWanLengthStep";
import WanStep from "./steps/tvt/WanStep";
import LanStep from "./steps/tvt/LanStep";
import AdvancedStep from "./steps/common/AdvancedStep";
import SiteSubmitStep from "./steps/common/SiteSubmitStep";
import SiteResultStep from "./steps/tvt/SiteResultStep";
import {makeStyles} from "@mui/styles";
import CreateTypeStep from "./steps/tvt/CreateTypeStep";
import SelectSiteStep from "./steps/promote/SelectSiteStep";
import PromoteSubmitStep from "./steps/promote/PromoteSubmitStep";
import PromoteResultStep from "./steps/promote/PromoteResultStep";
import apiResources from "../../../apiResources";
import {format} from "../edit/EditTVTContent";
import {SELECT_EMPTY_VALUE} from "../../../utils/constants";

const useStyles = makeStyles((theme) => ({
    root: {},
    dialogContent: {
        width: 850,
    },
    standardInputStep: {
        paddingLeft: "96px",
        paddingRight: "64px",
    },
    submitStep: {
        paddingLeft: "64px",
        paddingRight: "64px",
    },
}));

interface CreateTVTContentProps {
    onClose?: () => void;
    onSuccess?: (response: any) => void;
    title?: string;
    type?: "site" | "gateway";
    label?: string;
}

const CreateTVTContent: React.FC<CreateTVTContentProps> = ({
                                                               onSuccess,
                                                               title,
                                                               type,
                                                               ...wizardProps
                                                           }) => {
    const registerAction = useMixpanel(
        type === "gateway" ? "Gateways Create Gateway" : "Sites Create Site",
        "Start",
        type === "site" ? {type: "T"} : undefined
    );
    const [translate] = useTranslation();
    const classes = useStyles();
    const [virtualWanId] = useGlobalParam("filter.virtualWanId");
    const [wanTotal, setWanTotal] = useState(2);
    const [lanTotal, setLanTotal] = useState(1);
    const [createMode, setCreateMode] = useState<string | undefined>();
    const [siteId, setSiteId] = useState<any>();
    const promoteChoices = getArrayDataContent(useCrudProps(createMode === "promote" ? apiResources.sitePromotable : undefined)[0]?.data);
    const memoedWizardProps = useMemo(() => {
            const common = {
                onChange: (newValues: any) => {
                    const wanTotal = parseInt(get(newValues, "wanTotal", 2)) || 2;
                    const lanTotal = parseInt(get(newValues, "lanTotal", 1)) || 0;
                    setWanTotal(wanTotal > 0 ? wanTotal : 1);
                    setLanTotal(lanTotal > 0 ? lanTotal : 0);
                    setCreateMode(get(newValues, "createMode"));
                    setSiteId(get(newValues, "siteId"));
                },
                initialValues: {
                    wans: [
                        {port: "p4", wanPort: "p4", name: "ISP-Uplink-1"},
                        {name: "ISP-Uplink-2"},
                    ],
                    lans: [],
                    wanTotal: "2",
                    lanTotal: "1",
                },
                onSubmitSuccess: (response: any) => {
                    registerAction(
                        "Success",
                        createMode === "promote"
                            ? {
                                action: "promote",
                            }
                            : {
                                model: response.model,
                                hasUpstream: type === "gateway" ? !!response.gateway : "",
                                type: type === "site" ? "T" : "on-prem",
                                wansLength: response.wans?.length,
                                lansLength: response.lans?.length,
                                isHa: response.serials?.length > 1,
                                action: type === "gateway" ? "create" : "",
                            }
                    );
                    onSuccess?.(response);
                },
            };
            return createMode === "promote"
                ? {
                    ...common,
                    resource: apiResources.sitePromote,
                    submitParams: {id: siteId},
                }
                : {
                    ...common,
                    resource:
                        type === "site"
                            ? apiResources.siteConfiguration
                            : apiResources.gatewaysOnPrem,
                    formatRequestData: (data: any) =>
                        Object.assign({}, data, {
                            gateway:
                                data.gateway === SELECT_EMPTY_VALUE ? null : data.gateway,
                            upstream:
                                data.upstream === SELECT_EMPTY_VALUE ? null : data.upstream,
                            serials: data.serials.map((appliance: any) => appliance.serial),
                            wans: data.wans
                                .slice(0, data.wanTotal)
                                .map((wan: any) => ({
                                    ...wan,
                                    virtualLanId: wan.virtualLanId
                                        ? parseInt(wan.virtualLanId)
                                        : null,
                                    port: wan.mode !== "wwan" ? wan.port : undefined,
                                }))
                                .filter((wan: any) => wan.mode !== "bridge"),
                            lans: data.lans
                                .slice(0, data.lanTotal)
                                .map((lan: any) => ({
                                    ...lan,
                                    virtualLanId: lan.virtualLanId
                                        ? parseInt(lan.virtualLanId)
                                        : null,
                                    dhcp: get(lan, "dhcp.type")
                                        ? get(lan, "dhcp")
                                        : {type: "disabled"},
                                })),
                            bridges: data.wans
                                .slice(0, data.wanTotal)
                                .filter((wan: any) => wan.mode === "bridge")
                                .map((bridge: any) => ({
                                    ...bridge,
                                    name: bridge.name,
                                    type: "inline",
                                    lanPorts: bridge.lanPorts,
                                    wanPort: bridge.wanPort,
                                    address: bridge.address,
                                    netmask: bridge.netmask,
                                    gateway: bridge.gateway,
                                    provider: bridge.provider,
                                    dhcp: {
                                        type: "disabled",
                                    },
                                })),
                            virtualWan: virtualWanId,
                        }),
                    validate: (values: any = {}) => {
                        // Verify lan/wan/routes port usage and names
                        const errors: any = {};

                        const totalWans = parseInt(get(values, "wanTotal")) || 1;
                        const wanBridgeCount = get(values, "wans", []).slice(0, totalWans).filter((wan: any) => wan.mode === "bridge").length;
                        const totalLans = parseInt(get(values, "lanTotal", "1")) || 0;
                        const usedNetworks = validateNetworks(values, "wans", totalWans, translate, errors);
                        validateNetworks(values, "lans", totalLans, translate, errors, usedNetworks);
                        const usedNetworkNames = validateNames(values, "wans", totalWans, translate, errors);
                        validateNames(values, "lans", totalLans, translate, errors, usedNetworkNames);
                        validateNames(values, "routes", undefined, translate, errors);

                        if (totalLans < 1 && wanBridgeCount === 0) {
                            set(errors, "lanTotal", translate("tesseract.appliances.dialog.validation.noLans"));
                        }

                        get(values, "wans", []).map((wan: any, index: number) => ({...wan, index}))
                            .filter(({mode}: any) => mode === "pppoe")
                            .slice(4)
                            .forEach(({index}: any) => set(errors, `wans[${index}].mode`, translate("tesseract.appliances.dialog.validation.exceededPPoE")));

                        if (wanBridgeCount > 1) {
                            values.wans.forEach((wan: any, index: number) => {
                                if (wan.mode === "bridge") {
                                    set(errors, `wans[${index}].mode`, translate("tesseract.appliances.dialog.validation.oneInlineBridge"));
                                }
                            });
                        }
                        // Verify secondary DNS is not set if there is no primary
                        values.secondaryDns && !values.primaryDns && set(errors, "secondaryDns", translate("tesseract.appliances.dialog.validation.secondaryDns"));

                        // Verify first wan has a public ip (once set)
                        if (get(values, "wans[0].address")) {
                            const privateIp = !validatePrivateIpv4(get(values, "wans[0].address"), values, {t: translate});
                            type === "gateway" && get(values, "wans[0].address") && privateIp && set(errors, "wans[0].address", translate("tesseract.appliances.dialog.validation.publicIp"));
                        }

                        // Return errors
                        return errors;
                    },
                    formatError: format.error,
                };
        },
        [createMode, siteId]
    );
    return (
        <ConnectedWizard
            title={title}
            dialogBodyProps={{classes: {dialogContent: classes.dialogContent}}}
            {...memoedWizardProps}
            {...wizardProps}
        >
            {type === "gateway" ? (
                <InputStep label="tesseract.appliances.dialog.createType.sectionTitle">
                    <CreateTypeStep/>
                </InputStep>
            ) : null}
            {createMode === "promote" ? (
                <InputStep
                    key="siteSelect"
                    label="tesseract.appliances.dialog.siteSelection.sectionTitle"
                    classes={{content: classes.standardInputStep}}
                >
                    <SelectSiteStep choices={promoteChoices}/>
                </InputStep>
            ) : null}
            {createMode === "promote" ? (
                <SubmitStep
                    key="promoteSubmit"
                    label="tesseract.appliances.dialog.steps.submit"
                    classes={{content: classes.submitStep}}
                >
                    <PromoteSubmitStep choices={promoteChoices}/>
                </SubmitStep>
            ) : null}
            {createMode === "promote" ? (
                <ResultStep
                    key="promoteResult"
                    label="tesseract.appliances.dialog.steps.result"
                    onClose={wizardProps.onClose}
                >
                    <PromoteResultStep/>
                </ResultStep>
            ) : null}
            {createMode !== "promote" ? (
                <InputStep
                    key="basic"
                    label="tesseract.appliances.dialog.basic.sectionTitle"
                    classes={{content: classes.standardInputStep}}
                >
                    <BasicStep applianceType={type}/>
                </InputStep>
            ) : null}
            {createMode !== "promote" ? (
                <InputStep
                    key="serial"
                    label={translate(
                        "tesseract.appliances.dialog.serials.sectionTitle",
                        {context: type}
                    )}
                >
                    <SerialStep
                        allowHa
                        series="T"
                        mainMessage={
                            type === "gateway"
                                ? "tesseract.appliances.dialog.serials.gatewayMessage"
                                : "tesseract.appliances.dialog.serials.TVTSiteMessage"
                        }
                    />
                </InputStep>
            ) : null}
            {createMode !== "promote" ? (
                <MultiStep
                    key="wan"
                    label="tesseract.appliances.dialog.wans.sectionTitle"
                >
                    {[
                        <InputStep key="mainInput" classes={{content: classes.standardInputStep}}>
                            <SelectLanWanLengthStep
                                type="wans"
                                applianceType={type}
                            />
                        </InputStep>,
                        ...Array(Math.min(wanTotal, 16)).fill(null).map((value, index) => (
                            <InputStep
                                key={index}
                                classes={{content: classes.standardInputStep}}
                            >
                                <WanStep
                                    stepIndex={index}
                                    series="T"
                                    applianceType={type}
                                />
                            </InputStep>
                        ))
                    ]}
                </MultiStep>
            ) : null}
            {createMode !== "promote" ? (
                <MultiStep
                    key="lan"
                    label="tesseract.appliances.dialog.lans.sectionTitle"
                >
                    {[
                        <InputStep key="mainInput" classes={{content: classes.standardInputStep}}>
                            <SelectLanWanLengthStep
                                type="lans"
                                applianceType={type}
                            />
                        </InputStep>,
                        ...Array(Math.min(lanTotal, 16)).fill(null).map((value, index) => (
                            <InputStep
                                key={index}
                                classes={{content: classes.standardInputStep}}
                            >
                                <LanStep stepIndex={index}/>
                            </InputStep>
                        ))
                    ]}
                </MultiStep>
            ) : null}
            {createMode !== "promote" ? (
                <InputStep
                    key="advanced"
                    label="tesseract.appliances.dialog.advanced.sectionTitle"
                    classes={{content: classes.standardInputStep}}
                >
                    <AdvancedStep applianceType={type}/>
                </InputStep>
            ) : null}
            {createMode !== "promote" ? (
                <SubmitStep
                    key="submit"
                    label="tesseract.appliances.dialog.steps.submit"
                    classes={{content: classes.submitStep}}
                >
                    <SiteSubmitStep applianceType={type}/>
                </SubmitStep>
            ) : null}
            {createMode !== "promote" ? (
                <ResultStep
                    key="result"
                    label="tesseract.appliances.dialog.steps.result"
                    onClose={wizardProps.onClose}
                >
                    <SiteResultStep applianceType={type}/>
                </ResultStep>
            ) : null}
        </ConnectedWizard>
    );
};
export default CreateTVTContent;