import React, {ReactElement, useCallback, useMemo, useState} from "react";
import {get} from "lodash";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {TextField} from "../../components/fields";
import {useDeepCompareCallback, useDeepCompareMemoize} from "../UtilHooks";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import {createStyles} from "@mui/styles";
import {CellRender} from "../../utils/commonTypes";

type DataItem = {
    value: any,
    items?: any[]
    "cuda-connected-table-footer"?: any
};
const groupData = (data: DataItem[], field: string, expanded: object, allGroupsExpanded?: boolean) => {
    const groupedData = data ? data.reduce(({groups, footers}, row) => {
        if (row?.["cuda-connected-table-footer"]) {
            return {groups, footers: [...footers, row]};
        }
        const rowGroupValue = get(row, field);
        const existingGroupIndex = groups.findIndex((group: DataItem) => group.value === rowGroupValue);
        const newGroupIndex = existingGroupIndex >= 0 ? existingGroupIndex : groups.length;
        const group = existingGroupIndex >= 0 ? groups[existingGroupIndex] : {
            field,
            value: rowGroupValue,
            items: [] as any[],
            expanded: get(expanded, rowGroupValue, allGroupsExpanded || newGroupIndex === 0)
        };
        group.items?.push(row);

        return {groups: [...groups.slice(0, newGroupIndex), group, ...groups.slice(newGroupIndex + 1)], footers};
    }, {groups: [] as DataItem[], footers: [] as DataItem[]}) : {groups: [], footers: []};

    return [...groupedData.groups.map((group) => ({
        ...group,
        items: group.items?.map((item) => ({...item, groupTotal: group.items?.length}))
    })), ...groupedData.footers];
};

const styles = createStyles({
    expandRow: {
        cursor: "pointer"
    },
    expandGroupIcon: {
        position: "relative",
        top: 7,
        left: -4,
        transform: "rotate(-90deg)",
        "& > svg": {
            transform: "none"
        },
        height: 24,
        width: 24
    },
    shrinkGroupIcon: {
        position: "relative",
        top: 7,
        left: -4,
        height: 24,
        width: 24
    },
});
const useGroupsStyles = makeOverrideableStyles("DataTableGroups", styles);

export interface useDataTableGroupsProps extends StyledComponentProps<typeof styles> {
    /**
     * if true, all groups will initially render expanded, otherwise only the first group will be expanded.
     */
    allGroupsExpanded?: boolean,
    /**
     * a dot-notation path to the property in each row's data to use when grouping.
     */
    groupBy?: string,
    /**
     * a custom component to use when rendering the group title. All *Field components will work seamlessly here.
     */
    groupField?: ReactElement
}

interface useDataTableGroupsReturn {
    cellRender: CellRender
    data: object[],
    expandField: "expanded",
    group: { field: string }[] | undefined,
    onExpandChange: ((event: any) => void) | undefined,
}

/**
 * Hook for providing grouping functionality to a BDS DataTable (or other tables based on it, such as Table and ConnectedTable).
 *
 * If you already make use of "cellRender", you should instead provide your method to the returned cellRender function as the third argument. I.e:
 * @example
 * cellRender={(cell, cellProps) => dataTableSelectProps.cellRender(cell, cellProps, yourRenderMethod)}
 */
export const useDataTableGroups = (
    data: any[],
    props: useDataTableGroupsProps
): useDataTableGroupsReturn => {
    const {groupBy, groupField, allGroupsExpanded} = props;
    const classes = useGroupsStyles(props);
    const [expanded, setExpanded] = useState({});
    const onExpandChange = useCallback((event) => {
        const groupValue = get(event, "dataItem.value");
        if (groupValue) {
            setExpanded((currentExpanded) => ({...currentExpanded, [groupValue]: event.value}));
        }
    }, []);
    const group = useMemo(() => [{field: groupBy}], [groupBy]);
    const memoizedData = useDeepCompareMemoize(groupBy ? groupData(data, groupBy, expanded, allGroupsExpanded) : data);
    const cellRender = useDeepCompareCallback((cell, cellProps, additionalRender) => {
        const {columnIndex, dataIndex, dataItem} = cellProps;
        if (groupBy && dataIndex === -1) {
            const expandedState = get(dataItem, "expanded", true);

            return cell && React.cloneElement(cell, {
                onClick: (event) => {
                    // @ts-ignore I was unable to figure out where "closest" comes from
                    const isGroup = event?.target?.closest?.(".k-grouping-row");

                    isGroup && setExpanded((currentExpanded) => ({
                        ...currentExpanded,
                        [get(dataItem, "value")]: !expandedState
                    }));
                },
                className: classes.expandRow
            }, /* @ts-ignore more issues related to children of CellRender */ (
                <span>
                    <ArrowDropDownIcon className={expandedState ? classes.shrinkGroupIcon : classes.expandGroupIcon}/>
                    {React.cloneElement(groupField || <TextField/>, {
                        data: dataItem,
                        source: "value",
                        value: dataItem.value
                    })}
                </span>
            ));
        }
        if (groupBy && columnIndex === 0) {
            return cell;
        }
        return additionalRender ? additionalRender(cell, cellProps) : cell;
    }, [groupBy, groupField]);

    return {
        onExpandChange: groupBy ? onExpandChange : undefined,
        expandField: "expanded",
        group: groupBy ? group as { field: string }[] : undefined,
        data: memoizedData,
        cellRender
    };
};