import {get, set} from "lodash";
import React from "react";
import {useTranslation} from "react-i18next";
import {useChoices, useDeepCompareMemo} from "../../../hooks";
import AutocompleteSearch from "../../search/AutocompleteSearch/AutocompleteSearch";
import ConnectedAutocompleteSearch from "../../search/ConnectedAutocompleteSearch/ConnectedAutocompleteSearch";
import classNames from "classnames";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import {Theme} from "@mui/material";
import {createStyles} from "@mui/styles";
import {Chip, CircularProgress, FormControl, Tooltip} from "@barracuda-internal/bds-core";

interface BaseSelectArrayProps {
    /**
     * class name for the component.
     * */
    className?: string,
    /**
     * if true, input gets disabled
     */
    disabled?: boolean,
    /**
     * error message to display.
     * If array of strings, each entry will be displayed as error.
     */
    error?: any,
    /**
     * key on the list of choices to filter by.
     */
    filterKey?: string,
    /**
     * icons to display on each chip.
     */
    icon?: React.ReactNode,
    /**
     * a map of icons to display inside of each chip. The "optionIcon" value of each choice is used to identify the icon from this map.
     */
    iconMap?: { [key: string]: React.ReactNode },
    /**
     * id of the component for unique identification. This value is prefixed with "select-array-input-".
     * provided automatically when component is rendered inside a [Input](/?path=/docs/core-components-inputs-input) component.
     */
    id: string,
    /**
     * label for the input.
     * Passed to the AutocompleteSearch/ConnectedAutocompleteSearch component.
     */
    label?: string,
    /**
     * max number of characters for each chip.
     */
    maxChipLength?: number,
    /**
     * max number of selected chips. Input will get disabled in case this number is hit.
     */
    maxInputs?: number,
    /**
     * callback to called when component stops being interacted with.
     * @function onBlur
     */
    onBlur?: (value: string[]) => void,
    /**
     * callback to call when the input value has been changed. Usually provided by Input component
     * @function onChange
     */
    onChange?: (value: string[]) => void,
    /**
     * callback to called when input get focus.
     * @function
     */
    onFocus?: () => void,
    /**
     * dot-notation path to the property in each choice object that defines the icon to use from 'iconMaps'.
     */
    optionIcon?: string,
    /**
     * a dot-notation path to the value in the choice objects that should be displayed in the label of each chip.
     */
    optionText?: string,
    /**
     * a dot-notation path to the value in the choice objects that should be used as the identifying "value" when it is selected.
     */
    optionValue?: string,
    /**
     * placeholder text to display in the dropdown.
     */
    placeholder?: string,
    /**
     * if true, new choice will be added with the label provided by the prop selectAllText ("all" in case is not provided) allowing users to select all choices.
     * The value of that choice will be provided by selectAllValue prop.
     */
    selectAll?: boolean,
    /**
     * text for the select all choice.
     */
    selectAllText?: string,
    /**
     * value for the select all choice.
     */
    selectAllValue?: string,
    /**
     * the content to render for all chips where the value does not match any of the given choices.
     */
    unknownChipContent?: string | React.ReactNode,
    /**
     * current value of the input.
     */
    value?: string | string[],
    /**
     * the [CRUD](/?path=/docs/key-concepts-crud) resource to fetch/update data from.
     */
    resource?: string,
    /**
     * array of choices to select from
     */
    choices?: any[],
    /**
     * dot-notation path to the property in each choice object that defines the tooltip string to display OR a method
     * that takes the current valuye and data and returns the tooltip string for each chip
     */
    optionTooltip?: string | ((choice: any) => string | undefined),
}

export const styles = (theme: Theme) => createStyles<string, BaseSelectArrayProps>({
    selectArray: {
        width: (props) => get(props, "options.fullWidth") ? "100%" : 256
    },
    chip: {
        margin: "2px",
        pointerEvents: "all"
    },
    chipIcon: {
        margin: "0 !important",
        height: 24
    },
    chipContainer: {
        maxWidth: "100%",
        marginBottom: "4px"
    },
    chipError: {
        backgroundColor: theme.palette.error.main
    },
    chipUnknown: {
        color: theme.palette.error.main,
        marginLeft: "12px"
    }
});


const useStyles = makeOverrideableStyles("SelectArray", styles);

interface ConnectedSelectArrayProps extends StyledComponentProps<typeof styles>, BaseSelectArrayProps {
    resource?: string,
    /**
     * this is passed to the ConnectedAutocompleteSearch component.
     */
    options?: Partial<React.ComponentProps<typeof ConnectedAutocompleteSearch>>,
}

interface OfflineSelectArrayProps extends StyledComponentProps<typeof styles>, BaseSelectArrayProps {
    resource: undefined,
    /**
     * this is passed to the ConnectedAutocompleteSearch component.
     */
    options?: Partial<React.ComponentProps<typeof AutocompleteSearch>>,
}

export type SelectArrayProps = OfflineSelectArrayProps | ConnectedSelectArrayProps;

/**
 * Renders a ConnectedAutocompleteSearch/AutocompleteSearch component from where user can choose a list of elements. Each selected element will get render as a chip.
 * The components is wrapped within a FormControl component.
 */
export const SelectArray = (props: SelectArrayProps) => {
    const {
        choices = [],
        disabled,
        error,
        filterKey,
        icon,
        iconMap,
        label,
        maxChipLength = 30,
        maxInputs,
        onChange,
        optionIcon,
        optionText = "name",
        optionValue = "key",
        optionTooltip,
        options,
        placeholder = "cuda.inputs.selectArray.typeToSearch",
        resource,
        selectAll,
        selectAllText = "cuda.inputs.selectArray.all",
        selectAllValue = "*",
        id,
        value = [],
        unknownChipContent = "cuda.inputs.selectArray.unknownChipText"
    } = props;
    const [translate] = useTranslation();
    const getChoiceName = (choice: any) => {
        const name = choice && get(choice, optionText) || "";
        return translate(name);
    };
    const classes = useStyles(props);
    const getChoiceId = (choice: any) => choice && get(choice, optionValue) || "";
    const selectAllChoice = selectAll && set(set({}, optionValue, selectAllValue), optionText, selectAllText);
    const unknownChipValue = typeof unknownChipContent === "string" ? translate(unknownChipContent) : unknownChipContent;
    // "emptyValue" needs regenerating everytime the value changes, in order to empty the text field.
    // Otherwise it should a static empty object, to prevent the text entry from clearing itself on every re-render.
    const emptyValue = useDeepCompareMemo(() => ({}), [value]);

    const currentValue = Array.isArray(value) ? value : (value && [value] || []);

    const addChip = (choice: any) => {
        if (!choice) {
            return;
        }

        const choiceId = getChoiceId(choice);
        const newChips = currentValue ? [...currentValue].concat([choiceId]) : [choiceId];
        if (selectAll && newChips.includes(selectAllValue)) {
            onChange && onChange([choiceId]);
        } else {
            const uniqueChips = [...new Set(newChips)];
            onChange && onChange(uniqueChips);
        }
    };

    const [chipsToShow, addChipToShow, loading] = useChoices(
        currentValue,
        {
            choices: choices && [...(selectAllChoice ? [selectAllChoice] : []), ...choices],
            filterKey: filterKey || optionValue,
            optionValue,
            onAdd: addChip,
            resource,
            params: {
                pagination: {
                    page: 1,
                    perPage: currentValue.length
                }
            }
        }
    );

    const handleRequestDelete = (choiceId: string) => {
        const newValue = currentValue ? currentValue.filter((val: string) => val !== choiceId) : [];
        onChange && onChange(newValue);
    };

    const searchProps = {
        onChange: addChipToShow,
        optionText,
        id: id && "select-array-input-" + id,
        disabled: disabled || !!(currentValue && maxInputs && currentValue.length >= maxInputs),
        iconMap,
        icon,
        optionIcon,
        noSearchIcon: true,
        placeholder,
        label,
        ...options
    };

    return (
        <FormControl className={classes.selectArray} id="select-array-root">
            <div className={classes.chipContainer}>
                {chipsToShow.map((choice, index) => {
                    const choiceId = getChoiceId(choice);
                    const tooltip = typeof optionTooltip === "function" ? optionTooltip(choice) : get(choice, optionTooltip || "");
                    const chipIcon = iconMap && optionIcon && iconMap[get(choice, optionIcon)] || icon;
                    const specificError = error && Array.isArray(error) && error[index];
                    const val = getChoiceName(choice);
                    const valueLabel = val || (loading ? (
                        <CircularProgress size={16}/>
                    ) : (
                        <span className={classes.chipUnknown}>
                            {unknownChipValue}
                        </span>
                    ));
                    return (
                        <Tooltip
                            key={choiceId}
                            title={translate(tooltip)}
                            placement="top"
                            arrow
                        >
                            <Chip
                                onDelete={disabled ? undefined : () => handleRequestDelete(choiceId)}
                                className={classNames(classes.chip, specificError && classes.chipError)}
                                disabled={disabled}
                                label={typeof val === "string" && val.length > maxChipLength ? val.substring(0, maxChipLength - 3) + "..." : valueLabel}
                                startIcon={chipIcon ? (
                                    <span className={classes.chipIcon}>
                                        {chipIcon}
                                    </span>
                                ) : undefined}
                            />
                        </Tooltip>
                    );
                })
                }
            </div>
            {resource ? (
                <ConnectedAutocompleteSearch
                    resource={resource}
                    error={error || undefined}
                    filterChoices={(choice) => !currentValue.includes(getChoiceId(choice))}
                    staticChoices={selectAllChoice ? [selectAllChoice] : []}
                    totalText="cuda.inputs.selectArray.showing"
                    clearOnFocus
                    {...searchProps}
                />
            ) : (
                <AutocompleteSearch
                    //Set currentValue to empty object to disable displaying the last selected currentValue
                    value={emptyValue}
                    filterSelectedOptions
                    choices={
                        choices &&
                        [...(selectAllChoice ? [selectAllChoice] : []), ...choices]
                            .filter((choice) => !currentValue.includes(getChoiceId(choice)))
                    }
                    error={error || undefined}
                    {...searchProps}
                />
            )}
        </FormControl>
    );
};

export default SelectArray;