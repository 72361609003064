import {get} from "lodash";

const baseUnleashConfig = {
    refreshInterval: 300,
    appName: 'secure-edge',
};
export const unleashConfigs = {
    production: {
        ...baseUnleashConfig,
        environment: 'production',
        url: 'https://api.unleash.cudasvc.com/api/frontend/',
        clientKey: "default:production.835574055e1eee1e75b7b1f890fac40dd0d84c1464d33f316fe170ac"
    },
    staging: {
        ...baseUnleashConfig,
        environment: 'development',
        url: 'https://api.unleash.cudasvc.com/api/frontend/',
        clientKey: "default:development.e4621fe366a756a7c38868a87a2d21bd9d25db93a648745fb98246cb"
    },
    qa: {
        ...baseUnleashConfig,
        environment: 'production',
        url: 'https://api.unleash.qa.cudaops.com/api/frontend/',
        clientKey: "default:production.47adf62344f1f119e23d32ef536f11c42a74788b2b005ec4ddbaf2e5"
    },
    dev: {
        ...baseUnleashConfig,
        environment: 'development',
        url: 'https://api.unleash.qa.cudaops.com/api/frontend/',
        clientKey: "default:development.624843de56a35a0e827e5717546058375121ebffb87858fdb00846f4"
    }
};

const getUserIdentity = (globalParams: any | undefined) => get(globalParams, "userData.mode") === "demo" ? null : get(globalParams, "userData.currentUser");
const baseMixpanelConfig = {
    identifier: getUserIdentity,
    appPrefix: (globalParams: any | undefined) => get(globalParams, "userData.mode") === "demo" ? "CGW-Demo" : "CGW"
};
export const mixpanelConfigs = {
    production: {
        ...baseMixpanelConfig,
        token: "1320f2411f85edb0c4282e94ff8abaf8"
    },
    nonProduction: {
        ...baseMixpanelConfig,
        token: "063c67642e59ec6bca5cb0da7c588b8b"
    }
};

export default () => {
    const domain = window.location.hostname;
    if (/^(se|secureedge|se-demo|secureedge-demo)\.barracudanetworks\.com$/.test(domain)) {
        return {
            unleashConfig: unleashConfigs.production,
            mixpanelConfig: mixpanelConfigs.production
        };
    }
    if (/^(se|secureedge|se-demo|secureedge-demo)(-staging\.qa|\.staging)\.cudaops\.com$/.test(domain)) {
        return {
            unleashConfig: unleashConfigs.staging,
            mixpanelConfig: mixpanelConfigs.nonProduction
        };
    }
    if (/^(se|secureedge|se-demo|secureedge-demo)\.qa\.cudaops\.com$/.test(domain)) {
        return {
            unleashConfig: unleashConfigs.qa,
            mixpanelConfig: mixpanelConfigs.nonProduction
        };
    }
    return {
        unleashConfig: unleashConfigs.dev,
        mixpanelConfig: mixpanelConfigs.nonProduction
    };
};