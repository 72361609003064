import {Account, useAccounts, useDeepCompareEffect, useGlobalParam, Workspace} from "@cuda-react/core";
import apiResources from "../mainApiResources";
import {useHistory, useLocation} from "react-router-dom";
import {useEffect} from "react";
import {useTranslation} from "react-i18next";

const pathRegexTest = /^\/([0-9]+)\/([0-9a-fA-F-]+)\/(.*)$/;

export default (isSubscribed: boolean) => {
    // Get current account/workspace from location
    const {pathname, search, hash} = useLocation();
    const {replace} = useHistory();
    const locationPath = pathRegexTest.exec(pathname);
    const locationAccount = locationPath?.[1];
    const locationWorkspace = locationPath?.[2];

    // Load current and available accounts
    const [accounts, currentAccount, defaultAccount, swapAccount, refreshAccounts, loadingAccounts] = useAccounts(
        isSubscribed ? apiResources.accountListWithVirtualWans : undefined,
        true)
    ;
    const availableAccounts = accounts.map((account) => ({
        ...account,
        virtualWans: account.virtualWans?.length ? account.virtualWans : []
    }));

    // Load current and available workspaces
    const [translate] = useTranslation();
    const noWorkspace = {name: translate("stratos.accounts.noWorkspace")};
    const workspaceChoices: Array<Workspace> = availableAccounts.find((account) => currentAccount.id === account.id)?.virtualWans ?? [];
    const [workspaceId, setWorkspaceId] = useGlobalParam("filter.virtualWanId");
    const [currentWorkspaceUuid, setCurrentWorkspaceUuid] = useGlobalParam("userData.currentWorkspace");
    const currentWorkspace: Workspace = workspaceChoices.find((choice: Workspace) => choice.id === workspaceId) || noWorkspace;

    //Method to swap workspace/account
    const changeWorkspace = (account: Account, workspace?: Workspace) => {
        let newPath = pathname;
        if (account.id !== locationAccount) {
            newPath = newPath.replace(`/${locationAccount}`,`/${account.id}`);
        }
        if (workspace) {
            newPath = newPath.replace(`/${locationWorkspace}`, `/${workspace?.uuid}`);
        }
        locationPath && replace(`${newPath}${search}${hash}`);
    };

    // Make local references to account/workspace match the current URL
    useDeepCompareEffect(() => {
        if (loadingAccounts) return;
        if (availableAccounts.length && locationAccount && currentAccount.id !== locationAccount) {
            const targetAccount = availableAccounts.find(({id}) => id === locationAccount);
            if (targetAccount) {
                swapAccount(targetAccount);
            } else {
                // no matching account, swap to first account/workspace
                swapAccount(availableAccounts[0]);
                changeWorkspace(availableAccounts[0], availableAccounts[0]?.virtualWans?.[0]);
            }
        } else if (!locationAccount && !currentAccount.id && availableAccounts.length) {
            // no account in url or local storage, so open first account
            swapAccount(availableAccounts[0]);
            changeWorkspace(availableAccounts[0], availableAccounts[0]?.virtualWans?.[0]);
        } else if (workspaceChoices.length && locationWorkspace && currentWorkspaceUuid !== locationWorkspace) {
            // Try to find and set workspace to match current location
            const targetWorkspace = workspaceChoices.find(({uuid}) => uuid === locationWorkspace);
            if (targetWorkspace) {
                setCurrentWorkspaceUuid(targetWorkspace.uuid);
                setWorkspaceId(targetWorkspace.id);
            } else {
                setCurrentWorkspaceUuid(workspaceChoices[0].uuid);
                setWorkspaceId(workspaceChoices[0].id);
                changeWorkspace(currentAccount, workspaceChoices[0]);
            }
        } else if (!locationWorkspace && !currentWorkspaceUuid && workspaceChoices.length) {
            // no workspace in url or local storage, so open first workspace
            setCurrentWorkspaceUuid(workspaceChoices[0].uuid);
            setWorkspaceId(workspaceChoices[0].id);
            changeWorkspace(currentAccount, workspaceChoices[0]);
        }
    }, [locationAccount, locationWorkspace, workspaceChoices, currentAccount.id, workspaceId, currentWorkspaceUuid, loadingAccounts]);

    // Ensure page title is kept inline with account/workspace
    const [, setAccountTitle] = useGlobalParam("pageTitle.account");
    useDeepCompareEffect(() => {
        setAccountTitle(currentWorkspace?.id ? [currentAccount?.name, currentWorkspace.name] : currentAccount?.name);
    }, [currentAccount, currentWorkspace]);

    // Refresh accounts on subscribe state change
    useEffect(() => {
        refreshAccounts();
    }, [isSubscribed]);

    return {
        availableAccounts,
        changeWorkspace,
        currentAccount,
        defaultAccount,
        refreshAccounts,
        swapAccount,
        workspaceChoices,
        currentWorkspace
    };
};