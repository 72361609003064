import {CudaReactLayout, useGlobalParam} from "@cuda-react/core";
import SwapTenantIcon from "./components/SwapTenantOption/SwapTenantIcon";
import SwapTenantOption from "./components/SwapTenantOption/SwapTenantOption";
import CustomLogoutMenu from "./components/CustomLogoutMenu";
import CustomUnauthorizedPage from "./components/CustomUnauthorizedPage";
import Footer from "./components/Footer";
import {Route} from "react-router-dom";
import SubscriptionPage from "./components/subscription/SubscriptionPage";
import ExpiredPage from "./components/subscription/ExpiredPage";
import DeprecatedPage from "./components/subscription/DeprecatedPage";
import * as React from "react";
import {useMemo, useEffect} from "react";
import {useNavigationRoutes} from "./routing";
import {useUnleashContext} from '@unleash/proxy-client-react';
import WelcomePage from "./components/subscription/WelcomePage";
import GlobalStatusController from "./components/GlobalStatusController";
import MainPageRoutes from "./components/MainPageRoutes";

const AppContent = () => {
    const navigationEntries = useNavigationRoutes();
    const [userId] = useGlobalParam("userData.currentAccount");
    const updateContext = useUnleashContext();

    useEffect(() => {
        // context is updated with userId
        userId && updateContext({userId});
    }, [userId]);

    return (
        <CudaReactLayout
            title="stratos.title"
            additionalNavbarOptions={useMemo(() => [
                {
                    name: "stratos.accounts.tenants",
                    icon: <SwapTenantIcon/>,
                    menu: <SwapTenantOption/>,
                },
            ], [])}
            customLogoutMenu={<CustomLogoutMenu/>}
            customUnauthorizedPage={CustomUnauthorizedPage}
            footerItems={<Footer/>}
            fullPageRoutes={useMemo(() => [
                <Route key="welcome" exact path="/welcome" component={WelcomePage}/>,
                <Route
                    key="post_purchase"
                    exact
                    path="/post_purchase"
                    component={SubscriptionPage}
                />,
                <Route key="expired" exact path="/expired" component={ExpiredPage}/>,
                <Route
                    key="deprecated"
                    exact
                    path="/deprecated"
                    component={DeprecatedPage}
                />,
            ], [])}
            navigation={navigationEntries}
            nonRouteChildren={<GlobalStatusController/>}
            requiresAuthentication
        >
            <Route path="/:account?/:workspace?" component={MainPageRoutes}/>
        </CudaReactLayout>
    );
};

export default AppContent;