import React from "react";
import {
    TextInput,
    PasswordInput,
    validateMaxLength,
    validateRegexMemo,
    validateUrl
} from "@cuda-react/core";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    azureMonitorAgentForm: {
        marginBottom: "32px"
    }
}));

const AzureLogAnalyticsDaemon = () => {
    const classes = useStyles();
    const validateUuid = validateRegexMemo(/^([a-fA-F0-9]{8}-([a-fA-F0-9]{4}-){3}[a-fA-F0-9]{12})?$/, "tesseract.validation.uuid");

    return (
        <div className={classes.azureMonitorAgentForm}>
            <TextInput
                source="settings.azure_log_analytics.clientId"
                label="tesseract.settings.tabs.oms.clientId"
                validate={validateUuid}
                isRequired
                newStyle
                inputLabelProps={{nested: true}}
            />
            <PasswordInput
                source="settings.azure_log_analytics.clientSecret"
                label="tesseract.settings.tabs.oms.clientSecret"
                isRequired
                validate={validateMaxLength(255)}
                newStyle
                inputLabelProps={{nested: true}}
            />
            <TextInput
                source="settings.azure_log_analytics.tenantId"
                label="tesseract.settings.tabs.oms.tenantId"
                validate={validateUuid}
                isRequired
                newStyle
                inputLabelProps={{nested: true}}
            />
            <TextInput
                source="settings.azure_log_analytics.immutableId"
                label="tesseract.settings.tabs.oms.immutableId"
                validate={validateMaxLength(255)}
                isRequired
                newStyle
                inputLabelProps={{nested: true}}
            />
            <TextInput
                source="settings.azure_log_analytics.stream"
                label="tesseract.settings.tabs.oms.stream"
                validate={validateMaxLength(255)}
                isRequired
                newStyle
                inputLabelProps={{nested: true}}
            />
            <TextInput
                source="settings.azure_log_analytics.logsUrl"
                label="tesseract.settings.tabs.oms.logIngestionUrl"
                validate={[validateUrl, validateMaxLength(255)]}
                isRequired
                newStyle
                inputLabelProps={{nested: true}}
            />
        </div>
    );
};

export default AzureLogAnalyticsDaemon;