import React from "react";
import {SvgIcon, useTokens} from "@barracuda-internal/bds-core";
import classNames from "classnames";

export const Office365Icon = ({className}: { className?: string }) => {
    const tokens = useTokens();
    return (
        <SvgIcon
            className={classNames(className, "cuda-react-icon")}
            id="cuda-icon-office365"
            viewBox="0 0 60 60"
        >
            <g id="l4W0sz">
                <path style={{fill: tokens.aliasesColorTextStaticNeutral, strokeWidth: 0, fillRule: "evenodd"}}
                      d="M9.3,44.9c0-.5,0-.9,0-1.2,0-9.1,0-18.1,0-27.2,0-1.1.3-1.6,1.4-2,8.2-3,16.4-6.1,24.7-9.2.4-.1.9-.2,1.3-.1,4.7,1.4,9.4,2.7,14.2,4.1v41.5c-4.8,1.4-9.5,2.8-14.3,4.2-.3,0-.7,0-1.1,0-8.1-3-16.3-6-24.4-9-.2,0-.3-.2-.8-.4,8.8,1.2,17.2,2.4,25.8,3.6V12.7c-3.8.9-7.5,1.8-11.1,2.7-1.7.4-3.4.9-5.1,1.2-1.2.2-1.6.7-1.5,1.9,0,7.2,0,14.5,0,21.7,0,.9-.2,1.4-1.1,1.7-2.2.8-4.3,1.7-6.5,2.5-.4.1-.8.2-1.2.4Z"/>
            </g>
        </SvgIcon>
    );
};

export default Office365Icon;