import {Dashboard} from "../../../hooks/useDashboards";
import {DialogBody, Form, HiddenInput, SelectInput} from "@cuda-react/core";
import React from "react";
import {Typography} from "@barracuda-internal/bds-core";
import {useTranslation} from "react-i18next";
import {Theme} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";

export interface ResetDashboardDialogProps {
    /** Current dashboard (leave undefined for create). */
    dashboard?: Dashboard,
    /** callback called on save. */
    addUpdateDashboard: (newDashboardSettings: Dashboard, originalDashboardName?: string, reset?: boolean) => Promise<any>,
    /** callback called on cancel/dialog close/after saving */
    onClose?: () => void,
    /** templates to select for new dashboard. **/
    templates?: Dashboard[]
    /** not used for this component, but for setting the menu button entry label **/
    label?: string
    /**
     * boolean, if true it is a one tab dashboard. This means that there are no other default templates than the default one.
     */
    oneTabDashboard?: boolean
}

const styles = (theme: Theme) => createStyles({
    resetToDefaultMessage: {
        textAlign: "center",
        color: theme.palette.text.primary,
        margin: "16px"
    }
});
const useStyles = makeStyles(styles);

/**
 * Dialog content for the Create/Edit Dashboard dialog.
 */
export const ResetDashboardDialog = ({
        dashboard,
        addUpdateDashboard,
        onClose,
        templates,
        oneTabDashboard
    }: ResetDashboardDialogProps) => {
    const [translate] = useTranslation();
    const classes = useStyles();

        return (
            <DialogBody
                title={"stratosReporting.dashboardPage.reset"}
                onClose={onClose}
                form
            >
                <Form
                    initialValues={dashboard}
                    onCancel= {onClose}
                    save={
                        (newDashboard: any) => addUpdateDashboard(newDashboard, dashboard?.name, true)
                            .then((result) => {
                                onClose?.();
                                return result;
                            })
                    }
                >
                    {oneTabDashboard ?
                        <Typography className={classes.resetToDefaultMessage}>
                            {translate("stratosReporting.dashboardPage.resetToDefault")}
                        </Typography> : null}
                    {oneTabDashboard ?
                        <HiddenInput
                            source="template"
                            hiddenValue={templates && templates[0]?.name}
                        /> :
                        <>
                            <Typography className={classes.resetToDefaultMessage}>
                                {translate("stratosReporting.dashboardPage.resetToTemplateDescription")}
                            </Typography>
                            <SelectInput
                                source="template"
                                label="stratosReporting.dashboardPage.resetToTemplate"
                                choices={templates}
                                defaultChoice={dashboard?.template}
                                optionValue="name"
                            />
                        </>
                    }
                </Form>
            </DialogBody>
        );
};