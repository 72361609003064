import {
    ActionButtonsField,
    ConnectedTableRefresh,
    CrudTypes,
    CustomField,
    DeleteDialog,
    DisableableButton,
    EmptyPageContent,
    getArrayDataContent,
    getDataContent,
    LinkField,
    PropGateway,
    SelectedChoiceField,
    StatusIconField,
    TablePage,
    TextField,
    useCrudProps,
    useCrudSubscription,
    useGlobalParam,
    useMixpanel
} from "@cuda-react/core";
import {
    AzureIcon,
    EdgeServiceIcon,
    GetStartedBackgroundGraphic,
    PointOfPresenceGraphic,
    PrivateEdgeIcon
} from "@cuda-react/icons";
import React, {useRef, useState} from "react";
import CreateGatewayDialog from "./dialogs/CreateGatewayDialog";
import {getAzureHubLink, getAzureResourceLink} from "../../../utils/azureUtils";
import {get} from "lodash";
import {makeStyles} from "@mui/styles";
import {Forward} from "@barracuda-internal/bds-core/dist/Icons/Core";
import apiResources from "../../../apiResources";
import {useTranslation} from "react-i18next";
import SizeFilter, {SIZE_FILTER_CHOICE_KEY} from "../../../components/gateways/filters/SizeFilter";
import {useHistory} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    scaleUnitCell: {
        width: "10%"
    },
    gatewayIcon: {
        height: "1.2rem",
        width: "1.2rem",
        marginBottom: "-4px",
        paddingRight: "8px"
    }
}));

export const PRIVATE_CLOUD_FILTER_KEY = "private-cloud";
export const MANAGED_CLOUD_FILTER_KEY = "managed";


const GatewayTable = (props: any) => {
    const registerAction = useMixpanel("Gateways");
    const classes = useStyles();
    const [translate] = useTranslation();
    const statusChoices = useCrudProps(apiResources.gatewayStates)[0]?.data;
    const regionChoices = useCrudProps(apiResources.gatewayRegions)[0]?.data;
    const scaleUnitChoices = useCrudProps(apiResources.gatewayScaleUnits)[0]?.data;
    const bandwidthChoices = getArrayDataContent(useCrudProps(apiResources.managedGatewaysBandwidths)[0]);
    const [cloudVwanChoices] = useCrudSubscription(CrudTypes.GET, apiResources.cloudVwans);
    const isDemo = useGlobalParam("userData.mode")[0] === "demo";
    const [pointToSite] = useCrudSubscription(CrudTypes.GET, apiResources.pointToSite, {}, {crudOptions: {quietErrors: true}});
    const pointToSiteEnabled = get(getDataContent(pointToSite), "enabled", false);
    const {push} = useHistory();
    const tableRefreshRef = useRef<ConnectedTableRefresh>(null);
    const [virtualWanId] = useGlobalParam("filter.virtualWanId");
    const [regionsList, , refreshRegionsList] = useCrudProps(apiResources.managedGatewaysRegions, {virtualWanId});
    const isRegionAvailable = getArrayDataContent(regionsList).length > 0;
    const [actionVisible, setActionVisible] = useState(false);
    const [createMenu, setCreateMenu] = useState<"cloud" | "on-prem" | "managed" | null>(null);
    const [subscriptionSerial] = useGlobalParam("userData.subscription.serial");

    const getBandwidth = (model: any) => bandwidthChoices.find((item) => item.key === model)?.name;

    const renderSize = (scaleUnit: string, data: any) => {
        const type = get(data, "gatewayType");
        if (type === "cloud") {
            return (
                <SelectedChoiceField
                    data={data}
                    source="scaleUnit"
                    choices={getArrayDataContent(scaleUnitChoices)}
                />
            );
        }
        if (type === "managed") {
            return getBandwidth(get(data, "model"));
        }
        return get(data, "model") + (get(data, "serial", "").includes(",") ? ` ${translate("tesseract.gateways.table.HA")}` : "");
    };

    const getResource = (data: any) => {
        const type = get(data, "gatewayType");
        if (type === "cloud") {
            return apiResources.gateways;
        }
        if (type === "managed") {
            return apiResources.managedGateways;
        }
        return apiResources.gatewaysOnPrem;
    };

    const createButton = (
        <DisableableButton
            buttonText="tesseract.gateways.dialog.create.buttonText"
            menuItems={[
                {
                    label: "tesseract.gateways.dialog.create.managed.menuLabel",
                    onClick: () => setCreateMenu("managed"),
                    disabled: !isRegionAvailable
                },
                {
                    label: "tesseract.gateways.dialog.create.cloud.menuLabel",
                    onClick: () => setCreateMenu("cloud"),
                    disabled: !subscriptionSerial
                },
                {
                    label: "tesseract.gateways.dialog.create.onPrem.menuLabel",
                    onClick: () => setCreateMenu("on-prem")
                }
            ]}
        />
    );

    return (
        <>
            <TablePage
                titleActions={actionVisible && createButton}
                authenticated
                resource={apiResources.gateways}
                title="tesseract.network.pageTitle"
                subtitle="tesseract.network.gateways"
                minCellWidth={88}
                tableName="Gateways"
                resetOnGlobalParamChange
                refreshRef={tableRefreshRef}
                resizable
                reorderable
                editColumns
                noDataPage={
                    <EmptyPageContent
                        background={<GetStartedBackgroundGraphic/>}
                        icon={<PointOfPresenceGraphic/>}
                        iconHeader="tesseract.gateways.noData.iconHeader"
                        textHeader="tesseract.gateways.noData.textHeader"
                        text="tesseract.gateways.noData.text"
                        actions={createButton}
                    />
                }
                onPageStatusChange={(pageStatus) => setActionVisible(pageStatus === "table")}
                params={(params) => ({
                    ...params,
                    filter: {
                        ...params.filter,
                        cloudVirtualWanId: params.filter?.cloudVirtualWanId === PRIVATE_CLOUD_FILTER_KEY || params.filter?.cloudVirtualWanId === MANAGED_CLOUD_FILTER_KEY ? undefined : params.filter?.cloudVirtualWanId,
                        type: params.filter?.cloudVirtualWanId === PRIVATE_CLOUD_FILTER_KEY ? "on-prem" : params.filter?.cloudVirtualWanId === MANAGED_CLOUD_FILTER_KEY ? "managed" : undefined,
                        size: undefined,
                        scaleUnit: params.filter?.size?.[SIZE_FILTER_CHOICE_KEY] === "scaleUnit" ? params.filter?.size.scaleUnit : undefined,
                        model: params.filter?.size?.[SIZE_FILTER_CHOICE_KEY] === "model" ? params.filter?.size.model : params.filter?.size?.[SIZE_FILTER_CHOICE_KEY] === "bandwidth" ? params.filter?.size.bandwidth : undefined,
                    }
                })}
                {...props}
            >
                <StatusIconField
                    source="aggregateState"
                    alwaysVisible
                    width={88}
                />
                <LinkField
                    source="name"
                    label="tesseract.gateways.table.name"
                    link={getAzureResourceLink("applicationId", isDemo)}
                    filter="text"
                    sortable
                />
                <SelectedChoiceField
                    source="cloudVirtualWan.id"
                    label="tesseract.gateways.table.type"
                    optionValue="id"
                    render={(value, data) => {
                        const hasCloudVwan = !!data?.cloudVirtualWan?.id;
                        let text = hasCloudVwan && data?.cloudVirtualWan?.name || "";
                        text = text + (data?.cloudVirtualWan?.description ? ` (${data?.cloudVirtualWan?.description})` : "");
                        const isOnPrem = get(data, "gatewayType") === "on-prem";
                        return hasCloudVwan
                            ? <>
                                <AzureIcon classes={{icon: classes.gatewayIcon}}/>
                                <LinkField
                                    source="text"
                                    data={{data, text}}
                                    link={getAzureResourceLink("data.cloudVirtualWan.resourceId", isDemo)}
                                />
                            </>
                            : <>
                                {isOnPrem ?
                                    //@ts-ignore this is bad typescript on cuda-react end
                                    <PrivateEdgeIcon classes={{icon: classes.gatewayIcon}}/> :
                                    //@ts-ignore this is bad typescript on cuda-react end
                                    <EdgeServiceIcon classes={{icon: classes.gatewayIcon}}/>}
                                {translate(`tesseract.gateways.table.${isOnPrem ? "privateCloud" : "managedCloud"}`)}
                            </>;
                    }}
                    filter="select"
                    filterProps={{
                        source: "cloudVirtualWanId",
                        optionValue: "id",
                        choices: [
                            {id: PRIVATE_CLOUD_FILTER_KEY, name: translate("tesseract.gateways.table.privateCloud")},
                            {id: MANAGED_CLOUD_FILTER_KEY, name: translate("tesseract.gateways.table.managedCloud")},
                            ...getArrayDataContent(cloudVwanChoices).map((choice) => ({...choice, id: choice.id + ""}))
                        ]
                    }}
                    columnProps={{
                        field: "cloudVirtualWanName"
                    }}
                />
                <CustomField
                    source="hubName"
                    label="tesseract.gateways.table.upstream"
                    render={(name, data) => get(data, "gatewayType") === "cloud"
                        ? <LinkField data={data} source="hubName" link={getAzureHubLink("hubId", isDemo)}/>
                        : <TextField
                            data={data}
                            source="upstreamName"
                            defaultText={translate("tesseract.gateways.table.noGateway")}
                        />
                    }
                    filter="text"
                    sortable
                />
                <SelectedChoiceField
                    source="region"
                    label="tesseract.gateways.table.region"
                    choices={getArrayDataContent(regionChoices)}
                    filter="selectarray"
                    filterProps={{
                        choices: getArrayDataContent(regionChoices)
                    }}
                    sortable
                />
                <SelectedChoiceField
                    source="connectionStatus.aggregateConnectionState"
                    label="tesseract.gateways.table.status"
                    choices={getArrayDataContent(statusChoices)}
                    filter="selectarray"
                    filterProps={{
                        choices: getArrayDataContent(statusChoices)
                    }}
                    columnProps={{
                        field: "connectionStatus"
                    }}
                    sortable
                />
                <CustomField
                    source="scaleUnit"
                    label="tesseract.gateways.table.size"
                    cellClassName={classes.scaleUnitCell}
                    render={renderSize}
                    filter="custom"
                    filterProps={{
                        component: SizeFilter,
                        source: "size",
                        scaleUnitChoices: getArrayDataContent(scaleUnitChoices),
                        bandwidthChoices
                    }}
                />
                <TextField
                    source="sites"
                    label="tesseract.gateways.table.sites"
                    width={104}
                    sortable
                />
                <ActionButtonsField width={128} minResizableWidth={128} source="id" alwaysVisible>
                    <PropGateway editProps={({data}: any) => ({
                        data,
                        resource: getResource(data)
                    })}>
                        <DeleteDialog
                            disable={(data) => (data?.sites || data?.gateways || data?.secureEdgeConnectors) > 0}
                            title="tesseract.gateways.delete.title"
                            message="tesseract.gateways.delete.body"
                            disabledMessage="tesseract.gateways.delete.forbidden"
                            onSuccess={() => {
                                registerAction("Delete Gateway");
                                tableRefreshRef.current?.();
                                refreshRegionsList();
                            }}
                        />
                    </PropGateway>
                    <Forward
                        id="gateway-dashboard-icon"
                        onClick={(event: string, data: any) => {
                            push("/infrastructure/gateways/" + data.id);
                        }}
                    />
                </ActionButtonsField>
            </TablePage>
            <CreateGatewayDialog
                currentDialog={createMenu}
                onSuccess={() => {
                    refreshRegionsList();
                    tableRefreshRef.current?.();
                }}
                onClose={() => setCreateMenu(null)}
            />
        </>
    );
};

export default GatewayTable;