import {Theme} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {Divider, List, Typography, IconButton} from "@barracuda-internal/bds-core";
import {Close, Search} from "@barracuda-internal/bds-core/dist/Icons/Core";
import {Account, AccountWorkspace, Text, useMixpanel, Workspace} from "@cuda-react/core";
import {StratosSortIcon} from "@cuda-react/icons";
import AccountListItem from "./AccountListItem";
import WorkspaceListItem from "./WorkspaceListItem";
import React, {useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import InputAdornment from "@mui/material/InputAdornment";
import classNames from "classnames";
import WorkspaceActions from "./WorkspaceActions/WorkspaceActions";
import {CudaTheme} from "@cuda-react/theme";
import {cloneDeep, get, sortBy} from "lodash";

const useStyles = makeStyles((theme: Theme & typeof CudaTheme) => ({
    root: {
        height: (props: TenantWorkspaceListProps) => props.open ? "100%" : 0,
        overflowY: (props: TenantWorkspaceListProps) => props.open ? "hidden" : undefined,
        transition: "height 0.25s ease-in-out",
        display: (props: TenantWorkspaceListProps) => props.open ? "flex" : "none",
        width: "100%",
        flexDirection: "column"
    },
    accountWorkspace: {
        padding: "8px 16px 0 8px",
        display: "flex",
        justifyContent: "space-between"
    },
    accountText: {
        minWidth: 100,
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden"
    },
    accountName: {
        fontSize: 12,
        fontWeight: "bold"
    },
    workspaceName: {
        fontSize: 12
    },
    accountList: {
        height: "calc(100vh - 200px)",
        overflowY: "auto"
    },
    expandIcon: {
        transition: "transform 0.25s ease-in-out"
    },
    expandIconExpanded: {
        transform: "rotate(180deg)"
    },
    search: {
        marginTop: "8px",
        "& input": {
            backgroundColor: "#F2F4F6",
            padding: "4px 0 6px",
            fontSize: 14
        },
        "& > div": {
            backgroundColor: "#F2F4F6",
            padding: "0 4px"
        },
        "& svg": {
            color: "rgba(0,0,0,0.54)",
            height: 20,
            width: 20
        },
        "& fieldset": {
            border: "none"
        }
    },
    count: {
        padding: "8px 8px 8px",
        marginRight: "auto"
    },
    countAndActions: {
        display: "flex",
        alignItems: "center",
        "& svg": {
            "&:hover": {
                backgroundColor: "#E4F4FF" // TODO: Does this need tokenizing?
            },
            width: 20,
            height: 20
        },
        paddingRight: "16px"
    },
    sortAction: {
        "& svg": {
            transition: "transform 0.25s ease-in-out"
        }
    },
    sortActionInverted: {
        "& > span > svg": {
            transform: "rotate(180deg)"
        }
    },
    lastDivider: {
        marginBottom: "8px"
    }
}));

interface TenantWorkspaceListProps {
    accounts: AccountWorkspace[];
    activeWorkspace?: Workspace;
    changeWorkspace: (account: Account, workspace: Workspace) => void;
    currentAccount?: Account;
    defaultAccount?: Account;
    onChange?: () => void;
    open?: boolean;
    workspaces: Workspace[];
    swapAccount: (account: Account) => void;
}

const matchesFilter = (value: string, filter: string) => value.toLowerCase().includes(filter.toLowerCase());

const sortAndFlip = (invert: boolean, values: Array<any>) => {
    const result = cloneDeep(values);
    sortBy(result, (item) => item.name);
    invert && result.reverse();
    return result;
};

const sortAccounts = (invert: boolean) => (accounts: Array<AccountWorkspace>) => {
    const sortedAccounts = accounts.map((account: AccountWorkspace) => ({
        ...account,
        virtualWans: sortAndFlip(invert, account.virtualWans || [])
    }));
    return sortAndFlip(invert, sortedAccounts);
};

export const TenantWorkspaceList = (props: TenantWorkspaceListProps) => {
    const {accounts, swapAccount, changeWorkspace, defaultAccount, currentAccount, workspaces, activeWorkspace, onChange} = props;
    const registerAction = useMixpanel("Workspace Tenant", false);
    const classes = useStyles(props);
    const [translate] = useTranslation();
    const listAccounts = accounts.length > 1;
    const [filter, setFilter] = useState("");
    const [invertOrder, setInvertOrder] = useState(false);

    const filteredAccounts = useMemo(() => {
        const sortEntries = sortAccounts(invertOrder);

        if (listAccounts && filter) {
            const result: Array<AccountWorkspace> = [];

            accounts.forEach((account) => {
                // if the search matches the account name, add the account and all of its workspaces to the results
                if (matchesFilter(account.name, filter)) {
                    result.push(account);
                    return;
                }

                // else if the search matches any of the workspaces then add the account with only the matching workspaces to the results
                const matchingWorkspaces = get(account, "virtualWans", []).filter(({name}: Workspace) => matchesFilter(name, filter));
                if (matchingWorkspaces.length) {
                    result.push({
                        ...account,
                        virtualWans: matchingWorkspaces
                    });
                }
            });

            return sortEntries(result);
        } else {
            return sortEntries(accounts);
        }
    }, [listAccounts, filter, accounts, invertOrder]);

    const filteredWorkspaces = !listAccounts && filter ? workspaces.filter(({name}) => matchesFilter(name, filter)) : [...workspaces];
    if (invertOrder) {
        filteredWorkspaces.reverse();
    }

    return (
        <div className={classes.root} data-testid="tenant-workspace-list">
            <Divider/>
            <div className={classes.accountWorkspace}>
                <span className={classes.accountText}>
                    <Typography variant="caption" className={classes.accountName} title={currentAccount?.name}>
                        {currentAccount?.name}
                    </Typography>
                    <Typography variant="caption" className={classes.workspaceName} title={activeWorkspace?.name}>
                        {" / " + activeWorkspace?.name}
                    </Typography>
                </span>
                <WorkspaceActions
                    onAdd={(workspace) => {
                        onChange?.();
                        // Need to delay swapping to the newly created workspace, otherwise the form dialog won't have
                        // closed and you'll get an "are you sure" notification.
                        workspace && currentAccount && setTimeout(() => changeWorkspace(currentAccount, workspace), 250);
                    }}
                />
            </div>
            <Text
                id="tenants-workspace-search"
                value={filter}
                onChange={(event: any) => setFilter(event?.target?.value)}
                placeholder={listAccounts ? "stratos.accounts.searchTenants" : "stratos.accounts.searchWorkspaces"}
                options={{
                    fullWidth: true,
                    InputProps: {
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search/>
                            </InputAdornment>
                        ),
                        endAdornment: filter ? (
                            <InputAdornment position="end">
                                <IconButton
                                    size="small"
                                    onClick={() => setFilter("")}
                                >
                                    <Close fontSize="small"/>
                                </IconButton>
                            </InputAdornment>
                        ) : undefined
                    }
                }}
                classes={{
                    textField: classes.search
                }}
            />
            <div className={classes.countAndActions}>
                <Typography variant="caption" className={classes.count}>
                    {listAccounts ?
                        translate("stratos.accounts.tenantCount", {count: filteredAccounts.length, context: filteredAccounts.length !== 1 ? "plural" : "single"})
                        : translate("stratos.accounts.workspaceCount", {count: filteredWorkspaces.length, context: filteredWorkspaces.length !== 1 ? "plural" : "single"})}
                </Typography>
                <WorkspaceActions
                    activeWorkspace={activeWorkspace}
                    onChange={onChange}
                    onDelete={() => {
                        onChange?.();
                        const newWorkspaceIndex = workspaces.findIndex((workspace) => workspace.id === activeWorkspace?.id) - 1;
                        currentAccount && changeWorkspace(currentAccount, workspaces[newWorkspaceIndex >= 0 ? newWorkspaceIndex : 0]);
                    }}
                />
                <IconButton
                    size="small"
                    onClick={() => setInvertOrder((currentOrder) => !currentOrder)}
                    className={classNames(classes.sortAction, invertOrder && classes.sortActionInverted)}
                >
                    <StratosSortIcon/>
                </IconButton>
            </div>
            <Divider/>
            <List className={classes.accountList}>
                {listAccounts ? filteredAccounts.map((account) => (
                    <AccountListItem
                        key={account.id}
                        account={account}
                        active={account.id === currentAccount?.id}
                        defaultAccount={account.id === defaultAccount?.id}
                        workspaces={account.virtualWans}
                        setWorkspace={(workspace: Workspace) => {
                            registerAction("Swap", {withinTenant: currentAccount?.id === account.id});
                            changeWorkspace(account, workspace);
                        }}
                        activeWorkspaceId={activeWorkspace?.id || ""}
                        swapAccount={swapAccount}
                    />
                )) : filteredWorkspaces.map((workspace) => (
                    <WorkspaceListItem
                        key={workspace.id}
                        workspace={workspace}
                        setWorkspace={(workspace: Workspace) => {
                            registerAction("Swap", {withinTenant: true});
                            currentAccount && changeWorkspace(currentAccount, workspace);
                        }}
                        active={activeWorkspace?.id === workspace.id}
                    />
                ))}
            </List>
        </div>
    );
};