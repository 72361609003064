import React, {useState, useCallback} from "react";
import {Select,
    Input,
    InputProps
} from "@cuda-react/core";
import {Chip, FormControl, IconButton, SelectChangeEvent, TextField} from "@barracuda-internal/bds-core";
import classNames from "classnames";
import {isEqual, isArray, isEmpty} from "lodash";
import {createStyles} from "@mui/styles";
import {Theme} from "@mui/material";
import {Add} from "@barracuda-internal/bds-core/dist/Icons/Core";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";

const styles = (theme: Theme) => createStyles({
    root: {
        display: "flex",
        alignItems: "center"
    },
    firstInput: {
        width: 132,
        "&> div": {
            width: 124,
        },
        "&> div > div": {
            width: 124,
        },
        paddingRight: 8
    },
    secondInput: {
        paddingLeft: 0,
        width: 124,
        marginRight: 8
    },
    chip: {
        margin: "0.1rem",
        pointerEvents: "all"
    },
    chipContainer: {
        maxWidth: 256,
        marginBottom: "0.1rem"
    },
    chipError: {
        border: `1px solid ${theme.palette.error.main}`
    }
});
export const useStyles = makeOverrideableStyles("VLanInput", styles);

export interface VLanInputComponentProps<InputComponent extends React.ElementType> extends StyledComponentProps<typeof styles> {
    /**
     * if true, the input is disabled.
     */
    disabled?: boolean,
    /**
     * provided automatically when component is rendered inside a [Input](/?path=/docs/core-components-inputs-input) component.
     * error associated with this input.
     */
    error?: any,
    /**
     * additional props to pass to the first of the two rendered child inputs.
     */
    firstInputProps?: Partial<React.ComponentProps<InputComponent>>,
    /**
     * additional props to pass to both rendered child inputs.
     */
    inputProps?: Partial<React.ComponentProps<InputComponent>>,
    /**
     * max number of selected chips. Input will get disabled in case this number is hit.
     */
    maxInputs?: number,
    /**
     * callback to call when the input value has been changed.
     * provided automatically when component is rendered inside a [Input](/?path=/docs/core-components-inputs-input) component.
     * @function onChange
     */
    onChange?: (value: string[]) => void,
    /**
     * callback to called when input get focus.
     * provided automatically when component is rendered inside a [Input](/?path=/docs/core-components-inputs-input) component.
     * @function
     */
    onFocus?: () => void,
    /**
     * callback to called when component stops being interacted with.
     * provided automatically when component is rendered inside a [Input](/?path=/docs/core-components-inputs-input) component.
     * @function onBlur
     */
    onBlur?: () => void,
    /**
     * additional props to pass to the second of the two rendered child inputs.
     */
    secondInputProps?: Partial<React.ComponentProps<InputComponent>>,
    /**
     * current value of the input.
     * provided automatically when component is rendered inside a [Input](/?path=/docs/core-components-inputs-input) component.
     */
    value?: string[],
}

/**
 * Creates a "double input", which is simply two inputs rendered against a single label/description and data source.
 * The value is stored as a 2 part array, with the first array value passed to the first child input, and the second to the second.
 */
export const VLanInputComponent = <InputComponent extends React.ElementType>(props: VLanInputComponentProps<InputComponent>) => {
    const {
        disabled,
        error,
        firstInputProps,
        onBlur,
        onChange,
        onFocus,
        secondInputProps,
        value,
        inputProps,
        maxInputs,
        ...otherProps
    } = props;
    const classes = useStyles(props);
    const [currentValue, setCurrentValue] = useState(['', '']);

    const childOnChange = useCallback((inputIndex: 0 | 1) => (eventOrValue: any) => {
        const value = eventOrValue?.target ? eventOrValue.target.value : eventOrValue;
        const newValue = [
            inputIndex === 0 ? value : currentValue[0],
            inputIndex === 1 ? value : currentValue[1]
        ] as [any, any];
        if(!(isEqual(currentValue, newValue))) {
            setCurrentValue(newValue);
        }
    }, [currentValue]);

    const handleRequestDelete = (index: number) => {
        const newValue = (value || []).filter((val, itt) => itt !== index);
        onChange && onChange(newValue);
    };

    const addChips = (chips: string[]) => {
        const firstValue = chips[0].trim();
        const secondValue = chips[1]?.trim();
        const newChip = secondValue ? `${firstValue}.${secondValue}` : firstValue;
        let newChips = value ? [...value].concat(newChip) : [newChip];
        newChips = [...new Set(newChips)];
        onChange?.(newChips);
        setCurrentValue(["", ""]);
    };

    const onEnter = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            addChips(currentValue);
        }
    };

    return (
        <React.Fragment>
            <div className={classes.chipContainer}>
                {value && value.length >= 1 && value.map((val: string, index: number) => (
                    <Chip
                        key={index}
                        onDelete={disabled ? undefined : () => handleRequestDelete(index)}
                        className={classNames(
                            classes.chip,
                            !disabled && isArray(error) && !isEmpty(error[index]) && classes.chipError
                        )}
                        disabled={disabled}
                        label={val.charAt(0).toUpperCase() + val.slice(1)}
                    />
                ))}
            </div>
            <div className={classes.root}>
                <div className={classes.firstInput}>
                    {React.createElement(Select, {
                        id: "vLanSelectInput",
                        value: currentValue[0],
                        onChange: (eventOrValue: SelectChangeEvent) => {childOnChange(0)(eventOrValue)},
                        onBlur: () => onBlur && onBlur(),
                        onFocus: () => onFocus && onFocus(),
                        error: Array.isArray(error) ? (error[0] ? error[0] : undefined) : error,
                        disabled: disabled || (maxInputs && value && (value.length >= maxInputs)) ||false,
                        ...firstInputProps,
                        ...inputProps,
                        ...otherProps
                    })}
                </div>
                <div className={classes.secondInput}>
                    {React.createElement(TextField, {
                        id:"text-input-vLanTextInput",
                        value: currentValue[1],
                        onKeyDown: onEnter,
                        onChange: (eventOrValue) => {childOnChange(1)(eventOrValue)},
                        onBlur: () => onBlur && onBlur(),
                        onFocus: () => onFocus && onFocus(),
                        error: Array.isArray(error) ? (error[1] ? error[1] : undefined) : error,
                        disabled: disabled || (maxInputs && value && (value.length >= maxInputs)) ||false,
                        ...secondInputProps,
                        ...inputProps,
                        ...otherProps
                    })}
                </div>
                <IconButton
                    id="add-vlan-button"
                    size="small"
                    onClick={() => addChips(currentValue)}
                    disabled={disabled || (maxInputs && value && (value.length >= maxInputs)) ||false}
                    color="primary"
                >
                    <Add id="cuda-vlan-input"/>
                </IconButton>
            </div>
        </React.Fragment>
    );
};

export interface VLanInputProps extends Omit<InputProps<typeof VLanInputComponent>, "component" | "displayError"> {}

export const VLanInput = (props: VLanInputProps) => (
    <Input {...props} component={VLanInputComponent} />
);

export default VLanInput;