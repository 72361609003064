import React from "react";
import {makeStyles} from "@mui/styles";
import classNames from "classnames";
import {TenantWorkspaceList} from "./TenantWorkspaceList";
import {useTenantWorkspace} from "../../hooks/tenantWorkspaceHooks";
import {useHistory, useLocation} from "react-router";

const useStyles = makeStyles((theme) => ({
    swapTenantOption: {
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        "& > :first-child": {
            flexShrink: 0
        },
        width: "100%",
        height: "100%",
        overflowY: "auto",
        paddingTop: `calc(8px - 1px)`
    }
}));

const siteIdRegex = /\/network\/sites\/([0-9]*)/;
const gatewayIdRegex = /\/network\/gateways\/([0-9]*)/;

const SwapTenantOption = () => {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const {availableAccounts, swapAccount, changeWorkspace, currentAccount, defaultAccount, refreshAccounts, workspaceChoices, currentWorkspace} = useTenantWorkspace();

    return (
        <div className={classNames(classes.swapTenantOption)}>
            <TenantWorkspaceList
                open
                accounts={availableAccounts}
                currentAccount={currentAccount}
                defaultAccount={defaultAccount}
                workspaces={workspaceChoices}
                changeWorkspace={(account, workspace) => {
                    changeWorkspace(account, workspace);
                    (location.pathname.match(siteIdRegex)) && history.push("/network/sites");
                    (location.pathname.match(gatewayIdRegex)) && history.push("/network/gateways");
                }}
                activeWorkspace={currentWorkspace}
                onChange={refreshAccounts}
                swapAccount={swapAccount}
            />
        </div>
    );
};

export default SwapTenantOption;