import React from "react";
import {SvgIcon, useTokens} from "@barracuda-internal/bds-core";
import classNames from "classnames";

const overrideColor = {
    primaryColor: "#0C7FD4",
    secondaryColor: "#4D4D4D"
};

const LiveLogClosingConnectionIcon = ({className}: { className?: string }) => {
    const tokens = useTokens();
    return (
        <SvgIcon
            className={classNames(className, "cuda-react-icon")}
            id="cuda-icon-access-live-log-closing-connection"
            viewBox="0 0 60 60"
        >
            <path style={{fill: tokens.globalBlue50, strokeWidth: 0}}
                  d="M42.3,32.5l-3.5-3.4,6.4-6.4H7.9v-4.9h37.2l-6.4-6.4,3.5-3.4,12.3,12.3-12.3,12.3Z"/>
            <polygon style={{fill: tokens.aliasesColorTextStaticNeutral, strokeWidth: 0, opacity: .4}}
                     points="17.7 52.1 5.5 39.8 17.7 27.5 21.2 31 14.9 37.4 52.1 37.4 52.1 42.3 14.9 42.3 21.2 48.6 17.7 52.1"/>
        </SvgIcon>
    );
};


export default LiveLogClosingConnectionIcon;