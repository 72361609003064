import {TFunction} from "i18next";
import {getDataContent} from "../../utils";
import {get} from "lodash";
import {ExtendedSeriesMappointOptions, LocationMapSeriesDefinition} from "./UseLocationsMap";

/***
 * parses the provided data into map-ready series data format.
 */
export const parseData = (series: LocationMapSeriesDefinition[], data: any, translate: TFunction, noRandom?: boolean): Partial<ExtendedSeriesMappointOptions>[] => series.map((seriesEntry) => {
    const resourceData = getDataContent(seriesEntry.data || data);
    const dataAsArray = Array.isArray(resourceData) ? resourceData : [resourceData];
    const mergedData = seriesEntry.source
        ? dataAsArray.flatMap(
            (parent) => {
                const values = seriesEntry.source && get(parent, seriesEntry.source) || [];
                return (Array.isArray(values) ? values : [values]).map((item) => ({
                    ...item,
                    linkedSeriesParent: seriesEntry.linkedSeries ? parent : undefined,
                    series: seriesEntry
                }));
            }
        )
        : dataAsArray.map((item) => ({
            ...item,
            series: seriesEntry,
            linkedSeriesSources: seriesEntry.linkedSeries ? series.map((series) => series.source).filter((source) => source) : undefined
        }));

    const filteredLocations = mergedData
       .filter((location) =>
    location &&
    typeof location.latitude === "number" &&
    typeof location.longitude === "number" &&
    isFinite(location.latitude) &&
    isFinite(location.longitude)
).map((location) => ({
            lat: noRandom ? location.latitude : location.latitude + Math.random() / 1000,
            lon: noRandom ? location.longitude : location.longitude + Math.random() / 1000,
            pointData: location
        }));

    return {
        label: seriesEntry.label && translate(seriesEntry.label),
        color: seriesEntry.color,
        data: seriesEntry.filter ? filteredLocations.filter(seriesEntry.filter) : filteredLocations,
        zIndex: seriesEntry.zIndex
    };
});