import React, {useState} from "react";
import {Button, CircularProgress, Paper, Typography} from "@barracuda-internal/bds-core";
import {Table, TableBody, TableCell, TableRow, Theme} from "@mui/material";
import {PageFirst, PageLast, PageNext, PagePrevious} from '@barracuda-internal/bds-core/dist/Icons/Core';
import {useTranslation} from "react-i18next";
import {get, union} from "lodash";
import classNames from "classnames";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import {useDeepCompareEffect} from "../../../hooks";
import {createStyles} from "@mui/styles";

const styles = (theme: Theme) => createStyles({
    dualListInput: {
        textAlign: "left",
        display: "inline-block",
        paddingLeft: "1rem",
        height: "250px",
        maxWidth: "100%"
    },
    errorMessage: {
        color: theme.palette.error.main,
        position: "absolute",
        bottom: -20,
        fontSize: 12
    },
    additionalInfo: {
        position: "absolute",
        bottom: -20,
        fontSize: 12
    },
    additionalInfoWithError: {
        position: "absolute",
        bottom: -40,
        fontSize: 12
    },
    additionalInfoInRedWithError: {
        color: theme.palette.error.main,
        position: "absolute",
        bottom: -40,
        fontSize: 12
    },
    errorPaper: {
        // @ts-ignore This works.. not sure why typescript doesnt like it.
        boxShadow: [
            theme.palette.error.main + " 0px 1px 6px",
            theme.palette.error.main + " 0px 1px 4px"
        ]
    },
    disabled: {
        // @ts-ignore Added by Cuda-react. Default (undefined), is fine
        borderColor: theme.palette.disabled,
        // @ts-ignore Added by Cuda-react. Default (undefined), is fine
        color: theme.palette.disabled
    },
    dualList: {
        marginBottom: "24px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        flexWrap: "nowrap",
        width: "100%",
        height: "calc(100% - 24px)",
        position: "relative",
    },
    listContainer: {
        margin: "2px",
        paddingTop: 4,
        height: "calc(100% - 4px)",
        flex: "1 1 auto",
        overflowY: "scroll",
        overflowX: "auto",
        width: "35rem !important",
    },
    actionContainer: {
        flex: "1 1 auto",
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        height: "100%",
        margin: "8px",
        maxWidth: "90px",
    },
    actionButton: {
        minWidth: 50,
        maxWidth: 80,
        width: "100%",
        color: theme.palette.primary.contrastText
    },
    table: {
        padding: 0,
    },
    tableRow: {
        border: 0,
        height: "auto",
        cursor: "default",
        "&$selected": {
            backgroundColor: theme.palette.primary.main,
            "& td": {
                color: theme.palette.primary.contrastText
            },
        },
        "&$selected:hover": {
            backgroundColor: theme.palette.primary.dark,
        }
    },
    tableCell: {
        height: "auto",
        padding: "2px",
        border: 0
    },
    section: {
        paddingLeft: "8px",
        fontWeight: "bold",
    },
    item: {
        paddingLeft: "24px",
    },
    spinnerBlock: {
        height: 30,
        width: "100%",
        margin: 0
    },
    selected: {}
});
const useStyles = makeOverrideableStyles("DualList", styles);

export interface DualListProps extends StyledComponentProps<typeof styles> {
    /**
     * array of possible choices.
     */
    choices?: any[],
    /**
     * if true, left table choices and buttons to move choices to the left will be disabled.
     */
    disableLeft?: boolean,
    /**
     * if true, right table choices and buttons to move choices to the right will be disabled.
     */
    disableRight?: boolean,
    /**
     * if true, DualList component will get disabled.
     */
    disabled?: boolean,
    /**
     * provided automatically when component is rendered inside a [Input](/?path=/docs/core-components-inputs-input) component.
     * error associated with this input.
     */
    error?: any,
    /**
     * if true, a spinner will be render
     */
    hasSpinner?: boolean,
    /**
     * if true, hides additional info when an error is present
     */
    hideAdditionalInfoOnError?: boolean,
    /**
     * id of the component for unique identification. This value is prefixed with "boolean-input-".
     * provided automatically when component is rendered inside a [Input](/?path=/docs/core-components-inputs-input) component.
     */
    id: string,
    /**
     * if true, and hasSpinner is not falsy, the spinner will be shown
     */
    loading?: boolean,
    /**
     * the max number of items that can be selected
     */
    maxSelectable?: number,
    /**
     * if falsy, add all and remove all buttons will not be rendered.
     */
    multiSelectable?: boolean,
    /**
     * callback to called when component stops being interacted with.
     * provided automatically when component is rendered inside a [Input](/?path=/docs/core-components-inputs-input) component.
     * @function onBlur
     */
    onBlur?: () => void,
    /**
     * handler for the onChange event. Called with the current selected block.
     *
     * @function
     * @param {object} array with the current selected choices.
     */
    onChange?: (value: string[]) => void,
    /**
     * a dot notation path to the data.
     * Choices can be group by optionBlockValue. This prop points to the group of choices that belongs to a specific group.
     */
    optionBlockData?: string,
    /**
     * a dot notation path to the data.
     * Choices can be group by this prop.
     */
    optionBlockValue?: string,
    /**
     * can be either:
     *
     * - dot-notation path in the data structure to the representative name of the choice.
     * - a function, called with the optionBlockValue.
     * @function
     * @param {string} optionBlockValue to get the representative value of the choice.
     */
    optionText?: string | ((choice: any) => string),
    /**
     * dot-notation path in the data structure to the representative value of the choice.
     */
    optionValue?: string,
    /**
     * custom message to show at the bottom of the right side list with the optional values of maxCount and currentCount
     */
    additionalInfo?: string,
    /**
     * if true, choices values will be translated.
     */
    translateChoice?: boolean,
    /**
     * current value of the input.
     * provided automatically when component is rendered inside a [Input](/?path=/docs/core-components-inputs-input) component.
     */
    value?: string | string[],
    /**
     * initial value of the input in array format.
     */
    initialValues?: string[]
}

type DualListRow = {name: string, value?: any};

const choicesToRows = (props: DualListProps) => {
    const leftRows: DualListRow[] = [];
    const rightRows: DualListRow[] = [];
    const {choices = [], value = [], optionValue = "id", optionText = "name", optionBlockData = "data", optionBlockValue = "value"} = props;
    const selected = Array.isArray(value) ? value : [value];
    choices.forEach((block) => {
        const blockData = get(block, optionBlockData);
        const blockValue = get(block, optionBlockValue);

        if (blockData.some((item: any) => !selected.includes(get(item, optionValue)))) {
            leftRows.push({name: (typeof optionText === "function" ? optionText(blockValue) : get(blockValue, optionText))});
        }

        if (blockData.some((item: any) => selected.includes(get(item, optionValue)))) {
            rightRows.push({name: (typeof optionText === "function" ? optionText(blockValue) : get(blockValue, optionText))});
        }

        blockData.forEach((item: any) => {
            const name = typeof optionText === "function" ? optionText(item) : get(item, optionText);
            const value = get(item, optionValue);

            if (!selected.includes(value)) {
                leftRows.push({name, value});
            } else {
                rightRows.push({name, value});
            }
        });
    });

    return {leftRows, rightRows};
};


/**
 * Renders a two tables input with control buttons that allows users to select a list/group of options from choices.
 * This component is used by DualListInput component (which provides an inputs) that allows this component to work with the default [Input](/?path=/docs/core-components-inputs-input) format, and work natively with redux form "input" prop.
 */
export const DualList  = (props: DualListProps) => {
    const {
        translateChoice= true,
        disabled,
        error,
        multiSelectable = true,
        hasSpinner,
        hideAdditionalInfoOnError,
        loading,
        id,
        disableLeft,
        disableRight,
        value = [],
        onChange,
        initialValues,
        additionalInfo,
        maxSelectable,
        onBlur
    } = props;
    const valueAsArray = Array.isArray(value) ? value : [value];
    const {leftRows, rightRows} = choicesToRows(props);
    const [leftSelectedRows, setLeftSelectedRows] = useState<string[]>([]);
    const [rightSelectedRows, setRightSelectedRows] = useState<string[]>([]);
    const handleRowClick = (row: DualListRow, leftState: boolean) => {
        if (!row.value) {
            return;
        }
        const selected = leftState ? leftSelectedRows : rightSelectedRows;
        const selectedIndex = selected.indexOf(row.name);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, row.name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        leftState ? setLeftSelectedRows(newSelected) : setRightSelectedRows(newSelected);
    };
    const isSelected = (name: string, leftState: boolean) => {
        const selected = leftState ? leftSelectedRows : rightSelectedRows;
        return (selected.indexOf(name) !== -1);
    };
    const addAll = () => {
        const {leftRows} = choicesToRows(props);
        const newSelection = valueAsArray.concat(leftRows.filter((item) => !!item.value).map((item) => item.value));
        onChange?.(newSelection);
        onBlur?.();
    };
    const addToSelection = () => {
        const selected = valueAsArray;
        const {leftRows} = choicesToRows(props);
        const newSelection = (selected).slice(0);
        leftRows.forEach((row) => {
            if (row.value && leftSelectedRows.includes(row.name)) {
                newSelection.push(row.value);
            }
        });

        onChange?.(newSelection);
        onBlur?.();
    };
    const addOneToSelection = (currentValue: string) => {
        const newSelection = union([], valueAsArray, [currentValue]);
        onChange?.(newSelection);
        onBlur?.();
    };

    const removeOneFromSelection = (currentValue: string) => {
        const newSelection = valueAsArray.filter((val) => val !== currentValue);
        onChange?.(newSelection);
        onBlur?.();
    };

    const removeFromSelection = () => {
        const {rightRows} = choicesToRows(props);
        const removeItems: any[] = [];
        rightRows.forEach((row) => {
            if (row.value && rightSelectedRows.includes(row.name)) {
                removeItems.push(row.value);
            }
        });
        const newSelection = valueAsArray.filter((item: any) => (!removeItems.includes(item)));
        onChange?.(newSelection);
        onBlur?.();
    };

    useDeepCompareEffect(() => {
        setLeftSelectedRows([]);
        setRightSelectedRows([]);
    }, [value]);

    useDeepCompareEffect(() => {
        const timer = setTimeout(() => {
            initialValues && onChange?.(initialValues);
        }, 50);

        return () => clearTimeout(timer);
    }, [initialValues]);

    const classes = useStyles(props);
    const [translate] = useTranslation();

    const additionalInfoClass = (error: any, maxSelectable: number | undefined, currentCount: number) => {
        if (maxSelectable) {
            if (!error) {
                if (currentCount > maxSelectable) {
                    return classes.errorMessage;
                } else {
                    return classes.additionalInfo;
                }
            } else {
                if (currentCount > maxSelectable) {
                    return classes.additionalInfoInRedWithError;
                } else {
                    return classes.additionalInfoWithError;
                }
            }
        } else {
            if(!error) {
                return classes.additionalInfo;
            } else {
                return classes.additionalInfoWithError;
            }
        }
    };

    return (
        <div className={classes.dualListInput} id={id && "dual-list-input-" + id}>
            <Typography component="div" className={classes.dualList}>
                <Paper className={classNames(classes.listContainer, error ? classes.errorPaper : undefined)}>
                    <Table className={classes.table}>
                        <TableBody>
                            {leftRows.map((row, index) => (
                                <TableRow
                                    className={classes.tableRow}
                                    classes={{selected: classes.selected}}
                                    key={index}
                                    onClick={() => handleRowClick(row, true)}
                                    selected={isSelected(row.name, true)}
                                >
                                    <TableCell
                                        className={classNames(
                                            classes.tableCell,
                                            !row.value ? classes.section : classes.item,
                                            (disabled || disableRight) ? classes.disabled : undefined
                                        )}
                                        onDoubleClick={row.value ? () => addOneToSelection(row.value) : undefined}
                                    >
                                        {translateChoice ? translate(row.name) : row.name}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Paper>
                <div className={classes.actionContainer}>
                    {multiSelectable && (
                        <Button
                            variant="contained"
                            bdsType="interactiveEmphasis"
                            size="small"
                            className={classes.actionButton}
                            onClick={() => addAll()}
                            disabled={!!(disabled || disableRight)}
                        >
                            <PageLast id="cuda-dual-list-add-all"/>
                        </Button>
                    )}
                    <Button
                        variant="contained"
                        bdsType="interactiveEmphasis"
                        size="small"
                        className={classes.actionButton}
                        onClick={() => addToSelection()}
                        disabled={!!(disabled || disableRight)}
                    >
                        <PageNext id="cuda-dual-list-add-selected"/>
                    </Button>
                    {hasSpinner && (
                        <div className={classes.spinnerBlock}>
                            {loading && (<CircularProgress size={30} thickness={2}/>)}
                        </div>
                    )}
                    <Button
                        variant="contained"
                        bdsType="interactiveEmphasis"
                        size="small"
                        className={classes.actionButton}
                        onClick={() => removeFromSelection()}
                        disabled={!!(disabled || disableLeft)}
                    >
                        <PagePrevious id="cuda-dual-list-remove-selected"/>
                    </Button>
                    {multiSelectable && (
                        <Button
                            variant="contained"
                            bdsType="interactiveEmphasis"
                            size="small"
                            className={classes.actionButton}
                            onClick={() => {
                                onChange?.([]);
                                onBlur?.();
                            }}
                            disabled={!!(disabled || disableLeft)}
                        >
                            <PageFirst id="cuda-dual-list-remove-all"/>
                        </Button>
                    )}
                </div>
                <Paper className={classNames(classes.listContainer, error ? classes.errorPaper : undefined)}>
                    <Table className={classes.table}>
                        <TableBody>
                            {rightRows.map((row, index) => (
                                <TableRow
                                    className={classes.tableRow}
                                    classes={{selected: classes.selected}}
                                    key={index}
                                    onClick={() => handleRowClick(row, false)}
                                    selected={isSelected(row.name, false)}
                                >
                                    <TableCell
                                        className={classNames(
                                            classes.tableCell,
                                            !row.value ? classes.section : classes.item,
                                            (disabled || disableLeft) ? classes.disabled : undefined
                                        )}
                                        onDoubleClick={row.value ? () => removeOneFromSelection(row.value) : undefined}
                                    >
                                        {translateChoice ? translate(row.name) : row.name}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    {error && (
                        <div className={classes.errorMessage}>
                            {error}
                        </div>
                    )}
                    {!(hideAdditionalInfoOnError && error) && additionalInfo && (
                        <div className={additionalInfoClass(error, maxSelectable, valueAsArray.length)}>
                            {translate(additionalInfo, {maxCount: maxSelectable, currentCount: valueAsArray.length})}
                        </div>
                    )}
                </Paper>
            </Typography>
        </div>
    );
};

export default DualList;