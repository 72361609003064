import {Box, Typography} from '@barracuda-internal/bds-core';
import {StatusIcon} from '@cuda-react/icons';
import {styled} from '@mui/styles';
import React from 'react';
import apiResources from '../../../../../../../apiResources';
import {getArrayDataContent, useCrudProps} from '@cuda-react/core';
import {useTranslation} from 'react-i18next';

const Wrapper = styled(Box)(({theme}) => ({
    //@ts-ignore TODO: this theme entry does exist. We should extend DefaultTheme to fix this properly (or BDS should do that).
    backgroundColor: theme.palette.warning.light ?? '',
}));

export const ConnectorIPsInfo = () => {
    const [translate] = useTranslation();
    const ips: string[] = getArrayDataContent(useCrudProps(apiResources.directoriesConnectorsIps))?.[0]?.data?.content;

    if (!ips || ips.length === 0) {
        return null;
    }

    return (
        <Wrapper p={2}>
            <Box display="flex" flexDirection="row" alignItems="start">
                <StatusIcon status="warning" />
                <Box pl={2}>
                    <Typography >{translate('tesseract.identity.userDirectories.form.ldap.connectorIps.warning')}</Typography>
                    <Typography fontWeight="bold">{ips.join(', ')}</Typography>
                </Box>
            </Box>
        </Wrapper>
    );
};