import {isEmpty} from "lodash";
import React, {useRef, useState} from "react";
import {formatErrorMessage, getArrayDataContent} from "../../../utils";
import {CircularProgress, IconButton, TextField} from "@barracuda-internal/bds-core";
import {InputAdornment, Theme} from "@mui/material";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import {useCrudProps, useDeepCompareEffect} from "../../../hooks";
import {Assignment, Sync} from "@barracuda-internal/bds-core/dist/Icons/Core";
import {useTranslation} from "react-i18next";
import {createStyles} from "@mui/styles";

interface BaseAutoSelectProps {
    /**
     * if true, users can type into the field, as well as clicking auto-generate.
     */
    allowManualInput?: boolean,
    /**
     * if true, displays a button that when clicked will select a new random choice from the provided choices.
     */
    allowShuffle?: boolean,
    /**
     * if true, the input is allowed to be empty, and won't automatically choose the first available choice.
     */
    allowEmpty?: boolean,
    /**
     * an array of strings to select from when value is empty (or when shuffle button is clicked).
     */
    choices?: (string | number)[],
    /**
     * the choice to select instead of the first available choice when value is empty.
     */
    defaultChoice?: string,
    /**
     * provided by [Input][Input](/?path=/docs/core-components-inputs-input) component. id of the component for unique identification. This value is prefixed with "autoselect-input-".
     */
    id: string,
    /**
     * callback to called when component stops being interacted with. Provided by [Input](/?path=/docs/core-components-inputs-input) im case the component is wrapped within it.
     * @function onBlur
     */
    onBlur?: () => void,
    /**
     * callback to call when the input value has been changed. Provided by [Input](/?path=/docs/core-components-inputs-input) im case the component is wrapped within it.
     * @function onChange
     */
    onChange?: (value: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void,
    /**
     * current value of that input. Provided by [Input](/?path=/docs/core-components-inputs-input) in case the component is wrapped within it.
     */
    value?: string | number,
    /**
     * provided automatically when component is rendered inside a [Input](/?path=/docs/core-components-inputs-input) component.
     * error associated with this input.
     */
    error?: any,
    /**
     * additional props to pass to the underlying TextField.
     */
    options?: object,
    /**
     * if true, the input is disabled.
     */
    disabled?: boolean,
    /**
     * [CRUD](/?path=/docs/cudareactapp-crud--page) resource to fetch choices from. The fetched choices are added to the
     * provided choices (if any).
     */
    resource?: string,
    /**
     * [CRUD](/?path=/docs/cudareactapp-crud--page) params to use when fetching choices.
     */
    params?: object,
    /**
     * if provided, this callback is called to allow the provided/resource choices to be formatted, prior to use.
     *
     * @function
     * @param {Array<*>} choices the current array of choices and/or resource fetched choices.
     * @param {string|number} value the current value.
     * @param {string|number} initialValue the initial value.
     * @returns {Array<*>} the formatted array of choices to use when auto-selecting.
     */
    formatChoices?: (choices: any[], value: string|number, initialValue?: string|number) => (string | number)[],
    /**
     * label shown at the end of the Text Area to explain the nature of the input. To remove, simply set an empty string.
     */
    generatedLabel?: string
}

const styles = (theme: Theme) => createStyles<string, BaseAutoSelectProps>({
    textField: {
        width: 256
    },
    endAdornment: {
        fontSize: 14,
        color: theme.palette.text.secondary
    },
    syncDisabled: {
        opacity: (props) => props.disabled ? 0.4 : 1
    }
});
const useStyles = makeOverrideableStyles("AutoSelect", styles);


export interface AutoSelectProps extends StyledComponentProps<typeof styles>, BaseAutoSelectProps {}


/**
 * Restricted user-interaction input, which automatically selects a value for the user from the provided choices.
 *
 * If "allowShuffle" is turned on, the user can choose to generate a new value (from the provided choices).
 *
 * This is primarily used in contexts where the value is required, and needs to be presented to the customer, but in most use
 * cases the customer does not need to select it.
 */
export const AutoSelect = (props: AutoSelectProps) => {
    const {
        allowManualInput,
        allowShuffle,
        allowEmpty,
        resource,
        params,
        disabled,
        formatChoices,
        defaultChoice,
        choices,
        id,
        error,
        value = "",
        onChange,
        onBlur,
        options = {},
        generatedLabel = "cuda.inputs.autoSelect.generated"
    } = props;
    const classes = useStyles(props);
    const [generated, setGenerated] = useState(false);
    const [translate] = useTranslation();
    const initialValue = useRef(value);
    const textFieldRef = useRef<HTMLTextAreaElement|null>(null);

    const resourceChoices = getArrayDataContent(useCrudProps(resource, params)[0]?.data);
    const resolvedChoices = [...(choices || []), ...resourceChoices];
    const formattedChoices = formatChoices ? formatChoices(resolvedChoices, value, initialValue.current) : resolvedChoices;
    const filteredChoices = formattedChoices.filter((choice) => choice !== value);
    const valueIsFromChoices = formattedChoices.length > filteredChoices.length;

    // Automatically select choice, either the defaultChoice or the first in the choices array
    useDeepCompareEffect(() => {
        if (!isEmpty(formattedChoices) && !allowEmpty && (allowManualInput ? !value : !valueIsFromChoices)) {
            setGenerated(true);
            onChange?.(defaultChoice || formattedChoices[0]);
            onBlur?.();
        }
    }, [formattedChoices, value, onChange]);

    return (
        <TextField
            className={classes.textField}
            value={(allowManualInput || valueIsFromChoices) && value || ""}
            onChange={allowManualInput ? (eventOrValue) => {
                setGenerated(false);
                onChange && onChange(eventOrValue);
                onBlur && onBlur();
            } : () => {}}
            {...options}
            error={!!error}
            disabled={disabled}
            helperText={error && formatErrorMessage(error) || undefined}
            id={"autoselect-input-" + id}
            inputRef={textFieldRef}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end" className={classes.endAdornment}>
                        {generated ? translate(generatedLabel) : null}
                        {allowShuffle ? (
                            <IconButton
                                size="small"
                                id="regenerate-value-button"
                                className={classes.regenerateButton}
                                onClick={() => {
                                    setGenerated(true);
                                    onChange && onChange(filteredChoices[Math.floor(Math.random() * filteredChoices.length)]);
                                    onBlur && onBlur();
                                }}
                                disabled={disabled || filteredChoices.length < 1}
                            >
                                <Sync className={disabled ? classes.syncDisabled : ""}/>
                            </IconButton>
                        ) : undefined}
                        {valueIsFromChoices || allowManualInput || allowEmpty ? (
                            <IconButton
                                size="small"
                                onClick={() => {
                                    if (textFieldRef.current) {
                                        textFieldRef.current.select();
                                        document.execCommand("copy");
                                        textFieldRef.current.setSelectionRange(0, 0);
                                    }
                                }}
                            >
                                <Assignment className={disabled ? classes.syncDisabled : ""}/>
                            </IconButton>
                        ) : (
                            <CircularProgress size={24}/>
                        )}
                    </InputAdornment>
                )
            }}
        />
    );
};

export default AutoSelect;