import {SvgIcon, useTokens} from "@barracuda-internal/bds-core";
import React from "react";
import classNames from "classnames";

export const ConnectorGraphic = ({className}: { className?: string }) => {
    const tokens = useTokens();
    return (
        <SvgIcon
            className={classNames(className, "cuda-react-graphic")}
            id="cuda-graphic-connectors"
            viewBox="0 0 96 96"
        >
            <g id="ArtBoard11">
                <g id="Ebene-1">
                    <rect style={{fill: tokens.aliasesColorTextStaticNeutral, strokeWidth: 0}} x="46.3" y="49.9" width="5.7" height="5.7"/>
                    <rect style={{fill: tokens.aliasesColorTextStaticNeutral, strokeWidth: 0}} x="32.5" y="49.9" width="5.7" height="5.7"/>
                    <rect style={{fill: tokens.aliasesColorTextStaticNeutral, strokeWidth: 0}} x="18.7" y="49.9" width="5.7" height="5.7"/>
                    <path style={{fill: tokens.aliasesColorTextStaticNeutral, strokeWidth: 0}}
                          d="M18.5,18.1l-11.5,4.3v8.7c0,7.2,4.9,14,11.5,15.6,6.6-1.6,11.5-8.4,11.5-15.6v-8.7l-11.5-4.3ZM17,37.5l-5.1-5.1,2-2,3,3,6.1-6.1,2,2-8.1,8.1Z"/>
                    <path style={{fill: tokens.aliasesColorTextStaticNeutral, strokeWidth: 0}}
                          d="M72.6,40.6c.3,0,.6,0,.9,0-1.2-1.6-3.1-2.6-5.3-2.6H30.8c-.9,2.1-2.1,4.1-3.6,5.7h34.6c3.1-2,6.8-3.2,10.8-3.2"/>
                    <path style={{fill: tokens.aliasesColorTextStaticNeutral, strokeWidth: 0}}
                          d="M52.6,61.7H13c-.6,0-1.1-.5-1.1-1.1v-14.9c-1.7-1.4-3.2-3.1-4.3-5-.9,1.1-1.4,2.6-1.4,4.1v15.8c0,3.8,3.1,6.8,6.8,6.8h40.8c-.7-1.8-1.1-3.7-1.2-5.7"/>
                    <path style={{fill: tokens.aliasesColorIconSuccessNeutral, strokeWidth: 0}}
                          d="M72.6,43.5c-9.5,0-17.2,7.7-17.2,17.2s7.7,17.2,17.2,17.2,17.2-7.7,17.2-17.2-7.7-17.2-17.2-17.2ZM81.2,62.4h-6.9v6.9h-3.4v-6.9h-6.9v-3.4h6.9v-6.9h3.4v6.9h6.9v3.4Z"/>
                </g>
            </g>
        </SvgIcon>
    );
};

export default ConnectorGraphic;