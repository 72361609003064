import React from "react";
import {useTranslation} from "react-i18next";
import {useGlobalParam} from "@cuda-react/core";

export const hooks = {
    useGlobalParam,
    useTranslation,
};

const Footer = () => {
    const [translate] = hooks.useTranslation();
    const [accountId] = hooks.useGlobalParam("userData.currentAccount");
    return (
        <span>
            {accountId ? translate("stratos.footer.bccAccount", {accountId}) : translate("stratos.footer.noSerial")}
        </span>
    );
};

export default Footer;