import {colors} from "@barracuda-internal/bds-core";

/**
 * A theme used by the reporting
 * extended from the [BDSTheme](https://cudaui.com).
 *
 * @name ReportingTheme
 */
const ReportingTheme = {
    genericColors: {
        turquoise: colors.skyBlue300,
        blue: colors.blue500,
        darkBlue: colors.blue600,
        graphite: colors.deepOcean500,
        purple: colors.purple600,
        magenta: colors.magenta600,
        white: colors.white,
        yellow: colors.yellow400
    },
    reporting: {
        titleColor: colors.scaleGray700,
    },
    riskColors: {
        low: colors.green600,
        lowMedium: colors.green400,
        medium: colors.yellow300,
        mediumHigh: colors.orange300,
        high: colors.red600
    }
};

export default ReportingTheme;