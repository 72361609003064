export default {
  translation: {
    tesseract: {
      productName: "Barracuda SecureEdge",
      dashboard: {
        menuTitle: "DASHBOARD",
        pageTitle: "Dashboard",
        emptyTemplate: "Leer",
        filters: {
          timeframe: "Zeitfenster",
          appliance: "Edge Service/Site",
          noAppliances: "Keine",
          timeframes: {
            lastDay: "Letzte 24 Stunden",
            lastMonth: "Letzter Monat",
            lastWeek: "Letzte Woche",
            lastQuarter: "Letzte 3 Monate"
          },
          sourceIp: "Quell-IP",
          riskLevel: "Risikolevel",
          application: "Applikation",
          destinationDomain: "Ziel-Domain",
          targetIp: "Ziel-IP",
          user: "Benutzer",
          urlCategory: "URL-Kategorie",
          malwareFile: "Malware-Datei",
          ipsEvent: "IPS Event",
          addFilter: "Filter hinzufügen",
          geoSource: "Geo-Quelle",
          geoDestination: "Geo-Ziel",
          resetFilter: "Filter zurücksetzen",
          riskLevelChoice_0: "Gering",
          riskLevelChoice_1: "Gering - Mittel",
          riskLevelChoice_2: "Mittel",
          riskLevelChoice_3: "Mittel - Hoch",
          riskLevelChoice_4: "Hoch",
          sdwanCategory: "App-Kategorie",
          activeFilters: "Auf Widgets, die mit <0/> gekennzeichnet sind, werden alle aktiven Filter angewendet.",
          disabledFilters: "Die aktuellen Filter gelten für keines der angezeigten Widgets.",
          addFilterMessage: "Klicken Sie oben oder in einer Widget-Zeile auf „Filter hinzufügen“, um einen Filter zu erstellen.",
          unsupportedFilters: "Der aktuelle Edge Service unterstützt keine Filter."
        },
        cards: {
          gateway: {
            cardTitle: "Edge Service Status",
            online: "Erreichbar",
            pending: "Ausstehend",
            degraded: "Eingeschränkt",
            offline: "Nicht erreichbar"
          },
          iotStatusCard: {
            healthy: "Erreichbar",
            pending: "Ausstehend",
            connecting: "Verbindung herstellen",
            degraded: "Eingeschränkt",
            offline: "Nicht verbunden",
            name: "Name",
            gateway: "Gateway",
            aggregateState: "Status",
            type: "Model"
          },
          status: {
            site: {
              tabTitle: "Site Status"
            },
            iot: {
              tabTitle: "IoT Status"
            },
            applianceConfiguration: {
              tabTitle: "Status der Gerätekonfiguration",
              completed: "Abgeschlossen",
              connecting: "Verbindung herstellen",
              failed: "Fehlgeschlagen",
              pending: "Ausstehend"
            }
          },
          vpn: {
            locations: {
              tabTitle: "Karte",
              gateways: {
                label: "Edge Services",
                linkedSites: "Verbundene Aussenstellen/IoT",
                fields: {
                  name: "Name",
                  sites: "Verbundene Aussenstellen",
                  iot: "Verbundene IoT Geräte",
                  vwan: "Arbeitsumgebung",
                  hub: "Hub",
                  region: "Region",
                  size: "Größe"
                }
              },
              sites: {
                label: "Sites",
                linkedHubs: "Verbundener Edge Service",
                fields: {
                  name: "Name",
                  location: "Standort",
                  gateway: "Edge Service",
                  providers: "Anzahl der Dienstleister"
                }
              },
              iot: {
                label: "IoT",
                linkedHubs: "Verbundener Edge Service",
                fields: {
                  name: "Name",
                  location: "Standort",
                  gateway: "Edge Service",
                  providers: "Anzahl der Dienstleister"
                }
              },
              standaloneSites: {
                label: "Eigenständige Site"
              }
            },
            status: {
              tabTitle: "Verbindungsstatus",
              ok: "OK",
              warning: "Warnung",
              error: "Fehler",
              online: "Verbunden",
              pending: "Ausstehend",
              connecting: "Verbinden...",
              degraded: "Eingeschränkt",
              offline: "Nicht verbunden"
            },
            offline: {
              tabTitle: "Nicht verbunden",
              gatewayName: "Edge Service Name",
              gatewayStatus: "Edge Service Status",
              siteName: "Aussenstelle / IoT Name",
              siteStatus: "Aussenstelle / IoT Status",
              siteLocation: "Aussenstelle / IoT Standort",
              noData: "Keine Offline-Tunnel gefunden."
            }
          },
          recentEvents: {
            adminTabTitle: "Stattgefundene administrative Ereignisse",
            systemTabTitle: "Stattgefundene Systemereignisse",
            latest: "Neueste {{length}}"
          },
          categories: {
            status: "Status"
          },
          ztnaSetup: {
            title: "Zero Trust Access einrichten",
            type: "Zero Trust Access",
            step1: "Point of Entry erstellen",
            step2: "Directory & Authentifizierung einrichten",
            step3: "App hinzufügen",
            step4: "ZTNA-Richtlinie konfigurieren",
            step5: "SecureEdge Agent ausrollen",
            setup: "Setup"
          }
        },
        status: "Status",
        security: "Security",
        webFilter: "Web-Filter",
        ztnaDashboard: "Zero Trust Access",
        ztnaUserDashboard: "Benutzer Dashboard"
      },
      network: {
        pageTitle: "Infrastruktur",
        vmActivation: "VM Aktivierung",
        vmActivationMessage: "Geben Sie Ihre Rechnungsdaten für die automatische VM-Aktivierung in allen Mandanten/Arbeitsbereichen ein. Dies ist nur bei der ersten Verwendung eines virtuellen Maschinen-Images erforderlich.",
        gateways: "Edge Services",
        sites: "Aussenstellen",
        iot: {
          menuTitle: "IoT",
          noData: {
            iconHeader: "Beginnen Sie mit dem Hinzufügen eines IoT-Geräts!",
            labelButton: "Hinzufügen eines IoT-Geräts",
            textHeader: "Was ist ein IoT-Gerät?",
            text: "Ein IoT-Gerät bezeichnet einen Internet-of-Things-Standort, der durch eine Barracuda Secure Connector Appliance verbunden (und geschützt) ist. Secure Connector Appliances können als Hardware oder virtuelle Appliance bereitgestellt werden - je nachdem, was Ihren Anforderungen am besten entspricht."
          }
        },
        settings: "Einstellungen",
        firewalls: "CloudGen Firewall",
        sdwanConnector: {
          menuTitle: "CONNECTOR",
          pageTitle: "Connectors",
          table: {
            status: "Status",
            name: "Name",
            gateway: "Edge Service",
            hostnames: "Hostnamen",
            internalResources: "Internal Ressourcen",
            provisioningDate: "Bereitstellungsdatum",
            servers: "Server",
            ellipsisMenu: {
              downloadLinux: "Den Linux-Agenten herunterladen",
              downloadWindows: "Den Windows-Agenten herunterladen",
              token: "Registrierungs-Token generieren"
            }
          },
          delete: {
            body: "Sind Sie sicher, dass Sie diesen Connector löschen möchten?",
            title: "Connector löschen {{name}}"
          },
          dialog: {
            add: "Hinzufügen",
            menuItemDisabled: "Connector benötigt einen Edge Service",
            basic: {
              name: "Name",
              sectionTitle: "Grundlegende Informationen",
              tabTitle: "Grundlagen"
            },
            create: {
              dialogTitle: "Connector hinzufügen"
            },
            edit: {
              dialogTitle: "Connector bearbeiten"
            },
            poolNetwork: {
              sectionTitle: "Geroutete IP-Adressen",
              descriptionMessage: "Bevor Sie Ihren ersten Connector hinzufügen können, müssen Sie einen Client-Netzwerkbereich festlegen. Das Client-Netzwerk wird verwendet, um den Ressourcen hinter den Connectors IPs zuzuweisen.",
              network: "Client-Netzwerk"
            },
            general: {
              sectionTitle: "Allgemein",
              name: "Name",
              description: "Beschreibung",
              appliance: "Edge Service",
              routedMode: "Routed"
            },
            servers: {
              sectionTitle: "Server",
              serviceName: "Server",
              itemName: "Server",
              hostname: "Hostname",
              internalIp: "Interne IP Adresse",
              category: "Kategorie",
              dnsSuffix: {
                label: "Internal Routed Hostname",
                value: "secureedge.barracuda.internal"
              },
              ports: "Ports"
            },
            summary: {
              sectionTitle: "Zusammenfassung",
              name: "Zusammenfassung der Konfiguration für SD-WAN Connector {{connector}}",
              connectorName: "Name",
              serviceName: "Service Name",
              hostname: "Hostname"
            },
            completed: {
              sectionTitle: "Connector \"{{connector}}\" erfolgreich erzeugt",
              sectionTitleNextSteps: "Nächste Schritte",
              sectionMessage: "Bitte führen Sie die folgenden Schritte aus, um den SD-WAN Connector zu benutzen",
              sectionSteps: "Herunterladen und Installieren des SD-WAN Connector",
              sectionSteps2: "Den Registrierungs-Token eintragen:",
              sectionSteps3: "Prüfen Sie, ob die Verbindung hergestellt ist",
              downloadButtonLinux: "Herunterladen für Linux",
              downloadButtonWindows: "Herunterladen für Windows"
            },
            description: {
              poolNetwork: {
                network: "Cient-Netzwerkbereich in CIDR-Notation"
              },
              general: {
                sectionTitle: "Grundlegend",
                name: "Eindeutiger Name für Ihren Connector.",
                description: "Kurze Beschreibung Ihres Connectors.",
                appliance: "Wählen Sie einen Edge Service oder Appliance aus, die Sie konfigurieren möchten.",
                routedMode: "Routed"
              },
              servers: {
                sectionTitle: "Server",
                serviceName: "Ein eindeutiger Name für den Server.",
                hostname: "Der Hostname, der sich auf den Server bezieht.",
                internalIp: "Die interne IP des Servers.",
                category: "Kategorie",
                dnsSuffix: "DNS Suffix",
                ports: "Geben Sie Ports als einzelne Zahlen (z.B. 80), Bereiche (z.B. 8443-8444) oder kommagetrennte Liste ein."
              }
            },
            token: {
              dialogTitle: "Registrierungs-Token generieren",
              message: "Registrierungs-Token generieren für \"{{sec}}\"",
              subLabel: "Connector Token"
            }
          },
          noData: {
            iconHeader: "Beginnen Sie mit dem Hinzufügen eines SD-WAN Connectors!",
            labelButton: "SD-WAN Connector hinzufügen",
            textHeader: "Was ist ein SD-WAN Connector?",
            text: "Die Bereitstellung von Anwendungen für Ihre Mitarbeiter kann eine schwierige Aufgabe sein.<0 /> Mit einem SD-WAN-Connector können Sie Windows- oder Linux- und Cloud-Dienste und -Server direkt in Ihr Netzwerk einbinden und sie Ihren Mitarbeitern zur Verfügung stellen, ohne dass ein dediziertes Standortgerät erforderlich ist."
          }
        },
        customCategories: "Benutzerdefinierte Kategorien",
        locations: {
          menuTitle: "STANDORTE",
          pageTitle: "Standorte",
          noData: {
            iconHeader: "Beginnen Sie mit dem Hinzufügen eines Standorts!",
            labelButton: "Standort hinzufügen",
            textHeader: "Was ist ein Standort?",
            text: "Ein Standort ist ein physischer oder virtueller Ort, der mit dem Barracuda SecureEdge-Netzwerk verbunden ist. Es kann sich um eine Zweigstelle, ein Rechenzentrum oder einen Cloud-Dienst handeln."
          },
          dialog: {
            add: "Hinzufügen",
            menuItemDisabled: "Standort benötigt einen Edge Service",
            create: "Neuen Standort hinzufügen",
            edit: "Standort bearbeiten",
            createContent: "Hier hinzugefügte Standorte können in Webfilter-Richtlinien und dergleichen verwendet werden. Sie können statische oder dynamische IPs haben. Für Letzteres muss die Helper-Anwendung auf Ihrem Gerät installiert sein.",
            name: "Standortname",
            type: "IP-Typ",
            ipAddress: "IP-Addresse",
            description: {
              name: "Eindeutiger Name für den Standort.",
              type: "Wählen Sie zwischen statischen und dynamischen IPs.",
              ipAddress: "Geben Sie die IP-Addressen ein."
            }
          },
          table: {
            name: "Name",
            ipAddresses: "IP-Adressen",
            dnsProxyContent: "Diese Standorte können innerhalb von SecureEdge in Webfilter-Richtlinien und dergleichen verwendet werden.<br/> Stellen Sie sicher, dass Sie DNS Proxy IPs in den Netzwerkeinstellungen Ihres lokalen Rechners wie unten angegeben einrichten:",
            primaryDNS: "Primary DNS Proxy: {{primaryDNS}}",
            secondaryDNS: "Secondary DNS Proxy: {{secondaryDNS}}"
          },
          delete: {
            body: "Sind Sie sicher, dass Sie diesen Standort löschen möchten?",
            title: "Standort löschen {{name}}"
          }
        }
      },
      endpoint: {
        pageTitle: "Zero Trust Access",
        devices: "Geräte",
        settings: "Einstellungen",
        zeroTrustAccess: "Zero Trust Access",
        zeroTrustSettingsTitle: "Einstellungen",
        pointToSite: "Point to Site",
        users: "Angemeldete Benutzer",
        appCatalog: {
          pageTitle: "App-Katalog",
          textHeader: "Was ist der App-Katalog?",
          text: "Mit dem App-Katalog können Sie die Anwendungen festlegen, die in der SecureEdge Access Agent-App für den schnellen Zugriff angezeigt werden.<0 />Diese Schnellzugriffsfunktion funktioniert sowohl für externe als auch für interne Anwendungen.<br/><br/>Diese Funktion wird in Kürze verfügbar sein.",
          startText: "Mit dem App-Katalog können Sie die Apps definieren, die in der SecureEdge Access Agent-App für den Schnellzugriff angezeigt werden.<0 />Diese Schnellzugriffsfunktion funktioniert sowohl für externe als auch für interne Apps.",
          startTextHeader: "Beginnen Sie mit dem Hinzufügen einer App!",
          buttonText: "App hinzufügen",
          iconText: "Symbol",
          name: "Name",
          application: "App",
          launchURL: "URL starten",
          visibility: "Visibilität",
          create: {
            title: "App hinzufügen",
            displayName: "Namen anzeigen",
            displayIcon: "Symbol anzeigen",
            associatedApplication: "Zugehörige App",
            users: "Benutzer",
            groups: "Gruppen",
            descriptions: {
              icon: "App-Symbol hochladen.",
              iconInfo: "Unterstützte Dateiformate: JPEG, PNG, GIF und SVG.",
              name: "Eindeutiger Name für die App.",
              application: "Zugehörige App auswählen",
              launchUrl: "Start-URL angeben",
              visibility: "Sichtbarkeit des Links auswählen",
              users: "Wählen Sie die Benutzer aus, denen der Zugriff auf die ausgewählten Apps gestattet werden soll. Wenn keine Benutzer oder Gruppen ausgewählt sind, sind die ausgewählten Apps für alle zugänglich.",
              groups: "Wählen Sie die Gruppen aus, denen der Zugriff auf die ausgewählten Apps gestattet werden soll. Wenn keine Benutzer oder Gruppen ausgewählt sind, sind die ausgewählten Apps für alle zugänglich."
            }
          },
          edit: {
            title: "App bearbeiten"
          },
          delete: {
            body: "Sind Sie sicher, dass Sie diese App löschen möchten?",
            title: "Löschen {{name}}"
          },
          validate: {
            userGroupRequired: "Mindestens ein Benutzer oder eine Gruppe erforderlich",
            tenantIdRequired: "Benutzer und Gruppen nicht verfügbar. Sie müssen zunächst ein Benutzer-Verzeichnis auf der Seite Identität > Einstellungen hinzufügen."
          }
        },
        enrollEmailDialog: {
          addEmail: "Benutzer oder eMail hinzufügen",
          body: "Einrichtung",
          customMessage: "Einladungs-Mail",
          defaultContent: "Griass Di, \n\nSie wurden von Ihrer Organisation zur sicheren Fernzugriffsfunktion mit Barracuda SecureEdge hinzugefügt. \n\nUm Zugang zu erhalten, folgen Sie bitte diesen Anweisungen: \n\n1. Gehen Sie auf {{link}}\n2. Laden Sie den Barracuda VPN-Client herunter und installieren Sie ihn.\n3. Drücken Sie auf \"Enroll now\" um den Vorgang abzuschließen.\n\nSie sind nun in der Lage, eine Verbindung zu Barracuda SecureEdge von Ihrem Windows- oder macOS-Gerät aus herzustellen.",
          defaultSubject: "Sie haben sich bei Barracuda SecureEdge angemeldet",
          zeroTrustSubject: "Einladung zur Registrierung Ihres Barracuda SecureEdge Access Agent",
          emailTitle: "Benutzer registrieren",
          resendEmailTitle: "Einladung zur erneuten Einschreibung senden",
          noHtml: "die folgenden Sonderzeichen sind nicht zulässig: <>",
          recipients: "An",
          users: "Benutzer",
          send: "Senden",
          subject: "Betreff",
          enrollMessage: "Wählen Sie den Benutzer oder die Gruppe aus, der/die für SecureEdge Access registriert werden sollen. Benutzer müssen sich beim ersten Start des Agents registrieren. Sie können diesen Fortschritt auf der Seite Zugang > Benutzer verfolgen.<0>Sie haben noch {{count}} Seats frei.</0>",
          sendMessage: "Folgende Nachricht wird an die ausgewählten Benutzer und Gruppen gesendet. Sind Sie sicher, dass Sie Ihre Einladung senden möchten?",
          successHeader_plural: "Ihre Einladung zur Registrierung wird an {{count}} Benutzer gesendet!",
          successHeader: "Ihre Einladung zur Registrierung wird an {{count}} Benutzer gesendet!",
          successMessage_plural: "Sie können den Anmeldestatus für jeden Benutzer in der Benutzertabelle überprüfen.",
          successMessage: "Sie können den Anmeldestatus des Benutzers in der Benutzertabelle überprüfen",
          emailTemplate: {
            hello: "Hallo {{username}}!",
            message: "Sie sind eingeladen, Ihren Barracuda SecureEdge Access Agent zu registrieren. Dieser Agent sichert Ihren Zugang zu Unternehmensressourcen und schützt Ihre Geräte vor Cyber-Bedrohungen.",
            clickStart: "Bitte klicken Sie auf\"Start\" um den Selbstregistrierungsprozess zu starten.",
            start: "Start",
            copyLink: "Alternativ können Sie auch den unten stehenden Link direkt in Ihren SecureEdge Access Agent kopieren, um sich anzumelden.",
            exampleLink: "https://se.barracudanetworks.com/zerotrust/enroll/?uuid=22fc1190-8d5a-4b8e-803f-fd8819408305&endpoint=https://se.barracudanetworks.com"
          },
          addUsers: "Benutzer hinzufügen",
          addGroups: "Gruppen hinzufügen",
          userNotificationSectionTitle: "Benutzerbenachrichtigung (Optional)",
          enrollmentEmail: {
            label: "Einladungs-E-Mail",
            enabled: "Ja - beinhaltet Agent Download Link",
            disabled: "Nein - unbeaufsichtigte Installation"
          },
          groups: "Gruppen",
          enroll: "Registrierung",
          enrollMessageOld: "Wählen Sie die Benutzer oder Gruppen aus, die für SecureEdge Access eingeladen werden sollen. Der Benutzer muss sich beim ersten Start des Agents anmelden. Sie können diesen Fortschritt auf der Seite Access > Benutzer verfolgen.",
          enrollMessage_plural: "Wählen Sie die Benutzer oder Gruppen aus, die für SecureEdge Access registriert werden sollen. Benutzer muss sich beim ersten Start des Agents registrieren. Sie können diesen Fortschritt auf der Seite Zugang > Benutzer verfolgen.<0>Sie haben noch {{count}} Seats frei.</0>",
          resolvedUsers: "Die von Ihnen ausgewählten Benutzer/Gruppen haben mehr Benutzer als Seats zur Verfügung stehen. Bitte verwenden Sie das nachstehende Menü, um die Benutzer zu entfernen, die Sie jetzt nicht registrieren möchten, oder gehen Sie zurück, um andere Benutzer/Gruppen auszuwählen.",
          successHeaderOld: "Ihre Einladung zur Registrierung wird an '{{users}}' gesendet!",
          successHeaderOld_plural: "Ihre Einladung zur Registrierung wird an {{count}} Benutzer gesendet!",
          noEmailSuccessHeader: "{{count}} Benutzer werden registriert!",
          noEmailSuccessHeader_plural: "{{count}} Benutzer werden registriert!",
          validate: {
            userGroupRequired: "Mindestens ein Benutzer oder eine Gruppe erforderlich",
            userRequired: "Mindestens ein Benutzer erforderlich",
            seatLimit: "Registrieren Sie {{currentCount}} Benutzer ({{maxCount}} Seats verfügbar)"
          },
          reenrollMessage: "Die Einladung zur Registrierung erneut an den ausgewählten Benutzer senden.",
          noSeats: "Es sind keine Seats mehr frei.",
          seats: "Sie haben noch {{count}} Seat.",
          seats_plural: "Sie haben noch {{count}} Seats.",
          unSuccessHeaderAllUsersAlreadyEnrolled: "Der gewählte Benutzer ist bereits registriert.",
          unSuccessHeaderAllUsersAlreadyEnrolled_plural: "Alle gewählten Benutzer sind bereits registriert.",
          alreadyEnrolledUsersUnsuccessful_emailplural: "Es werden keine E-Mails verschickt.",
          alreadyEnrolledUsers: "{{totalCount}} der gewählten Benutzer ist bereits registriert.",
          alreadyEnrolledUsers_workspace: "{{totalCount}} der gewählten Benutzer ist bereits ({{unenrollableCount}} über ein anderes Konto bzw. Workspace) registriert.",
          alreadyEnrolledUsers_email: "{{totalCount}} der gewählten Benutzer ist bereits registriert. An diesen Benutzer werden keine E-Mails verschickt.",
          alreadyEnrolledUsers_emailworkspace: "{{totalCount}} der gewählten Benutzer ist bereits ({{unenrollableCount}} über ein anderes Konto bzw. Workspace) registriert. An diesen Benutzer werden keine E-Mails verschickt.",
          alreadyEnrolledUsers_plural: "{{totalCount}} der gewählten Benutzer sind bereits registriert.",
          alreadyEnrolledUsers_workspaceplural: "{{totalCount}} der gewählten Benutzer sind bereits ({{unenrollableCount}} über ein anderes Konto bzw. Workspace) registriert.",
          alreadyEnrolledUsers_emailplural: "{{totalCount}} der gewählten Benutzer sind bereits registriert. An diese Benutzer werden keine E-Mails verschickt.",
          alreadyEnrolledUsers_emailworkspaceplural: "{{totalCount}} der gewählten Benutzer sind bereits ({{unenrollableCount}} über ein anderes Konto bzw. Workspace) registriert. An diese Benutzer werden keine E-Mails verschickt."
        },
        remoteAccessPolicies: {
          delete: {
            title: "Zero Trust-Zugangsrichtlinie löschen {{name}}",
            body: "Sind Sie sicher, dass Sie diese Zero Trust-Zugangsrichtlinie löschen möchten?"
          },
          order: "Rangfolge",
          name: "Name",
          description: "Beschreibung",
          groupsGrantedAccess: "Gruppen die Zugriff haben",
          resources: "Resourcen",
          devicePosture: "Gerätezustand",
          usersGrantedAccess: "Benutzer, denen Zugang gewährt wurde",
          zeroTrustAccess: "Zero Trust Access",
          policy: "Richtlinie",
          create: "Zero Trust-Zugangsrichtlinie erstellen",
          edit: "Zero Trust-Zugangsrichtlinie bearbeiten",
          success: "Erfolg",
          successMessage: "Eine neue Zero Trust-Zugangsrichtlinie wurde erfolgreich erstellt.",
          createButton: "Richtlinie hinzufügen",
          deviceAttributes: "Gerätezustand",
          resourcesSection: {
            title: "Ressourcen",
            internalResource: "Interne Ressources (custom apps)",
            publicResource: "Öffentliche Ressourcen (SaaS)",
            resourceTypeLabel: "Ressourcentyp",
            resourceTypeDescription: "Wählen Sie \"Intern\" für Ressourcen, die nur intern über einen Einstiegspunkt zugänglich sind, und \"Öffentlich\" für öffentlich zugängliche Ressourcen.",
            resourcesLabel: "Ressourcen"
          },
          applications: "Anwendungen",
          accessCriteriaSection: "Zugangskriterien",
          users: "Benutzer",
          groups: "Gruppen",
          securityInspection: "Sicherheitsüberprüfung",
          screenLock: "Bildschirmsperre aktiviert",
          firewall: "Firewall aktiviert",
          jailbroken: "Blockieren von Jailbreak-Geräten",
          encryption: "Festplattenverschlüsselung erzwingen",
          appUpdates: "Aktualisierungen des Barracuda SecureEdge Access Agent",
          minimumVersion: "Minimale Version",
          platform: "Betriebssystem",
          osUpdates: "Betriebssystem updates",
          antivirus: "Antivirus aktiviert",
          descriptions: {
            name: "Eindeutiger Name für die Richtlinie.",
            description: "Kurze Beschreibung der Richtlinie.",
            resourceType: "Konfigurieren Sie entweder benutzerdefinierte Anwendungen oder externe Anwendungen von Drittanbietern.",
            applications: "Die Anwendung(en), für die diese Richtlinie gilt.",
            users: "Wählen Sie die Benutzer aus, denen der Zugriff auf die ausgewählte(n) Anwendung(en) gestattet werden soll. Wenn keine Benutzer oder Gruppen ausgewählt sind, sind diese für alle zugänglich.",
            groups: "Wählen Sie Gruppen aus, denen der Zugriff auf die ausgewählte(n) Anwendung(en) gestattet werden soll. Wenn keine Benutzer oder Gruppen ausgewählt sind, sind diese für alle zugänglich.",
            securityPosture: "Wählen Sie, ob die konfigurierten Geräteattribute erzwungen werden sollen oder ob Verstöße nur protokolliert werden sollen.",
            securityInspection: "Wenn diese Option aktiviert ist, wird der Datenverkehr zu den ausgewählten Anwendungen durch einen Tunnel an den SecureEdge Service geleitet um eine eingehendere Sicherheitsprüfung zu ermöglichen. Die Sicherheitsrichtlinien für Einstiegspunkt werden angewendet. Ist die Option deaktiviert, wird der Datenverkehr normal über das Internet geleitet.",
            screenLock: "Benutzer müssen eine Bildschirmsperre für ihre Geräte einrichten. Unterstützte Plattformen: Android, iOS.",
            firewall: "Die Benutzer müssen eine Firewall auf ihren Geräten konfiguriert und aktiviert haben. Unterstützte Plattformen: Mac OS, Windows.",
            jailbroken: "Verhindern Sie, dass kompromittierte Geräte Zugriff auf Ressourcen erhalten. Unterstützte Plattformen: Android, iOS.",
            encryption: "Benutzer müssen die Festplattenverschlüsselung aktivert haben. Unterstützte Plattformen: Android, iOS, Mac OS, Windows.",
            appUpdates: "Erfordert von Benutzern, den Barracuda SecureEdge Access Agent auf die neueste Version aktualisiert zu haben. Unterstützte Plattformen: Android, iOS, Linux, Mac OS, Windows.",
            osUpdates: "Anforderung das Betriebssystem (OS) auf die eingestellte Mindestversion aktualisiert zu haben. Unterstützte Plattformen: Android, iOS, Mac OS, Windows.",
            antivirus: "Benutzer müssen einen Antiviren-Dienst auf ihrem Gerät konfiguriert haben. Plattformen: Windows."
          },
          validatePlatform: "Betriebssystem bereits ausgewählt",
          validateDeviceCriteria: "mindestens ein Sicherheitsmerkmal muss aktiviert sein",
          lastMileOptimization: "Optimierung der letzten Meile",
          noData: {
            iconHeader: "Beginnen Sie mit dem Hinzufügen einer Zero Trust-Zugangsrichtlinie!",
            labelButton: "Zero Trust-Zugangsrichtlinie hinzufügen",
            textHeader: "Was ist eine Zero Trust-Zugangsrichtlinie?",
            text: "In diesem Abschnitt können Sie die Anforderungen definieren, die Endgeräte erfüllen müssen, um Zugang zum Netzwerk zu erhalten.<0 /> Sie können zum Beispiel verlangen, dass auf den Geräten die neuesten Betriebssystem-Updates installiert und die integrierten Sicherheitsfunktionen aktiviert sind, oder Sie können sogar gehackte ( jailbreak) Geräte sperren."
          }
        },
        pointsOfEntry: {
          title: "Zugangspunkte",
          table: {
            name: "Name",
            type: "Typ",
            size: "GRÖßE",
            location: "Ort",
            publicIp: "Öffentliche IP Adressen",
            noData: "Es wurden keine Zugangspunkte konfiguriert. Für die Verwendung von Zero Trust Access muss mindestens ein Zugangspunkt konfiguriert sein."
          },
          createButton: "Zugangspunkte hinzufügen",
          create: {
            title: "Zugangspunkte hinzufügen",
            configurationType: "Typ",
            site: "Aussenstelle",
            gateway: "Edge Service",
            firewall: "Firewall",
            ipAddress: "Öffentliche IP Adressen",
            sites: "Aussenstellen",
            gateways: "Edge Services",
            firewalls: "Firewalls",
            descriptions: {
              sites: "Aussenstellen, die als Zugangspunkte hinzugefügt werden können.",
              gateways: "Edge Services, die als Zugangspunkte hinzugefügt werden können..",
              firewalls: "Firewalls, die als Zugangspunkte hinzugefügt werden können."
            },
            validateSelectOne: "Es muss mindestens ein Firewall, Aussenstelle oder Edge Service ausgewählt werden."
          },
          edit: {
            title: "Zugangspunkte ändern",
            name: "Name",
            ipAddress: "Öffentliche IP Adressen",
            descriptions: {
              ipAddress: "Die öffentlichen IP-Adresse, über die diese Firewall erreichbar ist."
            }
          },
          delete: {
            title: "Zugangspunkt {{name}} löschen",
            body: "Sind Sie sicher, dass Sie diesen Zugangspunkt löschen möchten?"
          },
          noData: {
            iconHeader: "Beginnen Sie mit dem Hinzufügen eines Zugangspunktes!",
            labelButton: "Zugangspunkt hinzufügen",
            textHeader: "Was ist ein Zugangspunkt?",
            text: "Ein Point of Entry (PoE) dient als Verbindungspunkt für Ihre SecureEdge Access Agents.<0 /> Die Agents wählen automatisch den nächstgelegenen verfügbaren PoE, um eine optimale Servicequalität zu gewährleisten."
          }
        },
        zeroTrustSettings: {
          agentWebFiltering: "Web-Filterung mit SecureEdge Access Agent",
          dnsSuffix: "Client Network DNS Suffix",
          downloadCertificateHelpText: "Laden Sie dieses Root Certificate herunter und führen Sie es auf allen Clients aus, um parallele Vorgänge mit CloudGen-Firewall-Client-to-Site-Setups und dem SecureEdge Access Agent sicherzustellen.",
          downloadCertificateLabel: "Zertifikat herunterladen",
          networkSectionTitle: "Netzwerkkonfiguration des Agenten",
          clientNetworkDescription: "Der Endgeräte-IP-Adressbereich wird verwendet, um IP-Adressen Endgeräten zuzuweisen, die über den SecureEdge Access Agent eine Verbindung herstellen. Er ist in Pools unterteilt, die dann auf jeden Zugangspunkt verteilt werden. Diese Einstellung ist für mehrere Zugangspunkte erforderlich.",
          manualConfigurationEnabled: "Manuelle Konfiguration verwenden",
          clientNetworkRange: "Endgeräte-IP-Adressbereich",
          clientNetworkSize: "Die nachstehende Konfiguration bietet ein Minimum an <0> {{connections}} Endgerätverbindungen </0> pro Zugangspunkt.",
          clientNetworkPoolSize: "Pool Bitmaske",
          validatePoolSize: "muss kleiner sein als das Client-Netzwerk",
          enforce: "Erzwingen",
          disable: "Deaktivieren",
          globalZtnaSettings: "Einstellungen Zero Trust Access"
        },
        ztnaUsers: {
          dashboardTabTitle: "Dashboard",
          settingsTabTitle: "Einstellungen",
          userNotFound: "Benutzer nicht gefunden"
        }
      },
      securityAndAccess: {
        pageTitle: "Security Richtlinie",
        apps: "Anwendungen und Ressourcen",
        network: "Netzwerk ACL",
        gateway: "Edge Service ACL",
        site: "Aussenstellen ACL",
        sdwan: "SD-WAN",
        categories: "App-Kategorien",
        explicit: "Benutzerdefinierte Kategorien",
        security: "Security",
        urlFilter: "Richtlinien",
        webFilter: "Web-Filter",
        malware: "Advanced Threat Protection",
        ssl: "SSL Inspektion",
        ips: "IPS",
        ingressNatRules: "Zugangs NAT",
        webFilterSettings: "Einstellungen",
        webMonitorPolicies: "Web-Monitoring",
        settings: "Einstellungen",
        customResponse: "Benutzerdefinierte Antworten"
      },
      subscription: {
        pam: {
          menuTitle: "Subscriptions",
          pageTitle: "Profil",
          pageSubTitle: "Subscriptions",
          table: {
            subscription: "Subscriptions",
            serial: "Lizenz-Seriennummer",
            amount: "Menge",
            startDate: "Startdatum",
            expirationDate: "Ablaufdatum",
            noData: {
              iconHeader: "Beginnen Sie mit dem Hinzufügen einer Subscription!",
              textHeader: "Wie erhalte ich eine Subscription-Lizenz?",
              text: "Subscriptions ermöglichen zusätzliche Funktionalitäten in Ihrer SecureEdge-Installation.<0/> Wenn Sie zum Beispiel zusätzliche SecureEdge Access Seats für Ihre Remote-Mitarbeiter hinzufügen möchten, verwenden Sie einfach diese Schnittstelle, um die erforderliche Anzahl von Seats zu Ihrer Bereitstellung hinzuzufügen.<0/> Um die beste Subscription für Ihre Bedürfnisse zu erhalten, wenden Sie sich einfach an Barracuda Sales (barracuda.com/contact-sales)."
            },
            information: "Information",
            value: "{{value}}",
            value_34: "{{value}} Benutzer",
            value_24: "{{value}} Mbps",
            msp: "MSP in Rechnung gestellt"
          }
        }
      },
      appliances: {
        dashboard: {
          deployment: {
            status: {
              title: "Deployment-Status",
              status: "Status",
              action: "Aktion",
              message: "Meldung",
              registered: "Registriert",
              lastSeen: "Zuletzt gesehen",
              completed: "Fertiggestellt",
              localIp: "Lokale IP-Adresse",
              remoteIp: "Öffentliche IP-Adresse",
              serial: "Serie"
            },
            events: {
              title: "Ereignisse bei der Bereitstellung",
              state: "Stand",
              status: "Status",
              created: "Datum",
              siteDeploying: "Bereitstellung in Arbeit"
            },
            location: {
              title: "Bereitstellungsort"
            }
          },
          details: {
            servicesCardTitle: "Services",
            interfaces: {
              title: "Schnittstellen",
              status: "Status",
              name: "Name",
              speed: "Geschwindigkeit",
              duplex: "Duplex",
              errors: "Fehler",
              state: {
                ok: "OK",
                warning: "Warnung",
                error: "Fehler",
                unknown: "Unbekannt",
                loading: " "
              }
            },
            boxResources: {
              title: "Ressourcen",
              cpuLoad: "CPU",
              cpuLoadError: "Die CPU hat einen Fehler erkannt. Bitte starten Sie die Appliance neu. Wenn dieser Fehler anhält, wenden Sie sich an den Barracuda Support.",
              memory: "Speicher",
              diskInformation: "Plattenplatz",
              diskInformationError: "Auf einer der Festplattenpartitionen wurde ein Fehler erkannt. Bitte starten Sie die Appliance neu. Wenn dieser Fehler anhält, wenden Sie sich an den Barracuda Support."
            },
            vpnTunnels: {
              title: "VPN-Tunnel",
              instanceId: "Instanz-Serie",
              status: "Status",
              name: "Name",
              peer: "Peer",
              local: "Lokal",
              type: "Typ",
              state: {
                UP: "Up",
                DOWN: "Down",
                INACTIVE: "Inaktiv",
                FAIL: "Down - Fehlgeschlagen",
                STANDBY: "Down - Standby",
                "DOWN (PASSIVE)": "Down - Passiv",
                "DOWN (FAIL)": "Down - Fehlgeschlagen",
                "DOWN (STANDBY)": "Down - Standby",
                "DOWN (DISABLED)": "Deaktiviert",
                PENDING: "Ausstehend"
              }
            }
          },
          firmware: {
            details: {
              tabTitle: "Firmware",
              version: "Version",
              hotfixes: "Installierte Hotfixe",
              window: "Nächstes Update",
              nextUpdate: "Zwischen {{from}} und {{to}}"
            },
            hotfixes: {
              tabTitle: "Hotfixe",
              status: "Status",
              name: "Name"
            },
            updates: {
              tabTitle: "Konfigurations-Updates",
              lastUpdate: "Letztes Update",
              lastSeen: "Zuletzt gesehen",
              remoteIp: "Remote IP"
            },
            state: {
              error: "Fehler",
              ok: "OK",
              unknown: "Unbekannt",
              warning: "Ausstehend",
              loading: " "
            }
          },
          siteStatus: {
            tabTitle: "Verbundene Standorte"
          },
          iotStatus: {
            tabTitle: "Verbundene IoT-Geräte"
          },
          summary: {
            siteStatus: {
              title: "Status",
              model: "Modell",
              serials: "Seriennummer(n)",
              serial: "Seriennummer",
              release: "Release"
            },
            licenses: {
              title: "Lizenzen",
              type: "Typ",
              state: "Stand",
              status: "Status",
              expirationDate: "Ablaufdatum",
              serial: "Seriennummer",
              noData: "N/A"
            },
            gateway: {
              tabTitle: "Edge Service",
              noGateway: "Für diesen Standort wurde kein Edge Service konfiguriert",
              name: "Name",
              region: "Region",
              hubName: "Hub",
              vwan: "Cloud VWAN",
              size: "Größe",
              publicIP: "Öffentl. IP Adresse",
              publicIPs: "Öffentl. IP Adresse(n)",
              serial: "Seriennummer(n)",
              instanceNotFound: "Instance not found"
            },
            status: {
              tabTitle: "Status",
              hub: "Hub",
              region: "Region",
              vwan: "Cloud VWAN",
              connectedSites: "Verbundene Standorte"
            }
          },
          tunnelLocations: {
            title: "Karte",
            gateways: {
              label: "Edge Services",
              fields: {
                name: "Name",
                sites: "Verbundene Standorte",
                iot: "Verbundene IoT Geräte",
                vwan: "Arbeitsbereich",
                hub: "Hub",
                region: "Region",
                size: "Größe"
              }
            },
            iot: {
              label: "IoT"
            },
            sites: {
              label: "Standorte",
              fields: {
                name: "Name",
                location: "Standort",
                gateway: "Edge Service",
                providers: "Anzahl der Provider "
              }
            },
            standaloneSites: {
              title: "Karte",
              label: "Eigenständige Site"
            }
          }
        },
        dialog: {
          createType: {
            sectionTitle: "Edge Service Typ",
            new: "Neuer Private Edge Service",
            promote: "Bestehendes Site Device zu Private Edge Service upgraden"
          },
          siteSelection: {
            sectionTitle: "Auswahl des Standortes",
            mainMessage: "Wählen Sie den Standort aus, den Sie zu einem Private Edge Service machen möchten.",
            secondaryMessage: "Hinweis: Alle zugehörigen Richtlinien (z. B. ACLs, URL-Filter usw.) werden bei dem Vorgang gelöscht.",
            haWarning: "Dieser Standort ist nicht hochverfügbar. Es wird empfohlen, für Private Edge Services hochverfügbare Umgebungen zu verwenden.",
            site: "Standort"
          },
          basic: {
            gateway: "Edge Service",
            upstream_gateway: "Vorgelagert Edge Service",
            noGateway: "(Keiner)",
            name: "Name",
            sectionTitle: "Generelle Informationen",
            tabTitle: "Generell",
            rootPassword: "Root Passwort",
            rootPasswordConfirmation: "Bitte bestätigen Sie das Root Passwort",
            descriptions: {
              gateway_site: "Der Barracuda SecureEdge Edge Service, mit dem sich dieser Standort verbinden soll.",
              gateway_gateway: "Der Barracuda SecureEdge Edge Service, mit dem sich dieser Edge Service verbinden soll.",
              name_site: "Ein eindeutiger Name für den Standort.",
              name_gateway: "Ein eindeutiger Name für den Edge Service.",
              rootPassword_site: "Das Passwort des Root-Benutzers für den Standort.",
              rootPassword_gateway: "Das Passwort des Root-Benutzers für den Edge Service",
              upstream_gateway: "Der Barracuda SecureEdge Edge Service, mit dem sich dieser Edge Service upstream verbinden soll."
            }
          },
          serials: {
            gatewayMessage: "Wählen Sie die Edge Service-Appliances aus, die Sie konfigurieren möchten. Wir empfehlen, zwei Appliances auszuwählen, um einen Hochverfügbarkeits-Cluster zu erstellen.",
            TVTSiteMessage: "Wählen Sie die Site Appliances aus, die Sie konfigurieren möchten. Sie können entweder eine einzelne Appliance oder zwei Appliances auswählen, wenn Sie einen Hochverfügbarkeits-Cluster einrichten möchten.",
            SCSiteMessage: "Wählen Sie die Secure Connector-Appliance aus, die Sie konfigurieren möchten.",
            haMessage: "<1>Achtung:</1> Beide Appliances in einem HA-Cluster müssen das gleiche Modell und die gleiche Firmware-Version haben.",
            sectionTitle_site: "Standort Appliances",
            sectionTitle_gateway: "Edge Service Appliances",
            addAppliance: "Fügen Sie fehlende Geräte nach Seriennummer und linking code oder Lizen-Token hinzu",
            linkingCode: "Code/Token",
            table: {
              serial: "Seriennummer",
              model: "Modell",
              location: "Standort",
              orderId: "Auftragsnummer",
              orderDate: "Auftragsdatum"
            }
          },
          wans: {
            sectionTitle: "WANs",
            message: "Ihr Gerät hat gesamt {{portsAvailable}} Ports für WAN and LAN Aufteilung.",
            message2_site: "Wie viele WAN-Verbindungen möchten Sie für diesen Standort konfigurieren?",
            message2_gateway: "Wie viele WAN-Verbindungen möchten Sie für diesen Edge-Service konfigurieren?",
            size: "Anzahl WAN Verbindungen",
            setup: "WAN Verbindung konfigurieren {{step}}",
            name: "Name",
            port: "Port",
            virtualLanId: "Virtual LAN ID",
            type: "Typ",
            providerPinning: "Provider Pinning",
            address: "IP Addresse",
            netmask: "Netzmaske",
            gateway: "Gateway",
            pin: "SIM Pin für {{serial}}",
            invalidPinFormat: "SIM Pin sollte 4 stellig sein",
            SCmessage: "Ihr Gerät verfügt über einen einzigen zu konfigurierenden WAN-Port.",
            SCmessage_failover: "Ihr Gerät kann bis zu 2 WAN-Verbindungen haben (eine primäre und eine Failover-Verbindung).",
            SCconfigureFailover: "Möchten Sie eine Ausfallschutz für diesen Standort konfigurieren?",
            failover: "WAN-Ausfallschutz",
            setupSC: "Primäre WAN-Verbindung konfigurieren",
            setupSC_0: "Primäre WAN-Verbindung konfigurieren",
            setupSC_1: "Failover-WAN-Verbindung konfigurieren",
            descriptions: {
              name: "Ein eindeutiger Name für diese WAN-Verbindung.",
              port: "Der Port, der für diese Verbindung verwendet werden soll.",
              virtualLanId: "Optionale Virtual-LAN-ID für das Netzwerk (wird verwendet, wenn eine Verbindung zu einem Switch mit Virtual-LAN-Unterstützung besteht).",
              type: "Der Netztyp, der für diese Verbindung verwendet werden soll.",
              providerPinning: "Die Anbietergruppe, der dieser Link zugeordnet werden soll. Anbietergruppen werden in den SD-WAN-Richtlinien verwendet, um den Verkehrsfluss und das Verhalten zu definieren.",
              address: "Die IP-Adresse, die dem Anschluss zugewiesen werden soll.",
              netmask: "CIDR-Netzmaskensuffix für diese Verbindung.",
              gateway: "Die mit dieser Verbindung verbundene Gateway-IP-Adresse.",
              pin: "Die persönliche Identifikationsnummer (PIN) für die in diesem Gerät installierte SIM-Karte."
            },
            bridgeType: {
              name: "Bridge",
              address: "IP-Adresse",
              gateway: "Gateway",
              lanPorts: "LAN Ports",
              netmask: "Netzmaske",
              wanPort: "WAN Port",
              descriptions: {
                address: "Die dem Port zugewiesene IP-Adresse.",
                gateway: "Die mit diesem Link verknüpfte Gateway IP-Adresse.",
                lanPorts: "Der zu verwendende LAN Port",
                netmask: "Das Network CIDR Netmask Suffix",
                wanPort: "Der zu verwendende WAN Port"
              }
            },
            pppoeType: {
              name: "Benutzername",
              password: "Passwort",
              description: {
                name: "Eindeutiger Name für dieses PPPoE.",
                password: "Eindeutiges Passwort"
              }
            },
            validation: {
              mode: "Wenn ein Edge Service ausgewählt ist, ist keine Bridging-Funktion möglich."
            }
          },
          lans: {
            sectionTitle: "LANs",
            message: "Ihr Gerät verfügt über insgesamt {{portsAvailable}} Ports, die sowohl für WAN als auch für LAN verfügbar sind, und Sie haben {{wans}} dem WAN zugewiesen.",
            message2_site: "Wie viele LAN-Verbindungen möchten Sie für diesen Standort konfigurieren?",
            message2_gateway: "Wie viele LAN-Verbindungen möchten Sie für diesen Edge-Service konfigurieren?",
            size: "Anzahl LAN Verbindungen",
            setup: "LAN Verbindung konfigurieren {{step}}",
            name: "Name",
            port: "Port",
            virtualLanId: "Virtuelle LAN ID",
            address: "IP Addresse",
            netmask: "Netzmaske",
            dhcpEnable: "DHCP Server",
            dhcpDescription: "Bechreibung",
            dhcpFirstIp: "Erste IP-Adresse",
            dhcpLastIp: "Letzte IP-Adresse",
            dhcpDomain: "DHCP-Domäne",
            descriptions: {
              dhcpEnable: "Aktiviert den DHCP-Server für diese LAN-Verbindung.",
              name: "Ein eindeutiger Name für diese LAN-Verbindung.",
              port: "Der Geräteanschluss, der für diese Verbindung verwendet werden soll.",
              virtualLanId: "Optionale Virtual-LAN-ID für das Netzwerk (zur Verwendung, wenn eine Verbindung zu einem Switch mit Virtual-LAN-Unterstützung besteht).",
              address: "Die IP-Adresse, die dem Port zugewiesen werden soll.",
              netmask: "Der CIDR-Netzmaskensuffix, das für das erstellte Netzwerk verwendet werden soll.",
              dhcpDescription: "Benutzerdefinierte Beschreibung für dieses dhcp-Netzwerk.",
              dhcpFirstIp: "Erste IP-Adresse, die in diesem dhcp-Netzwerk zugewiesen werden kann.",
              dhcpLastIp: "Letzte IP-Adresse, die in diesem dhcp-Netzwerk zugewiesen werden kann.",
              dhcpDomain: "Domain name for this dhcp service.",
              wifi: {
                ssid: "Die SSID des WiFi-Netzwerkes.",
                securityMode: "Der Wi-Fi-Sicherheitsmodus, den Sie verwenden möchten.",
                passphrase: "Passphrase für das Wi-Fi Netzwerk."
              },
              type: "Der Schnittstellentyp. Kann als standardmäßiges LAN Interface oder Bridge konfiguriert sein."
            },
            type: "Typ",
            bridgeType: {
              default: "Single Port",
              name: "Bridge",
              address: "IP-Adresse",
              lanPorts: "Ports",
              netmask: "Netzmaske",
              description: {
                address: "Die dem Port zugewiesene IP-Adresse.",
                lanPorts: "Die zu verwendenden LAN Ports",
                netmask: "Das Network CIDR Netmask Suffix"
              }
            },
            dhcpRelayType: "DHCP",
            validation: {
              dhcpRelayType: "kann nicht aktiviert werden, wenn SecureEdge eingestellt ist"
            }
          },
          advanced: {
            sectionTitle: "Erweiterte Einstellungen",
            updateWindowTZ: "Zeitzone des Updatefensters",
            primaryDns: "Primäre DNS Server",
            secondaryDns: "Sekundärer DNS Server",
            descriptions: {
              updateWindowTZ_site: "Die Zeitzone für den Standort, die zur Bestimmung des Updatefensters für diesen Standort verwendet wird.",
              updateWindowTZ_gateway: "Die Zeitzone für den Edge Service, die zur Bestimmung des Updatefensters für diesen Edge Service verwendet wird.",
              primaryDns: "Legen Sie die primäre DNS-Adresse manuell fest.",
              secondaryDns: "Legen Sie die sekundäre DNS-Adresse manuell fest."
            }
          },
          submit: {
            message_site: "Bitte überprüfen Sie die Details für den Standort und klicken Sie auf Speichern",
            message_gateway: "Bitte überprüfen Sie die Details für den Edge Service und klicken Sie auf Speichern",
            name: "Name",
            model: "Modell",
            description: "Beschreibung",
            serial: "Seriennumer",
            gateway: "Edge Service",
            noGateway: "(keiner)",
            upstream_gateway: "Edge Service upstream",
            timezone: "Zeitzone",
            status: "Verbindungsstatus",
            peeringAddress: "Peering IP",
            wan: "WAN",
            lan: "LAN",
            primaryDns: "Primärer DNS Server",
            secondaryDns: "Sekundärer DNS Server",
            haWarning: "Dieser Standort ist nicht hochverfügbar. Es wird empfohlen, für Private Edge Services hochverfügbare Umgebungen zu verwenden."
          },
          result: {
            success_site: "Standort wurde erfolgreich erstellt",
            success_gateway: "Edge Service wurde erfolgreich erstellt",
            connectPort: "Bitte stellen Sie sicher, dass Sie den Port <0>{{port}}</0> der Appliance an eine DHCP-fähige Internetverbindung anschließen, um die automatische Konfiguration Ihrer Appliance zu ermöglichen. Wenn Sie einen anderen Port verwenden möchten, kann dieser direkt auf der Appliance konfiguriert werden.",
            connectPort_plural: "Bitte stellen Sie sicher, dass Sie den Port <0>{{port}}</0> der Appliances an eine DHCP-fähige Internetverbindung anschließen, um die automatische Konfiguration Ihrer Appliances zu ermöglichen. Wenn Sie einen anderen Port verwenden möchten, kann dieser direkt auf den Appliances konfiguriert werden.",
            HAMessage: "Verbinden Sie Port <0>P1</0> der beiden Appliances direkt mit einem Netzwerkkabel. Die HA-Synchronisierung wird automatisch gestartet.",
            campus: "Für weitere Informationen siehe <0>Barracuda Campus</0>",
            serial: "Seriennummer: {{serial}}",
            nonstandardPort: "Warning: Warnung: Diese Konfiguration verwendet einen nicht standardisierten Port:<0>{{ports}}</0>. Bitte stellen Sie sicher, dass er auf Ihrer virtuellen Appliance aktiviert ist.",
            nonstandardPort_plural: "Diese Konfiguration verwendet nicht standardisierte Ports:<0>{{ports}}</0>. Bitte stellen Sie sicher, dass diese auf den virtuellen Appliances aktiviert sind."
          },
          promoteResult: {
            primaryMessage: "Standortgerät erfolgreich zum Edge Service befördert.",
            secondaryMessage: "Sie können nun damit beginnen, neue oder bestehende Standorte anzubinden."
          },
          steps: {
            result: "Fertig",
            submit: "Zusammenfassung"
          },
          types: {
            dynamic: "Dynamisch (DHCP)",
            configure_on_branch: "In der Zweigstelle konfiguriert"
          },
          validation: {
            duplicateNetwork: "ein Netzwerk, das mit diesem Port/virtuellen LAN-ID übereinstimmt, wurde bereits konfiguriert",
            duplicateWwan: "es kann nur ein WWAN konfiguriert werden",
            duplicateExpressRoute: "es kann nur eine Expressroute konfiguriert werden",
            duplicateName: "der Name wird bereits für eine andere Verbindung verwendet",
            secondaryDns: "sollte nicht gesetzt werden, wenn es keinen primären DNS gibt",
            availableAsn: "dieser asn-Wert ist nicht verfügbar",
            publicIp: "Die IP-Adresse muss im öffentlichen Bereich liegen.",
            noLans: "Muss mindestens ein LAN haben",
            exceededPPoE: "Es können nur bis zu vier WAN PPPoEs konfiguriert werden",
            oneInlineBridge: "Es kann nur eine WAN Bridge konfiguriert werden"
          }
        },
        settings: {
          tabTitle: "Einstellungen",
          basic: {
            tabTitle: "Grundlegende Informationen",
            name: "Name",
            model: "Modell",
            gateway: "Edge Service",
            serials: "Seriennummer(n)",
            descriptions: {
              gateway_site: "Der Barracuda SecureEdge Edge Service, mit dem sich dieser Standort verbinden soll.",
              gateway_gateway: "Der upstream Barracuda SecureEdge Edge Service, mit dem sich dieser Edge Service verbinden soll.",
              name_site: "Ein eindeutiger Name für Ihren Standort.",
              name_gateway: "Ein eindeutiger Name für Ihren Edge Service.",
              model_site: "Das Gerätemodell für diesen Standort.",
              model_gateway: "Das Gerätemodell für diesen Edge Service.",
              serials_site: "Die Seriennummer(n) der Geräte für diesen Standort",
              serials_gateway: "Die Seriennummer(n) der Geräte für diesen Edge Service."
            },
            serialEdit: {
              table: {
                status: "Status",
                serial: "Seriennummer",
                add: "Zweite hinzufügen"
              },
              addTitle: "Seriennummer hinzufügen",
              editTitle: "Seriennummer tauschen",
              deleteTitle: "Seriennummer löschen",
              edit_site: "Wählen Sie unten die Seriennummer des Ersatzgerätes aus, das Sie an diesen Standort anschließen möchten. Die Geräte können nur durch ein anderes Gerät desselben Modells ersetzt werden.",
              edit_gateway: "Wählen Sie unten die Seriennummer des Ersatzgeräts aus, das Sie mit diesem Edge Service verbinden möchten. Geräte können nur durch ein anderes Gerät desselben Modells ersetzt werden.",
              edit: "Wählen Sie unten die Seriennummer des Ersatzgeräts aus, das Sie an diesen Standort anschließen möchten. Die Geräte können nur durch ein anderes Gerät desselben Modells ersetzt werden.",
              add_site: "Wenn Sie eine zweite Appliance an Ihren Standort anschließen, wird die Hochverfügbarkeitsfunktion aktiviert. Bitte wählen Sie unten die Seriennummer Ihrer Appliance aus.",
              add_gateway: "Wenn Sie eine zweite Appliance zu Ihrem Edge Service hinzufügen, wird die Hochverfügbarkeitsfunktion aktiviert. Bitte wählen Sie unten die Seriennummer Ihrer Appliance aus.",
              add: "Wenn Sie eine zweite Appliance zu Ihrem Standort hinzufügen, wird die Hochverfügbarkeitsfunktion aktiviert. Bitte wählen Sie unten die Seriennummer der Appliance aus.",
              delete1_site: "Sind Sie sicher, dass Sie Seriennummer {{serial}} von diesem Standort entfernen wollen?",
              delete1_gateway: "Sind Sie sicher, dass Sie Seriennummer {{serial}} von diesem Edge Service entfernen wollen?",
              delete1: "Sind Sie sicher, dass Sie Seriennummer {{serial}} von diesem Standort entfernen wollen?",
              delete2: "Dadurch wird die Hochverfügbarkeitsfunktionalität entfernt und die entsprechende Appliance wird deaktiviert.",
              save: "Die Änderungen werden erst nach dem Speichern wirksam.",
              saveDelete: "Nach dem Speichern können Sie das Gerät vom Netz trennen.",
              saveAdd: "Nach dem Speichern können Sie das neue Gerät anschließen. Verbinden Sie zunächst Port <0>P4</0> des Gerätes mit einer DHCP-fähigen Internetverbindung, um die automatische Konfiguration Ihres Geräts zu ermöglichen. Wenn Sie einen anderen Port verwenden möchten, kann dieser direkt auf der Appliance konfiguriert werden.",
              saveSCAdd: "Nach dem Speichern können Sie die neue Appliance anschließen, indem Sie den WAN-Port mit einer DHCP-fähigen Internetverbindung verbinden, um die automatische Konfiguration Ihrer Appliance zu ermöglichen.",
              saveEdit: "Nach dem Speichern können Sie die alte Appliance trennen. Schließen Sie dann das neue Gerät an. Verbinden Sie zunächst Port <0>P4</0> des Geräts mit einer DHCP-fähigen Internetverbindung, um die automatische Konfiguration Ihres Geräts zu ermöglichen. Wenn Sie einen anderen Port verwenden möchten, können Sie diesen direkt auf dem Gerät konfigurieren.",
              saveSCEdit: "Nach dem Speichern können Sie die alte Appliance trennen. Schließen Sie dann die neue Appliance an und verbinden Sie den WAN-Port mit einer DHCP-fähigen Internetverbindung, um die automatische Konfiguration Ihrer Appliance zu ermöglichen.",
              saveHA: "Verbinden Sie außerdem Port <0>P1</0> der neuen Appliance und der vorhandenen Appliance direkt mit einem Netzwerkkabel. Die HA-Synchronisierung wird automatisch gestartet.",
              saveEditSecondary: "Die alte Appliance wurde für HA konfiguriert. Bevor sie wieder verwendet werden kann, muss sie neu installiert werden.",
              saveDeleteSecondary: "Diese Appliance wurde für HA konfiguriert. Bevor sie wieder verwendet werden kann, muss sie neu installiert werden.",
              saveDeletePrimary_site: "Die andere Appliance, die an diesen Standort angeschlossen ist, wurde für HA konfiguriert. Sie muss nach dem Speichern neu installiert werden, damit sie sich als Nicht-HA-Appliance neu konfigurieren kann. Dies bedeutet eine gewisse Ausfallzeit für Ihren Standort, bis dieser Prozess abgeschlossen ist.",
              saveDeletePrimary_gateway: "Die andere Appliance, die an diesen Edge Service angeschlossen ist, wurde für HA konfiguriert. Sie muss nach dem Speichern neu installiert werden, damit sie sich als Nicht-HA-Appliance neu konfigurieren kann. Dies bedeutet eine gewisse Ausfallzeit für Ihren Edge Service, bis dieser Prozess abgeschlossen ist.",
              saveDeletePrimary: "Die andere Appliance, die an diesen Standort angeschlossen ist, wurde für HA konfiguriert. Sie muss nach dem Speichern neu installiert werden, damit sie sich als Nicht-HA-Appliance neu konfigurieren kann. Dies bedeutet eine gewisse Ausfallzeit für Ihren Standort, bis dieser Prozess abgeschlossen ist."
            }
          },
          wan: {
            tabTitle: "WAN",
            addTitle: "Neue WAN-Schnittstelle hinzufügen",
            addButtonText: "WAN-Schnittstelle hinzufügen",
            addSCButtonText: "WAN-Fallback hinzufügen",
            editTitle: "WAN-Schnittstelle bearbeiten",
            deleteTitle: "WAN-Schnittstelle löschen",
            deleteMessage: "Sind Sie sicher, dass Sie dieses WAN Interface löschen wollen?<0/>Alle mit diesem WAN verbundenen Verbindungen und Richtlinien (z.B. IPsec-Tunnel) werden automatisch entfernt.<0/>Die Löschung wird erst beim Speichern wirksam.",
            name: "Name",
            port: "Port",
            virtualLanId: "Virtuelle LAN ID",
            type: "Typ",
            providerPinning: "Provider Pinning",
            fallback: "Fallback",
            address: "IP Addresse",
            netmask: "Netzmaske",
            gateway: "Gateway",
            pin: "SIM Pin für {{serial}}",
            scPin: "SIM Pin",
            invalidPinFormat: "SIM Pin muss aus 4 Nummern bestehen",
            portWarning: "Die ausgegrauten Zeilen oben zeigen an, dass der ausgewählte Anschluss auf der Appliance nicht aktiviert wurde.",
            failover: "HA-Ausfallsicherung",
            network: "Netzwer",
            doublePort: "Port",
            doubleVirtualLanId: "Virtuelle LAN ID",
            doubleAddress: "IP Addresse",
            doubleNetmask: "Netzmaske",
            virtualTransferNetwork: "Virtuelles Transfernetz",
            asn: "Express Route ASN",
            expressRoute: "Express Route",
            primary: "Primär",
            secondary: "Sekundär",
            validatePublicIp: "muss eine öffentliche IP für User Connectivity sein",
            validateOnePort: "Sie können nur einen dynamischen/statischen WAN-Anschluss und einen WWAN-Anschluss konfigurieren.",
            descriptions: {
              name: "Ein eindeutiger Name für diese WAN-Verbindung.",
              port: "Der Geräteanschluss, der für diese Verbindung verwendet werden soll.",
              virtualLanId: "Optionale Virtual LAN ID für das Netzwerk (wird verwendet, wenn eine Verbindung zu einem Switch mit VLAN-Unterstützung besteht).",
              type: "Der Netztyp, der für diese Verbindung verwendet werden soll.",
              providerPinning: "Die Anbietergruppe, der dieser Link zugeordnet werden soll. Anbietergruppen werden in den SD-WAN-Richtlinien verwendet, um den Verkehrsfluss und das SD-WAN Verhalten zu definieren.",
              address: "Die IP-Adresse, die dem Anschluss zugewiesen werden soll.",
              netmask: "CIDR-Netzmaskensuffix für diese Verbindung.",
              gateway: "Die mit dieser Verbindung verbundene Gateway-IP-Adresse.",
              pin: "Die persönliche Identifikationsnummer für die in diesem Gerät installierte SIM-Karte.",
              failover: "Falls aktiviert, wird der Port überwacht und ein HA-Failover-Ereignis durchgeführt, wenn die Verbindung unterbrochen wird. Nur verfügbar, wenn HA aktiviert ist. HINWEIS: Um zu verhindern, dass das Gerät nicht mehr erreichbar ist, überprüfen Sie bitte vor der Aktivierung, ob die zu überwachende Verbindung aktiv ist.",
              doublePort: "Die Geräteanschlüsse, die für die primären und sekundären Verbindungen verwendet werden sollen.",
              doubleVirtualLanId: "Optionale Virtuelle LAN ID für die primären und sekundären Links (wird verwendet, wenn eine Verbindung zu einem Switch mit VLAN-Unterstützung besteht).",
              doubleAddress: "Die IP-Adresse, die den primären und sekundären Verbindungen zugewiesen werden soll. Dies sollte die erste nutzbare IP-Adresse der in Azure für jede Verbindung konfigurierten Bereiche sein (z. B. wenn 172.16.0.4/30 in Azure konfiguriert ist, ist 172.16.0.5 die einzugebende IP-Adresse).",
              doubleNetmask: "CIDR-Netzmaskensuffix für die primären und sekundären Verbindungen.",
              asn: "Automatisch generierte BGP-ASN für diese Verbindung. Dies führt zu keinen Konflikten mit anderen von SecureEdge verwalteten ASNs.",
              virtualTransferNetwork: "Netzwerk, das zur Reservierung von IPs für die virtuellen Übertragungsschnittstellen verwendet wird.",
              healthCheck: {
                enabled: "Wenn diese Funktion aktiviert ist, wird die Schnittstelle in regelmäßigen Abständen auf ihren Status hin überprüft.",
                mode: "Der Health Check-Modus, den Sie verwenden wollen.",
                targets: "IP-Adressen, die mit dem ICMP Health Check geprüft werden sollen."
              },
              userNamePassword: "Muss weniger als 256 Zeichen haben",
              mtu: "Sollte eine Zahl bis (einschließlich) 1492 sein.",
              additionalAddresses: "Die dem Port zugewiesene zusätzliche IP-Adresse."
            },
            bridge: "Bridge",
            advanced: "Erweitert",
            basic: "Basic",
            userName: "Benutzername",
            password: "Passwort",
            mtu: "MTU-Größe",
            healthCheck: {
              enabled: "Health Check aktivieren",
              mode: "Health Check",
              targets: "Health Check Target"
            },
            warning: "Wenn Sie den WAN-Typ ändern, werden die zugehörigen IPsec-Tunnel oder NAT-Ingress-Regeln gelöscht.",
            additionalAddresses: "Zusätzliche IP-Adressen",
            addressWarning: "Achtung: Wenn eine der zusätzlichen WAN-IP-Adressen, die Sie entfernt haben, in einer IPSec-Tunnel- oder NAT-Ingress-Regel verwendet wurde, werden die zugehörigen Regeln beim Speichern dieser Änderungen gelöscht."
          },
          lan: {
            tabTitle: "LAN",
            sectionTitle: "LAN Anschlüsse",
            addTitle: "Neue LAN-Schnittstelle hinzufügen",
            addButtonText: "LAN-Schnittstelle hinzufügen",
            editTitle: "LAN-Schnittstelle bearbeiten",
            deleteTitle: "LAN-Schnittstelle löschen",
            deleteMessage1: "Sind Sie sicher, dass Sie diese LAN-Schnittstelle löschen möchten? Dies hat die folgenden Auswirkungen:",
            deleteMessage2: "Alle zugehörigen DHCP-Konfigurationen und Reservierungen werden entfernt.",
            deleteMessage3: "Alle Sicherheitsregeln, die diese LAN-Schnittstelle verwenden, werden aktualisiert oder entfernt.",
            deleteMessage4: "Das Löschen wird erst beim Speichern dauerhaft.",
            name: "Name",
            port: "Port",
            virtualLanId: "Virtuelle LAN ID",
            address: "IP Addresse",
            netmask: "Netzmaske",
            dhcpEnable: "DHCP Server",
            dhcpFirst: "Erste IP-Adresse",
            dhcpLast: "Letzte IP-Adresse",
            dhcpDomain: "DHCP-Domäne",
            dhcpRange: "DHCP-Bereich",
            portWarning: "Die ausgegrauten Zeilen oben zeigen an, dass der ausgewählte Anschluss auf der Appliance nicht aktiviert wurde.",
            failover: "HA-Ausfallsicherung",
            descriptions: {
              name: "Ein eindeutiger Name für diese LAN-Verbindung.",
              port: "Der Geräteanschluss, der für diese Verbindung verwendet werden soll.",
              virtualLanId: "Optionale Virtual-LAN-ID für das Netzwerk (wird verwendet, wenn eine Verbindung zu einem Switch mit VLAN-Unterstützung besteht).",
              address: "Die IP-Adresse, die dem Anschluss zugewiesen werden soll.",
              netmask: "CIDR-Netzmaskensuffix, das für das erstellte Netzwerk verwendet werden soll.",
              dhcpEnable: "Aktivieren Sie den DHCP-Server auf dem Gerät für diese Verbindung.",
              dhcpFirst: "Die erste für die DHCP-Zuweisung verfügbare IP. Sie muss innerhalb des durch die Port-IP und Netzmaske definierten Netzwerks liegen.",
              dhcpLast: "Die letzte für die DHCP-Zuweisung verfügbare IP. Sie muss innerhalb des durch die Port-IP und Netzmaske definierten Netzes liegen.",
              dhcpDomain: "Der Domänenname, der dem DHCP-Server zugewiesen werden soll.",
              failover: "Falls aktiviert, wird der Port überwacht und ein HA-Failover-Ereignis durchgeführt, wenn die Verbindung unterbrochen wird. Nur verfügbar, wenn HA aktiviert ist. HINWEIS: Um zu verhindern, dass das Gerät nicht mehr erreichbar ist, überprüfen Sie bitte vor der Aktivierung, ob die zu überwachende Verbindung aktiv ist."
            },
            wifi: {
              validateSsid: "darf nur alphanumerische oder '-' Zeichen enthalten"
            }
          },
          route: {
            tabTitle: "Routen",
            sectionTitle: "Statische Routen",
            addTitle: "Neue Statische Route",
            addButtonText: "Statische Route hinzufügen",
            editTitle: "Statische Route ändern",
            deleteTitle: "Statische Route löschen",
            deleteMessage: "Sind Sie sicher, dass Sie diese statische Route löschen möchten? Das Löschen wird erst beim Speichern dauerhaft.",
            name: "Name",
            address: "Zielnetz",
            netmask: "Netzmaske",
            gateway: "Gateway",
            descriptions: {
              name: "Ein eindeutiger Name für diese Route.",
              address: "Die Zielnetzadresse für diese Route.",
              netmask: "Das CIDR-Netzmaskensuffix für das Zielnetz.",
              gateway: "Das für diese Route zu verwendende Gateway."
            }
          },
          dhcp: {
            tabTitle: "DHCP",
            networksSectionTitle: "DHCP Netze",
            leasesSectionTitle: "DHCP Leases",
            reservationsSectionTitle: "DHCP Reservierungen",
            dhcpEnable: "DHCP Service aktivieren",
            addNetworkTitle: "Neues DHCP Netz",
            addNetworkButtonText: "Neues Netz",
            editNetworkTitle: "DHCP-Netzwerk bearbeiten",
            deleteNetworkTitle: "DHCP-Netzwerk löschen",
            deleteNetworkMessage: "Sind Sie sicher, dass Sie dieses DHCP-Netzwerk löschen wollen? Das Löschen wird erst beim Speichern dauerhaft.",
            networks: {
              networkSectionTitle: "Netzwerk",
              basicSectionTitle: "Grundlegend",
              basicTabTitle: "Grundlegend",
              advancedSectionTitle: "Erweitert",
              advancedTabTitle: "Erweitert",
              enable: "DHCP-Status",
              port: "LAN Name",
              description: "Beschreibung",
              firstIp: "Erste IP-Adresse",
              lastIp: "Letzte IP-Adresse",
              domain: "Domänenname",
              dnsServers: "DNS Server",
              bootFileName: "Name der Boot-Datei",
              defaultLeaseTime: "Standard Ausgabezeit",
              maxLeaseTime: "Maximum Ausgabezeit",
              winsServers: "WINS Server",
              tftpHostname: "TFTP Host Name",
              tftpIpAddress: "TFTP IP Addresse",
              vendorOptions: "Vendor Options",
              validateMaxLeaseTime: "darf nicht kleiner sein als die Standard Ausgabezeit",
              dhcpRelayType: "DHCP",
              disabled: "Deaktiviert",
              dhcpRelay: "DHCP Relay",
              dhcpService: "DHCP Service"
            },
            blockUnknownMac: "Nur bekannte MAC-Adressen zulassen",
            addReservationTitle: "Neue DHCP-Reservierung hinzufügen",
            addReservationButtonText: "Reservierung hinzufügen",
            editReservationTitle: "DHCP-Reservierung bearbeiten",
            deleteReservationTitle: "DHCP-Reservierung löschen",
            deleteReservationMessage: "Sind Sie sicher, dass Sie diese DHCP-Reservierung löschen wollen? Das Löschen wird erst beim Speichern dauerhaft.",
            leases: {
              reservationSectionTitle: "Leases",
              port: "LAN Name",
              ip: "IP Addresse",
              mac: "MAC Addresse",
              reserve: "Reserve IP",
              noLeases: "Derzeit sind keine DHCP-Leases aktiv. Es kann bis zu 2 Minuten dauern, bis neue DHCP-Leases erscheinen."
            },
            reservations: {
              reservationSectionTitle: "Reservierung",
              name: "Name",
              description: "Beschreibung",
              port: "LAN Name",
              ip: "IP Addresse",
              mac: "MAC Addresse",
              clientId: "Client ID",
              hostname: "Hostname",
              validateMac: "muss eine gültige MAC-Adresse sein",
              validateHostname: "darf nur alphanumerische, '-' oder '_' Zeichen enthalten",
              duplicate: "muss innerhalb des LAN eindeutig sein",
              duplicateName: "muss eindeutig sein"
            },
            descriptions: {
              dhcpEnable_site: "Aktivieren Sie DHCP für diesen Standort.",
              dhcpEnable_gateway: "Aktivieren Sie DHCP für diesen Edge Service.",
              blockUnknownMac: "Wenn diese Option aktiviert ist, können nur die in der folgenden Tabelle aufgeführten MAC-Adressen den DHCP-Dienst nutzen.",
              networks: {
                enable: "Aktivieren Sie den DHCP-Server auf dem Gerät für diese Verbindung.",
                description: "Geben Sie eine optionale Beschreibung für diese DHCP-Serverkonfiguration an",
                port: "Die LAN-Schnittstelle, über die das DHCP-Netzwerk konfiguriert werden soll.",
                firstIp: "Die erste für die DHCP-Zuweisung verfügbare IP. Sie muss innerhalb des durch die Port-IP und Netzmaske definierten Netzwerks liegen.",
                lastIp: "Die letzte für die DHCP-Zuweisung verfügbare IP. Sie muss innerhalb des durch die Port-IP und Netzmaske definierten Netzes liegen.",
                domain: "Legen Sie optional einen Domänennamen fest, der dem DHCP-Server zugewiesen werden soll.",
                dnsServers: "Legen Sie optional eine Liste der zu verwendenden DNS-Server fest.",
                bootFileName: "Legen Sie optional den Namen einer anfänglichen Boot-Datei fest, die von einem Client geladen werden soll. Der Name sollte für jedes Dateiübertragungsprotokoll erkennbar sein, das der Client zum Laden der Datei verwenden kann.",
                defaultLeaseTime: "Die Standardlänge in Sekunden, die einem Lease zugewiesen wird.",
                maxLeaseTime: "Die maximale Länge in Sekunden, die einem Lease zugewiesen wird.",
                winsServers: "Fügen Sie optional eine oder mehrere WINS-Server-IP-Adressen hinzu.",
                tftpHostname: "Optional kann der Hostname eines TFTP-Servers angegeben werden.",
                tftpIpAddress: "Optional können Sie die IP-Adresse eines TFTP-Servers angeben.",
                vendorOptions: "Optional können Sie eine oder mehrere durch ';' getrennte Vendor-Pptionen festlegen."
              },
              reservations: {
                name: "Ein Name, der zur Identifizierung dieser Reservierung verwendet wird.",
                description: "Optionale Beschreibung für diese Reservierung.",
                port: "Die LAN-Schnittstelle, für die diese Reservierung vorgenommen werden soll.",
                ip: "Die für den Client zu reservierende IP.",
                mac: "Die MAC-Adresse zur Identifizierung des Clients.",
                clientId: "Client-ID, die den Host-Client identifiziert, wenn eine IP-Adresse angefordert wird.",
                hostname: "Hostname, der dieser IP zugewiesen werden soll."
              },
              dhcpRelayType: "Wählen Sie den DHCP-Typ aus."
            },
            validation: {
              dhcpEnable: "Kann nicht aktiviert werden, wenn eine WAN Bridge konfiguriert ist"
            }
          },
          advanced: {
            tabTitle: "Erweiterte Einstellungen",
            primaryDns: "Primärer DNS Server",
            secondaryDns: "Sekundärer DNS Server",
            rootPassword: "Root Password",
            rootPasswordConfirmation: "Root Password bestätigen",
            updateWindowTZ: "Zeitzone des Updatefensters",
            disableOMS: "Log Analytics deaktivieren",
            disableSecurityPolicy: "Security deaktivieren",
            disableIpsPolicy: "IPS deaktivieren",
            omsNote: "Damit werden die Standardeinstellungen für die Log Analytics Anbindung außer Kraft gesetzt.",
            descriptions: {
              updateWindowTZ_site: "Die Zeitzone für den Standort, die zur Bestimmung des Updatefensters für diesen Standort verwendet wird.",
              updateWindowTZ_gateway: "Die Zeitzone für den Edge Service, die zur Bestimmung des Updatefensters für diesen Edge Service verwendet wird.",
              primaryDns: "Legen Sie die primäre DNS-Adresse manuell fest.",
              secondaryDns: "Legen Sie die sekundäre DNS-Adresse manuell fest.",
              rootPassword_site: "Das Passwort des Root-Benutzers für diesen Standort.",
              rootPassword_gateway: "Das Passwort des Root-Benutzers für diesen Edge Service.",
              disableOMS_site: "Deaktivieren Sie das Streaming von Protokollen an Azure Log Analytics für diesen Standort.",
              disableOMS_gateway: "Deaktivieren Sie das Streaming von Protokollen an Azure Log Analytics für diesen Edge Service.",
              disableSecurityPolicy_site: "Deaktivieren Sie alle Sicherheitsrichtlinien für diesen Standort.",
              disableSecurityPolicy_gateway: "Deaktivieren Sie alle Sicherheitsrichtlinien für diesen Edge Service.",
              disableIpsPolicy_site: "Deaktivieren Sie die IPS-Richtlinien für diesen Standort.",
              disableIpsPolicy_gateway: "Deaktivieren Sie die IPS-Richtlinien für diesen Edge Service.",
              secureOnPremNetwork: "Backhauling und Sicherung des gesamten Internetverkehrs aus den LAN-Netzwerken der Standorte aktivieren.",
              proxyConnect: "Proxy-Verbindungen aktivieren. Standardmäßig werden für jedes LAN-Netzwerk Sockets auf der Haupt-IP mit den Ports 3128 und 8080 erstellt. Sie können jedoch unten Ihre eigenen Sockets angeben.",
              proxyConnectSockets: "Geben Sie die Sockets an, die für Proxy-Verbindungen verwendet werden sollen.",
              dnsInterception: "Definieren Sie die IP-Adressen, die für bestimmte Domains innerhalb der angeschlossenen LANs aufgelöst werden sollen.",
              reporting: "Ermöglicht Erfassung von Daten für das Reporting."
            },
            secureOnPremNetwork: "Backhaul Traffic von <br/> Sites",
            proxyConnect: "Proxy Connect aktivieren",
            proxyConnectSockets: "Proxy Connect Sockets",
            validation: {
              proxyConnect: "muss eine gültige Socket sein"
            },
            domain: "Domain",
            ipAddress: "IP-Addresse",
            dnsInterception: "DNS Interception",
            dnsInterceptionAddTitle: "Domain/IP-Zuordnung hinzufügen",
            dnsInterceptionEditTitle: "Domain/IP-Zuordnung bearbeiten",
            dnsInterceptionDeleteTitle: "Domain/IP-Zuordnung löschen",
            dnsInterceptionDeleteMessage: "Sind Sie sicher, dass Sie diese Domain/IP-Zuordnung löschen möchten?",
            reporting: "Reporting aktivieren"
          },
          confirmPortNotActivated: "Einige der ausgewählten Ports sind derzeit nicht für dieses Gerät aktiviert.",
          confirmPortNotActivated_site: "Einige der ausgewählten Ports sind derzeit nicht für diesen Standort aktiviert.",
          confirmPortNotActivated_gateway: "Einige der ausgewählten Ports sind derzeit nicht für diesen Edge Service aktiviert.",
          confirmPointOfEntryBroken: "Dieses Gerät ist als Einstiegspunkt konfiguriert und benötigt ein WAN mit einer statischen öffentlichen IP. Wenn Sie das Gerät speichern, ohne eine statische öffentliche IP zu konfigurieren, wird die Einstiegspunkt-Funktionalität entfernt.",
          confirmPointOfEntryBroken_site: "Dieser Standort ist als Einstiegspunkt konfiguriert und erfordert ein WAN mit einer statischen öffentlichen IP. Wenn Sie ohne statische öffentliche IP speichern, wird die Einstiegspunkt-Funktionalität entfernt.",
          confirmPointOfEntryBroken_gateway: "Dieser Edge Service ist als Einstiegspunkt konfiguriert und erfordert ein WAN mit einer statischen öffentlichen IP. Wenn Sie ohne statische öffentliche IP speichern, wird die Einstiegspunkt-Funktionalität entfernt.",
          confirm: "Sind Sie sicher, dass Sie fortfahren wollen?"
        },
        support: {
          buttonText: "Support Zugang",
          dialogTitle: "Support Zugang",
          message_site: "Mit der hier angegebenen Support-ACL und dem SSH-Schlüssel wird der SSH-Zugang zu diesem Standort für 24 Stunden gewährt.",
          message_gateway: "Mit der hier angegebenen Support-ACL und dem SSH-Schlüssel wird der SSH-Zugang zu diesem Edge Service für 24 Stunden gewährt.",
          cloudGatewayMessage: "Um auf einen Edge Service für Virtual WAN zugreifen zu können, müssen Sie auch Support-Zugriff in der <0>Azure Managed Application</0> aktivieren.",
          networks: "Support ACL",
          publicKey: "SSH Public Key",
          success_site: "Support-Zugang für Standort '{{Name}}' für 24 Stunden aktiviert.",
          success_gateway: "Support-Zugang für Edge Service '{{Name}}' für 24 Stunden aktiviert.",
          description: {
            networks_site: "Durch Kommata getrennte Liste der Netze, denen SSH-Zugang zu dem Standort gewährt werden soll.",
            networks_gateway: "Durch Kommata getrennte Liste der Netze, denen SSH-Zugang zu dem Edge Service gewährt werden soll.",
            publicKey_site: "Der öffentliche OpenSSH-Schlüssel, der für den Zugriff auf den Standort erforderlich ist.",
            publicKey_gateway: "Der öffentliche OpenSSH-Schlüssel, der für den Zugriff auf den Edge Service erforderlich ist."
          }
        },
        restart: {
          buttonLabel: "Neustart",
          box: {
            menuChoice: "Standort neu starten",
            dialogTitle: "Standort neu starten",
            confirmMessage: "Wenn Sie auf BESTÄTIGEN klicken, wird dieser Standort neu gestartet. Dies kann bis zu 5 Minuten dauern. <0/><0/> Sind Sie sicher, dass Sie fortfahren möchten?",
            confirmMessage_plural: "Wenn Sie auf BESTÄTIGEN klicken, werden beide Appliances in Ihrem HA-Paar neu gestartet. Dies kann bis zu 5 Minuten dauern. <0/> Dies betrifft nur Appliances, die als online gemeldet sind. <0/><0/> Sind Sie sicher, dass Sie fortfahren möchten?"
          },
          vpn: {
            menuChoice: "VPN Service neu starten",
            dialogTitle: "VPN Service neu starten",
            confirmMessage: "Wenn Sie auf BESTÄTIGEN klicken, wird der VPN-Service für diese Appliance neu gestartet. Dies kann bis zu 5 Minuten dauern. <0/><0/> Sind Sie sicher, dass Sie fortfahren möchten?",
            confirmMessage_plural: "Wenn Sie auf BESTÄTIGEN klicken, wird der VPN Service für beide Appliances in Ihrem HA-Paar neu gestartet; dies kann bis zu 5 Minuten dauern. <0/> Dies betrifft nur Appliances, die als online gemeldet sind. <0/><0/> Sind Sie sicher, dass Sie fortfahren möchten?"
          },
          firmware: {
            menuChoice: "Firmware durchstarten",
            dialogTitle: "Firmware durchstarten",
            confirmMessage: "Wenn Sie auf BESTÄTIGEN klicken, wird die Firmware für diese Appliance neu gestartet. Dies kann bis zu 5 Minuten dauern. <0/><0/> Sind Sie sicher, dass Sie fortfahren möchten?",
            confirmMessage_plural: "Wenn Sie auf BESTÄTIGEN klicken, wird die Firmware für beide Appliances in Ihrem HA-Paar neu gestartet. Dies kann bis zu 5 Minuten dauern. <0/> Dies betrifft nur Appliances, die als online gemeldet sind. <0/><0/> Sind Sie sicher, dass Sie fortfahren möchten?"
          }
        }
      },
      security: {
        securityEnabled: "Sicherheitsfunktionen eingeschaltet",
        menuTitle: "Sicherheitsfunktionen",
        pageTitle: "Sicherheitsfunktionen",
        comingSoon: "Bald verfügbar",
        protection: {
          tabTitle: "Schutz vor Schadsoftware",
          settings: {
            tabTitle: "Einstellungen",
            protection: "Schutz vor Schadsoftware",
            name: "Name",
            action: "Aktion",
            description: "Beschreibung",
            order: "Rangfolge",
            source: "Quelle",
            destination: "Ziel",
            defaultAction: "Standardmaßnahme",
            inputs: {
              enabled: "Eingeschaltet"
            },
            descriptions: {
              name: "Ein eindeutiger Name für Ihre Richtlinie.",
              description: "Eine kurze Beschreibung Ihrer Richtlinie.",
              action: "Die zu ergreifende Maßnahme für den von dieser Richtlinie erfassten Verkehr."
            },
            create: {
              buttonText: "Neue Richtlinie",
              dialogTitle: "Neue Richtlinie hinzufügen"
            },
            edit: {
              dialogTitle: "Richtlinie bearbeiten"
            },
            delete: {
              title: "Richtlinie löschen",
              body: "Sind Sie sicher, dass Sie diese Richtlinie löschen wollen?"
            }
          }
        },
        addRuleButtonText: "Richtlinie hinzufügen",
        sourceCriteria: {
          any: "Any",
          section: "Quellen",
          type: "Typ",
          site: "Standort",
          internet: "Internet",
          siteNetwork: "Netzwerke",
          source: "Quelle",
          application: "Benutzerdefinierte Netzwerkanwendung",
          applications: "Benutzerdefinierte Netzwerkanwendungen",
          allSites: "Alle Standorte",
          allNetworks: "Alle Netze",
          addSite: "Standort hinzufügen",
          editSite: "Standort besrbeiten",
          network: "IP/Netzwerk",
          userOrGroup: "Benutzer/Gruppe",
          users: "Benutzer",
          groups: "Gruppen",
          userOrGroupRequired: "mindestens ein Benutzer oder eine Gruppe ist erforderlich",
          userOrGroupDisabled: "Sie müssen zunächst einen lokalen Benutzer (Identität > Benutzerseite) oder ein Benutzerverzeichnis (Identität > Einstellungen) konfigurieren.",
          userOrGroupDisabledShort: "Kein Benutzerverzeichnis/lokaler Benutzer konfiguriert.",
          description: "Wählen Sie den Quellentyp aus.",
          applicationDescription: "Wählen Sie die Quellanwendung aus.",
          networkDescription: "Fügen Sie Ihr Quellnetz ein.",
          siteDescription: "Wählen Sie den Quell-Standort.",
          userDescription: "Wählen Sie den/die Quell-Benutzer.",
          groupDescription: "Wählen Sie den/die Quell-Benutzergruppe(n)).",
          gatewayNetwork: "Netzwerke",
          allOnpremGateways: "Alle Private Edge Services",
          addOnpremGateway: "Private Edge Service hinzufügen",
          editOnpremGateway: "Private Edge Service bearbeiten",
          onpremGateway: "Private Edge Service",
          onpremGatewayDescription: "Wählen Sie den Private Edge Service für Ihre Source aus.",
          location: "Standort",
          locationDescription: "Wählen Sie Ihren Quell-Standort aus",
          allLocation: "Alle Standorte",
          secureEdgeConnector: "Connectors",
          secureEdgeConnectorDescription: "Wählen Sie Ihre Quell-Connector(s) aus"
        },
        destinationCriteria: {
          section: "Zielkriterien",
          type: "Typ",
          destination: "Ziel",
          site: "Standort",
          siteNetwork: "Netzwerke",
          allSites: "Alle Standorte",
          allNetworks: "Alle Netzwerke",
          addSite: "Standort hinzufügen",
          editSite: "Edit Site",
          network: "IP/Network",
          application: "Application",
          domain: "Domain",
          category: "URL Category",
          onPremGateway: "Private Edge Service",
          description: "Wählen Sie Ihren Zieltyp.",
          applicationDescription: "Wählen Sie Ihre Zielanwendung aus.",
          siteDescription: "Wählen Sie Ihren Ziel-Standort.",
          gatewayDescription: "Wählen Sie den Private Edge Service für die Destination aus.",
          networkDescription: "Wählen Sie Ihren Zielnetz.",
          gateway: "Edge Service",
          ingressSite: "Standort",
          customCategory: "Benutzerdefinierte Kategorien",
          gatewayNetwork: "Netzwerke",
          onpremGateway: "Private Edge Service",
          allOnpremGateways: "Alle Private Edge Services",
          addOnpremGateway: "Private Edge Service hinzufügen",
          editOnpremGateway: "Private Edge Service bearbeiten",
          onpremGatewayDescription: "Wählen Sie den Private Edge Service für Ihre Destination aus.",
          secureEdgeConnector: "Connectors",
          secureEdgeConnectorDescription: "Wählen Sie Ihre Ziel-Connector(s) aus"
        },
        targetCriteria: {
          section: "Ziel-Kriterien"
        },
        tables: {
          allSites: "Alle Standorte",
          allGateways: "Alle Edge Services",
          source: "Quelle",
          sourceNetwork: "IP/Netz",
          sourceApplication: "Benutzerdefinierte Netzwerkanwendung",
          sourceSite: "Standort",
          sourceType: "Quell-Typ",
          destination: "Destination",
          destinationSite: "Standort",
          destinationApplication: "Anwendung",
          destinationCategories: "URL-Kategorie",
          destinationNetwork: "IP/Netz",
          destinationDomain: "Domäne",
          destinationType: "Ziel-Typ",
          destinationGateway: "Ziel Edge Service",
          destinationWan: "Ziel WAN",
          user: "Benutzer",
          group: "Gruppe",
          destinationCustomCategories: "Benutzerdefinierte Kategorie",
          allOnpremGateways: "Alle Private Edge Services",
          sourceOnpremGateway: "Private Edge Service",
          destinationOnpremGateway: "Private Edge Service",
          allLocations: "Alle Standorte",
          secureEdgeConnector: "Connector",
          location: "Standort"
        },
        applicationControl: {
          siteTabTitle: "Standort ACL",
          gatewayTabTitle: "Edge Service ACL",
          settings: {
            tabTitle: "Einstellungen",
            siteApplicationControl: "Standort ACL",
            gatewayApplicationControl: "Edge Service ACL",
            order: "Rangfolge",
            name: "Name",
            action: "Aktion",
            description: "Beschreibung",
            defaultAction: "Standard-Aktion",
            source: "Quelle",
            destination: "Ziel",
            scope: "Anwendungsbereich",
            allSites: "Alle Standorte",
            allGateways: "Alle Edge Services",
            icmp: "ICMP",
            descriptions: {
              name: "Ein eindeutiger Name für Ihre Richtlinie.",
              description: "Eine kurze Beschreibung Ihrer Richtlinie.",
              action: "Die zu ergreifende Maßnahme (blockieren oder zulassen) für den von dieser Richtlinie erfassten Verkehr.",
              scope: "Die Standort(e), auf die diese Richtlinie anzuwenden ist.",
              icmp: "Zulassen, dass Geräte über ICMP kommunizieren."
            },
            create: {
              buttonText: "Richtlinie hinzufügen",
              dialogTitle: "Neue Richtlinie hinzufügen"
            },
            edit: {
              dialogTitle: "Richtlinie ändern"
            },
            delete: {
              title: "Richtlinie löschen",
              body: "Sind Sie sicher, dass Sie diese Richtlinie löschen wollen?"
            }
          },
          noData: {
            edgeServiceAcl: {
              iconHeader: "Beginnen Sie mit dem Hinzufügen einer ACL für den Edge Service",
              labelButton: "Edge Service ACL hinzufügen",
              textHeader: "Was ist eine ACL für den Edge Service?",
              text: "Aus Sicherheitsgründen sollten Sie den Zugang zu Ihrem(n) Service(s) beschränken.<0 /> Mit dieser Access Control List (ACL) können Sie konfigurieren, wer auf den/die Services zugreifen darf und wie"
            },
            siteAcl: {
              iconHeader: "Beginnen Sie mit dem Hinzufügen einer Standort ACL!",
              labelButton: "Hinzufügen Standort ACL",
              textHeader: "Was ist eine Standort ACL?",
              text: "Aus Sicherheitsgründen sollten Sie den Zugriff auf Ihren/Ihre Standort(e) einränken.<0 /> Mit dieser Access Control List (ACL) können Sie konfigurieren, wer auf Ihren/Ihre Standort(e) zugreifen darf und wie"
            },
            appResource: {
              iconHeader: "Beginnen Sie mit dem Hinzufügen von Apps und Ressourcen!",
              labelButton: "Benutzerdefinierte App hinzufügen",
              textHeader: "Was ist eine benutzerdefinierte App?",
              text: "Für eine funktionierende SD-WAN-Umgebung muss Ihre Installation die verwendeten Apps kennen.<0/> Während eine große Anzahl von Apps von Anfang an unterstützt wird, gehören bestimmte Apps möglicherweise nicht zu dieser ersten Gruppe.<0/>Sie können hier Netzwerk- und Web-Apps hinzufügen, die nicht unterstützt werden."
            }
          }
        },
        urlFiltering: {
          tabTitle: "URL-Filtern",
          settings: {
            tabTitle: "Einstellungen",
            order: "Rangfolge",
            name: "Name",
            description: "Beschreibung",
            appliesTo: "Betrifft",
            action: "Aktion",
            type: "Typ",
            rule: "Ziel",
            domains: "Domäne",
            categories: "URL-Kategorie",
            customCategories: "Benutzerdefinierte Kategorien",
            defaultAction: "Standard-Aktion",
            destinationCriteria: "Zielkriterien",
            destinationDomains: "{{domains.0}}{{additional}}",
            destinationDomains_1: "{{domains.0}}",
            destinationCategories: "Kategorien ({{activeCategories.0.name}}, +{{additional}})",
            destinationCategories_1: "Kategorien ({{activeCategories.0.name}})",
            destinationCustomCategories: "Benutzerdefinierte Kategorien ({{activeCategories.0.name}}, +{{additional}})",
            destinationCustomCategories_1: "Benutzerdefinierte Kategorien ({{activeCategories.0.name}})",
            descriptions: {
              name: "Ein eindeutiger Name für Ihre Richtlinie.",
              description: "Eine kurze Beschreibung Ihrer Richtlinie.",
              action: "Die zu ergreifende Maßnahme (blockieren, zulassen...) für den von dieser Richtlinie erfassten Verkehr.",
              type: "Erstellen Sie diese Richtlinie entweder auf URL-Kategorie oder auf der Übereinstimmung mit Domänennamen.",
              domains: "Die Domänen, die für diese Richtlinie verwendet werden.",
              customCategories: "Die benutzerdefinierten Kategorien, die für diese Richtlinie verwendet werden.",
              silent: "Stummes Sperren der Regel."
            },
            create: {
              buttonText: "Richtlinie hinzufügen",
              dialogTitle: "Neue Richtlinie hinzufügen"
            },
            edit: {
              dialogTitle: "Richtlinie bearbeiten"
            },
            validation: {
              emptyCategories: "es muss mindestens eine Kategorie ausgewählt sein."
            },
            delete: {
              title: "Richtlinie löschen",
              body: "Sind Sie sicher, dass Sie diese Richtlinie löschen wollen?"
            },
            silent: "Stumm"
          },
          exceptions: {
            tabTitle: "Explizit",
            order: "Rangfolge",
            name: "Name",
            description: "Beschreibung",
            action: "Aktion",
            type: "Typ",
            rule: "Richtlinie",
            domain: "Domäne",
            category: "URL-Kategorie",
            customCategories: "Benutzerdefinierte Kategorien",
            domains: "Ziel-Hostname",
            categories: "URL-Kategorie",
            source: "Quelle",
            sourceNetwork: "Quell IP/Netz",
            sourceSite: "Quell Standort",
            allSites: "Alle Standorte",
            allNetworks: "Alle Netze",
            sourceSiteNetwork: "Netze",
            sourceType: "Typ",
            network: "IP/Netz",
            site: "Standort",
            sourceSites: "Standort",
            addSite: "Standort hinzufügen",
            editSite: "Standort editieren",
            destinations: "Ziel",
            destination: "Ziel IP/Netz",
            destinationType: "Typ",
            destinationCategories: "Ziel URL-Kategorie",
            destinationDomains: "Ziel Domäne",
            sectionTitle: "Kriterien",
            sourceCriteria: "Quell-Kriterien",
            destinationCriteria: "Ziel-Kriterien",
            descriptions: {
              name: "Ein eindeutiger Name für diese explizite Richtlinie.",
              description: "Eine kurze Beschreibung dieser Richtlinie.",
              action: "Die zu ergreifende Aktion (blockieren, zulassen...) für den von dieser Richtlinie erfassten Verkehr.",
              categories: "Die Kategorien, die verwendet werden, um dieser Richtlinie zu entsprechen.",
              customCategories: "Die benutzerdefinierten Kategorien, die für diese Richtlinie verwendet werden.",
              domains: "Die Domänen, die für diese Richtlinie verwendet werden.",
              silent: "Stummes Sperren der Regel.",
              scope: "Scope bietet eine Möglichkeit, Webfilterregeln zu gruppieren, basierend auf dem Dienst, der sie anwendet."
            },
            validation: {
              sourceDest: "es muss mindestens eine Quelle oder ein Ziel angeben werden"
            },
            create: {
              buttonText: "Hinzufügen",
              dialogTitle: "Explizite Richtlinie hinzufügen"
            },
            edit: {
              dialogTitle: "Explizit Richtlinie bearbeiten"
            },
            delete: {
              title: "Explizite Richtlinie löschen",
              body: "Sind Sie sicher, dass Sie diese Ausnahme löschen möchten??"
            },
            silent: "Stumm",
            scope: "Scope"
          }
        },
        urlCategories: {
          tabTitle: "URL-Kategorien",
          settings: {
            tabTitle: "Einstellungen",
            urlCategories: "URL-Kategorien",
            description: "Markieren Sie eine URL-Oberkategorie oder URL-Kategorie, um sie zu blockieren"
          },
          blocklists: {
            tabTitle: "Erlaubt/Blockieren Listen",
            description: "Definieren Sie URLs, IPs oder Netzwerke, die dauerhaft gesperrt oder zugelassen werden sollen.",
            inputs: {
              blacklist: "Immer blockieren",
              whitelist: "Immer zulassen"
            }
          },
          exceptions: {
            tabTitle: "Explizit",
            categories: "Oberkategorie",
            subCategories: "Kategorien",
            mode: "Erlauben/Blockieren",
            allow: "Erlauben",
            block: "Blockieren",
            users: "Benutzer",
            groups: "Benutzergruppen",
            source: "Quell IP/Netz",
            time: "Zeit",
            validateCategory: "mindestens eine URL-Oberkategorie oder URL-Kategorie muss angegeben werden",
            validateSource: "mindestens eine Quelle, ein Benutzer oder eine Gruppe muss angegeben werden",
            delete: {
              title: "Explizite löschen",
              body: "Sind Sie sicher, dass Sie diese Ausnahme löschen wollen? Das Löschen wird erst beim Speichern dauerhaft."
            },
            create: {
              title: "Neu hinzufügen Explizit",
              button: "Hinzufügen"
            },
            edit: {
              title: "Explizit bearbeiten"
            }
          }
        },
        ips: {
          tabTitle: "IPS",
          settings: {
            tabTitle: "Einstellungen",
            falsePositivesTabTitle: "Positive Fehlmeldungen",
            ips: "Intrusion Prevention",
            name: "Name",
            action: "Aktion",
            description: "Beschreibung",
            block: "Blockieren",
            mode: "Modus",
            log: "Protokollierung",
            order: "Rangfolge",
            source: "Quelle",
            exploits: "Exploit",
            destination: "Ziel",
            defaultAction: "Standard-Aktion",
            descriptions: {
              name: "Ein eindeutiger Name für die Richtlinie",
              description: "Eine kurze Beschreibung der Richtlinie.",
              action: "Die zu ergreifende Maßnahme für den von dieser Richtlinie erfassten Verkehr."
            },
            inputs: {
              enabled: "Eingeschaltet"
            },
            create: {
              buttonText: "Richtlinie hinzufügen",
              dialogTitle: "Neue Richtlinie hinzufügen"
            },
            edit: {
              dialogTitle: "Richtlinie bearbeiten"
            },
            delete: {
              title: "Richtlinie löschen",
              body: "Sind Sie sicher, dass Sie diese Richtlinie löschen wollen?"
            }
          }
        },
        sslInterception: {
          tabTitle: "SSL Inspektion",
          settings: {
            tabTitle: "Zertifikate",
            sslInterception: "SSL Inspektion",
            name: "Name",
            action: "Aktion",
            description: "Beschreibung",
            order: "Rangfolge",
            source: "Quelle",
            destination: "Ziel",
            defaultAction: "Standard-Aktion",
            explicitTabTitle: "Einstellungen",
            status: "Status",
            issuer: "Herausgeber",
            startDate: "Startdatum",
            endDate: "Enddatum",
            makeActive: "Auf aktiv setzen",
            active: "Activ",
            activeCertificate: "Active Zertifikate",
            makeCertificateActive: "Aktivieren",
            sslCertificates: "SSL Inspektion Zertifikate",
            sslCertificateAuthorities: "Vertrauenswürdige Zertifikatsstellen",
            uploadP12: "PKCS12 hochladen",
            uploadPEM: "PEM oder CRT hochladen",
            passphrase: "Passphrase",
            downloadCertificate: "Client-Zertifikat herunterladen",
            certificates: {
              create: {
                label: "Zertifikat hinzufügen",
                dialogTitle: "Neues Zertifikat hinzufügen"
              },
              edit: {
                dialogTitle: "Zertifikatdetails bearbeiten"
              },
              delete: {
                title: "Zertifikat löschen",
                body: "Sind Sie sicher, dass Sie dieses Zertifikat löschen möchten?",
                disabledMessage: "Aktives Zertifikat kann nicht gelöscht werden."
              }
            },
            descriptions: {
              name: "Ein eindeutiger Name für Ihre Richtlinie.",
              certificateName: "Ein eindeutiger Name für Ihr Zertifikat.",
              description: "Eine kurze Beschreibung Ihrer Richtlinie.",
              certificateDescription: "Eine kurze Beschreibung Ihres Zertifikats.",
              action: "Die zu ergreifende Maßnahme für den von dieser Richtlinie erfassten Verkehr.",
              uploadP12: "Laden Sie Ihr PKS12-Zertifikat für hoch. Es muss den privaten Schlüssel enthalten.",
              uploadPEM: "Laden Sie ein PEM- oder CRT-Zertifikat hoch.",
              passphrase: "Die Passphrase zum Entsperren des PKS12-Zertifikats (falls erforderlich)."
            },
            inputs: {
              enabled: "Eingeschaltet"
            },
            create: {
              buttonText: "Richtlinie hinzufügen",
              dialogTitle: "Neue Richtlinie hinzufügen"
            },
            edit: {
              dialogTitle: "Richtlinie bearbeiten"
            },
            delete: {
              title: "Richtlinie löschen",
              body: "Sind Sie sicher, dass Sie diese Richtlinie löschen wollen?"
            }
          }
        },
        ingressNatRules: {
          settings: {
            order: "Auftrag",
            name: "Name",
            target: "Ziel",
            description: "Beschreibung",
            source: "Quelle",
            destination: "Ziel",
            gateway: "Edge Service",
            wan: "WAN",
            targetApplicationResource: "Anwendung/Ressource",
            descriptions: {
              name: "Eindeutiger Name für Ihre Regel.",
              description: "Kurze Beschreibung Ihrer Regel.",
              target: "Ziel für den Traffic, auf den diese Regel zutrifft.",
              wan: "Wählen Sie Ihr WAN Interface je nach der benötigten öffentlichen IP.",
              patPublicPort: "Ihr Ziel-PAT Public Port. Dies ist ein Pflichtfeld, wenn das Ziel eine Netzwerkanwendung ohne Ports ist. Wenn kein Port angegeben wird, wird der konfigurierte Port der Zielanwendung (oder der erste Port im konfigurierten Portbereich) verwendet."
            },
            create: {
              buttonText: "Regel hinzufügen",
              dialogTitle: "Neue Regel hinzufügen"
            },
            edit: {
              dialogTitle: "Regel bearbeiten"
            },
            delete: {
              title: "Regel löschen",
              body: "Sind Sie sicher, dass Sie diese Regel löschen möchten?"
            },
            IPAddress: "Adresse"
          },
          noData: {
            iconHeader: "Beginnen Sie mit dem Hinzufügen einer Ingress NAT-Regel!",
            labelButton: " Ingress NAT-Regel hinzufügen",
            textHeader: "Was ist eine Ingress NAT?",
            text: "Falls Sie interne Netzwerkressourcen für die Öffentlichkeit zugänglich machen wollen, verwenden Sie diesen Abschnitt, um die Parameter für solche Verbindungen zu definieren.<0/><0/>Bitte beachten Sie, dass der interne Dienst unter einer statischen öffentlichen IP-Adresse laufen muss."
          }
        }
      },
      customApps: {
        menuTitle: "BENUTZERDEFINIERTE APPS",
        pageTitle: "Benutzerdefinierte Anwendungen",
        table: {
          name: "Name",
          description: "Beschreibung",
          protocol: "Protokoll",
          destination: "Ziele",
          category: "Kategorie",
          type: "Typ"
        },
        dialogs: {
          create: {
            buttonText: "Neue benutzerdefinierte Anwendung",
            dialogTitleWeb: "Neue benutzerdefinierte Web-Anwendung",
            dialogTitleNetwork: "Neue benutzerdefinierte Netzwerk-Anwendung",
            menuLabelWeb: "Neue benutzerdefinierte Web-Anwendung",
            menuLabelNetwork: "Neue benutzerdefinierte Netzwerk-Anwendung"
          },
          edit: {
            dialogTitle: "Benutzerdefinierte Anwendung bearbeiten"
          },
          general: {
            sectionTitle: "Allgemein",
            name: "Name",
            description: "Beschreibung",
            descriptions: {
              name: "Eindeutiger Name für diese benutzerdefinierte Anwendung.",
              description: "Kurze Beschreibung dieser benutzerdefinierten Anwendung."
            }
          },
          endpoints: {
            protocol: "Protokoll",
            sectionTitle: "App-Endpunkte",
            destination: "Ziel",
            port: "Port",
            firstPort: "Erster Port",
            lastPort: "Letzter Port",
            descriptions: {
              protocol: "Das von dieser benutzerdefinierten Anwendung verwendete Protokoll.",
              destination: "Die Ziel-IP, Domain oder das Netzwerk, an das diese benutzerdefinierte Anwendung Traffic sendet.",
              webAppDestination: "Der Hostname des Ziels, an das diese benutzerdefinierte Anwendung Traffic sendet.",
              port: "Der von dieser benutzerdefinierten Anwendung verwendete Port.",
              firstPort: "OPTIONAL: Der erste Port im Bereich der von dieser benutzerdefinierten Anwendung verwendeten Ports.",
              lastPort: "OPTIONAL: Der letzte Port im Bereich der von dieser benutzerdefinierten Anwendung verwendeten Ports."
            }
          },
          category: "Kategorie",
          descriptions: {
            category: "Die Kategorie, der diese benutzerdefinierte Anwendung zuzuordnen ist."
          }
        },
        delete: {
          title: "Löschen {{name}}",
          body: "Sind Sie sicher, dass Sie diese benutzerdefinierte Anwendung löschen möchten?"
        }
      },
      sites: {
        auditLog: {
          tabTitle: "Audit-Protokoll",
          pageTitle: "Audit-Protokoll"
        },
        connectionHistory: {
          tabTitle: "Letzte Verbindungen",
          pageTitle: "Letzte Verbindungen",
          waitingForConnections: "Verbinden mit der Site. Es kann bis zu 2 Minuten dauern, bis eine Verbindung zustande kommt. Bitte warten...",
          emptyData: "Keine letzten Verbindungen...",
          pauseRequestsButton: "Pause",
          resumeRequestsButton: "Wiederaufnahme"
        },
        dashboard: {
          tabTitle: "Dashboard",
          statusCardTitle: "Status",
          servicesCardTitle: "Dienste",
          licensesCardTitle: "Lizenzen",
          locationsCardTitle: "Karte",
          gateway: {
            tabTitle: "Edge Service",
            name: "Name",
            region: "Region",
            hubName: "Hub",
            vwan: "VWAN",
            size: "Größe"
          },
          siteStatus: {
            title: "Information",
            model: "Modell",
            licenses: "Lizenzen",
            serials: "Seriennummer(n)",
            uptime: "Aktiv seit",
            time: "Aktuelle Zeit",
            release: "Release",
            hostname: "Hostname"
          },
          licenses: {
            type: "Typ",
            state: "Zustand",
            status: "Status",
            expirationDate: "Ablaufdatum",
            serial: "Serie",
            noData: "k. A."
          },
          boxResources: {
            title: "Ressourcen",
            cpuLoad: "CPU",
            cpuLoadError: "Die CPU hat einen Fehler erkannt. Bitte starten Sie die Appliance neu. Wenn dieser Fehler anhält, wenden Sie sich an den Support.",
            memory: "Speicher",
            diskInformation: "Festplatte",
            diskInformationError: "Auf einer der Festplattenpartitionen wurde ein Fehler erkannt. Bitte starten Sie die Appliance neu. Wenn dieser Fehler anhält, wenden Sie sich an den Support."
          },
          interfaces: {
            title: "Schnittstellen",
            status: "Status",
            name: "Name",
            speed: "Geschwindigkeit",
            duplex: "Duplex",
            errors: "Fehler",
            state: {
              ok: "OK",
              warning: "Warnung",
              error: "Fehler",
              unknown: "Unbekannt",
              loading: " "
            }
          },
          deploymentEvents: {
            title: "Einsatz-Ereignisse",
            state: "Zustand",
            status: "Status",
            created: "Datum"
          },
          deploymentStatus: {
            title: "Einsatz-Status {{serial}}",
            status: "Status",
            action: "Aktion",
            message: "Nachricht",
            registered: "Registriert",
            lastSeen: "Zuletzt angesehen",
            completed: "Abgeschlossen",
            localIp: "Lokale IP",
            remoteIp: "Öffentliche IP",
            siteDeploying: "Laufender Einsatz"
          },
          deploymentLocation: {
            title: "Einsatzort"
          },
          firmware: {
            tabTitle: "Firmware",
            version: "Version",
            hotfixes: "Installierte Hotfixes",
            window: "Nächstes Update",
            nextUpdate: "Von {{from}} bis {{to}}"
          },
          hotfixes: {
            tabTitle: "Hotfixes",
            status: "Status",
            name: "Name",
            state: {
              error: "Fehler",
              ok: "OK",
              unknown: "Unbekannt",
              warning: "Ausstehend",
              loading: " "
            }
          },
          updates: {
            tabTitle: "Konfigurations-Updates",
            lastUpdate: "Letztes Update",
            lastSeen: "Zuletzt angesehen",
            remoteIp: "Remote IP"
          },
          restart: {
            buttonLabel: "Neustart",
            box: {
              menuChoice: "Reboot Site",
              dialogTitle: "Reboot Site",
              confirmMessage: "Zum Neustart der Site auf BESTÄTIGEN klicken, dies kann bis zu 5 Minuten dauern. <0/><0/> Sind Sie sicher, dass Sie fortfahren wollen?",
              confirmMessage_plural: "Zum Neustart beider Appliances in Ihrem HA-Paar auf BESTÄTIGEN klicken, dies kann bis zu 5 Minuten dauern. <0/> Dies wirkt sich nur auf Anwendungen aus, die als online gemeldet sind. <0/><0/> Sind Sie sicher, dass Sie fortfahren wollen?"
            },
            vpn: {
              menuChoice: "Neustart VPN Service",
              dialogTitle: "Neustart VPN Service",
              confirmMessage: "Zum Neustart des VPN Service für dieser Appliance auf BESTÄTIGEN klicken, dies kann bis zu 5 Minuten dauern. <0/><0/> Sind Sie sicher, dass Sie fortfahren wollen?",
              confirmMessage_plural: "Zum Neustart des VPN Service beider Appliances in Ihrem HA-Paar auf BESTÄTIGEN klicken, dies kann bis zu 5 Minuten dauern. <0/> Dies wirkt sich nur auf Appliances aus, die als online gemeldet sind. <0/><0/> Sind Sie sicher, dass Sie fortfahren wollen?"
            },
            firmware: {
              menuChoice: "Neustart Firmware",
              dialogTitle: "Neustart Firewall Firmware",
              confirmMessage: "Zum Neustart der Firmware für diese Appliance auf BESTÄTIGEN klicken, dies kann bis zu 5 Minuten dauern. <0/><0/> Sind Sie sicher, dass Sie fortfahren wollen?",
              confirmMessage_plural: "Zum Neustart der Firmware für beide Appliances in Ihrem HA-Paar auf BESTÄTIGEN klicken, dies kann bis zu 5 Minuten dauern. <0/> Dies wirkt sich nur auf Appliances aus, die als online gemeldet sind. <0/><0/> Sind Sie sicher, dass Sie fortfahren wollen?"
            }
          }
        },
        delete: {
          body: "Sind Sie sicher, dass Sie dieses Site Device löschen möchten?",
          title: "Site Device löschen {{name}}"
        },
        dialog: {
          create: {
            buttonText: "Neue Site",
            sc: {
              createSC: "Neue IoT-Appliance",
              dialogTitle: "Neue IoT-Appliance",
              menuItemDisabled: "IoT-Appliance benötigt einen Edge Service"
            },
            tvt: {
              createTVT: "Neue T/VT-Appliance",
              dialogTitle: "Neue Site"
            }
          },
          wans: {
            sectionTitle: "WANs",
            message: "Ihre Appliance verfügt über insgesamt {{portsAvailable}} Ports für WAN- und LAN-Zuweisungen.",
            message2_site: "Wie viele WAN-Verbindungen möchten Sie für diese Site konfigurieren?",
            message2_gateway: "Wie viele WAN-Verbindungen möchten Sie für diesen Edge Service konfigurieren?",
            size: "Anzahl der WANs",
            setup: "WAN-Link konfigurieren {{step}}",
            name: "Name",
            port: "Port",
            virtualLanId: "Virtual LAN ID",
            type: "Typ",
            providerPinning: "Provider Pinning",
            address: "IP-Adresse",
            netmask: "Netzmaske",
            gateway: "Gateway",
            pin: "SIM Pin für {{serial}}",
            invalidPinFormat: "SIM Pin sollte 4 Ziffern haben",
            SCmessage: "Ihre Appliance verfügt über einen einzigen zu konfigurierenden WAN Port.",
            SCmessage_failover: "Ihre Appliance kann bis zu 2 WAN-Verbindungen haben (eine primäre und eine Failover-Verbindung).",
            SCconfigureFailover: "Möchten Sie einen Failover für diese Site konfigurieren?",
            failover: "WAN Failover",
            setupSCPrimary: "Primäre WAN-Verbindung konfigurieren",
            setupSCFailover: "Failover WAN-Verbindung konfigurieren",
            descriptions: {
              name: "Eindeutiger Name für diese WAN-Verbindung",
              port: "Appliance-Port, der für diese Verbindung verwendet werden soll.",
              virtualLanId: "Optionale Virtual-LAN-ID für das Netzwerk (wird verwendet, wenn eine Verbindung zu einem Switch mit Virtual-LAN-Unterstützung besteht).",
              type: "Netzwerk, das für diese Verbindung verwendet soll.",
              providerPinning: "Providergruppe, mit der diese Verbindung verknüpft werden soll. Providergruppen werden in den SD-WAN-Richtlinien verwendet, um den Traffic-Fluss und das Verhalten zu definieren.",
              address: "Die dem Port zugewiesene IP-Adresse.",
              netmask: "Network CIDR Netmask Suffix für diese Verbindung.",
              gateway: "Mit dieser Verbindung verknüpfte Gateway IP-Adresse.",
              pin: "Persönliche Identifikationsnummer für die in dieser Appliance installierte SIM-Karte."
            }
          },
          lans: {
            sectionTitle: "LANs",
            message: "Ihre Appliance verfügt über insgesamt {{portsAvailable}} Ports für WAN- und LAN-Zuweisungen, von denen Sie {{wans}} an WAN zugewiesen haben.",
            SCmessage: "Ihre Appliance verfügt über insgesamt {{portsAvailable}} LAN Ports.",
            message2: "Wie viele LAN-Verbindungen möchten Sie für diese Site konfigurieren?",
            size: "Anzahl der LANs",
            setup: "LAN-Verbindung konfigurieren {{step}}",
            name: "Name",
            port: "Port",
            virtualLanId: "Virtual LAN ID",
            address: "IP-Adresse",
            netmask: "Netzmaske",
            dhcpEnable: "DHCP Server",
            dhcpDescription: "Beschreibung",
            dhcpFirstIp: "Erste IP-Adresse",
            dhcpLastIp: "Letzte IP-Adresse",
            dhcpDomain: "DHCP Domain",
            dhcpSectionTitle: "DHCP Konfiguration",
            wifiSectionTitle: "WiFi Konfiguration",
            wifi: {
              ssid: "SSID",
              securityMode: "Sicherheits-Modus",
              passphrase: "Passphrase",
              passphraseConfirm: "Passphrase bestätigen"
            },
            descriptions: {
              dhcpEnable: "Aktiviert den DHCP-Server für diese LAN-Verbindung.",
              name: "Eindeutiger Name für diese LAN-Verbindung",
              port: "Appliance-Port, der für diese Verbindung verwendet werden soll.",
              virtualLanId: "Optionale Virtual-LAN-ID für das Netzwerk (wird verwendet, wenn eine Verbindung zu einem Switch mit Virtual-LAN-Unterstützung besteht).",
              address: "Die dem Port zugewiesene IP-Adresse.",
              netmask: "Network CIDR Netmask Suffix, das für das erstellte Netzwerk verwendet werden soll.",
              dhcpDescription: "Benutzerdefinierte Beschreibung für dieses DHCP Network.",
              dhcpFirstIp: "Erste IP-Adresse, die diesem DHCP Network zugewiesen werden kann.",
              dhcpLastIp: "Letzte IP-Adresse, die diesem DHCP Network zugewiesen werden kann.",
              dhcpDomain: "Domain Name für diesen DHCP Service.",
              wifi: {
                ssid: "SSID des WLAN-Netzwerks.",
                securityMode: "WLAN Sicherheits-Modus, den Sie verwenden wollen.",
                passphrase: "Passwort für das Netzwerk."
              }
            }
          },
          advanced: {
            sectionTitle: "Erweiterte Einstellungen",
            updateWindowTZ: "Zeitzone Update-Fenster",
            primaryDns: "Primärer DNS",
            secondaryDns: "Sekundärer DNS",
            descriptions: {
              updateWindowTZ: "Die Zeitzone des Standorts, die für die Bestimmung des Update-Fensters für dieses Site Device verwendet wird.",
              primaryDns: "Primäre DNS-Adresse manuell einstellen.",
              secondaryDns: "Sekundäre DNS-Adresse manuell einstellen."
            }
          },
          submit: {
            message: "Bitte überprüfen Sie die Angaben zu Ihrer Site und klicken Sie auf Speichern",
            name: "Name",
            description: "Beschreibung",
            serial: "Serie",
            gateway: "Edge Service",
            noGateway: "(Keiner)",
            timezone: "Zeitzone",
            status: "Verbindungs-Status",
            peeringAddress: "Peering Adresse",
            wan: "WAN",
            lan: "LAN",
            primaryDns: "Primärer DNS",
            secondaryDns: "Sekundärer DNS"
          },
          result: {
            siteCreated: "Site wurde erfolgreich erstellt",
            connectPort: "Bitte stellen Sie sicher, dass Port <0>{{port}}</0> der Appliance unten an eine DHCP-fähige Internetverbindung angeschlossen ist, damit die Appliance automatisch konfiguriert werden kann. Wenn Sie einen anderen Port verwenden möchten, kann dieser direkt auf der Appliances konfiguriert werden.",
            connectPortSc: "Bitte stellen Sie sicher, dass der WAN Port der Appliance unten an eine DHCP-fähige Internetverbindung angeschlossen ist, damit die Appliance automatisch konfiguriert werden kann.",
            connectPort_plural: "Bitte stellen Sie sicher, dass Port <0>{{port}}</0> der Appliance unten an eine DHCP-fähige Internetverbindung angeschlossen ist, damit Ihre Appliances automatisch konfiguriert werden können. Wenn Sie einen anderen Port verwenden möchten, kann dieser direkt auf den Appliances konfiguriert werden.",
            HAMessage: "Schließen Sie Port <0>P1</0> beider Appliances direkt mit einem Netzwerkkabel an. HA Sync startet automatisch.",
            campus: "Weitere Informationen finden Sie im <0>Barracuda Campus</0>",
            serial: "Serie: {{serial}}",
            nonstandardPort: "Warnung: Diese Konfiguration verwendet einen nicht-standardmäßigen Port<0>{{ports}}</0> Bitte stellen Sie sicher, dass dies auf Threr virtuellen Appliance aktiviert ist.",
            nonstandardPort_plural: "Warnung: Diese Konfiguration verwendet nicht-standardmäßigen Ports<0>{{ports}}</0> Bitte stellen Sie sicher, dass diese auf Threr virtuellen Appliance aktiviert sind."
          },
          steps: {
            result: "Abgeschlossen",
            submit: "Zusammenfassung"
          },
          types: {
            dynamic: "Dynamisch (DHCP)",
            configure_on_branch: "Im Branch konfiguriert"
          },
          validation: {
            duplicateNetwork: "Ein Netzwerk, das mit dieser Port/Virtual LAN ID übereinstimmt, wurde bereits konfiguriert.",
            duplicateWwan: "Es kann nur ein WWAN konfiguriert werden",
            duplicateSCwan: "Es kann nur ein dynamisches oder statisches WAN konfiguriert werden",
            duplicateExpressRoute: "Es kann nur eine Express Route konfiguriert werden",
            duplicateName: "Name wird bereits für eine andere Verbindung verwendet",
            secondaryDns: "Sollte nicht eingestellt werden, wenn kein primärer DNS vorhanden",
            availableAsn: "ASN-Wert nicht verfügbar"
          }
        },
        liveLog: {
          tabTitle: "Live-Verbindungen",
          pageTitle: "Live-Verbindungen",
          waitingForConnections: "Verbinden mit der Site. Es kann bis zu 2 Minuten dauern, bis eine Verbindung zustande kommt. Bitte warten...",
          emptyData: "Derzeit keine Live-Verbindungen aktiv...",
          pauseRequestsButton: "Pause",
          resumeRequestsButton: "Wiederaufnahme"
        },
        menuTitle: "SITES",
        pageTitle: "Sites",
        settings: {
          tabTitle: "Einstellungen",
          basic: {
            tabTitle: "Grundlegende Informationen",
            name: "Name",
            model: "Modell",
            gateway: "Edge Service",
            serials: "Seriennummer(n)",
            descriptions: {
              gateway: "Barracuda SecureEdge Edge Service, mit dem diese Site verbunden ist.",
              name: "Eindeutiger Name für Ihre Site.",
              model: "Appliance-Modell für diese Site.",
              serials: "Appliance-Seriennummer(n) für diese Site."
            },
            serialEdit: {
              table: {
                status: "Status",
                serial: "Seriennummer",
                add: "Zweite hinzufügen"
              },
              addTitle: "Seriennummer hinzufügen",
              editTitle: "Seriennummer tauschen",
              deleteTitle: "Seriennummer entfernen",
              edit: "Wählen Sie unten die Seriennummer des Ersatz-Appliance aus, das Sie an diese Site anschließen möchten. Eine Appliance kann nur durch ein andere Appliance desselben Modells ausgetauscht werden.",
              add: "Wenn Sie eine zweite Appliance an Ihre Site anschließen, wird die Hochverfügbarkeitsfunktion aktiviert. Bitte wählen Sie unten die Seriennummer Ihrer Appliance aus.",
              delete1: "Sind Sie sicher, dass Sie die Seriennummer {{serial}} von dieser Site löschen wollen?",
              delete2: "Dadurch wird die Hochverfügbarkeitsfunktion entfernt und die betreffende Appliance deaktiviert.",
              save: "Die Änderungen werden erst nach dem Speichern wirksam.",
              saveDelete: "Nach dem Speichern können Sie die Appliance entfernen.",
              saveAdd: "Nach dem Speichern können Sie die neue Appliance anschließen, indem Sie zunächst Port <0>P4</0> der Appliance an eine DHCP-fähige Internetverbindung anschließen, damit die Appliance automatisch konfiguriert werden kann. Wenn Sie einen anderen Port verwenden möchten, kann dieser direkt auf der Appliance konfiguriert werden.",
              saveEdit: "Nach dem Speichern können Sie die alte Appliance entfernen. Dann können Sie die neue Appliance anschließen, indem Sie zunächst Port <0>P4</0> der Appliance an eine DHCP-fähige Internetverbindung anschließen, damit die Appliance automatisch konfiguriert werden kann. Wenn Sie einen anderen Port verwenden möchten, kann dieser direkt auf der Appliance konfiguriert werden.",
              saveHA: "Verbinden Sie außerdem Port <0>P1</0> der neuen Appliance und der vorhandenen Appliance direkt mit einem Netzwerkkabel. HA Sync startet automatisch.",
              saveEditSecondary: "Die alte Appliance wurde für HA konfiguriert. Bevor es wieder verwendet werden kann, muss ein Re-Imaging erfolgen.",
              saveDeleteSecondary: "Die Appliance wurde für HA konfiguriert. Bevor es wieder verwendet werden kann, muss ein Re-Imaging erfolgen.",
              saveDeletePrimary: "Die andere mit dieser Site verbundene Appliance wurde für HA konfiguriert. Nach dem Speichern muss ein Re-Imaging erfolgen, damit es sich selbst als Nicht-HA-Appliance rekonfigurieren kann. Dies bedeutet eine gewisse Ausfallzeit für Ihre Site, bis dieser Prozess abgeschlossen ist."
            }
          },
          wan: {
            tabTitle: "WAN",
            addTitle: "Neues WAN Interface hinzufügen",
            addButtonText: "WAN Interface hinzufügen",
            editTitle: "WAN Interface bearbeiten",
            deleteTitle: "WAN Interface löschen",
            deleteMessage: "Sind Sie sicher, dass Sie dieses WAN Interface löschen wollen? <0> Die Löschung wird erst beim Speichern wirksam.",
            name: "Name",
            port: "Port",
            virtualLanId: "Virtual LAN ID",
            type: "Typ",
            providerPinning: "Provider Pinning",
            address: "IP-Adresse",
            netmask: "Netzmaske",
            gateway: "Gateway",
            pin: "SIM Pin für {{serial}}",
            scPin: "SIM Pin",
            invalidPinFormat: "SIM Pin sollte 4 Ziffern haben",
            portWarning: "Die ausgegrauten Zeilen oben zeigen an, dass der ausgewählte Port nicht auf der Appliance aktiviert wurde.",
            failover: "HA Failover",
            network: "Netzwerk",
            doublePort: "Port",
            doubleVirtualLanId: "Virtual LAN ID",
            doubleAddress: "IP-Adresse",
            doubleNetmask: "Netzmaske",
            virtualTransferNetwork: "Virtual Transfer Network",
            asn: "Express Route ASN",
            expressRoute: "Express Route",
            primary: "Primär",
            secondary: "Sekundär",
            validatePublicIp: "Muss für Benutzer-Konnektivität eine öffentliche IP sein",
            descriptions: {
              name: "Eindeutiger Name für diese WAN-Verbindung",
              port: "Geräte-Port, der für diese Verbindung verwendet werden soll.",
              virtualLanId: "Optionale Virtual LAN ID für das Netzwerk (wird verwendet, wenn eine Verbindung zu einem Switch mit Virtual-LAN-Unterstützung besteht).",
              type: "Netzwerk, das für diese Verbindung verwendet soll.",
              providerPinning: "Providergruppe, mit der diese Verbindung verknüpft werden soll. Providergruppen werden in den SD-WAN-Richtlinien verwendet, um den Traffic-Fluss und das Verhalten zu definieren.",
              address: "Die dem Port zugewiesene IP-Adresse.",
              netmask: "Network CIDR Netmask Suffix für diese Verbindung.",
              gateway: "Mit dieser Verbindung verknüpfte Gateway IP-Adresse.",
              pin: "Persönliche Identifikationsnummer für die in diesem Gerät installierte SIM-Karte.",
              failover: "Falls aktiviert, wird der Port überwacht und ein HA-Failover-Ereignis durchgeführt, wenn die Verbindung unterbrochen wird. Nur auf einer HA-fähigen Website verfügbar. HINWEIS: Um zu verhindern, dass das Gerät nicht mehr erreichbar ist, überprüfen Sie bitte vor der Aktivierung, ob die zu überwachende Verbindung aktiv ist.",
              doublePort: "Die für die primären und sekundären Verbindungen zu verwendenden Appliance-Ports.",
              doubleVirtualLanId: "Optionale Virtual LAN ID für die primären und sekundären Verbindungen (wird verwendet, wenn eine Verbindung zu einem Switch mit Virtual LAN-Unterstützung besteht).",
              doubleAddress: "Die den primären und sekundären Verbindungen zuzuweisende IP-Adresse. Dabei sollte es sich um die erste nutzbare IP-Adresse der in Azure für jede Verbindung konfigurierten Bereiche handeln (wenn z. B. 172.16.0.4/30 in Azure konfiguriert ist, ist 172.16.0.5 die einzugebende IP-Adresse).",
              doubleNetmask: "Network CIDR Netmask Suffix für die primären und sekundären Verbindungen.",
              asn: "Automatisch generierte BGP-ASN für diese Verbindung. Dies führt nicht zu Konflikten mit anderen ASNs, die innerhalb des Cloud Gen WAN-Service verwaltet werden.",
              virtualTransferNetwork: "Netzwerk, das zur Reservierung von IPs für die Virtual Transfer Interfaces verwendet wird."
            }
          },
          lan: {
            tabTitle: "LAN",
            sectionTitle: "LAN Interfaces",
            addTitle: "Neues LAN Interface hinzufügen",
            addButtonText: "LAN Interface hinzufügen",
            editTitle: "LAN Interface bearbeiten",
            deleteTitle: "LAN Interface löschen",
            deleteMessage1: "Sind Sie sicher, dass Sie dieses LAN Interface löschen wollen? Dies hat folgenden Effekt:",
            deleteMessage2: "Alle dazugehörigen DHCP-Konfigurationen und Reservierungen werden entfernt.",
            deleteMessage3: "Alle Sicherheitsregeln, die dieses LAN Interface verwenden, werden aktualisiert oder entfernt.",
            deleteMessage4: "Die Löschung wird erst beim Speichern wirksam.",
            name: "Name",
            port: "Port",
            virtualLanId: "Virtual LAN ID",
            address: "IP-Adresse",
            netmask: "Netzmaske",
            dhcpEnable: "DHCP Server",
            dhcpFirst: "Erste IP-Adresse",
            dhcpLast: "Letzte IP-Adresse",
            dhcpDomain: "DHCP Domain",
            dhcpRange: "DHCP-Bereich",
            portWarning: "Die ausgegrauten Zeilen oben zeigen an, dass der ausgewählte Port auf der Appliance nicht aktiviert wurde.",
            failover: "HA Failover",
            descriptions: {
              name: "Eindeutiger Name für diese LAN-Verbindung",
              port: "Geräte-Port, der für diese Verbindung verwendet werden soll.",
              virtualLanId: "Optionale Virtual LAN ID für das Netzwerk (wird verwendet, wenn eine Verbindung zu einem Switch mit Virtual-LAN-Unterstützung besteht).",
              address: "Die dem Port zugewiesene IP-Adresse.",
              netmask: "Network CIDR Netmask Suffix, das für das erstellte Netzwerk verwendet werden soll.",
              dhcpEnable: "DHCP-Server auf der Appliance für diese Verbindung aktivieren.",
              dhcpFirst: "Die erste für die DHCP-Zuweisung verfügbare IP. Muss sich innerhalb des durch die Port-IP und Netzmaske definierten Netzwerks befinden.",
              dhcpLast: "Die letzte für die DHCP-Zuweisung verfügbare IP. Muss sich innerhalb des durch die Port-IP und Netzmaske definierten Netzwerks befinden.",
              dhcpDomain: "Der dem DHCP Server zugewiesene Domain Name.",
              failover: "Falls aktiviert, wird der Port überwacht und ein HA-Failover-Ereignis durchgeführt, wenn die Verbindung unterbrochen wird. Nur auf einer HA-fähigen Website verfügbar. HINWEIS: Um zu verhindern, dass das Gerät nicht mehr erreichbar ist, überprüfen Sie bitte vor der Aktivierung, ob die zu überwachende Verbindung aktiv ist."
            }
          },
          route: {
            tabTitle: "Route",
            sectionTitle: "Statische Routen",
            addTitle: "Neue statische Route hinzufügen",
            addButtonText: "Statische Route hinzufügen",
            editTitle: "Statische Route bearbeiten",
            deleteTitle: "Statische Route löschen",
            deleteMessage: "Sind Sie sicher, dass Sie diese statische Route löschen möchten? Die Löschung wird erst beim Speichern wirksam.",
            name: "Name",
            address: "Ziel-Netzwerk",
            netmask: "Netzmaske",
            gateway: "Gateway",
            descriptions: {
              name: "Eindeutiger Name für diese Route.",
              address: "Ziel-Netzwerk-Adresse für diese Route.",
              netmask: "CIDR Netmask Suffix für das Ziel-Netzwerk.",
              gateway: "Für diese Route zu verwendendes Gateway."
            }
          },
          dhcp: {
            tabTitle: "DHCP",
            networksSectionTitle: "DHCP-Netzwerke",
            leasesSectionTitle: "DHCP Leases",
            reservationsSectionTitle: "DHCP-Reservierungen",
            dhcpEnable: "DHCP Service aktivieren",
            addNetworkTitle: "Neues DHCP-Netzwerk hinzufügen",
            addNetworkButtonText: "Netzwerk hinzufügen",
            editNetworkTitle: "DHCP-Netzwerk bearbeiten",
            deleteNetworkTitle: "DHCP-Netzwerk löschen",
            deleteNetworkMessage: "Sind Sie sicher, dass Sie dieses DHCP-Netzwerk löschen wollen? Die Löschung wird erst beim Speichern wirksam.",
            networks: {
              networkSectionTitle: "Netzwerk",
              basicSectionTitle: "Basic",
              basicTabTitle: "Basic",
              advancedSectionTitle: "Erweitert",
              advancedTabTitle: "Erweitert",
              enable: "Aktiver DHCP-Bereich",
              port: "LAN Name",
              description: "Beschreibung",
              firstIp: "Erste IP-Adresse",
              lastIp: "Letzte IP-Adresse",
              domain: "Domain Name",
              dnsServers: "DNS Servers",
              bootFileName: "Boot File Name",
              defaultLeaseTime: "Standard-Leasingdauer",
              maxLeaseTime: "Maximale Leasingdauer",
              winsServers: "WINS Servers",
              tftpHostname: "TFTP Host Name",
              tftpIpAddress: "TFTP IP-Adresse",
              vendorOptions: "Anbieter-Optionen",
              validateMaxLeaseTime: "Darf nicht kürzer als die Standard-Leasingdauer sein"
            },
            blockUnknownMac: "Nur bekannte MAC-Adressen zulassen",
            addReservationTitle: "Neue DHCP-Reservierung hinzufügen",
            addReservationButtonText: "Reservierung hinzufügen",
            editReservationTitle: "DHCP-Reservierung bearbeiten",
            deleteReservationTitle: "DHCP-Reservierung löschen",
            deleteReservationMessage: "Sind Sie sicher, dass Sie diese DHCP-Reservierung löschen wollen? Die Löschung wird erst beim Speichern wirksam.",
            leases: {
              reservationSectionTitle: "Leases",
              port: "LAN Name",
              ip: "IP-Adresse",
              mac: "MAC-Adresse",
              reserve: "Reserve IP",
              noLeases: "Derzeit keine Leases aktiv. Es kann bis zu 2 Minuten dauern, bis neue Leases angezeigt werden."
            },
            reservations: {
              reservationSectionTitle: "Reservierung",
              name: "Name",
              description: "Beschreibung",
              port: "LAN Name",
              ip: "IP-Adresse",
              mac: "MAC-Adresse",
              clientId: "Client ID",
              hostname: "Hostname",
              validateMac: "Muss eine gültige MAC-Adresse sein",
              validateHostname: "Darf nur alphanumerischen Zeichen, '-' oder '_‘ Zeichen enthalten.",
              duplicate: "Muss innerhalb des LAN eindeutig sein",
              duplicateName: "Muss eindeutig sein"
            },
            descriptions: {
              dhcpEnable: "DHC Service Provider für diese Site aktivieren.",
              blockUnknownMac: "Wenn diese Option aktiviert ist, können nur die in der folgenden Tabelle aufgeführten MAC-Adressen den DHCP-Dienst nutzen.",
              networks: {
                enable: "DHCP-Server auf der Appliance für diese Verbindung aktivieren.",
                description: "Optionale Beschreibung für diese DHCP-Serverkonfiguration angeben.",
                port: "LAN Interface, auf dem dieses DHCP-Netzwerk konfiguriert werden soll.",
                firstIp: "Die erste für die DHCP-Zuweisung verfügbare IP. Muss sich innerhalb des durch die Port-IP und Netzmaske definierten Netzwerks befinden.",
                lastIp: "Die letzte für die DHCP-Zuweisung verfügbare IP. Muss sich innerhalb des durch die Port-IP und Netzmaske definierten Netzwerks befinden.",
                domain: "Dem DHCP Server zugewiesenen Domain Name optional festlegen.",
                dnsServers: "Liste von zu verwendenden DNS-Servern optional festlegen.",
                bootFileName: "Den Namen einer vom Client geladenen Boot-Datei optional festlegen. Er sollte für jedes Dateiübertragungsprotokoll, das der Client zum Laden der Datei verwenden kann, erkennbar sein.",
                defaultLeaseTime: "Standardlänge in Sekunden, die einem Lease zugewiesen wird.",
                maxLeaseTime: "Maximale Länge in Sekunden, die einem Lease zugewiesen wird.",
                winsServers: "Eine oder mehr WINS Server IP-Adressen optional hinzufügen.",
                tftpHostname: "Den Hostname eines TFTP-Servers optional festlegen.",
                tftpIpAddress: "Die IP-Adresse eines TFTP-Servers optional festlegen.",
                vendorOptions: "Eine oder mehrere durch ';' getrennte Anbieter-Optionen optional festlegen."
              },
              reservations: {
                name: "Zur Identifizierung dieser Reservierung verwendeter Name.",
                description: "Optionale Beschreibung für diese Reservierung.",
                port: "LAN Interface, auf dem diese Reservierung durchgeführt werden soll.",
                ip: "Für den Client zu reservierende IP.",
                mac: "MAC-Adresse zur Identifizierung des Client.",
                clientId: "Client Identifier, der den Host-Client bei der Anfrage nach einer IP-Adresse identifiziert.",
                hostname: "Dieser IP zuzuweisender Hostname."
              }
            }
          },
          advanced: {
            tabTitle: "Erweiterte Einstellungen",
            primaryDns: "Primärer DNS",
            secondaryDns: "Sekundärer DNS",
            rootPassword: "Root-Passwort",
            rootPasswordConfirmation: "Root-Passwort bestätigen",
            updateWindowTZ: "Zeitzone Update-Fenster",
            disableOMS: "Log Analytics deaktivieren",
            disableSecurityPolicy: "Security deaktivieren",
            disableIpsPolicy: "IPS deaktivieren",
            omsNote: "Damit werden die Standardeinstellungen von Log Analytics überschrieben.",
            descriptions: {
              updateWindowTZ: "Die Zeitzone für die Site, die für die Bestimmung des Update-Fensters für diese Site verwendet wird.",
              primaryDns: "Primäre DNS-Adresse manuell einstellen.",
              secondaryDns: "Sekundäre DNS-Adresse manuell einstellen.",
              rootPassword: "Für diese Site verwendetes Root-Benutzer-Passwort.",
              disableOMS: "Streaming von Protokollen an Azure Log Analytics für diese Site deaktivieren.",
              disableSecurityPolicy: "Alle Sicherheitsrichtlinien für diese Site deaktivieren.",
              disableIpsPolicy: "IPS Policy für diese Site deaktivieren."
            },
            downloadBootstrapConfigDialog: {
              button: "Schaltfläche",
              buttons: "Schaltflächen",
              buttonLabel: "Bootstrap-Konfiguration",
              title: "Bootstrap-Konfiguration downloaden",
              dialogMessage: "Die Appliances sollten sich automatisch mit SecureEdge verbinden und ihre Konfiguration herunterladen, vorausgesetzt, sie verfügen über eine DHCP-unterstützte Internetverbindung.\n\nWenn keine Internetverbindung zur Verfügung steht, können Sie die Appliances mit dem Tool \"Barracuda Firewall Install\" und einem USB-Flashspeicher konfigurieren. Eine Dokumentation für dieses Tool finden Sie <0>hier</0>.\n\nVerwenden Sie die Schaltfläche {{button}} weiter unten, um die Konfigurationsdateien herunterzuladen. Diese Dateien sollten während der Installation der Barracuda Firewall als \"CGW-Konfigurationen\" bereitgestellt werden.",
              dialogMessageSc: "Die Appliances sollten sich automatisch mit SecureEdge verbinden und ihre Konfiguration herunterladen, vorausgesetzt, sie verfügen über eine DHCP-unterstützte Internetverbindung.\n\nVerwenden Sie die Schaltfläche {{button}} weiter unten, um die Konfigurationsdatei herunterzuladen.\nDiese Datei sollte in das Root-Verzeichnis eines USB-Flashspeichers kopiert werden.\nWenn der USB-Speicher beim Booten aktiv ist, wird die Konfiguration übernommen.",
              downloadButtonLabel: "Download",
              serial: "Seriennummer {{serial}}"
            }
          },
          confirm: "Einige der ausgewählten Ports sind derzeit nicht für diese Site aktiviert. Sind Sie sicher, dass Sie fortfahren möchten?"
        },
        table: {
          name: "Name",
          description: "Beschreibung",
          serial: "Seriennummer",
          model: "Modell",
          gateway: "Edge Service",
          cloudVirtualWan: "Cloud VWAN",
          privateCloud: "Keine",
          status: "Verbindungs-Status",
          peeringAddress: "Peering Address",
          asn: "AS-Nummer",
          lan: "LANs",
          wan: "WANs",
          linkAddresses: "Adressen",
          noSites: "Keine Sites gefunden.",
          noIot: "Keine IoT-Geräte gefunden.",
          dynamic: "Dynamisch",
          wwan: "WWAN",
          bridge: "Bridge",
          vpnSite: "Client VPN-Konfiguration downloaden.",
          vpnSiteDisabled: "Um diese Aktion durchführen zu können, muss die Funktion für Benutzerverbindungen aktiviert und ein statisches WAN für Benutzerverbindungen zugewiesen sein.",
          noGateway: "(Keiner)",
          licenseType: "Lizenztyp",
          licenseExpiration: "Lizenzablauf"
        },
        siteNotFound: "Die von Ihnen gesuchte Site wurde nicht gefunden.",
        noData: {
          iconHeader: "Beginnen Sie mit dem Hinzufügen einer Site!",
          textHeader: "Was ist eine Site?",
          text: "Eine Site bezeichnet einen Ort, der durch ein Site Device verbunden (und geschützt) ist.<0/>Ein Site Device kann als Hardware oder als virtuelle Appliance implementiert werden - je nachdem, was Ihren Anforderungen am besten entspricht.<0/>Das Hinzufügen eines Site Device zu Ihrer Netzwerkinstallation ist ein einfacher und unkomplizierter Prozess.<0/><0/>Sie haben noch keine Site?",
          demoLicenseLink: "<0>Demo-Lizenz anfordern</0>"
        }
      },
      gateways: {
        menuTitle: "EDGE SERVICES",
        pageTitle: "Edge Services",
        noData: {
          iconHeader: "Beginnen Sie mit dem Hinzufügen eines Edge Service!",
          textHeader: "Was ist ein Edge Service?",
          text: "Ein Edge Service fungiert als Verbindungspunkt für Ihre Sites und Remote Access Agents.<0/> Sowohl die Sites als auch die Agents wählen automatisch den nächstgelegenen verfügbaren Edge Service, um eine optimale Servicequalität zu gewährleisten."
        },
        table: {
          asn: "AS-Nummer",
          name: "Name",
          type: "Typ",
          upstream: "Upstream",
          region: "Ort",
          instances: "Instanzen",
          size: "Größe",
          HA: "(HA)",
          upstream_gateway: "Upstream Edge Service",
          scaleUnit: "Skalierungseinheit",
          sites: "Sites",
          status: "Verbindungs-Status",
          vpnGateway: "Client VPN-Konfiguration downloaden.",
          vpnGatewayDisabled: "Um diese Aktion durchführen zu können, muss die Funktion für Benutzerverbindungen aktiviert sein.",
          privateCloud: "Private Edge Service",
          managedCloud: "Edge Service",
          noGateway: "(Keiner)",
          mbit: "MBit",
          filters: {
            sizeSelector: "Größentyp",
            scaleUnit: "Skalierungseinheit",
            model: "Modell",
            bandwidth: "Bandbreite"
          }
        },
        dashboard: {
          locationsCardTitle: "Karte",
          tabTitle: "Dashboard",
          connectedSites: {
            tabTitle: "Verbundene Site."
          },
          firmware: {
            tabTitle: "Firmware",
            version: "Version",
            hotfixes: "Installierte Hotfixes",
            window: "Nächstes Update",
            nextUpdate: "Von {{from}} bis {{to}}"
          }
        },
        liveLog: {
          tabTitle: "Live-Verbindungen",
          pageTitle: "Live-Verbindungen",
          waitingForConnections: "Verbindung mit Edge Service wird aufgebaut. Es kann bis zu 2 Minuten dauern, bis eine Verbindung zustande kommt. Bitte warten...",
          emptyData: "Derzeit keine Live-Verbindungen aktiv...",
          pauseRequestsButton: "Pause",
          resumeRequestsButton: "Wiederaufnahme"
        },
        connectionHistory: {
          pageTitle: "Letzte Verbindungen",
          tabTitle: "Letzte Verbindungen",
          waitingForConnections: "Verbindung mit Edge Service wird aufgebaut. Es kann bis zu 2 Minuten dauern, bis eine Verbindung zustande kommt. Bitte warten...",
          emptyData: "Keine letzten Verbindungen...",
          pauseRequestsButton: "Pause",
          resumeRequestsButton: "Wiederaufnahme"
        },
        delete: {
          body: "Sind Sie sicher, dass Sie diesen Edge Service löschen möchten?",
          title: "Edge Service löschen {{name}}",
          forbidden: "Um diesen Edge Service zu löschen, löschen Sie bitte zuerst alle zugehörigen Edge Services und Sites."
        },
        dialog: {
          basic: {
            name: "Name",
            sectionTitle: "Grundlegende Informationen",
            tabTitle: "Basic"
          },
          create: {
            buttonText: "Neuer Edge Service",
            cloud: {
              menuLabel: "Edge Service für Virtual WAN",
              dialogTitle: "Edge Service Token erstellen"
            },
            onPrem: {
              menuLabel: "Private Edge Service",
              dialogTitle: "Neuer Private Edge Service",
              serials: {
                sectionTitle: "Private Edge Service-Apps"
              }
            },
            managed: {
              menuLabel: "Edge Service",
              dialogTitle: "Neuer Edge Service",
              name: "Name",
              region: "Region",
              bandwidth: "Bandbreite",
              bandwidthAvailable: "{{availableBandwidth}} Verfügbare Mbps",
              invalidBandwidth: "Max wählbare Bandbreite: {{availableBandwidth}} Mbit. Verringern Sie die Bandbreitenauswahl oder erwerben Sie zusätzliche Kapazität, um diesen Edge Service zu erstellen."
            }
          },
          edit: {
            dialogTitle: "Edge Service bearbeiten"
          },
          result: {
            managedAppText: "Managed App OTP",
            message: "Kopieren Sie das folgende Token in Ihre <0>Edge Service für Virtual WAN</0>-Installation.",
            managedAppOtp: "Dieses Token ist nur für eine einzige Bereitstellung gültig und läuft in 30 Minuten ab."
          },
          steps: {
            result: "Abgeschlossen",
            submit: "Konfigurieren"
          },
          validation: {
            duplicateName: "Darf kein Duplikat des Edge-Service-Namens sein"
          }
        },
        settings: {
          tabTitle: "Einstellungen",
          managed: {
            bandwidth: {
              invalidBandwidth: "Max wählbare Bandbreite: {{availableBandwidth}} Mbit. Verringern Sie die Bandbreitenauswahl oder erwerben Sie zusätzliche Kapazität, um diesen Edge Service zu aktualisieren."
            }
          },
          labels: {
            secureInternetTraffic: "Internet Traffic aus VNETs sichern"
          },
          descriptions: {
            secureInternetTraffic: "Prüfung des Internet Traffic aus diesem virtuellen Netzwerk durch diesen Edge Service zulassen."
          },
          editBandwidthWarning: "Eine Änderung der Bandbreite führt dazu, dass der Edge Service vorübergehend nicht verfügbar ist, da er neu gestartet werden muss."
        },
        auditLog: {
          tabTitle: "Audit-Protokoll",
          pageTitle: "Audit-Protokoll"
        },
        gatewayNotFound: "Der von Ihnen gesuchte Edge Service wurde nicht gefunden."
      },
      logs: {
        pageTitle: "Logs und Berichte",
        audit: "Audit-Protokoll",
        notifications: "Benachrichtigungen",
        notificationsMessage: "Benachrichtigungen für administrative und Systemereignisse für alle Workspaces konfigurieren.",
        auditLog: {
          message: "Audit Logs zeigen administrative und Systemereignisse für alle Workspaces.",
          labels: {
            status: "Status",
            created: "Erstellt",
            type: "Typ",
            event: "Name",
            serial: "Seriennummer",
            description: "Beschreibung",
            remoteAddress: "Öffentliche IP",
            createdUsername: "Administrator"
          }
        },
        connectionHistory: {
          detailsTitle: "Verbindungsdetails",
          labels: {
            application: "Anwendung",
            count: "Zähler",
            destinationIP: "Ziel-IP",
            destinationPort: "Ziel-Port",
            information: "Information",
            ipProtocol: "IP-Protokoll",
            lastTime: "Letzter Zeitpunkt",
            nextHop: "NextHop",
            sourceIP: "Quell-IP",
            sourceMac: "Quell-MAC",
            state: "Zustand",
            urlCategory: "URL-Kategorie",
            user: "Benutzer"
          }
        },
        liveLog: {
          detailsTitle: "Verbindungsdetails",
          labels: {
            agentId: "Agent-ID",
            appName: "App-Kontext",
            application: "Anwendung",
            connectionTrafficState: "Verbindungsstatus",
            destinationBytesDescription: "Bytes Out",
            destinationIp: "Ziel-IP",
            destinationInterface: "Ziel-Interface",
            destinationPort: "Ziel-Port",
            idleTime: "Inaktiv seit",
            ipProtocol: "IP-Protokoll",
            protocol: "Protokoll",
            protocolId: "Protokoll-ID",
            serial: "Seriennummer",
            sourceBytesDescription: "Bytes In",
            sourceIp: "Quell-IP",
            sourcePort: "Quell-Port",
            sourceNat: "Quell-NAT",
            speedDescription: "Geschwindigkeit",
            startTime: "Startzeit",
            state: "Zustand",
            target: "Ziel",
            type: "Typ",
            totalBytesDescription: "Bytes insgesamt",
            urlCategories: "URL-Kategorien",
            user: "Benutzer",
            vpnRoute: "VPN Route"
          },
          connectionState: {
            initiate: "Aktiver One-Way Traffic",
            fail: "Inaktiver One-Way Traffic",
            sync: "Two-Way Traffic",
            closing: "Verbindung wird geschlossen",
            established: "Verbindung hergestellt",
            httpDeny: "Verbindung nicht hergestellt"
          }
        }
      },
      customCategories: {
        table: {
          name: "Name",
          description: "Beschreibung",
          details: "Details",
          detailCategories: "Kategorien ({{activeCategories.0.name}}, +{{additional}}) ",
          detailCategories_1: "Kategorien ({{activeCategories.0.name}}) ",
          detailDomains: "Eingeschl. Domains ({{domains.0}}, +{{additionalDomains}})",
          detailDomains_1: "Eingeschl. Domain ({{domains.0}})",
          domainsExcluded: "Exkludierte Domains",
          domainsIncluded: "Inkludierte Domain",
          noData: "Keine benutzerdefinierten Kategorieregeln gefunden."
        },
        dialog: {
          create: {
            buttonText: "Benutzerdefinierte Kategorie hinzufügen",
            dialogTitle: "Neu Benutzerdefinierte Kategorie"
          },
          edit: {
            dialogTitle: "Benutzerdefinierte Kategorie bearbeiten"
          },
          name: "Name",
          description: "Beschreibung",
          details: "Details",
          includedDomains: "Inkludierte Domain",
          excludedDomains: "Exkludierte Domains",
          descriptions: {
            name: "Eindeutiger Name für diese Regel.",
            description: "Kurze Beschreibung dieser Regel.",
            includedDomains: "Liste der von dieser Regel betroffenen Domains",
            excludedDomains: "Liste aller nicht von dieser Regel betroffenen Domains"
          },
          validation: {
            emptyCategories: "Muss mindestens eine Kategorie oder Domain enthalten"
          },
          delete: {
            title: "Löschen {{name}}",
            body: "Sind Sie sicher, dass Sie diese Regel löschen möchten? Alle Sicherheitsregeln, die diese benutzerdefinierte Kategorie verwenden, werden aktualisiert oder entfernt."
          }
        }
      },
      policies: {
        menuTitle: "SD-WAN",
        sdwan: {
          tabTitle: "App-Kategorien",
          search: "App suchen",
          table: {
            category: "Kategorie",
            apps: "App",
            customApps: "Benutzerdefinierte Apps",
            priority: "Priorität",
            action: "Aktion",
            fallback: "Fallback",
            loadBalancing: "Load Balancing",
            reset: "Reset"
          }
        },
        explicit: {
          delete: {
            title: "Löschen {{name}}",
            body: "Sind Sie sicher, dass Sie diese Richtlinie löschen möchten?"
          },
          tabTitle: "Überschreibungs-Kategorien",
          table: {
            order: "Auftrag",
            name: "Name",
            apps: "Apps",
            sources: "Quelle",
            user: "Benutzer",
            traffic: "Traffic-Typ",
            priority: "Priorität",
            action: "Aktion",
            fallback: "Fallback",
            loadBalancing: "Load Balancing"
          },
          noData: {
            iconHeader: "Beginnen Sie hier mit dem Hinzufügen einer SD-WAN-Richtlinienüberschreibung!",
            labelButton: "Überschreibungsrichtlinie hinzufügen",
            text: "Ihr SecureEdge-Setup bietet einen Satz von allgemeinen SD-WAN-Richtlinien, die sofort einsatzbereit sind. <0/>Wenn Ihr Setup jedoch ein anderes SD-WAN-Profil für bestimmte Apps erfordert, können Sie hier Ihre eigenen Richtlinien definieren."
          }
        },
        dialogs: {
          explicit: {
            create: {
              buttonText: "Richtlinie hinzufügen",
              dialogTitle: "Neue Richtlinie"
            },
            edit: {
              dialogTitle: "Richtlinie bearbeiten"
            },
            general: {
              sectionTitle: "Allgemein",
              name: "Name",
              description: "Beschreibung",
              descriptions: {
                name: "Eindeutiger Name für diese Richtlinie.",
                description: "Kurze Beschreibung dieser Richtlinie."
              }
            },
            criteria: {
              sectionTitle: "Kriterien",
              applications: "Apps",
              sources: "Quell-IP/Netzwerk",
              trafficType: "Traffic-Typ",
              descriptions: {
                applications: "Spezifische Apps, auf die diese Richtlinie angewandt werden soll. Es muss mindestens eine Anwendung oder Quelle angegeben werden.",
                sources: "Spezifische Quell-IPs/Netzwerke, auf die diese Richtlinie angewandt werden soll. Es muss mindestens eine Anwendung oder Quelle angegeben werden.",
                trafficType: "Definieren Sie optional den spezifischen Traffic-Typ, auf den diese Richtlinie angewandt werden soll."
              }
            },
            action: {
              sectionTitle: "Aktion",
              priority: "Priorität",
              action: "Aktion",
              fallback: "Fallback",
              loadBalancing: "Load Balancing",
              forwardErrorCorrection: {
                label: "Letzte-Meile-Optimierung",
                options: {
                  on: "An",
                  off: "Aus"
                }
              },
              descriptions: {
                priority: "Prioritätsstufe, die für den von dieser Richtlinie erfassten Traffic gelten soll.",
                action: "Aktion, die für den von dieser Richtlinie erfassten Traffic durchgeführt werden soll.",
                fallback: "Fallback Behavior, das für den von dieser Richtlinie erfassten Traffic angewandt werden soll.",
                loadBalancing: "Load Balancing Behavior, das für den von dieser Richtlinie erfassten Traffic angewandt werden soll.",
                forwardErrorCorrection: "Schaltet die Letzte-Meile-Optimierung für den von dieser Richtlinie abgedeckten Traffic ein. Dies kann bei verlustbehafteten Netzwerken helfen, erhöht aber die Bandbreitennutzung durch die Clients."
              }
            },
            validation: {
              required: "Eine App oder Quelle ist erforderlich"
            }
          }
        }
      },
      sdwanExplicitPolicies: {
        delete: {
          title: "Delete Policy {{name}}",
          body: "Sind Sie sicher, dass Sie diese Richtlinie löschen möchten?"
        }
      },
      audit: {
        menuTitle: "AUDIT LOG",
        pageTitle: "Audit Log",
        labels: {
          created: "Erstellt",
          type: "Typ",
          event: "Name",
          serial: "Seriennummer",
          description: "Beschreibung",
          status: "Status",
          createdUsername: "Administrator",
          remoteAddress: "Öffentliche IP"
        },
        filter: {
          created: {
            days1: "1 Tag",
            days3: "3 Tage",
            weeks1: "1 Woche",
            weeks2: "2 Wochen",
            months1: "1 Monat",
            months2: "2 Monate",
            months3: "3 Monate",
            months6: "6 Monate",
            months9: "9 Monate",
            years1: "1 Jahr"
          }
        }
      },
      settings: {
        menuTitle: "EINSTELLUNGEN",
        pageTitle: "Einstellungen",
        tabs: {
          workspaces: {
            title: "Workspaces",
            pageTitle: "Workspaces verwalten",
            fields: {
              name: "Name",
              description: "Beschreibung",
              associations: "Sites/Edge Services"
            },
            dialog: {
              create: {
                buttonLabel: "Neuer Workspace",
                dialogTitle: "Neuer Workspace"
              },
              edit: "Workspace bearbeiten"
            },
            delete: {
              body: "Sind Sie sicher, dass Sie diesen Workspace löschen möchten?",
              title: "Löschen {{name}}",
              disabled: {
                notEmpty: "Workspaces können nicht gelöscht werden, solange ihnen eine Site oder ein Edge Service zugeordnet ist.",
                lastWorkspace: "Sie müssen mindestens einen Workspace haben."
              }
            },
            none: "Keine Workspaces verfügbar"
          },
          workspaceSettings: {
            title: "Workspace-Einstellungen"
          },
          updates: {
            title: "Update-Fenster",
            firmware: {
              title: "Firmware-Update-Fenster",
              description: "Legen Sie einen Zeitrahmen fest, um Firmware-Updates automatisch zu installieren. Dieser Zeitrahmen muss mindestens 3 Stunden lang sein und wird in der lokalen Zeitzone des Geräts ausgeführt.",
              noWindowsMessage: "Es wurden keine Update-Fenster festgelegt",
              noUpdatesMessage: "Es stehen keine Updates zur Installation bereit.",
              noAppliancesMessage: "Derzeit erfordert keine Appliance ein Update.",
              default: {
                label: "Standardmäßiges Update-Fenster",
                to: "bis",
                from: "von",
                setting: "<0>Von: </0> <1>{{from}}</1>         <0>Bis: </0> <1>{{to}}</1>"
              },
              create: {
                buttonText: "Neues Update-Fenster",
                dialogTitle: "Neues Update-Fenster hinzufügen"
              },
              edit: {
                dialogTitle: "Update-Fenster bearbeiten"
              },
              delete: {
                title: "Update-Fenster löschen",
                message: "Nach dem Löschen wird für die Site oder den Edge Service, die mit diesem Update-Fenster verbunden sind, das Standard-Update-Fenster verwendet."
              },
              fields: {
                appliance: "Site/Edge Service",
                from: "Von",
                to: "Bis",
                windowLength: "Liefert ein Firmware-Update-Fenster von {{duration}}.",
                timeWindowDescription: "Wählen Sie den Zeitraum, in dem Firmware-Updates automatisch installiert werden können. Das Zeitfenster bezieht sich auf die automatisch ermittelte lokale Zeitzone des jeweiligen Edge Service oder Standalone Site."
              },
              updateNow: {
                buttonLabel: "Jetzt installieren",
                disabledMessage: "Keine Updates verfügbar.",
                dialogTitle: "Jetzt Updates installieren",
                dialogMessage: "Wenn Sie jetzt ein Update durchführen, werden alle geplanten Update-Fenster entfernt und alle Edge Services und Standalone Sites sofort aktualisiert. Sites mit einem Edge Service werden aktualisiert, sobald das Update ihres Edge Service abgeschlossen ist. Dies kann zu Ausfallzeiten führen."
              },
              validation: {
                insufficientWindow: "Das Update-Fenster muss mindesten 3 Stunden lang sein"
              },
              editDefault: {
                dialogTitle: "Standard-Update-Fenster bearbeiten"
              }
            },
            security: {
              title: "Sicherheits-Update-Fenster",
              window: "Hotfix-Update-Fenster",
              description: "Legen Sie einen Zeitrahmen fest, um Ihre Sicherheitsupdates automatisch zu installieren. Das Zeitfenster bezieht sich auf die automatisch ermittelte lokale Zeitzone des jeweiligen Edge Service oder der Site, die in der Site-Konfiguration überschrieben werden kann.",
              setting: "<0>Von: </0> <1>{{from}}</1>         <0>An: </0> <1>{{to}}</1>"
            },
            hotfixUpdateWindow: "Hotfix-Update-Fenster",
            validation: {
              invalidStartTime: "Ungültige Startzeit",
              invalidEndTime: "Ungültige Endzeit"
            }
          },
          oms: {
            title: "Azure Monitor",
            enabled: "Aktiviert",
            id: "Workspace ID",
            key: "Workspace Key",
            descriptions: {
              oms: "Nur dann aktivieren, wenn Sie Microsoft Operations Management Suite (OMS) verwenden. OMS wird nicht mehr weiterentwickelt."
            },
            clientId: "Client ID",
            clientSecret: "Client Secret",
            correlationId: "Correlation ID",
            region: "Region",
            resourceGroup: "Ressourcen-Gruppe",
            subscriptionId: "Abonnement ID",
            tenantId: "Tenant ID",
            oms: "Azure Log Analytics (OMS)",
            disabled: "Deaktiviert",
            introduction: "Mit Azure Monitor können Sie die von Ihren IT-Systemen und Ihrer technologischen Infrastruktur erzeugten Maschinendaten durchsuchen, analysieren und visualisieren, um mehr über Ihr Netzwerk zu erfahren. Streamen Sie Logs in Ihr Azure Monitor-Konto, um die Logdaten Ihrer Installation weiter zu verarbeiten.",
            azureLogAnalyticsDaemon: "Azure Log Analytics Daemon",
            immutableId: "Immutable ID",
            stream: "Stream",
            logIngestionUrl: "Log Ingestion URL"
          },
          global: {
            title: "Globale Einstellungen"
          },
          forwardedDomains: {
            title: "Weitergeleitete Domains",
            domains: "Domains",
            descriptions: {
              domains: "DNS Domains, die zur Auflösung an Azure weitergeleitet werden sollten"
            },
            domain: "Domain",
            forwarders: "Server",
            button: "Hinzufügen",
            duplicateName: "Bereits verwendeter Domain Name",
            addDomain: "Domain hinzufügen",
            editDomain: "Domain bearbeiten",
            deleteDomain: "Domain löschen",
            helpText: "Geben Sie die Domains an, die von einem bestimmten DNS-Server anstelle des Standard-DNS-Servers aufgelöst werden sollen."
          },
          reportCreatorAcl: {
            title: "Report Creator",
            subMenu: "Zulässige Netzwerke",
            description: "Erstellen Sie benutzerdefinierte Berichte anhand von Statistiken und Protokollen, die von den Sites und Edge Services gesammelt wurden. Fügen Sie Sites/Edge Services hinzu, die bei der Erstellung von Berichten abgefragt werden sollen."
          },
          vmActivation: {
            title: "VM-Aktivierung",
            firstName: "Vorname",
            lastName: "Nachname",
            companyName: "Firmenname",
            phoneNumber: "Telefonnummer",
            address: "Adresszeile 1",
            address2: "Adresszeile 2",
            city: "Stadt",
            country: "Land",
            emailAddress: "E-Mail-Adresse",
            state: "Zustand",
            zip: "PLZ",
            billingDetailsSection: "Rechnungsdaten",
            billingAddressSection: "Rechnungsadresse"
          },
          notification: {
            title: "Benachrichtigungen",
            delete: "Benachrichtigung löschen ",
            labels: {
              name: "Name",
              events: "Ereignisse",
              status: "Status",
              administrators: "Administratoren",
              actions: "Aktionen"
            },
            descriptions: {
              name: "Eindeutiger Name für diese Benachrichtigung.",
              events: "Ereignisse, für die Benachrichtigungen verschickt werden sollen.",
              status: "Statustypen, für die Benachrichtigungen verschickt werden sollen.",
              administrators: "E-Mail-Adressen der Administratoren, an die Benachrichtigungen verschickt werden sollen.",
              actions: "Aktionen, für die Benachrichtigungen verschickt werden sollen."
            },
            components: {
              button: {
                add: "Hinzufügen",
                edit: "Bearbeiten",
                delete: "Löschen",
                save: "Speichern",
                cancel: "Stornieren"
              },
              title: {
                editNotification: "Benachrichtigung bearbeiten",
                createNotification: "Benachrichtigung erstellen"
              }
            },
            action: {
              deleteNotification: "Sind Sie sicher, dass Sie diese Benachrichtigung löschen möchten?"
            }
          },
          sdwanConnectorNetwork: {
            title: "Connector",
            description: "Mit einem Connector können Sie Ihrem Netzwerk Linux- und Windows-Server hinzufügen. Der Connector IP-Adressbereich verwaltet den IP-Pool für Connectors.",
            connectorIpRange: "Connector IP-Adressbereich"
          },
          dcAgent: {
            title: "Domain Controller Agent",
            description: "Um die Anmeldeaktivitäten der Benutzer in Ihren Windows-Domänen transparent zu verfolgen, geben Sie die IP-Adresse Ihres installierten Domain Controller Agent ein.",
            ipAddress: "IP-Adresse"
          },
          dhcpServer: {
            title: "DHCP Relay Server",
            description: "Geben Sie die IP-Adresse des DHCP-Servers ein, den Sie verwenden möchten.",
            ipAddress: "IP-Adresse"
          },
          SIPProxy: {
            title: "SIP Proxy",
            UdpTimeout: "UDP Timeout",
            enabled: "Aktiviert",
            sipProxySetting: "SIP Proxy",
            cardDescription: "Der SIP-Proxy fungiert als Vermittler zwischen Endpunkten wie IP-Telefonen, Mobilgeräten und Konferenzgeräten.",
            description: {
              UdpTimeout: "UDP-Timeout (in Sekunden) wird verwendet, wenn der SIP-Proxy nicht aktiviert ist.",
              sipProxySetting: "SIP Proxy verwendet standardmäßig ein Timeout von 60s."
            }
          },
          updateWindows: {
            title: "Update-Fenster"
          },
          generalSettings: {
            title: "Allgemein"
          }
        }
      },
      statusBanner: {
        subscriptionExpiresSoon: "Ihre Subscription läuft in {{days}} Tagen ab",
        subscriptionExpiresOneDay: "Ihre Subscription läuft in 1 Tag ab",
        subscriptionExpiresToday: "Ihre Subscription läuft in weniger als 1 Tag ab",
        deprecatedExpiresSoon: "Das von Ihnen verwendete Azure-Subscriptionmodell wird nicht mehr unterstützt. Dieser Service läuft in {{days}} Tage ab, wenn Sie nicht auf ein neues Modell umsteigen. Ändern Sie Ihr Modell bitte {{linkStart}}hier{{linkEnd}}.",
        deprecatedExpiresOneDay: "Das von Ihnen verwendete Azure-Subscriptionmodell wird nicht mehr unterstützt. Dieser Service läuft in 1 Tag ab, wenn Sie nicht auf ein neues Modell umsteigen. {{linkStart}}{{linkEnd}}",
        deprecatedExpiresToday: "Das von Ihnen verwendete Azure-Subscriptionmodell wird nicht mehr unterstützt. Dieser Service läuft in weniger als einem Tag ab, wenn Sie nicht auf ein neues Modell umsteigen. Ändern Sie Ihr Modell bitte {{linkStart}}hier{{linkEnd}}",
        updateAvailable: "Es steht ein neues Firmware Update zur Verfügung! Vereinbaren Sie vor {{date}} ein neues Wartungsfenster, um den Update-Prozess zu starten.",
        pamBandwidthCapacity: "Ihre aktuelle Edge-Service-Bandbreite übersteigt die lizenzierte Menge. Bitte kontaktieren Sie den Vertrieb, um mehr Bandbreite zu erwerben."
      },
      states: {
        pending: "Ausstehend",
        completed: "Abgeschlossen",
        online: "Online",
        offline: "Offline",
        failed: "Fehlgeschlagen",
        error: "Fehler",
        degraded: "Herabgestuft",
        connecting: "Verbindung wird aufgebaut",
        unknown: "Unbekannt",
        loading: " "
      },
      validation: {
        alphaNumericDash: "Darf nur Zahlen, Buchstaben und Bindestriche enthalten",
        alphaNumericDotsDash: "Darf nur Zahlen, Buchstaben, Punkte und Bindestriche enthalten",
        alphaNumericDotsColonsDash: "Darf nur Zahlen, Buchstaben, Punkte, Doppelpunkte und Bindestriche enthalten",
        alphaNumericDashNoHaSuffix: "Darf kein '-ha'-Suffix enthalten",
        alphaNumericDashSpace: "Darf nur Zahlen, Buchstaben, Bindestriche und Leerzeichen enthalten",
        customKeyword: "darf nur maximal 128 Zahlen, Buchstaben, Bindestriche, Punkte, Apostrophe oder Leerzeichen enthalten. Muss mit einem alphanumerischen Zeichen beginnen und enden.",
        uuid: "muss eine gültige UUID-/ GUID sein"
      },
      footer: {
        serial: " Abonnement-Seriennummer: {{serial}}"
      },
      users: {
        table: {
          fullName: "Vollständiger Name",
          email: "E-Mail",
          deviceCount: "Anzahl der Geräte",
          enrollmentCompleted: {
            label: "Anmeldung abgeschlossen",
            yes: "Anmeldung abgeschlossen",
            no: "Anmeldung ausstehend"
          },
          enrollmentLink: "Anmeldungs-Link kopieren",
          enrollmentLinkCopied: "Anmeldungs-Link in Clipboard kopieren",
          resendInvite: "Einladung erneut versenden",
          tamperProof: "Manipulationssicher",
          devicePreConnect: "Endgeräte Pre-Logon",
          webFiltering: "Web-Filterung",
          optionsOverride: "Benutzer-Override",
          on: "An",
          off: "Aus",
          tpmEnforcement: "TPM erzwingen"
        },
        delete: {
          title: "Benutzer abmelden",
          body: "Sind Sie sicher, dass Sie diesen Benutzer entfernen möchten?"
        },
        dialog: {
          buttonText: "Benutzer anmelden",
          disabledButton: "Sie müssen zunächst einen Identity Provider (Identität > Einstellungen) und entweder einen lokalen Benutzer (Identität > Benutzerseite) oder ein Benutzerverzeichnis (Identität > Einstellungen) konfigurieren."
        },
        noData: {
          iconHeader: "Beginnnen Sie mit dem Hinzufügen eines Benutzers!",
          labelButton: "Benutzer hinzufügen und einladen",
          textHeader: "Warum sind Benutzer für SecureEdge Access so wichtig?",
          text: "SecureEdge Access kontrolliert die Zugangsrechte und Berechtigungen der Benutzer in Ihrem Netzwerk. Hier können Sie also festlegen, wer zugreifen darf, und den Anmeldeprozess für die SecureEdge Access Agents starten.",
          firstTitle: "1",
          firstStep: "Anmelde-Einladung versenden",
          secondTitle: "2",
          secondStep: "Lassen Sie den Benutzer den Agent herunterladen, installieren und sich selbst anmelden.",
          textFooter: "Das war‘s. Sie können loslegen!"
        },
        settings: {
          global: "Benutzer-Override",
          introduction: "Überschreiben Sie die globalen ZTNA-Einstellungen für diesen Benutzer.",
          tamperProof: "Manipulationssicher",
          devicePreConnect: "Windows Pre-Logon",
          deviceLimit: "Benutzer-Gerätelimit",
          webFiltering: "Web-Filterung",
          enabled: "Aktiviert",
          disabled: "Deaktiviert",
          overrideEnabled: "unter Verwendung der unten angegebenen Einstellungen",
          overrideDisabled: "unter Verwendung der globalen ZTNA-Einstellungen",
          descriptions: {
            tamperProof: "Verhindert, dass der Benutzer den SecureEdge Access Agent deaktiviert. Für Endgeräte mit iOS, iPadOS oder Android ist eine MDM-Lösung erforderlich.",
            deviceLimit: "Endgeräte pro Benutzer",
            tpmEnforcement: "Trusted-Platform-Modul. Nicht exportierbare und sichere Speicherung des Zertifikats für die Authentifizierung des Endgeräts unter Verwendung des TPM-Chips auf Ihrem Endgerät.",
            devicePreConnect: "Ermöglicht die Verwaltung von Benutzergeräten unter Windows, ohne dass der Benutzer angemeldet ist."
          },
          tpmEnforcement: "TPM-Durchsetzung"
        }
      },
      devices: {
        table: {
          userName: "Benutzer",
          email: "E-Mail",
          brand: "Gerätemarke",
          model: "Gerätemodell",
          hardwareModel: "Hardwaremodell",
          os: "Betriebssystem",
          osFlavour: "OS Flavor",
          osVersion: "OS Version",
          enrollmentDate: "Anmeldedatum"
        },
        delete: {
          body: "Sind Sie sicher, dass Sie dieses Gerät löschen möchten?",
          title: "Gerät löschen"
        },
        noData: {
          iconHeader: "Keine angemeldeten Geräte gefunden!",
          action: "Benutzer hinzufügen und einladen",
          textHeader: "Was ist ein angemeldetes Gerät?",
          text: "Sobald Sie einen Benutzer eingeladen haben, den Selbstanmeldungsprozess zu starten, und der Benutzer die Einführungsschritte abgeschlossen hat, werden hier alle Endgeräte dieses Benutzers aufgelistet, auf denen SecureEdge Access Agents installiert sind."
        }
      },
      firewalls: {
        table: {
          name: "Name",
          serials: "Seriennummer",
          isCcManaged: "CC gemanaged",
          model: "Modell"
        },
        delete: {
          title: "CloudGen Firewall trennen {{name}}",
          body: "Sind Sie sicher, dass Sie diese CloudGen Firewall von Ihrer SecureEdge-Umgebung trennen möchten?",
          forbidden: "Sie können CloudGen Firewall-Integrationen nicht entfernen, wenn sie als Endpunkt konfiguriert sind."
        },
        dialog: {
          create: {
            buttonText: "Registrierungs-Token",
            dialogTitle: "Registrierungs-Token erstellen"
          },
          result: {
            message: "Kopieren Sie das folgende Token in Ihr <0>Firewall Admin-Aktivierungsformular</0>.",
            firewallText: "Firewall Registrierungs-Token",
            firewallOtp: "Dieses Token ist nur für eine einzige Firewall gültig und läuft in 30 Minuten ab."
          }
        },
        noData: {
          iconHeader: "Richten Sie eine CloudGen Firewall ein!",
          labelButton: "Holen Sie sich ein Registrierungs-Token",
          textHeader: "Sie wollen Ihrer Installation eine CloudGen Firewall hinzufügen?",
          text: "Wenn Sie bereits eine Barracuda CloudGen Firewall einsetzen, können Sie diese auch als Point-of-Entry für Ihre Access Agents nutzen. Das Verbinden einer CloudGen Firewall ist so einfach wie das Kopieren des Registrierungs-Tokens in der Management-Konsole für CloudGen Firewall.<0 /><0 />Beachten Sie, dass nach der Anforderung der CloudGen Firewall die Remote Access-Komponente nicht mehr durch das Firewall Admin-Tool gesteuert wird."
        }
      },
      integration: {
        pageTitle: "Integration",
        firewalls: "CloudGen Firewall",
        ipSecV2: {
          create: "IPsec Tunnel erstellen",
          createButton: "IPsec Tunnel hinzufügen",
          edit: "IPsec Tunnel bearbeiten",
          general: "Allgemein",
          network: "Netzwerk",
          phases: "Phasen",
          sourceDest: "Quelle/Ziel",
          success: "Erfolg",
          successMessage: "Neuer IPsec Tunnel erfolgreich erzeugt",
          pageTitle: "IPsec VPN",
          table: {
            status: "Status",
            name: "Name",
            description: "Beschreibung",
            enabled: "Aktiviert",
            gateway: "Remote Gateway",
            localNetworks: "Lokale Netzwerke",
            localBgp: "Lokale BGP",
            remoteNetworks: "Remote-Netzwerke",
            remoteBgp: "Remote BGP",
            type: "Typ",
            siteWanInterface: "SecureEdge Peer",
            peerIp: "Peer IP",
            duration: "Dauer",
            encryption: "Verschlüsselung",
            filters: {
              enabled: {
                enabled: "Aktiviert",
                disabled: "Deaktiviert"
              },
              secureEdgePeer: {
                peerType: "Peer-Typ",
                edgeService: "Edge Service",
                site: "Site"
              }
            },
            ellipsisMenu: {
              restart: "IPsec Tunnel neu starten"
            }
          },
          cloudCantInitiate: "Nur Sites und Private Edge Services können so eingestellt werden, dass sie Tunnel initiieren",
          dialog: {
            create: {
              buttonText: "Tunnel hinzufügen"
            },
            delete: {
              body: "Sind Sie sicher, dass Sie diesen IPsec Tunnel löschen möchten?",
              title: "IPsec Tunnel löschen {{name}}"
            },
            restart: {
              ok: "Ok",
              cancel: "Stornieren",
              body: "Sind Sie sicher, dass Sie diesen IPsec Tunnel neu starten möchten?",
              title: "IPsec Tunnel neu starten {{name}}",
              message: "Bitte warten, der Tunnel wird neu gestartet"
            },
            generalSection: {
              descriptionMessage: "Um einen neuen Tunnel zu erstellen, nehmen Sie zur Konfiguration des Tunnels die folgenden Einstellungen vor.",
              tunnelStatus: "Aktivieren",
              general: {
                title: "Allgemeine Informationen",
                name: "Name",
                description: "Beschreibung",
                initiatesTunnel: "Tunnel initiieren",
                restartSa: "SA neu starten",
                descriptions: {
                  title: "Beschreibung allgemeine Informationen",
                  name: "Eindeutiger Name für diesen IPsec Tunnel.",
                  description: "Optionale Beschreibung des Tunnels.",
                  tunnelStatus: "Ausschalten, um den Tunnel vorübergehend zu deaktivieren.",
                  initiatesTunnel: "Aktiviert, damit die SecureEdge-Appliance den IPsec-Tunnel initiiert."
                }
              },
              authentication: {
                title: "Authentifizierung",
                authentication: "Authentifizierung",
                sharedSecret: "Shared Secret",
                serverCert: "Server-Zertifikat",
                caCert: "CA-Zertifikat",
                x509Condition: "X509-Bedingung",
                exploit509: "Exploit 509",
                descriptions: {
                  authentication: "Methode, die der IPsec-Tunnel zur Authentifizierung verwenden sollte.",
                  sharedSecret: "Pre-Shared Key, der für die Authentifizierung verwendet werden sollte.",
                  serverCert: "Beschreibung Server-Zertifikat",
                  caCert: "Beschreibung CA-Zertifikat",
                  x509Condition: "Beschreibung X509-Bedingung",
                  exploit509: "Beschreibung Exploit 509"
                }
              }
            },
            sourceDestSection: {
              descriptionMessage: "Konfigurieren Sie die Quelle und das Ziel des Tunnels, den Sie hinzufügen möchten.",
              enableBgp: "BGP aktivieren",
              descriptions: {
                enableBgp: "BGP aktivieren"
              },
              source: {
                title: "Quelle",
                type: "Typ",
                peer: "Peer",
                ipAddresses: "Peer Gateways",
                initiatesTunnel: "Initiieren",
                wanInterface: "WAN Interface",
                localId: "Local ID",
                networkAddresses: "Netzwerk-Adressen",
                networkAddressBgp: "Peering Address",
                asnBgp: "ASN",
                descriptions: {
                  ipAddresses: "Öffentliche IP-Adresse des gewählten Edge Service. Für die Konfiguration Ihres Remote-Geräts erforderlich.",
                  localId: "IPsec ID der lokalen Seite des Tunnels.",
                  networkAddresses: "Die auf der lokalen Seite des Tunnels verfügbaren Netzwerke.",
                  networkAddressBgp: "Die auf der lokalen Seite zur BGP-Kommunikation verwendete IP-Adresse.",
                  asnBgp: "Die Autonome Systemnummer der lokalen Seite.",
                  enableBgp: "Aktivieren Sie diese Option, damit die an den lokalen und Remote-Standorten erreichbaren Netze dynamisch über BGP ausgetauscht werden.",
                  dnsVip: "Die DNS Routed IP der lokalen Seite des Tunnels."
                },
                sourceTypeData: {
                  gateway: "Edge Service",
                  site: "Site"
                },
                localWans: "Adresse",
                primaryAddress: "Primäre Adresse",
                additionalAddresses: "Zusätzliche Adressen",
                dnsVip: "DNS Routed IP"
              },
              destination: {
                title: "Ziel",
                remoteGatewayName: "Name",
                remoteGateway: "Remote Gateway",
                remoteId: "Remote ID",
                networkAddresses: "Netzwerk-Adressen",
                networkAddressBgp: "Peering Address",
                asnBgp: "ASN",
                multiInputTitle: "Remote Gateway",
                multiInputTitleLabel: "Ziele",
                descriptions: {
                  remoteId: "IPsec ID der Remote-Seite des Tunnels.",
                  networkAddresses: "Die auf der Remote-Seite des Tunnels verfügbaren Netzwerke.",
                  networkAddressBgp: "Die auf der Remote-Seite zur BGP-Kommunikation verwendete IP-Adresse.",
                  asnBgp: "Die Autonome Systemnummer der Remote-Seite.",
                  remoteGateway: "FQDN- oder IP der Remote-Seite des Tunnels. Wenn die lokale Seite den Tunnel nicht initiiert, kann dies auf 0.0.0.0/0 gesetzt werden."
                }
              }
            },
            phasesSection: {
              descriptionMessage: "Der Tunnel bietet 2 Phasen. Richten Sie Ihre bevorzugten Sicherheitseinstellungen für jede dieser Phasen ein.",
              phase1: {
                title: "Phase 1",
                encryption: "Verschlüsselung",
                hash: "Hash",
                dhGroup: "DH-Gruppe",
                proposalHandling: "Proposal Handling",
                lifetime: "Lebensdauer",
                descriptions: {
                  proposalHandling: "Bei der Einstellung \"strict\" kann nur die ausgewählte Verschlüsselung, der Hash-Algorithmus und die Diffie-Hellman-Gruppe verwendet werden, bei der Einstellung \"negotiate\" kann der Initiator einen anderen Satz von Algorithmen und eine andere DH-Gruppe auswählen.",
                  lifetime: "Die Anzahl der Sekunden, bis die IPsec (SA) neu eingegeben wird."
                }
              },
              phase2: {
                title: "Phase 2",
                lifetime: "Lebensdauer",
                trafficVolume: "Aktiviertes Traffic-Volumen",
                trafficVolumeKb: "Traffic-Volumen KB",
                encryption: "Verschlüsselung",
                hash: "Hash",
                dhGroup: "DH-Gruppe",
                proposalHandling: "Proposal Handling",
                descriptions: {
                  proposalHandling: "Bei der Einstellung \"strict\" kann nur die ausgewählte Verschlüsselung, der Hash-Algorithmus und die Diffie-Hellman-Gruppe verwendet werden, bei der Einstellung \"negotiate\" kann der Initiator einen anderen Satz von Algorithmen und eine andere DH-Gruppe auswählen.",
                  lifetime: "Die Anzahl der Sekunden, bis die IPsec (SA) neu eingegeben wird.",
                  trafficVolume: "Wenn diese Funktion aktiviert ist, kann eine erneute Eingabe auf der Grundlage des Traffic-Volumens ausgelöst werden.",
                  trafficVolumeKb: "Die Anzahl der Sekunden, bis die SA neu eingegeben wird."
                }
              }
            },
            networkSection: {
              descriptionMessage: "Netzwerkeinstellungen konfigurieren. Dies sind erweiterte Optionen, die für einen allgemeinen Tunnel nicht zwingend erforderlich sind.",
              networkSettings: {
                title: "Netzwerkeinstellungen",
                oneTunnelSubnet: "EIn VPN Tunnel pro Subnetz-Paar",
                universalTrafficSelectors: "Universal Traffic Selectors",
                forceUdpEncapsulation: "UDP-Kapselung erzwingen",
                ikeReauthentication: "IKE-Reauthentifizierung",
                descriptions: {
                  oneTunnelSubnet: "Erstellen Sie eine Security Association, SA, pro Paar von lokalen und Remote-Netzwerken.",
                  universalTrafficSelectors: "Ist diese Funktion aktiviert, kann der gesamte Traffic durch den Tunnel gehen.",
                  forceUdpEncapsulation: "Verwenden Sie UDP Port 4500 für den \"IPsec Tunnel Envelope\", auch wenn kein Natting erkannt wurde.",
                  ikeReauthentication: "Nehmen Sie jedes Mal, wenn eine SA neu eingegeben wird, eine Authentifizierung vor."
                }
              },
              deadPeerDetection: {
                title: "Dead Peer Detection",
                actionDetected: "Aktion bei Erkennung",
                delay: "Verzögerung",
                descriptions: {
                  actionDetected: "Aktion, wenn die Remote-Seite nicht auf die DPD-Meldungen antwortet. \"Löschen\" beendet den Tunnel, \"Neustart\" versucht, den Tunnel wiederherzustellen und \"Keine\" deaktiviert DPD.",
                  delay: "Zeit in Sekunden, nach der eine DPD Keep Alive-Meldung gesendet wird, wenn kein anderer Traffic empfangen wird."
                }
              }
            }
          },
          noData: {
            buttonText: "IPsec-Verbindung hinzufügen",
            iconHeader: "Beginnen Sie mit dem Hinzufügen einer IPsec-Verbindung!",
            labelButton: "IPsec-Verbindung hinzufügen",
            textHeader: "Warum sollte eine IPsec-Verbindung integriert werden?",
            text: "SecureEdge-Installation verwenden die proprietäre Barracuda TINA VPN für sichere und zuverlässige Konnektivität. Verwenden Sie diese Konfigurationsseite, um Drittanbieter-Implementierungen zu verbinden, die IPsec IKEv2 verwenden können. Sobald die Konfiguration abgeschlossen ist, können Sie auf dieser Seite den Status IhrerIPsec-VPN-Verbindung überprüfen."
          }
        },
        azureMonitor: {
          title: "Azure Monitor",
          azureMonitorChoices: {
            disabled: "Deaktiviert",
            azureLogAnalyticsDaemon: "Azure Log Analytics Daemon",
            azureLogAnalyticsOms: "Azure Log Analytics (OMS)"
          }
        }
      },
      syslog: {
        pageTitle: "Syslog Streaming",
        certificateCName: "Allgemeiner Zertifikatsname",
        certificateExpiryDate: "Zertifikat-Ablaufdatum",
        enable: "Syslog Streaming",
        logs: "Logs",
        port: "Port",
        protocol: "Protokoll",
        securityProtocol: "Sicherheitsprotokoll",
        server: "Syslog Server Name oder IP",
        syslogOverride: "Syslog Streaming deaktivieren",
        uploadSslCert: "SSL-Zertifikat hochladen",
        descriptions: {
          syslogOverride_site: "Syslog Streaming für diese Site deaktivieren.",
          syslogOverride_gateway: "Syslog Streaming für diesen Edge Service deaktivieren."
        },
        syslogStreamingTile: "Syslog Streaming",
        syslogDescription: "Syslog Streaming kann für einen gesamten Arbeitsbereich aktiviert werden, aber Sie können es auch für einen bestimmten Standort, Private Edge, Edge Service für VWAN oder einen gehosteten Edge Service deaktivieren."
      },
      xdr: {
        pageTitle: "Barracuda XDR",
        enable: "Log Streaming",
        sites: "Sites",
        gateways: "Edge Services",
        hostname: "XDR Hostname",
        port: "Port",
        xdrDescription: "Konfigurieren Sie Barracuda SecureEdge unten, um Protokolle an Barracuda XDR-Dienste zu streamen, um Bedrohungen zu erkennen und auf böswillige Ereignisse zu reagieren. Eine gültige Barracuda XDR-Subscription und eine gültige Konfiguration sind erforderlich. Weitere Informationen finden Sie <0> hier </0>.",
        streamingEnabled: "Aktiviert"
      },
      webFilterSettings: {
        safeSearch: "SafeSearch",
        silentAdBlocking: "Blockieren von Werbung",
        title: "Einstellungen",
        helpText: "Die SecureEdge-Webfilterung unterstützt SafeSearch auf Netzwerkebene und blockiert die URL-Kategorie \"Werbung bzw. Banner\"<0/>Dadurch wird der Zugriff auf unangemessene Inhalte über beliebte Suchmaschinen und YouTube verhindert."
      },
      webMonitorPolicies: {
        webConfigHelpText: "Aktivieren oder deaktivieren Sie die Überwachung der Websuchen Ihrer Nutzer nach bestimmten Schlüsselwörtern. Wenn diese Funktion aktiviert ist, können Sie die Schlüsselwortübereinstimmungen (die, als Teil der Service Logs-Sammlung, im Web Monitor Alert Log gesammelt werden) an den gewünschten Syslog Server zur weiteren Verarbeitung weiterleiten.<0/> Wählen Sie aus einer Reihe von vordefinierten Stichwörtern aus und/oder fügen Sie benutzerdefinierte Stichwörter hinzu, die genau Ihren Anforderungen entsprechen.",
        enabled: "Web Monitoring aktivieren",
        webMonitoringTitle: "Web Monitoring",
        customKeywordTitle: "Benutzerdefiniertes Keyword Set",
        customKeywordHelpText: "Geben Sie benutzerdefinierte Schlüsselwörter in das Textfeld ein und klicken Sie auf die Schaltfläche \"+\", um sie der Überwachungsliste hinzuzufügen.",
        customKeywords: "Benutzerdefinierte Schlüsselwörter",
        monitored: "Überwacht",
        keywordCategoriesTitle: "Schlüsselwort-Kategorien",
        keywordCategoriesHelpText: "Verwenden Sie diese vordefinierten Schlüsselwortkategorien. Für weitere Informationen über diese Kategorien <0>klicken Sie hier</0>.",
        toggleEnabled: "Aktiviert"
      },
      customResponse: {
        setup: "Benutzerdefinierte Antwortseite einrichten",
        setupHelpText: "Hier können Sie Ihre benutzerdefinierte Antwortseite einrichten. Nach dem Speichern Ihres Textes wird diese Antwortseite allen aktiven Benutzern angezeigt.",
        barracudaBranding: "Barracuda Branding",
        customLogo: "Benutzerdefiniertes Logo",
        customText: "Benutzerdefinierter Text",
        showBarracudaBranding: "Branding anzeigen",
        descriptions: {
          customText: "Wird unter dem benutzerdefinierten Logo angezeigt.<0/> Lassen Sie diesen Platz frei, wenn Sie keinen benutzerdefinierten Text verwenden.",
          customLogo: "Wird oben angezeigt. Unterstützte Dateiformate: JPEG, PNG, GIF und SVG."
        }
      },
      copy: {
        label: "In Clipboard kopieren"
      },
      identity: {
        menuTitle: "IDENTITÄT",
        pageTitle: "Identität",
        pageSubtitle: "Einstellungen",
        identityProviders: {
          title: "Identity Providers",
          addNotification: "Identity Provider erfolgreich hinzugefügt",
          noDataMessage: "Anscheinend haben Sie noch keinen Identity Provider konfiguriert.",
          clickBelow: "Klicken Sie auf die Schaltfläche unten, um einen Identity Provider hinzuzufügen.",
          createIdentityProvider: "Identity Provider hinzufügen",
          saml: {
            informationNeeded: "Von Ihrem Identity Provider benötigte Information",
            entityId: "Entity ID",
            acsURL: "ACS URL"
          },
          add: {
            title: "Identity Provider hinzufügen",
            createStep: "SAML Sign In-Einstellungen",
            setupStep: "SAML einrichten",
            button: "Hinzufügen",
            buttonDisabled: "Sie können immer nur einen Identity Provider haben.",
            types: {
              email: "E-Mail",
              entraid: "Microsoft Entra ID",
              bcc: "Barracuda Cloud Control",
              google: "Google Workspace",
              oidc: "OpenID Connect",
              saml: "SAML 2.0",
              okta: "Okta Workforce"
            },
            options: {
              provider: "Provider",
              displayName: "Namen anzeigen",
              tenantId: "Tenant ID",
              clientId: "Client ID",
              clientSecret: "Client Secret",
              discoveryURL: "Discovery URL",
              scope: "Scope",
              googleWorkspaceDomain: "Google Workspace Domain",
              signInEndpoint: "Sign In-Endgerät",
              signingCert: "Signing Cert",
              oktaDomainName: "Okta Domain Name",
              oktaClientId: "Okta Client ID",
              oktaClientSecret: "Okta Client Secret",
              domains: "Domains"
            },
            redirectUri: "URI weiterleiten"
          },
          table: {
            name: "Name",
            type: "Typ",
            status: "Status",
            noData: "Keine Identity Providers gefunden."
          },
          authorizationStatusField: {
            needs_consent: "Autorisierung erforderlich",
            active: "Aktiv",
            error: "Fehler"
          },
          menu: {
            test: "Identity Provider testen",
            setupSaml: "SAML einrichten",
            grantPermissions: "Berechtigungen erteilen"
          },
          delete: {
            title: "Identity Provider entfernen",
            body: "Sind Sie sicher, dass Sie diesen Identity Provider entfernen möchten?"
          },
          editIdentityProvider: "Identity Provider bearbeiten",
          cantEditIdentityProvider: "Identity Provider des Typs {{type}} kann nicht bearbeitet werden."
        },
        userDirectories: {
          title: "Identität",
          subtitle: "Benutzer-Verzeichnisse",
          tableName: "Benutzer-Verzeichnisse",
          noDataMessage: "Anscheinend haben Sie noch kein Benutzer-Verzeichnis konfiguriert.",
          clickBelow: "Klicken Sie auf die Schaltfläche unten, um eines hinzuzufügen.",
          createDirectory: "Benutzer-Verzeichnis hinzufügen",
          createDirectoryDialogTitle: "Benutzer-Verzeichnis hinzufügen",
          editDirectory: "Benutzer-Verzeichnis bearbeiten",
          displayName: "Name",
          type: "Typ",
          status: "Status",
          statusField: {
            running: "Läuft",
            cancelled: "Abgebrochen",
            errored: "Fehler",
            completed: "Abgeschlossen",
            pending: "Ausstehend",
            unknown: "Unbekannt",
            needs_consent: "Autorisierung erforderlich"
          },
          sync: "Sync",
          syncResult: "Sync-Ergebnis",
          syncedNotification: "Sync Benutzer-Verzeichnis gestartet",
          syncErrors: "Sync-Fehler",
          users_one: "{{count}} Benutzer",
          users_other: "{{count}} Benutzer",
          groups_one: "{{count}} Gruppe",
          groups_other: "{{count}} Gruppen",
          syncStatus: "{{users}}, {{groups}}, Synchronisiert",
          lastSuccessfulSync: "Letzte Sync",
          lastSyncAt: "Letzte Sync am {{date}}",
          neverSynced: "Noch keine Sync",
          delete: {
            body: "Sind Sie sicher, dass Sie dieses Benutzer-Verzeichnis löschen möchten?",
            body2: "Eine Änderung oder Löschung des Benutzer-Verzeichnisses für Ihr Konto kann dazu führen, dass bestehende Sicherheits- und Zugriffsregeln nicht mehr wie erwartet funktionieren. Bitte überprüfen Sie diese und korrigieren Sie sie gegebenenfalls. Bereits registrierte Benutzer haben weiterhin Zugang. Bitte verwenden Sie die Benutzer-Seite, um festzulegen, wer gelöscht werden soll.",
            title: "Benutzer-Verzeichnis löschen {{name}}"
          },
          form: {
            title: "Verzeichnis",
            displayName: "Namen anzeigen",
            tenantId: "Tenant ID",
            type: {
              label: "Verzeichnis-Typ",
              bcc: "Barracuda Cloud Control",
              entraid: "Microsoft Entra ID",
              okta: "Okta",
              ldap: "LDAP",
              google: "Google Workspace",
              scim: "SCIM"
            },
            ldap: {
              steps: {
                userDirectory: "Info Benutzer-Verzeichnis",
                auth: "Authentifizierung",
                tls: "TLS-Einstellungen"
              },
              host: {
                label: "Host",
                placeholder: "z.B.: ldap.barracuda.com"
              },
              port: {
                label: "Port",
                placeholder: "z.B.: 389"
              },
              profile: {
                label: "Profil",
                placeholder: "Profil auswählen",
                ad: "Ad",
                custom: "Benutzerdefiniert"
              },
              userSearchBase: {
                label: "Benutzer-Suchbasis",
                placeholder: "z.B.: dc=mydomain.com,dc=com"
              },
              groupSearchBase: {
                label: "Gruppen-Suchbasis",
                placeholder: "z.B.: dc=mydomain.com,dc=com"
              },
              authenticationMethod: {
                label: "Authentifizierungs-Methode",
                placeholder: "Authentifizierungs-Methode auswählen",
                anon: "Anon",
                simple: "Einfach",
                saslExternal: "SASL Extern",
                saslKerberos: "SASL Kerberos",
                ntlm: "NTLM"
              },
              username: "Benutzername",
              password: "Passwort",
              saslCredentials: "SASL-Anmeldedaten",
              useTls: {
                tlsSettings: "TLS-Einstellungen",
                none: "Keine",
                tls: "TLS",
                startTls: "TLS starten",
                encryptionMethod: "Verschlüsselungsmethode",
                ldapSni: {
                  label: "SNI Hostname",
                  placeholder: "z.B.: example.org"
                },
                ldapPrivkey: {
                  label: "Privater Schlüssel",
                  placeholder: "Fügen Sie hier Ihren privaten Schlüssel ein"
                },
                ldapPrivkeyPassword: "Passwort",
                ldapPubkey: {
                  label: "Öffentlicher Schlüssel",
                  placeholder: "Fügen Sie hier Ihren öffentlichen Schlüssel ein"
                },
                ldapCacerts: {
                  label: "CA-Zertifikate",
                  placeholder: "Fügen Sie hier Ihre CA-Zertifikate ein"
                },
                ldapCheckCerts: {
                  label: "Zertifikate prüfen",
                  description: "Prüfen, ob empfangene Serverzertifikate vertrauenswürdig sind"
                },
                ldapCheckHostname: {
                  label: "Hostnamen prüfen",
                  description: "Hostname des Zertifikats überprüfen"
                },
                ldapCertAdditionalNames: {
                  label: "Weitere Zertifikatnamen",
                  placeholder: "z.B.: example.org,company.com,dc1.example.org"
                }
              },
              connectorIps: {
                warning: "Um unseren LDAP-Connector nutzen zu können, müssen Sie den Datenverkehr von den folgenden IP-Adressen zulassen:"
              }
            },
            okta: {
              domainname: "Okta Domain Name",
              authToken: "Okta Auth Token"
            },
            google: {
              googleWorkspaceDomain: "Google Workspace Domain"
            },
            bcc: {
              environment: "Umgebung"
            },
            groupsIncluded: "Inkludierte Gruppen",
            scim: {
              steps: {
                userDirectory: "Info User Directory",
                info: "Info SCIM",
                submitAdd: "Hinzufügen",
                submitEdit: "Speichern"
              },
              information: {
                subtext: "Von Ihrem Directory Provider benötigte Information",
                scimEndpoint: "SCIM-Endpunkt",
                scimToken: "SCIM-Token",
                name: "Name",
                expiresAt: "Ende",
                token: "Token",
                primaryToken: "Primär",
                secondaryToken: "Sekundär"
              }
            }
          },
          syncDisabled: "Sync deaktiviert",
          connectivity: {
            title: "Verbindung {{name}} wird getestet",
            connecting: "Verbindung wird aufgebaut…",
            test: "Konnektivität testen",
            success: "Konnektivitätstest User Directory: Erfolgreich",
            failure: "Konnektivitätstest User Directory: Fehlgeschlagen"
          },
          testingConnectivity: "Konnektivität wird getestet",
          setupSCIM: "SCIM einrichten",
          testConnectivity: "Konnektivität testen",
          grantPermissions: "Berechtigungen erteilen"
        },
        users: {
          title: "Benutzer",
          subtitle: "Benutzer",
          tableName: "Benutzer",
          name: "Name",
          email: "E-Mail",
          noDataMessage: "Keine Benutzer gefunden.",
          nameOrEmail: "Benutzer",
          create: "Lokalen Benutzer erstellen",
          edit: "Lokalen Benutzer bearbeiten",
          cantEditExternalUsers: "Externe Benutzer können nicht bearbeitet werden",
          cantDeleteExternalUsers: "Externe Benutzer können nicht gelöscht werden",
          groups: "Gruppen",
          directory: "Directory",
          type: "Typ",
          delete: {
            title: "Benutzer löschen {{name}}",
            body: "Sind Sie sicher, dass Sie diesen Benutzer löschen möchten?"
          },
          enabled: "Aktiviert"
        },
        groups: {
          title: "Gruppen",
          subtitle: "Gruppen",
          tableName: "Gruppen",
          name: "Name",
          noDataMessage: "Keine Gruppen gefunden.",
          members: "Mitglieder",
          directory: "Directory",
          description: "Beschreibung",
          type: "Typ",
          create: "Lokale Gruppe erstellen",
          edit: "Lokale Gruppe bearbeiten",
          cantEditExternalGroups: "Externe Gruppen können nicht bearbeitet werden",
          cantDeleteExternalGroups: "Externe Gruppen können nicht gelöscht werden",
          delete: {
            title: "Gruppe löschen {{name}}",
            body: "Sind Sie sicher, dass Sie diese Gruppe löschen möchten?"
          }
        }
      },
      setupWizard: {
        pageTitle: "Zero Trust Network Access einrichten",
        wizardIntroductionPage: {
          textHeader: "Zero Trust Network Access (ZTNA)",
          textSubHeader: "Zugriff von jedem Endgerät auf jede App sichern",
          mainText: "<0>Niemals blind vertrauen, immer auf Nummer sicher gehen.</0><1>Die kontinuierliche Bewertung von Risiko- und Vertrauensstufen auf Grundlage von Identität und Kontext, die sich an das Risiko anpasst, optimiert die Sicherheitslage Ihres Unternehmens.</1><0>Sicherer Zugriff auf Cloud-Anwendungen und -Workloads.</0><1>Durchsetzungspunkte für Sicherheitsrichtlinien zwischen Benutzern und Ihren Cloud-Diensten stellen die Einhaltung der Sicherheitsrichtlinien des Unternehmens beim Zugriff auf Cloud-basierte Ressourcen sicher.</1><0>Die Zero-Trust-Architektur ist mehr als ein Produkt.</0><1>Zero Trust steht für drei Kernprinzipien:<br /><br />1. Standardmäßig wird keiner Instanz vertraut,<br />2. Der Zugriff mit den geringsten Privilegien wird erzwungen,<br />3. Eine umfassende Sicherheitsüberwachung wird implementiert.</1><1>Mit der richtigen Plattformlösung ist die Einrichtung des Zero-Trust-Modells viel einfacher.</1>",
          graphic: "Einleitung ZTNA-Setup"
        },
        wizardStep1: {
          textHeader: "Zugangspunkt erstellen",
          textSubHeader: "Der Zugangspunkt ist der Punkt, an dem Endgeräte auf Ihr Netzwerk zugreifen",
          mainText: "Im Rahmen der Erstellung des <strong>Point of Entry</strong> müssen Sie eine Infrastruktur einrichten. Beginnen wir mit dem <strong>Edge Service</strong>, also dem Hub, in dem Konnektivität und Richtlinien in Ihrem Netzwerk durchgesetzt werden. <br/><br/><strong>Wo möchten Sie den Edge Service erstellen?</strong><br />Wählen Sie die Region aus, die der Mehrheit Ihrer Benutzer am nächsten ist."
        },
        wizardStep2: {
          textHeader: "Benutzerverzeichnis und Authentifizierung einrichten",
          textSubHeader: "Von wo wollen Sie die Benutzerdaten importieren?",
          userDirectories: {
            title: "Benutzerverzeichnis",
            type: "Benutzer importieren aus",
            tenantId: "TenantID",
            displayName: "Anzeigename",
            googleWorkspaceDomain: "Google Workspace Domain",
            directoryInfo: "Info Directory",
            host: "Host",
            userSearchBase: "Suchbasis Benutzer",
            groupSearchBase: "Suchbasis Gruppen",
            authentication: "Authentifizierung",
            authenticationMethod: "Authentifizierungsmethode",
            username: "Benutzername",
            password: "Passwort",
            tlsSettings: "TLS-Einstellungen",
            encryptionMethod: "Verschlüsselungsmethode",
            localUserDirectory: "Directory lokaler Benutzer",
            externalUserDirectory: "Directory externer Benutzer",
            entraId: "Microsoft Entra ID",
            googleWorkspace: "Google Workspace",
            okta: "Okta",
            barracudaCloudControl: "Barracuda Cloud Control",
            ldap: "LDAP",
            syncButton: "Sync-Directory",
            addUser: "+ Benutzer hinzufügen",
            addingUsers: "Bis zu 5 Benutzer erstellen. Lokale Benutzer können über „Identität > Benutzer“ weiter verwaltet werden oder Sie können sich über „Identität > Einstellungen“ mit externen Verzeichnissen verbinden.",
            localUserName: "Benutzername",
            localUserEmail: "Benutzer-E-Mail",
            tlsSettingsDropdown: {
              none: "Keine",
              startTls: "TLS starten",
              tls: "TLS"
            },
            authenticationMethodDropdown: {
              simple: "Einfach",
              anon: "Anon",
              ntlm: "NTLM",
              saslExternal: "SASL Extern",
              saslKerberos: "SASL Kerberos"
            },
            environment: "Umgebung",
            groupsIncluded: "Gruppen eingeschlossen",
            oktaDomainName: "Okta Domain Name",
            oktaAuthToken: "Okta Auth Token",
            saslCredential: "SASL-Berechtigung",
            ldapSni: "SNI-Hostname",
            ldapPrivkey: "Privater Schlüssel",
            ldapPrivkeyPassword: "Passwort",
            ldapPubkey: "Öffentlicher Schlüssel",
            ldapCacerts: "CA-Zertifikat",
            ldapCheckCerts: "Zertifikat prüfen",
            ldapCheckHostname: "Hostnamen prüfen",
            ldapCertAdditionalNames: "Weitere Zertifikatnamen",
            syncing: "Benutzer & Gruppen synchronisieren",
            synced: "Synchronisiert"
          },
          identityProviders: {
            title: "Identity Provider hinzufügen",
            identityProvider: "Identity Provider",
            displayName: "Anzeigename",
            tenantId: "Tenant ID",
            googleWorkspaceDomain: "Google Workspace Domain",
            okta: "Okta Workforce",
            oktaDisplayName: "Okta-Anzeigename",
            oktaDomainName: "Okta-Domainname",
            oktaClientId: "Okta Client ID",
            oktaClientSecret: "Okta Client Secret",
            discoveryUrl: "Discovery URL",
            clientId: "Client ID",
            clientSecret: "Client Secret",
            scope: "Scope",
            redirectUri: "URI weiterleiten",
            signInEndpoint: "Anmeldeendpunkt",
            signingCert: "Signaturzertifikat",
            informationNeeded: "Von Ihrem Identity Provider benötigte Information",
            entraId: "Microsoft Entra ID",
            googleWorkspace: "Google Workspace",
            barracudaCloudControl: "Barracuda Cloud Control",
            openIdConnect: "OpenID Connect",
            saml: "SAML 2.0",
            emailOtp: "E-Mail OPT",
            authenticateButton: "Authentifizieren",
            localUserDirectoryDescription: "Klicken Sie „Weiter“, um E-Mail OTP als Identity Provider für Ihre Benutzer zu konfigurieren. Weitere Identity Provider können unter „Identität > Einstellungen“ konfiguriert werden."
          }
        },
        wizardStep3: {
          pageTitle: "Zero Trust Network Access einrichten",
          textHeader: "Definieren Sie eine App, mit der Sie beginnen möchten",
          textSubHeader: "Wofür wollen Sie den Zugriff kontrollieren? Sie können später weitere Apps hinzufügen.",
          applicationTypeSection: "Was ist der App-Typ?",
          helpText: "*Sie können interne Apps definieren und sogar Konnektoren einrichten, wenn eine App nicht über das Routing zugänglich ist. <0>Mehr erfahren</0>",
          radioButtonInput1: "Vordefinierte Saas-App",
          radioButtonInput2: "Benutzerdefinierte App veröffentlichen",
          applicationSection: "Eine App auswählen",
          applicationDropdown: "App",
          appCatalogSection: "App zum App-Katalog hinzufügen",
          helpTextAppCatalogSection: "Mit dem App-Katalog können Sie die Applikationen definieren, die in der App SecureEdge Access Agent für den Schnellzugriff angezeigt werden.",
          displayName: "Anzeigename",
          launchUrl: "URL starten",
          protocolValidation: "muss mit http:// oder https:// beginnen",
          domainValidation: "muss einen gültigen Domainnamen enthalten"
        },
        wizardStep4: {
          pageTitle: "Zero Trust Network Access (ZTNA) einrichten",
          textHeader: "Zero Trust Access Policy konfigurieren",
          textSubHeader: "Wie möchten Sie den Zugriff kontrollieren?",
          applicationPolicy: "Richtlinie für Ihre App erstellen",
          application: "App",
          usersAndGroups: "Auf welche Benutzer soll diese Applikation beschränkt sein?",
          usersAndGroupsText: "Wenn keine Benutzer ausgewählt sind, ist die App für alle Benutzer zugänglich.",
          deviceAttributes: "Welchen Endgerätezustand soll diese Richtlinie erzwingen?",
          maxUsers: "Onboarding mit dem Wizard ist auf 5 Benutzer begrenzt. Um weitere Benutzer oder Gruppen zu registrieren, gehen Sie auf „Zugriff > Registrierte Benutzer“ und klicken Sie auf die Schaltfläche Registrieren"
        },
        wizardStep5: {
          textHeader: "SecureEdge Agent bereitstellen",
          textSubHeader: "Agent auf Endgeräten installieren",
          mainText: "<0>Benutzer registrieren</0><1>Wählen Sie die Bentuzer oder Gruppen aus, die für SecureEdge Access registriert werden sollen. Der Benutzer muss sich beim ersten Start des Agents anmelden. Sie können diesen Vorgang auf der Seite „Zugriff > Benutzer“ verfolgen. </1>",
          extraText: "Folgende E-Mail wird an Ihre ausgewählten Benutzer gesendet:",
          seats: "Sie haben insgesamt {{count}} Plätze",
          graph: "Demo-E-Mail"
        },
        wizardComplete: {
          textHeader: "Sie sind bereit!",
          textSubHeader: "Herzlichen Glückwunsch, Ihre Basisinfrastruktur wurde erfolgreich eingerichtet.",
          mainText: "<0>Nächste Schritte</0><1>Befolgen Sie die Anweisungen aus der E-Mail, um den Agenten auf den Endgeräten der ausgewählten Benutzer zu installieren und zu registrieren.</1><1>Sie haben nun den Prozess der Erstellung einer Basisinfrastruktur abgeschlossen und eine Zero-Trust-Network-Access-Richtlinie für eine Applikation implementiert. Erweitern Sie Ihre Richtlinien mithilfe der SecureEdge-Produkteinstellungen auf alle Ihre Apps.</1><1><0><0>Ändern Sie die Einstellungen für den Endgerätezustand in der ZTNA-Richtlinie, um den Zugriff zu blockieren.</0><0>Fügen Sie Web-Filterrichtlinien hinzu, um den Zugriff zu blockieren.</0><0>Ändern Sie den Zugriff basierend auf Benutzer oder Gruppe.</0><0>Fügen Sie IPsec-Tunnel oder SecureEdge Connectors hinzu, um den Zugriff auf private Ressourcen zu ermöglichen.</0></0></1>"
        },
        buttons: {
          setup: "Setup",
          next: "Weiter",
          done: "Fertig",
          exitSetup: "Setup verlassen"
        }
      }
    }
  }
} as const;