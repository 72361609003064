import {SvgIcon, useTokens} from "@barracuda-internal/bds-core";
import React from "react";
import classNames from "classnames";

export const WidgetInfoIcon = ({className}: { className?: string }) => {
    const tokens = useTokens();
    return (
        <SvgIcon
            className={classNames(className, "cuda-react-icon")}
            id="cuda-icon-widget-info"
            viewBox="0 0 60 60"
        >
            <path style={{fill: tokens.aliasesColorIconInfoNeutral, strokeWidth: 0}}
                  d="M27.6,42.2h4.9v-14.7h-4.9v14.7ZM30,22.7c.7,0,1.3-.2,1.7-.7.5-.5.7-1,.7-1.7s-.2-1.3-.7-1.7c-.5-.5-1-.7-1.7-.7s-1.3.2-1.7.7c-.5.5-.7,1-.7,1.7s.2,1.3.7,1.7c.5.5,1,.7,1.7.7ZM30,54.5c-3.4,0-6.6-.6-9.5-1.9s-5.6-3-7.8-5.2c-2.2-2.2-3.9-4.8-5.2-7.8-1.3-3-1.9-6.2-1.9-9.5s.6-6.6,1.9-9.5c1.3-3,3-5.6,5.2-7.8,2.2-2.2,4.8-3.9,7.8-5.2,3-1.3,6.2-1.9,9.5-1.9s6.6.6,9.5,1.9c3,1.3,5.6,3,7.8,5.2,2.2,2.2,3.9,4.8,5.2,7.8,1.3,3,1.9,6.2,1.9,9.5s-.6,6.6-1.9,9.5-3,5.6-5.2,7.8c-2.2,2.2-4.8,3.9-7.8,5.2-3,1.3-6.2,1.9-9.5,1.9ZM30,49.6c5.5,0,10.1-1.9,13.9-5.7,3.8-3.8,5.7-8.4,5.7-13.9s-1.9-10.1-5.7-13.9c-3.8-3.8-8.4-5.7-13.9-5.7s-10.1,1.9-13.9,5.7-5.7,8.4-5.7,13.9,1.9,10.1,5.7,13.9,8.4,5.7,13.9,5.7Z"/>
        </SvgIcon>
    );
};

export default WidgetInfoIcon;