import React from "react";
import {Theme} from "@mui/material";
import {createStyles} from "@mui/styles";
import {ButtonDialog} from "../../dialog";
import IconPreview, {IconPreviewProps} from "./IconPreview";
import IconBrowser from "./IconBrowser";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";

const styles = (theme: Theme) => createStyles({
    container: {
        display: "inline-flex",
    },
    button: {
        marginLeft: "8px"
    },
});
const useStyles = makeOverrideableStyles("IconSelector", styles);

export interface IconSelectorProps extends Pick<IconPreviewProps, "supportedFiles" | "name">, StyledComponentProps<typeof styles> {
    /**
     * current value of the input.
     * provided automatically when component is rendered inside a [Input](/?path=/docs/core-components-inputs-input) component.
     */
    value?: any,
    /**
     * callback to call when the input value has been changed.
     * provided automatically when component is rendered inside a [Input](/?path=/docs/core-components-inputs-input) component.
     * @function onChange
     */
    onChange?: (fileContents: {
        filename?: string,
        data?: string
    }) => void,
    /**
     * used to generate a unique ID for the input.
     */
    id?: string,
    /** name, used for default icon in the preview if no data is provided */
    name?: string,
    /**
     * if true, input gets disabled
     */
    disabled?: boolean,
}

const IconSelector = (props: IconSelectorProps) => {
    const classes = useStyles(props);

    return (
        <div className={classes.container}>
            <IconPreview
                icon={props.value?.data}
                filename={props.value?.filename}
                supportedFiles={props.supportedFiles || [{fileExtensions: ["svg"], mimeType: "image/svg+xml"}]}
                name={props.name}
            />
            <ButtonDialog
                buttonText="Choose Icon"
                buttonClasses={{button: classes.button}}
                buttonProps={{
                    id: "icon-input-" + props.id
                }}
                disabled={props.disabled}
            >
                <IconBrowser
                    value={props.value}
                    onChange={props.onChange}
                    supportedFiles={props.supportedFiles}
                    id="icon-upload"
                    name={props.name}
                />
            </ButtonDialog>

        </div>
    );
};

export default IconSelector;