import {
    ConnectedForm,
    DialogBody,
    SelectInput,
    TextArrayInput,
    TextInput,
    useGlobalParam,
    validateMaxLengthMemo,
    validateRegexMemo
} from "@cuda-react/core";
import apiResources from "../../../../apiResources";
import {useTranslation} from "react-i18next";
import {Theme, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {Grid} from "@barracuda-internal/bds-core";
import React, {useState} from "react";
import CreateLocationTokenContent from "./CreateLocationTokenContent";

const useStyles = makeStyles((theme: Theme) => ({
    headerText: {
        margin: "16px",
        color: "#646464"
    },
    divider: {
        width: "auto",
        margin: "auto 16px"
    },
    textContainer: {
        textAlign: "left",
        paddingBottom: 12,
        margin: "20px 16px"
    },
    locationContent: {
        display: "flex",
        width: "100%",
        padding: "10px 0 0",
        fontSize: "inherit",
        fontWeight: "600"
    },
}));

export interface CreateEditLocationsProps {
    onClose: () => void,
    onSuccess: () => void,
    create?: boolean,
    id?: number | string,
}

export const CreateEditLocations = ({onClose, onSuccess, create, id}: CreateEditLocationsProps) => {
    const [virtualWanId,] = useGlobalParam("filter.virtualWanId");
    const [translate] = useTranslation();
    const [successfulDynamicData, setSuccessfulDynamicData] = useState<any | undefined>();
    const classes = useStyles();
    const IPTypes= [
      {key: "static", value: "Static"},
      {key: "dynamic", value: "Dynamic"}
    ];
    const validations = {
        name: validateMaxLengthMemo(255),
        description: [validateMaxLengthMemo(255), validateRegexMemo(/^[a-zA-Z0-9- ]+$/, "tesseract.validation.alphaNumericDashSpace")]
    };

    return successfulDynamicData ? (
        <CreateLocationTokenContent
            name={successfulDynamicData?.name}
            uuid={successfulDynamicData?.uuid}
            onClose={() => {
                onSuccess();
                onClose();
            }}
        />
    ) : (
        <DialogBody
            title={create ? "tesseract.network.locations.dialog.create" : "tesseract.network.locations.dialog.edit"}
            onClose={onClose}
            form
        >
            <ConnectedForm
                resource={apiResources.addLocations}
                formatRequestData={(data: any) => ({
                    ...data,
                    virtualWanId
                })}
                params={create ? undefined : {id}}
                onSubmitSuccess={(data) => {
                    if (data?.type === "dynamic") {
                        setSuccessfulDynamicData(data);
                    } else {
                        onSuccess();
                        onClose();
                    }
                }}
                onCancel={onClose}
                create={create}
            >
                <Grid item xs={12} className={classes.textContainer}>
                    <Typography variant="body1">
                        {translate("tesseract.network.locations.dialog.createContent")}
                    </Typography>
                </Grid>
                <TextInput
                    label="tesseract.network.locations.dialog.name"
                    description="tesseract.network.locations.dialog.description.name"
                    source="name"
                    validate={validations.name}
                    isRequired
                />
                <TextInput
                    source="description"
                    label="tesseract.network.locations.dialog.descriptionField"
                    description="tesseract.network.locations.dialog.description.description"
                    validate={validations.description}
                />
                 <SelectInput
                    label="tesseract.network.locations.dialog.type"
                    description="tesseract.network.locations.dialog.description.type"
                    source="type"
                    choices={IPTypes}
                    optionValue="key"
                    optionText="value"
                    isRequired
                />
                <TextArrayInput
                    source="addresses"
                    label="tesseract.network.locations.dialog.ipAddress"
                    description="tesseract.network.locations.dialog.description.ipAddress"
                    isRequired
                    hide={(_value: any, data: any) => (data?.type === "dynamic")}
                />
            </ConnectedForm>
        </DialogBody>
    );
};

export default CreateEditLocations;