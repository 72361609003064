import {makeStyles} from "@mui/styles";
import {
    ActionButtonsField,
    ChipArrayField,
    ConnectedTableRefresh,
    CrudTypes,
    DeleteDialog,
    DisableableButton,
    EmptyPageContent,
    formatDateTime,
    getArrayDataContent,
    getDataContent,
    LinkField,
    SelectedChoiceField,
    StatusIconField,
    TablePage,
    TextField,
    useCrudProps,
    useCrudSubscription,
    useGlobalParam,
    useMixpanel
} from "@cuda-react/core";
import {Forward} from "@barracuda-internal/bds-core/dist/Icons/Core";
import React, {useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {get} from "lodash";
import {useHistory} from "react-router";
import apiResources from "../../../apiResources";
import {getAzureResourceLink} from "../../../utils/azureUtils";
import {AzureIcon, GetStartedBackgroundGraphic, IotGraphic, PrivateEdgeIcon} from "@cuda-react/icons";
import {PRIVATE_CLOUD_FILTER_KEY} from "../gateways/GatewayTable";
import CreateSCContent from "../../../components/appliances/create/CreateSCContent";
import {Dialog} from "@barracuda-internal/bds-core";

/**
 * For now we are using this workaround to get the karma tests working.
 */
export const useStyles = makeStyles((theme) => ({
    statusCell: {
        width: 32
    },
    actionsCell: {
        width: 64
    },
    maxWidthCell: {
        maxWidth: 128
    },
    gatewayIcon: {
        top: "6px",
        position: "relative",
        paddingRight: "8px"
    }
}));

export const IotTable = (props: any) => {
    const registerAction = useMixpanel("IoT");
    const [gatewaysData, loading] = useCrudSubscription(CrudTypes.GET, apiResources.gatewayNames);
    const gatewayChoices = [
        {
            key: -1,
            name: "tesseract.sites.table.noGateway"
        },
        ...getArrayDataContent(gatewaysData)
    ];
    const statusChoices = useCrudProps(apiResources.siteStates)[0]?.data;
    const [pointToSite] = useCrudSubscription(CrudTypes.GET, apiResources.pointToSite, {}, {crudOptions: {quietErrors: true}});
    const pointToSiteEnabled = get(getDataContent(pointToSite), "enabled", false);
    const modelsData = useCrudProps(apiResources.allModelNames, {}, {}, true)[0]?.data;
    const modelChoices = getArrayDataContent(modelsData)
        .filter((model) => model.startsWith("FSC"))
        .map((modelName) => ({key: modelName.replace("_", "."), name: modelName.replace("_", ".")}));
    const [cloudVwanChoices] = useCrudSubscription(CrudTypes.GET, apiResources.cloudVwans);
    const noGateways = getArrayDataContent(gatewaysData).length === 0;
    const [translate] = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const isDemo = useGlobalParam("userData.mode")[0] === "demo";
    const tableRefreshRef = useRef<ConnectedTableRefresh>(null);
    const [actionVisible, setActionVisible] = useState(false);
    const [createOpen, setCreateOpen] = useState(false);

    return (
        <>
            <TablePage
                titleActions={actionVisible &&
                    <DisableableButton
                        buttonText="tesseract.sites.dialog.create.sc.createSC"
                        disabledMessage="tesseract.sites.dialog.create.sc.menuItemDisabled"
                        disabled={noGateways || loading}
                        onClick={() => setCreateOpen(true)}
                    />
                }
                authenticated
                resource={apiResources.sites}
                title="tesseract.network.pageTitle"
                subtitle="tesseract.network.iot.menuTitle"
                {...props}
                noDataMessage="tesseract.sites.table.noIot"
                minCellWidth={160}
                tableName="IoT"
                resizable
                reorderable
                editColumns
                resetOnGlobalParamChange
                refreshRef={tableRefreshRef}
                noDataPage={
                    <EmptyPageContent
                        background={<GetStartedBackgroundGraphic/>}
                        icon={<IotGraphic/>}
                        iconHeader="tesseract.network.iot.noData.iconHeader"
                        textHeader="tesseract.network.iot.noData.textHeader"
                        text="tesseract.network.iot.noData.text"
                        actions={
                            <DisableableButton
                                buttonText="tesseract.sites.dialog.create.sc.createSC"
                                disabledMessage="tesseract.sites.dialog.create.sc.menuItemDisabled"
                                disabled={noGateways || loading}
                                onClick={() => setCreateOpen(true)}
                            />
                        }
                    />
                }
                onPageStatusChange={(pageStatus: string) => setActionVisible(pageStatus === "table")}
                params={{filter: {modelSeries: "S"}}}
            >
                <StatusIconField
                    source="aggregateState"
                    cellClassName={classes.statusCell}
                    width={96}
                    alwaysVisible
                />
                <TextField
                    source="name"
                    label="tesseract.sites.table.name"
                    filter="text"
                    sortable
                />
                <ChipArrayField
                    source="serials"
                    label="tesseract.sites.table.serial"
                    cellClassName={classes.maxWidthCell}
                    filter="text"
                    columnProps={{
                        field: "serial"
                    }}
                />
                <TextField
                    source="model"
                    label="tesseract.sites.table.model"
                    cellClassName={classes.maxWidthCell}
                    filter="selectarray"
                    filterProps={{
                        choices: modelChoices
                    }}
                    sortable
                />
                <TextField
                    source="gatewayName"
                    label="tesseract.sites.table.gateway"
                    cellClassName={classes.maxWidthCell}
                    filter="selectarray"
                    filterProps={{
                        id: "gateway",
                        source: "upstream",
                        choices: gatewayChoices
                    }}
                    columnProps={{
                        field: "gateway"
                    }}
                    sortable
                />
                <SelectedChoiceField
                    source="cloudVirtualWan"
                    label="tesseract.sites.table.cloudVirtualWan"
                    choices={getArrayDataContent(cloudVwanChoices)}
                    optionValue="id"
                    cellClassName={classes.maxWidthCell}
                    render={(value, data, choice) => {
                        const hasCloudVwan = !!data?.cloudVirtualWan;
                        let text = hasCloudVwan && choice?.name || "";
                        text = text + (choice?.description ? ` (${choice?.description})` : "");
                        return hasCloudVwan
                            ? <>
                                <AzureIcon
                                    //@ts-ignore
                                    classes={{icon: classes.gatewayIcon}}
                                />
                                <LinkField source="text" data={{choice, text}}
                                           link={getAzureResourceLink("choice.resourceId", isDemo)}/>
                            </>
                            :
                            <>
                                <PrivateEdgeIcon
                                    // @ts-ignore error in cuda-react ts
                                    classes={{icon: classes.gatewayIcon}}
                                />
                                {translate("tesseract.gateways.table.privateCloud")}
                            </>;
                    }}
                    filter="select"
                    filterProps={{
                        source: "cloudVirtualWanId",
                        optionValue: "id",
                        choices: [
                            {id: PRIVATE_CLOUD_FILTER_KEY, name: translate("tesseract.gateways.table.privateCloud")},
                            ...getArrayDataContent(cloudVwanChoices).map((choice) => ({...choice, id: choice.id + ""}))
                        ]
                    }}
                    columnProps={{
                        field: "cloudVirtualWanName"
                    }}
                />
                <SelectedChoiceField
                    source="connectionStatus.aggregateConnectionState"
                    label="tesseract.sites.table.status"
                    choices={getArrayDataContent(statusChoices)}
                    cellClassName={classes.maxWidthCell}
                    filter="selectarray"
                    filterProps={{
                        id: "connection-state",
                        choices: getArrayDataContent(statusChoices)
                    }}
                    columnProps={{
                        field: "connectionStatus"
                    }}
                    sortable
                />
                <ChipArrayField
                    source="lanNetworks"
                    label="tesseract.sites.table.lan"
                />
                <ChipArrayField
                    source="wans"
                    render={(value) => Object.entries(value || {}).map(([name, network]) => `${name} (${network === "dynamic" || network === "wwan" ? translate("tesseract.sites.table." + network) : network})`)}
                    label="tesseract.sites.table.wan"
                />
                <ChipArrayField
                    source="licenseStatus.licenseStates"
                    render={(val) => formatDateTime(val?.expiryDate)}
                    label="tesseract.sites.table.licenseExpiration"
                    columnProps={{
                        field: "licenseExpiration"
                    }}
                    hideByDefault
                />
                <ActionButtonsField
                    source="id"
                    width={128}
                    alwaysVisible
                >
                    <DeleteDialog
                        resource={apiResources.sites}
                        title="tesseract.sites.delete.title"
                        message="tesseract.sites.delete.body"
                        onSuccess={() => {
                            registerAction("Delete Site");
                            tableRefreshRef.current?.();
                        }}
                    />
                    <Forward id="site-dashboard-icon"
                             onClick={(event: any, data: any) => history.push("/infrastructure/iot/" + data.id)}/>
                </ActionButtonsField>
            </TablePage>
            <Dialog
                open={createOpen}
                maxWidth={false}
                onClose={() => setCreateOpen(false)}
                sx={{
                    '& .MuiPaper-root': {
                        width: '100%',
                        maxWidth: '882px'
                    }
                }}
                disableEnforceFocus
            >
                <CreateSCContent
                    onSuccess={() => tableRefreshRef.current?.()}
                    onClose={() => setCreateOpen(false)}
                />
            </Dialog>
        </>
    );
};

export default IotTable;