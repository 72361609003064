import {TFunction} from "i18next";
import {Theme} from "@mui/material";
import {get} from "lodash";
import {
    ChartRef,
    LocationMapSeriesDefinition,
    SetLocationTooltip
} from "./UseLocationsMap";
import {handleLinkedPointClick} from "./handleLinkedPointClick";
import {CustomMapPoint} from "./getLinkedPoints";

/**
 * Generates a new map point series with a single data entry (the provided point) to act as a "highlighted" point, given that
 * point selection does not work with the cluster module.
 */
export const highlightSourcePoint = (chart: ChartRef, sourceType: LocationMapSeriesDefinition, point: CustomMapPoint, setTooltip: SetLocationTooltip, translate: TFunction, theme: Theme) => {
    const seriesConfig = {
        id: "linked-points-source-point-series",
        visible: true,
        cursor: "pointer",
        type: "mappoint",
        showInLegend: false,
        zIndex: (sourceType.linkedSeries && sourceType.linkedSeries.selectedZIndex) || 9999,
        color: (sourceType.linkedSeries && sourceType.linkedSeries.selectedColor) || sourceType.color,
        marker: {
            symbol: "circle",
            lineWidth: 2,
            radius: 7,
            lineColor: theme.palette.text.primary,
        },
        states: {
            hover: {
                enabled: false
            },
            inactive: {
                enabled: false
            }
        },
        allowPointSelect: false,
        data: [{
            ...chart.current?.fromLatLonToPoint({
                lat: get(point.pointData, "latitude") as any,
                lon: get(point.pointData, "longitude") as any
            }),
            source: point.pointData
        }] as any[],
        point: {
            events: {
                click: handleLinkedPointClick(chart, setTooltip, translate, theme)
            }
        }
    } as any;

    const existingPointSeries = chart.current?.series.find((series) => series.userOptions.id === seriesConfig.id);
    if (existingPointSeries) {
        existingPointSeries.update(seriesConfig, false);
    } else {
        chart.current?.addSeries(seriesConfig, false);
    }
};