import {BaseCard, BaseCardProps} from "../BaseCard";
import {Divider, Grid, Skeleton, Typography} from "@barracuda-internal/bds-core";
import {RiskGaugeIcon, RiskLegend} from "../../icons";
import {Theme} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import {CardSizeOptions, CardSizes, RiskLevels} from "../../../typesAndConstants";
import {BarGrid, BarGridProps} from "../../statistics/BarGrid";
import React from "react";
import {useTranslation} from "react-i18next";
import {WidgetInfoIcon} from "@cuda-react/icons";

export interface RiskCardProps extends Omit<BaseCardProps, "title" | "children"> {
    /** the current total risk level */
    riskLevel: RiskLevels,
    /** the risk level traffic statistics to show in the BarGrid for large cards */
    entries: BarGridProps["entries"],
    /** if true, renders loading bars instead of data */
    loading?: boolean
}

const styles = (theme: Theme) => createStyles<string, RiskCardProps>({
    container: {
        height: "calc(100% - 8px)"
    },
    overview: {
        height: ({size}) => size === CardSizes.large ? "calc(50% - 5px)" : "100%"
    },
    breakdown: {
        height: "calc(50% - 5px)",
        paddingLeft: `16px !important`
    },
    breakdownGrid: {
        paddingLeft: "8px"
    },
    riskTitle: {
        paddingLeft: "8px",
        color: theme.palette.text.primary,
        fontSize: 16,
        fontWeight: "bold"
    },
    levelLabels: {
        height: 210,
        marginTop: -12,
        width: 150,
        color: theme.palette.text.primary,
    },
    overallRiskLabel: {
        position: "relative",
        bottom: 90,
        textAlign: "center",
        marginBottom: -42,
        color: theme.palette.text.primary,
        fontWeight: "bold"
    },
    gaugeSkeleton: {
        width: 268,
        height: 192,
        transform: "none",
        borderRadius: 10
    },
    noDataContainer: {
        height: ({size}) => `calc(${CardSizeOptions[size]?.height}px - 80px)`,
        width: "100%"
    },
    noDataIcon: {
        width: 64,
        height: 64,
    },
    noDataMessage: {
        textAlign: "center",
        color: theme.palette.text.primary
    }
});
const useStyles = makeStyles(styles);

export const RiskCard = (props: RiskCardProps) => {
    const {size, riskLevel, entries, loading} = props;
    const classes = useStyles(props);
    const [translate] = useTranslation();

    return (
        <BaseCard
            {...props}
            title="stratosReporting.applicationRisk.title"
        >
            {!loading && !entries.length ? (
                <Grid container justifyContent="center" alignContent="center" className={classes.noDataContainer}>
                    <Grid xs={12} style={{textAlign: "center"}}>
                        <WidgetInfoIcon className={classes.noDataIcon}/>
                    </Grid>
                    <Grid xs={12}>
                        <Typography variant="h6" className={classes.noDataMessage}>
                            {translate("stratosReporting.barGrid.noData")}
                        </Typography>
                    </Grid>
                </Grid>
            ) : (
                <Grid container spacing={1} className={classes.container}>
                    <Grid
                        item
                        xs={12}
                        className={classes.overview}
                        container
                        spacing={1}
                        justifyContent="space-around"
                        alignItems="center"
                    >
                        {loading ? (
                            <Grid item>
                                <Skeleton variant="rectangular" className={classes.gaugeSkeleton}/>
                            </Grid>
                        ) : (
                            <Grid item>
                                <RiskGaugeIcon riskLevel={riskLevel}/>
                                <Typography variant="h4" className={classes.overallRiskLabel}>
                                    {translate(`stratosReporting.applicationRisk.${riskLevel}`)}
                                </Typography>
                            </Grid>
                        )}
                        {size !== CardSizes.small ? (
                            <Grid item>
                                <Grid container spacing={1} wrap="nowrap" justifyContent="center" alignItems="center">
                                    <Grid item>
                                        <RiskLegend/>
                                    </Grid>
                                    <Grid item>
                                        <Grid
                                            container
                                            spacing={1}
                                            justifyContent="center"
                                            alignItems="center"
                                            className={classes.levelLabels}
                                        >
                                            {Object.values(RiskLevels).map((level) => (
                                                <Grid item xs={12} key={level}>
                                                    <Typography>{translate(`stratosReporting.applicationRisk.${level}`)}</Typography>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ) : null}
                    </Grid>
                    {size === CardSizes.large ? (
                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>
                    ) : null}
                    {size === CardSizes.large ? (
                        <Grid item xs={12} className={classes.breakdown}>
                            <Typography variant="h5" className={classes.riskTitle}>
                                {translate("stratosReporting.applicationRisk.risk")}
                            </Typography>
                            <BarGrid
                                size={CardSizes.medium}
                                entries={entries}
                                loading={loading}
                                classes={{container: classes.breakdownGrid}}
                            />
                        </Grid>
                    ) : null}
                </Grid>
            )}
        </BaseCard>
    );
};