import {SvgIcon, useTokens} from "@barracuda-internal/bds-core";
import React from "react";
import classNames from "classnames";

const OffIcon = ({className}: { className?: string }) => {
    const tokens = useTokens();
    return (
        <SvgIcon
            className={classNames(className, "cuda-react-icon")}
            id="cuda-icon-off"
            viewBox="0 0 60 60"
        >
            <path style={{fill: tokens.aliasesColorTextStaticNeutral, strokeWidth: 0}}
                  d="M30,5.4c-13.6,0-24.6,11-24.6,24.6s11,24.6,24.6,24.6,24.6-11,24.6-24.6S43.6,5.4,30,5.4ZM32.5,42.3h-4.9v-24.6h4.9v24.6Z"/>
        </SvgIcon>
    );
};

export default OffIcon;