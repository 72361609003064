import React, {useEffect, useState} from "react";
import {Theme} from "@mui/material";
import {createStyles} from "@mui/styles";
import {get} from "lodash";
import {useCrudProps} from "../../../hooks";
import Text from "../TextInput/Text";
import File from "../FileInput/File";
import classNames from "classnames";
import {DialogBody} from "../../dialog";
import IconPreview, {IconPreviewProps} from "./IconPreview";
import {Button, Grid, Typography} from "@barracuda-internal/bds-core";
import Toolbar from "../../layout/Toolbar/Toolbar";
import {Trans, useTranslation} from "react-i18next";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import {Boolean, InputLabel} from "../index";

const styles = (theme: Theme) => createStyles(({
    search: {
        width: `${(50 + parseInt("8px")) * 5}px`,
        marginLeft: "8px"
    },
    iconSelector: {
        width: `${(50 + parseInt("8px")) * 5}px`,
        height: "auto",
        flexBasis: `${(44 + parseInt("8px")) * 4}px`,
        flexGrow: 0,
        flexShrink: 0,
        margin: "8px",
        display: "flex",
        flexWrap: "wrap"
    },
    iconContainer: {
        margin: "4px",
        height: 44,
        width: 44
    },
    iconContainerDisabled: {
        margin: "4px",
        height: 44,
        width: 44,
        opacity: 0.5
    },
    iconPreview: {
        border: "solid 2px rgba(0,0,0,0)",
        "&:hover": {
            borderColor: "red",
            borderRadius: 8
        }
    },
    iconSelected: {
        borderColor: "blue",
        borderRadius: 8
    },
    previewIcon: {
        marginLeft: "8px"
    },
    previewIconDisabled: {
        marginLeft: "8px",
        opacity: 0.5
    },
    iconSelectorText: {
        margin: "16px",
        color: "#646464"
    },
    searchContainer: {
        display: "flex",
        alignItems: "flex-start",
        margin: "0 32px 8px 32px",
        justifyContent: "center",
        borderBottom: "1px solid #DDDDDD",
    },
    searchContainerInner: {
        display: "flex",
        alignItems: "flex-start",
        flexDirection: "column",
    },
    searchText: {
        margin: "8px 8px 0 16px",
        color: "#676767",
    },
    customInput: {
        width: "100%"
    },
    customInputHelp: {
        display: "none !important"
    },
    customInputLabel: {
        width: "150px !important",
    },
    fileSelector: {
        display: "inline-flex"
    }
}));
const useStyles = makeOverrideableStyles("IconBrowser", styles);

const getIcons = (iconResponse: any): string[] => {
    const icons = Object.values(get(iconResponse, "categories", {})).flatMap((subicons) => subicons);
    return [...icons, ...get(iconResponse, "uncategorized", [])];
};

export type IconValue = {
    filename?: string,
    data?: string
};

export interface IconBrowserProps extends Pick<IconPreviewProps, "supportedFiles" | "name">, StyledComponentProps<typeof styles> {
    /**
     * callback called when the dialog close, cancel and save buttons are clicked
     */
    onClose?: () => void,
    /**
     * current value of the input.
     * provided automatically when component is rendered inside a [Input](/?path=/docs/core-components-inputs-input) component.
     */
    value?: IconValue,
    /**
     * callback to call when the input value has been changed.
     * provided automatically when component is rendered inside a [Input](/?path=/docs/core-components-inputs-input) component.
     * @function onChange
     */
    onChange?: (fileContents: {
        filename?: string,
        data?: string
    }) => void,
    /**
     * used to generate a unique ID for the underlying file input
     */
    id: string,
    /**
     * current value of the Application name
     */
    name?: string,
}

const IconBrowser = (props: IconBrowserProps) => {
    const [selectedIcon, setSelectedIcon] = useState<IconValue>({filename: "", data: ""});
    const [search, setSearch] = useState("");
    const [isEnabled, setIsEnabled] = useState(false);
    const iconData = useCrudProps("https://api.iconify.design/collection", {filter: {prefix: "logos"}})[0].data;
    const icons = getIcons(iconData).filter((icon) => icon.includes(search)).slice(0, 15);
    const classes = useStyles(props);
    const [translate] = useTranslation();

    useEffect(() => {
        setSelectedIcon(props.value || {});
        setIsEnabled(!!props.value?.filename && !props.value?.filename?.startsWith?.("//ICON::"));
    }, [props.value]);

    return (
        <DialogBody title="Choose Icon" onClose={props.onClose}>
            <Typography
                variant="subtitle1"
                className={classes.iconSelectorText}
            >
                <Trans i18nKey="cuda.inputs.IconBrowser.iconSelectorText">
                    <br/>
                </Trans>
            </Typography>
            <InputLabel
                label="cuda.inputs.IconBrowser.searchText"
                classes={{root: classes.customInput, inputHelp: classes.customInputHelp, inputLabel: classes.customInputLabel}}
            >
                <div className={classes.searchContainerInner}>
                    <Text
                        value={search}
                        onChange={(event) => setSearch(event?.target?.value || "")}
                        id="search"
                        placeholder="cuda.inputs.IconBrowser.placeholderText"
                        classes={{textField: classes.search}}
                        disabled={isEnabled}
                    />
                    <div className={classes.iconSelector}>
                        {icons?.length > 0 ? icons.map((icon) => (
                            <Grid
                                item
                                key={icon}
                                onClick={isEnabled ? undefined : () => {
                                    if (selectedIcon === `//ICON::${icon}.svg`) {
                                        setSelectedIcon({});
                                    } else {
                                        fetch(`https://api.iconify.design/logos/${icon}.svg?width=40&height=40`)
                                            .then((response) => response.text())
                                            .then((svgData) => {
                                                setSelectedIcon({filename: `//ICON::${icon}.svg`, data: btoa(svgData)});
                                            }).catch(() => {
                                        });
                                    }
                                }}
                                className={isEnabled ? classes.iconContainerDisabled : classes.iconContainer}
                            >
                                <img
                                    src={`https://api.iconify.design/logos/${icon}.svg?width=40&height=40`}
                                    alt={icon}
                                    title={icon}
                                    className={classNames(classes.iconPreview, `//ICON::${icon}.svg` === selectedIcon.filename && classes.iconSelected)}
                                />
                            </Grid>
                        )) : (
                            <Grid item>
                                {translate("cuda.inputs.IconBrowser.noIcons")}
                            </Grid>
                        )}

                    </div>
                </div>
            </InputLabel>
            <InputLabel
                label="cuda.inputs.IconBrowser.useCustomText"
                classes={{root: classes.customInput, inputHelp: classes.customInputHelp, inputLabel: classes.customInputLabel}}
            >
                <Boolean id={""} value={isEnabled} onChange={(value) => setIsEnabled(!!value)}/>
            </InputLabel>
            <InputLabel
                label="cuda.inputs.IconBrowser.customApplicationText"
                classes={{root: classes.customInput, inputHelp: classes.customInputHelp, inputLabel: classes.customInputLabel}}
            >
                <div className={classes.fileSelector}>
                    <File
                        value={selectedIcon?.filename?.startsWith?.("//ICON::") ? {} : selectedIcon}
                        onChange={setSelectedIcon}
                        id={props.id}
                        showDelete
                        disabled={!isEnabled}
                    />
                    <IconPreview
                        icon={selectedIcon.data}
                        filename={selectedIcon.filename}
                        supportedFiles={props.supportedFiles}
                        name={props.name}
                        size={40}
                        classes={{
                            initialsIcon: isEnabled ? classes.previewIcon : classes.previewIconDisabled,
                            iconImage: isEnabled ? classes.previewIcon : classes.previewIconDisabled
                        }}
                    />
                </div>
            </InputLabel>
            <Toolbar>
                <Button
                    variant="contained"
                    bdsType="interactiveEmphasis"
                    size="small"
                    onClick={() => {
                        props.onChange?.(selectedIcon);
                        props.onClose?.();
                    }}
                >
                    {translate("cuda.action.confirm")}
                </Button>
                <Button
                    variant="contained"
                    bdsType="interactiveNeutral"
                    size="small"
                    onClick={props.onClose}
                >
                    {translate("cuda.action.cancel")}
                </Button>
            </Toolbar>
        </DialogBody>
    );
};

export default IconBrowser;