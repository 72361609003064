import React from 'react';
import {ListCardContent, StatusIconField} from "@cuda-react/core";
import {makeStyles} from "@mui/styles";
import apiResources from "./../../../../apiResources";
import {
    AuditLogIcon,
    ConfigUpdatesIcon,
    FirewallIcon,
    GatewayIcon,
    SdWanIcon,
    SecurityIcon,
    UrlCategoriesIcon
} from "@cuda-react/icons";
import {BaseCard, DashboardCardProps} from "@stratos/reporting";

const serviceIcons: { [key: string]: React.ElementType } = {
    "SD WAN": SdWanIcon,
    "Routing": GatewayIcon,
    "Firewall": FirewallIcon,
    "Configuration": ConfigUpdatesIcon,
    "IPS": SecurityIcon,
    "Log Analytics": AuditLogIcon,
    "URL Filtering": UrlCategoriesIcon
};

const useStyles = makeStyles((theme) => ({
    serviceIcon: {
        height: "1.5rem",
        width: "1.5rem",
        marginBottom: "-6.4px",
        paddingRight: "8px",
        position: "relative",
    }
}));

export interface ApplianceServicesCardProps extends DashboardCardProps {
    additionalProps?: {
        details: {
            id?: string | number;
            activeSerial?: string;
            gatewayType?: string;
        };
        type: "gateways" | "sites";
    };
}

export const ApplianceServicesCard = (props: ApplianceServicesCardProps) => {
    const {additionalProps, size, preview} = props;
    const {details, type} = additionalProps || {};
    const unsupported = additionalProps?.type === "gateways" && additionalProps?.details?.gatewayType && additionalProps?.details?.gatewayType !== "on-prem";
    const classes = useStyles();

    return (
        <BaseCard
            id="ApplianceServicesCard"
            title="tesseract.appliances.dashboard.details.servicesCardTitle"
            size={size}
            preview={preview}
            unsupported={!!unsupported}
        >
            <ListCardContent
                getFrom={{
                    resource: details?.activeSerial ? (type !== "sites" ? apiResources.configurationOnPremServices : apiResources.configurationServices) : undefined,
                    params: {id: details?.activeSerial, siteId: details?.id}
                }}
            >
                <StatusIconField
                    source="state"
                    arraySource="services"
                    textSource="state"
                    textPrefix="cuda.state."
                    labelIcon={({data, ...props}) => {
                        const LabelIcon = serviceIcons[data.name];
                        return LabelIcon ? <LabelIcon className={classes.serviceIcon} {...props} /> : null;
                    }}
                />
            </ListCardContent>
        </BaseCard>
    );
};