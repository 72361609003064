import React, {useRef, useState} from "react";
import {
    ActionButtonsField,
    ChipArrayField,
    ConnectedTableRefresh,
    DeleteDialog,
    DisableableButton,
    EmptyPageContent,
    InputLabel,
    TablePage,
    TextField,
    useCrudProps,
    useMixpanel
} from "@cuda-react/core";
import {ConnectorGraphic, GetStartedBackgroundGraphic} from "@cuda-react/icons";
import apiResources from "../../../apiResources";
import {Dialog,Typography} from "@barracuda-internal/bds-core";
import CreateEditButtonDialog from "../../../components/CreateEditButtonDialog";
import CreateEditLocations from "./createEdit/CreateEditLocations";
import {Theme} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {Trans} from "react-i18next";
import LocationsTableMenu from "./LocationsTableMenu";

const useStyles = makeStyles((theme: Theme) => ({
    headerText: {
        margin: "16px",
        color: "#646464"
    },
    divider: {
        width: "auto",
        margin: "auto 16px"
    },
     cardActions: {
        width: "calc(100% - 16px)",
        margin: "8px 0 0 16px !important"
    },
     actionsCell: {
        paddingRight: "0 !important"
    },
    customInputHelp: {
        display: "none",
    },
    locationContent: {
        display: "flex",
        width: "100%",
        padding: "0 0 8px 15px",
        fontSize: "inherit",
        fontWeight: "600"
    },
    headerTextContainer: {
        width: "60%",
        paddingTop: "0!important"
    },
    customInputLabel: {
        padding: "10px 16px 10px 15px"
    }
}));

export const Locations = () => {
    const tableRefreshRef = useRef<ConnectedTableRefresh | null>(null);
    const registerAction = useMixpanel("Locations");
    const classes = useStyles();
    const dnsProxies = useCrudProps(apiResources.locationProxies)[0]?.data;
    const [actionVisible, setActionVisible] = useState(false);
    const [createOpen, setCreateOpen] = useState(false);

    return (
        <>
            <TablePage
                title="tesseract.network.pageTitle"
                subtitle= {"tesseract.network.locations.pageTitle"}
                classes={{cardActions: classes.cardActions}}
                tableActionsAndFiltersProps={{classes: {actionFullWidth: classes.headerTextContainer}}}
                actions={[
                    <InputLabel
                        key="dnsProxyContent"
                        label="tesseract.network.locations.table.dnsProxyContent"
                        minimised
                        fullWidth
                        classes={{inputHelp: classes.customInputHelp, inputLabel: classes.customInputLabel}}
                        //@ts-ignore prop used by a parent
                        left
                    >
                        <Typography variant="body2"  className={classes.locationContent}>
                            <Trans i18nKey="tesseract.network.locations.table.primaryDNS" values={{primaryDNS: dnsProxies?.primaryIp}}>
                                <strong />
                            </Trans>
                        </Typography>
                        <Typography variant="body2" className={classes.locationContent}>
                             <Trans i18nKey="tesseract.network.locations.table.secondaryDNS" values={{secondaryDNS: dnsProxies?.secondaryIp}}>
                                <strong />
                            </Trans>
                        </Typography>
                    </InputLabel>
                ]}
                titleActions={actionVisible && (
                    <DisableableButton
                        buttonText="tesseract.network.locations.dialog.add"
                        disabledMessage="tesseract.network.locations.dialog.menuItemDisabled"
                        onClick={() => setCreateOpen(true)}
                        key="create"
                    />
                )}
                authenticated
                resource={apiResources.addLocations}
                tableName="locationsTable"
                minCellWidth={128}
                defaultItemsPerPage={5000}
                flat
                refreshRef={tableRefreshRef}
                resizable
                reorderable
                editColumns
                onPageStatusChange={(pageStatus: string) => setActionVisible(pageStatus === "table")}
                noDataPage={
                    <EmptyPageContent
                        background={<GetStartedBackgroundGraphic/>}
                        icon={<ConnectorGraphic/>}
                        iconHeader="tesseract.network.locations.noData.iconHeader"
                        textHeader="tesseract.network.locations.noData.textHeader"
                        text="tesseract.network.locations.noData.text"
                        actions={
                            <DisableableButton
                                buttonText="tesseract.network.locations.noData.labelButton"
                                disabledMessage="tesseract.network.locations.dialog.menuItemDisabled"
                                onClick={() => setCreateOpen(true)}
                                key="create"
                            />
                        }
                    />
                }
            >
                <TextField
                    source="name"
                    label="tesseract.network.locations.table.name"
                    filter="text"
                    sortable
                />
                <TextField
                    source="description"
                    label="tesseract.network.locations.table.description"
                    filter="text"
                />
                <ChipArrayField
                    source="addresses"
                    label="tesseract.network.locations.table.ipAddresses"
                    sortable
                />
                <ActionButtonsField
                    width={142}
                    source="id"
                    alwaysVisible
                    cellClassName={classes.actionsCell}
                >
                    <LocationsTableMenu/>
                    <CreateEditButtonDialog
                        component={CreateEditLocations}
                        onSuccess={() => {
                            registerAction("Update");
                            tableRefreshRef.current?.();
                        }}
                    />
                    <DeleteDialog
                        resource={apiResources.addLocations}
                        title="tesseract.network.locations.delete.title"
                        message="tesseract.network.locations.delete.body"
                        onSuccess={() => {
                            tableRefreshRef.current?.();
                            registerAction("Delete Location");
                        }}
                    />
                </ActionButtonsField>
            </TablePage>
            <Dialog
                open={createOpen}
                maxWidth={false}
                sx={{
                    '& .MuiPaper-root': {
                        width: '100%',
                        maxWidth: '720px'
                    }
                }}
                disableEnforceFocus
            >
                <CreateEditLocations
                    onSuccess={() => {
                        registerAction("Create");
                        tableRefreshRef.current?.();
                    }}
                    create
                    onClose={() => setCreateOpen(false)}
                />
            </Dialog>
        </>
    );
};

export default Locations;