import React, {useRef, useState} from "react";
import {
    ActionButtonsField,
    ChipArrayField,
    ConnectedTableRefresh,
    CrudTypes,
    DateField,
    DeleteDialog,
    DisableableButton,
    EmptyPageContent,
    getArrayDataContent,
    PropGateway,
    StatusIconField,
    TablePage,
    TextField,
    useCrudSubscription,
    useMixpanel
} from "@cuda-react/core";
import {BooleanIcon, ConnectorGraphic, GetStartedBackgroundGraphic} from "@cuda-react/icons";
import apiResources from "../../../apiResources";
import CreateEditButtonDialog from "../../../components/CreateEditButtonDialog";
import EditSdwanConnector from "./createEdit/EditSdwanConnector";
import {siteGatewayStatusIcons} from "../../../components/iconMapping";
import {useGenericField} from "../../../hooks/sourceAndDestinationHooks";
import SdwanConnectorTableMenu from "./SdwanConnectorTableMenu";
import CreateSdwanConnector from "./createEdit/CreateSdwanConnector";
import {Dialog} from "@barracuda-internal/bds-core";

export const SdwanConnector = () => {
    const tableRefreshRef = useRef<ConnectedTableRefresh | null>(null);
    const registerAction = useMixpanel("SD-WAN Connector");
    const generateChipArrayProps = useGenericField();
    const [gatewaysData, loading] = useCrudSubscription(
        CrudTypes.GET,
        apiResources.gatewayNames,
        {},
        {pollInterval: 30000}
    );
    const noGateways = getArrayDataContent(gatewaysData).length === 0;
    const [actionVisible, setActionVisible] = useState(false);
    const [createOpen, setCreateOpen] = useState(false);

    return (
        <>
            <TablePage
                title="tesseract.network.pageTitle"
                subtitle={"tesseract.network.sdwanConnector.pageTitle"}
                titleActions={actionVisible &&
                    <DisableableButton
                        buttonText="tesseract.network.sdwanConnector.dialog.add"
                        disabledMessage="tesseract.network.sdwanConnector.dialog.menuItemDisabled"
                        disabled={noGateways || loading}
                        onClick={() => setCreateOpen(true)}
                    />
                }
                authenticated
                resource={apiResources.sdwanConnector}
                tableName="SdWanConnectorTable"
                minCellWidth={128}
                defaultItemsPerPage={5000}
                flat
                refreshRef={tableRefreshRef}
                resizable
                reorderable
                editColumns
                onPageStatusChange={(pageStatus) => setActionVisible(pageStatus === "table")}
                noDataPage={
                    <EmptyPageContent
                        background={<GetStartedBackgroundGraphic/>}
                        icon={<ConnectorGraphic/>}
                        iconHeader="tesseract.network.sdwanConnector.noData.iconHeader"
                        textHeader="tesseract.network.sdwanConnector.noData.textHeader"
                        text="tesseract.network.sdwanConnector.noData.text"
                        actions={
                            <DisableableButton
                                buttonText="tesseract.network.sdwanConnector.noData.labelButton"
                                disabledMessage="tesseract.network.sdwanConnector.dialog.menuItemDisabled"
                                disabled={noGateways || loading}
                                onClick={() => setCreateOpen(true)}
                            />
                        }
                    />
                }
            >
                <StatusIconField
                    isNotification={true}
                    source="connectionStatus.aggregateConnectionState"
                    iconMap={siteGatewayStatusIcons}
                    tooltipSource="statusName"
                    label="tesseract.network.sdwanConnector.table.status"
                    width={128}
                    filter="selectarray"
                    sortable
                />
                <TextField
                    source="name"
                    label="tesseract.network.sdwanConnector.table.name"
                    filter="text"
                    sortable
                />
                <PropGateway
                    source="configurationUuid"
                    label="tesseract.network.sdwanConnector.table.gateway"
                    editProps={generateChipArrayProps("configurationType")}
                    sortable
                >
                    <ChipArrayField/>
                </PropGateway>
                <ChipArrayField
                    source="resources"
                    render={(value) => (value?.serviceName)}
                    label="tesseract.customApps.table.destination"
                    sortable
                />
                <ChipArrayField
                    source="resources"
                    render={(value, data) => data?.routedMode ? [value?.hostname, `${value?.hostname}.${data?.dnsSuffix}`] : value?.hostname}
                    label="tesseract.network.sdwanConnector.table.hostnames"
                    columnProps={{
                        field: "hostnames"
                    }}
                />
                <StatusIconField
                    source="routedMode"
                    label="tesseract.network.sdwanConnector.dialog.general.routedMode"
                    filter="select"
                    icon={<BooleanIcon/>}
                    sortable
                />
                <ChipArrayField
                    source="resources"
                    render={(resource: any) =>
                        resource.ports?.map(
                            (port: any) =>
                                port.lastPort
                                    ? `${port.firstPort}-${port.lastPort}`
                                    : `${port.firstPort}`
                        )
                    }
                    label="tesseract.network.sdwanConnector.dialog.servers.ports"
                    sortable
                />
                <DateField
                    source="created"
                    label="tesseract.network.sdwanConnector.table.provisioningDate"
                    sortable
                />
                <ActionButtonsField
                    width={142}
                    source="id"
                    alwaysVisible
                >
                    <SdwanConnectorTableMenu/>
                    <CreateEditButtonDialog component={EditSdwanConnector}
                                            onSuccess={tableRefreshRef.current || undefined}/>
                    <DeleteDialog
                        resource={apiResources.sdwanConnector}
                        title="tesseract.network.sdwanConnector.delete.title"
                        message="tesseract.network.sdwanConnector.delete.body"
                        onSuccess={() => {
                            tableRefreshRef.current?.();
                            registerAction("Delete SecureEdge Connector");
                        }}
                    />
                </ActionButtonsField>
            </TablePage>
            <Dialog
                open={createOpen}
                maxWidth={false}
                sx={{
                    '& .MuiPaper-root': {
                        width: '100%',
                        maxWidth: '882px'
                    }
                }}
                disableEnforceFocus
                onClose={() => setCreateOpen(false)}
            >
                <CreateSdwanConnector
                    onSuccess={tableRefreshRef.current}
                    onClose={() => {
                        setCreateOpen(false);
                    }}
                />
            </Dialog>
        </>
    );
};

export default SdwanConnector;