import {FormHelperText, ListItemText, MenuItem, Select, TextField, Typography} from "@barracuda-internal/bds-core";
import classNames from "classnames";
import {clone, cloneDeep, get, isEmpty, set} from "lodash";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import {formatErrorMessage} from "../../../utils";
import {Theme} from "@mui/material";
import {createStyles} from "@mui/styles";

export interface BaseTimeWindowProps {
    /**
     * if true, adds a new choice that will mean every day.
     */
    allowDaily?: boolean,
    /**
     * if true, components get disabled.
     */
    disabled?: boolean,
    /**
     * error message to display.
     */
    error?: string,
    /**
     * if true, day Select components will not render.
     */
    isDaily?: boolean,
    /**
     * event handler called when the input is focused.
     */
    onFocus?: (event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>) => void,
    /**
     * used to generate unique id for the Select/TextField components that represent days and / hours start and end. "{start|end}-{day|time}-input-$(id))"
     */
    id?: string
}

const baseDayOptions = [
    {key: "mon", value: "cuda.inputs.timeWindow.abbrevDaysOfWeek.mon"},
    {key: "tue", value: "cuda.inputs.timeWindow.abbrevDaysOfWeek.tue"},
    {key: "wed", value: "cuda.inputs.timeWindow.abbrevDaysOfWeek.wed"},
    {key: "thu", value: "cuda.inputs.timeWindow.abbrevDaysOfWeek.thu"},
    {key: "fri", value: "cuda.inputs.timeWindow.abbrevDaysOfWeek.fri"},
    {key: "sat", value: "cuda.inputs.timeWindow.abbrevDaysOfWeek.sat"},
    {key: "sun", value: "cuda.inputs.timeWindow.abbrevDaysOfWeek.sun"}
];

export const styles = (theme: Theme) => createStyles<string, BaseTimeWindowProps>({
    textField: {
        minWidth: 98,
        maxWidth: 140, // Allow for AM/PM as set by locale
    },
    fromToText: {
        display: "inline-block",
        color: theme.palette.text.secondary,
        lineHeight: "19px",
        minWidth: 38
    },
    fromBlock: {
        [theme.breakpoints.down("sm")]: {
            paddingBottom: (props) => !props.isDaily ? "10px" : 0
        }
    },
    fromText: {
        padding: "10px 8px 10px 0",
    },
    toText: {
        padding: "10px 4px",
        textAlign: "center"
    },
    listItemIcon: {
        display: "inline-flex",
        minWidth: "unset",
        marginRight: "8px",
        verticalAlign: "middle"
    },
    inset: {
        paddingLeft: 32
    },
    listItemText: {
        display: "inline-flex",
        margin: 0
    },
    listItemTextDisabled: {
        color: "grey",
        display: "inline-flex",
        margin: 0
    },
    select: {
        marginRight: "12px",
        width: 98,
        // "&:before, &:hover:before": {
        //     borderBottomColor: theme.palette.customDivider?.border?.color
        // },
        // "&:after": {
        //     borderBottomColor: theme.palette.secondary.main
        // },
        // "& .MuiSelect-select": {
        //     minHeight: 25,
        //     paddingTop: 0,
        //     paddingBottom: 0,
        //     overflow: "visible",
        //     "&:focus": {
        //         backgroundColor: theme.palette.background.paper
        //     }
        // },
        // fontSize: 14
    },
    outerContainer: {
        display: "flow-root"
    },
    timePoint: {
        display: "inline-flex",
        justifyContent: "center"
    },
    inputHelp: {
        display: "inline-block",
        width: "1rem",
        height: "auto",
        padding: "1rem 0.5rem 1rem 0"
    }
});

const useStyles = makeOverrideableStyles("TimeWindow", styles);


export interface TimeWindowProps<TimeWindow extends object> extends StyledComponentProps<typeof styles>, BaseTimeWindowProps {
    /**
     * object of default values for all fields.
     */
    defaults?: TimeWindow,
    /**
     * dot-path notation to the date string in value/default that represents the last day.
     */
    endDayKey?: string,
    /**
     * dot-path notation to the date string in value/default that represents when the time is over.
     */
    endTimeKey?: string,
    /**
     * event handler called when the input loses focus.
     */
    onBlur?: (value: TimeWindow) => void,
    /**
     * event handler called when the input's value changes.
     */
    onChange?: (value: TimeWindow) => void,
    /**
     * dot-path notation to the date string in value/default that represents which day is the start day.
     */
    startDayKey?: string,
    /**
     * dot-path notation to the date string in value/default that represents which time is the start time.
     */
    startTimeKey?: string,
    /**
     * structure that holds the information about the day/time start/end.
     */
    value?: TimeWindow
}

/**
 * Renders two Select components with the week days (and a daily choice if allowDaily)
 * and two TextField components (time type) to be able to choice between two different times.
 */
export const TimeWindow = <TimeWindow extends object> (props: TimeWindowProps<TimeWindow>) => {
    const {
        allowDaily,
        defaults,
        disabled,
        endDayKey = "to.day",
        endTimeKey = "to.time",
        error,
        isDaily,
        onChange,
        onFocus,
        onBlur,
        id,
        startDayKey = "from.day",
        startTimeKey = "from.time",
        value = {}
    } = props;
    const classes = useStyles(props);
    const [translate] = useTranslation();
    const dayOptions = clone(baseDayOptions);
    const errorToDisplay = error && formatErrorMessage(error);
    if (allowDaily) {
        dayOptions.push({key: "*", value: "cuda.inputs.timeWindow.daily"});
    }

    useEffect(() => {
        const initialState = {};
        if (!get(value, startTimeKey)) {
            set(initialState, startTimeKey, get(defaults, startTimeKey, "00:00"));
        }
        if (!get(value, endTimeKey)) {
            set(initialState, endTimeKey, get(defaults, endTimeKey, "00:00"));
        }

        if (isEmpty(value) && isDaily) {
            set(initialState, startDayKey, "*");
            set(initialState, endDayKey, "*");
        } else {
            if (!get(value, startDayKey)) {
                set(initialState, startDayKey, get(defaults, startDayKey, "sun"));
            }
            if (!get(value, endDayKey)) {
                set(initialState, endDayKey, get(defaults, endDayKey, "mon"));
            }
        }

        if (!isEmpty(initialState)) {
            onChange?.(initialState as TimeWindow);
        }
    }, []);

    const updateWindow = (field: string, target: {value: any}, isBlur?: boolean) => {
        if (target) {
            const newWindow = cloneDeep(value) as TimeWindow;
            if (field === startDayKey) {
                if (target.value === "*" && get(newWindow, startDayKey) !== "*") {
                    set(newWindow, endDayKey, target.value);
                } else if (target.value !== "*" && get(newWindow, startDayKey) === "*") {
                    set(newWindow, endDayKey, target.value);
                }
            }
            set(newWindow, field, target.value);
            isBlur ? (onBlur?.(newWindow)) : (onChange?.(newWindow));
        }
    };

    const renderMenuItem = (choice: {key: string, value: string}) => {
        const choiceName = get(choice, "value");
        const choiceKey = get(choice, "key");
        return (
            <MenuItem
                key={choiceKey}
                value={choiceKey}
            >
                <ListItemText
                    primary={translate(choiceName)}
                    className={classes.listItemText}
                    classes={{inset: classes.inset}}
                />
            </MenuItem>
        );
    };

    return (
        <div className={classes.outerContainer}>
            <div className={classNames(classes.timePoint, classes.fromBlock)}>
                <Typography variant="body1" className={classNames(classes.fromToText, classes.fromText)}>{translate("cuda.inputs.timeWindow.from")}</Typography>
                {!isDaily ?
                    <Select
                        value={get(value, startDayKey, "mon")}
                        onChange={(event) => updateWindow(startDayKey, event?.target)}
                        error={!!errorToDisplay}
                        disabled={disabled}
                        id={"start-day-input-" + id}
                        className={classes.select}
                        variant="outlined"
                    >
                        {dayOptions.map((day) => renderMenuItem(day))}
                    </Select>
                    : null
                }
                <TextField
                    value={get(value, startTimeKey, "")}
                    onBlur={(event) => updateWindow(startTimeKey, event?.target, true)}
                    onFocus={(event) => onFocus?.(event)}
                    onChange={(event) => updateWindow(startTimeKey, event?.target)}
                    type="time"
                    size="small"
                    error={!!errorToDisplay}
                    disabled={disabled}
                    id={"start-time-input-" + id}
                    className={classes.textField}
                    inputProps={{step: 900 /* 15 minutes */}}
                    variant="outlined"
                />
            </div>
            <div className={classes.timePoint}>
                <Typography variant="body1"  className={classNames(classes.fromToText, classes.toText)}>{translate("cuda.inputs.timeWindow.to")}</Typography>
                {!isDaily && get(value, startDayKey) !== "*" ?
                    <Select
                        value={get(value, endDayKey, "")}
                        onChange={(event) => updateWindow(endDayKey, event?.target)}
                        error={!!errorToDisplay}
                        disabled={disabled}
                        id={"end-day-input-" + id}
                        className={classes.select}
                        variant="outlined"
                    >
                        {baseDayOptions.map((day) => renderMenuItem(day))}
                    </Select>
                    : null
                }
                <TextField
                    value={get(value, endTimeKey, "")}
                    onBlur={(event) => updateWindow(endTimeKey, event?.target, true)}
                    onFocus={(event) => onFocus?.(event)}
                    onChange={(event) => updateWindow(endTimeKey, event?.target)}
                    type="time"
                    size="small"
                    error={!!errorToDisplay}
                    disabled={disabled}
                    id={"end-time-input-" + id}
                    className={classes.textField}
                    inputProps={{step: 900 /* 15 minutes */}}
                    variant="outlined"
                />
            </div>
            {error ? <FormHelperText error>{errorToDisplay}</FormHelperText> : null}
        </div>
    );
};

export default TimeWindow;