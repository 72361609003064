import {SvgIcon, useTokens} from "@barracuda-internal/bds-core";
import React from "react";
import classNames from "classnames";

export const UsersGraphic = ({className}: { className?: string }) => {
    const tokens = useTokens();
    return (
        <SvgIcon
            className={classNames(className, "cuda-react-graphic")}
            id="cuda-graphic-users"
            viewBox="0 0 96 96"
        >
            <g id="ArtBoard11">
                <g id="Ebene-1">
                    <path style={{fill: tokens.aliasesColorTextStaticNeutral, strokeWidth: 0}}
                          d="M42.6,36.4c-5,0-9.1-4.1-9.1-9.1s4.1-9.1,9.1-9.1,9.1,4.1,9.1,9.1-4.1,9.1-9.1,9.1M42.6,13.2c-7.8,0-14.2,6.4-14.2,14.2s6.4,14.2,14.2,14.2,14.2-6.4,14.2-14.2-6.4-14.2-14.2-14.2"/>
                    <path style={{fill: tokens.globalWhite, strokeWidth: 0}} d="M68.5,58.5h-3.4v6.8h-6.8v3.4h6.8v6.8h3.4v-6.8h6.8v-3.4h-6.8v-6.8Z"/>
                    <path style={{fill: tokens.aliasesColorTextStaticNeutral, strokeWidth: 0}}
                          d="M47.1,67s0,0,0-.1h-27.8c-.6,0-1.1-.5-1.1-1.1v-4.2c0-3.6,13-9.1,24.3-9.1s6.5.5,9.7,1.2c1.4-1.6,3.1-2.9,4.9-4-5.2-1.5-10.6-2.3-14.6-2.3-10,0-29.4,5-29.4,14.2v10.4h34.6c-.4-1.6-.7-3.3-.7-5"/>
                    <path style={{fill: tokens.aliasesColorIconSuccessNeutral, strokeWidth: 0}}
                          d="M66.9,50.1c-9.3,0-16.9,7.6-16.9,16.9s7.6,16.9,16.9,16.9,16.9-7.6,16.9-16.9-7.6-16.9-16.9-16.9ZM75.3,68.7h-6.8v6.8h-3.4v-6.8h-6.8v-3.4h6.8v-6.8h3.4v6.8h6.8v3.4Z"/>
                </g>
            </g>
        </SvgIcon>
    );
};

export default UsersGraphic;