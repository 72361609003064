import React from "react";
import {Route, Switch} from "react-router";
import SiteDetails from "./SiteDetails";
import SiteTable from "./SiteTable";

export const Sites= () => (
    <Switch>
        <Route
            exact
            path="/:account/:workspace/infrastructure/sites"
            component={SiteTable}
        />
        <Route
            path="/:account/:workspace/infrastructure/sites/:id"
            component={SiteDetails}
        />
    </Switch>
);


export default Sites;