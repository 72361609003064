import * as React from "react";
import {CudaReactProvider} from "@cuda-react/core";
import StratosTheme from "./theme/StratosTheme";
import StratosMessages from "./i18n/StratosMessages";
import authClient from "./clients/authClient";
import AppProvider from "./components/AppProvider";
import AppContent from "./AppContent";
import getAppConfig from "./utils/getAppConfig";

const App = (): JSX.Element => {
    const {mixpanelConfig, unleashConfig} = getAppConfig();

    return (
        <CudaReactProvider
            // @ts-ignore
            customTheme={StratosTheme}
            bccAuthentication
            customMessages={StratosMessages}
            authClient={authClient}
            mixpanelConfig={mixpanelConfig}
            unleashConfig={unleashConfig}
        >
            <AppProvider>
                <AppContent/>
            </AppProvider>
        </CudaReactProvider>
    );
};

export default App;