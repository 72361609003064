import React, {useState} from "react";
import {
    BooleanInput,
    ConnectedForm,
    CrudTypes,
    DialogBody,
    getArrayDataContent,
    SelectInput,
    TextInput,
    useCrudProps,
    useCrudSubscription,
    useGlobalParam,
    useMixpanel,
    usePreview,
    validateMaxLengthMemo,
    validateRegexMemo
} from "@cuda-react/core";
import {get, isEqual} from "lodash";
import {useGeneratedSourceCriteriaSection} from "../../../../../hooks/sourceAndDestinationHooks";
import generateDestinationCriteriaSection
    from "../../../../../components/sourceAndDestination/generateDestinationCriteriaSection";
import {
    formatRequestCriteria,
    formatResourceCriteria
} from "../../../../../components/sourceAndDestination/formUtils";
import {settingsIcons} from "../../../../../components/iconMapping";
import apiResources from "../../../../../apiResources";
import {useTranslation} from "react-i18next";

const hideInput = (key: any, type: any) => (value: any, data: any) => !get(data, key) || get(data, key) !== type;

/**
 * For now we are using this workaround to get the karma tests working.
 */
interface CreateEditUrlExceptionsProps {
    create?: boolean;
    id?: string | number;
    onClose: () => void;
    onSuccess?: () => void;
    defaultScope?: string
}

export const CreateEditUrlExceptions: React.FC<CreateEditUrlExceptionsProps> = ({create, id, onSuccess, defaultScope, ...props}) => {
    const registerAction = useMixpanel("Web Filter Explicit", false);
    const availableWebFilterPoliciesScope = getArrayDataContent(useCrudProps(apiResources.availableWebFilterPoliciesScope, {}, {}, true)[0]?.data);
    const [currentScope, setCurrentScope] = useState<string|undefined>(defaultScope);
    const currentScopeName = availableWebFilterPoliciesScope.find((scope) => scope.key === currentScope)?.name;
    const categoryChoices = getArrayDataContent(useCrudProps(apiResources.urlFilterCategories, {}, {}, true)[0]?.data);
    const groupChoices = getArrayDataContent(useCrudProps(apiResources.urlFilterGroups, {}, {}, true)[0]?.data);
    const [urlFilterSourcesByScope] = useCrudSubscription(CrudTypes.GET, currentScope && apiResources.urlFilterSourcesByScope, {filter: {scope: currentScope}});
    const urlFilterSourcesByScopeArray = getArrayDataContent(urlFilterSourcesByScope);
    const urlFilterActionsByScope = useCrudSubscription(CrudTypes.GET, currentScope && apiResources.urlFilterActionsByScope, {filter: {scope: currentScope}})[0]?.data?.content;
    const [translate] = useTranslation();

    const [virtualWanId] = useGlobalParam("filter.virtualWanId");

    const isDnsLocation = usePreview("dnslocations");

    return (
        <DialogBody
            {...props}
            form
            title={create ?
                "tesseract.security.urlFiltering.settings.create.dialogTitle" :
                translate("tesseract.security.urlFiltering.settings.edit.dialogTitle", {currentScope: currentScopeName})
            }
        >
            <ConnectedForm
                resource={apiResources.urlExceptions}
                // @ts-ignore
                form="urlExceptions"
                create={create}
                params={create ? undefined : {id}}
                onSubmitSuccess={(response) => {
                    registerAction(create ? "Create" : "Update", {
                        action: response.action,
                        sourceType: response.source?.type,
                        destinationType: response.destination?.type
                    });
                    onSuccess?.();
                    props.onClose();
                }}
                onChange={(newValues) => {
                    newValues?.scope && setCurrentScope(newValues?.scope);
                }}
                onCancel={props.onClose}
                formatRequestData={formatRequestCriteria(virtualWanId)}
                formatResourceData={formatResourceCriteria}
                initialValues={create ? {scope: defaultScope || "", source: {allSites: true, locations: {all: true, ids: []}}, destination: {allSites: true}} : undefined}
                allowDirtyNavigation
            >
                <SelectInput
                    source="scope"
                    label="tesseract.security.urlFiltering.exceptions.scope"
                    description="tesseract.security.urlFiltering.exceptions.descriptions.scope"
                    choices={(isDnsLocation || !create) ? availableWebFilterPoliciesScope : availableWebFilterPoliciesScope.filter(({key}) => key !== "dnsLocation")}
                    disabled={!create}
                    defaultChoice={defaultScope}
                    isRequired
                />
                <TextInput
                    source="name"
                    label="tesseract.security.urlFiltering.exceptions.name"
                    description="tesseract.security.urlFiltering.exceptions.descriptions.name"
                    validate={[validateMaxLengthMemo(64), validateRegexMemo(/^[a-zA-Z0-9-]+$/, "tesseract.validation.alphaNumericDash")]}
                    isRequired
                />
                <TextInput
                    source="description"
                    label="tesseract.security.urlFiltering.exceptions.description"
                    description="tesseract.security.urlFiltering.exceptions.descriptions.description"
                    validate={[validateMaxLengthMemo(255), validateRegexMemo(/^[a-zA-Z0-9- ]+$/, "tesseract.validation.alphaNumericDashSpace")]}
                />
                <SelectInput
                    source="action"
                    label="tesseract.security.urlFiltering.exceptions.action"
                    description="tesseract.security.urlFiltering.exceptions.descriptions.action"
                    // @ts-ignore
                    icons={settingsIcons}
                    iconMap={settingsIcons}
                    choices={urlFilterActionsByScope}
                    isRequired
                />
                <BooleanInput
                    source="silent"
                    label="tesseract.security.urlFiltering.exceptions.silent"
                    description="tesseract.security.urlFiltering.exceptions.descriptions.silent"
                    disable={hideInput("action", "block")}
                    hide={(value, data) => data?.scope === "dnsLocation" || data?.scope === "allSources"}
                />
                {useGeneratedSourceCriteriaSection(urlFilterSourcesByScopeArray)}
                {generateDestinationCriteriaSection(["category", "domain", "customCategory"], categoryChoices, groupChoices)}
            </ConnectedForm>
        </DialogBody>
    );
};


export const propsAreEqual = (val1: any, val2: any) => isEqual(val1.id, val2.id) && isEqual(val1.create, val2.create);

export default React.memo(CreateEditUrlExceptions, propsAreEqual);