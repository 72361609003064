import React, {useCallback} from "react";
import apiResources from "../../../apiResources";
import {
    CrudTypes,
    Form,
    HiddenInput,
    SelectArrayInput,
    useCrudFetch,
    useCrudProps,
    useGlobalParam
} from "@cuda-react/core";
import {Trans, useTranslation} from "react-i18next";
import WizardPage, {WizardPageProps} from "../WizardPage";
import {User} from "@barracuda-internal/bds-core/dist/Icons/Core";
import {Typography} from "@barracuda-internal/bds-core";
import {SavedFormData} from "../SetupWizard";
import {makeStyles} from "@mui/styles";

interface WizardStep5Props extends Pick<WizardPageProps, "wizardStates" | "registerStepAction"> {
    onSuccess?: () => void,
    savedFormData?: SavedFormData
}

const useStyles = makeStyles({
    form: {
        height: "100%"
    }
});

const WizardStep5 = (props: WizardStep5Props) => {
    const [virtualWanId] = useGlobalParam("filter.virtualWanId");
    const [, saving, crudSubmit] = useCrudFetch(CrudTypes.CREATE, apiResources.sendZeroTrustEnrollEmail, {});
    const [{data: seatsData}] = useCrudProps(apiResources.zeroTrustUsersRemainingSeats);
    const remainingSeats = seatsData?.count;
    const [translate] = useTranslation();
    const validateNoSeats = useCallback(() => remainingSeats < 1 ? translate("tesseract.endpoint.enrollEmailDialog.noSeats") : undefined, [remainingSeats]);
    const registerFailAction = (error: any) => {
        props.registerStepAction("Fail", {error: JSON.stringify(error)});
    };
    const classes = useStyles();

    const save = useCallback((values) => {
        props.registerStepAction("Submit");
        return crudSubmit(
            {
                data: {
                    ...values,
                    virtualWanId,
                    subject: translate("tesseract.endpoint.enrollEmailDialog.zeroTrustSubject"),
                }
            },
            {formPromise: true}
        ).then((response: { data?: any, error?: any }) => {
            if (!response?.error) {
                props.onSuccess?.();
            }
            return response?.data;
        }).catch((error: any) => {
            registerFailAction(error);
            throw error;
        });
    }, [crudSubmit, virtualWanId]);

    return (
        <Form
            save={save}
            disabled={saving}
            initialValues={{users: props.savedFormData?.users || props.savedFormData?.policyUsers || []}}
            noToolbar
            flat
            // @ts-ignore prop does exist
            classes={{form: classes.form}}
        >
            <WizardPage
                textHeader="tesseract.setupWizard.wizardStep5.textHeader"
                textSubHeader="tesseract.setupWizard.wizardStep5.textSubHeader"
                mainText="tesseract.setupWizard.wizardStep5.mainText"
                extraText="tesseract.setupWizard.wizardStep5.extraText"
                activeStep={4}
                {...props}
            >
                <Typography>
                    <Trans i18nKey="tesseract.setupWizard.wizardStep5.seats" values={{count: remainingSeats}}/>
                </Typography>
                <HiddenInput source="enrollmentEmailEnabled" hiddenValue={true}/>
                <SelectArrayInput
                    source="users"
                    label="tesseract.endpoint.enrollEmailDialog.users"
                    resource={apiResources.users}
                    optionValue="name"
                    optionText="username"
                    filterKey="id"
                    options={{
                        label: "tesseract.endpoint.enrollEmailDialog.addUsers",
                        dropdownAutoWidth: true,
                        optionSubText: "name",
                        filterKey: "partialMatch"
                    }}
                    validate={[validateNoSeats]}
                    icon={<User/>}
                    newStyle
                    isRequired
                />
            </WizardPage>
        </Form>
    );
};


export default WizardStep5;