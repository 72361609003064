import React from "react";
import {Route, Switch} from "react-router";
import UsersTable from "./UsersTable";
import UserDetails from "./user/UserDetails";

export const Users = () => (
    <Switch>
        <Route
            exact
            path="/:account/:workspace/ztna/users"
            component={UsersTable}
        />
        <Route
            path="/:account/:workspace/ztna/users/:id"
            component={UserDetails}
        />
    </Switch>
);

export default Users;