import React from "react";
import {
    BooleanInput,
    FormSection,
    getArrayDataContent,
    PasswordInput,
    SelectInput,
    TextInput,
    useCrudProps,
    validateMaxLengthMemo
} from "@cuda-react/core";
import {Typography} from "@barracuda-internal/bds-core";
import {makeStyles} from "@mui/styles";
import {useTranslation} from "react-i18next";
import {useSiteInputValidations} from "../../../../hooks/createEditSiteHooks";
import apiResources from "../../../../apiResources";

const useStyles = makeStyles((theme) => ({
    dialogContent: {
        fontSize: 16,
    },
    completeMessage: {
        margin: "24px"
    },
    boolean: {
        maxWidth: 256
    }
}));

export const GeneralIpSecTunnelInput = () => {
    const classes = useStyles();
    const [translate] = useTranslation();
    const inputValidations = useSiteInputValidations();
    const authenticationTypes = getArrayDataContent(useCrudProps(apiResources.authenticationType)[0]);


    return (
        <React.Fragment>
            <Typography variant="body1" className={classes.completeMessage}>
                {translate("tesseract.integration.ipSecV2.dialog.generalSection.descriptionMessage")}
            </Typography>
            <BooleanInput
                source="enabled"
                label="tesseract.integration.ipSecV2.dialog.generalSection.tunnelStatus"
                description="tesseract.integration.ipSecV2.dialog.generalSection.general.descriptions.tunnelStatus"
            />
            <BooleanInput
                source="initiate"
                label="tesseract.integration.ipSecV2.dialog.sourceDestSection.source.initiatesTunnel"
                description="tesseract.integration.ipSecV2.dialog.generalSection.general.descriptions.initiatesTunnel"
                classes={{field: classes.boolean}}
            />
            <FormSection title="tesseract.integration.ipSecV2.dialog.generalSection.general.title">
                <TextInput
                    label="tesseract.integration.ipSecV2.dialog.generalSection.general.name"
                    description="tesseract.integration.ipSecV2.dialog.generalSection.general.descriptions.name"
                    source="name"
                    validate={inputValidations.validateName}
                    isRequired
                />
                <TextInput
                    label="tesseract.integration.ipSecV2.dialog.generalSection.general.description"
                    description="tesseract.integration.ipSecV2.dialog.generalSection.general.descriptions.description"
                    source="description"
                    validate={[validateMaxLengthMemo(255)]}
                />
            </FormSection>
            <FormSection title="tesseract.integration.ipSecV2.dialog.generalSection.authentication.title">
                <SelectInput
                    label="tesseract.integration.ipSecV2.dialog.generalSection.authentication.authentication"
                    description="tesseract.integration.ipSecV2.dialog.generalSection.authentication.descriptions.authentication"
                    source="authentication.method"
                    choices={authenticationTypes}
                    disabled
                    isRequired
                />
                <PasswordInput
                    label="tesseract.integration.ipSecV2.dialog.generalSection.authentication.sharedSecret"
                    description="tesseract.integration.ipSecV2.dialog.generalSection.authentication.descriptions.sharedSecret"
                    source="authentication.psk.secret"
                    validate={inputValidations.validatePassword}
                    hide={((values, data) => data?.authentication?.method !== "pre-shared-key")}
                    isRequired
                />
            </FormSection>
        </React.Fragment>
    );
};

export default GeneralIpSecTunnelInput;