import React from "react";
import {Route, Switch} from "react-router";
import SiteDetails from "./SiteDetails";
import IotTable from "./IotTable";

export const IoT = () => (
    <Switch>
        <Route
            exact
            path="/:account/:workspace/infrastructure/iot"
            component={IotTable}
        />
        <Route
            path="/:account/:workspace/infrastructure/iot/:id"
            component={SiteDetails}
        />
    </Switch>
);


export default IoT;