import {Button, Collapse, FormHelperText, Grid, TextField, Typography} from "@barracuda-internal/bds-core";
import {Search} from "@barracuda-internal/bds-core/dist/Icons/Core";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {get, isEqual, merge} from "lodash";
import React, {useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {CrudTypes} from "../../../clients";
import {ConnectedTableRefresh, useCrudFetch, useDeepCompareEffect, useDeepCompareMemo} from "../../../hooks";
import {formatErrorMessage} from "../../../utils";
import ChipArrayField from "../../fields/ChipArrayField/ChipArrayField";
import ConnectedTable from "../../table/ConnectedTable/ConnectedTable";
import InputLabel from "../InputLabel/InputLabel";
import Text from "../TextInput/Text";
import {InputAdornment, Theme} from "@mui/material";
import {createStyles} from "@mui/styles";

interface BaseSelectSerialProps {
    /**
     * children components will get wrapped within a ConnectedTable component.
     */
    children: React.ComponentProps<typeof ConnectedTable>["children"],
    /**
     * passed as listenResources prop to the ConnectedTable.
     * See [ConnectedTable](/?path=/docs/core-components-table-connectedtable--connected-table) for more information.
     */
    claimResource?: string,
    /**
     * passed as prop to the ConnectedTable.
     * See [ConnectedTable](/?path=/docs/core-components-table-connectedtable--connected-table) for more information.
     */
    defaultSort?: {
        field: string,
        order: "asc" | "desc"
    },
    /**
     * function to filter the list of selected serials.
     * passed to the ConnectedTable component as part of the params prop.
     * See [ConnectedTable](/?path=/docs/core-components-table-connectedtable--connected-table) for more information.
     * @function
     * @param {rows} selectedRows array of selected entries.
     * @returns {object[]} array of valid selected entries.
     */
    dynamicFilter?: (rows: any[]) => any,
    /**
     * provided automatically when component is rendered inside a [Input](/?path=/docs/core-components-inputs-input) component.
     * error associated with this input.
     */
    error?: any,
    /**
     * text for the show/hide ClaimForm button.
     */
    expandSectionButtonText?: string,
    /**
     * object to filter by on the CRUD call.
     * See [ConnectedTable](/?path=/docs/core-components-table-connectedtable--connected-table) for more information.
     * passed to the ConnectedTable component as params={filter: tableFilter}}
     */
    filter?: object,
    /**
     * help text for the collapsable form.
     */
    helpText?: string,
    /**
     * id of the component for unique identification. This value is prefixed with "serial-input-".
     * provided automatically when component is rendered inside a [Input](/?path=/docs/core-components-inputs-input) component.
     */
    id: string,
    /**
     * label for the liking code text input of the colapsable form.
     */
    linkingCodeInputLabel?: string,
    /**
     * max number of selectable entries on the ConnectedTable. Once reached, entries in the ConnectedTable will get disabled.
     */
    maxSelectable?: number,
    /**
     * callback to called when component stops being interacted with.
     * provided automatically when component is rendered inside a [Input](/?path=/docs/core-components-inputs-input) component.
     * @function onBlur
     */
    onBlur?: () => void,
    /**
     * callback to call when the input value has been changed.
     * provided automatically when component is rendered inside a [Input](/?path=/docs/core-components-inputs-input) component.
     * @function onChange
     */
    onChange?: (value: any) => void,
    /**
     * a unique identifier for each row if optionValue is not set.
     * Used internally for comparisons when some inconsequential values may change, such as timestamps.
     */
    optionIdentifier?: string,
    /**
     * dot-notation path to the property in each choice object that defines the text to display in the chip.
     */
    optionText?: string,
    /**
     * dot-notation path to the property in each choice object that defines the value for matching against the data.
     */
    optionValue?: string,
    /**
     * passed to the ConnectedTable component as prop.
     * [CRUD](/?path=/docs/key-concepts-crud) fetch params. Can either be a param object, or a callback that accepts props and returns a param object
     */
    params?: object,
    /**
     * field to search for on the ConnectedTable component.
     * If not provided, no search field will be rendered.
     */
    searchField?: string,
    /**
     * placeholder for the search field on the ConnectedTable.
     */
    searchPlaceholder?: string,
    /**
     * label for the selected serials chips.
     */
    selectedSerialsLabel?: string,
    /**
     * label for the serial input in the collapsable form.
     */
    serialInputLabel?: string,
    /**
     * passed to the ConnectedTable component as resource prop.
     * See [ConnectedTable](/?path=/docs/core-components-table-connectedtable--connected-table) for more information.
     */
    tableResource: string,
    /**
     * current value of the input.
     * provided automatically when component is rendered inside a [Input](/?path=/docs/core-components-inputs-input) component.
     */
    value?: any
}

const styles = (theme: Theme) => createStyles<string, BaseSelectSerialProps>({
    root: {},
    searchField: {
        marginTop: "16px"
    },
    searchFieldButtonbar: {
        margin: "8px 16px 0 16px",
        width: `calc(100% + 8px)`
    },
    searchFieldCardActions: {
        padding: 0
    },
    connectedTable: {
        //@ts-ignore TODO: this theme entry does exist. We should extend DefaultTheme to fix this properly (or BDS should do that).
        borderColor: (props) => props.error ? theme.palette.error.main : theme.palette.customDivider?.border?.color,
        borderRadius: theme.shape.borderRadius,
        borderStyle: "solid",
        borderWidth: 1
    },
    tableError: {
        marginLeft: "8px"
    },
    addSerial: {
        marginTop: "16px"
    },
    collapseButton: {
        textTransform: "none"
    },
    collapse: {
        marginTop: "8px",
        marginLeft: "20px"
    },
    infoText: {
        marginLeft: "24px"
    },
    submitButton: {
        marginBottom: 10,
        left: -65
    }
});
const useStyles = makeOverrideableStyles("SelectSerial", styles);

export interface SelectSerialProps extends StyledComponentProps<typeof styles>, BaseSelectSerialProps {}

/**
 * Renders a form component with a ConnectedTable, ChipArray, and a collapsable sub form.
 * This group of components allows you to select from the ConnectedTable a list of serials.
 * Also in the collapsable sub form, it allows to claim an appliance box with it's serial and it's Code/Token.
 * This is a pretty specific component to be able to claim and select appliances via serial number.
 * The ConnectedTable will render the children fields of the component as row fields.
 * The ChipArrayField will display the selected serials.
 * The collapsable form has 2 inputs (serial and code/token) that allows to claim a appliance.
 */
export const SelectSerial = (props: SelectSerialProps) => {
    const {
        children,
        claimResource,
        defaultSort = {
            field: "serial",
            order: "asc"
        },
        dynamicFilter,
        filter,
        expandSectionButtonText = "cuda.inputs.selectSerial.expandSectionButtonText",
        helpText = "cuda.inputs.selectSerial.helpText",
        id,
        linkingCodeInputLabel = "cuda.inputs.selectSerial.linkingCode",
        maxSelectable,
        error,
        onChange,
        onBlur,
        value = "",
        optionIdentifier = "serial",
        optionText = "serial",
        optionValue,
        params,
        searchField,
        searchPlaceholder = "cuda.inputs.selectSerial.searchPlaceholder",
        selectedSerialsLabel = "cuda.inputs.selectSerial.selectedSerials",
        serialInputLabel = "cuda.inputs.selectSerial.serial",
        tableResource
    } = props;
    const classes = useStyles(props);
    const [translate] = useTranslation();
    // Claim serial form state and logic
    const tableRefreshRef = useRef<ConnectedTableRefresh>(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [showClaimForm, setShowClaimForm] = useState(false);
    const [values, setValues] = useState<{serial?: string, linkingCode?: string}>({});
    const [touched, setTouched] = useState<"all"|{[key: string]: boolean}>({});
    const [errors, setErrors] = useState<{[key: string]: any}>({});
    const [claimedAppliances, setClaimedAppliances] = useState<string[]>([]);
    const [changedSinceSubmit, setChangedSinceSubmit] = useState<{[key: string]: boolean}>({});
    const [saveData, saving, performSave] = useCrudFetch(CrudTypes.CREATE, claimResource, {data: values}, {quietErrors: true});
    const serverErrors = saveData?.error?.body?.errors || {};
    const formContainsErrors = Object.keys(errors).length > 0;
    const fieldIsTouched = (source: string) => touched === "all" || touched[source];

    const getFieldErrors = (source: string) => {
        if (!get(changedSinceSubmit, source) && claimedAppliances.length > 0) {
            return get(serverErrors, source);
        }
        return get(errors, source);
    };

    const claimAppliance = () => {
        setTouched("all");
        if (formContainsErrors) {
            return;
        }
        performSave().then(() => tableRefreshRef.current?.());
        setClaimedAppliances((currentVal) => [...new Set(currentVal.concat([(values.serial || "").trim()]))]);
        setChangedSinceSubmit({});
    };

    // Table selection logic
    const [tableFilter, setTableFilter] = useState(merge({}, filter, searchField && {[searchField]: searchQuery}));
    let selectedRows = value || [];
    if (!Array.isArray(selectedRows)) {
        selectedRows = [selectedRows];
    }
    const {
        isDisabled,
        isSelected,
        onSelect,
        onSelectAll,
        selectAllState
    }  = useDeepCompareMemo(() => {
        const getRowValue = (row: any) => get(row, optionValue || "", row);
        const getRowIdentifier = (row: any) => optionValue ? getRowValue(row) : get(row, optionIdentifier, row);
        const isSelected = (row: any) => selectedRows.some((selectedRow: any) => isEqual(getRowIdentifier(selectedRow), getRowIdentifier(row)));
        const isDisabled = (row: any) => !!(maxSelectable && maxSelectable > 1 && (selectedRows.length >= maxSelectable) && !isSelected(row));
        const onSelect = (row: any) => {
            onBlur && onBlur();
            if (isDisabled(row)) {
                return;
            }
            const newSelectedRows = maxSelectable === 1 ? [] : selectedRows.slice();

            if (isSelected(row)) {
                // item is already selected, so we should remove it
                const itemIndex = newSelectedRows.map(getRowIdentifier).indexOf(getRowIdentifier(row));
                newSelectedRows.splice(itemIndex, 1);
            } else {
                newSelectedRows.push(getRowValue(row));
            }
            onChange && onChange(newSelectedRows);
        };
        const onSelectAll = (data: any) => {
            onBlur?.();
            if (selectedRows.length) {
                onChange && onChange([]);
                return;
            }

            onChange && onChange(data.map(getRowValue));
        };
        const selectAllState = (data: any) => selectedRows.length > 0 && selectedRows.length === data.length;

        return {
            isSelected,
            onSelect,
            isDisabled,
            onSelectAll,
            selectAllState
        };
    }, [optionValue, selectedRows, maxSelectable]);

    const getSerialText = (appliance: any) => typeof appliance === "object" ? get(appliance, optionText) : appliance;
    const onDelete = (value: string) => {
        const newSelectedRows = selectedRows.slice();
        const itemIndex = newSelectedRows.map(getSerialText).indexOf(value);
        newSelectedRows.splice(itemIndex, 1);
        onChange && onChange(newSelectedRows);
    };

    useDeepCompareEffect(() => {
        setTableFilter(merge({}, filter, dynamicFilter && dynamicFilter(selectedRows), searchField && {[searchField]: searchQuery}));
    }, [selectedRows, searchField, searchQuery]);

    // Claim serial form validation
    useDeepCompareEffect(() => {
        const errors: {[key: string]: string} = {};

        if (!values.serial) {
            errors.serial = translate("cuda.validation.required");
        }
        if (!values.linkingCode) {
            errors.linkingCode = translate("cuda.validation.required");
        }

        setErrors(errors);
    }, [values]);

    //Claim serial form after-saving logic
    useDeepCompareEffect(() => {
        if (!saving && saveData && saveData.data && !saveData.error && claimedAppliances.includes(saveData.data && saveData.data.serial)) {
            setValues({});
            setTouched({});
            setShowClaimForm(false);
            onSelect(saveData.data);
        }
    }, [saving]);

    return (
        <div id={"serial-input-" + id} className={classes.root}>
            <ConnectedTable
                actions={searchField && (
                    <TextField
                        className={classes.searchField}
                        value={searchQuery}
                        onChange={(eventOrValue) => setSearchQuery(eventOrValue.target.value)}
                        placeholder={translate(searchPlaceholder)}
                        variant="outlined"
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search/>
                                </InputAdornment>
                            )
                        }}
                    />
                )}
                classes={{dataTable: classes.connectedTable, cardActions: classes.searchFieldCardActions}}
                tableActionsAndFiltersProps={{classes: {buttonBar: classes.searchFieldButtonbar}}}
                isDisabled={isDisabled}
                isSelected={isSelected}
                onSelect={onSelect}
                onSelectAll={maxSelectable ? undefined : onSelectAll}
                selectAllState={selectAllState}
                flat
                noRouter
                refreshRef={tableRefreshRef}
                resource={tableResource}
                defaultSort={defaultSort}
                params={{...params, filter: tableFilter}}
                disablePageSizes
            >
                {children}
            </ConnectedTable>
            <FormHelperText error className={classes.tableError}>
                {formatErrorMessage(error)}
            </FormHelperText>
            {(Array.isArray(value) ? value.length > 0 : value) && (
                <InputLabel label={selectedSerialsLabel}>
                    <ChipArrayField
                        data={{serials: Array.isArray(value) ? value.map(getSerialText) : getSerialText(value)}}
                        source="serials"
                        onDelete={onDelete}
                    />
                </InputLabel>
            )}
            <div className={classes.addSerial}>
                <Button
                    className={classes.collapseButton}
                    onClick={() => setShowClaimForm((prevState) => !prevState)}
                    startIcon={showClaimForm ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                    bdsType="interactiveSubtle"
                >
                    {translate(expandSectionButtonText)}
                </Button>
                <Collapse
                    className={classes.collapse}
                    in={showClaimForm}
                >
                    <Typography
                        className={classes.infoText}
                        color="textSecondary"
                    >
                        {translate(helpText)}
                    </Typography>
                    <Grid
                        alignItems="flex-end"
                        container
                        direction="row"
                        wrap="nowrap"
                    >
                        <Grid item>
                            {saveData && saveData.error && claimedAppliances.length > 0 && (
                                <FormHelperText error>{saveData.error.message}</FormHelperText>
                            )}
                            <InputLabel isRequired label={serialInputLabel} >
                                <Text
                                    disabled={saving}
                                    onBlur={() => !fieldIsTouched("serial") && setTouched((prevState) => prevState === "all" ? "all" : {...prevState, serial: true})}
                                    onChange={(event) => {
                                        const serial = event && event.target && event.target.value || "";
                                        setValues((prevState) => ({...prevState, serial}));
                                        !changedSinceSubmit.serial && setChangedSinceSubmit((prevState) => ({...prevState, serial: true}));
                                    }
                                    }
                                    value={values.serial}
                                    error={ fieldIsTouched("serial") && getFieldErrors("serial")}
                                    id="serial"
                                />
                            </InputLabel>
                            <InputLabel isRequired label={linkingCodeInputLabel} >
                                <Text
                                    disabled={saving}
                                    id="linkingcode"
                                    onBlur={() => (!fieldIsTouched("linkingCode") && setTouched((prevState) => prevState === "all" ? "all" : {...prevState, linkingCode: true}))}
                                    onChange={(event) => {
                                        const linkingCode = event && event.target && event.target.value || "";
                                        setValues((prevState) => ({...prevState, linkingCode}));
                                        !changedSinceSubmit.linkingCode && setChangedSinceSubmit((prevState) => ({...prevState, linkingCode: true}));
                                    }}
                                    value={values.linkingCode}
                                    error={fieldIsTouched("linkingCode") && getFieldErrors("linkingCode")}
                                />
                            </InputLabel>
                        </Grid>
                        <Grid item>
                            <Button
                                className={classes.submitButton}
                                bdsType="interactiveEmphasis"
                                size="small"
                                disabled={formContainsErrors || saving}
                                onClick={claimAppliance}
                                variant="contained"
                            >
                                {translate("cuda.buttons.add")}
                            </Button>
                        </Grid>
                    </Grid>
                </Collapse>
            </div>
        </div>
    );
};

export default SelectSerial;