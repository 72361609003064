import React from "react";
import {Typography} from "@barracuda-internal/bds-core";
import {getArrayDataContent, PasswordConfirmInput, SelectInput, TextInput, useCrudProps} from "@cuda-react/core";
import {useTranslation} from "react-i18next";
import {get} from "lodash";
import {makeStyles} from "@mui/styles";
import {useSiteInputValidations} from "../../../../../hooks/createEditSiteHooks";
import {useWatch} from "react-hook-form";
import apiResources from "../../../../../apiResources";

const useStyles = makeStyles((theme) => ({
    message: {
        marginTop: "8px",
        marginBottom: "8px"
    },
}));

interface LanStepProps {
    stepIndex?: number
}

export const LanStep: React.FC<LanStepProps> = ({stepIndex= 0}) => {
    const getFullSource = (source: string) => "lans[" + stepIndex + "]." + source;
    const [model, port] = useWatch({name: ["serials[0].model", getFullSource("port")]});
    const portsData = getArrayDataContent(useCrudProps(apiResources.modelPorts, {id: model})[0]?.data);
    const availablePorts = portsData.filter((port: any) => port.port && !port.port.includes("wan"));
    const wifiEncryptionMode = getArrayDataContent(useCrudProps(apiResources.wifiEncryptionMode)[0]?.data);
    const inputValidations = useSiteInputValidations();
    const [translate] = useTranslation();
    const classes = useStyles();
    const dhcpTypeChoices = getArrayDataContent(useCrudProps(apiResources.siteConfigurationTypes)[0]?.data);
    const filteredDhcpTypeChoices = dhcpTypeChoices.filter((choice: any) => choice.key !== "dhcpRelay");

    return (
        <React.Fragment>
            <Typography className={classes.message} variant="body1">
                {translate("tesseract.sites.dialog.lans.setup", {step: stepIndex + 1})}
            </Typography>
            <TextInput
                source={getFullSource("name")}
                label="tesseract.sites.dialog.lans.name"
                description="tesseract.sites.dialog.lans.descriptions.name"
                isRequired
                validate={inputValidations.validateName}
            />
            <SelectInput
                source={getFullSource("port")}
                label="tesseract.sites.dialog.lans.port"
                description="tesseract.sites.dialog.lans.descriptions.port"
                choices={availablePorts}
                optionValue="port"
                optionText="description"
            />
            <TextInput
                source={getFullSource("address")}
                label="tesseract.sites.dialog.lans.address"
                description="tesseract.sites.dialog.lans.descriptions.address"
                validate={inputValidations.validatePrivateIp}
                isRequired
            />
            <TextInput
                source={getFullSource("netmask")}
                label="tesseract.sites.dialog.lans.netmask"
                description="tesseract.sites.dialog.lans.descriptions.netmask"
                validate={inputValidations.validateNetmask}
                isRequired
            />
            {port === "wifi" && (
                <React.Fragment>
                    <Typography className={classes.message} variant="body1">
                        {translate("tesseract.sites.dialog.lans.wifiSectionTitle")}
                    </Typography>
                    <TextInput
                        source={getFullSource("ssid")}
                        label="tesseract.sites.dialog.lans.wifi.ssid"
                        description="tesseract.sites.dialog.lans.descriptions.wifi.ssid"
                        validate={inputValidations.validateWifiSsid}
                        isRequired
                    />
                    <SelectInput
                        source={getFullSource("mode")}
                        label="tesseract.sites.dialog.lans.wifi.securityMode"
                        description="tesseract.sites.dialog.lans.descriptions.wifi.securityMode"
                        choices={wifiEncryptionMode}
                        isRequired
                    />
                    <PasswordConfirmInput
                        source={getFullSource("passphrase")}
                        label="tesseract.sites.dialog.lans.wifi.passphrase"
                        confirmLabel="tesseract.sites.dialog.lans.wifi.passphraseConfirm"
                        description="tesseract.sites.dialog.lans.descriptions.wifi.passphrase"
                        validate={inputValidations.validateWifiPassphrase}
                        isRequired
                    />
                </React.Fragment>
            )}
            <Typography className={classes.message} variant="body1">
                {translate("tesseract.sites.dialog.lans.dhcpSectionTitle")}
            </Typography>
            <SelectInput
                source={getFullSource("dhcp.type")}
                label="tesseract.appliances.settings.dhcp.networks.dhcpRelayType"
                description="tesseract.appliances.settings.dhcp.descriptions.dhcpRelayType"
                choices={filteredDhcpTypeChoices}
                isRequired
            />
            <TextInput
                source={getFullSource("dhcp.description")}
                label="tesseract.sites.dialog.lans.dhcpDescription"
                description="tesseract.sites.dialog.lans.descriptions.dhcpDescription"
                hide={(value, data) => get(data, getFullSource("dhcp.type")) !== "dhcpService"}
            />
            <TextInput
                source={getFullSource("dhcp.firstIp")}
                label="tesseract.sites.dialog.lans.dhcpFirstIp"
                description="tesseract.sites.dialog.lans.descriptions.dhcpFirstIp"
                validate={inputValidations.validateIp}
                hide={(value, data) => get(data, getFullSource("dhcp.type")) !== "dhcpService"}
                isRequired
            />
            <TextInput
                source={getFullSource("dhcp.lastIp")}
                label="tesseract.sites.dialog.lans.dhcpLastIp"
                description="tesseract.sites.dialog.lans.descriptions.dhcpLastIp"
                validate={inputValidations.validateIp}
                hide={(value, data) => get(data, getFullSource("dhcp.type")) !== "dhcpService"}
                isRequired
            />
            <TextInput
                source={getFullSource("dhcp.domain")}
                label="tesseract.sites.dialog.lans.dhcpDomain"
                description="tesseract.sites.dialog.lans.descriptions.dhcpDomain"
                hide={(value, data) => get(data, getFullSource("dhcp.type")) !== "dhcpService"}
            />
        </React.Fragment>
    );
};

export default LanStep;