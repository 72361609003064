import React from "react";
import {Route, Switch} from "react-router";
import GatewayTable from "./GatewayTable";
import GatewayDetails from "./GatewayDetails";

export const Gateways = () => (
    <Switch>
        <Route
            exact
            path="/:account/:workspace/infrastructure/gateways"
            component={GatewayTable}
        />
        <Route
            path="/:account/:workspace/infrastructure/gateways/:id"
            component={GatewayDetails}
        />
    </Switch>
);


export default Gateways;