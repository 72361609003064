import React, {useEffect} from 'react';
import {CrudTypes, useCrudFetch} from '@cuda-react/core';
import {Chip, Menu, MenuItem, ClickAwayListener, Box} from "@barracuda-internal/bds-core";
import {useInfiniteScroll} from '@cuda-react/core/lib/hooks/TableScrollHooks';
import {truncate} from 'lodash';


interface ChipMembersFieldProps {
    resource?: string;
    params?: any;
    data?: any[];
}

const CHIPS_LIMIT = 2;

// fetching +2 because packages/core/src/clients/crudClient.ts:63 subtracts 1
const PAGE_OFFSET = 2;

/**
 * A component that renders a list of chips with a limit of 2 chips,
 * if there are more than 2 chips, a +{number} chip will be displayed
 * Data can be fetched from an API resource or passed as a prop
 * @param {ChipMembersFieldProps} {resource, params, data}
 * @return {*}
 */
export const ChipMembersField = ({resource, params, data}: ChipMembersFieldProps) => {
    const [{data: apiData}, loading, fetch] = useCrudFetch(CrudTypes.GET, resource, params);
    const [allData,setAllData] = React.useState(data);
    const [listRef, setListRef] =  React.useState<Element | null>(null);

    useEffect(() => {
        if(resource) {
            fetch();
            setListRef(null);
            setAllData([]);
        }
    }, [resource, fetch, setAllData, params]);

    useEffect(() => {
        setAllData(data);
    }, [data]);

    const canLoadMoreRows = apiData?.page?.number + 1 <= apiData?.page?.totalPages && !loading;

    const {onScroll} = useInfiniteScroll({
        canLoadMoreRows,
        pageMode: 'dynamic',
        loading,
        loadMoreRows: () => {
            if(resource) {
                fetch({pagination: {page: apiData?.page?.number + PAGE_OFFSET, perPage: 25}});
            }
        }
    });

    useEffect(() => {
        setAllData((previousData:any = []): any => [...previousData, ...apiData?.content ?? []]);
    }, [apiData]);


    return (
        <Box flexWrap="wrap">
            {allData?.slice(0, CHIPS_LIMIT).map((member: {id: string, displayName?: string, name: string}, index: number) => (
                <Chip
                    // eslint-disable-next-line id-length
                    sx={{m: 0.5}}
                    key={member.id}
                    label={truncate(member?.displayName || member.name, {length: 15})}
                    title={member?.displayName || member.name}
                />
            ))}
            {
                (allData?.length ?? 0) > CHIPS_LIMIT && (
                    <>
                        <ClickAwayListener onClickAway={() => setListRef(null)}>
                            <>
                                <Chip
                                    onClick={(event) => setListRef(event.currentTarget)}
                                    // eslint-disable-next-line id-length
                                    sx={{m: 0.5}}
                                    label={`+${(apiData?.page?.totalElements ?? allData?.length) - CHIPS_LIMIT}`}
                                    size="small"
                                />
                                <Menu
                                    anchorEl={listRef}
                                    open={!!listRef}
                                    onClose={() => setListRef(null)}
                                >
                                    <Box maxHeight={400} overflow="auto" onScroll={onScroll}>
                                        {allData?.slice(CHIPS_LIMIT).map((member: {id: string; displayName?: string; name: string;}) => (
                                            <MenuItem key={member.id} disableTouchRipple>
                                                {member?.displayName || member.name}
                                            </MenuItem>
                                        ))}
                                    </Box>
                                </Menu>
                            </>
                        </ClickAwayListener>
                    </>
                )
            }
        </Box>
    );
};