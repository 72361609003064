import React from "react";
import {get, uniqBy} from "lodash";
import {
    BooleanCheckListInput,
    BooleanInput,
    ConnectedFormPage,
    DateField,
    FileInput,
    FormSection,
    getArrayDataContent,
    ReadOnlyInput,
    SelectInput,
    TextField,
    TextInput,
    useCrudProps,
    useMixpanel,
    validateInt,
    validateMaxValueMemo,
    validateMinValueMemo
} from "@cuda-react/core";
import apiResources from "../../../apiResources";
import {parseJson} from "../../../utils/jsonUtils";
import {Typography} from "@barracuda-internal/bds-core";
import {Trans} from "react-i18next";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    introductionText: {
        padding: "8px 8px 32px 24px",
        //@ts-ignore TODO: this theme entry does exist. We should extend DefaultTheme to fix this properly (or BDS should do that).
        color: theme.palette.text.secondary
    }
}));

const Syslog = () => {
    const registerAction = useMixpanel("Syslog");
    const protocols = getArrayDataContent(useCrudProps(apiResources.syslogProtocols)[0]);
    const securityProtocols = getArrayDataContent(useCrudProps(apiResources.syslogSecurityProtocols)[0]);
    const logs = useCrudProps(apiResources.syslogLogs)[0]?.data?.content;
    const groupChoices: any[] = uniqBy(logs?.map((obj: any) => ({key: obj.category, name: obj.category})), "key");
    const categoryChoices = logs?.map((obj: any) => ({...obj, group: obj.category}));
    const disableInput = (key: string, type: string) => (value: any, data: any) => !get(data, key) || get(data, key) !== type || !get(data, "enabled");
    const classes = useStyles();

    const defaultValues = {
        enabled: false,
        port: "514",
        protocol: "udp",
        securityProtocol: "tls_v1_2",
        certificate: {},
        logs: categoryChoices?.filter((obj: any) => obj.group === "SECURITY").map((obj: any) => obj.key)
    };

    return (
        <ConnectedFormPage
            resource={apiResources.settings}
            title="tesseract.logs.pageTitle"
            subtitle="tesseract.syslog.pageTitle"
            onSubmitSuccess={() => {
                registerAction("Update");
            }}
            formatResourceData={(data) => {
                const parsedData = parseJson(data?.settings?.syslog_streaming || "{}");

                if (!parsedData.enabled) {
                    return defaultValues;
                }
                return {...defaultValues, ...parsedData};
            }}
            formatRequestData={(data) => (
                {
                    settings: {
                        syslog_streaming: JSON.stringify({
                            ...data,
                            certificate: get(data, "protocol") === "tcpTls" ? data.certificate.data : undefined,
                            securityProtocol: get(data, "protocol") === "tcpTls" ? data.securityProtocol : undefined
                        })
                    }
                }
            )}
            params={{filter: {type: "virtualWan"}}}
            formatError={(error) => (
                error.settings.syslog_streaming
            )}
            clearOnSuccess
            canReset
        >
            <FormSection title="tesseract.syslog.syslogStreamingTile" newStyle hideBorder>
                <Typography className={classes.introductionText}>
                    <Trans i18nKey="tesseract.syslog.syslogDescription"/>
                </Typography>
                <BooleanInput
                    source="enabled"
                    label="tesseract.syslog.enable"
                    toggleEnabledText="tesseract.settings.tabs.oms.enabled"
                    newStyle
                />
                <TextInput
                    source="server"
                    label="tesseract.syslog.server"
                    disable={(value, data) => !data?.enabled}
                    isRequired
                    newStyle
                    inputLabelProps={{nested: true}}
                />
                <SelectInput
                    source="protocol"
                    label="tesseract.syslog.protocol"
                    choices={protocols}
                    disable={(value, data) => !data?.enabled}
                    isRequired
                    newStyle
                    inputLabelProps={{nested: true}}
                />
                <TextInput
                    source="port"
                    label="tesseract.syslog.port"
                    validate={[validateInt, validateMaxValueMemo(65535), validateMinValueMemo(1)]}
                    disable={(value, data) => !data?.enabled}
                    isRequired
                    newStyle
                    inputLabelProps={{nested: true}}
                />
                <FileInput
                    source="certificate"
                    label="tesseract.syslog.uploadSslCert"
                    disable={disableInput("protocol", "tcpTls")}
                    readType="string"
                    newStyle
                    inputLabelProps={{nested: true}}
                />
                <ReadOnlyInput
                    source="certificate_cname"
                    label="tesseract.syslog.certificateCName"
                    field={TextField}
                    hide={(value) => !value}
                    disable={disableInput("protocol", "tcpTls")}
                    newStyle
                    inputLabelProps={{nested: true}}
                />
                <ReadOnlyInput
                    source="certificate_enddate"
                    label="tesseract.syslog.certificateExpiryDate"
                    field={DateField}
                    hide={(value) => !value}
                    disable={disableInput("protocol", "tcpTls")}
                    newStyle
                    inputLabelProps={{nested: true}}
                />
                <SelectInput
                    source="securityProtocol"
                    label="tesseract.syslog.securityProtocol"
                    choices={securityProtocols}
                    disable={disableInput("protocol", "tcpTls")}
                    newStyle
                    inputLabelProps={{nested: true}}
                />
            </FormSection>
            <BooleanCheckListInput
                source="logs"
                title="tesseract.syslog.logs"
                label=" "
                categoryChoices={categoryChoices ?? []}
                groupChoices={groupChoices ?? []}
                disable={(value: any, data: any) => !data?.enabled}
                isSyslog
                newStyle
            />
        </ConnectedFormPage>
    );
};

export default Syslog;