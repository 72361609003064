import {get} from "lodash";
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material";
import {Trans, useTranslation} from "react-i18next";
import {usei18nCountries} from "@stratos/reporting/lib/utils/countries";
import {getArrayDataContent, SearchableSelect, Select, Text, useCrudProps} from "@cuda-react/core";
import apiResources from "../../apiResources";
import React, {useState} from "react";
import {
    Button,
    ClickAwayListener,
    Collapse,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Typography
} from "@barracuda-internal/bds-core";
import {Close, Filter} from "@barracuda-internal/bds-core/dist/Icons/Core";
import FilterIcon from "@mui/icons-material/FilterAlt";

export const timeframes = [
    {key: "1", name: "tesseract.dashboard.filters.timeframes.lastDay", unit: "hours", duration: "24"},
    {key: "7", name: "tesseract.dashboard.filters.timeframes.lastWeek", unit: "days", duration: "7"},
    {key: "28", name: "tesseract.dashboard.filters.timeframes.lastMonth", unit: "weeks", duration: "4"},
    {key: "90", name: "tesseract.dashboard.filters.timeframes.lastQuarter", unit: "months", duration: "3"}
];

const getStaticChoiceFromFilter = (filterId: string, filterValues: any, optionValue="key"): any[] => {
    const filterValue = get(filterValues, filterId);
    if (filterValue?.key && filterValue?.name) {
        return [{[optionValue]: filterValue?.key, name: filterValue?.name}];
    }
    return [];
};

type FilterOption = {
    component: any,
    source: string,
    label: string,
} & Record<string, any>;

const useStyles = makeStyles((theme: Theme) => createStyles({
    filterContainer: {
        marginBottom: "8px",
        "& > div": {
            marginTop: 0,
            paddingTop: 0
        },
        "& .MuiInputBase-root": {
            background: "none"
        }
    },
    filterContainerTimeAndAppliance: {
        marginRight: "8px",
        marginBottom: "8px",
        "& > div": {
            marginTop: 0,
            paddingTop: 0
        }
    },
    filterField: {
        verticalAlign: "bottom",
        width: 256
    },
    filterRemoveIcon: {
        marginBottom: 2
    },
    clearFilterButton: {
        marginLeft: "8px",
        marginRight: "8px"
    },
    filterBar: {
        position: "relative",
        display: "block",
        marginRight: "auto",
        marginLeft: "12px",
        marginBottom: "8px",
        marginTop: "8px"
    },
    buttonLabel: {
        whiteSpace: "nowrap"
    },
    filterButtons: {
        display: "flex",
        flexDirection: "row-reverse"
    },
    list: {},
    filterMessage: {
        alignContent: "center",
        padding: "0px 8px",
        textWrap: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        flexShrink: 1
    }
}));

export default (
    activeFilters: Record<string, any>,
    updateFilter: (value: any, id: string) => void,
    removeFilter: (id: string) => void,
    clearAllFilters: () => void,
    appliances: {key: string, name: string, type?: string}[],
    disabled: boolean,
    hideAppliance?: boolean
) => {
    const [translate] = useTranslation();
    // Generate options for filters
    const riskLevelChoices = ["0","1","2","3","4"].map((riskLevel) => ({
        id: riskLevel,
        name: translate("tesseract.dashboard.filters.riskLevelChoice", {context: riskLevel})
    }));
    const getCountryNameByCode = usei18nCountries();
    const geoLocations = getArrayDataContent(useCrudProps(apiResources.geoLocations, {}, {cache: true})[0]?.data).map((countryCode) => ({
        name: getCountryNameByCode(countryCode),
        key: countryCode
    }));
    const sdwanCategories = getArrayDataContent(useCrudProps(apiResources.sdwanAppCategories)[0]);
    const visibleFilters = Object.keys(activeFilters);
    const [filterListOpen, setFilterListOpen] =  useState<Element | null>(null);
    const classes = useStyles();
    const unsupportedAppliance = appliances.length < 1 || appliances[0]?.key === "_UNSUPPORTED_";
    const resolveDisabled = disabled || appliances.length < 1 || appliances[0]?.key === "_NONE_" || unsupportedAppliance;
    const appliancesWithUniqueIds = appliances.map((appliance) => ({...appliance, uniqueId: `${appliance.key}/${appliance.type}`}));

    const isTimeFrameOrApplianceFilter = (source: string) => source === "applianceId" || source === "timeframe";
    const renderFilter = (targetFilter: FilterOption) => {
        const {source, component, ...targetFilterProps} = targetFilter;
        const value = activeFilters?.[source]?.key || activeFilters?.[source] || "";
        return (
            <Grid className={isTimeFrameOrApplianceFilter(source) ? classes.filterContainerTimeAndAppliance : classes.filterContainer}>
                {React.createElement<any>(component, {
                    id: "cuda-filter-input-" + source.replace(/[^a-zA-Z0-9]/g, "-"),
                    onChange: (eventOrValue: any) => {
                        const value = (eventOrValue?.target) ? eventOrValue.target.value : eventOrValue;
                        updateFilter(value, source);
                    },
                    value,
                    variant: "outlined",
                    className: classes.filterField,
                    disabled: resolveDisabled,
                    ...targetFilterProps,
                })}
                {!isTimeFrameOrApplianceFilter(source) ?
                    <IconButton
                        size="small"
                        className={classes.filterRemoveIcon}
                        onClick={() => removeFilter(source)}
                    >
                        <Close/>
                    </IconButton> : null
                }
            </Grid>
        );
    };

    const filters: FilterOption[] = [
        {
            component: Select,
            source: "timeframe",
            label: "tesseract.dashboard.filters.timeframe",
            choices: timeframes,
        },
        {
            component: Select,
            source: hideAppliance ? "_HIDE_" : "applianceId",
            label: "tesseract.dashboard.filters.appliance",
            choices: appliancesWithUniqueIds,
            optionValue: "uniqueId"
        },
        {
            component: SearchableSelect,
            source: "applicationDataPoints",
            label: "tesseract.dashboard.filters.application",
            options: {
                label: "tesseract.dashboard.filters.application"
            },
            staticChoices: getStaticChoiceFromFilter("applicationDataPoints", activeFilters, "id"),
            resource: apiResources.applications,
            optionValue: "id",
            filterKey: "applicationId",
            clearOnFocus: true
        },
        {
            component: Select,
            source: "sdwanCategoryDataPoints",
            label: "tesseract.dashboard.filters.sdwanCategory",
            choices: sdwanCategories
        },
        {
            component: Text,
            source: "domainDataPoints",
            label: "tesseract.dashboard.filters.destinationDomain"
        },
        {
            component: SearchableSelect,
            source: "geoDestinationDataPoints",
            label: "tesseract.dashboard.filters.geoDestination",
            options: {
                label: "tesseract.dashboard.filters.geoDestination"
            },
            choices: geoLocations,
            clearOnFocus: true
        },
        {
            component: SearchableSelect,
            source: "geoSourceDataPoints",
            label: "tesseract.dashboard.filters.geoSource",
            options: {
                label: "tesseract.dashboard.filters.geoSource"
            },
            choices: geoLocations,
            clearOnFocus: true
        },
        {
            component: SearchableSelect,
            source: "ipsDataPoints",
            label: "tesseract.dashboard.filters.ipsEvent",
            options: {
                label: "tesseract.dashboard.filters.ipsEvent"
            },
            staticChoices: getStaticChoiceFromFilter("ipsDataPoints", activeFilters, "id"),
            resource: apiResources.ipsExploits,
            optionValue: "id",
            clearOnFocus: true
        },
        {
            component: Select,
            source: "atdDataPoints",
            label: "tesseract.dashboard.filters.malwareFile",
            choices: getStaticChoiceFromFilter("atdDataPoints", activeFilters),
            hidden: true
        },
        {
            component: Select,
            source: "riskDataPoints",
            label: "tesseract.dashboard.filters.riskLevel",
            optionValue: "id",
            choices: riskLevelChoices
        },
        // TODO: Removing URL Category searchable select input for now until the API is fixed, just using hidden select input.
        // {
        //     component: SearchableSelect,
        //     source: "urlCategoryDataPoints",
        //     label: "tesseract.dashboard.filters.urlCategory",
        //     options: {
        //         label: "tesseract.dashboard.filters.urlCategory"
        //     },
        //     resource: apiResources.urlFilterCategories,
        //     hidden: true
        // },
        {
            component: Select,
            source: "urlCategoryDataPoints",
            label: "tesseract.dashboard.filters.urlCategory",
            choices: getStaticChoiceFromFilter("urlCategoryDataPoints", activeFilters),
            hidden: true
        },
        // TODO: Removing this filter completely until it is fixed on the platform
        // {
        //     component: SearchableSelect,
        //     source: "userDataPoints",
        //     label: "tesseract.dashboard.filters.user",
        //     staticChoices: [{"id": "#", "name": "Not set"}],
        //     options: {
        //         label: "tesseract.dashboard.filters.user",
        //     },
        //     resource: apiResources.users,
        //     optionValue: "id"
        // },
    ];

    const filterButtons = (
        <React.Fragment>
            <Grid className={classes.filterButtons}>
                <Typography className={classes.filterMessage}>
                    <Trans i18nKey={`tesseract.dashboard.filters.${resolveDisabled ? (unsupportedAppliance? "unsupportedFilters" : "disabledFilters") : "activeFilters"}`}>
                        <FilterIcon color="primary" sx={{margin: "-6px -2px -6px -2px"}} />
                    </Trans>
                </Typography>
                {visibleFilters.length > 2 ? (
                    <Button
                        onClick={() => clearAllFilters()}
                        startIcon={<Close/>}
                        bdsType="interactiveSubtle"
                        size="small"
                        className={classes.clearFilterButton}
                        classes={{root: classes.buttonLabel}}
                    >
                        {translate("tesseract.dashboard.filters.resetFilter")}
                    </Button>
                ) : null}
                <ClickAwayListener onClickAway={() => setFilterListOpen(null)}>
                    <React.Fragment>
                        <Button
                            startIcon={<Filter/>}
                            bdsType="interactiveNeutral"
                            size="small"
                            onClick={(event) => setFilterListOpen(event.currentTarget)}
                            disabled={resolveDisabled || visibleFilters.length >= filters.length}
                        >
                            {translate("tesseract.dashboard.filters.addFilter")}
                        </Button>
                        <Menu
                            className={classes.list}
                            anchorEl={filterListOpen}
                            open={!!filterListOpen}
                            onClose={() => setFilterListOpen(null)}
                        >
                            {filters
                                .filter((filter) => !visibleFilters.includes(filter.source) && !filter.hidden)
                                .map((filter) => (
                                    <MenuItem
                                        key={filter.source}
                                        onClick={() => updateFilter("", filter.source)}
                                    >
                                        {filter.label && translate(filter.label)}
                                    </MenuItem>
                                ))}
                        </Menu>
                    </React.Fragment>
                </ClickAwayListener>
            </Grid>
        </React.Fragment>
    );

    const activeFilterInputs = (
        <Collapse
            className={classes.filterBar}
            in
        >
            <Grid container spacing={1}>
                {filters.filter((filter) => visibleFilters.includes(filter.source)).map(renderFilter)}
                {visibleFilters.length > 2 || resolveDisabled ? null : (
                    <Typography className={classes.filterMessage} sx={{marginTop: -1}}>
                        <Trans i18nKey="tesseract.dashboard.filters.addFilterMessage" />
                    </Typography>
                )}
            </Grid>
        </Collapse>
    );

    return {filterButtons, activeFilterInputs};
};