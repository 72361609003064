import {CrudParams, CrudTypes} from "../../clients";
import {getArrayDataContent} from "../../utils";
import {useCrudProps, useCrudSubscription} from "../CrudHooks";
import {useDeepCompareEffect} from "../UtilHooks";
import Highcharts, {Chart, SeriesMappointOptions} from "highcharts";
import "highcharts/modules/map";
import "highcharts/modules/marker-clusters";
import {useEffect, useRef, useState} from "react";
import proj4 from "proj4";
import {get, merge} from "lodash";
import {useTheme} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useStatusAvatar} from "../CardHooks";
import {parseData} from "./parseData";
import {generateOptions} from "./generateOptions";
import {CustomMapPointOrCluster, zoomToFit} from "./zoomToFit";
import {clearLinkedSeries} from "./clearLinkedSeries";
import {CustomMapPoint} from "./getLinkedPoints";

/** Need to extend SeriesMappointOptions, as we add originalData, to pass around to later callbacks */
export type ExtendedSeriesMappointOptions = /*SeriesMappointOptions &*/ {
    originalData?: SeriesMappointOptions["data"],
    label?: string
};

/**
 * definition of how each map data series should be displayed, and how it can be extracted from the supplied data.
 */
export interface LocationMapSeriesDefinition {
    /** color the color of the points. */
    color?: string,
    /** if provided, when a series is clicked on a tooltip will be displayed, with a ListCardContent content. */
    details?: {
        /** array of children to pass to the ListCardContent. Each child will be cloned and passed "data", which is the data associated with this point. */
        fields: React.ReactElement[],
        /** a "source" dot-path for a value to use as the "id" param when performing a CRUD for further data. Only used if details.resource is provided. */
        optionValue?: string,
        /**  a CRUD resource to fetch data from and merge with the data provided for the point. */
        resource?: string
    },
    /** function passed to Array.filter() to filter the provided data, returning true only for those entries that belong to this series. The data associated with the point is available at ".pointData" */
    filter?: (seriesEntry: any, index: number) => boolean,
    /** series label, which will be displayed in the legend (if enabled) . */
    label?: string,
    /** an index to determine the order in which this series will appear in the legend. by default they will appear in the order defined by the pointTypes array. */
    legendIndex?: number,
    /** if set, whenever an entry in this series is clicked, links will be shown connected it to all its "linked" entries. Another series' entry is "linked" to the clicked target entry in this series if either: it's data was collected via a "source" property on the target entry; OR the target entry was collected via a "source" property on the other series' entry. */
    linkedSeries?: {
        /** the color to use when highlighting the linked entries. */
        color?: string,
        /** the label to use in the legend to describe the linked entries. */
        label?: string,
        /** the zIndex to use for the highlighted linked entries. */
        zIndex?: number,
        /** the zIndex to use for the highlighted clicked. */
        selectedZIndex?: number,
        /** the color to use for the highlighted clicked. */
        selectedColor?: string,
    },
    /** the data to use for this series, instead of the main data. */
    data?: any[],
    /** a "source" property from each of the main resource/data's entries to use to create this series. All matching values will be concatenated to create this series. */
    source?: string,
    /** the zIndex to use for this series data points */
    zIndex?: number
}

/**
 * Hook for creating a Locations Map. Simply pass in your props.
 */
export interface UseLocationMapsProps {
    /** definition of how each map data series should be displayed, and how it can be extracted from the supplied data. */
    series: LocationMapSeriesDefinition[],
    /** CRUD resource from which to collect map data. This can also be provided per series. */
    resource?: string,
    /** local data to use, instead of CRUD collected data. */
    data?: any[],
    /** if true, map will not auto-zoom to fit content when collected data is first rendered. */
    disableZoomOnLoad?: boolean,
    /** CRUD params to pass to all CRUD requests. */
    params?: CrudParams,
    /** if false, the map legend is not rendered (default is true). */
    legendEnabled?: boolean,
    /** callback called whenever the global status changes. This is usually provided by TabbedCard. */
    updateState?: (status: string) => void,
    /** A dot-path key to a property in the returned data that defines the overall status of the data. This status is then used to call the updateState callback (if provided). */
    statusAvatarSource?: string,
    /** A dot-path key to a property in the returned data that defines the overall status of the data. This status is then used to call the updateState callback (if provided).  */
    statusAvatarAggregateSource?: string,
    /** if false, the table is not updated/rendered to the mapRef component, however CRUD requests are still made. */
    visible?: boolean,
    /** if false, a small random number (b/w 0 and 0.001) is added to the location co-ordinates so that coincident sites/gateways are distinguishable on zooming */
    noRandom?: boolean,
    /** optionally override any chart setting */
    chartOverrides?: any
}

export type LocationTooltip = null | { element: HTMLAnchorElement | null, point?: CustomMapPoint };
export type SetLocationTooltip = (tooltip: LocationTooltip) => void;
export type LocationTooltipRef = React.MutableRefObject<LocationTooltip>;
export type ChartRef = React.MutableRefObject<Chart | undefined>;

/**
 * The map rendering items. Be sure to pass the mapRef to the div component you wish the map to be rendered into.
 *
 * @type {object}
 * @param {htmlElement} tooltipAnchor
 * @param {object} tooltip
 * @param {func} setTooltip
 * @param {object} pointResourceData the data associated with the currently selected point (if any).
 * @param {object} seriesDetails the data associated with the series of the currently selected point (if any).
 * @param {ref} mapRef react ref to pass to the div component you want to render the map in.
 */
export interface UseLocationMapsReturn {
    /** the selected mappoint element that the tooltip is attached to. */
    tooltipAnchor: HTMLAnchorElement | null,
    /** the point data for selected point. */
    tooltip?: LocationTooltip,
    /** callback to update the tooltip. Provide (null) or ({element: null}) to close the tooltip. */
    setTooltip: SetLocationTooltip,
    pointResourceData: any,
    seriesDetails: any,
    mapRef: React.MutableRefObject<HTMLDivElement | null>
}

/**
 * Hook for creating a Locations Map.
 */
export const useLocationsMap = ({
                                    series,
                                    resource,
                                    data,
                                    disableZoomOnLoad,
                                    params,
                                    statusAvatarSource,
                                    statusAvatarAggregateSource,
                                    updateState,
                                    legendEnabled = true,
                                    visible = true,
                                    noRandom,
                                    chartOverrides
                                }: UseLocationMapsProps): UseLocationMapsReturn => {
    const mapRef = useRef<HTMLDivElement | null>(null);
    const chart = useRef<Chart>();
    const tooltipRef = useRef<LocationTooltip>(null);
    const theme = useTheme();
    const [translate, {options: {resources}, language}] = useTranslation();
    const [tooltipAnchor, setTooltipAnchor] = useState<HTMLAnchorElement | null>(null);
    const setTooltip: SetLocationTooltip = (tooltip) => {
        if (!tooltip) {
            setTooltipAnchor(null);
            tooltipRef.current = null;
        } else {
            tooltip.element !== undefined && setTooltipAnchor(tooltip.element);
            tooltipRef.current = merge({}, tooltipRef.current, tooltip);
        }
    };

    const [crudData, loading] = useCrudSubscription(CrudTypes.GET, resource, params);
    const resolvedData = resource ? {data: crudData?.data} : {data};

    // -1 accounts for the existing map series
    const selectedPointType: any = get(tooltipRef.current, "point.pointData.series");
    const seriesDetails = selectedPointType && selectedPointType.details;
    const pointResourceId = get(tooltipRef.current, `point.pointData.${seriesDetails && seriesDetails.optionValue}`);
    const pointResourceData = useCrudProps(
        seriesDetails && seriesDetails.resource,
        pointResourceId && {id: pointResourceId}
    )[0]?.data;

    // Handle status reporting
    useStatusAvatar(
        resolvedData,
        getArrayDataContent(resolvedData?.data).flatMap((value) => value.data),
        loading,
        statusAvatarSource,
        statusAvatarAggregateSource,
        updateState
    );

    // On mount, check if proj4 exists in global scope, and add it if it is missing.
    // On unmount, destroy the chart.
    useEffect(() => {
        if (typeof window !== "undefined") {
            window.proj4 = window.proj4 || proj4;
        }

        return () => {
            chart.current && chart.current.destroy();
        };
    }, []);

    // Generate, or redraw the map if the data, pointtypes, or visible state has changed
    useDeepCompareEffect(() => {
        if (visible) {
            const filteredDataPoints = parseData(series || [], resolvedData, translate, noRandom) as any;
            if (chart.current) {
                clearLinkedSeries(chart, setTooltip, false);
                if (!disableZoomOnLoad) {
                    // @ts-ignore, should we be setting to null here? Rather than undefined?
                    chart.current.xAxis && chart.current.xAxis[0].setExtremes(null, null, false);
                    // @ts-ignore, should we be setting to null here? Rather than undefined?
                    chart.current.yAxis && chart.current.yAxis[0].setExtremes(null, null, false);
                }
                filteredDataPoints.forEach((dataPoint: any, index: number) => {
                    chart.current?.series[index + 1]?.update(
                        {
                            ...dataPoint,
                            name: dataPoint.label,
                            type: "mappoint",
                            data: dataPoint.data,
                            originalData: dataPoint.data
                        } as any,
                        true
                    );
                });
                chartOverrides && chart.current.update(chartOverrides, true);
                chart.current.reflow();

                if (!disableZoomOnLoad) {
                    const allPoints = chart.current.series
                        .filter((series) => series.userOptions && series.userOptions.type === "mappoint")
                        .flatMap((series) => series.points as CustomMapPointOrCluster[]);
                    allPoints && zoomToFit(chart, allPoints, true);
                }
            } else {
                const options = generateOptions(
                    chart,
                    theme,
                    legendEnabled,
                    filteredDataPoints,
                    setTooltip,
                    tooltipRef,
                    translate,
                    chartOverrides
                );

                // @ts-ignore Not sure about this one, it says "Map" does not exist on Highcharts, although it clearly must do...
                chart.current = new Highcharts["Map"](mapRef.current, options);
            }
        }
    }, [series, crudData, visible, resources, language, chartOverrides]);

    return {tooltipAnchor, tooltip: tooltipRef.current, setTooltip, pointResourceData, seriesDetails, mapRef};
};