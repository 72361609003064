import {Button, CircularProgress, TextField, Typography} from "@barracuda-internal/bds-core";
import {InputAdornment} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {DialogBody,
    Toolbar,
    useGlobalParam,
    useMixpanel
,useCrudProps} from "@cuda-react/core";
// @ts-ignore There is no @types module for this
import hexGenerator from "hex-generator";
import React, {useEffect} from "react";
import {Trans, useTranslation} from "react-i18next";
import apiResources from "../../../../apiResources";
import CopyToClipboardButton from "../../../../components/CopyToClipboardButton";

export const useStyles = makeStyles((theme) => ({
    dialogContent: {
        maxWidth: 400,
        padding: "8px"
    },
    inputRoot: {
        width: "calc(100% - 64px)",
        margin: "16px 32px 8px",
        "& .MuiOutlinedInput-multiline": {
            height: "inherit"
        }
    },
    completeMessage: {
        margin: "8px"
    }
}));

interface CreateLocationsTokenContentProps {
    onClose?: () => void,
    uuid?: number,
    name?: string
}

export const CreateLocationTokenContent: React.FC<CreateLocationsTokenContentProps> = ({uuid, name, onClose}) => {
    useMixpanel("Locations", "Create Location Token");
    const [translate] = useTranslation();
    const classes = useStyles();
    const demo = useGlobalParam("userData.mode")[0] === "demo";
    const [data, , fetchToken] = useCrudProps(!demo ? apiResources.locationAgentLinkingCode : undefined, {uuid});
    const token = React.useMemo(() => demo ? hexGenerator(128) : data?.data?.content, [demo, data]);

    useEffect(() => {
        demo || fetchToken();
    }, [demo]);

    return (
        <React.Fragment>
            <DialogBody
                classes={{dialogContent: classes.dialogContent}}
                title="tesseract.network.locations.dialog.token.dialogTitle"
                onClose={onClose}
            >
                <Typography variant="body1" className={classes.completeMessage}>
                    <Trans i18nKey="tesseract.network.locations.dialog.token.message" values={{name}}>
                        <a
                            href="https://s3.amazonaws.com/bcs-files-production/ddns/Dynamic%20IP%20Updater.msi"
                            target="_blank"
                            rel="noreferrer"
                        />
                    </Trans>
                </Typography>
                <TextField
                    id="sec-otp"
                    className={classes.inputRoot}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">
                            {token ? (
                                <CopyToClipboardButton
                                    value={token || ""}
                                    iconOnly
                                />
                            ) : (
                                <CircularProgress size={24}/>
                            )}
                        </InputAdornment>
                    }}
                    fullWidth
                    label={translate("tesseract.network.locations.dialog.token.subLabel")}
                    value={token || ""}
                    disabled={!token}
                    variant="outlined"
                />
            </DialogBody>
            <Toolbar>
                <Button variant="contained" color="primary" onClick={onClose}>
                    {translate("cuda.notification.ok")}
                </Button>
            </Toolbar>
        </React.Fragment>
    );
};


export default CreateLocationTokenContent;