import React from "react";
import Grid from "@mui/material/Grid";
import {Link, Typography} from "@barracuda-internal/bds-core";
import {Trans, useTranslation} from "react-i18next";
import {CustomField} from "@cuda-react/core";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    siteCreated: {
        textAlign: "center",
        marginBottom: "16px"
    },
    message: {
        marginTop: "8px",
        marginBottom: "8px"
    },
    serial: {
        textAlign: "center",
        paddingTop: 12
    },
    warning: {
        //@ts-ignore TODO: this theme entry does exist. We should extend DefaultTheme to fix this properly (or BDS should do that).
        color: theme.palette.warning.main
    },
}));

interface SiteResultStepProps {
    data?: any
}

export const SiteResultStep: React.FC<SiteResultStepProps> = ({data}) => {
    const classes = useStyles();
    const [translate] = useTranslation();

    return (
        <CustomField
            source="serials"
            data={data}
            render={(value) => {
                const valueAsArray = value && (Array.isArray(value) ? value : [value]);
                return (
                    <Grid container direction="column">
                        <Grid item>
                            <Typography className={classes.siteCreated} variant="h5">
                                {translate("tesseract.sites.dialog.result.siteCreated")}
                            </Typography>
                            <Typography className={classes.message} variant="body1">
                                <Trans i18nKey="tesseract.sites.dialog.result.connectPortSc"
                                       count={valueAsArray.length}>
                                    <strong/>
                                </Trans>
                            </Typography>
                            <Typography className={classes.message} variant="body1">
                                <Trans i18nKey="tesseract.sites.dialog.result.campus">
                                    <Link href="https://campus.barracuda.com/doc/98224805/" target="_blank"
                                          rel="noopener"/>
                                </Trans>
                            </Typography>
                        </Grid>
                        <Grid item>
                            {valueAsArray.map((appliance: any) => (
                                <Typography variant="h6" className={classes.serial} key={appliance.serial}>
                                    {translate("tesseract.sites.dialog.result.serial", {serial: appliance.serial})}
                                </Typography>
                            ))}
                        </Grid>
                    </Grid>
                );
            }}
        />
    );
};


export default SiteResultStep;