import {Button, CircularProgress, IconButton, TextField, Typography} from "@barracuda-internal/bds-core";
import {InputAdornment} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {Assignment} from "@barracuda-internal/bds-core/dist/Icons/Core";
import {
    CrudTypes,
    DialogBody,
    getDataContent,
    Toolbar,
    useCrudFetch,
    useGlobalParam,
    useMixpanel
} from "@cuda-react/core";
// @ts-ignore this module doesn't have and @types.
import hexGenerator from "hex-generator";
import React, {useEffect, useRef} from "react";
import {Trans, useTranslation} from "react-i18next";
import apiResources from "../../../../apiResources";

export const useStyles = makeStyles((theme) => ({
    dialogContent: {
        maxWidth: 400,
        padding: "16px"
    },
    inputRoot: {
        width: "calc(100% - 64px)",
        margin: "16px 32px 8px",
        "& .MuiOutlinedInput-multiline": {
            height: "inherit"
        }
    },
    completeMessage: {
        margin: "8px"
    }
}));

interface CreateGatewayContentProps {
    create?: boolean;
    id?: number | string;
    onClose?: () => void;
    title?: string;
    label?: string;
}

export const CreateGatewayContent: React.FC<CreateGatewayContentProps> = ({onClose, title}) => {
    useMixpanel("Gateways", "Create Gateway Token");
    const [translate] = useTranslation();
    const classes = useStyles();
    const demo = useGlobalParam("userData.mode")[0] === "demo";
    const [data, , fetchToken] = useCrudFetch(CrudTypes.CREATE, apiResources.registerGateway);
    const token = demo
        ? React.useMemo(() => hexGenerator(128), [])
        : (getDataContent(data) || {}).otp;
    const textAreaRef = useRef<HTMLInputElement|null>(null);

    useEffect(() => {
        demo || fetchToken();
    }, []);

    return (
        <React.Fragment>
            <DialogBody classes={{dialogContent: classes.dialogContent}} title={title} onClose={onClose}>
                <Typography variant="body1" className={classes.completeMessage}>
                    <Trans i18nKey="tesseract.gateways.dialog.result.message">
                        <a
                            href="https://portal.azure.com/#create/barracudanetworks.barracuda_cloudgenwan_gatewayvirtualwan_gateway_saas"
                            rel="noreferrer noopener"
                            target="_blank"
                        />
                    </Trans>
                </Typography>
                <TextField
                    id="gateway-otp"
                    className={classes.inputRoot}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">
                            {token ? (
                                <IconButton onClick={() => {
                                    textAreaRef.current?.select();
                                    document.execCommand("copy");
                                    textAreaRef.current?.setSelectionRange(0, 0);
                                }}>
                                    <Assignment/>
                                </IconButton>
                            ) : (
                                <CircularProgress size={24}/>
                            )}
                        </InputAdornment>
                    }}
                    fullWidth
                    inputRef={textAreaRef}
                    label={translate("tesseract.gateways.dialog.result.managedAppText")}
                    value={token || ""}
                    disabled={!token}
                    variant="outlined"
                />
                <Typography variant="body1" className={classes.completeMessage}>
                    {translate("tesseract.gateways.dialog.result.managedAppOtp")}
                </Typography>
            </DialogBody>
            <Toolbar>
                <Button variant="contained" bdsType="interactiveEmphasis" onClick={onClose}>
                    {translate("cuda.notification.ok")}
                </Button>
            </Toolbar>
        </React.Fragment>
    );
};

export default CreateGatewayContent;