import {Route, Switch} from "react-router";
import React from "react";
import FirewallsTable from "./firewalls/FirewallsTable";
import AzureMonitor from "./settings/AzureMonitor";
import Xdr from "./xdr/Xdr";
import IpsecV2 from "./ipsecv2/Ipsecv2";
import ATRConfiguration from "./atrConfiguration/ATRConfiguration";

export const Integration = () => (
    <Switch>
        <Route path="/:account/:workspace/integration/firewalls">
            <FirewallsTable/>
        </Route>
        <Route path="/:account/:workspace/integration/settings">
            <AzureMonitor/>
        </Route>
        <Route path="/:account/:workspace/integration/xdr">
            <Xdr/>
        </Route>
        <Route path="/:account/:workspace/integration/atrConfiguration">
            <ATRConfiguration/>
        </Route>
        <Route path="/:account/:workspace/integration/ipsecV2">
            <IpsecV2/>
        </Route>
    </Switch>
);

export default Integration;