import React from "react";
import CreateGatewayContent from "./CreateGatewayContent";
import CreateTVTContent from "../../../../components/appliances/create/CreateTVTContent";
import CreateManagedGatewayContent from "./CreateManagedGatewayContent";
import {Dialog} from "@barracuda-internal/bds-core";

interface CreateGatewayDialogProps {
    onSuccess?: any,
    onClose?: any,
    currentDialog?: "cloud" | "on-prem" | "managed" | null
}

const CreateGatewayDialog: React.FC<CreateGatewayDialogProps> = ({currentDialog, onSuccess, onClose}) => (
    <Dialog
        open={!!currentDialog}
        sx={currentDialog === "managed" ? {
            '& .MuiPaper-root': {
                width: "100%",
                maxWidth: '712px'
            }
        } : currentDialog === "on-prem" ? {
            '& .MuiPaper-root': {
                width: '100%',
                maxWidth: '882px'
            }
        } : {
            '& .MuiPaper-root': {
                width: "100%",
                maxWidth: '420px'
            }
        }}
        disableEnforceFocus
    >
        {currentDialog === "managed" && (
            <CreateManagedGatewayContent
                title="tesseract.gateways.dialog.create.managed.dialogTitle"
                label="tesseract.gateways.dialog.create.managed.menuLabel"
                onSuccess={onSuccess}
                onClose={onClose}
            />
        )}
        {currentDialog === "cloud" && (
            <CreateGatewayContent
                create
                title="tesseract.gateways.dialog.create.cloud.dialogTitle"
                label="tesseract.gateways.dialog.create.cloud.menuLabel"
                onClose={onClose}
            />
        )}
        {currentDialog === "on-prem" && (
            <CreateTVTContent
                type="gateway"
                title="tesseract.gateways.dialog.create.onPrem.dialogTitle"
                label="tesseract.gateways.dialog.create.onPrem.menuLabel"
                onSuccess={onSuccess}
                onClose={onClose}
            />
        )}
    </Dialog>
);


export default CreateGatewayDialog;