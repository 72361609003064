import React from "react";
import {Typography} from "@barracuda-internal/bds-core";
import {getArrayDataContent, SelectInput, TextInput, useCrudProps} from "@cuda-react/core";
import {useSiteInputValidations} from "../../../../../hooks/createEditSiteHooks";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@mui/styles";
import {useWatch} from "react-hook-form";
import apiResources from "../../../../../apiResources";
import {get} from "lodash";

const useStyles = makeStyles((theme) => ({
    message: {
        marginTop: "8px",
        marginBottom: "8px"
    },
}));

interface LanStepProps {
    stepIndex?: number,
    portsData?: []
}

export const LanStep: React.FC<LanStepProps> = ({stepIndex = 0}) => {
    const getFullSource = (source: any) => "lans[" + stepIndex + "]." + source;
    const model = useWatch({name: "serials.0.model"});
    const portsData = getArrayDataContent(useCrudProps(apiResources.modelPorts, {id: model})[0]?.data);
    const inputValidations = useSiteInputValidations();
    const [translate] = useTranslation();
    const classes = useStyles();
    const dhcpTypeChoices = getArrayDataContent(useCrudProps(apiResources.siteConfigurationTypes)[0]?.data);

    return (
        <React.Fragment>
            <Typography className={classes.message} variant="body1">
                {translate("tesseract.appliances.dialog.lans.setup", {step: stepIndex + 1})}
            </Typography>
            <TextInput
                source={getFullSource("name")}
                label="tesseract.appliances.dialog.lans.name"
                description="tesseract.appliances.dialog.lans.descriptions.name"
                isRequired
                validate={inputValidations.validateName}
            />
            <SelectInput
                source={getFullSource("port")}
                label="tesseract.appliances.dialog.lans.port"
                description="tesseract.appliances.dialog.lans.descriptions.port"
                choices={portsData}
                optionValue="port"
                optionText="description"
            />
            <TextInput
                label="tesseract.appliances.dialog.lans.virtualLanId"
                source={getFullSource("virtualLanId")}
                description="tesseract.appliances.dialog.lans.descriptions.virtualLanId"
                validate={inputValidations.validateVlanId}
            />
            <TextInput
                source={getFullSource("address")}
                label="tesseract.appliances.dialog.lans.address"
                description="tesseract.appliances.dialog.lans.descriptions.address"
                validate={inputValidations.validatePrivateIp}
                isRequired
            />
            <TextInput
                source={getFullSource("netmask")}
                label="tesseract.appliances.dialog.lans.netmask"
                description="tesseract.appliances.dialog.lans.descriptions.netmask"
                validate={inputValidations.validateNetmask}
                isRequired
            />
            <SelectInput
                source={getFullSource("dhcp.type")}
                label="tesseract.appliances.dialog.lans.dhcpRelayType"
                description="tesseract.appliances.dialog.lans.descriptions.dhcpRelayType"
                choices={dhcpTypeChoices}
                isRequired
            />
            <TextInput
                source={getFullSource("dhcp.description")}
                label="tesseract.appliances.dialog.lans.dhcpDescription"
                description="tesseract.appliances.dialog.lans.descriptions.dhcpDescription"
                hide={(value, data) => get(data, getFullSource("dhcp.type")) !== "dhcpService"}
            />
            <TextInput
                source={getFullSource("dhcp.firstIp")}
                label="tesseract.appliances.dialog.lans.dhcpFirstIp"
                description="tesseract.appliances.dialog.lans.descriptions.dhcpFirstIp"
                validate={inputValidations.validateIp}
                hide={(value, data) => get(data, getFullSource("dhcp.type")) !== "dhcpService"}
                isRequired
            />
            <TextInput
                source={getFullSource("dhcp.lastIp")}
                label="tesseract.appliances.dialog.lans.dhcpLastIp"
                description="tesseract.appliances.dialog.lans.descriptions.dhcpLastIp"
                validate={inputValidations.validateIp}
                hide={(value, data) => get(data, getFullSource("dhcp.type")) !== "dhcpService"}
                isRequired
            />
            <TextInput
                source={getFullSource("dhcp.domain")}
                label="tesseract.appliances.dialog.lans.dhcpDomain"
                description="tesseract.appliances.dialog.lans.descriptions.dhcpDomain"
                hide={(value, data) => get(data, getFullSource("dhcp.type")) !== "dhcpService"}
            />
        </React.Fragment>
    );
};


export default LanStep;