import React from "react";
import {Boolean, Input} from "@cuda-react/core";
import {Typography} from "@barracuda-internal/bds-core";
import {Trans} from "react-i18next";
import {Theme} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {InputProps} from "@cuda-react/core/lib/components/inputs/Input/Input";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        textAlign: "left",
        width: "calc(100% - 4px)",
        display: "inline-flex",
        minHeight: 40,
        margin: "10px 0",
    },
    input: {
        width: 80,
        flexShrink: 0
    },
    inputLabelSection: {
        display: "flex",
        flexDirection: "column"
    },
    inputLabel: {
        color: theme.palette.text.secondary,
        lineHeight: "19px",
        fontWeight: "bold",
    },
    inputAdditionalInfo: {
        maxWidth: "100%",
        fontSize: 12,
        lineHeight: "18px",
        marginTop: 4
    }
}));

export interface BooleanInputProps extends Omit<InputProps<typeof Boolean>, "component" | "noLabel"> {
    additionalInfoLabel?: string
}

/**
 * This is a version of the Boolean basic input that can be used seemlessly with the Form and Wizards.
 *
 * All props provided are passed to Input and Boolean.
 *
 * See [Input](/?path=/docs/core-components-inputs-input--input) for more information on the props required for Input.
 *
 * See [Boolean](/?path=/docs/core-components-inputs-basic-boolean--boolean) for props required for Boolean. Note that state related props
 * (such as "value" and "onChange") will already be provided by the Input component.
 */
export const BooleanInput = (props: BooleanInputProps) => {
    const {label, additionalInfoLabel} = props;
    const classes = useStyles();

    return (
        <div
            className={classes.root}
            id="cuda-react-input"
        >
            <div className={classes.input}>
                <Input {...props} component={Boolean} noLabel/>
            </div>
            <div className={classes.inputLabelSection}>
                <Typography variant="body1" className={classes.inputLabel}>
                    <Trans i18nKey={label}/>
                </Typography>
                {additionalInfoLabel ?
                    <Typography variant="body1" className={classes.inputAdditionalInfo}>
                        <Trans i18nKey={additionalInfoLabel}>
                            <br/>
                        </Trans>
                    </Typography> : null
                }
            </div>
        </div>
    );
};

export default BooleanInput;