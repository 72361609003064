import {CardHeader, Divider} from "@barracuda-internal/bds-core";
import React from "react";
import {Theme} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import {useTranslation} from "react-i18next";
import {StyledComponentProps} from "@cuda-react/theme";
import {TableCardContent} from "@cuda-react/core";
import {CardSizeOptions} from "../../../typesAndConstants";
import {TableCardContentProps} from "@cuda-react/core/lib/components/cards/TableCardContent/TableCardContent";

const styles = (theme: Theme) => createStyles({
    dividerCards: {
        margin: "4px 40px 0",
        width: `calc(100% -80px)`
    },
    titleTypography: {
        fontSize: 16,
        fontWeight: "bold",
        overflowY: "visible",
        paddingLeft: "8px",
        color: theme.palette.text.primary
    },
    table: {
        maxHeight: `calc(${CardSizeOptions.small.height}px - 52px) !important`,
        minHeight: `calc(${CardSizeOptions.small.height}px - 52px) !important`,
        margin: "0 20px",
        border: "none",
        "& div.k-grid-container": {
            overflow: "unset !important"
        },
        "& div.k-grid-content": {
            overflowY: "auto !important"
        }
    }
});
const useStyles = makeStyles(styles);

export interface StatisticsTableProps extends StyledComponentProps<typeof styles>, Omit<TableCardContentProps, "classes"> {
    /** Title of the table (for when this table is used alongside other card content) */
    tableTitle?: string
}

export const StatisticsTable = (props: StatisticsTableProps) => {
    const {tableTitle, children, ...tableCardContentProps} = props;
    const classes = useStyles(props);
    const [translate] = useTranslation();

    return (
        <>
            {tableTitle ? <Divider className={classes.dividerCards}/> : null}
            {tableTitle ? <CardHeader classes={{title: classes.titleTypography}} title={translate(tableTitle)}/> : null}
            <TableCardContent {...tableCardContentProps} classes={{dataTable: classes.table}}>
                {children}
            </TableCardContent>
        </>
    );
};