import React from "react";
import {Typography} from "@barracuda-internal/bds-core";
import {
    getArrayDataContent,
    HiddenInput,
    PasswordInput,
    SelectInput,
    TextInput,
    useCrudProps,
    validateMaxLength
} from "@cuda-react/core";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@mui/styles";
import {useSiteInputValidations} from "../../../../../hooks/createEditSiteHooks";
import {get} from "lodash";
import {useWatch} from "react-hook-form";
import apiResources from "../../../../../apiResources";
import VLanInput from "../../../edit/inputs/wanTableInput/vLanInput/VLanInput";

const useStyles = makeStyles((theme) => ({
    message: {
        marginTop: "8px",
        marginBottom: "8px"
    },
}));

interface WanStepProps {
    applianceType?: "gateway" | "site",
    series?: "S" | "T",
    stepIndex?: number
}

const WanStep: React.FC<WanStepProps> = ({applianceType, series, stepIndex = 0}) => {
    const serials = useWatch({name: "serials"}) || [];
    const portsData = getArrayDataContent(useCrudProps(apiResources.modelPorts, {id: get(serials, "[0].model")})[0]?.data);
    const wanTypesData = getArrayDataContent(useCrudProps(apiResources.siteModes)[0]?.data);
    const providersData = getArrayDataContent(useCrudProps(apiResources.siteProviders)[0]?.data);
    const [translate] = useTranslation();
    const classes = useStyles();
    const inputValidations = useSiteInputValidations();
    const getFullSource = (source: any) => "wans[" + stepIndex + "]." + source;
    const isOnPremGateway = applianceType === "gateway" && series === "T";
    const isTseriesSite = applianceType === "site" && series === "T";
    const fullWanTypeData = isTseriesSite && wanTypesData.length ?
        [...wanTypesData, {
            key: "bridge",
            name: "tesseract.appliances.dialog.wans.bridgeType.name"
        }] : wanTypesData;
    const forceStaticWan = isOnPremGateway && stepIndex === 0;

    return (
        <React.Fragment>
            <Typography className={classes.message} variant="body1">
                {translate("tesseract.appliances.dialog.wans.setup", {step: stepIndex + 1})}
            </Typography>
            <TextInput
                source={getFullSource("name")}
                label="tesseract.appliances.dialog.wans.name"
                description="tesseract.appliances.dialog.wans.descriptions.name"
                isRequired
                validate={inputValidations.validateName}
            />
            <SelectInput
                source={getFullSource("mode")}
                label="tesseract.appliances.dialog.wans.type"
                description="tesseract.appliances.dialog.wans.descriptions.type"
                choices={fullWanTypeData}
                disabled={forceStaticWan}
                validate={(value, allValues) => {
                    const isGatewaySet = allValues?.gateway !== "__SELECT_EMPTY_VALUE";

                    if (value === "bridge" && isGatewaySet) {
                        return translate("tesseract.appliances.dialog.wans.validation.mode");
                    } else {
                        return undefined;
                    }
                }}
            />
            {forceStaticWan ? (
                <HiddenInput
                    source={getFullSource("mode")}
                    hiddenValue="static"
                />
            ) : null}


            {/* BRIDGE FIELDS */}
            <SelectInput
                source={getFullSource("wanPort")}
                label="tesseract.appliances.dialog.wans.bridgeType.wanPort"
                description="tesseract.appliances.dialog.wans.bridgeType.descriptions.wanPort"
                choices={portsData}
                optionValue="port"
                optionText="description"
                hide={(value, data) => get(data, getFullSource("mode")) !== "bridge"}
            />
            <TextInput
                source={getFullSource("address")}
                label="tesseract.appliances.dialog.wans.bridgeType.address"
                description="tesseract.appliances.dialog.wans.bridgeType.descriptions.address"
                validate={inputValidations.validatePrivateIp}
                hide={(value, data) => get(data, getFullSource("mode")) !== "bridge"}
                isRequired
            />
            <TextInput
                source={getFullSource("netmask")}
                label="tesseract.appliances.dialog.wans.bridgeType.netmask"
                description="tesseract.appliances.dialog.wans.bridgeType.descriptions.netmask"
                validate={inputValidations.validateNetmask}
                hide={(value, data) => get(data, getFullSource("mode")) !== "bridge"}
                isRequired
            />
            <TextInput
                source={getFullSource("gateway")}
                label="tesseract.appliances.dialog.wans.bridgeType.gateway"
                description="tesseract.appliances.dialog.wans.bridgeType.descriptions.gateway"
                validate={inputValidations.validatePrivateIp}
                hide={(value, data) => get(data, getFullSource("mode")) !== "bridge"}
                isRequired
            />
            <VLanInput
                source={getFullSource("lanPorts")}
                label="tesseract.appliances.dialog.wans.bridgeType.lanPorts"
                description="tesseract.appliances.dialog.wans.bridgeType.descriptions.lanPorts"
                firstInputProps={{choices: portsData, optionValue: "port", optionText: "description"}}
                hide={(value: any, data: any) => get(data, getFullSource("mode")) !== "bridge"}
                isRequired
            />
            {/* END BRIDGE FIELDS */}
            <SelectInput
                source={getFullSource("port")}
                label="tesseract.appliances.dialog.wans.port"
                description="tesseract.appliances.dialog.wans.descriptions.port"
                choices={portsData}
                optionValue="port"
                optionText="description"
                hide={(value, data) => get(data, getFullSource("mode")) === "wwan" || get(data, getFullSource("mode")) === "bridge"}
            />
            <TextInput
                label="tesseract.appliances.dialog.wans.virtualLanId"
                source={getFullSource("virtualLanId")}
                description="tesseract.appliances.dialog.wans.descriptions.virtualLanId"
                validate={inputValidations.validateVlanId}
                hide={(value, data) => get(data, getFullSource("mode")) === "wwan" || get(data, getFullSource("mode")) === "bridge"}
            />

            <TextInput
                source={getFullSource("userName")}
                label="tesseract.appliances.dialog.wans.pppoeType.name"
                description="tesseract.appliances.dialog.wans.pppoeType.description.name"
                validate={validateMaxLength(255)}
                hide={(value, data) => get(data, getFullSource("mode")) !== "pppoe"}
            />
            <PasswordInput
                source={getFullSource("password")}
                label="tesseract.appliances.dialog.wans.pppoeType.password"
                description="tesseract.appliances.dialog.wans.pppoeType.description.password"
                validate={inputValidations.validatePassword}
                hide={(value, data) => get(data, getFullSource("mode")) !== "pppoe"}
            />

            <SelectInput
                source={getFullSource("provider")}
                label="tesseract.appliances.dialog.wans.providerPinning"
                description="tesseract.appliances.dialog.wans.descriptions.providerPinning"
                choices={providersData}
            />
            <TextInput
                source={getFullSource("address")}
                label="tesseract.appliances.dialog.wans.address"
                description="tesseract.appliances.dialog.wans.descriptions.address"
                validate={inputValidations.validateIp}
                hide={(value, data) => get(data, getFullSource("mode")) !== "static" || get(data, getFullSource("mode")) === "bridge"}
                isRequired
            />
            <TextInput
                source={getFullSource("netmask")}
                label="tesseract.appliances.dialog.wans.netmask"
                description="tesseract.appliances.dialog.wans.descriptions.netmask"
                validate={inputValidations.validateNetmask}
                hide={(value, data) => get(data, getFullSource("mode")) !== "static"}
                isRequired
            />
            <TextInput
                source={getFullSource("gateway")}
                label="tesseract.appliances.dialog.wans.gateway"
                description="tesseract.appliances.dialog.wans.descriptions.gateway"
                validate={inputValidations.validateIp}
                hide={(value, data) => get(data, getFullSource("mode")) !== "static"}
                isRequired
            />
            <TextInput
                source={getFullSource("pins[0].pin")}
                label={translate("tesseract.appliances.dialog.wans.pin", {serial: get(serials, "[0].serial", "")})}
                description="tesseract.appliances.dialog.wans.descriptions.pin"
                validate={inputValidations.validatePin}
                hide={(value, data) => get(data, getFullSource("mode")) !== "wwan"}
            />
            <HiddenInput
                source={getFullSource("pins[0].serial")}
                hiddenValue={get(serials, "[0].serial", "")}
            />
            {serials.length > 1 ? (
                <TextInput
                    source={getFullSource("pins[1].pin")}
                    label={translate("tesseract.appliances.dialog.wans.pin", {serial: get(serials, "[1].serial", "")})}
                    description="tesseract.appliances.dialog.wans.descriptions.pin"
                    validate={inputValidations.validatePin}
                    hide={(value, data) => get(data, getFullSource("mode")) !== "wwan"}
                />
            ) : null}
            {serials.length > 1 ? (
                <HiddenInput
                    source={getFullSource("pins[1].serial")}
                    hiddenValue={get(serials, "[1].serial", "")}
                />
            ) : null}
        </React.Fragment>
    );
};


export default WanStep;