import {CircularProgress, Typography} from "@barracuda-internal/bds-core";
import React from "react";
import {useTranslation} from "react-i18next";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import {Theme} from "@mui/material";
import {createStyles} from "@mui/styles";

export const styles = (theme: Theme) => createStyles({
    root: {
        width: "calc(100% - 2rem)",
        height: "fit-content",
        padding: "0.5rem 1rem",
        textAlign: "center",
        display: "inline-block"
    },
    spinner: {
        padding: "0 0.5rem",
        display: "inline-block"
    },
    loadingMessage: {
        margin: "12px",
        color: theme.palette.text.secondary,
        textAlign: "center",
        fontSize: 20,
        display: "inline-block"
    }
});
const useStyles = makeOverrideableStyles("LoadingMessage", styles);


export interface LoadingMessageProps extends StyledComponentProps<typeof styles> {}


/**
 * Simple 2d-center aligned loading message with spinner.
 *
 * Commonly used within *CardContent components and ConnectedTable when data is loading for the first time.
 */
const LoadingMessage = (props: LoadingMessageProps) => {
    const classes = useStyles(props);
    const [translate] = useTranslation();

    return (
        <span className={classes.root}>
            <Typography variant="body1" className={classes.loadingMessage}>
                {translate("cuda.cards.loading")}
            </Typography>
            <CircularProgress size={20} className={classes.spinner} key="spinner"/>
        </span>
    );
};


export default LoadingMessage;