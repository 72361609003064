import React, {useRef} from "react";
import {
    ActionButtonsField,
    ConnectedTable,
    CrudTypes,
    DateField,
    DeleteDialog,
    DownloadButton,
    TextField,
    useCrudFetch,
    useCrudSubscription,
    useMixpanel
} from "@cuda-react/core";
import {makeStyles} from "@mui/styles";
import {Button} from "@barracuda-internal/bds-core";
import CreateEditButtonDialog from "../../../../../../components/CreateEditButtonDialog";
import CreateEditSslCertificates from "./CreateEditSslCertificates";
import {useTranslation} from "react-i18next";
import {get} from "lodash";
import ExpiryStatus from "./ExpiryStatus";
import apiResources from "../../../../../../apiResources";

export const styles = (theme: {
    spacing: (arg0: number) => string;
}) => ({
    cardActions: {
        paddingRight: "16px",
        width: "calc(100% - 16px)"
    },
    orderCell: {
        minWidth: 70
    },
    actionCell: {
        minWidth: 70,
        paddingLeft: 0
    },
    actions: {
        paddingRight: 0,
        "&> span": {
            position: "relative",
            left: "5%"
        }
    },
    buttons: {
        paddingLeft: "16px",
        minWidth: 124
    }
});
const useStyles = makeStyles(styles);

interface MakeActiveButtonProps {
    data?: Record<string, any>,
    onActivate: (data: Record<string, any> | undefined) => void,
    activeId?: string | number | any,
}

// @ts-ignore
export const MakeActiveButton: React.FC<MakeActiveButtonProps> = ({activeId, data, onActivate, ...props}) => {
    if (!activeId || !get(data, "id")) {
        return null;
    }
    const [translate] = useTranslation();
    return get(data, "id") !== activeId ? (
        <Button variant="contained" onClick={() => onActivate(data)} bdsType="interactiveEmphasis" {...props}>
            {translate("tesseract.security.sslInterception.settings.makeCertificateActive")}
        </Button>
    ) : translate("tesseract.security.sslInterception.settings.activeCertificate");
};


export const WrappedMakeActiveButton = MakeActiveButton;

interface SslInterceptionCertificatesProps {
    enabled: boolean
}

export const SslInterceptionCertificates: React.FC<SslInterceptionCertificatesProps> = ({enabled}) => {
    const registerAction = useMixpanel("SSL Certificate", false);
    const tableRefreshRef = useRef<any>();
    const [activeCertificate, , activeCertificateRefresh] = useCrudSubscription(CrudTypes.GET, apiResources.sslCertificatesActive);
    const [, , updateActiveCertificate] = useCrudFetch(CrudTypes.UPDATE, apiResources.sslCertificatesActive, {});
    const handleActiveCall = (data: any) => updateActiveCertificate({id: data.id}).then(activeCertificateRefresh);
    const activeCertificateId = get(activeCertificate, "data.content");
    const isDisabled = typeof enabled === "boolean" && !enabled;
    const classes = useStyles();

    return (
        <div id="ssl-certificates">
            <ConnectedTable
                resource={apiResources.sslCertificates}
                actions={[
                    <CreateEditButtonDialog
                        key="create"
                        create
                        component={CreateEditSslCertificates}
                        type="inspection"
                        label="tesseract.security.sslInterception.settings.certificates.create.label"
                        onSuccess={() => {
                            registerAction("Create");
                            tableRefreshRef.current?.();
                        }}
                    />
                ]}
                classes={{cardActions: classes.cardActions}}
                params={{filter: {type: "inspection"}}}
                minCellWidth={128}
                refreshRef={tableRefreshRef}
                flat
            >
                <ExpiryStatus source="end"
                    // @ts-ignore
                              label="tesseract.security.sslInterception.settings.status" width={96}/>
                <TextField source="name" label="tesseract.security.sslInterception.settings.name"/>
                <TextField source="description" label="tesseract.security.sslInterception.settings.description"/>
                <TextField source="issuer" label="tesseract.security.sslInterception.settings.issuer"/>
                <DateField source="start" label="tesseract.security.sslInterception.settings.startDate"/>
                <DateField source="end" label="tesseract.security.sslInterception.settings.endDate"/>
                <WrappedMakeActiveButton
                    // @ts-ignore
                    source="active"
                    activeId={activeCertificateId}
                    label="tesseract.security.sslInterception.settings.active"
                    className={classes.buttons}
                    onActivate={(...args) => {
                        registerAction("Active");
                        handleActiveCall(...args);
                    }}
                />
                <ActionButtonsField
                    cellClassName={classes.actions}
                    source="id"
                    width={120}
                >
                    <DownloadButton
                        resource={apiResources.sslCertificatesDownload}
                        params={(props) => ({id: get(props, "data.id")})}
                        // @ts-ignore
                        filename={(downloadData: any, props: any) => get(props, "data.name") || "activeSslInspectionCertificate"}
                        filetype="pem"
                        tooltipText="tesseract.security.sslInterception.settings.downloadCertificate"
                        iconButton
                    />
                    <CreateEditButtonDialog
                        component={CreateEditSslCertificates}
                        resource={apiResources.sslCertificates}
                        type="inspection"
                        onSuccess={() => {
                            registerAction("Update");
                            tableRefreshRef.current?.();
                        }}
                    />
                    <DeleteDialog
                        resource={apiResources.sslCertificates}
                        title="tesseract.security.sslInterception.settings.certificates.delete.title"
                        message="tesseract.security.sslInterception.settings.certificates.delete.body"
                        disabledMessage="tesseract.security.sslInterception.settings.certificates.delete.disabledMessage"
                        disable={(data) => !isDisabled && (!get(data, "id") || !activeCertificateId || get(data, "id") === activeCertificateId)}
                        onSuccess={() => {
                            registerAction("Delete");
                            tableRefreshRef.current?.();
                        }}
                    />
                </ActionButtonsField>
            </ConnectedTable>
        </div>
    );
};

export default SslInterceptionCertificates;