import {Route, Switch} from "react-router";
import React from "react";
import CustomApps from "./customApps/CustomApps";
import Endpoint from "./endpoint/Endpoint";
import Security from "./security/Security";
import Sdwan from "./sdwan/Sdwan";
import NetworkAccess from "./networkAccess/NetworkAccess";
import WebFilter from "./webFilter/WebFilter";
import Settings from "./settings/Settings";

export const SecurityAndAccess = () => (
    <Switch>
        <Route path="/:account/:workspace/policies/apps">
            <CustomApps />
        </Route>
        <Route path="/:account/:workspace/policies/network">
            <NetworkAccess />
        </Route>
        <Route path="/:account/:workspace/policies/sdwan">
            <Sdwan />
        </Route>
        <Route path="/:account/:workspace/policies/webfilter">
            <WebFilter />
        </Route>
        <Route path="/:account/:workspace/policies/security">
            <Security />
        </Route>
        <Route path="/:account/:workspace/policies/ztna">
            <Endpoint />
        </Route>
        <Route path="/:account/:workspace/policies/settings">
            <Settings />
        </Route>
    </Switch>
);

export default SecurityAndAccess;