import {Theme} from "@mui/material";
import ReportingTheme from "../themes/ReportingTheme";

/**
 * Function to calculate the progress of the bar in example bar cards
 * @param x1 index of the entry
 */
export const progressFunction = (x1: number): number => {
    const exp = 5.0326 - x1 * 0.54;
    return Math.exp(exp) + 3;
};

const statusColors = [
    ReportingTheme.genericColors.blue,
    ReportingTheme.genericColors.yellow,
    ReportingTheme.genericColors.purple,
    ReportingTheme.genericColors.magenta,
    ReportingTheme.genericColors.turquoise,
    ReportingTheme.genericColors.darkBlue,
    ReportingTheme.genericColors.magenta,
];
export const getStatusColorByIndex = (index: number) => statusColors[index % statusColors.length] || statusColors[0];

/**
 * Calculates the color of the bar in bar cards (in neutral scale)
 * @param percentage value of the bar in percentage
 * @param theme the mui theme
 */
export const colorFunction = (percentage: number, theme: Theme): string => getGradientFromHexColors(theme.palette.primary.main, theme.palette.primary.light, percentage / 100.0);

/**
 * Calculates the color of the bar in bar cards (in success scale)
 * @param percentage value of the bar in percentage
 * @param theme the mui theme
 */
export const colorFunctionSuccess = (percentage: number, theme: Theme): string => getGradientFromHexColors(theme.palette.success.main, theme.palette.success.light, percentage / 100.0);

/**
 * Calculates the color of the bar in bar cards (in error scale)
 * @param percentage value of the bar in percentage
 * @param theme the mui theme
 */
export const colorFunctionError = (percentage: number, theme: Theme): string => getGradientFromHexColors(theme.palette.error.main, theme.palette.error.light, percentage / 100.0);

/**
 * Compare function for the javascript sort method used to sort an array of semantic versions.
 * It takes two strings and compare both returning the lower one
 * @param firstStr
 * @param secondStr
 */
export const compareSemanticVersions = (firstStr: string, secondStr: string) => {
    const firstStringSplitted = firstStr.split('.');
    const secondStringSplitted = secondStr.split('.');
    const minStringsLength = Math.min(firstStringSplitted.length, secondStringSplitted.length);

    for (let index = 0; index < minStringsLength; index++) {
        const firstStringCompValue = +firstStringSplitted[index] || 0;
        const secondStringCompValue = +secondStringSplitted[index] || 0;

        if (firstStringCompValue !== secondStringCompValue) {
            return firstStringCompValue > secondStringCompValue ? 1 : -1;
        }
    }

    return firstStringSplitted.length - secondStringSplitted.length;
};

/**
 * Compare function that takes an object with a list of semantic version with their amount and the deepness
 * If the deepness is "minor" it will return the version and subversion followed by X:
 * E.g. {1.1.0: 2, 1.1.2: 2, 1.0.1: 2} => {1.1.X: 4, 1.0.X: 2}
 * @param object {version: amount}
 * @param deepness string, can be minor or major
 */
export const groupBySemanticVersions = (object: { [string: string]: number }, deepness: string) => {
    const sortObject: { [string: string]: number } = {};
    const versions = Object.keys(object);

    versions.forEach((version) => {
        const splittedString = version.split('.');
        let newValue;

        if (deepness === "minor") {
            newValue = splittedString[0] + "." + splittedString[1] + "." + "X";
        } else {
            newValue = splittedString[0] + ".X.X";
        }

        newValue in sortObject ? sortObject[newValue] += object[version] : sortObject[newValue] = object[version];
    });

    return sortObject;
};

type Color = [red: number, green: number, blue: number, alpha: number];

const colorToHex = (color: Color): string => color.reduce((hexCode, colorPart) => {
    const part = (colorPart.toString(16));
    return hexCode + (part.length === 1 ? "0" : "") + part;
}, "#");
const colorToRgba = (color: Color): string => `rgba(${color[0]},${color[1]},${color[2]},${color[3]})`;
const hexToColor = (color: string): Color => {
    const hex = color.startsWith("#") ? color.substring(1) : color;
    return hex.length === 3 ?
        [
            parseInt(hex[0] + hex[0], 16),
            parseInt(hex[1] + hex[1], 16),
            parseInt(hex[2] + hex[2], 16),
            1
        ] : [
            parseInt(hex.substring(0, 2), 16),
            parseInt(hex.substring(2, 4), 16),
            parseInt(hex.substring(4, 6), 16),
            1
        ];
};
const rgbToColor = (color: string): Color => {
    const rgbMatch = color.match(/rgba?\((\d+),\s?(\d+),\s?(\d+)(?:,\s?(\d+))?\)/);
    return [
        parseInt(rgbMatch?.[1] || "0"),
        parseInt(rgbMatch?.[2] || "0"),
        parseInt(rgbMatch?.[3] || "0"),
        parseFloat(rgbMatch?.[4] || "1"),
    ];
};

const stringToColor = (color: string): Color => {
    if (color.startsWith("rgb")) {
        return rgbToColor(color);
    }
    return hexToColor(color);
};

/**
 * Function to get the gradient color at a point between two colours
 * It takes two strings colors and the ratio value
 * @param firstColor string
 * @param secondColor string
 * @param ratio number
 */
export const getGradientFromHexColors = (firstColor: string, secondColor: string, ratio: number) => {
    const firstColorRgba = stringToColor(firstColor);
    const secondColorRgba = stringToColor(secondColor);

    const red = Math.ceil(firstColorRgba[0] * ratio + secondColorRgba[0] * (1.0 - ratio));
    const green = Math.ceil(firstColorRgba[1] * ratio + secondColorRgba[1] * (1.0 - ratio));
    const blue = Math.ceil(firstColorRgba[2] * ratio + secondColorRgba[2] * (1.0 - ratio));
    const alpha = firstColorRgba[3] * ratio + secondColorRgba[3] * (1.0 - ratio);

    return colorToRgba([red, green, blue, alpha]);
};