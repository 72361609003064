export default {
  translation: {
    stratosReporting: {
      resourceStatisticsCard: {
        overview: "Total"
      },
      sdwanStatusCard: {
        title: "Statut SD-WAN",
        overviewTitle: "Total",
        healthy: "Sain",
        degraded: "Dégradé",
        down: "Arrêté",
        tableTitle: "Top 10 appareils pour statut SD-WAN",
        name: "Nom",
        location: "Emplacement",
        type: "Type"
      },
      sdwanBandwidthCard: {
        tableTitle: "Top 5 bande passante SD-WAN",
        title: "Synthèse de bande passante SD-WAN"
      },
      sdwanBandwidthChartCard: {
        title: "Bande passante SD-WAN",
        topTunnels: "Top {{length}} tunnels"
      },
      osStatusCard: {
        title: "Systèmes d'exploitation",
        others: "Autres",
        name: "Nom",
        location: "Emplacement",
        type: "Type",
        ip: "IP",
        linux: "Linux",
        mac: "Mac OSX",
        windows: "Windows",
        other: "Autres",
        android: "Android",
        ios: "iOS"
      },
      applianceMapCard: {
        title: "Plan d'appareil"
      },
      baseBarCard: {
        total: "sur {{total}}"
      },
      topApplicationCard: {
        title: "Top applications",
        topApplications: "Top {{length}} applications"
      },
      sdwanSummaryCard: {
        title: "Synthèse SD-WAN",
        topAppliances: "Top {{length}} appareils",
        inbound: "entrant",
        outbound: "sortant"
      },
      topWebRequest: {
        title: "Requêtes Web",
        tableTitle: "Top Requêtes Web",
        total: "Total",
        allowed: "autorisé",
        blocked: "bloqué",
        type: "Type",
        location: "Emplacement",
        name: "Nom"
      },
      storageCard: {
        title: "Aperçu de stockage",
        use: "<0>{{spaceUsed}}GB</0> utilisés sur {{totalSpace}}GB",
        retention: "Fidélisation maxi. env. 3 jours",
        name: "Nom",
        lastChange: "Dernière modification",
        fileSize: "Taille de fichier",
        storageLow: "Espace de stockage bientôt épuisé !",
        upgradeStorageNow: "Mettez à niveau le stockage maintenant !",
        tableTitle: "Top appareils pour la consommation de stockage"
      },
      topBlockedUserCard: {
        title: "Top des utilisateurs bloqués",
        topUsers: "Top {{length}} utilisateurs",
        total: "sur {{total}}",
        hits: "{{formattedCount}} Résultats",
        hits_one: "{{formattedCount}} Résultat",
        hits_other: "{{formattedCount}} Résultats"
      },
      sdwanHeatMap: {
        title: "Carte de densité SD-WAN"
      },
      legendFooter: {
        online: "en ligne",
        offline: "hors ligne",
        degraded: "dégradé"
      },
      applianceStatus: {
        title: "Statut d'appareil",
        topUsers: "Top {{length}} utilisateurs",
        total: "sur {{total}}"
      },
      previewDialog: {
        addWidgetButton: "Ajouter widget",
        title: "Ajouter widget",
        card: "Widget",
        size: "Taille",
        cancel: "Annuler",
        add: "Ajouter",
        small: "Petit",
        medium: "Moyen",
        large: "Grand",
        addWidgetCard: "Cliquez pour ajouter un widget !",
        ok: "OK",
        editTitle: "Modifier Widget"
      },
      reportTable: {
        title: "Tous les rapports",
        type: "Type",
        name: "Nom",
        period: "Période de temps",
        devices: "Dispositifs",
        schedule: "Planification",
        owner: "Propriétaire"
      },
      reportDialog: {
        create: {
          title: "Créer un rapport",
          button: "Créer"
        },
        edit: {
          title: "Modifier un rapport",
          button: "Modifier"
        },
        reportData: {
          label: "Données de rapport",
          application: {
            name: "Application"
          },
          protocol: {
            name: "Protocole"
          },
          applicationRule: {
            name: "Règle d'application"
          },
          contentType: {
            name: "Type de contenu"
          },
          outputInterface: {
            name: "Interface de sortie"
          },
          destination: {
            name: "Destination"
          }
        },
        datasource: {
          label: "Source de données",
          activity: {
            name: "Activité"
          },
          threat: {
            name: "Menace"
          },
          webFilter: {
            name: "Filtre Web"
          },
          sdwanTunnels: {
            name: "Tunnels SD-WAN"
          },
          sdwanTunnelsAppProtocol: {
            name: "Applications et protocoles de tunnels SD-WAN"
          }
        },
        output: {
          label: "Sortie",
          outputFormat: {
            label: "Format de sortie"
          },
          deliveryOptions: {
            label: "Options de livraison",
            email: "E-mail",
            fileDownload: "Téléchargement de fichier"
          },
          recipients: {
            label: "Destinataires"
          },
          sizeLimit: {
            label: "Limite de taille"
          },
          frequency: {
            label: "Fréquence",
            once: "Une fois",
            weekly: "Hebdomadaire",
            monthly: "Mensuel"
          },
          status: {
            label: "Statut",
            enabled: "Activé",
            disabled: "Désactivé"
          }
        },
        advanced: {
          label: "Avancé",
          destination: {
            label: "Destination",
            domain: "Domaine",
            category: "Catégorie"
          },
          ipAnonymize: {
            label: "Anonymiser IP"
          },
          serialNumberBucketing: {
            label: "Bucketing de numéro de série"
          },
          domains: {
            label: "Domaines"
          },
          exludeTimeframe: {
            label: "Exclure échéancier"
          },
          action: {
            label: "Action"
          },
          chartType: {
            label: "Type de tableau",
            horizontalBar: "Barre horizontale",
            verticalBar: "Barre verticale",
            pieChart: "Graphique en fromage"
          },
          ddLimitLevels: {
            label: "Niveaux limites d'approfondissement"
          }
        },
        result: {
          label: "Étape de résultat"
        },
        success: "Correctement créé"
      },
      userEnrollment: {
        title: "Inscription d'utilisateur",
        enrolledUsers: "Utilisateurs",
        enrolledDevices: "Dispositifs",
        pendingEnrollment: "En cours",
        name: "Nom",
        location: "Emplacement",
        device: "Dispositif"
      },
      licensing: {
        title: "Licences",
        bandwidth: "Bande passante",
        bandwidthUsageMedium: "{{remain}} Gbit/s restants (sur {{total}} Gbit/s)",
        bandwidthUsageLarge: "{{used}} utilisés (sur {{total}} GB/s)",
        bandwidthExpire: "Expire dans {{daysLeft}} jours",
        users: "Utilisateurs",
        usersUsageMedium: "{{remain}} Utilisateurs restants (sur {{total}} utilisateurs)",
        usersUsageLarge: "{{used}} Utilisateurs utilisés (sur {{total}} utilisateurs)",
        usersExpire: "{{remain}} Utilisateurs restants",
        storage: "Stockage",
        storageUsageMedium: "{{remain}}GB restants (sur {{total}}GB)",
        storageUsageLarge: "{{used}} GB/sB utilisés (sur {{total}} GB/s)",
        storageRetention: "Fidélisation maxi. env. {{remain}} jours",
        storageExpire: "Expire dans {{daysLeft}} jours",
        appliances: "Appareils",
        appliancesUsageMedium: "{{remain}} jours restants (sur {{total}} jours)",
        appliancesUsageLarge: "{{used}} jours utilisés (sur {{total}} jours)",
        appliancesExpire: "Expire dans {{daysLeft}} jours",
        percentage: "{{percentage}}"
      },
      dashboardPage: {
        done: "Terminé",
        cancel: "Annuler",
        editDashboard: "Modifier un tableau de bord",
        emptyMessage: "Ce tableau de bord ne contient pour le moment aucun widget.",
        addMessage: "Ajouter des widgets en cliquant sur le bouton Ajouter.",
        lastDay: "Dernières 24 heures",
        lastWeek: "Dernière semaine",
        lastMonth: "Dernier mois",
        configureDashboard: "Configurez ce tableau de bord !",
        addDashboard: "Créer un tableau de bord",
        name: "Nom",
        template: "Modèle",
        reset: "Réinitialiser tableau de bord",
        rename: "Renommer un tableau de bord",
        delete: "Supprimer un tableau de bord",
        confirmDeletion: "Voulez-vous vraiment supprimer le tableau de bord « {{name}} » ?",
        actions: "Options",
        add: "Ajouter tableau de bord",
        resetToTemplate: "Réinitialiser vers modèle",
        validateName: "peut uniquement contenir des caractères alphanumériques ou des espaces.",
        changeDashboardMessage: "Êtes-vous certain de vouloir modifier les tableaux de bord sans enregistrer vos modifications ?",
        uniqueNameError: "doit être unique",
        resetToDefault: "Voulez-vous réinitialiser le tableau de bord par défaut ?",
        resetToTemplateDescription: "Réinitialisez votre tableau de bord sur les widgets et la mise en page d'origine pour le modèle choisi.",
        options: "Options",
        maxNumberOfDashboardReached: "Impossible de créer plus de tableaux de bord, maximum de {{maxDashboardsAmount}} atteint"
      },
      domainCard: {
        title: "Domaines"
      },
      dualTopBarBaseCard: {
        title: "Domaines",
        overview: "Total",
        totalAllowedSessions: "Sessions autorisées ",
        totalBlockedSessions: "Sessions bloquées ",
        allowed: "Autorisé",
        blocked: "Bloqué"
      },
      topUrlCard: {
        title: "Catégories d'URL"
      },
      malwareCard: {
        title: "Advanced Threat Protection",
        hits: "{{formattedCount}} Résultats",
        hits_one: "{{formattedCount}} Résultat",
        hits_other: "{{formattedCount}} Résultats"
      },
      ipsIncidentCard: {
        title: "Incidents IPS",
        sessions: "{{sessions}} Résultats",
        sessions_one: "{{sessions}} Résultat",
        sessions_other: "{{sessions}} Résultats"
      },
      affectedUsers: {
        title: "Utilisateurs affectés"
      },
      totalChart: {
        total: "Total"
      },
      geoCard: {
        sourceTopCountries: {
          title: "Top des pays Géo-Source"
        },
        destinationTopCountries: {
          title: "Top des pays Géo-Destination"
        }
      },
      topAllowedCategoriesSessions: {
        title: "Top des catégories d'application autorisées <0>(par Session)</0>",
        menuEntry: "Top des catégories d'application autorisées (par Session)",
        footerKey: "Top {{length}}",
        sessions: "{{formattedCount}} Sessions",
        sessions_one: "{{formattedCount}} Session",
        sessions_other: "{{formattedCount}} Sessions"
      },
      topAllowedCategoriesTraffic: {
        title: "Top des catégories d'application autorisées <0>(par Trafic)</0>",
        menuEntry: "Top des catégories d'application autorisées (par Trafic)",
        footerKey: "Top {{length}}"
      },
      blockedApplicationCategories: {
        title: "Top des catégories d'application bloquées",
        footerKey: "Top {{length}}"
      },
      allowedApplicationsSessions: {
        title: "Top des applications autorisées <0>(par Session)</0>",
        menuEntry: "Top des applications autorisées (par Session)",
        sessions: "{{formattedCount}} Sessions",
        sessions_one: "{{formattedCount}} Session",
        sessions_other: "{{formattedCount}} Sessions",
        footerKey: "Top {{length}}"
      },
      allowedApplicationsTraffic: {
        title: "Top des applications autorisées <0>(par Trafic)</0>",
        menuEntry: "Top des applications autorisées (par Trafic)",
        footerKey: "Top {{length}}"
      },
      blockedApplications: {
        title: "Top des applications bloquées",
        sessions: "{{formattedCount}} Sessions",
        sessions_one: "{{formattedCount}} Session",
        sessions_other: "{{formattedCount}} Sessions",
        footerKey: "Top {{length}}"
      },
      applicationRisk: {
        mediumHigh: "Moyen -\nHaut",
        medium: "Moyen",
        lowMedium: "Bas - Moyen",
        low: "Bas",
        high: "Haut",
        title: "Risque d'application",
        risk: "Risque"
      },
      highRiskApplicationsCard: {
        title: "Applications à haut risque",
        top: "Top {{length}}"
      },
      topBlockedUrlCategoriesCard: {
        title: "Top des catégories d'URL bloquées",
        topUrlCategories: "Top {{length}} catégories d'URL bloquées",
        total: "sur {{total}}",
        sessions: "{{formattedCount}} Sessions",
        sessions_one: "{{formattedCount}} Session",
        sessions_other: "{{formattedCount}} Sessions"
      },
      applicationCategories: {
        title: "Catégories d'application"
      },
      topApplications: {
        title: "Applications"
      },
      topAllowedUrlCategoriesCard: {
        titlePerSession: "Top des catégories d'URL autorisées <0>(par Session)</0>",
        titlePerTraffic: "Top des catégories d'URL autorisées <0>(par Trafic)</0>",
        menuEntryPerSession: "Top des catégories d'URL autorisées (par Session)",
        menuEntryPerTraffic: "Top des catégories d'URL autorisées (par Trafic)",
        topAllowedUrlCategories: "Top {{length}} catégories d'URL autorisées",
        total: "sur {{total}}",
        sessions: "{{formattedCount}} Sessions",
        sessions_one: "{{formattedCount}} Session",
        sessions_other: "{{formattedCount}} Sessions"
      },
      topAllowedDomainsCard: {
        titlePerSession: "Top des domaines autorisés <0>(par Session)</0>",
        titlePerTraffic: "Top des domaines autorisés <0>(par Trafic)</0>",
        menuEntryPerSession: "Top des domaines autorisés (par Session)",
        menuEntryPerTraffic: "Top des domaines autorisés (par Trafic)",
        topAllowedDomains: "Top {{length}} domaines autorisés",
        total: "sur {{total}}",
        sessions: "{{formattedCount}} Sessions",
        sessions_one: "{{formattedCount}} Session",
        sessions_other: "{{formattedCount}} Sessions"
      },
      topBlockedDomainsCard: {
        title: "Top des domaines bloqués",
        topBlockedDomains: "Top {{length}} domaines bloqués",
        total: "sur {{total}}",
        sessions: "{{formattedCount}} Sessions",
        sessions_one: "{{formattedCount}} Session",
        sessions_other: "{{formattedCount}} Sessions"
      },
      topUsersCard: {
        title: "Top des utilisateurs",
        topUsers: "Top {{length}} utilisateurs",
        total: "sur {{total}}"
      },
      noData: {
        message: "Aucune donnée pour ce widget !",
        unsupported: "Widget incompatible avec l'Edge Service actuel."
      },
      barGrid: {
        noData: "Aucune entrée correspondante trouvée !"
      },
      ZTNAResources: {
        title: "Ressources",
        others: "Autres",
        total: "Total disponible",
        custom: "Personnalisé",
        inUse: "En cours d'utilisation",
        tableName: "Stratégies d'accès",
        table: {
          name: "Nom",
          description: "Description",
          users: "Utilisateurs",
          groups: "Groupes",
          resources: "Ressources",
          devicePosture: "Posture de sécurité"
        }
      },
      ZTNAAgentInUse: {
        title: "SE d'agent en usage",
        total: "Total",
        windows: "Win",
        macos: "macOS",
        android: "Android",
        ios: "iOS",
        linux: "Linux",
        tableName: "Top SE en usage",
        devices: "{{value}} Dispositif",
        devices_plural: "{{value}} Dispositifs"
      },
      ZTNADeviceLocation: {
        title: "Carte de dispositifs",
        devices: "Dispositifs",
        noData: "Aucun dispositif trouvé.",
        user: "Utilisateur",
        brand: "Marque de dispositif",
        model: "Modèle de dispositif",
        hardwareModel: "Modèle de matériel",
        operatingSystem: "Système d'exploitation",
        operatingSystemVersion: "Version OS",
        agentVersion: "Version d'agent",
        enrollmentDate: "Date d'inscription",
        country: "Pays",
        noCountry: "Aucun emplacement"
      },
      ZTNAUserGeoLocation: {
        title: "Géolocalisation d'utilisateur",
        nonRoutableOrIP: "Adresses IP privées ou non-routables",
        devices: "{{value}} Dispositif",
        devices_plural: "{{value}} Dispositifs",
        nonRoutableOrIPUnknown: "Inconnu"
      },
      userStatusCard: {
        title: "Utilisateurs",
        total: "Total",
        enrolled: "Inscrit",
        pending: "En cours",
        tableTitle: "Top utilisateurs (par compte de dispositifs)",
        name: "Nom",
        email: "E-mail",
        deviceCount: "Nombre de dispositifs",
        enrollmentCompleted: {
          label: "Inscription terminée",
          yes: "Inscription terminée",
          no: "Inscription en cours"
        }
      },
      userSummaryCard: {
        title: "Synthèse",
        fullName: "Nom complet",
        email: "E-mail",
        deviceCount: "Nombre de dispositifs",
        enrollmentCompleted: "Inscription terminée"
      },
      userDevicesCard: {
        title: "Dispositifs",
        table: {
          userFullName: "Nom complet",
          userEmail: "E-mail",
          brand: "Marque de dispositif",
          hardwareModel: "Modèle de dispositif",
          operatingSystem: "Système d'exploitation",
          operatingSystemVersion: "Version OS",
          enrollmentDate: "Date d'inscription"
        }
      },
      accessDevicesAgentVersionCard: {
        title: "Version d'agent en usage",
        total: "Total",
        version: "Version {{version}}",
        older: "Ancien",
        tableTitle: "Top Versions en usage",
        devices: "{{value}} Dispositif",
        devices_plural: "{{value}} Dispositifs"
      },
      categories: {
        ztna: "Zero Trust Access",
        security: "Sécurité",
        webFilter: "Filtre Web"
      },
      filterTooltip: "Cliquez pour créer un filtre."
    }
  }
} as const;