import React from "react";
import {
    CrudTypes,
    CustomField,
    getArrayDataContent,
    LinkField,
    LocationsCardContent,
    SelectedChoiceField,
    TextField,
    useCrudProps,
    useCrudSubscription,
    useGlobalParam
} from "@cuda-react/core";
import {getAzureHubLink, getAzureResourceLink} from "../../../../utils/azureUtils";
import apiResources from "./../../../../apiResources";
import {useTheme} from "@mui/material";
import {BaseCard, DashboardCardProps} from "@stratos/reporting";
import {ReportingTheme} from "@stratos/reporting/lib/themes";

export interface GatewayTunnelLocationsCardProps extends DashboardCardProps {
    additionalProps?: {
        details: {
            id?: number | string
        },
    },
}

export const GatewayTunnelLocationsCard: React.FC<GatewayTunnelLocationsCardProps> = (props) => {
    const {additionalProps, size, preview} = props;
    const {details} = additionalProps || {};
    const theme = useTheme();
    const isDemo = useGlobalParam("userData.mode")[0] === "demo";

    const gatewayLocationsData = getArrayDataContent(useCrudSubscription(
        CrudTypes.GET,
        details?.id ? apiResources.gatewayTunnelLocation : undefined,
        {id: details?.id}
    )[0].data).map((gateway) => ({
        ...gateway,
        tseries: gateway.sites.filter((site?: { modelSeries?: "S" | "T" }) => site?.modelSeries === "T"),
        iot: gateway.sites.filter((site?: { modelSeries?: "S" | "T" }) => site?.modelSeries === "S")
    }));

    const regionChoices = getArrayDataContent(useCrudProps(apiResources.gatewayRegions)[0]?.data);
    const scaleUnitChoices = getArrayDataContent(useCrudProps(apiResources.gatewayScaleUnits)[0]?.data);

    return (
        <BaseCard
            id="GatewayTunnelLocationsCard"
            title="tesseract.appliances.dashboard.tunnelLocations.title"
            size={size}
            preview={preview}
        >
            <LocationsCardContent
                data={gatewayLocationsData}
                series={[
                    {
                        label: "tesseract.appliances.dashboard.tunnelLocations.gateways.label",
                        color: ReportingTheme.genericColors.purple,
                        zIndex: 4,
                        linkedSeries: {
                            color: ReportingTheme.genericColors.darkBlue,
                            zIndex: 3,
                            selectedZIndex: 5
                        },
                        details: {
                            resource: apiResources.gateways,
                            optionValue: "id",
                            fields: [
                                <LinkField
                                    label="tesseract.appliances.dashboard.tunnelLocations.gateways.fields.name"
                                    source="name"
                                    key="name"
                                    link={getAzureResourceLink("applicationId", isDemo)}
                                />,
                                <CustomField
                                    label="tesseract.appliances.dashboard.tunnelLocations.gateways.fields.sites"
                                    source="tseries"
                                    key="tseries"
                                    render={(sites, data) => (
                                        <LinkField
                                            defaultText={(sites?.length) || sites}
                                            link={`/infrastructure/sites?filter={"gateway":[${data.id}]}`}
                                        />
                                    )}
                                />,
                                <CustomField
                                    label="tesseract.appliances.dashboard.tunnelLocations.gateways.fields.iot"
                                    source="iot"
                                    key="iot"
                                    render={(sites, data) => (
                                        <LinkField
                                            defaultText={(sites?.length) || sites}
                                            link={`/infrastructure/iot?filter={"gateway":[${data.id}]}`}
                                        />
                                    )}
                                />,
                                <LinkField
                                    label="tesseract.appliances.dashboard.tunnelLocations.gateways.fields.vwan"
                                    source="virtualWan.name"
                                    key="vwanName"
                                    link={getAzureResourceLink("virtualWan.resourceId", isDemo)}
                                />,
                                <LinkField
                                    label="tesseract.appliances.dashboard.tunnelLocations.gateways.fields.hub"
                                    source="hubName"
                                    key="hubName"
                                    link={getAzureHubLink("hubId", isDemo)}
                                />,
                                <SelectedChoiceField
                                    label="tesseract.appliances.dashboard.tunnelLocations.gateways.fields.region"
                                    source="region"
                                    key="region"
                                    choices={getArrayDataContent(regionChoices)}
                                />,
                                <SelectedChoiceField
                                    label="tesseract.appliances.dashboard.tunnelLocations.gateways.fields.size"
                                    source="scaleUnit"
                                    key="scaleUnit"
                                    choices={getArrayDataContent(scaleUnitChoices)}
                                    render={((value: any, _data: any, choices: {key: any, name: any}) => choices.key === value ? choices.name : value)}

                                />
                            ]
                        }
                    },
                    {
                        label: "tesseract.sites.pageTitle",
                        color: ReportingTheme.genericColors.blue,
                        source: "tseries",
                        zIndex: 1,
                        linkedSeries: {
                            color: ReportingTheme.genericColors.magenta,
                            zIndex: 6,
                            selectedZIndex: 2
                        },
                        details: {
                            resource: apiResources.sites,
                            optionValue: "id",
                            fields: [
                                <LinkField
                                    label="tesseract.appliances.dashboard.tunnelLocations.sites.fields.name"
                                    source="name"
                                    key="name"
                                    link={(data) => `/infrastructure/sites/${data.id}`}
                                />,
                                <TextField
                                    label="tesseract.appliances.dashboard.tunnelLocations.sites.fields.location"
                                    source="summary"
                                    key="summary"
                                />,
                                <LinkField
                                    label="tesseract.appliances.dashboard.tunnelLocations.sites.fields.gateway"
                                    source="gatewayName"
                                    key="gatewayName"
                                    link={(data) => `/infrastructure/gateways?filter={"name":"${data.gatewayName}"}`}
                                />,
                                <TextField
                                    label="tesseract.appliances.dashboard.tunnelLocations.sites.fields.providers"
                                    source="wanNetworks.length"
                                    key="wans"
                                />
                            ]
                        }
                    },
                    {
                        label: "tesseract.network.iot.menuTitle",
                        color: ReportingTheme.genericColors.turquoise,
                        source: "iot",
                        zIndex: 1,
                        linkedSeries: {
                            color: ReportingTheme.genericColors.magenta,
                            zIndex: 6,
                            selectedZIndex: 2
                        },
                        details: {
                            resource: apiResources.sites,
                            optionValue: "id",
                            fields: [
                                <LinkField
                                    label="tesseract.appliances.dashboard.tunnelLocations.sites.fields.name"
                                    source="name"
                                    key="name"
                                    link={(data) => `/infrastructure/iot/${data.id}`}
                                />,
                                <TextField
                                    label="tesseract.appliances.dashboard.tunnelLocations.sites.fields.location"
                                    source="summary"
                                    key="summary"
                                />,
                                <LinkField
                                    label="tesseract.appliances.dashboard.tunnelLocations.sites.fields.gateway"
                                    source="gatewayName"
                                    key="gatewayName"
                                    link={(data) => `/infrastructure/gateways?filter={"name":"${data.gatewayName}"}`}
                                />,
                                <TextField
                                    label="tesseract.appliances.dashboard.tunnelLocations.sites.fields.providers"
                                    source="wanNetworks.length"
                                    key="wans"
                                />
                            ]
                        }
                    }
                ]}
            />
        </BaseCard>
    );
};