import React from 'react';
import {Typography} from "@barracuda-internal/bds-core";
import {makeStyles} from "@mui/styles";
import classNames from "classnames";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    mainMessage: {
        fontWeight: "bold",
        marginBottom: "16px"
    },
    messages: {
        marginLeft: "64px"
    }
}));

const PromoteResultStep = () => {
    const classes = useStyles();
    const [translate] = useTranslation();

    return (
        <>
            <Typography className={classNames(classes.messages, classes.mainMessage)}>
                {translate("tesseract.appliances.dialog.promoteResult.primaryMessage")}
            </Typography>
            <Typography className={classes.messages}>
                {translate("tesseract.appliances.dialog.promoteResult.secondaryMessage")}
            </Typography>
        </>
    );
};

export default PromoteResultStep;