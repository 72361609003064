import {IconButton, Tooltip, TooltipProps, Typography} from "@barracuda-internal/bds-core";
import get from "lodash/get";
import React from "react";
// @ts-ignore
import {AuditStatusIconMap, BooleanIcon, NotificationStatusIconMap, StatusIcon} from "@cuda-react/icons";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import {useTranslation} from "react-i18next";
import {Theme} from "@mui/material";
import {ClassKeyOfStyles, ClassNameMap, createStyles} from "@mui/styles";
import {BaseFieldProps} from "../index";
import {TFunction} from "i18next";
import {StatusIconProps} from "@cuda-react/icons/lib/icons/StatusIcon";

export interface BaseStatusIconFieldProps extends BaseFieldProps {
    /**
     * prop passed down to each rendered icon. It is used to define the color by the status value for @cuda-react/icons/StatusIcon.
     */
    getStatusColorFromTheme?: (status: string, theme: Theme) => string,
    /**
     * function to determinate if field needs be hidden
     * @param {object} data the full data array
     * @return {boolean} if true, renders a div instead of the field.
     */
    hide?: (data: any) => boolean,
    /**
     * icon to display next to each status. If iconMap is provided as well, this icon will be used whenever a matching icon is not found in iconMap.
     */
    icon?: React.ReactNode,
    /**
     * a map of icons. Passed down to each rendered StatusIcon
     */
    iconMap?: StatusIconProps["iconMap"],
    /**
     * entry to search for in the data for the icon status. If not provided, source will be used.
     */
    iconSource?: string,
    /**
     * if true and iconMap not provided, it will use the AuditStatusIconMap as iconMap.
     */
    isAudit?: boolean,
    /**
     * if true, it will render a BooleanIcon.
     */
    isBoolean?: boolean,
    /**
     * if true and iconMap not provided, it will use the NotificationStatusIconMap as iconMap.
     */
    isNotification?: boolean,
    /**
     * callback function called whenever the icon is clicked. Callback is called with the event and all the props.
     */
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, props: any) => void,
    /**
     * if iconSource is not provided, this will be the entry to search for in the data for the icon status.
     */
    source?: string,
    /**
     * Property for rendering the text for the status icon. Can be either:
     *
     *  - the string to render
     *  - a function, called with value and data, and which returns the content to render.
     *  - an object, which will get render as text.
     *
     * @function
     * @param {string} value the value extracted from the full data array.
     * @param {object} data the full data array
     * @returns {node} the sub text to render
     */
    text?: string | object | ((value: any, data: any) => React.ReactNode),
    /**
     * prefix for the text to render
     */
    textPrefix?: string,
    /**
     * entry to look for in the data structure as text.
     */
    textSource?: string,
    /**
     * tooltip to display when overing the icon. It could either be:
     *  - the string to render
     *  - a function, called with value and data, and which returns the content to render.
     *  - an object, which will get render as text.
     *
     * @function
     * @param {string} value the value extracted from the full data array.
     * @param {object} data the full data array
     * @returns {node} the sub text to render
     */
    tooltip?: string | object | ((value: any, data: any) => React.ReactNode),
    /**
     * passed to the ToolTip component as placement prop.
     */
    tooltipPosition?: TooltipProps["placement"],
    /**
     * prefix to add to the tooltip string
     */
    tooltipPrefix?: string,
    /**
     * entry to look into the data structure as tooltip.
     */
    tooltipSource?: string
}

export const styles = (theme: Theme) => createStyles<string, BaseStatusIconFieldProps>({
    tooltip: {
        ...theme.typography.body2,
        // @ts-ignore Added by Cuda-react. Default (undefined), is fine
        color: theme.palette.text.tooltip,
    },
    iconButton: (props) => ({
        cursor: props.onClick ? "pointer" : "default",
        "&:hover": {
            backgroundColor: props.onClick ? undefined : "transparent"
        }
    }),
    text: (props) => {
        const value = get(props.data, props.textSource || "");
        return {
            color: props.getStatusColorFromTheme ? props.getStatusColorFromTheme(value, theme) : undefined,
            display: "inline",
            verticalAlign: "middle",
            fontSize: theme.typography.pxToRem(14)
        };
    }
});

const useStyles = makeOverrideableStyles("StatusIcon", styles);
type StatusIconFieldClasses = Partial<ClassNameMap<ClassKeyOfStyles<typeof styles>>>;

export interface StatusIconFieldProps extends StyledComponentProps<typeof styles>, BaseStatusIconFieldProps {
}

const getIcon = (props: StatusIconFieldProps) => {
    const {iconSource, source, data, isAudit, isNotification, icon, isBoolean} = props;
    let {iconMap} = props;
    const trueSource = iconSource || source || "";
    const value = get(data, trueSource);

    if (isBoolean) {
        return (<BooleanIcon status={value}/>);
    }

    if (isAudit) {
        iconMap = iconMap || AuditStatusIconMap;
    }

    if (isNotification) {
        iconMap = iconMap || NotificationStatusIconMap;
    }

    const iconToRender = typeof icon === "function" ? icon(value, props.data) : icon;

    return (
        <StatusIcon
            status={value}
            icon={iconToRender}
            iconMap={iconMap}
        />
    );
};

const getValue = (translate: TFunction, prefix?: string, specificSource?: string, source?: string, data?: any, item?: string | object | ((value: any, data: any) => React.ReactNode)) => {
    const trueSource = specificSource || source || "";
    const value = get(data, trueSource);
    let composedValue;

    if (specificSource) {
        composedValue = value;
    } else {
        composedValue = typeof item === "function" ? item?.(value, data) : item;
    }

    if (composedValue) {
        if (prefix) {
            composedValue = prefix + composedValue;
        }
    }

    return translate(composedValue);
};

const renderText = (props: StatusIconFieldProps, classes: StatusIconFieldClasses, translate: TFunction) => {
    const {text, textSource, textPrefix, source, data} = props;
    let textToRender = "";

    if (text || textSource) {
        textToRender = getValue(translate, textPrefix, textSource, source, data, text);
    }

    return textToRender ? (<Typography className={classes.text} variant="body2">{textToRender}</Typography>) : null;
};

const renderTooltip = (iconButtonToRender: React.ReactNode, props: StatusIconFieldProps, classes: StatusIconFieldClasses, translate: TFunction): JSX.Element => {
    const {tooltip, tooltipSource, tooltipPrefix, source, data, tooltipPosition = "top-end"} = props;
    let tooltipText = "";

    if (tooltip || tooltipSource) {
        tooltipText = getValue(translate, tooltipPrefix, tooltipSource, source, data, tooltip);
    }

    return tooltipText ? (
        <Tooltip
            title={tooltipText}
            placement={tooltipPosition}
            classes={{tooltip: classes.tooltip}}
        >
            <span>
                {iconButtonToRender}
            </span>
        </Tooltip>
    ) : iconButtonToRender as JSX.Element;
};

/**
 * Renders a status field with a representative icon and a text (in case it is needed).
 * This component renders a StatusIcon as the icon.
 */
export const StatusIconField = (props: StatusIconFieldProps): JSX.Element => {
    const {onClick, hide} = props;
    const classes = useStyles(props);
    const [translate] = useTranslation();
    const iconToRender = getIcon(props);
    const textToRender = renderText(props, classes, translate);

    const iconButtonToRender = (
        <React.Fragment>
            <IconButton
                size="small"
                onClick={onClick ? (event) => onClick(event, props) : undefined}
                className={classes.iconButton}
                disableTouchRipple={!onClick}
            >
                {iconToRender}
            </IconButton>
            {textToRender}
        </React.Fragment>
    );

    if (hide?.(props)) {
        return (<div/>);
    }

    return renderTooltip(iconButtonToRender, props, classes, translate);
};

export default StatusIconField;