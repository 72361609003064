import {Table, TableBody, TableCell, TableRow, Theme} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";
import Columns, {ColumnsProps} from "../../layout/Columns/Columns";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import {createStyles} from "@mui/styles";
import {BaseFieldProps} from "../index";

const styles = (theme: Theme) => createStyles({
    root: {
        paddingTop: "8px"
    },
    labelCell: {
        fontSize: theme.typography.pxToRem(14),
        borderBottom: "none",
        padding: "4px 16px",
        color: theme.palette.text.secondary
    },
    dataCell: {
        fontSize: theme.typography.pxToRem(14),
        borderBottom: "none",
        height: 24
    },
    borderContainer: {
        borderBottom: "solid 1px #DDDDDD",
    },
    columnStyleLabelCell: {
        borderBottom: "none",
        fontSize: theme.typography.pxToRem(14),
        fontWeight: "bold",
        padding: "8px 16px",
        textAlign: "right",
        width: "30%",
    }
});
const useStyles = makeOverrideableStyles("ColumnField", styles);

export interface ColumnFieldProps extends StyledComponentProps<typeof styles>, BaseFieldProps, Omit<ColumnsProps, "classes"> {
    /**
     * Children to render within columns. All *Field components will work seamlessly here.
     *
     * The children are provided with the "data" prop, and rendered inside of a custom-rendered [Columns](/?path=/docs/core-components-layout-columns--columns) component.
     *
     * All additional props for Columns are supported, alongside:
     *
     * @param {string} child.props.label text to display next to the child content.
     */
    children?: React.ReactElement | (React.ReactElement | null)[] | null,
    /**
     * The total number of columns to display. This is ignored if a custom "columns" definition has been provided.
     */
    columnCount?: number,
    /**
     * columns definition, as required by [Columns](/?path=/docs/core-components-layout-columns--columns). If not provided, a default column definition will be used of {xs: 12, md: 6} per each column of columnCount.
     */
    columns?: ColumnsProps["columns"]
    /**
     * boolean, when set the TableRow uses a css prop in order to show the summary column in a different way
     */
    stratosSummaryStyle?: boolean,
}

/**
 * Renders multiple columns for displaying data using the provided children
 * This field ignores the `source` prop, it's expected its children will manage this aspect
 *
 * Designed to work as a child of [DialogBody](/?path=/docs/core-components-dialogs-dialogbody--dialog-body) or [ResultStep](/?path=/docs/core-components-wizard-resultstep--result-step)/[SubmitStep](/?path=/docs/core-components-wizard-submitstep--submit-step) with *Field components as its children.
 */
export const ColumnField = (props: ColumnFieldProps) => {
    const {children, columnCount = 2, columns, data, stratosSummaryStyle, ...columnProps} = props;
    const classes = useStyles(props);
    const [translate] = useTranslation();
    const columnStructure = columns || Array(columnCount).fill({xs: 12, md: 6});

    const tabulateItems = (itemsArray: ColumnFieldProps["children"]) => (
        <Table size="small">
            <TableBody>
                {React.Children.map(itemsArray, (child) => child ? (
                    <TableRow className={stratosSummaryStyle ? (child.props.borderBottom ? classes.borderContainer : undefined) : undefined}>
                        <TableCell className={stratosSummaryStyle ? classes.columnStyleLabelCell : classes.labelCell}>
                            {translate(child.props.label)}
                        </TableCell>
                        <TableCell className={classes.dataCell}>
                            {React.cloneElement(child, {data})}
                        </TableCell>
                    </TableRow>
                ): null)}
            </TableBody>
        </Table>
    );

    return (
        <Columns
            columns={columnStructure}
            render={tabulateItems}
            className={classes.root}
            {...columnProps}
        >
            {children}
        </Columns>
    );
};

export default ColumnField;