import {IconButton} from "@barracuda-internal/bds-core";
import React from "react";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import {createStyles} from "@mui/styles";
import {BaseFieldProps} from "../index";
import {ClonableChildren} from "../../../utils/commonTypes";

interface BaseActionsButtonsFieldProps extends BaseFieldProps {
    /**
     * the children to render as actions. Each child is cloned, and provided with the passed in data.
     * If a child has an onClick function, it is rendered inside an IconButton, and the onClick method is moved to the IconButton.
     */
    children?: ClonableChildren,
    /**
     * if true, the component content is set to be left aligned.
     */
    left?: boolean
}

export const styles = createStyles<string, BaseActionsButtonsFieldProps>({
    buttonContainer: (props) => ({
        float: props.left ? "left" : "right",
        whiteSpace: "nowrap",
        zIndex: 15,
        "& > *": {
            marginRight: 4,
            marginLeft: 4
        }
    })
});

const useStyles = makeOverrideableStyles("ActionButtonsField", styles);

export interface ActionButtonsFieldProps extends BaseActionsButtonsFieldProps, StyledComponentProps<typeof styles> {

}

/**
 * Renders a list of Actions with the provided childs.
 *
 * If the action has onClick defined, it is rendered inside an IconButton, and the onClick method is moved to the IconButton.
 *
 * Optionally you can display the component on the left hand side of the page with the left attribute.
 */
export const ActionButtonsField = (props: ActionButtonsFieldProps) => {
    const {data} = props;
    const classes = useStyles(props);

    return (
        <span className={classes.buttonContainer}>
            {React.Children.map(props.children, (child) => {
                if (!child) {
                    return null;
                }
                const {onClick, disabled} = child.props;
                const isDisabled = typeof disabled === "function" ? disabled(data) : disabled;
                return onClick ? (
                    <IconButton
                        size="small"
                        onClick={(event) => onClick(event, data)}
                        disabled={isDisabled}
                    >
                        {React.cloneElement(
                            child,
                            {
                                data,
                                onClick: undefined,
                                disabled: isDisabled
                            }
                        )}
                    </IconButton>
                ) : React.cloneElement(
                    child,
                    {data}
                );
            })}
        </span>
    );
};

export default ActionButtonsField;