import {
    getArrayDataContent,
    useCrudProps,
    useDeepCompareMemo,
    validateArray,
    validateCIDR,
    validateInt,
    validateIpv4,
    validateIpv4CIDR,
    validateMaxLength,
    validateMaxValue,
    validateMinLength,
    validateMinValue,
    validateNegativeRegex,
    validatePrivateIpv4,
    validateRegex,
    validateRequired
} from "@cuda-react/core";
import {get, isArray, isEqual, set} from "lodash";
import {useMemo} from "react";
import {useTranslation} from "react-i18next";
import apiResources from "../apiResources";
import {useWatch} from "react-hook-form";
import {TFunction} from "i18next";


type Network = {
    id?: string;
    type?: string,
    name?: string,
    port?: string,
    lanPorts?: string[],
    wanPort?: string,
    virtualLanId?: number | string,
    mode?: string,
    doublePort?: [string, string],
    doubleVirtualLanId?: [number | string, number | string],
    asn?: number | string
};
type AugmentedNetwork = Network & {
    networkId?: string
};

type Errors = Record<string, any>;

export const getNetworkId = (network: Network = {}): string => network.port + (network.virtualLanId ? "." + network.virtualLanId : "");
export const getExpressRouteNetworkId = (network: Network = {}, portIndex: number) => getNetworkId({
    port: get(network, `doublePort[${portIndex}]`),
    virtualLanId: get(network, `doubleVirtualLanId[${portIndex}]`)
});
export const getNetworkPorts = (network: any) => {
    if (network.mode === "bridge") {
        return [...network.lanPorts, network.wanPort];
    }
    if (network.mode === "expressRoute") {
        return network?.doublePort;
    }
    return [network.port];
};
export const getNetworkIds = (network: any) => {
    if (network.mode === "bridge") {
        return [...network.lanPorts, network.wanPort];
    }
    if (network.mode === "expressRoute") {
        return [network?.doublePort?.[0] + (network?.doubleVirtualLanId?.[0] ? "." + network?.doubleVirtualLanId?.[0] : ""),
            network?.doublePort?.[1] + (network?.doubleVirtualLanId?.[1] ? "." + network?.doubleVirtualLanId?.[1] : "")
        ];
    }
    return getNetworkId(network);
};
export const findNetworksMatching = (targetNetwork: Network) => (network: Network) =>
    network.mode === "expressRoute" ? (
        getExpressRouteNetworkId(network, 0) === getNetworkId(targetNetwork) ||
        getExpressRouteNetworkId(network, 1) === getNetworkId(targetNetwork)
    ) : getNetworkId(network) === getNetworkId(targetNetwork);
export const validateNetworks = (
    values: { [key: string]: Network[] },
    arrayKey: string,
    arrayLength: number | undefined,
    translate: TFunction,
    errors: Errors,
    usedNetworks: string[] = []) => {
    get(values, arrayKey, [])
        .slice(0, arrayLength || (get(values, arrayKey, [])).length)
        .forEach((value, index) => {
            switch (value && value.mode) {
                case "wwan":
                    if (usedNetworks.includes("lte")) {
                        set(errors, arrayKey + `[${index}].mode`, translate("tesseract.appliances.dialog.validation.duplicateWwan"));
                    }
                    usedNetworks.push("lte");
                    break;
                case "expressRoute":
                    if (usedNetworks.includes("expressRoute")) {
                        set(errors, arrayKey + `[${index}].mode`, translate("tesseract.appliances.dialog.validation.duplicateExpressRoute"));
                    }
                    usedNetworks.push("expressRoute");
                    if (usedNetworks.includes(getExpressRouteNetworkId(value, 0))) {
                        set(errors, arrayKey + `[${index}].doublePort[0]`, translate("tesseract.appliances.dialog.validation.duplicateNetwork"));
                        set(errors, arrayKey + `[${index}].doubleVirtualLanId[0]`, translate("tesseract.appliances.dialog.validation.duplicateNetwork"));
                    }
                    usedNetworks.push(getExpressRouteNetworkId(value, 0));
                    if (usedNetworks.includes(getExpressRouteNetworkId(value, 1))) {
                        set(errors, arrayKey + `[${index}].doublePort[1]`, translate("tesseract.appliances.dialog.validation.duplicateNetwork"));
                        set(errors, arrayKey + `[${index}].doubleVirtualLanId[1]`, translate("tesseract.appliances.dialog.validation.duplicateNetwork"));
                    }
                    usedNetworks.push(getExpressRouteNetworkId(value, 1));
                    break;
                case "bridge":
                    if (value && value.wanPort && usedNetworks.some(((networkId) => networkId.split(".")[0] === (value.wanPort)))) {
                        set(errors, arrayKey + `[${index}]wanPort`, translate("tesseract.appliances.dialog.validation.duplicateNetwork"));
                    }
                    value && value.wanPort && usedNetworks.push(value.wanPort);

                    value && value.lanPorts && value.lanPorts.forEach((lanPort, lanIndex) => {
                        if (usedNetworks.some(((networkId) => networkId === (lanPort)))) {
                            set(errors, arrayKey + `[${index}]lanPorts[${lanIndex}]`, translate("tesseract.appliances.dialog.validation.duplicateNetwork"));
                        }
                        usedNetworks.push(lanPort);
                    });
                    break;
                default:
                    if (values?.wans?.find((wan) => wan.mode === "bridge")) {
                        const bridgeIndex = values.wans.findIndex((wan) => wan.mode === "bridge");
                        const bridgeWanPort = values.wans[bridgeIndex].wanPort;
                        const bridgeLanPorts = values.wans[bridgeIndex].lanPorts;

                        if (bridgeWanPort === value.port || (value.port && bridgeLanPorts?.includes(value.port))) {
                            set(errors, arrayKey + `[${index}].port`, translate("tesseract.appliances.dialog.validation.duplicateNetwork"));
                        }
                    }

                    if (values?.lans?.find((lan) => lan.mode === "bridge")) {
                        const bridgeIndex = values.lans.findIndex((lan) => lan.mode === "bridge");
                        const bridgeLanPorts = values.lans[bridgeIndex].lanPorts;

                        if (value.port && bridgeLanPorts?.includes(value.port)) {
                            set(errors, arrayKey + `[${index}].port`, translate("tesseract.appliances.dialog.validation.duplicateNetwork"));
                        }
                    }

                    if (value && value.port && usedNetworks.includes(getNetworkId(value))) {
                        set(errors, arrayKey + `[${index}].port`, translate("tesseract.appliances.dialog.validation.duplicateNetwork"));
                        set(errors, arrayKey + `[${index}].virtualLanId`, translate("tesseract.appliances.dialog.validation.duplicateNetwork"));
                    }
                    value && usedNetworks.push(getNetworkId(value));
            }
        });
    return usedNetworks;
};
export const validateNames = (values: {
    [key: string]: Network[]
}, arrayKey: string, arrayLength: number | undefined, translate: TFunction, errors: Errors, usedNames: string[] = []) => {
    get(values, arrayKey, [])
        .slice(0, arrayLength || get(values, arrayKey, []).length)
        .forEach((value, index) => {
            if (value && usedNames.includes(get(value, "name", ""))) {
                set(errors, arrayKey + "[" + index + "].name", translate("tesseract.appliances.dialog.validation.duplicateName"));
            }
            value && usedNames.push(get(value, "name", ""));
        });
    return usedNames;
};

const networkFormValidation = (existingNetworks: Network[], asnData: number[], translate: TFunction) => (values: Network = {}, initialValues: Network = {}) => {
    const errors: Errors = {};

    // Verify name is unique
    if (values.name !== initialValues.name && existingNetworks.find((network) => network.name === values.name)) {
        errors.name = translate("tesseract.appliances.dialog.validation.duplicateName");
    }

    if (values.mode === "pppoe" && initialValues.mode !== "pppoe" && existingNetworks.filter((wan, index) => wan.mode === "pppoe").length >= 4) {
        set(errors, "mode", translate("tesseract.appliances.dialog.validation.exceededPPoE"));
    }

    // Verify port/vlan id is unique
    if (values.mode === "expressRoute" && (!isEqual(values.doublePort, initialValues.doublePort) || !isEqual(values.doubleVirtualLanId, initialValues.doubleVirtualLanId))) {
        if (existingNetworks.find((network) => getNetworkId(network) === getExpressRouteNetworkId(values, 0))) {
            set(errors, "doublePort[0]", translate("tesseract.appliances.dialog.validation.duplicateNetwork"));
            set(errors, "doubleVirtualLanId[0]", translate("tesseract.appliances.dialog.validation.duplicateNetwork"));
        }
        if (
            existingNetworks.find((network) => getNetworkId(network) === getExpressRouteNetworkId(values, 1)) ||
            getExpressRouteNetworkId(values, 0) === getExpressRouteNetworkId(values, 1)
        ) {
            set(errors, "doublePort[1]", translate("tesseract.appliances.dialog.validation.duplicateNetwork"));
            set(errors, "doubleVirtualLanId[1]", translate("tesseract.appliances.dialog.validation.duplicateNetwork"));
        }
    }

    if (values.mode === "bridge") {
        const usedNetworkIds = existingNetworks.filter((network) => network.id !== values.id).flatMap(getNetworkIds);
        const usedPorts = existingNetworks.filter((network) => network.id !== values.id).flatMap(getNetworkPorts);
        const otherInlineBridges = values.wanPort && existingNetworks.some((network) => network.id !== values.id && network.mode === "bridge" && network.wanPort);
        if (otherInlineBridges) {
            set(errors, "mode", translate("tesseract.appliances.dialog.validation.oneInlineBridge"));
        }

        if (values.wanPort && (usedPorts.includes(values.wanPort) || values.lanPorts?.includes(<string>values.wanPort))) {
            set(errors, "wanPort", translate("tesseract.appliances.dialog.validation.duplicateNetwork"));
        }

        values.lanPorts?.some((port, index) => {
            if (usedNetworkIds.includes(port)) {
                set(errors, `lanPorts[${index}]`, translate("tesseract.appliances.dialog.validation.duplicateNetwork"));
            }
        });
    }

    if (values.mode !== "bridge" && existingNetworks.find((network, index) => network.mode === "bridge")) {
        const bridgeIndex = existingNetworks.findIndex((network) => network.mode === "bridge");

        if (existingNetworks[bridgeIndex].id !== values.id && existingNetworks[bridgeIndex].wanPort === values.port) {
            errors.port = translate("tesseract.appliances.dialog.validation.duplicateNetwork");
        }

        // @ts-ignore
        if (existingNetworks[bridgeIndex].id !== values.id && existingNetworks[bridgeIndex].lanPorts.includes(<string>getNetworkId(values))) {
            errors.port = translate("tesseract.appliances.dialog.validation.duplicateNetwork");
        }
    }

    if (values.mode !== "bridge" && values.mode !== "wwan" && values.mode !== "expressRoute" && getNetworkId(values) !== getNetworkId(initialValues) && existingNetworks.find(findNetworksMatching(values))) {
        errors.port = translate("tesseract.appliances.dialog.validation.duplicateNetwork");
        errors.virtualLanId = translate("tesseract.appliances.dialog.validation.duplicateNetwork");
    }

    // Verify only one wwan
    if (values.mode !== initialValues.mode && values.mode === "wwan" && existingNetworks.find((network) => network.mode === values.mode)) {
        errors.mode = translate("tesseract.appliances.dialog.validation.duplicateWwan");
    }

    // Verify only one express route
    if (values.mode !== initialValues.mode && values.mode === "expressRoute" && existingNetworks.find((network) => network.mode === values.mode)) {
        errors.mode = translate("tesseract.appliances.dialog.validation.duplicateExpressRoute");
    }

    // Verify asn is updated to an available value
    if (values.mode === "expressRoute" && `${values.asn}` !== `${initialValues.asn}` && !asnData.some((asn) => `${asn}` === `${values.asn}`)) {
        errors.asn = translate("tesseract.appliances.dialog.validation.availableAsn");
    }

    return errors;
};

export const useNetworkFormValidation = () => {
    const [wans = [], lans = []] = useWatch({name: ["wans", "lans"]});
    const existingNetworks = [...wans, ...lans];
    // Type instantiation is excessively deep and possibly infinite.
    // @ts-ignore
    const [translate] = useTranslation();
    const asnData = getArrayDataContent(useCrudProps(apiResources.availableAsns)[0]?.data);

    return useDeepCompareMemo(() => networkFormValidation(existingNetworks, asnData, translate), [existingNetworks, asnData]);
};

type Route = { name?: string };

const routeFormValidation = (existingRoutes: Route[], translate: TFunction) => (values: Route = {}, initialValues: Route = {}) => {
    const errors: Errors = {};

    // Verify name is unique
    if (values.name !== initialValues.name && existingRoutes.find((route) => route.name === values.name)) {
        errors.name = translate("tesseract.appliances.dialog.validation.duplicateName");
    }

    return errors;
};

export const useRouteFormValidation = () => {
    const existingRoutes = useWatch({name: "routes"}) || [];
    const [translate] = useTranslation();

    return useDeepCompareMemo(() => routeFormValidation(existingRoutes, translate), [existingRoutes]);
};

type Reservation = {
    name?: string,
    clientId?: string,
    ipAddress?: string,
    mac?: string,
    networkId?: string
};

const reservationFormValidation = (lans: AugmentedNetwork[], translate: TFunction) => (values: Reservation = {}, initialValues: Reservation = {}) => {
    const errors = {};
    if (values.networkId) {
        const lan = lans.find((lan) => getNetworkId(lan) === values.networkId);
        const reservations: Reservation[] = get(lan, "dhcp.reservations", []);

        if (values.clientId && values.clientId !== initialValues.clientId && reservations.some((reservation) => reservation.clientId === values.clientId)) {
            set(errors, "clientId", translate("tesseract.appliances.settings.dhcp.reservations.duplicate"));
        }
        if (values.mac && values.mac !== initialValues.mac && reservations.some((reservation) => reservation.mac === values.mac?.replace(/-/g, ":"))) {
            set(errors, "mac", translate("tesseract.appliances.settings.dhcp.reservations.duplicate"));
        }
        if (values.ipAddress && values.ipAddress !== initialValues.ipAddress && reservations.some((reservation) => reservation.ipAddress === values.ipAddress)) {
            set(errors, "ipAddress", translate("tesseract.appliances.settings.dhcp.reservations.duplicate"));
        }
        const allReservations: Reservation[] = lans.flatMap((lan) => get(lan, "dhcp.reservations", []));
        if (values.name && values.name !== initialValues.name && allReservations.some((reservation) => reservation.name === values.name)) {
            set(errors, "name", translate("tesseract.appliances.settings.dhcp.reservations.duplicateName"));
        }
    }
    return errors;
};
export const useReservationFormValidation = () => {
    const lans = useWatch({name: "lans"}) || [];
    const [translate] = useTranslation();

    return useDeepCompareMemo(() => reservationFormValidation(lans, translate), [lans]);
};

export const useSiteInputValidations = () => {
    const [translate] = useTranslation();

    return useMemo(() => ({
        validateIp: [validateIpv4],
        validateCidr: [validateCIDR],
        validateCidrArray: [validateArray(validateCIDR)],
        validatePrivateIp: [validatePrivateIpv4],
        validateIpArray: [validateArray(validateIpv4)],
        validateIpv4CidrArray: [validateArray(validateIpv4CIDR)],
        validateStaticAddress: [validateIpv4],
        validateRequiredPrivateIpArray: [validateArray([validateRequired, validatePrivateIpv4], true)],
        validateName: [validateMaxLength(64), validateRegex(/^[a-zA-Z0-9-]+$/, translate("tesseract.validation.alphaNumericDash"))],
        validateSiteName: [
            validateMaxLength(64),
            validateRegex(/^[a-zA-Z0-9-]+$/, translate("tesseract.validation.alphaNumericDash")),
            validateNegativeRegex(/^.*-ha$/i, translate("tesseract.validation.alphaNumericDashNoHaSuffix"))
        ],
        validateNetmask: [validateInt, validateMaxValue(32), validateMinValue(1)],
        validateRequiredNetmaskArray: [validateArray([validateRequired, validateInt, validateMaxValue(32), validateMinValue(1)])],
        validatePin: [validateRegex(/^[0-9]{4}$/, translate("tesseract.appliances.settings.wan.invalidPinFormat"))],
        validateDefaultLeaseTime: [validateInt, validateMinValue(0)],
        validateMaxLeaseTime: [
            validateInt,
            validateMinValue(0),
            (value: string, allValues: any) => parseInt(value) < parseInt(get(allValues, "dhcp.defaultLeaseTime")) ?
                translate("tesseract.appliances.settings.dhcp.networks.validateMaxLeaseTime") :
                undefined
        ],
        validatePassword: [validateMaxLength(255)],
        validateMac: [validateRegex(/^((([0-9A-Fa-f]{2}:){5})|(([0-9A-Fa-f]{2}-){5}))[0-9A-Fa-f]{2}$/, translate("tesseract.appliances.settings.dhcp.reservations.validateMac"))],
        validateHostname: [validateRegex(/^[a-zA-Z0-9-_]*$/, translate("tesseract.appliances.settings.dhcp.reservations.validateHostname"))],
        validateVlanId: [validateInt, validateMinValue(1), validateMaxValue(4094)],
        validateVlanIdArray: [validateArray([validateInt, validateMinValue(1), validateMaxValue(4094)])],
        validateWansLength: [validateInt, validateMinValue(1), validateMaxValue(16)],
        validateLansLength: [validateInt, validateMinValue(0), validateMaxValue(16)],
        validateSCWansLength: [validateInt, validateMinValue(1), validateMaxValue(2)],
        validateSCLansLength: (maxValue: number) => [validateInt, validateMinValue(1), validateMaxValue(maxValue)],
        validateAsn: [validateInt, validateMinValue(64512), validateMaxValue(65514)],
        validateAsnIpsec: [validateInt, validateMinValue(64512), validateMaxValue(65534)],
        validateAsnAny: [validateInt, validateMinValue(0)],
        validateSerials: [validateArray([validateRequired], true)],
        validateWifiPassphrase: [validateMinLength(8), validateMaxLength(63)],
        validateWifiSsid: [validateMaxLength(32), validateRegex(/^[a-zA-Z0-9-]+$/, translate("tesseract.appliances.settings.lan.wifi.validateSsid"))],
        validateAlphaNumericDotsColonsDash: (maxLenght: number) => [validateMaxLength(maxLenght), validateRegex(/^[a-zA-Z0-9.:-]+$/, translate("tesseract.validation.alphaNumericDotsColonsDash"))],
        validateTunnelLifetime: [validateInt, validateMinValue(0), validateMaxValue(2147483647)], //  0 < z < 2B
    }), []);
};

export const getNetworkNameFromPorts = (portsData: { port: string, name: string }[]) => {
    const generateNetworkName = (port?: string, virtualLanId?: number | string) => ((portsData.find((portData) => portData.port === port) || {}).name || "") + (virtualLanId ? "." + virtualLanId : "");
    const getPortName = (portId: string) => (portId.length == 2 ? (portsData.find((portData) => portData.port === portId) || {}).name : (portId.charAt(0).toUpperCase() + portId.slice(1)) || "");

    return (network: Network) => {
        if (network && network.mode === "expressRoute") {
            return generateNetworkName(get(network, "doublePort[0]"), get(network, "doubleVirtualLanId[0]"))
                + ", " + generateNetworkName(get(network, "doublePort[1]"), get(network, "doubleVirtualLanId[1]"));
        } else if (network && network.mode === "bridge") {
            let lanPorts;
            if (network.lanPorts && network.lanPorts.length > 1) {
                lanPorts = network.lanPorts.map(getPortName).join(", ");
            } else if (network.lanPorts && network.lanPorts.length == 1) {
                lanPorts = getPortName(network.lanPorts[0])
            } else {
                lanPorts = "";
            }
            return network.wanPort ? `${network.wanPort && getPortName(network.wanPort)}, ${lanPorts}` : lanPorts;
        }
        return generateNetworkName(network.port, network.virtualLanId);
    };
};

export const formatSCErrors = (errors: Errors, originalValues: Record<string, any>, formattedValues: Record<string, any>) => {
    const wifiValue = get(formattedValues, "wifi");
    const lanErrors = get(errors, "lan");
    const wifiErrors = get(errors, "wifi");
    if (wifiErrors || (lanErrors && wifiValue)) {
        const wifiIndex = get(originalValues, "lans", []).findIndex((lan: Network) => lan.port === "wifi");
        const lans = get(errors, "lans", Array(get(formattedValues, "lans", []).length));
        isArray(lans) && lans.splice(wifiIndex, 0, wifiErrors);
        return {...errors, lans};
    }
    return errors;
};