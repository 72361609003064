import React, {useState} from "react";
import {CardSizes, CardsRecord} from "../../../typesAndConstants";
import {Theme} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import {Divider, Grid} from "@barracuda-internal/bds-core";
import {DialogBody, Form, Input, SelectInput} from "@cuda-react/core";
import {SizeSelect} from "../SizeSelect";
import {StyledComponentProps} from "@cuda-react/theme";
import {sortBy} from "lodash";
import {ActiveDashboardCard} from "../../../hooks/useDashboards";

const styles = (theme: Theme) => createStyles({
    previewWindow: {
        backgroundColor: "#F8F8F8",
        overflowY: "hidden",
        height: 388,
        padding: "16px",
    },
    overlay: {
        backgroundColor: "rgb(0,0,0,0)",
        position: "relative",
        height: 388,
        marginTop: -388,
    },
    divider: {
        marginLeft: "8px",
        marginRight: "8px"
    },
    inputRoot: {
        width: "100%",
    },
    inputLabel: {
        width: 140
    },
    select: {
        width: 380,
    },
    toggle: {
        width: 386,
    }
});
const useStyles = makeStyles(styles);

export interface PreviewDialogContentProps extends StyledComponentProps<typeof styles> {
    /**
     * The cards available for selection
     */
    cards: CardsRecord<any>,
    /**
     * Is this dialog open
     */
    open?: boolean,
    /**
     * Callback when dialog add button is pressed
     */
    onAdd?: (cardId: string | undefined, size: CardSizes) => void,
    /**
     * Callback when the dialog cancel button is pressed
     */
    onClose?: () => void
    /**
     * if true, the small size is preselected and all other options disabled
     */
    forceSmall?: boolean,
    /**
     * Contains information of a card that's been pre-selected
     */
    selectedCard?: ActiveDashboardCard,
}

export const PreviewDialogContent = (props: PreviewDialogContentProps) => {
    const {cards, onAdd, onClose, forceSmall, selectedCard} = props;
    const classes = useStyles(props);
    const cardsArray = sortBy(Object.keys(cards).map((cardId) => ({key: cardId, ...cards[cardId]})), (card) => card.key);
    const [preview, setPreview] = useState<any>({card: cardsArray?.[0]?.key, size: CardSizes.small});

    return (
        <DialogBody
            title={selectedCard ? "stratosReporting.previewDialog.editTitle" : "stratosReporting.previewDialog.title"}
            form
            onClose={onClose}
        >
            <Form
                onChange={(data) => setPreview(data)}
                save={(values) => {
                    onAdd?.(values.card, values.size);
                    onClose?.();
                    return Promise.resolve({});
                }}
                initialValues={
                    selectedCard ? {
                        card: selectedCard.componentId,
                        size: selectedCard.size
                    } : undefined
                }
                onCancel={onClose}
                formButtonToolbarProps={{
                    label: `stratosReporting.previewDialog.${selectedCard ? "ok" : "add"}`
                }}
            >
                <SelectInput
                    source="card"
                    choices={cardsArray}
                    label="stratosReporting.previewDialog.card"
                    inputLabelProps={{
                        classes: {root: classes.inputRoot, inputLabel: classes.inputLabel}
                    }}
                    inputProps={{
                        classes: {select: classes.select}
                    }}
                    optionCategory={cardsArray[0]?.type ? "type" : undefined}
                    sortByDisplayName
                />
                <Input
                    source="size"
                    label="stratosReporting.previewDialog.size"
                    component={SizeSelect}
                    inputLabelProps={{
                        classes: {root: classes.inputRoot, inputLabel: classes.inputLabel}
                    }}
                    inputProps={{
                        classes: {toggleGroup: classes.toggle},
                        forceSmall
                    }}
                />
                <Divider className={classes.divider}/>
                <Grid container justifyContent="center" alignItems="center" className={classes.previewWindow}>
                    {preview?.card && cards[preview?.card] && preview?.size && React.createElement<any>(
                        cards[preview?.card].component,
                        {
                            size: preview.size, preview: true, onEventClick: () => undefined,
                        })}
                </Grid>
                <Grid className={classes.overlay} onClick={(event) => event.preventDefault()}/>
            </Form>
        </DialogBody>
    );
};