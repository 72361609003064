import React, {ReactNode} from "react";
import {useGlobalParam} from "@cuda-react/core";
import {TenantWorkspaceContext} from "../hooks/tenantWorkspaceHooks";
import useAccountAndWorkspace from "../hooks/useAccountAndWorkspace";

export interface TenantWorkspaceProviderProps {
    children: ReactNode;
}

const AppProvider = ({children}: TenantWorkspaceProviderProps): JSX.Element => {
    const [subscription = {}] = useGlobalParam("userData.subscription");
    const isSubscribed = subscription?.setUpComplete && subscription?.licensed && !subscription?.deprecated;
    const {
        availableAccounts,
        changeWorkspace,
        currentAccount,
        defaultAccount,
        refreshAccounts,
        swapAccount,
        workspaceChoices,
        currentWorkspace
    } = useAccountAndWorkspace(isSubscribed);

    return (
        <TenantWorkspaceContext.Provider value={{
            availableAccounts,
            changeWorkspace,
            currentAccount,
            defaultAccount,
            refreshAccounts,
            swapAccount,
            workspaceChoices,
            currentWorkspace
        }}>
            {children}
        </TenantWorkspaceContext.Provider>
    );
};

export default AppProvider;