import React, {ReactElement} from "react";
import {useTranslation} from "react-i18next";
import classNames from "classnames";
import {GridColumn, GridColumnProps, GridProps} from "@progress/kendo-react-grid";
import {Theme} from "@mui/material";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import {createStyles} from "@mui/styles";
import {CellRender} from "../../utils/commonTypes";
import {useDataTablePersonalisedColumnsChildProps} from "./useDataTablePersonalisedColumns";

const styles = (theme: Theme) => createStyles({
    cells: {
        padding: (props: useDataTableFieldColumnsProps) => props.compact ? "8px" + " !important" : undefined
    },
    headerCells: {
        padding: (props: useDataTableFieldColumnsProps) => props.compact ? "8px" + " !important" : undefined,
        // @ts-ignore important isn't recognised as part of a valid option
        whiteSpace: "normal !important",
        lineHeight: 1.3,
        verticalAlign: "middle !important",
        textTransform: "uppercase",
        "& a.k-link": {
            display: "flex !important",
            alignItems: "center",
            cursor: (props: useDataTableFieldColumnsProps) => props.reorderable ? undefined : "default",
            minHeight: "1rem"
        },
        "&:hover": {
            borderRightWidth: (props: useDataTableFieldColumnsProps) => props.resizable ? 1 : undefined,
            borderLeftWidth: (props: useDataTableFieldColumnsProps) => props.resizable ? 1 : undefined,
            borderLeftColor: (props: useDataTableFieldColumnsProps) => props.resizable ? "inherit" : undefined
        }
    },
    sortableHeaderCells: {
        "& a.k-link": {
            cursor: "pointer"
        }
    },
});
const useDataTableFieldColumnsStyles = makeOverrideableStyles("DataTableFieldColumns", styles);

const generateUniqueKey = (child: useDataTableFieldColumnsChild, index: number) => {
    const key = "" + child.key;
    return key.startsWith(".$") ? key.replace(".$", "") : (child.props.source || index);
};

export interface useDataTableFieldColumnsChildProps extends useDataTablePersonalisedColumnsChildProps {
    /**
     * custom className to apply to each cell in the column
     */
    cellClassName: string,
    /**
     * props to pass straight to the GridColumn
     */
    columnProps: GridColumnProps,
    /**
     * data for the current row of the table
     */
    data: object,
    /**
     * if set the row should be disabled
     */
    disabled?: boolean,
    /**
     * title for the column, shown in the header
     */
    label: string,
    /**
     * if true, the GridColumns will be flagged as sortable by default.
     */
    sortable?: boolean
    /**
     * the name of the field which the column relates to
     */
    source: string,
}

interface useDataTableFieldColumnsChild extends ReactElement<useDataTableFieldColumnsChildProps> {
}

export interface useDataTableFieldColumnsProps extends Pick<GridProps, "reorderable" | "resizable">, StyledComponentProps<typeof styles> {
    /**
     * if true, reduces the amount of padding on each cell to make a more compact table.
     */
    compact?: boolean,
    /**
     * callback called on each cell rendering.
     * It is passed the data associated with the current row, and if sets the returned value to the "disabled" prop or the rendered Field component.
     */
    isDisabled?: (dataItem: object) => boolean,
    /**
     * set all columns to be sortable, can be overridden for individual columns by explicitly setting sortable=false on a child
     */
    sortable?: boolean
}

export interface useDataTableFieldColumnsReturn {
    /**
     * resultant table children
     */
    children: ReactElement[],
    /**
     * a custom render function to render a table cell
     */
    cellRender: CellRender
}

/**
 * Converts *Field children to GridColumn children and generates a cellRender function.
 *
 * This allows *Field children to be converted for use with the BDS DataTable, so that the pre-made handling of data values
 * that *Field components provide can be utilised.
 *
 */
export const useDataTableFieldColumns = (
    children: useDataTableFieldColumnsChild[],
    props: useDataTableFieldColumnsProps
): useDataTableFieldColumnsReturn => {
    const {isDisabled, sortable} = props;
    const [translate] = useTranslation();
    const classes = useDataTableFieldColumnsStyles(props);

    return {
        children: children.map((child, childIndex) => {
            const childIsSortable = Boolean((sortable && child.props.sortable !== false) || child.props.sortable);
            return (
                <GridColumn
                    key={generateUniqueKey(child, childIndex)}
                    field={child.props.source}
                    title={child.props.label && translate(child.props.label) || " "}
                    sortable={childIsSortable}
                    className={classNames(classes.cells, child.props.cellClassName)}
                    headerClassName={classNames(classes.headerCells, child.props.cellClassName, childIsSortable && classes.sortableHeaderCells)}
                    width={child.props.width}
                    minResizableWidth={child.props.minResizableWidth}
                    // @ts-ignore consumed by useDataTablePersonalisedColumns
                    editColumnLabel={child.props.editColumnLabel}
                    hideByDefault={child.props.hideByDefault}
                    alwaysVisible={child.props.alwaysVisible}
                    format={"fieldColumn_" + childIndex}
                    {...child.props.columnProps}
                />
            );
        }),
        cellRender: (cell, {className, dataIndex, dataItem, format}) => {
            if (format && format.startsWith("fieldColumn_")) {
                const childIndex = +format.split("_")[1];
                const child = children[childIndex];
                return (
                    <td className={className}>
                        {/* @ts-ignore it doesn't appear to detect the children of td here, and therefore thinks the types are wrong */}
                        {child && React.cloneElement(child, {
                            data: {
                                index: dataIndex,
                                ...dataItem
                            },
                            disabled: isDisabled?.(dataItem)
                        }) || null}
                    </td>
                );
            }
            return cell;
        }
    };
};