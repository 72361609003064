import React from "react";
import {SvgIcon, useTokens} from "@barracuda-internal/bds-core";
import classNames from "classnames";

export interface DomainsIconProps {
    className?: string,
    /** size (in pixels) of icon */
    size?: number
}

export const DomainsIcon = (props: DomainsIconProps) => {
    const tokens = useTokens();
    return (
        <SvgIcon
            className={classNames(props.className, "cuda-react-icon")}
            id="cuda-icon-custom-apps"
            viewBox="0 0 60 60"
            style={{
                width: props.size ?? 50,
                height: props.size ?? 50,
                fillRule: "evenodd",
                clipRule: "evenodd",
                strokeLinejoin: "round",
                strokeMiterlimit: 2
            }}
        >
            <path style={{fill: tokens.globalBlue50, strokeWidth: 0}}
                  d="M30,54.6c-3.4,0-6.5-.6-9.5-1.9-3-1.3-5.6-3.1-7.9-5.3s-4-4.9-5.3-7.9c-1.3-3-1.9-6.2-1.9-9.5s.6-6.6,1.9-9.6c1.3-3,3.1-5.6,5.3-7.8,2.2-2.2,4.9-4,7.9-5.3,3-1.3,6.2-1.9,9.5-1.9s6.6.6,9.6,1.9c3,1.3,5.6,3.1,7.8,5.3,2.2,2.2,4,4.8,5.3,7.8,1.3,3,1.9,6.2,1.9,9.6s-.6,6.5-1.9,9.5c-1.3,3-3.1,5.6-5.3,7.9-2.2,2.2-4.8,4-7.8,5.3-3,1.3-6.2,1.9-9.6,1.9ZM30,49.6c1.1-1.5,2-3,2.8-4.6.8-1.6,1.4-3.3,1.9-5.1h-9.4c.5,1.8,1.1,3.5,1.9,5.1s1.7,3.1,2.8,4.6ZM23.6,48.6c-.7-1.4-1.4-2.8-1.9-4.2-.6-1.5-1-3-1.4-4.5h-7.3c1.2,2.1,2.7,3.8,4.5,5.4,1.8,1.5,3.8,2.6,6.1,3.4ZM36.4,48.6c2.3-.7,4.3-1.9,6.1-3.4,1.8-1.5,3.3-3.3,4.5-5.4h-7.3c-.4,1.6-.8,3.1-1.4,4.5-.6,1.5-1.2,2.9-1.9,4.2ZM10.9,34.9h8.4c-.1-.8-.2-1.6-.3-2.4s0-1.6,0-2.5,0-1.7,0-2.5.2-1.6.3-2.4h-8.4c-.2.8-.4,1.6-.5,2.4s-.2,1.6-.2,2.5,0,1.7.2,2.5.3,1.6.5,2.4ZM24.2,34.9h11.6c.1-.8.2-1.6.3-2.4s0-1.6,0-2.5,0-1.7,0-2.5-.2-1.6-.3-2.4h-11.6c-.1.8-.2,1.6-.3,2.4s0,1.6,0,2.5,0,1.7,0,2.5.2,1.6.3,2.4ZM40.7,34.9h8.4c.2-.8.4-1.6.5-2.4s.2-1.6.2-2.5,0-1.7-.2-2.5-.3-1.6-.5-2.4h-8.4c.1.8.2,1.6.3,2.4s0,1.6,0,2.5,0,1.7,0,2.5-.2,1.6-.3,2.4ZM39.7,20.1h7.3c-1.2-2.1-2.7-3.8-4.5-5.4-1.8-1.5-3.8-2.6-6.1-3.4.7,1.4,1.4,2.8,1.9,4.2.6,1.5,1,3,1.4,4.5ZM25.3,20.1h9.4c-.5-1.8-1.1-3.5-1.9-5.1-.8-1.6-1.7-3.1-2.8-4.6-1.1,1.5-2,3-2.8,4.6s-1.4,3.3-1.9,5.1ZM13,20.1h7.3c.4-1.6.8-3.1,1.4-4.5s1.2-2.9,1.9-4.2c-2.3.7-4.3,1.9-6.1,3.4-1.8,1.5-3.3,3.3-4.5,5.4Z"/>
        </SvgIcon>
    );
};

export default DomainsIcon;