import React from "react";
import {CardSizes} from "../../../typesAndConstants";
import {ResourceStatisticsCard} from "../../baseCards";
import generatePreviewData from "../../../utils/previewDataGenerators/ztnaResources";
import apiResources from "../../../apiResources";
import {useCardFetch} from "../../../hooks";
import {ChipArrayField, getArrayDataContent, TextField, useCrudProps, useTableChoices} from "@cuda-react/core";
import {ReportingTheme} from "../../../themes";

export interface ZTNAResourcesCardProps {
    preview?: boolean,
    size: CardSizes
}

export const ZTNAResourcesCard = (props: ZTNAResourcesCardProps) => {
    const {preview, size} = props;
    const {data = {}} = useCardFetch({url: !preview ? apiResources.ZTNAResources : undefined});
    const {total, custom, inUse} = (preview ? generatePreviewData() : data) || {};
    const renderGroups: any = useTableChoices(apiResources.groups, {optionValue: "id", filterId: "id"});
    const renderApplication: any = useTableChoices(apiResources.applications, {optionValue: "id", filterId: "applicationId"});
    const renderUsers: any = useTableChoices(apiResources.users, {optionValue: "id", filterId: "id"});
    const securityPostureChoices = useCrudProps(apiResources.zeroTrustPoliciesSecurityPosture)[0]?.data;

    const overviewData = [
        {title: "stratosReporting.ZTNAResources.total", value: total || 0, color: ReportingTheme.genericColors.blue},
        {title: "stratosReporting.ZTNAResources.custom", value: custom || 0, color: ReportingTheme.genericColors.turquoise},
    ];
    const totalValue = inUse? inUse : 0;

    return (
        <ResourceStatisticsCard
            overViewValue={totalValue}
            overviewTitle="stratosReporting.ZTNAResources.title"
            customOverviewTitle="stratosReporting.ZTNAResources.inUse"
            overViewData={overviewData}
            tableTitle="stratosReporting.ZTNAResources.tableName"
            size={size}
            preview={preview}
            resource={apiResources.zeroTrustPolicies}
        >
            <TextField source="name" label="stratosReporting.ZTNAResources.table.name"/>
            <ChipArrayField
                source="accessCriteria.users"
                label="stratosReporting.ZTNAResources.table.users"
                render={renderUsers}
            />
            <ChipArrayField
                source="accessCriteria.groups"
                label="stratosReporting.ZTNAResources.table.groups"
                render={renderGroups}
            />
            <ChipArrayField
                source="resourceCriteria.applications"
                label="stratosReporting.ZTNAResources.table.resources"
                render={renderApplication}
            />
           <ChipArrayField
                source="accessCriteria.securityPosture"
                label="stratosReporting.ZTNAResources.table.devicePosture"
                choices={getArrayDataContent(securityPostureChoices)}
            />
        </ResourceStatisticsCard>
    );
};