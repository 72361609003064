import React from "react";
import {ChipArrayField, ListCardContent, TextField} from "@cuda-react/core";
import {StatusIcon} from "@cuda-react/icons";
import apiResources from "./../../../../apiResources";
import {makeStyles} from "@mui/styles";
import {BaseCard, DashboardCardProps} from "@stratos/reporting";
import {siteGatewayStatusIcons} from "../../../../components/iconMapping";

export interface SiteSummaryCardProps extends DashboardCardProps {
    additionalProps?: {
        details: {
            id?: number | string;
            modelSeries?: "S" | "T";
        };
    }
}

const useStyles = makeStyles((theme) => ({
    serialIconSpan: {
        lineHeight: 3,
        paddingLeft: 20,
        "& svg": {
            top: 2,
            left: 4,
            position: "absolute"
        }
    },
    serialChip: {
        position: "relative"
    }
}));

export const SiteSummaryCard: React.FC<SiteSummaryCardProps> = (props) => {
    const {additionalProps, size, preview} = props;
    const {details} = additionalProps || {};
    const isSc = details?.modelSeries === "S" || !details?.modelSeries; // Check model series condition
    const classes = useStyles();

    return (
        <BaseCard
            id="SiteSummaryCard"
            title="tesseract.appliances.dashboard.summary.siteStatus.title"
            size={size}
            preview={preview}
        >
            <ListCardContent
                getFrom={{
                    resource: apiResources.siteDashboardStatus,
                    params: {id: details?.id}
                }}
            >
                <TextField label="tesseract.appliances.dashboard.summary.siteStatus.model" source="model"/>
                <ChipArrayField
                    label={`tesseract.appliances.dashboard.summary.siteStatus.serial${isSc ? "" : "s"}`}
                    source="connectionStatus.connectionStates"
                    classes={{chip: classes.serialChip}}
                    render={(val: any) => (
                        <span className={classes.serialIconSpan}>
                            <StatusIcon
                                iconMap={siteGatewayStatusIcons}
                                status={val.state}
                            />
                            {val.id}
                        </span>
                    )}
                />
                <TextField label="tesseract.appliances.dashboard.summary.siteStatus.release" source="version"/>
            </ListCardContent>
        </BaseCard>
    );
};