import React from "react";
import {useGlobalParam, useMixpanel, usePreview} from "@cuda-react/core";
import {
    CardSizes,
    DashboardPage,
    ReportingDataCards,
    ReportingDataProvider,
    ZtnaDashboardDataCards
} from "@stratos/reporting";
import {useTranslation} from "react-i18next";
import useCustomDashboard from "../../../hooks/useCustomDashboard";
import {securityDashboardCards, webFilterDashboardCards, ztnaDashboardCards} from "../../dashboard/Dashboard";
import useReportingApiAndFilters from "../../../hooks/reportingDashboard/useReportingApiAndFilters";
import {useSiteDashboardCards} from "./cards";

interface SiteDashboardProps {
    details: {
        id?: string;
        name?: string;
        serials?: string[];
        uuid?: string;
        modelSeries?: "S" | "T";
        model?: string;
        applicationId?: string | number;
    };
    type: "gateways" | "sites";
}

export const siteDashboardCards = [
    {componentId: "SitesTunnelLocationsCard", size: CardSizes.large},
    {componentId: "SiteSummaryCard", size: CardSizes.small},
    {componentId: "SiteGatewayCard", size: CardSizes.small},
    {componentId: "SiteLicensesCard", size: CardSizes.medium},
    {componentId: "FirmwareDetailsCard", size: CardSizes.small},
    {componentId: "FirmwareHotfixesCard", size: CardSizes.small},
    {componentId: "ConfigurationUpdatesCard", size: CardSizes.small},
    {componentId: "ApplianceServicesCard", size: CardSizes.small},
    {componentId: "VPNTunnelsSitesCard", size: CardSizes.medium},
    {componentId: "ApplianceInterfacesCard", size: CardSizes.medium},
    {componentId: "ApplianceResourcesCard", size: CardSizes.medium},
    {componentId: "DeploymentStatusCard", size: CardSizes.medium},
    {componentId: "DeploymentLocationsCard", size: CardSizes.large},
    {componentId: "DeploymentEventsCard", size: CardSizes.large}
];

const SiteDashboard: React.FC<SiteDashboardProps> = (props) => {
    const {type, details} = props;
    const expert = usePreview("expert");
    const [translate] = useTranslation();
    const templateDashboards = [{
        name: translate("tesseract.dashboard.status"),
        cards: siteDashboardCards,
        template: translate("tesseract.dashboard.status")
    }, {
        name: translate("tesseract.dashboard.security"),
        cards: securityDashboardCards,
        template: translate("tesseract.dashboard.security")
    }, {
        name: translate("tesseract.dashboard.webFilter"),
        cards: webFilterDashboardCards,
        template: translate("tesseract.dashboard.webFilter")
    }, {
        name: translate("tesseract.dashboard.ztnaDashboard"),
        cards: ztnaDashboardCards,
        template: translate("tesseract.dashboard.ztnaDashboard")
    }, {
        name: translate("tesseract.dashboard.emptyTemplate"),
        cards: []
    }];
    const defaultDash = templateDashboards.slice(0, 4);
    const availableCards = {
        ...useSiteDashboardCards(expert),
        ...ReportingDataCards,
        ...ZtnaDashboardDataCards
    };
    const {
        remoteStoredCustomDashboards,
        handleDashboardFetch,
        loadingDashboards
    } = useCustomDashboard("siteDashboard", templateDashboards);

    const reportingApi = useReportingApiAndFilters({
        key: details.id && String(details.id) || "_NONE_",
        name: details.name || "",
        type: "site"
    }, true);
    const isDemo = useGlobalParam("userData.mode")[0] === "demo";

    useMixpanel(
        "Sites Dashboard",
        !!details.model,
        {model: details.model || "", type: details.modelSeries || ""}
    );

    return (
        <ReportingDataProvider
            data={reportingApi.data}
            loading={reportingApi.loading}
            register={reportingApi.register}
            unregister={reportingApi.unregister}
            addFilter={reportingApi.updateFilter}
        >
            <DashboardPage
                id="siteDashboard"
                availableCards={availableCards}
                defaultDashboards={defaultDash}
                templates={templateDashboards}
                filters={{filterButtons: reportingApi.filterButtons, activeFilters: reportingApi.activeFilters}}
                remoteStoredDashboards={isDemo ? undefined : remoteStoredCustomDashboards}
                loadingRemoteStoredDashboards={isDemo ? undefined : loadingDashboards}
                onDashboardsChange={isDemo ? undefined : handleDashboardFetch}
                maxDashboardsAmount={15}
                additionalProps={{details, type}}
            />
        </ReportingDataProvider>
    );
};

export default SiteDashboard;