import React from "react";
import {SvgIcon, useTokens} from "@barracuda-internal/bds-core";
import classNames from "classnames";
import {StyledComponentProps} from "@cuda-react/theme";

interface PrivateEdgeIconProps extends StyledComponentProps {
    className?: string;
    classes?: {icon: string};
}


export const PrivateEdgeIcon = (props: PrivateEdgeIconProps) => {
    const tokens = useTokens();
    return (
        <SvgIcon
            className={classNames(props.className, props.classes?.icon, "cuda-react-icon")}
            id="cuda-icon-private-edge"
            viewBox="0 0 60 60"
        >
            <path style={{fill: tokens.aliasesColorTextStaticNeutral, strokeWidth: 0}}
                  d="M55.4,27.5c-.7-.6-1.5-.9-2.4-1-.9,0-1.8.4-2.4,1-.2.2-.3.4-.5.7h-3.3v-4c0-.6-.2-1.1-.6-1.5h0s0,0,0,0c-.2-.2-.4-.4-.6-.5l-2.4-2.1c-.4-.5-1.1-.8-1.8-.8h-3.5l3.7-6.8c.3,0,.6,0,.9-.1.9-.3,1.6-.9,2-1.7.4-.8.5-1.7.2-2.6-.3-.9-.9-1.6-1.7-2-.8-.4-1.7-.5-2.6-.2-1.8.6-2.8,2.5-2.2,4.3,0,.3.2.5.3.7l-4.6,8.5h-7.7l-4.6-8.5c.1-.2.3-.5.3-.7.6-1.8-.4-3.7-2.2-4.3-.9-.3-1.8-.2-2.6.2-.8.4-1.4,1.1-1.7,2-.3.9-.2,1.8.2,2.6.4.8,1.1,1.4,2,1.7.3,0,.6.1.9.1l3.7,6.8h-3.5c-.8,0-1.4.3-1.9.9h0s-2.4,2-2.4,2c-.2.1-.4.3-.6.5h0s0,0,0,0c-.3.4-.6,1-.6,1.5v4h-3.3c-.2-.3-.3-.5-.6-.7-.7-.6-1.5-.9-2.4-1-.9,0-1.8.4-2.4,1-1.3,1.4-1.3,3.5,0,4.8.7.6,1.5,1,2.4,1s1.8-.4,2.5-1c.2-.2.3-.4.5-.6h3.3v6.5c0,1.4,1.1,2.5,2.5,2.5h6.5l-3.7,6.8c-.3,0-.6,0-.9.1-1.8.6-2.8,2.5-2.2,4.3.3.9.9,1.6,1.7,2,.5.3,1,.4,1.6.4s.7,0,1-.2c1.8-.6,2.8-2.5,2.2-4.3,0-.3-.2-.5-.3-.7l4.6-8.5h7.7l4.6,8.5c-.1.2-.3.5-.3.7-.6,1.8.4,3.7,2.2,4.3.3.1.7.2,1,.2,1.5,0,2.8-.9,3.3-2.4.3-.9.2-1.8-.2-2.6-.4-.8-1.1-1.4-2-1.7-.3,0-.6-.1-.9-.1l-3.7-6.8h6.5c1.4,0,2.5-1.1,2.5-2.5v-6.5h3.3c.1.2.3.5.5.7.7.6,1.5,1,2.4,1s1.8-.3,2.5-1h0c1.3-1.4,1.3-3.5,0-4.8ZM41.4,9s0,0,0,0c0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0ZM18.7,9s0,0,0,0c0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0ZM6.9,30.1s0,0,0-.1c0,0,0,0,0,0s0,0,0,0c0,0,0,0,0,.1,0,0,0,0-.1,0ZM18.7,51s0,0,0,0c0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0ZM41.4,51s0,0,0,0c0,0,0,0,0,0s0,0,0,0c0,0,0,0,0,0,0,0,0,0,0,0ZM42.6,36.6h-25.1v-10.1h25.1v10.1ZM53.1,30.1h0c0,0,0,0,0,0,0,0,0,0,0,0ZM53.1,30h0c0,0,0,0,0,0,0,0,0,0,0,.1h0Z"/>
            <rect style={{fill: tokens.aliasesColorTextStaticNeutral, strokeWidth: 0}} x="21" y="29.6" width="3.8" height="3.8"/>
        </SvgIcon>
    );
};

export default PrivateEdgeIcon;