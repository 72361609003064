import {makeStyles} from "@mui/styles";
import {
    ActionButtonsField,
    ChipArrayField,
    ConnectedTableRefresh,
    DeleteDialog,
    getArrayDataContent,
    StatusIconField,
    TableAction,
    TablePage,
    TextField,
    useCrudProps,
    useMixpanel
} from "@cuda-react/core";
import {flatMap} from "lodash";
import React, {useRef} from "react";
import AddNotificationDialog from "./AddNotificationDialog";
import EditNotificationDialog from "./EditNotificationDialog";
import apiResources from "../../../apiResources";
import {Typography} from "@barracuda-internal/bds-core";
import {useTranslation} from "react-i18next";

export const styles = (theme: any) => ({
    table: {
        "&:title": {
            minWidth: "260px"
        },
        "&:tbody:td": {
            overflow: "visible !important"
        },
        "&:th:first-child": {
            width: "50px !important",
            textAlign: "center !important"
        },
        "&:tr:td:first-child, &:tr:th:last-child": {
            textAlign: "center !important"
        },
        "&:td.column-id": {
            width: "120px"
        },
    },
    rowActions: {
        width: "120px"
    },
    name: {
        width: "20%"
    },
    message: {
        marginLeft: "24px",
        marginTop: "8px"
    }
});
const useStyles = makeStyles(styles);

export const NotificationList = () => {
    const registerAction = useMixpanel("Notifications");
    const notificationStatuses = useCrudProps(apiResources.auditStatuses, {}, {}, true)[0]?.data;
    const notificationEvents = useCrudProps(apiResources.auditEntriesEvents, {}, {}, true)[0]?.data;
    const notificationEventsReformatted = flatMap(getArrayDataContent(notificationEvents), (group) => group.events);
    const tableRefreshRef = useRef<ConnectedTableRefresh>(null);
    const classes = useStyles();
    const [translate] = useTranslation();

    return (
        <TablePage
            titleActions={
                <AddNotificationDialog
                    key="add"
                    onSuccess={() => {
                        registerAction("Create");
                        tableRefreshRef.current?.();
                    }}
                />
            }
            exportResource={{resource: apiResources.notificationExport, filename: "notification"}}
            resource={apiResources.notification}
            title="tesseract.logs.pageTitle"
            subtitle="tesseract.logs.notifications"
            defaultSort={{field: "name", dir: "asc"}}
            // className={classes.table}
            minCellWidth={160}
            refreshRef={tableRefreshRef}
            actions={
                <TableAction left>
                    <Typography variant="body1" className={classes.message}>
                        {translate("tesseract.logs.notificationsMessage")}
                    </Typography>
                </TableAction>
            }
        >
            <StatusIconField
                isNotification={true}
                source="status"
                tooltipSource="statusName"
                label="tesseract.settings.tabs.notification.labels.status"
                width={128}
                filter="selectarray"
                filterProps={{
                    choices: getArrayDataContent(notificationStatuses)
                }}
                sortable
            />
            <TextField
                source="name"
                label="tesseract.settings.tabs.notification.labels.name"
                cellClassName={classes.name}
                filter="text"
                sortable
            />
            <ChipArrayField
                source="eventNames"
                label="tesseract.settings.tabs.notification.labels.events"
                maxChips={7}
                filter="selectarray"
                filterProps={{
                    choices: notificationEventsReformatted
                }}
                columnProps={{
                    field: "event"
                }}
            />
            <ChipArrayField
                source="administrators"
                label="tesseract.settings.tabs.notification.labels.administrators"
                maxChips={2}
                filter="text"
                columnProps={{
                    field: "administrator"
                }}
            />
            <ActionButtonsField
                cellClassName={classes.rowActions}
                label="tesseract.settings.tabs.notification.labels.actions"
                width={120}
                source="id"
            >
                <EditNotificationDialog
                    id={(data: any) => data.id}
                    onSuccess={() => {
                        registerAction("Update");
                        tableRefreshRef.current?.();
                    }}
                />
                <DeleteDialog
                    resource={apiResources.notification}
                    title="tesseract.settings.tabs.notification.delete"
                    message="tesseract.settings.tabs.notification.action.deleteNotification"
                    onSuccess={() => {
                        registerAction("Delete");
                        tableRefreshRef.current?.();
                    }}
                />
            </ActionButtonsField>
        </TablePage>
    );
};

export default NotificationList;