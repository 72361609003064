import "isomorphic-fetch";
import React, {ElementType, ReactNode} from "react";
import {Route, Switch} from "react-router";
import BccRedirectLoginPage from "../authentication/BccRedirectLoginPage/BccRedirectLoginPage";
import BccUnauthorizedPage from "../authentication/BccUnauthorizedPage/BccUnauthorizedPage";
import AppLayout, {AppLayoutProps} from "../layout/AppLayout/AppLayout";
import LogoutMenu from "../authentication/LogoutMenu/LogoutMenu";
import UnsupportedPage from "../pages/UnsupportedPage/UnsupportedPage";
import {browserIsIE} from "../../utils";
import "@progress/kendo-theme-material/dist/all.css";
import {NavigationRoute} from "@barracuda-internal/bds-core";
import {LanguageFullReset} from "../../i18n";

export interface CudaReactLayoutProps {
    /**
     * additional buttons to show on the navigation bar, alongside the logout button
     */
    additionalNavbarOptions?: AppLayoutProps["additionalOptions"],
    /**
     * Children of CudaReactApp should be "Route"s, and are used to generate the pages of the app.
     */
    children?: ReactNode,
    /**
     * Provide a custom login page. See [Login Page](/?path=/docs/cudareactapp-authentication--page#login-page) for more information.
     */
    customLoginPage?: ReactNode,
    /**
     * Provide a custom logout button. Can be used to extend the logout button menu. See [Logout Button](/?path=/docs/cudareactapp-authentication--page#logout-button) for more information.
     */
    customLogoutMenu?: ReactNode,
    /**
     * Provide a custom Unauthorized access page. See [Logout Button](/?path=/docs/cudareactapp-authentication--page#unauthorized-page) for more information.
     */
    customUnauthorizedPage?: ElementType,
    /**
     * Components to rendered into the Footer bar.
     */
    footerItems?: ReactNode | ReactNode[],
    /**
     * Children, which are expected to be react-router Routes, that want declaring outside of the main AppLayout.
     */
    fullPageRoutes?: ReactNode | ReactNode[],
    /**
     * Resource url or name which provides a list of available accounts for the logged in user. If provided, the account switcher option in the Logout menu is displayed.
     */
    listAccountsResource?: string,
    /**
     * Navigation routes to pass to the NavigationBar.
     * as the "routes" prop, in order to generate the navigation buttons.
     */
    navigation?: NavigationRoute[],
    /**
     * Children that should always be rendered, regardless of the current page loaded. This is most often used for purely functional components, rather than decorative components, as children provided here will always be visible.
     */
    nonRouteChildren?: ReactNode | ReactNode[],
    /**
     * True if authentication is required (so renders logout menu, and login/unauthorized pages)
     */
    requiresAuthentication?: boolean,
    /**
     * the web app title to display next to the barracuda logo.
     */
    title: string
}


/**
 * Main app layout for the CudaReactApp. Intended to be rendered within a navigation Router (or CudaReactProvider).
 *
 * For a tutorial on how to get started with CudaReactApp, see the [Getting Started](/?path=/docs/cudareactapp-getting-started-creating-an-app--creating-an-app) guide.
 */
const CudaReactLayout = ({
                             title,
                             additionalNavbarOptions,
                             children,
                             footerItems,
                             nonRouteChildren,
                             customLoginPage,
                             customLogoutMenu,
                             customUnauthorizedPage,
                             listAccountsResource,
                             navigation = [],
                             fullPageRoutes,
                             requiresAuthentication
                         }: CudaReactLayoutProps): JSX.Element => {
    const logout = requiresAuthentication ? (customLogoutMenu || <LogoutMenu/>) : null;
    const loginPage = requiresAuthentication ? (customLoginPage || BccRedirectLoginPage) : null;

    // If browser is IE, block and show the unsupported page
    const unsupported = browserIsIE();

    return (
        <LanguageFullReset>
            {unsupported ? (
                <UnsupportedPage/>
            ) : (
                //@ts-ignore Switch complains it can only have one child, seems to work fine with multiple...
                <Switch>
                    {/* @ts-ignore */}
                    {loginPage && (<Route path="/login" exact component={loginPage}/>)}
                    {requiresAuthentication && (
                        <Route path="/unauthorized" exact>
                            {customUnauthorizedPage ? (
                                React.createElement<any>(customUnauthorizedPage, {listAccountsResource})
                            ) : (
                                <BccUnauthorizedPage/>
                            )}
                        </Route>
                    )}
                    {fullPageRoutes || null}
                    <Route
                        path="/"
                        render={(routeProps) => (
                            <React.Fragment>
                                {nonRouteChildren || null}
                                <AppLayout
                                    logout={logout}
                                    additionalOptions={additionalNavbarOptions}
                                    footerItems={footerItems}
                                    navigation={navigation}
                                    {...routeProps}
                                >
                                    {children}
                                </AppLayout>
                            </React.Fragment>
                        )}
                    />
                </Switch>
            )}
        </LanguageFullReset>
    );
};

export default CudaReactLayout;