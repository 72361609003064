import React, {MutableRefObject, useRef} from "react";
import {Button, ButtonProps, IconButton, IconButtonProps, TextField} from "@barracuda-internal/bds-core";
import {useTranslation} from "react-i18next";
import {Assignment} from "@barracuda-internal/bds-core/dist/Icons/Core";

interface CopyToClipboardButtonBaseProps {
    value: string;
    label?: string;
    iconOnly?: boolean;
    onClick?: () => void;
    hideButton?: boolean;
    onClickRef?: MutableRefObject<(() => void) | null>;
}
interface CopyToClipboardButtonBasePropsIconButton extends CopyToClipboardButtonBaseProps, Partial<Omit<IconButtonProps, keyof CopyToClipboardButtonBaseProps>> {
    iconOnly: true
}
interface CopyToClipboardButtonBasePropsButton extends CopyToClipboardButtonBaseProps, Partial<Omit<ButtonProps, keyof CopyToClipboardButtonBaseProps>> {
    iconOnly?: false | undefined
}

type CopyToClipboardButtonProps = CopyToClipboardButtonBasePropsIconButton | CopyToClipboardButtonBasePropsButton;

const CopyToClipboardButton: React.FC<CopyToClipboardButtonProps> = ({
    value,
    label = "tesseract.copy.label",
    iconOnly,
    onClick,
    hideButton,
    onClickRef,
    ...props
}) => {
    const textRef = useRef<HTMLInputElement>(null);
    const [translate] = useTranslation();
    const onClickHandler = () => {
        onClick?.();
        if (!navigator?.clipboard) {
            textRef?.current?.select();
            document.execCommand("copy");
            textRef?.current?.setSelectionRange(0, 0);
        } else {
            navigator.clipboard.writeText(value);
        }
    };
    if (onClickRef) {
        onClickRef.current = onClickHandler;
    }
    return (
        <>
            {!hideButton ? (
                iconOnly ? (
                    <IconButton onClick={onClickHandler} {...props}>
                        <Assignment/>
                    </IconButton>
                ) : (
                    //@ts-ignore TODO: need to resolve IconButton color vs Button bdsType
                    <Button
                        onClick={onClickHandler}
                        variant="contained"
                        bdsType="interactiveEmphasis"
                        startIcon={<Assignment/>}
                        {...props}
                    >
                        {translate(label)}
                    </Button>
                )
            ) : null}
            <TextField inputRef={textRef} value={value} style={{display: "none"}}/>
        </>
    );
};

export default CopyToClipboardButton;