import React from "react";
import {SvgIcon, useTokens} from "@barracuda-internal/bds-core";
import classNames from "classnames";

const ConfigUpdatesIcon = ({className}: { className?: string }) => {
    const tokens = useTokens();
    return (
        <SvgIcon
            className={classNames(className, "cuda-react-icon")}
            id="cuda-icon-config-updates"
            viewBox="0 0 60 60"
        >
            <path style={{fill: tokens.aliasesColorTextStaticNeutral, strokeWidth: 0}}
                  d="M40.8,28.8l-.7-3.5c-.5-.2-.9-.4-1.3-.6s-.8-.5-1.3-.8l-3.4,1.1-2.3-4,2.7-2.3c0-.5-.1-1-.1-1.5,0-.5,0-1,.1-1.5l-2.7-2.3,2.3-4,3.4,1.1c.4-.3.8-.6,1.3-.8s.8-.4,1.3-.6l.7-3.5h4.7s.7,3.5.7,3.5c.5.2.9.4,1.3.6s.8.5,1.3.8l3.4-1.1,2.3,4-2.7,2.3c0,.5.1,1,.1,1.5s0,1-.1,1.5l2.7,2.3-2.3,4-3.4-1.1c-.4.3-.8.6-1.3.8s-.8.4-1.3.6l-.7,3.5h-4.7ZM46.4,20.5c.9-.9,1.4-2,1.4-3.3s-.5-2.4-1.4-3.3-2-1.4-3.3-1.4-2.4.5-3.3,1.4c-.9.9-1.4,2-1.4,3.3s.5,2.4,1.4,3.3,2,1.4,3.3,1.4,2.4-.5,3.3-1.4ZM18,54.5l-1.1-4.9c-.7-.2-1.3-.5-1.8-.8s-1.1-.7-1.7-1.1l-4.7,1.5-3.3-5.6,3.7-3.3c0-.7-.1-1.4-.1-2.1s0-1.4.1-2.1l-3.7-3.3,3.3-5.6,4.7,1.5c.5-.4,1.1-.8,1.7-1.1s1.2-.6,1.8-.8l1.1-4.9h6.5s1.1,4.9,1.1,4.9c.7.2,1.3.5,1.8.8s1.1.7,1.7,1.1l4.7-1.5,3.3,5.6-3.7,3.3c0,.7.1,1.4.1,2.1s0,1.4-.1,2.1l3.7,3.3-3.3,5.6-4.7-1.5c-.5.4-1.1.8-1.7,1.1s-1.2.6-1.8.8l-1.1,4.9h-6.5ZM26.3,43.1c1.4-1.4,2-3,2-5s-.7-3.6-2-5c-1.4-1.4-3-2-5-2-1.9,0-3.6.7-5,2s-2,3-2,5,.7,3.6,2,5c1.4,1.4,3,2,5,2s3.6-.7,5-2Z"/>
        </SvgIcon>
    );
};

export default ConfigUpdatesIcon;