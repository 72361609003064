import React, {useCallback, useState} from "react";
import {
    ConnectedWizard,
    InputStep,
    MultiStep,
    ResultStep,
    SubmitStep,
    useGlobalParam,
    useMixpanel,
} from "@cuda-react/core";
import {get, set} from "lodash";
import {useTranslation} from "react-i18next";
import {formatSCErrors, validateNames, validateNetworks,} from "../../../hooks/createEditSiteHooks";
import BasicStep from "./steps/common/BasicStep";
import SerialStep from "./steps/common/SerialStep";
import WanStep from "./steps/sc/WanStep";
import LanStep from "./steps/sc/LanStep";
import AdvancedStep from "./steps/common/AdvancedStep";
import SiteSubmitStep from "./steps/common/SiteSubmitStep";
import SiteResultStep from "./steps/sc/SiteResultStep";
import {makeStyles} from "@mui/styles";
import SelectWanFallbackStep from "./steps/sc/SelectWanFallbackStep";
import SelectLanLengthStep from "./steps/sc/SelectLanLengthStep";
import apiResources from "../../../apiResources";

const useStyles = makeStyles((theme) => ({
    root: {},
    dialogContent: {
        width: 850,
    },
    standardInputStep: {
        paddingLeft: "96px",
        paddingRight: "64px",
    },
    submitStep: {
        paddingLeft: "64px",
        paddingRight: "64px",
    },
}));

interface FormValues {
    wanTotal: any;
    lanTotal: any;
}

const initialValues = {
    wans: [{port: "p4", name: "ISP-Uplink-1"}, {name: "ISP-Uplink-2"}],
    wanTotal: "1",
    lanTotal: "1",
    wanFailover: false
};

interface CreateSCContentProps {
    onClose?: () => void;
    onSuccess?: (response: any) => void;
}

interface ErrorType {
    wans?: any[];
}

const CreateSCContent: React.FC<CreateSCContentProps> = ({onClose, onSuccess}) => {
    const registerAction = useMixpanel("Sites Create Site", "Start", {type: "SC"});
    const [translate] = useTranslation();
    const classes = useStyles();
    const [virtualWanId] = useGlobalParam("filter.virtualWanId");
    const [{wanTotal = 2, lanTotal = 1}, setFormValues] = useState<FormValues>(initialValues);
    const onChangeHandler = useCallback((newValues: any) => {
        const wanTotal = parseInt(get(newValues, "wanTotal", 2)) || 2;
        const lanTotal = parseInt(get(newValues, "lanTotal", 1)) || 1;
        setFormValues({
            wanTotal: wanTotal > 0 ? wanTotal : 1,
            lanTotal: lanTotal > 0 ? lanTotal : 1,
        });
    }, []);
    const onSuccessHandler = useCallback(
        (response: any) => {
            registerAction("Success", {
                model: response.model,
                type: "SC",
                wansLength: response.wans.length,
                lansLength: response.lans.length,
                isHa: response.serials.length > 1,
            });
            onSuccess?.(response);
        },
        []
    );
    const validationHandler = useCallback((values: any = {}) => {
        // Verify lan/wan/routes port usage and names
        const errors = {} as ErrorType;
        const totalWans = parseInt(get(values, "wanTotal")) || 1;
        const totalLans = parseInt(get(values, "lanTotal")) || 1;

        const usedNetworks = validateNetworks(values, "wans", totalWans, translate, errors, undefined);
        if (errors.wans) {
            errors.wans = (errors.wans as any[]).map((error: any) => ({mode: error.mode || "tesseract.sites.dialog.validation.duplicateSCwan"}));
        }
        validateNetworks(values, "lans", totalLans, translate, errors, usedNetworks);
        const usedNetworkNames = validateNames(values, "wans", totalWans, translate, errors);
        validateNames(values, "lans", totalLans, translate, errors, usedNetworkNames);
        validateNames(values, "routes", undefined, translate, errors);

        //Verify secondary DNS is not set if there is no primary
        values.secondaryDns && !values.primaryDns && set(errors, "secondaryDns", translate("tesseract.appliances.dialog.validation.secondaryDns"));

        // Return errors
        return errors;
    }, []);
    const formatRequestHandler = useCallback(
        (data: any) =>
            Object.assign({}, data, {
                serials: data.serials.map((appliance: any) => appliance.serial),
                wans: data.wans.slice(0, data.wanTotal).map((wan: any) => ({
                    ...wan,
                    virtualLanId: wan.virtualLanId ? parseInt(wan.virtualLanId) : null,
                    port: (wan.mode !== "wwan") ? wan.port : undefined
                })),
                wifi: {enabled: true, ...(data.lans.find((lan: any) => lan.port === "wifi") || {enabled: false})},
                lans: data.lans.slice(0, data.lanTotal)
                    .filter((lan: any) => lan.port !== "wifi")
                    .map((lan: any) => ({...lan, virtualLanId: lan.virtualLanId ? parseInt(lan.virtualLanId) : null})),
                virtualWan: virtualWanId
            }), []);

    return (
        <ConnectedWizard
            title="tesseract.sites.dialog.create.sc.dialogTitle"
            dialogBodyProps={{classes: {dialogContent: classes.dialogContent}}}
            resource={apiResources.siteScConfiguration}
            formatRequestData={formatRequestHandler}
            validate={validationHandler}
            initialValues={initialValues}
            onChange={onChangeHandler}
            formatError={formatSCErrors}
            onClose={onClose}
            onSubmitSuccess={onSuccessHandler}
        >
            <InputStep label="tesseract.appliances.dialog.basic.sectionTitle" >
                <BasicStep enforceGateway applianceType="site"/>
            </InputStep>
            <InputStep label={translate("tesseract.appliances.dialog.serials.sectionTitle", {context: "site"})}>
                <SerialStep mainMessage="tesseract.appliances.dialog.serials.SCSiteMessage" series="S"/>
            </InputStep>
            <MultiStep label="tesseract.appliances.dialog.wans.sectionTitle">
                {[
                    <InputStep key="mainInput">
                        <SelectWanFallbackStep/>
                    </InputStep>,
                    ...Array(Math.min(wanTotal, 16)).fill(null).map((value: any, index) => (
                        <InputStep key={index} classes={{content: classes.standardInputStep}}>
                            <WanStep stepIndex={index}/>
                        </InputStep>
                    ))
                ]}
            </MultiStep>
            <MultiStep label="tesseract.appliances.dialog.lans.sectionTitle">
                {[
                    <InputStep key="mainInput">
                        <SelectLanLengthStep/>
                    </InputStep>,
                    ...Array(Math.min(lanTotal, 16)).fill(null).map((value, index): React.ReactElement => (
                        <InputStep key={index} classes={{content: classes.standardInputStep}}>
                            <LanStep stepIndex={index}/>
                        </InputStep>
                    ))
                ]}
            </MultiStep>
            <InputStep label="tesseract.appliances.dialog.advanced.sectionTitle">
                <AdvancedStep series="S" applianceType="site"/>
            </InputStep>
            <SubmitStep label="tesseract.appliances.dialog.steps.submit" >
                <SiteSubmitStep applianceType="site"/>
            </SubmitStep>
            <ResultStep label="tesseract.appliances.dialog.steps.result" onClose={onClose}>
                <SiteResultStep />
            </ResultStep>
        </ConnectedWizard>
    );
};


export default CreateSCContent;