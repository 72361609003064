import {Typography} from "@barracuda-internal/bds-core";
import {CustomField} from "@cuda-react/core";
import React from "react";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@mui/styles";
import EmailTemplate from "./EmailTemplate";

export const useStyles = makeStyles((theme) => ({
    preview: {
        height: 300,
        margin: "8px auto",
        width: 600,
        //@ts-ignore TODO: this theme entry does exist. We should extend DefaultTheme to fix this properly (or BDS should do that).
        border: `${theme.palette.customDivider?.border?.color} solid 1px`,
        //@ts-ignore TODO: this theme entry does exist. We should extend DefaultTheme to fix this properly (or BDS should do that).
        backgroundColor: theme.palette.background.default,
        //@ts-ignore TODO: this theme entry does exist. We should extend DefaultTheme to fix this properly (or BDS should do that).
        borderRadius: theme.shape.borderRadius,
        overflowX: "hidden",
        overflowY: "auto",
        paddingTop: 16
    },
    emailPreview: {
        transform: "scale(0.7) translateY(-17%) translateX(-12%)",
        height: 580
    },
    message: {
        margin: "8px 16px 16px"
    },
    customField: {
        width: `100%`
    }
}));

export type EmailPreviewStepProps = {
    data?: any
};

export const EmailPreviewStep = ({data}: EmailPreviewStepProps) => {
    const classes = useStyles();
    const [translate] = useTranslation();

    return (
        <React.Fragment>
            <Typography className={classes.message}>
                {translate("tesseract.endpoint.enrollEmailDialog.sendMessage")}
            </Typography>
            <CustomField
                data={data}
                source="content"
                classes={{root: classes.customField}}
                render={(customMessage) => (
                    <div className={classes.preview}>
                        <div className={classes.emailPreview}>
                            <EmailTemplate content={customMessage} username="John Doe"/>
                        </div>
                    </div>
                )}
            />
        </React.Fragment>
    );
};
export default EmailPreviewStep;