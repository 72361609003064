import React from "react";
import {BooleanInput, ConnectedFormPage, FormSection} from "@cuda-react/core";
import apiResources from "../../../../apiResources";
import {Typography} from "@barracuda-internal/bds-core";
import {Trans} from "react-i18next";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    helpText: {
        padding: "8px 8px 32px 24px",
        //@ts-ignore TODO: this theme entry does exist. We should extend DefaultTheme to fix this properly (or BDS should do that).
        color: theme.palette.text.secondary
    }
}));

const WebFilterSettings = () => {
    const classes = useStyles();

    return (
        <ConnectedFormPage
            resource={apiResources.settings}
            title="tesseract.securityAndAccess.pageTitle"
            subtitle={["tesseract.securityAndAccess.webFilter", "tesseract.securityAndAccess.webFilterSettings"]}
            formatRequestData={(data: any) => (
                {
                    settings: {
                        safe_search: data.safe_search.toString(),
                        no_push_notifications_for_ads: data.silent_ad_blocking.toString()
                    }
                }
            )}
            formatResourceData={(data: any) => {
                const safe_search = data?.settings?.safe_search === "true";
                const silent_ad_blocking = data?.settings?.no_push_notifications_for_ads === "true";

                return {safe_search, silent_ad_blocking};
            }}
            params={{filter: {type: "virtualWan"}}}
            canReset
        >
            <FormSection title="tesseract.webFilterSettings.title" newStyle hideBorder>
                <Typography className={classes.helpText}>
                    <Trans i18nKey="tesseract.webFilterSettings.helpText">
                        <br/>
                    </Trans>
                </Typography>
                <BooleanInput
                    source="safe_search"
                    label="tesseract.webFilterSettings.safeSearch"
                    newStyle
                />
                <BooleanInput
                    source="silent_ad_blocking"
                    label="tesseract.webFilterSettings.silentAdBlocking"
                    newStyle
                />
            </FormSection>
        </ConnectedFormPage>
);};


export default WebFilterSettings;