import {get} from "lodash";
import React from "react";
import {Select, SelectArray} from "@cuda-react/core";
import {makeStyles} from "@mui/styles";
import {useTranslation} from "react-i18next";
import {Grid} from "@barracuda-internal/bds-core";

export const PEER_TYPE_FILTER = "peer-type";

const useStyles = makeStyles((theme) => ({container: {
        width: 256
    },
    selectFilter: {
        marginTop: "8px",
        width: "100%",
        maxWidth: 256
    },
    typeSelect: {
        width: "100%",
        maxWidth: 256
    }}));

const peerTypeChoices = [
    {key: "gateway", name: "tesseract.integration.ipSecV2.table.filters.secureEdgePeer.edgeService"},
    {key: "site", name: "tesseract.integration.ipSecV2.table.filters.secureEdgePeer.site"}
];

const IpsecLocalPeerFilter = (props: any) => {
    const {onChange, ipsecSiteWans, ipsecGatewayWans, value} = props;
    const classes = useStyles();
    const [translate] = useTranslation();

    const update = (field: string) => (eventOrValue: any) => onChange({
        [PEER_TYPE_FILTER]: get(value, PEER_TYPE_FILTER, ""),
        [field]: eventOrValue?.target ? eventOrValue.target.value : eventOrValue
    });


    const filter = get(value, PEER_TYPE_FILTER) === "gateway" ? (
        <SelectArray
            key="uuid"
            label="tesseract.integration.ipSecV2.table.filters.secureEdgePeer.edgeService"
            id="edgeService"
            optionValue="uuid"
            choices={ipsecGatewayWans}
            value={get(value, "uuid")}
            onChange={update("uuid")}
            className={classes.selectFilter}
        />
    ) : get(value, PEER_TYPE_FILTER) === "site" ? (
        <SelectArray
            key="uuid"
            id="site"
            optionValue="uuid"
            label="tesseract.integration.ipSecV2.table.filters.secureEdgePeer.site"
            choices={ipsecSiteWans}
            value={get(value, "uuid")}
            onChange={update("uuid")}
            className={classes.selectFilter}
        />
    ) : null;

    return (
        <Grid container direction="column" className={classes.container}>
            <Select
                id="cuda-filter-input-type"
                value={get(value, PEER_TYPE_FILTER, "")}
                onChange={(eventOrValue) => onChange({[PEER_TYPE_FILTER]: eventOrValue.target ? eventOrValue.target.value : eventOrValue})}
                label={translate("tesseract.integration.ipSecV2.table.filters.secureEdgePeer.peerType")}
                choices={peerTypeChoices}
                classes={{select: classes.typeSelect, formControl: classes.typeSelect}}
            />
            {filter}
        </Grid>
    );
};

export default IpsecLocalPeerFilter;