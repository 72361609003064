import {StratosWorkspaceIcon} from "@cuda-react/icons";
import React from "react";
import {makeStyles} from "@mui/styles";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
    workspaceIcon: {
        width: "28px !important",
        height: "28px !important",
        padding: 4,
        //@ts-ignore TODO: this theme entry does exist. We should extend DefaultTheme to fix this properly (or BDS should do that).
        backgroundColor: theme.palette.primary.main,
        borderRadius: 14,
        margin: "0 !important"
    },
    workspaceIconTitle: {
        marginTop: -8
    },
    workspaceIconFill: {
        //@ts-ignore TODO: this theme entry does exist. We should extend DefaultTheme to fix this properly (or BDS should do that).
        fill: theme.palette.background.paper
    }
}));

const SwapTenantIcon = () => {
    const classes = useStyles();
    return (
        <StratosWorkspaceIcon
            // @ts-ignore
            classes={{
                icon: classNames(classes.workspaceIcon, classes.workspaceIconTitle),
                primaryFill: classes.workspaceIconFill
            }}
        />
    );
};

export default SwapTenantIcon;