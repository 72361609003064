import React from "react";
import {ActionButtonsField, ButtonDialog, ConnectedTable, DialogBody, Form, Input, TextField} from "@cuda-react/core";
import {get} from "lodash";
import {makeStyles} from "@mui/styles";
import {getNetworkId} from "../../../../hooks/createEditSiteHooks";
import apiResources from "../../../../apiResources";
import {InputProps} from "@cuda-react/core/lib/components/inputs/Input/Input";
import {FormProps} from "@cuda-react/core/lib/components/forms/Form/Form";
import {Theme} from "@mui/material";


export interface FormContentCommonProps {
    formatInput: (value: any) => any,
    formatOutput: (newValues: any, values: any) => any,
    formChildren?: FormProps["children"],
    formValidate?: FormProps["validate"],
    value?: (object | string)[],
    onChange: (value: any) => void,
    onBlur: (value: any) => void
}

export interface FormContentProps extends FormContentCommonProps {
    initialValues?: object,
    onClose?: () => void
}

export const FormContent: React.FC<FormContentProps> = ({formatInput, formatOutput, formChildren, formValidate, initialValues, value, onChange, onBlur, onClose}) => {
    const onSubmit = (values: any) => {
        const existingValues = formatInput(value || []);
        const newValues = [...existingValues, values];
        const outputValues = formatOutput(newValues, value || []);
        onChange(outputValues);
        onBlur(outputValues);
        onClose && onClose();
        return Promise.resolve(values);
    };

    return (
        <DialogBody
            form
            title="tesseract.appliances.settings.dhcp.addReservationTitle"
        >
            <Form
                validate={formValidate}
                initialValues={initialValues}
                onCancel={onClose}
                save={onSubmit}
                formButtonToolbarProps={{
                    label: "cuda.state.ok"
                }}
            >
                {formChildren}
            </Form>
        </DialogBody>
    );
};

export interface FormDialogProps extends FormContentProps {
    disabled?: boolean,
    data?: object,
    lans?: any[],
    disableReserveButton?: (networkId?: string, ip?: string) => boolean
}
// Caching the row data at the time of opening the dialog, so that if the leases table data changes underneath it, the open form
// is not affected.
export const FormDialog: React.FC<FormDialogProps> = ({disabled, disableReserveButton, data, lans, ...props}) => {
    const [initialValues, setInitialValues] = React.useState({});
    const networkId = getNetworkId(lans && lans.find((lan) => get(lan, "id") === get(data, "lanConfigurationId")) || {});

    return (
        <ButtonDialog
            buttonText="tesseract.appliances.settings.dhcp.leases.reserve"
            disabled={disabled || (!!disableReserveButton && disableReserveButton(networkId, get(data, "ip")))}
            onOpen={() => setInitialValues({
                networkId,
                ipAddress: get(data, "ip"),
                mac: get(data, "mac")
            })}
        >
            <FormContent {...props} initialValues={initialValues}/>
        </ButtonDialog>
    );
};

export const styles = (theme: Theme) => ({
    flat: {
        margin: "16px"
    }
});
const useStyles = makeStyles(styles);

export interface DhcpLeasesReservationProps extends FormDialogProps, FormContentCommonProps {
    id?: number | string,
    applianceType?: "site" | "gateway"
}

export const DhcpLeasesReservation: React.FC<DhcpLeasesReservationProps> = ({id, applianceType, ...props}) => {
    const classes = useStyles();
    return (
        <div id="table-input-dhcp-leases">
            <ConnectedTable
                resource={applianceType === "site" ? apiResources.configurationDhcpLeases : apiResources.configurationOnPremDhcpLeases}
                params={{id}}
                noRouter
                flat
                classes={classes}
                pollInterval={60000}
                noDataMessage="tesseract.appliances.settings.dhcp.leases.noLeases"
                minCellWidth={164}
            >
                <TextField
                    source="subnetName"
                    label="tesseract.appliances.settings.dhcp.leases.port"
                    filter="text"
                    sortable
                />
                <TextField
                    source="mac"
                    label="tesseract.appliances.settings.dhcp.leases.mac"
                    filter="text"
                    sortable
                />
                <TextField
                    source="ip"
                    label="tesseract.appliances.settings.dhcp.leases.ip"
                    filter="text"
                    sortable
                />
                <ActionButtonsField source="id">
                    <FormDialog {...props} />
                </ActionButtonsField>
            </ConnectedTable>
        </div>
    );
};

export interface DhcpLeasesReservationInputProps extends Omit<InputProps<typeof DhcpLeasesReservation>, "component">, Pick<DhcpLeasesReservationProps, "id"> {}

export const DhcpLeasesReservationInput: React.FC<DhcpLeasesReservationInputProps> = (props) => (<Input {...props} component={DhcpLeasesReservation} />);

export default DhcpLeasesReservationInput;