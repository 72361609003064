import React from "react";
import Input, {InputProps} from "../Input/Input";
import TextArray from './TextArray';

export interface TextArrayInputProps extends Omit<InputProps<typeof TextArray>, "component" | "displayError"> {
    bulkInputDisabled?: boolean;
}

export const TextArrayInput = (props: TextArrayInputProps) => (
    <Input {...props} displayError={false} component={TextArray}/>
);
export default TextArrayInput;