import React from "react";
import {SvgIcon, useTokens} from "@barracuda-internal/bds-core";
import classNames from "classnames";

export const SiteIcon = ({className}: { className?: string }) => {
    const tokens = useTokens();
    return (
        <SvgIcon
            className={classNames(className, "cuda-react-icon")}
            id="cuda-icon-site"
            viewBox="0 0 60 60"
        >
            <path style={{fill: tokens.aliasesColorTextStaticNeutral, strokeWidth: 0}}
                  d="M5,52.5V7.5h25v10h25v35H5ZM10,47.5h5v-5h-5v5ZM10,37.5h5v-5h-5v5ZM10,27.5h5v-5h-5v5ZM10,17.5h5v-5h-5v5ZM20,47.5h5v-5h-5v5ZM20,37.5h5v-5h-5v5ZM20,27.5h5v-5h-5v5ZM20,17.5h5v-5h-5v5ZM30,47.5h20v-25h-20v5h5v5h-5v5h5v5h-5v5ZM40,32.5v-5h5v5h-5ZM40,42.5v-5h5v5h-5Z"/>
        </SvgIcon>
    );
};

export default SiteIcon;