import {SvgIcon, useTokens} from "@barracuda-internal/bds-core";
import React from "react";
import classNames from "classnames";

export const IotGraphic = ({className}: { className?: string }) => {
    const tokens = useTokens();
    return (
        <SvgIcon
            className={classNames(className, "cuda-react-graphic")}
            id="cuda-graphic-iot"
            viewBox="0 0 96 96"
        >
            <g id="Ebene-1">
                <g id="ArtBoard11">
                    <g id="Ebene-11">
                        <path style={{fill: tokens.aliasesColorTextStaticNeutral, strokeWidth: 0}}
                              d="M43.9,69.8v-23.2h-5v23.2c-3.2,1.1-5.6,4.1-5.6,7.7s3.6,8.1,8.1,8.1,8.1-3.6,8.1-8.1-2.3-6.6-5.6-7.7ZM41.4,82c-2.5,0-4.5-2-4.5-4.5s2-4.5,4.5-4.5,4.5,2,4.5,4.5-2,4.5-4.5,4.5Z"/>
                        <path style={{fill: tokens.aliasesColorIconSuccessNeutral, strokeWidth: 0}}
                              d="M71.7,51.8c-9.5,0-17.2,7.7-17.2,17.2s7.7,17.2,17.2,17.2,17.2-7.7,17.2-17.2-7.7-17.2-17.2-17.2ZM80.2,70.7h-6.9v6.9h-3.4v-6.9h-6.9v-3.4h6.9v-6.9h3.4v6.9h6.9v3.4Z"/>
                    </g>
                </g>
            </g>
            <path style={{fill: tokens.aliasesColorTextStaticNeutral, strokeWidth: 0}}
                  d="M67.2,32.1c-2-2.2-4.5-3.5-7.4-3.9h0c0-5.2-1.8-9.5-5.3-13-3.5-3.5-7.9-5.3-12.9-5.3s-7.8,1.3-11.1,3.7c-3.2,2.5-5.4,5.7-6.5,9.7h0c0,0,0,0,0,0-3.3.7-6,2.4-8,5.1-2,2.6-3.1,5.7-3.1,9.1s1.4,7.3,4.2,10c2.7,2.6,6,4,9.8,4.1,0,0,0,0,0,0v8.6h-3.8c-1.1-3.2-4.1-5.6-7.7-5.6s-8.1,3.6-8.1,8.1,3.6,8.1,8.1,8.1,6.6-2.3,7.7-5.6h6.3c1.4,0,2.5-1.1,2.5-2.5v-13.6c0-1.4-1.1-2.5-2.4-2.5h-2.7c-2.4,0-4.5-1-6.2-2.7-1.8-1.8-2.7-4-2.7-6.6s.9-4.8,2.7-6.6c1.8-1.8,4-2.7,6.6-2.7h1.2c0-3.6,1.3-6.7,3.9-9.3,2.6-2.6,5.7-3.9,9.4-3.9s6.8,1.3,9.4,3.9c2.6,2.6,3.9,5.7,3.9,9.4v5.1h3.8c1.9,0,3.4.6,4.7,1.9,1.3,1.3,1.9,2.9,1.9,4.7s-.6,3.4-1.9,4.7c-1.3,1.3-2.9,1.9-4.7,1.9,0,0-1.2,0-1.2,0h-2.2s-1.4,0-1.4,0c-1.4,0-2.5,1.1-2.5,2.5v13.6c0,.8.4,1.5.9,1.9.7-3.1,2.1-6,4.1-8.4v-4.7h2.3c3.2,0,6-1.1,8.3-3.4,2.3-2.3,3.4-5.1,3.4-8.3s-1-5.6-3-7.8ZM15.2,67.2c-2.5,0-4.5-2-4.5-4.5s2-4.5,4.5-4.5,4.5,2,4.5,4.5-2,4.5-4.5,4.5Z"/>
            <g>
                <path style={{fill: tokens.aliasesColorTextStaticNeutral, strokeWidth: 0}}
                      d="M41.2,30.6c3.3,0,6.3,1.3,8.4,3.5l1.9-1.9c-2.6-2.7-6.3-4.3-10.4-4.3s-7.7,1.7-10.4,4.3l1.9,1.9c2.2-2.1,5.2-3.5,8.4-3.5Z"/>
                <path style={{fill: tokens.aliasesColorTextStaticNeutral, strokeWidth: 0}}
                      d="M41.2,33.4c-2.5,0-4.8,1-6.5,2.7l1.9,1.9c1.2-1.2,2.8-1.9,4.5-1.9s3.4.7,4.5,1.9l1.9-1.9c-1.6-1.7-3.9-2.7-6.5-2.7Z"/>
                <path style={{fill: tokens.aliasesColorTextStaticNeutral, strokeWidth: 0}} d="M41.2,38.9c-1,0-1.9.4-2.6,1.1l2.6,2.6,2.6-2.6c-.7-.7-1.6-1.1-2.6-1.1Z"/>
            </g>
        </SvgIcon>
    );
};

export default IotGraphic;