import React from "react";
import {
    CustomField,
    getArrayDataContent, LinkField,
    LocationsCardContent, SelectedChoiceField, TextField,
    useCrudProps, useGlobalParam
} from "@cuda-react/core";
import apiResources from "./../../../../apiResources";
import {BaseCard, DashboardCardProps, CardSizes} from "@stratos/reporting";
import {getAzureHubLink, getAzureResourceLink} from "../../../../utils/azureUtils";
import {ReportingTheme} from "@stratos/reporting/lib/themes";

export interface SitesTunnelLocationsCardProps extends DashboardCardProps {
    preview?: boolean,
    size: CardSizes
    additionalProps?: {
        details: {
            id?: number | string,
            name?: string,
            gateway?: string | number,
        },
    }
}

export const SitesTunnelLocationsCard: React.FC<SitesTunnelLocationsCardProps> = (props) => {
    const {additionalProps, size, preview} = props;
    const {details} = additionalProps || {};
    const isDemo = useGlobalParam("userData.mode")[0] === "demo";
    const isStandaloneSite = !details?.gateway;
    const regionChoices = getArrayDataContent(useCrudProps(apiResources.gatewayRegions)[0]?.data);
    const scaleUnitChoices = getArrayDataContent(useCrudProps(apiResources.gatewayScaleUnits)[0]?.data);

    return (
        <BaseCard
            id="SitesTunnelLocationsCard"
            title={isStandaloneSite ? "tesseract.appliances.dashboard.tunnelLocations.standaloneSites.title" : "tesseract.appliances.dashboard.tunnelLocations.title"}
            size={size}
            preview={preview}
        >
            {details?.name && isStandaloneSite ? (
                <LocationsCardContent
                    params={{id: details?.id}}
                    resource={apiResources.standaloneSiteLocation}
                    series={[{
                        label: "tesseract.appliances.dashboard.tunnelLocations.standaloneSites.label",
                        color: ReportingTheme.genericColors.graphite,
                        zIndex: 4,
                        details: {
                            optionValue: "id",
                            fields: [
                                <LinkField
                                    label="tesseract.appliances.dashboard.tunnelLocations.sites.fields.name"
                                    source="name"
                                    key="name"
                                    link={(data) => `/infrastructure/sites/${data.id}`}
                                />,
                                <TextField
                                    label="tesseract.appliances.dashboard.tunnelLocations.sites.fields.location"
                                    source="summary"
                                    key="summary"
                                />,
                                <TextField
                                    label="tesseract.appliances.dashboard.tunnelLocations.sites.fields.providers"
                                    source="wans.length"
                                    key="wans"
                                />,
                            ],
                        },
                    },
                    ]}
                />
            ) : null}
            {details?.name && !isStandaloneSite ? (
                <LocationsCardContent
                    params={{id: details?.id}}
                    resource={apiResources.siteTunnelLocations}
                    series={[
                        {
                            label: "tesseract.appliances.dashboard.tunnelLocations.gateways.label",
                            color: ReportingTheme.genericColors.purple,
                            zIndex: 4,
                            linkedSeries: {
                                color: ReportingTheme.genericColors.darkBlue,
                                zIndex: 3,
                                selectedZIndex: 5
                            },
                            details: {
                                resource: apiResources.gateways,
                                optionValue: "id",
                                fields: [
                                    <LinkField
                                        label="tesseract.appliances.dashboard.tunnelLocations.gateways.fields.name"
                                        source="name"
                                        key="name"
                                        link={getAzureResourceLink("applicationId", isDemo)}
                                    />,
                                    <CustomField
                                        label="tesseract.appliances.dashboard.tunnelLocations.gateways.fields.sites"
                                        source="tseries"
                                        key="tseries"
                                        render={(sites, data) => (
                                            <LinkField
                                                defaultText={(sites?.length) || sites}
                                                link={`/infrastructure/sites?filter={"gateway":[${data.id}]}`}
                                            />
                                        )}
                                    />,
                                    <CustomField
                                        label="tesseract.appliances.dashboard.tunnelLocations.gateways.fields.iot"
                                        source="iot"
                                        key="iot"
                                        render={(sites, data) => (
                                            <LinkField
                                                defaultText={(sites?.length) || sites}
                                                link={`/infrastructure/iot?filter={"gateway":[${data.id}]}`}
                                            />
                                        )}
                                    />,
                                    <LinkField
                                        label="tesseract.appliances.dashboard.tunnelLocations.gateways.fields.vwan"
                                        source="virtualWan.name"
                                        key="vwanName"
                                        link={getAzureResourceLink("virtualWan.resourceId", isDemo)}
                                    />,
                                    <LinkField
                                        label="tesseract.appliances.dashboard.tunnelLocations.gateways.fields.hub"
                                        source="hubName"
                                        key="hubName"
                                        link={getAzureHubLink("hubId", isDemo)}
                                    />,
                                    <SelectedChoiceField
                                        label="tesseract.appliances.dashboard.tunnelLocations.gateways.fields.region"
                                        source="region"
                                        key="region"
                                        choices={getArrayDataContent(regionChoices)}
                                    />,

                                    <SelectedChoiceField
                                        label="tesseract.appliances.dashboard.tunnelLocations.gateways.fields.size"
                                        source="scaleUnit"
                                        key="scaleUnit"
                                        choices={getArrayDataContent(scaleUnitChoices)}
                                        render={(value: any, data: any, choices: {
                                            key: any;
                                            name: any;
                                        }) => choices.key === value ? value = (
                                            choices.name) : (value)}
                                    />
                                ]
                            }
                        },
                        {
                            label: "tesseract.appliances.dashboard.tunnelLocations.sites.label",
                            color: ReportingTheme.genericColors.blue,
                            source: "sites",
                            details: {
                                optionValue: "id",
                                fields: [
                                    <LinkField
                                        label="tesseract.appliances.dashboard.tunnelLocations.sites.fields.name"
                                        source="name"
                                        key="name"
                                        link={(data) => `/infrastructure/sites/${data.id}`}
                                    />,
                                    <TextField
                                        label="tesseract.appliances.dashboard.tunnelLocations.sites.fields.location"
                                        source="summary"
                                        key="summary"
                                    />,
                                    <LinkField
                                        label="tesseract.appliances.dashboard.tunnelLocations.sites.fields.gateway"
                                        source="gatewayName"
                                        key="gatewayName"
                                        link={(data) => `/infrastructure/gateways?filter={"name":"${data.gatewayName}"}`}
                                    />,
                                    <TextField
                                        label="tesseract.appliances.dashboard.tunnelLocations.sites.fields.providers"
                                        source="wanNetworks.length"
                                        key="wans"
                                    />,
                                ],
                            },
                        },
                    ]}
                />) : null}
        </BaseCard>
    );
};