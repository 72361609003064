import {Redirect, Route, useLocation} from "react-router-dom";
import LoadMfeChild from "./LoadMfeChild";
import * as React from "react";
import {useEffect, useState} from "react";
import mainApiResources from "../mainApiResources";
import CgwanModule from "@stratos/cgwan-ui";
import {useGlobalParam, usePreview} from "@cuda-react/core";

const pathRegexTest = /^\/([0-9]+)\/([0-9a-fA-F-]+)\/(.*)$/;

const MainPageRoutes = () => {
    const isReportsEnabled = usePreview('reports');
    const [mfeManifest, setMfeManifest] = useState<any>({});
    const [account] = useGlobalParam("userData.currentAccount");
    const [workspace] = useGlobalParam("userData.currentWorkspace");
    const {pathname, search, hash} = useLocation();
    const pathTest = pathRegexTest.exec(pathname);

    // MFE manifest is an anonymous path, so we can use a plain fetch.
    // Using useCrud* methods at this high-level can cause unwanted behaviours such full app as refreshes
    useEffect(() => {
        if (isReportsEnabled) {
            fetch(mainApiResources.mfeManifest)
                .then((response) => response.json())
                .then((data) => setMfeManifest(data));
        }
    }, [isReportsEnabled]);

    if (!pathTest) {
        if (!account || !workspace) return null;
        return (<Redirect key="addAccountWorkspaceRedirect"  to={`/${account}/${workspace}${pathname}${search}${hash}`} />);
    } else if (pathTest[1] !== account || pathTest[2] !== workspace) {
        return null;
    }

    return (
        <React.Fragment>
            {isReportsEnabled && <Route path="/:account/:workspace/logs/reports">
                <LoadMfeChild loadout={mfeManifest?.microfrontends?.reporting}/>
            </Route>}
            <Route path="/:account/:workspace/*" component={CgwanModule}/>
        </React.Fragment>
    );
};

export default MainPageRoutes;