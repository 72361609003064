import React, {useState} from "react";
import {makeStyles} from "@mui/styles";
import {Trans, useTranslation} from "react-i18next";
import {
    Button,
    Container,
    Dialog,
    FormControl,
    FormHelperText,
    IconButton,
    Typography
} from "@barracuda-internal/bds-core";
import {Table, TableBody, TableCell, TableHead, TableRow,} from "@mui/material";
import {
    CrudTypes,
    DateField,
    DialogBody,
    formatErrorMessage,
    getArrayDataContent,
    getDataContent,
    Input,
    SelectSerial,
    TextField,
    Toolbar,
    useCrudSubscription
} from "@cuda-react/core";
import {StatusIcon} from "@cuda-react/icons";
import {Add, Delete, Edit} from "@barracuda-internal/bds-core/dist/Icons/Core";
import {get} from "lodash";
import {siteGatewayStatusIcons} from "../../../iconMapping";
import apiResources from "../../../../apiResources";
import {InputProps} from "@cuda-react/core/lib/components/inputs/Input/Input";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        "&> *": {
            marginBottom: "8px"
        }
    },
    spaced: {
        "&> *": {
            marginRight: "8px"
        }
    },
    spacedIcons: {
        "&> *": {
            marginRight: "8px"
        },
        "& svg": {
            position: "relative",
            top: "6px"
        }
    },
    serialTable: {
        width: "auto",
        "& td": {
            padding: "8px 16px"
        },
        "& th": {
            paddingBottom: "4px",
            //@ts-ignore TODO: this theme entry does exist. We should extend DefaultTheme to fix this properly (or BDS should do that).
            color: theme.palette.text.secondary
        }
    },
    addCell: {
        textAlign: "center",
        borderBottom: "0"
    },
    cancel: {
        marginRight: 12
    },
    dialog: {
        width: 850
    },
    dialogDelete: {
        width: "auto",
        maxWidth: 700
    },
    dialogBody: {
        padding: 16
    },
    dialogPaper: {
        minWidth: "fit-content"
    },
    typographyBold: {
        fontWeight: "bold"
    }
}));

interface SerialEditProps {
    applianceType?: "site" | "gateway";
    value: any;
    onChange: (value: any) => void;
    onBlur: (value: any) => void;
    error: any;
    disabled?: boolean;
    source?: string
    model?: string;
    id?: string;
    noAdd?: boolean;
    series?: string;
}

export const SerialEdit: React.FC<SerialEditProps> = ({
                                                          applianceType,
                                                          value,
                                                          onChange,
                                                          onBlur,
                                                          disabled,
                                                          model,
                                                          id,
                                                          error,
                                                          noAdd,
                                                          series,
                                                      }) => {
    const classes = useStyles();
    const [translate] = useTranslation();
    const [dialog, setDialog] = useState<any>();
    const siteStatus = getDataContent(useCrudSubscription(
        CrudTypes.GET,
        applianceType === "site" ? apiResources.siteDashboardStatus : apiResources.gateways,
        {id},
        {pollInterval: 30000}
    )[0], {});
    const connectionStatus = get(siteStatus, "connectionStatus.connectionStates", []);

    // Find out if serial is a HA serial or not.
    const siteHA = getArrayDataContent(useCrudSubscription(
        CrudTypes.GET,
        applianceType === "site" ? apiResources.siteHA : apiResources.gatewayHA,
        {id},
        {pollInterval: 30000}
    )[0]);
    const isSecondary = (serial: any) => value && value.length > 1 && (siteHA.find((item) => item.serial === serial) || {}).primary === false;

    return (
        <React.Fragment>
            <FormControl error={!!error}>
                {Array.isArray(value) ? (
                    <Table className={classes.serialTable} id="table-input-serial">
                        <TableHead>
                            <TableRow>
                                <TableCell>{translate("tesseract.appliances.settings.basic.serialEdit.table.status")}</TableCell>
                                <TableCell>{translate("tesseract.appliances.settings.basic.serialEdit.table.serial")}</TableCell>
                                <TableCell/>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {value.map((serial) => (
                                <TableRow key={serial}>
                                    <TableCell>
                                        <StatusIcon
                                            iconMap={siteGatewayStatusIcons}
                                            status={(connectionStatus.find((instance: any) => instance.id === serial) || {}).state || ""}
                                        />
                                    </TableCell>
                                    <TableCell>{serial}</TableCell>
                                    <TableCell>
                                        <IconButton
                                            onClick={() => setDialog({mode: "edit", target: serial})}
                                            disabled={disabled}
                                            id="edit-serial-icon"
                                        >
                                            <Edit/>
                                        </IconButton>
                                        {value.length > 1 ? (
                                            <IconButton
                                                onClick={() => setDialog({mode: "delete", target: serial})}
                                                disabled={disabled}
                                                id="delete-serial-icon"
                                            >
                                                <Delete />
                                            </IconButton>
                                        ) : null}
                                    </TableCell>
                                </TableRow>
                            ))}
                            {value.length < 2 && !noAdd ? (
                                <TableRow>
                                    <TableCell colSpan={5} className={classes.addCell}>
                                        <Button
                                            onClick={() => setDialog({mode: "add"})}
                                            startIcon={<Add/>}
                                            disabled={disabled}
                                        >
                                            {translate("tesseract.appliances.settings.basic.serialEdit.table.add")}
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ) : null}
                        </TableBody>
                    </Table>
                ) : null}
                {error && (
                    <FormHelperText>{formatErrorMessage(error)}</FormHelperText>
                )}
            </FormControl>
            <Dialog
                open={!!dialog}
                classes={{paper: classes.dialogPaper}}
            >
                <DialogBody
                    onClose={() => setDialog(null)}
                    title={
                        dialog?.mode === "edit" ? "tesseract.appliances.settings.basic.serialEdit.editTitle" :
                            dialog?.mode === "add" ? "tesseract.appliances.settings.basic.serialEdit.addTitle" :
                                "tesseract.appliances.settings.basic.serialEdit.deleteTitle"
                    }
                    classes={{dialogContent: dialog && dialog.mode === "delete" ? classes.dialogDelete : classes.dialog}}
                >
                    <Container className={classes.dialogBody}>
                        {dialog && (dialog.mode === "add" || dialog.mode === "edit") ? (
                            <React.Fragment>
                                <Typography>
                                    {translate(
                                        dialog?.mode === "edit" ? "tesseract.appliances.settings.basic.serialEdit.edit" : "tesseract.appliances.settings.basic.serialEdit.add",
                                        {context: applianceType}
                                    )}
                                </Typography>
                                <SelectSerial
                                    id="serial"
                                    value={dialog && dialog.serial || ""}
                                    onChange={(value) => {
                                        setDialog({...dialog, serial: value});
                                    }}
                                    onBlur={() => {
                                    }}
                                    claimResource={apiResources.claimAppliance}
                                    filter={{model, series}}
                                    expandSectionButtonText="tesseract.appliances.dialog.serials.addAppliance"
                                    linkingCodeInputLabel="tesseract.appliances.dialog.serials.linkingCode"
                                    maxSelectable={1}
                                    searchField="serial"
                                    tableResource={apiResources.serials}
                                >
                                    <TextField label="tesseract.appliances.dialog.serials.table.serial" source="serial"
                                               sortable/>
                                    <TextField label="tesseract.appliances.dialog.serials.table.model" source="model"/>
                                    <TextField label="tesseract.appliances.dialog.serials.table.location"
                                               source="location.summary"/>
                                    <TextField label="tesseract.appliances.dialog.serials.table.orderId"
                                               source="orderId"/>
                                    <DateField label="tesseract.appliances.dialog.serials.table.orderDate"
                                               source="ordered" dateOnly/>
                                </SelectSerial>
                                <br/>
                                <Typography
                                    className={classes.typographyBold}>{translate("tesseract.appliances.settings.basic.serialEdit.save")}</Typography>
                                <br/>
                                <Typography>
                                    {series === "S" ?
                                        <Trans
                                            i18nKey={
                                                dialog && dialog.mode === "add" ? "tesseract.appliances.settings.basic.serialEdit.saveSCAdd" : "tesseract.appliances.settings.basic.serialEdit.saveSCEdit"
                                            }
                                        >
                                            <b/>
                                        </Trans> :
                                        <Trans
                                            i18nKey={
                                                dialog && dialog.mode === "add" ? "tesseract.appliances.settings.basic.serialEdit.saveAdd" : "tesseract.appliances.settings.basic.serialEdit.saveEdit"
                                            }
                                        >
                                            <b/>
                                        </Trans>
                                    }
                                </Typography>
                                <br/>
                                {dialog && (dialog.mode === "add" || (dialog.mode === "edit" && Array.isArray(value) && value.length > 1)) && (
                                    <Typography>
                                        <Trans i18nKey="tesseract.appliances.settings.basic.serialEdit.saveHA">
                                            <b/>
                                        </Trans>
                                    </Typography>
                                )}
                                {dialog.mode === "edit" && isSecondary(dialog && dialog.target) && (
                                    <React.Fragment>
                                        <br/>
                                        <b>
                                            <Typography>
                                                {translate("tesseract.appliances.settings.basic.serialEdit.saveEditSecondary")}
                                            </Typography>
                                        </b>
                                    </React.Fragment>
                                )}
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <Typography>{translate("tesseract.appliances.settings.basic.serialEdit.delete1", {
                                    serial: dialog?.target || "",
                                    context: applianceType
                                })}</Typography>
                                <br/>
                                <Typography>{translate("tesseract.appliances.settings.basic.serialEdit.delete2")}</Typography>
                                <br/>
                                <Typography>{translate("tesseract.appliances.settings.basic.serialEdit.saveDelete")}</Typography>
                                {value && value.length > 1 && (
                                    <React.Fragment>
                                        <br/>
                                        <Typography>
                                            <b>
                                                {
                                                    (isSecondary(dialog?.target)) ?
                                                        translate("tesseract.appliances.settings.basic.serialEdit.saveDeleteSecondary")
                                                        :
                                                        translate("tesseract.appliances.settings.basic.serialEdit.saveDeletePrimary", {context: applianceType})
                                                }
                                            </b>
                                        </Typography>
                                    </React.Fragment>
                                )}
                            </React.Fragment>
                        )}
                    </Container>
                </DialogBody>
                <Toolbar>
                    <Button
                        variant="contained"
                        bdsType="interactiveEmphasis"
                        onClick={() => {
                            const newValue = value.filter((existingSerial: any) => existingSerial !== dialog.target);
                            dialog.mode !== "delete" && newValue.push(get(dialog.serial, "[0].serial"));
                            onChange(newValue);
                            onBlur(newValue);
                            setDialog(null);
                        }}
                        disabled={dialog && dialog.mode !== "delete" && (!dialog.serial || dialog.serial.length === 0)}
                    >
                        {translate("cuda.delete.ok")}
                    </Button>
                    <Button
                        className={classes.cancel}
                        variant="contained"
                        bdsType="interactiveNeutral"
                        onClick={() => {
                            onBlur(value);
                            setDialog(null);
                        }}
                    >
                        {translate("cuda.delete.cancel")}
                    </Button>
                </Toolbar>
            </Dialog>
        </React.Fragment>
    );
};

interface SerialEditInputProps extends Omit<InputProps<typeof SerialEdit>, "component"> {}

const SerialEditInput: React.FC<SerialEditInputProps> = (props) => (
    <Input {...props} component={SerialEdit}/>
);

export default SerialEditInput;