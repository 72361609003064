import React from "react";
import {SvgIcon, useTokens} from "@barracuda-internal/bds-core";
import classNames from "classnames";

export const EdgeServiceIcon = (props: { className?: string, classes: {icon: string} }) => {
    const tokens = useTokens();
    return (
        <SvgIcon
            className={classNames(props.className, props.classes?.icon, "cuda-react-icon")}
            id="cuda-icon-edge-service"
            viewBox="0 0 60 60"
        >
            <path style={{fill: tokens.aliasesColorTextStaticNeutral, strokeWidth: 0}}
                  d="M55.4,27.5c-1.4-1.3-3.5-1.3-4.8,0-.2.2-.3.4-.5.7h-2.7c-1.2-1.3-2.7-2.2-4.5-2.5-.1-3.1-1.3-5.9-3.6-8.1-.1-.1-.3-.3-.4-.4l2.6-4.8c.3,0,.6,0,.9-.1,1.8-.6,2.8-2.5,2.2-4.3-.6-1.8-2.5-2.8-4.3-2.2-1.8.6-2.8,2.5-2.2,4.3,0,.3.2.5.3.7l-2.4,4.5c-1.6-.8-3.4-1.2-5.4-1.2s-4.4.6-6.3,1.7l-2.7-5c.1-.2.3-.5.3-.7.6-1.8-.4-3.7-2.2-4.3-.9-.3-1.8-.2-2.6.2-.8.4-1.4,1.1-1.7,2h0c-.6,1.8.4,3.7,2.2,4.3.3,0,.6.1.9.1l3.1,5.6c-1.2,1.3-2.1,2.9-2.7,4.7-2.1.6-3.8,1.7-5.1,3.4-.5.7-.9,1.4-1.3,2.1h-2.6c-.2-.3-.3-.5-.6-.7-1.4-1.3-3.5-1.3-4.8,0h0c-1.3,1.4-1.3,3.5,0,4.8.7.6,1.5,1,2.4,1s1.8-.4,2.5-1c.2-.2.3-.4.5-.6h1.8c0,.2,0,.4,0,.6,0,2.7,1,5,2.9,6.9,1.9,1.9,4.2,2.8,6.9,2.8l-3,5.5c-.3,0-.6,0-.9.1-1.8.6-2.8,2.5-2.2,4.3s.9,1.6,1.7,2c.5.3,1,.4,1.6.4s.7,0,1-.2c1.8-.6,2.8-2.5,2.2-4.3,0-.3-.2-.5-.3-.7l3.9-7.1h9.2l3.9,7.1c-.1.2-.3.5-.3.7-.6,1.8.4,3.7,2.2,4.3.3.1.7.2,1,.2.5,0,1.1-.1,1.6-.4.8-.4,1.4-1.1,1.7-2,.6-1.8-.4-3.7-2.2-4.3-.3,0-.6-.1-.9-.1l-3-5.5h2.8c2.3,0,4.2-.8,5.8-2.4,1.6-1.6,2.4-3.5,2.4-5.8s0-1.4-.3-2.1h.8c.1.2.3.5.5.7.7.6,1.5,1,2.4,1s1.8-.3,2.5-1h0c1.3-1.4,1.3-3.5,0-4.8ZM41.4,9s0,0,0,0c0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0ZM6.9,30.1s0,0,0-.1h.1c0,.1,0,.1-.1.1ZM6.9,29.9h0s0,0,.1,0c0,0,0,0,0,.1h-.1ZM41.4,51s0,0,0,0c0,0,0,0,0,0s0,0,0,0c0,0,0,0,0,0,0,0,0,0,0,0ZM41.4,30.5c1,0,1.7.3,2.4,1,.7.7,1,1.4,1,2.4s-.3,1.7-1,2.4c-.7.7-1.4,1-2.4,1h-19.9c-1.4,0-2.6-.5-3.6-1.5-1-1-1.5-2.2-1.5-3.6s.5-2.6,1.5-3.6c1-1,2.2-1.5,3.6-1.5h1.6v-.8c0-2.1.7-3.8,2.2-5.3,1.5-1.5,3.2-2.2,5.3-2.2s3.8.7,5.3,2.2c1.5,1.5,2.2,3.2,2.2,5.3v4.1h3.2ZM18.7,9s0,0,0,0c0,0,0,0,0,0h0s0,0,0,0c0,0,0,0,0,0ZM18.7,51s0,0,0,0c0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0ZM53.1,30.1s0,0,0,0h0c0,0,0,0,0,0h0ZM53.1,30h0c0,0,0,0,0,0,0,0,0,0,0,.1h0Z"/>
        </SvgIcon>
    );
};

export default EdgeServiceIcon;