import React, {useRef, useState} from "react";
import {
    ActionButtonsField,
    ChipArrayField,
    ConnectedTableRefresh,
    CustomField,
    DeleteDialog,
    DisableableButton,
    EmptyPageContent,
    getArrayDataContent,
    IconPreview,
    TablePage,
    TextField,
    useCrudProps,
    useMixpanel
} from "@cuda-react/core";
import {AppCatalogGraphic, GetStartedBackgroundGraphic} from "@cuda-react/icons";
import CreateEditButtonDialog from "../../../components/CreateEditButtonDialog";
import {Dialog} from "@barracuda-internal/bds-core";
import apiResources from "../../../apiResources";
import CreateEditApplication from "./CreateEditApplication";
import {User, UserGroup} from "@barracuda-internal/bds-core/dist/Icons/Core";
import {categoryIcons} from "../../../components/iconMapping";
import {sortBy} from "lodash";

const AppCatalog = () => {
    const registerAction = useMixpanel("App Catalog");
    const tableRefreshRef = useRef<ConnectedTableRefresh | null>(null);
    const [actionVisible, setActionVisible] = useState(false);
    const [createOpen, setCreateOpen] = useState(false);
    const imageFileTypes = getArrayDataContent(useCrudProps(apiResources.customResponseImageTypes)[0]);
    const ztnaBasedApps = getArrayDataContent(useCrudProps(apiResources.zeroTrustApplicationsZtnaBased, {pagination: {page: 0, perPage: 3000}})[0]);
    const visibilityChoices = sortBy(getArrayDataContent(useCrudProps(apiResources.visibility)[0]), "name");

    return (
        <>
            <TablePage
                title="tesseract.endpoint.pageTitle"
                subtitle="tesseract.endpoint.appCatalog.pageTitle"
                resource={apiResources.appCatalog}
                minCellWidth={128}
                compact
                refreshRef={tableRefreshRef}
                titleActions={actionVisible &&
                    <DisableableButton
                        key="create"
                        buttonText="tesseract.endpoint.appCatalog.buttonText"
                        onClick={() => setCreateOpen(true)}
                    />
                }
                noDataPage={
                    <EmptyPageContent
                        background={<GetStartedBackgroundGraphic/>}
                        icon={<AppCatalogGraphic/>}
                        iconHeader="tesseract.endpoint.appCatalog.startTextHeader"
                        text="tesseract.endpoint.appCatalog.startText"
                        actions={
                            <DisableableButton
                                key="create"
                                buttonText="tesseract.endpoint.appCatalog.buttonText"
                                onClick={() => setCreateOpen(true)}
                            />
                        }
                    />
                }
                onPageStatusChange={(pageStatus: string) => setActionVisible(pageStatus === "table")}
            >
                <CustomField
                    source="icon"
                    label="tesseract.endpoint.appCatalog.iconText"
                    width={64}
                    render={(iconData, data) => (
                        <IconPreview
                            icon={iconData}
                            filename={data?.iconFilename}
                            name={data?.name}
                            supportedFiles={imageFileTypes}
                        />
                    )}
                />
                <TextField
                    source="name"
                    label="tesseract.endpoint.appCatalog.name"
                    filter="text"
                    sortable
                />
                <ChipArrayField
                    source="app"
                    label="tesseract.endpoint.appCatalog.application"
                    filter="selectarray"
                    choices={ztnaBasedApps}
                    filterProps={{
                        resource: apiResources.zeroTrustApplicationsZtnaBased,
                        optionIcon: "categoryId",
                        optionValue: "id",
                        iconMap: categoryIcons
                    }}
                    iconMap={categoryIcons}
                    optionValue="id"
                    optionIcon="categoryId"
                />
                <TextField
                    source="url"
                    label="tesseract.endpoint.appCatalog.launchURL"
                    filter="text"
                    sortable
                />
                <CustomField
                    source="visibility"
                    label="tesseract.endpoint.appCatalog.visibility"
                    filter="select"
                    filterProps={{
                        choices: visibilityChoices
                    }}
                    render={(value, data) => {
                        if (data?.visibility === "usersAndGroups") {
                            const userGroups = [
                                ...((data?.groups || []).map((_user: any, index: any) => ({
                                    name: data?.resolvedGroups?.[index],
                                    type: "group"
                                }))),
                                ...((data?.users || [])
                                    .map((user: any, index: any) => ({
                                        name: data?.resolvedUsers?.[index],
                                        tooltip: user,
                                        type: "user"
                                    }))
                                )
                            ];
                            return (
                                <ChipArrayField
                                    data={{userGroups}}
                                    source="userGroups"
                                    sourceValue="name"
                                    optionIcon="type"
                                    optionTooltip="tooltip"
                                    iconMap={{
                                        user: <User/>,
                                        group: <UserGroup/>
                                    }}
                                />
                            );
                        } else if (data?.visibility === "ztnaBased") {
                            if (!data?.resolvedUsers?.length && !data?.resolvedGroups?.length) {
                                return (
                                    <ChipArrayField
                                        data={{value: "all"}}
                                        source="value"
                                        choices={visibilityChoices}
                                        optionText="name"
                                    />
                                );
                            }
                            const userGroups = [
                                ...(data?.resolvedGroups || []).map((name: string) => ({name, type: "group"})),
                                ...(data?.resolvedUsers || []).map((name: string) => ({name, type: "user"}))
                            ];
                            return (
                                <ChipArrayField
                                    data={{userGroups}}
                                    source="userGroups"
                                    sourceValue="name"
                                    optionIcon="type"
                                    iconMap={{
                                        user: <User/>,
                                        group: <UserGroup/>
                                    }}
                                />
                            );
                        } else {
                            return (
                                <ChipArrayField
                                    data={{value}}
                                    source="value"
                                    choices={visibilityChoices}
                                    optionText="name"
                                />
                            );
                        }
                    }}
                />

                <ActionButtonsField
                    source="id"
                    width={96}
                    alwaysVisible
                >
                    <CreateEditButtonDialog
                        component={CreateEditApplication}
                        onSuccess={() => {
                            registerAction("Update");
                            tableRefreshRef.current?.();
                        }}
                    />
                    <DeleteDialog
                        resource={apiResources.appCatalog}
                        title="tesseract.endpoint.appCatalog.delete.title"
                        message="tesseract.endpoint.appCatalog.delete.body"
                        onSuccess={() => {
                            registerAction("Delete");
                            tableRefreshRef.current?.();
                        }}
                    />
                </ActionButtonsField>
            </TablePage>
            <Dialog
                open={createOpen}
                maxWidth={false}
                sx={{
                    '& .MuiPaper-root': {
                        width: 'auto',
                        maxWidth: '740px'
                    }
                }}
                disableEnforceFocus
            >
                <CreateEditApplication
                    onSuccess={() => {
                        registerAction("Create");
                        tableRefreshRef.current?.();
                    }}
                    create
                    onClose={() => setCreateOpen(false)}
                />
            </Dialog>
        </>
    );
};


export default AppCatalog;