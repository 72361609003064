import React from 'react';
import {
    CrudTypes,
    getArrayDataContent,
    LinkField,
    LocationsCardContent,
    TextField,
    useCrudSubscription,
} from "@cuda-react/core";
import {BaseCard, CardSizeOptions, CardSizes, DashboardCardProps} from "@stratos/reporting";
import apiResources from "../../../../apiResources";
import ActiveTunnelsTable from "./ActiveTunnelsTable/ActiveTunnelsTable";
import {useGatewayDetails} from "./useGatewayDetails";
import demoData from "./demoData";
import {makeStyles} from "@mui/styles";
import {ReportingTheme} from "@stratos/reporting/lib/themes";

export interface ActiveTunnelsCardProps extends DashboardCardProps {
    preview?: boolean,
    size: CardSizes
}

const useStyles = makeStyles((theme) => ({
    cardContent: {
        height: "calc(100% - 52px)",
        overflowY: "hidden",
        "& .in-highchart": {
            height: `calc(${CardSizeOptions.small.height}px - 48px)`,
            overflow: "show",
            width: "100%"
        }
    },
    title: {
        margin: "8px 8px 8px 20px",
        fontWeight: "bold",
        color: "#4E4D4D"
    }
}));


const sitesDetailFieldsArray = () => (
    [<LinkField
        label="tesseract.dashboard.cards.vpn.locations.sites.fields.name"
        source="name"
        key="name"
        link={(data) => `/infrastructure/sites/${data.id}`}
    />,
        <TextField
            label="tesseract.dashboard.cards.vpn.locations.sites.fields.location"
            source="summary"
            key="summary"
        />,
        <LinkField
            label="tesseract.dashboard.cards.vpn.locations.sites.fields.gateway"
            source="gatewayName"
            key="gatewayName"
            link={(data) => `/infrastructure/gateways?filter={"name":"${data.gatewayName}"}`}
        />,
        <TextField
            label="tesseract.dashboard.cards.vpn.locations.sites.fields.providers"
            source="wanNetworks.length"
            key="wans"
        />]
);

const iotDetailFieldsArray = () => (
    [<LinkField
        label="tesseract.dashboard.cards.vpn.locations.iot.fields.name"
        source="name"
        key="name"
        link={(data) => `/infrastructure/iot/${data.id}`}
    />,
        <TextField
            label="tesseract.dashboard.cards.vpn.locations.iot.fields.location"
            source="summary"
            key="summary"
        />,
        <LinkField
            label="tesseract.dashboard.cards.vpn.locations.iot.fields.gateway"
            source="gatewayName"
            key="gatewayName"
            link={(data) => `/infrastructure/gateways?filter={"name":"${data.gatewayName}"}`}
        />,
        <TextField
            label="tesseract.dashboard.cards.vpn.locations.iot.fields.providers"
            source="wanNetworks.length"
            key="wans"
        />]
);

const standaloneSitesFieldsArray = () => (
    [<LinkField
        label="tesseract.dashboard.cards.vpn.locations.sites.fields.name"
        source="name"
        key="name"
        link={(data) => `/sites/${data.id}`}
    />,
        <TextField
            label="tesseract.dashboard.cards.vpn.locations.sites.fields.location"
            source="summary"
            key="summary"
        />,
        <TextField
            label="tesseract.dashboard.cards.vpn.locations.sites.fields.providers"
            source="wanNetworks.length"
            key="wans"
        />]
);

export const ActiveTunnelsCard: React.FC<ActiveTunnelsCardProps> = (props) => {
    const classes = useStyles(props);
    const reportingTheme = ReportingTheme;
    const gatewayLocations = getArrayDataContent(useCrudSubscription(
        CrudTypes.GET,
        props.preview ? undefined : apiResources.VPNTunnelLocations
    )[0].data)
        .map((gateway) => ({
            ...gateway,
            tseries: gateway.sites.filter((site: any) => site?.modelSeries === "T"),
            iot: gateway.sites.filter((site: any) => site?.modelSeries === "S")
        }));
    const standaloneSitesAllLocations = getArrayDataContent(useCrudSubscription(
        CrudTypes.GET,
        props.preview ? undefined : apiResources.standaloneSitesAllLocations
    )[0]?.data);

    return (
        <BaseCard
            id="VPNStatusCard"
            title="tesseract.dashboard.cards.vpn.locations.tabTitle"
            classes={{cardContent: classes.cardContent}}
            {...props}
        >
            <LocationsCardContent
                data={props.preview ? demoData : gatewayLocations}
                series={[
                    {
                        label: "tesseract.dashboard.cards.vpn.locations.gateways.label",
                        color: reportingTheme.genericColors.purple,
                        zIndex: 4,
                        linkedSeries: {
                            color: reportingTheme.genericColors.darkBlue,
                            label: "tesseract.dashboard.cards.vpn.locations.gateways.linkedSites",
                            zIndex: 3,
                            selectedZIndex: 5
                        },
                        details: {
                            resource: apiResources.gateways,
                            optionValue: "id",
                            fields: useGatewayDetails()
                        }
                    },
                    {
                        label: "tesseract.dashboard.cards.vpn.locations.sites.label",
                        color: reportingTheme.genericColors.blue,
                        source: "tseries",
                        zIndex: 1,
                        linkedSeries: {
                            color: reportingTheme.genericColors.magenta,
                            label: "tesseract.dashboard.cards.vpn.locations.sites.linkedHubs",
                            zIndex: 6,
                            selectedZIndex: 2
                        },
                        details: {
                            resource: apiResources.sites,
                            optionValue: "id",
                            fields: sitesDetailFieldsArray()
                        }
                    },
                    {
                        label: "tesseract.dashboard.cards.vpn.locations.iot.label",
                        color: reportingTheme.genericColors.turquoise,
                        source: "iot",
                        zIndex: 1,
                        linkedSeries: {
                            color: reportingTheme.genericColors.magenta,
                            label: "tesseract.dashboard.cards.vpn.locations.iot.linkedHubs",
                            zIndex: 6,
                            selectedZIndex: 2
                        },
                        details: {
                            resource: apiResources.sites,
                            optionValue: "id",
                            fields: iotDetailFieldsArray()
                        }
                    },
                    {
                        label: "tesseract.dashboard.cards.vpn.locations.standaloneSites.label",
                        color: reportingTheme.genericColors.graphite,
                        data: standaloneSitesAllLocations,
                        zIndex: 1,
                        details: {
                            resource: apiResources.sites,
                            optionValue: "id",
                            fields: standaloneSitesFieldsArray()
                        }
                    }
                ]}
                chartOverrides={{
                    chart: {
                        height: CardSizeOptions.small.height - 48,
                        width: CardSizeOptions[props.size].width - 2,
                        spacing: [2, 2, 2, 2]
                    },
                    legend: {
                        floating: true,
                        backgroundColor: "rgba(255,255,255,0.4)",
                        shadow: {
                            color: "rgba(255,255,255, 0.4)",
                            opacity: 0.2,
                            width: 16
                        },
                        borderRadius: 4
                    }
                }}
            />
            {props.size === CardSizes.large && <ActiveTunnelsTable preview={props.preview}/>}
        </BaseCard>
    );
};

export default ActiveTunnelsCard;