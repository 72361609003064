import {SvgIcon, useTokens} from "@barracuda-internal/bds-core";
import React from "react";
import classNames from "classnames";

export const AppsResourcesGraphic = ({className}: { className?: string }) => {
    const tokens = useTokens();
    return (
        <SvgIcon
            className={classNames(className, "cuda-react-graphic")}
            id="cuda-graphic-appsresources"
            viewBox="0 0 96 96"
        >
            <g id="ArtBoard11">
                <g id="Ebene-1">
                    <path style={{fill: tokens.aliasesColorTextStaticNeutral, fillRule: "nonzero", strokeWidth: 0}} d="M9.1,36.5h23.2V13.3H9.1v23.2ZM14,18.3h13.2v13.2h-13.2v-13.2Z"/>
                    <path style={{fill: tokens.aliasesColorTextStaticNeutral, fillRule: "nonzero", strokeWidth: 0}} d="M41.3,36.5h23.2V13.3h-23.2v23.2ZM46.2,18.3h13.2v13.2h-13.2v-13.2Z"/>
                    <path style={{fill: tokens.aliasesColorTextStaticNeutral, fillRule: "nonzero", strokeWidth: 0}} d="M9.1,68.8h23.2v-23.2H9.1v23.2ZM14,50.6h13.2v13.2h-13.2v-13.2Z"/>
                    <path style={{fill: tokens.globalWhite, fillRule: "nonzero", strokeWidth: 0}} d="M71.6,57.1h-3.4v6.8h-6.8v3.4h6.8v6.8h3.4v-6.8h6.8v-3.4h-6.8v-6.8Z"/>
                    <path style={{fill: tokens.aliasesColorTextStaticNeutral, fillRule: "nonzero", strokeWidth: 0}}
                          d="M50,65.7c0-.6,0-1.2,0-1.8h-3.9v-13.2h10.7c2.2-1.9,4.7-3.3,7.5-4.1v-.9h-23.2v23.2h9c-.2-1-.3-2.1-.3-3.2"/>
                    <path style={{fill: tokens.aliasesColorIconSuccessNeutral, fillRule: "nonzero", strokeWidth: 0}}
                          d="M69.9,48.6c-9.4,0-17,7.6-17,17s7.6,17,17,17,17-7.6,17-17-7.6-17-17-17ZM78.4,67.4h-6.8v6.8h-3.4v-6.8h-6.8v-3.4h6.8v-6.8h3.4v6.8h6.8v3.4Z"/>
                </g>
            </g>
        </SvgIcon>
    );
};

export default AppsResourcesGraphic;