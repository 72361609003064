import {Divider, IconButton, Typography} from "@barracuda-internal/bds-core";
import {Add, Delete, Subtract} from "@barracuda-internal/bds-core/dist/Icons/Core";
import React, {Fragment, useEffect, useState} from "react";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import {get, merge} from "lodash";
import {useFieldArray, useFormState, useWatch} from "react-hook-form";
import {Theme} from "@mui/material";
import {createStyles} from "@mui/styles";
import {ClonableChildren} from "../../../utils/commonTypes";
import {useTranslation} from "react-i18next";
import {useDeepCompareMemo} from "../../../hooks";

const styles = (theme: Theme) => createStyles({
    componentHelperText: {
        marginLeft: "56px"
    },
    elementHelperText: {
        marginLeft: "56px"
    },
    inputGroup: {
        overflow: "hidden"
    },
    inputGroupHidden: {
        display: "none"
    },
    divider: {
        marginBottom: "8px",
        marginLeft: 32,
        marginRight: 32
    },
    buttons: {
        marginLeft: 32,
        marginRight: 32,
        float: "right"
    },
    multiInputMainColumnStyle: {},
    topBarColumnStyle: {
        borderTop: "solid 1px #DDDDDD",
        borderBottom: "solid 1px #DDDDDD",
        marginTop: 30
    },
    buttonsColumnStyle: {
        paddingLeft: 40
    },
    mainContentColumnStyle: {
        float: "left"
    },
    leftColumnColumnStyle: {
        float: "left",
        width: "20%",
        overflow: "hidden"
    },
    centerContainerColumnStyle: {
        float: "left",
        borderLeft: "solid 1px #DDDDDD",
    },
    titleColumnStyleActive: {
        color: "#0A5D91",
        backgroundColor: "#E4F4FF",
        padding: 10,
    },
    titleColumnStyle: {padding: 10}
});
const useStyles = makeOverrideableStyles("MultiInput", styles);

export interface MultiInputProps extends StyledComponentProps<typeof styles> {
    /**
     * Children inputs rendered inside each block.
     */
    children?: ClonableChildren,
    /**
     * function to determinate if all child inputs should be rendered or not.
     *
     * @function
     * @param {*} value the current value of the multi input.
     * @param {object} data the current value of the entire form.
     * @returns {boolean} if truthy, the inputs will not be rendered.
     */
    hide?: (value: any[], data: any) => boolean,
    /**
     * if true, inputs will get disabled. Also passed to the input. Additionally it removes any active validation.
     */
    disabled?: boolean,
    /**
     * function that checks if all child inputs should be disabled. The result is passed to each input as 'disabled'.
     * Additionally it removes any active validation if truthy.
     *
     * @function
     * @param {*} value the current value of the multi input.
     * @param {object} data the current value of the entire form.
     * @returns {boolean} if truthy, the input will be disabled.
     */
    disable?: (value: any[], data: any) => boolean,
    /**
     * Sets whether to display the required '*' and add the "required" validation on all child inputs.
     */
    isRequired?: boolean,
    /**
     * max number input blocks. The add button will get disabled.
     */
    maxInputs: number,
    /**
     * function to determine if all the child inputs should be marked as isRequired.
     * If truthy is returned, the child inputs gets the required '*' to the label and add the "required" validation.
     *
     * @function
     * @param {*} value the current value of the multi input.
     * @param {object} data the current value of the entire form.
     * @returns {boolean} if truthy, the input will be set as required.
     */
    require?: (value: any[], data: any) => boolean,
    /**
     * passed to the FieldArray component as name prop. Also used for each child as prefix for the name of the input.
     */
    source: string,
    /**
     *  boolean, when true a columnStyle multiInput is showed
     */
    columnStyle?: boolean
    /**
     *  label to show over the multiInput table
     */
    columnStyleTitleLabel?: string
    /**
     *  dot notation to the label to show when a new item is added to the multi input, if empty "Item" will be used
     */
    columnStyleItemLabel?: string
    /**
     *  dot notation to the label to show when a new item is added to the multi input, if empty "Item" will be used
     */
    columnStyleItemLabelSource?: string
    /**
     *  optional initial values for each new entry
     */
    initialValues?: any
}

/**
 * Renders a FieldArray component that allows to replicate all children components as one. So you can add/remove blocks of inputs.
 */
const MultiInput = (props: MultiInputProps) => {
    const {children, disabled, maxInputs, hide, isRequired, disable, require, source, columnStyle, columnStyleItemLabel, columnStyleItemLabelSource, columnStyleTitleLabel, initialValues} = props;
    const classes = useStyles(props);
    const {append, fields, insert, remove} = useFieldArray({name: source});

    const formData = useWatch();
    const fieldValue = get(formData, source);
    const fieldIsRequired = isRequired || !!require?.(fieldValue, formData);
    const fieldIsDisabled = disabled || !!disable?.(fieldValue, formData);
    const fieldIsHidden = hide?.(fieldValue, formData);

    const childArray = React.Children.toArray(children).filter((child): child is React.ReactElement => !!child);
    const defaultValues = useDeepCompareMemo(() => merge(childArray.reduce((acc, child) => ({[child.props.source]: "", ...acc}), {}), initialValues), [childArray, initialValues]);
    const[translate] = useTranslation();
    const [indexData, setIndexData] = useState({activeIndex: 0, totalItems: 1});

    const {errors} = useFormState();
    const sourceError: any = get(errors, source);

    useEffect(() => {
        if (fields.length === 0 && !fieldIsHidden) {
            // initialize with 1 element, and populate fields with an empty string
            append({...defaultValues}, {shouldFocus: false});
        }
    }, [fields, fieldIsHidden]);

    return (
        columnStyle && !fieldIsHidden ?
            <div className={classes.multiInputMainColumnStyle}>
                <div className={classes.topBarColumnStyle}>
                    <Typography component="span">
                        {typeof columnStyleTitleLabel === "string" ? translate(columnStyleTitleLabel) : columnStyleTitleLabel}
                    </Typography>
                    <span className={classes.buttonsColumnStyle}>
                        <IconButton
                            size="small"
                            onClick={() => {insert((indexData.activeIndex + 1), defaultValues);setIndexData({activeIndex: indexData.activeIndex + 1, totalItems: indexData.totalItems + 1});}}
                            disabled={fieldIsDisabled || fields.length >= maxInputs}
                        >
                            <Add id="cuda-multi-input-add"/>
                        </IconButton>
                        <IconButton
                            size="small"
                            onClick={() => {remove(indexData.activeIndex); setIndexData({activeIndex: indexData.activeIndex !== 0 ? indexData.activeIndex - 1 : 0, totalItems: indexData.totalItems - 1});}}
                            disabled={fieldIsDisabled || fields.length === 1}
                        >
                            <Delete id="cuda-multi-input-remove"/>
                        </IconButton>
                    </span>
                </div>
                <div className={classes.leftColumnColumnStyle}>
                    {fields.map(
                        (value, index) => (
                                <div
                                    className={indexData.activeIndex === index ? classes.titleColumnStyleActive : classes.titleColumnStyle}
                                    id={value.id}
                                    key={value.id}>
                                    <Typography
                                        color={sourceError && sourceError[index] ? "error" : undefined}
                                        component="span" onClick={() => {setIndexData({activeIndex: index, totalItems: indexData.totalItems});}}
                                        key={index}
                                    >
                                        {(columnStyleItemLabelSource && get(fieldValue?.[index], columnStyleItemLabelSource) || (columnStyleItemLabel && translate(columnStyleItemLabel)) || "Item")}
                                    </Typography>
                                </div>
                            )
                    )}
                </div>
                {fields.map(
                    ({id}, index) => (
                        indexData.activeIndex === index ?
                            <div className={classes.inputGroup} key={id}>
                                <div className={classes.mainContentColumnStyle}>
                                    <div className={classes.centerContainerColumnStyle}>
                                        {
                                            childArray.map(
                                                (child) => React.cloneElement(
                                                    child,
                                                    {
                                                        key: child.props.source,
                                                        disabled: fieldIsDisabled || child.props.disabled,
                                                        isRequired: fieldIsRequired || child.props.isRequired,
                                                        sourcePrefix: `${source}.${index}`
                                                    }
                                                )
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        : <div className={classes.inputGroupHidden} key={id}>
                                <div className={classes.mainContentColumnStyle}>
                                    <div className={classes.centerContainerColumnStyle}>
                                        {
                                            childArray.map(
                                                (child) => React.cloneElement(
                                                    child,
                                                    {
                                                        key: child.props.source,
                                                        disabled: fieldIsDisabled || child.props.disabled,
                                                        isRequired: fieldIsRequired || child.props.isRequired,
                                                        sourcePrefix: `${source}.${index}`
                                                    }
                                                )
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                    )
                )}
            </div> :
            <Fragment>
                {!fieldIsHidden ? fields.map(
                    ({id}, index, fields) => (
                        <div className={classes.inputGroup} key={id}>
                            {index !== 0 && (<Divider className={classes.divider}/>)}
                            {
                                childArray.map(
                                    (child) => React.cloneElement(
                                        child,
                                        {
                                            key: child.props.source,
                                            disabled: fieldIsDisabled || child.props.disabled,
                                            isRequired: fieldIsRequired || child.props.isRequired,
                                            sourcePrefix: `${source}.${index}`
                                        }
                                    )
                                )
                            }
                            <span className={classes.buttons}>
                                <IconButton
                                    size="small"
                                    onClick={() => insert(index + 1, defaultValues)}
                                    disabled={fieldIsDisabled || fields.length >= maxInputs}
                                >
                                    <Add id="cuda-multi-input-add"/>
                                </IconButton>
                                <IconButton
                                    size="small"
                                    onClick={() => remove(index)}
                                    disabled={fieldIsDisabled || fields.length === 1}
                                >
                                    <Subtract id="cuda-multi-input-remove"/>
                                </IconButton>
                            </span>
                        </div>
                    )
                ) : null}
            </Fragment>
    );
};

export default MultiInput;