import React, {useState} from "react";
import {
    AutoSelectInput,
    BooleanInput,
    CustomField,
    DoubleInput,
    getArrayDataContent,
    HiddenInput,
    Input,
    PasswordInput,
    Select,
    SelectedChoiceField,
    SelectInput,
    StatusIconField,
    Tab,
    TableInput,
    Text,
    TextArrayInput,
    TextField,
    TextInput,
    useCrudProps,
    validateInt,
    validateMaxLengthMemo,
    validateMaxValueMemo,
    validateMinValueMemo
} from "@cuda-react/core";
import {BooleanIcon} from "@cuda-react/icons";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@mui/styles";
import InputAdornment from "@mui/material/InputAdornment";
import {
    getNetworkNameFromPorts,
    useNetworkFormValidation,
    useSiteInputValidations
} from "../../../../../hooks/createEditSiteHooks";
import {useWatch} from "react-hook-form";
import apiResources from "../../../../../apiResources";
import {SELECT_EMPTY_VALUE} from "../../EditSCContent";
import ExpressRouteHeader from "../ExpressRouteHeader";
import {get} from "lodash";
import WanEditWarning from "./WanEditWarning";
import VLanInput from "./vLanInput/VLanInput";

const useStyles = makeStyles({
    doubleInput: {
        width: 124
    },
    selectDoubleInput: {
        width: 132,
        "&> div": {
            width: 124,
        },
        "&> div > div": {
            width: 124,
        }
    }
});

export const DEFAULT_MTU = "1452";

export interface WanTableInputProps {
    applianceType?: "site" | "gateway",
    serials: any,
    tableRowDimmed?: any,
    portsData?: any,
    series?: string
}

const WanTableInput: React.FC<WanTableInputProps> = ({applianceType, serials, tableRowDimmed, portsData, series}) => {
    const [translate] = useTranslation();
    const networkFormValidation = useNetworkFormValidation();
    const classes = useStyles();
    const inputValidations = useSiteInputValidations();
    const providersData = getArrayDataContent(useCrudProps(apiResources.siteProviders, {}, {}, true)[0]?.data);
    const getNetworkName = getNetworkNameFromPorts(portsData);
    const healthCheckModes = getArrayDataContent(useCrudProps(apiResources.siteHealthCheckModes, {}, {}, true)[0]?.data);
    const wanTypesData = getArrayDataContent(useCrudProps(apiResources.siteModes, {}, {}, true)[0]?.data);
    const gateway = useWatch({name: "gateway"}) || [];
    const expressRouteObject = {
        key: "expressRoute",
        name: "tesseract.appliances.settings.wan.expressRoute"
    };
    const bridgingObject = {
        key: "bridge",
        name: "tesseract.appliances.settings.wan.bridge"
    };
    const fullWanTypeData = [...wanTypesData, expressRouteObject];
    const filteredWanTypeFormChoices = (applianceType === "site" && series === "T") ? [...fullWanTypeData, bridgingObject] : fullWanTypeData;
    const allWanTypeFormChoices = [...wanTypesData, expressRouteObject, bridgingObject];
    const [currentMode, setCurrentMode] = useState(null);

    const filterAvailableChoice = ((menuValue: any, value: any, initialValue: any) => (
            (gateway === SELECT_EMPTY_VALUE && initialValue !== "expressRoute") || applianceType === "gateway") ?
            menuValue?.key !== "expressRoute" :
            true
    );

    return (
        <TableInput
            source="wans"
            optionValue="name"
            labels={{
                addTitle: "tesseract.appliances.settings.wan.addTitle",
                addButtonText: "tesseract.appliances.settings.wan.addButtonText",
                editTitle: "tesseract.appliances.settings.wan.editTitle",
                deleteTitle: "tesseract.appliances.settings.wan.deleteTitle",
                deleteMessage: "tesseract.appliances.settings.wan.deleteMessage"
            }}
            deleteButtonProps={{multiline: true}}
            formValidate={networkFormValidation}
            formatFormData={(newData) => ({
                    ...newData,
                    failover: newData.mode === "static" ? newData.failover : undefined,
                    port: newData.mode === "wwan" || newData.mode === "bridge" ? undefined : newData.port
                })}
            rowDimmed={tableRowDimmed}
            minimumItems={1}
            defaultFormValues={{doubleNetmask: ["", ""], doubleAddress: ["", ""], mtu: DEFAULT_MTU}}
            tabbed
            formProps={{onChange: (data) => setCurrentMode(data.mode)}}
            formChildren={[
                <Tab label="tesseract.appliances.settings.wan.basic" key="basic">
                    <TextInput
                        key="name"
                        source="name"
                        label="tesseract.appliances.settings.wan.name"
                        description="tesseract.appliances.settings.wan.descriptions.name"
                        validate={inputValidations.validateName}
                        isRequired
                    />
                    <SelectInput
                        key="mode"
                        source="mode"
                        choices={filteredWanTypeFormChoices}
                        filterChoices={filterAvailableChoice}
                        label="tesseract.appliances.settings.wan.type"
                        description="tesseract.appliances.settings.wan.descriptions.type"
                        validate={(value) => {
                            if (value === "bridge" && gateway !== "__SELECT_EMPTY_VALUE") {
                                return translate("tesseract.appliances.dialog.wans.validation.mode");
                            } else {
                                return undefined;
                            }
                        }}
                    />
                    {/* BRIDGE FIELDS */}
                    <SelectInput
                        source="wanPort"
                        label="tesseract.appliances.dialog.wans.bridgeType.wanPort"
                        description="tesseract.appliances.dialog.wans.bridgeType.descriptions.wanPort"
                        choices={portsData}
                        optionValue="port"
                        optionText="description"
                        hide={(value, data) => data?.mode !== "bridge"}
                    />
                    <TextInput
                        source="address"
                        label="tesseract.appliances.dialog.wans.bridgeType.address"
                        description="tesseract.appliances.dialog.wans.bridgeType.descriptions.address"
                        validate={inputValidations.validatePrivateIp}
                        hide={(value, data) => data?.mode !== "bridge"}
                        isRequired
                    />
                    <TextInput
                        source="netmask"
                        label="tesseract.appliances.dialog.wans.bridgeType.netmask"
                        description="tesseract.appliances.dialog.wans.bridgeType.descriptions.netmask"
                        validate={inputValidations.validateNetmask}
                        hide={(value, data) => data?.mode !== "bridge"}
                        isRequired
                    />
                    <TextInput
                        source="gateway"
                        label="tesseract.appliances.dialog.wans.bridgeType.gateway"
                        description="tesseract.appliances.dialog.wans.bridgeType.descriptions.gateway"
                        validate={inputValidations.validatePrivateIp}
                        hide={(value, data) => data?.mode !== "bridge"}
                        isRequired
                    />
                    <VLanInput
                        source="lanPorts"
                        label="tesseract.appliances.dialog.wans.bridgeType.lanPorts"
                        description="tesseract.appliances.dialog.wans.bridgeType.descriptions.lanPorts"
                        firstInputProps={{choices: portsData, optionValue: "port", optionText: "description"}}
                        hide={(value: any, data: any) => data?.mode !== "bridge"}
                        isRequired
                    />
                    {/* END BRIDGE FIELDS */}
                    <SelectInput
                        key="port"
                        source="port"
                        label="tesseract.appliances.settings.wan.port"
                        description="tesseract.appliances.settings.wan.descriptions.port"
                        choices={portsData}
                        optionValue="port"
                        optionText="description"
                        hide={(value, data) => data && ["wwan", "expressRoute", "bridge"].includes(data.mode) || false}
                    />
                    <TextInput
                        key="virtualLanId"
                        source="virtualLanId"
                        label="tesseract.appliances.settings.wan.virtualLanId"
                        description="tesseract.appliances.settings.wan.descriptions.virtualLanId"
                        validate={inputValidations.validateVlanId}
                        hide={(value, data) => data && ["wwan", "expressRoute", "bridge"].includes(data.mode) || false}
                    />
                    <TextInput
                        key="address"
                        source="address"
                        label="tesseract.appliances.settings.wan.address"
                        hide={(value, data) => !(data && data.mode === "static")}
                        description="tesseract.appliances.settings.wan.descriptions.address"
                        validate={inputValidations.validateStaticAddress}
                        isRequired
                    />
                    <TextArrayInput
                        key="addresses"
                        source="addresses"
                        label="tesseract.appliances.settings.wan.additionalAddresses"
                        hide={(value, data) => !(data && data.mode === "static")}
                        description="tesseract.appliances.settings.wan.descriptions.additionalAddresses"
                        validate={inputValidations.validateIpArray}
                    />
                    <TextInput
                        key="netmask"
                        source="netmask"
                        label="tesseract.appliances.settings.wan.netmask"
                        hide={(value, data) => !(data && data.mode === "static")}
                        description="tesseract.appliances.settings.wan.descriptions.netmask"
                        validate={inputValidations.validateNetmask}
                        isRequired
                    />
                    <TextInput
                        key="gateway"
                        source="gateway"
                        label="tesseract.appliances.settings.wan.gateway"
                        description="tesseract.appliances.settings.wan.descriptions.gateway"
                        hide={(value, data) => !(data && data.mode === "static")}
                        validate={inputValidations.validateIp}
                        isRequired
                    />
                    <Input
                        key="primarySecondary"
                        source="primarySecondary"
                        component={ExpressRouteHeader}
                        noLabel
                        hide={(value, data) => data && data.mode !== "expressRoute" || false}
                    />
                    <DoubleInput
                        key="doublePort"
                        source="doublePort"
                        label="tesseract.appliances.settings.wan.doublePort"
                        description="tesseract.appliances.settings.wan.descriptions.doublePort"
                        inputComponent={Select}
                        inputProps={{inputProps: {
                            choices: portsData,
                            optionValue: "port",
                            optionText: "description"
                        }}}
                        hide={(value, data) => data && data.mode !== "expressRoute" || false}
                        inputClasses={{
                            firstInput: classes.selectDoubleInput,
                            secondInput: classes.selectDoubleInput,
                        }}
                        isRequired
                    />
                    <DoubleInput
                        key="doubleVirtualLanId"
                        source="doubleVirtualLanId"
                        label="tesseract.appliances.settings.wan.doubleVirtualLanId"
                        description="tesseract.appliances.settings.wan.descriptions.doubleVirtualLanId"
                        inputComponent={Text}
                        hide={(value, data) => data && data.mode !== "expressRoute" || false}
                        inputProps={{inputProps: {
                            classes: {
                                textField: classes.doubleInput
                            }
                        }}}
                        validate={inputValidations.validateVlanIdArray}
                    />
                    <DoubleInput
                        key="doubleAddress"
                        source="doubleAddress"
                        label="tesseract.appliances.settings.wan.doubleAddress"
                        description="tesseract.appliances.settings.wan.descriptions.doubleAddress"
                        inputComponent={Text}
                        hide={(value, data) => data && data.mode !== "expressRoute" || false}
                        validate={inputValidations.validateRequiredPrivateIpArray}
                        inputProps={{inputProps: {
                            classes: {
                                textField: classes.doubleInput
                            }
                        }}}
                        isRequired
                    />
                    <DoubleInput
                        key="doubleNetmask"
                        source="doubleNetmask"
                        label="tesseract.appliances.settings.wan.doubleNetmask"
                        description="tesseract.appliances.settings.wan.descriptions.doubleNetmask"
                        inputComponent={Text}
                        hide={(value, data) => data && data.mode !== "expressRoute" || false}
                        inputProps={{inputProps: {
                            classes: {
                                textField: classes.doubleInput
                            }
                        }}}
                        validate={inputValidations.validateRequiredNetmaskArray}
                        isRequired
                        disabled
                    />
                    <HiddenInput
                        key="doubleNetmaskHardcoded"
                        source="doubleNetmask"
                        hiddenValue={["30", "30"]}
                        hide={(value, data) => data && data.mode !== "expressRoute" || false}
                    />
                    <SelectInput
                        key="provider"
                        source="provider"
                        choices={providersData}
                        label="tesseract.appliances.settings.wan.providerPinning"
                        description="tesseract.appliances.settings.wan.descriptions.providerPinning"
                    />
                    <BooleanInput
                        key="failover"
                        source="failover"
                        label="tesseract.appliances.settings.wan.failover"
                        hide={(value, data) => !(data && data.mode === "static")}
                        description="tesseract.appliances.settings.wan.descriptions.failover"
                        disabled={!serials || serials.length < 2}
                    />
                    <HiddenInput
                        key="pins[0].serial"
                        source="pins[0].serial"
                        hide={(value, data) => !(data && data.mode === "wwan")}
                        hiddenValue={serials[0]}
                    />
                    <TextInput
                        key="pins[0].pin"
                        source="pins[0].pin"
                        label={translate("tesseract.appliances.settings.wan.pin", {serial: serials[0]})}
                        hide={(value, data) => !(data && data.mode === "wwan")}
                        description="tesseract.appliances.settings.wan.descriptions.pin"
                        validate={inputValidations.validatePin}
                    />
                    {serials.length > 1 ? (
                        <HiddenInput
                            key="pins[1].serial"
                            source="pins[1].serial"
                            hiddenValue={serials[1]}
                            hide={(value, data) => !(data && data.mode === "wwan")}
                        />
                    ) : null}
                    {serials.length > 1 ? (
                        <TextInput
                            key="pins[1].pin"
                            source="pins[1].pin"
                            label={translate("tesseract.appliances.settings.wan.pin", {serial: serials[1]})}
                            hide={(value, data) => !(data && data.mode === "wwan")}
                            description="tesseract.appliances.settings.wan.descriptions.pin"
                            validate={inputValidations.validatePin}
                        />
                    ) : null}
                    <AutoSelectInput
                        key="asn"
                        source="asn"
                        label="tesseract.appliances.settings.wan.asn"
                        description="tesseract.appliances.settings.wan.descriptions.asn"
                        resource={apiResources.availableAsns}
                        hide={(value, data) => data && data.mode !== "expressRoute" || false}
                        validate={inputValidations.validateAsn}
                        allowShuffle
                        allowManualInput
                        isRequired
                    />
                    <TextInput
                        key="transferNetwork"
                        source="transferNetwork"
                        label="tesseract.appliances.settings.wan.virtualTransferNetwork"
                        description="tesseract.appliances.settings.wan.descriptions.virtualTransferNetwork"
                        hide={(value, data) => data && data.mode !== "expressRoute" || false}
                        validate={inputValidations.validatePrivateIp}
                        isRequired
                        options={{InputProps: {endAdornment: (<InputAdornment position="end">/28</InputAdornment>)}}}
                    />
                    <TextInput
                        key="userName"
                        source="username"
                        label="tesseract.appliances.settings.wan.userName"
                        description="tesseract.appliances.settings.wan.descriptions.userNamePassword"
                        hide={(value, data) => data && data.mode !== "pppoe" || false}
                        validate={validateMaxLengthMemo(255)}
                    />
                    <PasswordInput
                        source="password"
                        label="tesseract.appliances.settings.wan.password"
                        description="tesseract.appliances.settings.wan.descriptions.userNamePassword"
                        hide={(value, data) => data && data.mode !== "pppoe" || false}
                        validate={validateMaxLengthMemo(255)}
                    />
                    <WanEditWarning />
                </Tab>,
                <Tab
                    label="tesseract.appliances.settings.wan.advanced"
                    key="advanced"
                    disabled={!['static', 'dynamic', 'pppoe'].includes(currentMode || "")}
                >
                    <TextInput
                        source="mtu"
                        label="tesseract.appliances.settings.wan.mtu"
                        description="tesseract.appliances.settings.wan.descriptions.mtu"
                        validate={[validateInt, validateMaxValueMemo(1492), validateMinValueMemo(68)]}
                        hideInput={currentMode !== "pppoe"}
                    />
                    <BooleanInput
                        source="healthCheck.enabled"
                        label="tesseract.appliances.settings.wan.healthCheck.enabled"
                        description="tesseract.appliances.settings.wan.descriptions.healthCheck.enabled"
                    />
                    <SelectInput
                        source="healthCheck.mode"
                        label="tesseract.appliances.settings.wan.healthCheck.mode"
                        description="tesseract.appliances.settings.wan.descriptions.healthCheck.mode"
                        choices={healthCheckModes}
                        isRequired
                        disable={(value, data) => !get(data, "healthCheck.enabled") || get(data, "mode") !== "pppoe"}
                    />
                    {currentMode !== "pppoe" ? (
                        <HiddenInput
                            source="healthCheck.mode"
                            hiddenValue="icmp"
                        />
                    ) : null}
                    <TextArrayInput
                        source="healthCheck.targets"
                        label="tesseract.appliances.settings.wan.healthCheck.targets"
                        description="tesseract.appliances.settings.wan.descriptions.healthCheck.targets"
                        disable={(value, data) => !get(data, "healthCheck.enabled")}
                        validate={inputValidations.validateIpArray}
                        hide={(value, data) => data?.healthCheck?.mode !== "icmp"}
                        isRequired
                    />
                </Tab>
            ]}
        >
            <TextField
                source="name"
                label="tesseract.appliances.settings.wan.name"
            />
            <SelectedChoiceField
                source="mode"
                choices={allWanTypeFormChoices}
                label="tesseract.appliances.settings.wan.type"
            />
            <CustomField
                source="port"
                label="tesseract.appliances.settings.wan.port"
                width={104}
                render={(value, data) => getNetworkName(data)}
            />
            <SelectedChoiceField
                source="provider"
                choices={providersData}
                label="tesseract.appliances.settings.wan.providerPinning"
                width={164}
            />
            <StatusIconField
                source="failover"
                label="tesseract.appliances.settings.wan.failover"
                icon={<BooleanIcon/>}
                hidden={!serials || serials.length < 2}
                getStatusColorFromTheme={(status, theme) => status ? theme.palette.success.main : theme.palette.info.main}
            />
        </TableInput>
    );
};

export default WanTableInput;