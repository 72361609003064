import classNames from "classnames";
import {get, isArray} from "lodash";
import React from "react";
import {useTranslation} from "react-i18next";
import {Typography} from "@barracuda-internal/bds-core";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import {Theme} from "@mui/material";
import {createStyles} from "@mui/styles";
import {BaseFieldProps} from "../index";

export const styles = (theme: Theme) => createStyles({
    root: {
        lineHeight: "24px",
        color: theme.palette.common.black,
        fontSize: theme.typography.pxToRem(14)
    },
    icon: {
        top: "6px",
        position: "relative",
        paddingRight: "8px"
    }
});

const useStyles = makeOverrideableStyles("SelectedChoiceField", styles);

export interface SelectedChoiceFieldProps extends StyledComponentProps<typeof styles>, BaseFieldProps {
    /**
     * array of choice objects to search in.
     */
    choices?: any[],
    /**
     * classname to be added to the root element
     */
    className?: string,
    /**
     * icon to display next to each selected choice. If iconMap is provided as well, this icon will be used whenever a matching icon is not found in iconMap.
     */
    icon?: React.ReactNode,
    /**
     * a map of icons to display inside of each selected choice. The "optionIcon" value of each choice is used to identify the icon from this map.
     */
    iconMap?: {[key: string]: React.ReactNode},
    /**
     * dot-notation path to the property in each choice object that defines the icon to use from 'iconMaps'.
     */
    optionIcon?: string,
    /**
     * dot-notation path to the property in each choice object that defines the text to display in the selected choice.
     */
    optionText?: string,
    /**
     * dot-notation path to the property in each choice object that defines the value for matching against the data.
     */
    optionValue?: string,
    /**
     * function to render the content of the field
     * @function
     * @param {*} value the data value of the field
     * @param {object} data the full data array
     * @param {object} choice the matched choice object for the current value
     */
    render?: (value: any, data?: any, choice?: any) => React.ReactNode,
}

/**
 * Renders a selected choice field from a group of choices.
 */
export const SelectedChoiceField = (props: SelectedChoiceFieldProps) => {
    const {choices, className, data, icon, iconMap, optionText = "name", optionValue = "key", optionIcon = "", source = "", render} = props;
    const classes = useStyles(props);
    const [translate] = useTranslation();
    const dataValue = get(data, source);
    const choiceObject = isArray(choices) && choices.find((val) => get(val, optionValue) === dataValue) || {};
    const choiceText = get(choiceObject, optionText, null);
    const renderedIcon = get(iconMap, get(choiceObject, optionIcon), icon);
    const renderedText = render ? render(dataValue, data, choiceObject) : choiceText;

    return (
        <Typography variant="body2" component="span" className={classNames(className, classes.root)}>
            {renderedIcon ? (
                <span className={classes.icon}>
                    {renderedIcon}
                </span>
            ) : null}{renderedText && (typeof renderedText === "string" ? translate(renderedText) : renderedText)}
        </Typography>
    );
};

export default SelectedChoiceField;