import {
    ActionButtonsField,
    ConnectedAutocompleteSearch,
    CrudTypes,
    getArrayDataContent,
    SelectField,
    TablePage,
    TextField,
    useCrudFetch,
    useCrudProps,
    useMixpanel
} from "@cuda-react/core";
import {Restore} from "@barracuda-internal/bds-core/dist/Icons/Core";
import React, {useRef, useState} from "react";
import {fallbackIcons, priorityIcons} from "../../../../components/iconMapping";
import PolicyIconField from "../fields/PolicyIconField";
import apiResources from "../../../../apiResources";

const SdWanPolicies = () => {
    const registerAction = useMixpanel("SDWAN Categories");
    const resource = apiResources.sdwanPolicies;
    const [, , performDelete] = useCrudFetch(CrudTypes.DELETE, resource, {});
    // @ts-ignore
    const priorityData = useCrudProps(apiResources.priority, {}, {}, true)[0]?.data;
    // @ts-ignore
    const actionData = useCrudProps(apiResources.action, {}, {}, true)[0]?.data;
    // @ts-ignore
    const fallbackData = useCrudProps(apiResources.fallback, {}, {}, true)[0]?.data;
    // @ts-ignore
    const loadBalancingData = useCrudProps(apiResources.loadBalancing, {}, {}, true)[0]?.data;
    const [searchApplication, setSearchApplication] = useState<any>();
    const rowHighlight = (row: {
        category: any;
    }) => row && searchApplication && row.category === searchApplication.categoryId;
    const tableRefreshRef = useRef<any>();

    const onSuccess = (field: string) => (response: { category: any }) => {
        tableRefreshRef.current?.();
        registerAction("Update", {category: response.category, field});
    };

    return (
        <TablePage
            title="tesseract.securityAndAccess.pageTitle"
            subtitle={["tesseract.securityAndAccess.sdwan", "tesseract.securityAndAccess.categories"]}
            resource={resource}
            titleActions={
                <ConnectedAutocompleteSearch
                    value={searchApplication}
                    onChange={setSearchApplication}
                    resource={apiResources.applications}
                    placeholder="tesseract.policies.sdwan.search"
                    // @ts-ignore
                    fullWidth
                />
            }
            rowHighlight={searchApplication && rowHighlight || undefined}
            minCellWidth={64}
            compact
            refreshRef={tableRefreshRef}
        >
            <PolicyIconField
                source="category"
                // @ts-ignore
                label=" "
                width={40}
            />
            <TextField
                source="name"
                label="tesseract.policies.sdwan.table.category"
            />
            <TextField
                source="applications"
                label="tesseract.policies.sdwan.table.apps"
            />
            <TextField
                source="customApplications"
                label="tesseract.policies.sdwan.table.customApps"
            />
            <SelectField
                source="priority"
                label="tesseract.policies.sdwan.table.priority"
                resource={resource}
                id={(data) => data.id}
                choices={getArrayDataContent(priorityData)}
                iconMap={priorityIcons}
                width={146}
                columnProps={{
                    width: 160
                }}
                onSuccess={onSuccess("priority")}
            />
            <SelectField
                source="action"
                label="tesseract.policies.sdwan.table.action"
                resource={resource}
                id={(data) => data.id}
                choices={getArrayDataContent(actionData)}
                width={150}
                columnProps={{
                    width: 174
                }}
                onSuccess={onSuccess("action")}
            />
            <SelectField
                source="fallback"
                label="tesseract.policies.sdwan.table.fallback"
                resource={resource}
                id={(data) => data.id}
                choices={getArrayDataContent(fallbackData)}
                iconMap={fallbackIcons}
                width={124}
                columnProps={{
                    width: 148
                }}
                onSuccess={onSuccess("fallback")}
            />
            <SelectField
                source="loadBalancing"
                label="tesseract.policies.sdwan.table.loadBalancing"
                resource={resource}
                id={(data) => data.id}
                choices={getArrayDataContent(loadBalancingData)}
                width={120}
                columnProps={{
                    width: 144
                }}
                onSuccess={onSuccess("loadBalancing")}
            />
            <ActionButtonsField
                width={64}
                source="id"
            >
                <Restore
                    onClick={(event: any, data: { category: any; id: any }) => {
                        registerAction("Reset", {category: data.category});
                        performDelete({id: data.id}).then(tableRefreshRef.current);
                    }}
                    id="cuda-icon-restore"
                />
            </ActionButtonsField>
        </TablePage>
    );
};

export default SdWanPolicies;