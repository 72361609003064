import React from "react";
import {BooleanInput, FormSection, getArrayDataContent, SelectInput, TextInput, useCrudProps} from "@cuda-react/core";
import {Typography} from "@barracuda-internal/bds-core";
import {makeStyles} from "@mui/styles";
import {useTranslation} from "react-i18next";
import apiResources from "../../../../apiResources";
import {useSiteInputValidations} from "../../../../hooks/createEditSiteHooks";

const useStyles = makeStyles((theme) => ({
    dialogContent: {
        fontSize: 16,
    },
    completeMessage: {
        margin: "24px"
    }
}));

export const NetworkInput = () => {
    const classes = useStyles();
    const [translate] = useTranslation();
    const dpdAction = getArrayDataContent(useCrudProps(apiResources.phasesDpdActions)[0]);
    const inputValidations = useSiteInputValidations();

    return (
        <React.Fragment>
            <Typography variant="body1" className={classes.completeMessage}>
                {translate("tesseract.integration.ipSecV2.dialog.networkSection.descriptionMessage")}
            </Typography>
            <FormSection title="tesseract.integration.ipSecV2.dialog.networkSection.networkSettings.title">
                <BooleanInput
                    label="tesseract.integration.ipSecV2.dialog.networkSection.networkSettings.oneTunnelSubnet"
                    description="tesseract.integration.ipSecV2.dialog.networkSection.networkSettings.descriptions.oneTunnelSubnet"
                    source="subnetPairs"
                    hide={(value, data) => (data?.bgp)}
                />
                <BooleanInput
                    label="tesseract.integration.ipSecV2.dialog.networkSection.networkSettings.universalTrafficSelectors"
                    description="tesseract.integration.ipSecV2.dialog.networkSection.networkSettings.descriptions.universalTrafficSelectors"
                    source="universalTrafficSelectors"
                    hide={(value, data) => (data?.bgp)}
                />
                <BooleanInput
                    label="tesseract.integration.ipSecV2.dialog.networkSection.networkSettings.forceUdpEncapsulation"
                    description="tesseract.integration.ipSecV2.dialog.networkSection.networkSettings.descriptions.forceUdpEncapsulation"
                    source="udpEncapsulation"
                />
                <BooleanInput
                    label="tesseract.integration.ipSecV2.dialog.networkSection.networkSettings.ikeReauthentication"
                    description="tesseract.integration.ipSecV2.dialog.networkSection.networkSettings.descriptions.ikeReauthentication"
                    source="reauthentication"
                />
            </FormSection>
            <FormSection title="tesseract.integration.ipSecV2.dialog.networkSection.deadPeerDetection.title">
                <SelectInput
                    label="tesseract.integration.ipSecV2.dialog.networkSection.deadPeerDetection.actionDetected"
                    description="tesseract.integration.ipSecV2.dialog.networkSection.deadPeerDetection.descriptions.actionDetected"
                    source="dpd.action"
                    choices={dpdAction && dpdAction}
                />
                <TextInput
                    label="tesseract.integration.ipSecV2.dialog.networkSection.deadPeerDetection.delay"
                    description="tesseract.integration.ipSecV2.dialog.networkSection.deadPeerDetection.descriptions.delay"
                    source="dpd.delay"
                    inputProps={{type: "number"}}
                    validate={inputValidations.validateTunnelLifetime}
                    disable={(value, data) => !(data?.dpd?.action !== "none")}
                />
            </FormSection>
        </React.Fragment>
    );
};

export default NetworkInput;