import {categoryIcons} from "../iconMapping";
import {get} from "lodash";
import {Grid, TextField} from "@barracuda-internal/bds-core";
import SingleSiteIcon from "@mui/icons-material/StoreMallDirectory";
import React from "react";
import {CustomAppsIcon, GatewayIcon, SiteIcon} from "@cuda-react/icons";
import {Select, SelectArray} from "@cuda-react/core";
import classNames from "classnames";
import {makeStyles} from "@mui/styles";
import {Theme} from "@mui/material";
import {useTranslation} from "react-i18next";
import {getDestinationTypeChoices} from "./generateDestinationCriteriaSection";
import apiResources from "../../apiResources";


const useStyles = makeStyles((theme: Theme) => ({
    container: {
        width: 256
    },
    selectFilter: {
        marginTop: "8px",
        width: "100%",
        maxWidth: 256
    },
    textFilter: {
        marginTop: "16px",
        width: "100%",
        maxWidth: 256
    },
    typeSelect: {
        width: "100%",
        maxWidth: 256
    }
}));

interface DestinationFilterProps {
    value?: string | object;
    onChange?: any;
    filterSources: string[];
    table?: string;
    categoryChoices?: any;
    customCategories?: any;
    className?: string;
}

const DestinationFilter: React.FC<DestinationFilterProps> = ({
                                                                 value,
                                                                 onChange,
                                                                 filterSources,
                                                                 table,
                                                                 categoryChoices,
                                                                 customCategories,
                                                                 className
                                                             }) => {
    const update = (field: any) => (eventOrValue: any) => onChange({
        type: get(value, "type", ""),
        [field]: eventOrValue.target ? eventOrValue.target.value : eventOrValue
    });
    const classes = useStyles();
    const [translate] = useTranslation();
    const destinationSelectChoices = getDestinationTypeChoices(filterSources);
    const filters = [];

    if (get(value, "type") === "domain") {
        filters.push(
            <TextField
                key="domain"
                id="cuda-filter-input-domain"
                label={translate("tesseract.security.tables.destinationDomain")}
                value={get(value, "domain", "")}
                onChange={update("domain")}
                className={classNames(className, classes.textFilter)}
            />
        );
    }
    if (get(value, "type") === "network") {
        filters.push(
            <TextField
                key="destinationNetwork"
                id="cuda-filter-input-destinationNetwork"
                label={translate("tesseract.security.tables.destinationNetwork")}
                value={get(value, "destinationNetwork", "")}
                onChange={update("destinationNetwork")}
                className={classNames(className, classes.textFilter)}
            />
        );
    }
    if (get(value, "type") === "application") {
        const source = table === "SslInspection" || table === "MalwareProtection" || table === "IpsSettings" ? "application" : "destinationApplication";

        filters.push(
            <SelectArray
                key={source}
                id={source}
                label="tesseract.security.tables.destinationApplication"
                optionValue="id"
                resource={apiResources.applications}
                filterKey="applicationId"
                optionIcon="categoryId"
                iconMap={categoryIcons}
                value={get(value, source)}
                onChange={update(source)}
                className={classNames(className, classes.selectFilter)}
                options={{dropdownAutoWidth: true}}
            />
        );
    }
    if (get(value, "type") === "category") {
        filters.push(
            <SelectArray
                key="category"
                id="category"
                label="tesseract.security.tables.destinationCategories"
                choices={categoryChoices}
                value={get(value, "category")}
                onChange={update("category")}
                className={classNames(className, classes.selectFilter)}
            />
        );
    }
    if (get(value, "type") === "customCategory") {
        const source = table === "SslInspection" ? "destinationCustomCategory" :
            table === "WebFilter" ? "category" : "customCategory";

        filters.push(
            <SelectArray
                key={source}
                id={source}
                label="tesseract.security.tables.destinationCustomCategories"
                optionValue="id"
                choices={customCategories}
                value={get(value, source)}
                onChange={update(source)}
                className={classNames(className, classes.selectFilter)}
            />
        );
    }
    if (get(value, "type") === "onPremGateway" && filterSources.includes("onPremGatewayNetworks")) {
        filters.push(
            <SelectArray
                key="destinationOnPremGateway"
                id="destinationOnPremGateway"
                label="tesseract.security.tables.destinationOnpremGateway"
                optionValue="uuid"
                resource={apiResources.gatewaysOnPremNetworks}
                selectAll
                selectAllText="tesseract.security.tables.allOnpremGateways"
                optionIcon="uuid"
                icon={<GatewayIcon/>}
                value={get(value, "destinationOnPremGateway")}
                onChange={update("destinationOnPremGateway")}
                className={classNames(className, classes.selectFilter)}
            />
        );
    }
    if (get(value, "type") === "site") {
        filters.push(
            <SelectArray
                key="destinationSite"
                id="destinationSite"
                label="tesseract.security.tables.destinationSite"
                optionValue="uuid"
                resource={apiResources.siteNames}
                selectAll
                selectAllText="tesseract.security.tables.allSites"
                iconMap={{"*": <SiteIcon/>}}
                optionIcon="uuid"
                icon={<SingleSiteIcon/>}
                value={get(value, "destinationSite")}
                onChange={update("destinationSite")}
                className={classNames(className, classes.selectFilter)}
            />
        );
    }

    if (get(value, "type") === "ingressSite") {
        filters.push(
            <SelectArray
                key="destinationSiteId"
                id="destinationSiteId"
                label="tesseract.security.tables.destinationSite"
                optionValue="id"
                resource={apiResources.sites}
                iconMap={{"*": <SiteIcon/>}}
                optionIcon="uuid"
                icon={<SingleSiteIcon/>}
                value={get(value, "destinationSiteId")}
                onChange={update("destinationSiteId")}
                className={classNames(className, classes.selectFilter)}
            />
        );
    }

    if (get(value, "type") === "onPremGateway"  && !filterSources.includes("onPremGatewayNetworks")) {
        filters.push(
            <SelectArray
                key="destinationGatewayId"
                id="destinationGatewayId"
                label="tesseract.security.tables.destinationGateway"
                optionValue="id"
                resource={apiResources.gatewaysOnPrem}
                iconMap={{"*": <GatewayIcon/>}}
                filterKey="destinationGatewayId"
                value={get(value, "destinationGatewayId")}
                onChange={update("destinationGatewayId")}
                className={classNames(className, classes.selectFilter)}
            />
        );
    }

    if (get(value, "type") === "secureEdgeConnector") {
        filters.push(
            <SelectArray
                id="destination.secureEdgeConnector"
                label="tesseract.security.tables.secureEdgeConnector"
                optionValue="uuid"
                resource={apiResources.connectorsList}
                icon={<CustomAppsIcon/>}
                value={get(value, "destinationConnector")}
                onChange={update("destinationConnector")}
                className={classNames(className, classes.selectFilter)}
            />
        );
    }

    return (
        <Grid container direction="column" className={classes.container}>
            <Select
                id="cuda-filter-input-type"
                value={get(value, "type", "")}
                onChange={(eventOrValue) => onChange({type: eventOrValue.target ? eventOrValue.target.value : eventOrValue})}
                label={translate("tesseract.security.tables.destinationType")}
                choices={destinationSelectChoices}
                classes={{select: classes.typeSelect, formControl: classes.typeSelect}}
            />
            {filters}
        </Grid>
    );
};

export default DestinationFilter;