import {Switch, SwitchProps, Typography} from "@barracuda-internal/bds-core";
import React, {useEffect} from "react";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import {Theme} from "@mui/material";
import {createStyles} from "@mui/styles";
import {useTranslation} from "react-i18next";

const styles = (theme: Theme) => createStyles({
    root: {
        display: "inline-block"
    },
    switch: {
        margin: "10px 8px 10px 0"
    },
    toggleEnabledText: {
        fontSize: 12,
        lineHeight: "20px",
        display: "inline-block",
        padding: "10px 0"
    },
    toggleDisabledText: {
        fontSize: 12,
        lineHeight: "20px",
        display: "inline-block",
        padding: "10px 0"
    }
});
export const useStyles = makeOverrideableStyles("Boolean", styles);

export interface BooleanProps<UseStrings extends boolean> extends StyledComponentProps<typeof styles> {
    /**
     * id of the component for unique identification. This value is prefixed with "boolean-input-".
     * provided automatically when component is rendered inside a [Input](/?path=/docs/core-components-inputs-input) component.
     */
    id: string,
    /**
     * callback to called when component stops being interacted with.
     * provided automatically when component is rendered inside a [Input](/?path=/docs/core-components-inputs-input) component.
     * @function onBlur
     */
    onBlur?: () => void,
    /**
     * callback to call when the input value has been changed.
     * provided automatically when component is rendered inside a [Input](/?path=/docs/core-components-inputs-input) component.
     * @function onChange
     */
    onChange?: (value: UseStrings extends true ? ("true" | "false") : boolean) => void,
    /**
     * extra options passed to the Switch component.
     */
    options?: Partial<SwitchProps>,
    /**
     * Use string representations of "true" and "false" in value/onChange instead of boolean values.
     */
    useStrings?: UseStrings,
    /**
     * current value of that input.
     * provided automatically when component is rendered inside a [Input](/?path=/docs/core-components-inputs-input) component.
     */
    value?: "true" | "false" | boolean,
    /**
     * additional text to show on the right of Boolean input when enabled.
     */
    toggleEnabledText?: string
    /**
     * additional text to show on the right of Boolean input when disabled.
     */
    toggleDisabledText?: string
}

/**
 * A Boolean switch component.
 *
 * This is implements the BDS/MUI Switch component, with a few minor additions to the logic:
 *
 *  - Optionally hanles either pure booleans or string representations.
 *  - Sets a prefixed id for unique identification.
 *  - Applies some minor styling adjustments.
 */
export const Boolean = <UseStrings extends boolean>(props: BooleanProps<UseStrings>) => {
    const {onChange, onBlur, id, useStrings, value = false, options, toggleEnabledText, toggleDisabledText} = props;
    const classes = useStyles(props);
    const [translate] = useTranslation();
    const setValue = (value: boolean) => {
        if (useStrings) {
            onChange?.(value ? "true" : "false" as any);
        } else {
            onChange?.(value as any);
        }
    };

    useEffect(() => {
        // Force value to be one of the accepted values (true/false or "true"/"false")
        if (useStrings && value !== "true" && value !== "false") {
            setValue(value);
            onBlur?.();
        } else if (!useStrings && value !== true && value !== false) {
            setValue(value === "true");
            onBlur?.();
        }
    }, [value]);

    const checked = useStrings ? value === "true" : !!value;

    return (
        <div className={classes.root}>
            <Switch
                checked={checked}
                onChange={(event, value) => setValue(value)}
                id={id && "boolean-input-" + id}
                color="primary"
                className={classes.switch}
                {...options}
            />
            {toggleEnabledText && checked ?
                <Typography variant="body1" className={classes.toggleEnabledText}>
                    {translate(toggleEnabledText)}
                </Typography> : null
            }
            {toggleDisabledText && !checked ?
                <Typography variant="body1" className={classes.toggleDisabledText}>
                    {translate(toggleDisabledText)}
                </Typography> : null
            }
        </div>
    );
};

export default Boolean;