import React from "react";
import {Grid, Theme} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import {BaseCard, BaseCardProps, CardFooter, CardFooterProps} from "../../baseCards";
import {CardSizeOptions, CardSizes} from "../../../typesAndConstants";
import {RecentEvent, RecentEventEntry} from "./RecentEvent";

export interface RecentEventsCardProps extends Omit<BaseCardProps, "children"> {
    /** entries to display as RecentEvents */
    entries: RecentEventEntry[],
    /** the total number of entries available */
    total: number,
    /** override translation key for footer */
    footerKey: CardFooterProps["footerKey"],
    /** loading state */
    loading?: boolean,
    /** click handler for when an event row is clicked */
    onEventClick?: (event: RecentEventEntry) => void
}

const styles = (theme: Theme) => createStyles<string, RecentEventsCardProps>({
    container: {
        color: theme.palette.text.primary,
        maxHeight: ({size}) => `calc(${CardSizeOptions[size]?.height}px - ${size === CardSizes.large ? 82 : 58}px)`,
        minHeight: ({size}) => `calc(${CardSizeOptions[size]?.height}px - ${size === CardSizes.large ? 82 : 58}px)`,
        margin: "0 16px",
        width: `calc(100% - 32px)`,
        overflowY: "auto"
    },
});

const useStyles = makeStyles(styles);

export const RecentEventsCard = (props: RecentEventsCardProps) => {
    const {title, preview, entries, total, size, footerKey, loading, onEventClick} = props;
    const length = entries.length;
    const classes = useStyles(props);

    return (
        <BaseCard title={title} size={size} preview={preview}>
            <Grid container className={classes.container} direction="column" wrap="nowrap">
                {loading && new Array(5).fill(null).map((entry, index) => (
                    <RecentEvent
                        key={index}
                        size={size}
                        loading
                    />
                ))}
                {!loading && entries.map((entry) => (
                    <RecentEvent
                        key={entry.id}
                        size={size}
                        eventName={entry.eventName}
                        description={entry.description}
                        status={entry.status}
                        created={entry.created}
                        onClick={() => onEventClick?.(entry)}
                    />
                ))}
            </Grid>
            {size === CardSizes.large &&
                <CardFooter
                    footerKey={footerKey}
                    length={length}
                    total={total}
                />
            }
        </BaseCard>
    );
};