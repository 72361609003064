import React from "react";
import {SvgIcon, useTokens} from "@barracuda-internal/bds-core";
import classNames from "classnames";

export const WarnIcon = ({className}: { className?: string }) => {
    const tokens = useTokens();
	return (
		<SvgIcon
			className={classNames(className, "cuda-react-icon")}
			id="cuda-icon-warn"
			viewBox="0 0 60 60"
		>
			<path style={{fill: tokens.aliasesColorIconWarningNeutral, strokeWidth: 0}}
				  d="M30,6.8L3.1,53.2h53.7L30,6.8ZM32.4,45.9h-4.9v-4.9h4.9v4.9ZM27.6,36.1v-9.8h4.9v9.8h-4.9Z"/>
		</SvgIcon>
	);
};

export default WarnIcon;