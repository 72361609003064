import {Button, Dialog, List, ListItem, ListItemText} from "@barracuda-internal/bds-core";
import {ListItemIcon, Theme} from "@mui/material";
import {ExpandLess} from "@mui/icons-material";
import classNames from "classnames";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@mui/styles";
import {useTranslation} from "react-i18next";
import {Account, DialogBody, Toolbar, Workspace} from "@cuda-react/core";
import WorkspaceListItem from "./WorkspaceListItem";
import {useHistory} from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => ({
    listItem: {
        borderRadius: 4,
        '&:hover': {
            backgroundColor: '#E4F4FF', // TODO: Does this need tokenizing?
        },
        // color: '#646464', // TODO: Does this need tokenizing?
        height: 40,
        width: `calc(100% - 8px)`,
        padding: "8px 4px 8px 4px"
    },
    listItemName: {
        fontSize: 14,
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden"
    },
    listItemActive: {
        color: "#0A5D91 !important" // TODO: Does this need tokenizing?
    },
    listItemTextActive: {
        fontWeight: "bold" // TODO: Does this need tokenizing?
    },
    listItemDescription: {
        fontSize: 12,
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden"
    },
    nestedList: {
        transition: "height 0.25s ease-in-out",
        padding: 0,
        height: 0
    },
    nestedListExpanded: {
        height: "auto"
    },
    nestedListItem: {
        paddingLeft: "16px"
    },
    listItemIcon: {
        minWidth: 20
    },
    expandIcon: {
        transition: "transform 0.25s ease-in-out",
    },
    expandIconExpanded: {
        transform: "rotate(180deg)"
    },
    dialogContent: {
        padding: "16px !important",
    }
}));

interface AccountListItemProps {
    account: Account;
    active: boolean;
    activeWorkspaceId: string | number;
    defaultAccount: boolean;
    setWorkspace: (workspace: Workspace) => void;
    workspaces: Workspace[];
    swapAccount: (account: Account) => void
}

export const AccountListItem = ({account, active, activeWorkspaceId, defaultAccount, setWorkspace, workspaces, swapAccount}: AccountListItemProps) => {
    const classes = useStyles();
    const [translate] = useTranslation();
    const [open, setOpen] = useState(false);
    const [expanded, setExpanded] = useState(workspaces.some((workspace) => workspace.id === activeWorkspaceId));
    // If an account has no workspaces, it is treated as being "unsubscribed", as otherwise it should have at least 1 workspace.
    const noWorkspaces = workspaces.length < 1;
    const defaultAccountText = defaultAccount ? translate("cuda.auth.defaultAccount") : "";
    const noWorkspacesText = noWorkspaces ? translate("stratos.accounts.noWorkspaces") : "";
    const {push} = useHistory();
    const secondaryText = defaultAccountText || noWorkspacesText ? defaultAccountText + " " + noWorkspacesText : undefined;

    useEffect(() => {
        if (workspaces.some((workspace) => workspace.id === activeWorkspaceId)) {
            setExpanded(true);
        }
    }, [activeWorkspaceId]);

    return (
        <li>
            <ListItem
                onClick={() => {
                    if (noWorkspaces) {
                        setOpen(true);
                    } else {
                        setExpanded((val) => !val);
                    }
                }}
                id={account.id.toString()}
                className={classNames(classes.listItem, active && classes.listItemActive)}
            >
                <ListItemText
                    classes={{
                        primary: classNames(classes.listItemName, active && classes.listItemTextActive),
                        secondary: classes.listItemDescription
                    }}
                    primary={account.name}
                    primaryTypographyProps={{title: account.name}}
                    secondary={secondaryText}
                    secondaryTypographyProps={{title: secondaryText}}
                />
                {!noWorkspaces ? (
                    <ListItemIcon className={classes.listItemIcon}>
                        <ExpandLess className={classNames(classes.expandIcon, expanded && classes.expandIconExpanded)}/>
                    </ListItemIcon>
                ) : null}
            </ListItem>
            {!noWorkspaces && expanded && (
                <List className={classNames(classes.nestedList, classes.nestedListExpanded)}>
                    {workspaces.map((workspace) => (
                        <WorkspaceListItem
                            key={workspace.id}
                            workspace={workspace}
                            setWorkspace={setWorkspace}
                            active={activeWorkspaceId === workspace.id}
                            classes={{listItem: classes.nestedListItem}}
                        />
                    ))}
                </List>
            )}
            <Dialog open={open}>
                <DialogBody title="stratos.accounts.startTrial.title" classes={{dialogContent: classes.dialogContent}}>
                    {translate("stratos.accounts.startTrial.body", {account})}
                </DialogBody>
                <Toolbar>
                    <Button
                        size="small"
                        variant="contained"
                        bdsType="interactiveEmphasis"
                        onClick={() => {
                            swapAccount(account);
                            push("/welcome");
                        }}
                    >
                        {translate("stratos.accounts.startTrial.ok")}
                    </Button>
                    <Button
                        size="small"
                        variant="contained"
                        bdsType="interactiveNeutral"
                        onClick={() => setOpen(false)}
                    >
                        {translate("stratos.accounts.startTrial.cancel")}
                    </Button>
                </Toolbar>
            </Dialog>
        </li>
    );
};

export default AccountListItem;