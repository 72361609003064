import {Button, Typography} from "@barracuda-internal/bds-core";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useCrudFetch, useDeepCompareEffect} from "../../../hooks";
import {CrudParams, CrudTypes} from "../../../clients";
import DialogBody from "../DialogBody/DialogBody";
import Toolbar from "../../layout/Toolbar/Toolbar";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import {createStyles} from "@mui/styles";

const styles = createStyles({
    body: {
        textAlign: "center",
        padding: 16,
        maxWidth: 600
    }
});
const useStyles = makeOverrideableStyles("ActionDialog", styles);

export interface ActionDialogContentProps<Data extends object | undefined> extends StyledComponentProps<typeof styles> {
    /**
     * the [CRUD](/?path=/docs/cudareactapp-crud--page) request type to perform on confirm.
     */
    crudType?: CrudTypes,
    /**
     * object containing associated data. Passed to any message, title and params callbacks that are provided.
     *
     * Components such as [ConnectedTable](/?path=/docs/core-components-table-connectedtable--connected-table),
     * [ActionButtonsField](/?path=/docs/core-components-fields-actionbuttonsfield--action-buttons-field), and others
     * will automatically pass this down.
     */
    data?: Data,
    /**
     * the [CRUD](/?path=/docs/cudareactapp-crud--page) resource to request on confirm.
     */
    resource?: string,
    /**
     * the message to display in the main body of the dialog, or a callback to retrieve the message to display.
     *
     * @function
     * @param {object} data the data, as provided to ActionDialogContent.
     * @returns {object} the message to display in the main body of the dialog.
     */
    message?: string | React.ReactNode | ((data?: Data) => string | React.ReactNode),
    /**
     * callback, called when the dialog is closed (either after a confirmed and successful [CRUD](/?path=/docs/cudareactapp-crud--page) request,
     * or the dialog is cancelled/closed).
     */
    onClose?: () => void,
    /**
     * callback, called when the dialog confirm button is clicked
     * @function
     * @returns {boolean} a truthy value will close the dialog, otherwise it will remain open
     */
    onConfirm?: () => boolean,
    /**
     * callback, called when the dialog is confirmed and a successful [CRUD](/?path=/docs/cudareactapp-crud--page) request is returned.
     */
    onSuccess?: () => void,
    /**
     * the [CRUD](/?path=/docs/cudareactapp-crud--page) params to use in the request on confirm, or a callback to retrieve
     * the params.
     *
     * @function
     * @param {object} data the data, as provided to ActionDialogContent.
     * @returns {object} the [CRUD](/?path=/docs/cudareactapp-crud--page) params to use in the request on confirm.
     */
    params?: CrudParams | ((data?: Data) => CrudParams),
    /**
     * the title to display in the dialog, or a callback to retrieve the title to display.
     *
     * @function
     * @param {object} data the data, as provided to ActionDialogContent.
     * @returns {object} the title to display in the dialog.
     */
    title?: string | ((data?: Data) => string)
}

/**
 * A preset dialog content for performing user-confirmed CRUD requests.
 *
 * Once the dialog is confirmed, the provided [CRUD](/?path=/docs/cudareactapp-crud--page) resource will be called
 * with the provided params and [CRUD](/?path=/docs/cudareactapp-crud--page type.
 *
 * This component is an integral part of [ActionDialog](/?path=/docs/core-components-dialogs-actiondialogcontent--action-dialog-content).
 */
export const ActionDialogContent = <Data extends object | undefined>(props: ActionDialogContentProps<Data>) => {
    const {title = "cuda.action.title", data, resource, params, crudType = CrudTypes.ACTION, message = "cuda.action.message", onClose, onConfirm, onSuccess} = props;
    const classes = useStyles(props);
    const [translate] = useTranslation();
    const [submitted, setSubmitted] = useState(false);
    const [actionData, actionLoading, performAction] = useCrudFetch(crudType, resource, typeof params === "function" ? params(data) : params);

    useDeepCompareEffect(() => {
        if (submitted && !actionLoading && actionData && actionData.data !== undefined && !actionData.error) {
            onClose?.();
            onSuccess?.();
        }
    }, [actionLoading, actionData]);

    const handleConfirm = () => {
        resource && performAction();
        setSubmitted(true);
        if (onConfirm?.()) {
            onSuccess?.();
            onClose?.();
        }
    };

    let renderedMessage = typeof message === "function" ? message(data) : message;
    if (typeof renderedMessage === "string") {
        renderedMessage = (
            <Typography variant="body1">
                {translate(renderedMessage)}
            </Typography>
        );
    }

    return (
        <React.Fragment>
            <DialogBody
                title={translate(typeof title === "function" ? title(data) : title)}
            >
                <Container className={classes.body}>
                    {renderedMessage}
                </Container>
            </DialogBody>
            <Toolbar>
                <Button
                    variant="contained"
                    bdsType="interactiveEmphasis"
                    size="small"
                    startIcon={(actionLoading ? (
                        <CircularProgress id="cuda-loading-spinner" size={16} thickness={2}/>
                    ) : undefined)}
                    disabled={actionLoading}
                    onClick={handleConfirm}
                >
                    {translate("cuda.action.confirm")}
                </Button>
                <Button
                    variant="contained"
                    bdsType="interactiveNeutral"
                    size="small"
                    disabled={actionLoading}
                    onClick={onClose}
                >
                    {translate("cuda.action.cancel")}
                </Button>
            </Toolbar>
        </React.Fragment>
    );
};

export default ActionDialogContent;