import React from "react";
import {CreatedIdentityProvider} from "../IdentityProviderTypes";
import {InputAdornment, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {Box, CircularProgress, TextField} from "@barracuda-internal/bds-core";
import CopyToClipboardButton from "../../../../../components/CopyToClipboardButton";
import {makeStyles} from "@mui/styles";


const useStyles = makeStyles((theme) => ({
    inputRoot: {
        width: `calc(100% - 64px)`,
        margin: "16px 31px 8px",
        "& .MuiOutlinedInput-multiline": {
            height: "inherit"
        }
    },
}));

interface IdentityProviderSAMLSetup {
    identityProvider: CreatedIdentityProvider;
}
export const IdentityProviderSAMLSetup = ({identityProvider}: IdentityProviderSAMLSetup) => {
    const [translate] = useTranslation();
    const classes = useStyles();

    return (
        <>
            <Box display="flex" flexDirection="column" gap={1}>
                <Typography mb={2}>{translate('tesseract.identity.identityProviders.saml.informationNeeded')}</Typography>
                <TextField
                    id="entity-id"
                    className={classes.inputRoot}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">
                            {identityProvider.saml?.entityId ? (
                                <CopyToClipboardButton
                                    value={identityProvider.saml?.entityId || ""}
                                    iconOnly
                                />
                            ) : (
                                <CircularProgress size={24}/>
                            )}
                        </InputAdornment>
                    }}
                    fullWidth
                    label={translate('tesseract.identity.identityProviders.saml.entityId')}
                    value={identityProvider.saml?.entityId || ""}
                    disabled={!identityProvider.saml?.entityId}
                    variant="outlined"
                />
                <TextField
                    id="acs-url"
                    className={classes.inputRoot}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">
                            {identityProvider.saml?.acsURL ? (
                                <CopyToClipboardButton
                                    value={identityProvider.saml?.acsURL || ""}
                                    iconOnly
                                />
                            ) : (
                                <CircularProgress size={24}/>
                            )}
                        </InputAdornment>
                    }}
                    fullWidth
                    label={translate('tesseract.identity.identityProviders.saml.acsURL')}
                    value={identityProvider.saml?.acsURL || ""}
                    disabled={!identityProvider.saml?.acsURL}
                    variant="outlined"
                />
            </Box>
        </>
    );
};