import React from "react";
import {SelectArray, Text} from "@cuda-react/core";
import {get} from "lodash";
import {makeStyles} from "@mui/styles";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
    filter: {
        marginTop: "8px"
    }
}));

interface CustomAppDestinationFilterProps {
    value?: string | object | any;
    onChange?: (value: string | object) => void;
    choices?: any[];
    className?: string;
}

const CustomAppDestinationFilter: React.FC<CustomAppDestinationFilterProps> = ({className, value, onChange, choices}) => {
    const classes = useStyles();
    const update = (field: string) => (eventOrValue: { target: { value: any; }; }) => onChange && onChange({
        ...(value || {}),
        [field]: eventOrValue.target ? eventOrValue.target.value : eventOrValue
    });

    return (
        <React.Fragment>
            <Text
                // @ts-ignore
                source="destination"
                id="cuda-filter-input-destinations"
                label="tesseract.customApps.table.destination"
                value={get(value, "destination", "")}
                onChange={update("destination")}
                className={classNames(className, classes.filter)}
            />
            <SelectArray
                source="protocol"
                id="protocol"
                label="tesseract.customApps.table.protocol"
                value={get(value, "protocol")}
                // @ts-ignore
                onChange={update("protocol")}
                choices={choices}
                className={classNames(className, classes.filter)}
            />
        </React.Fragment>
    );
};

export default CustomAppDestinationFilter;