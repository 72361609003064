import SvgIcon from "@mui/material/SvgIcon";
import {useTheme} from "@mui/material";
import React from "react";

export interface AppsIconProps {
    /** size (in pixels) of icon */
    size?: number
}

export const AppsIcon = (props: AppsIconProps) => {
    const theme = useTheme();
    return (
        <SvgIcon viewBox="2 2 21 21" style={{width: props.size ?? 50, height: props.size ?? 50}}>
            <g transform="matrix(1,0,0,1,-5.42152,-5.995)">
                <rect x="5.422" y="5.995" width="24.026" height="24.026" style={{fill: "none"}}/>
                <g transform="matrix(1,0,0,1,7.86524,8.10862)">
                    <path
                        d="M0.569,0.899L0.569,8.899L8.569,8.899L8.569,0.899L0.569,0.899ZM6.569,6.899L2.569,6.899L2.569,2.899L6.569,2.899L6.569,6.899ZM0.569,10.899L0.569,18.899L8.569,18.899L8.569,10.899L0.569,10.899ZM6.569,16.899L2.569,16.899L2.569,12.899L6.569,12.899L6.569,16.899ZM10.569,0.899L10.569,8.899L18.569,8.899L18.569,0.899L10.569,0.899ZM16.569,6.899L12.569,6.899L12.569,2.899L16.569,2.899L16.569,6.899ZM10.569,10.899L10.569,18.899L18.569,18.899L18.569,10.899L10.569,10.899ZM16.569,16.899L12.569,16.899L12.569,12.899L16.569,12.899L16.569,16.899Z"
                        style={{fill: theme.palette.text.primary, fillRule: "nonzero"}}
                    />
                </g>
            </g>
        </SvgIcon>
    );
};