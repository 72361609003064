import React from "react";
import {SvgIcon, useTokens} from "@barracuda-internal/bds-core";
import classNames from "classnames";

export const SaasIcon = ({className}: { className?: string }) => {
    const tokens = useTokens();
    return (
        <SvgIcon
            className={classNames(className, "cuda-react-icon")}
            id="cuda-icon-saas"
            viewBox="0 0 60 60"
        >
            <path style={{fill: tokens.aliasesColorTextStaticNeutral, strokeWidth: 0}}
                  d="M16.5,49.7c-3.7,0-6.9-1.3-9.6-3.9-2.6-2.6-4-5.7-4-9.5s1-6.1,2.9-8.6c1.9-2.5,4.5-4.1,7.6-4.8,1-3.8,3.1-6.8,6.2-9.2s6.6-3.5,10.5-3.5,8.9,1.7,12.2,5c3.3,3.3,5,7.4,5,12.2,2.8.3,5.2,1.5,7,3.7,1.9,2.1,2.8,4.6,2.8,7.4s-1.1,5.7-3.2,7.8c-2.2,2.2-4.8,3.2-7.8,3.2h-29.5ZM16.5,44.8h29.5c1.7,0,3.2-.6,4.4-1.8,1.2-1.2,1.8-2.6,1.8-4.4s-.6-3.2-1.8-4.4-2.6-1.8-4.4-1.8h-3.7v-4.9c0-3.4-1.2-6.3-3.6-8.7s-5.3-3.6-8.7-3.6-6.3,1.2-8.7,3.6-3.6,5.3-3.6,8.7h-1.2c-2.4,0-4.4.8-6.1,2.5-1.7,1.7-2.5,3.7-2.5,6.1s.8,4.4,2.5,6.1c1.7,1.7,3.7,2.5,6.1,2.5Z"/>
        </SvgIcon>
    );
};


export default SaasIcon;