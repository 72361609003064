import {ButtonDialog, Tabs} from '@cuda-react/core';
import React from 'react';
import AddDashboardDialog from "../AddDashboardDialog";
import RenameDashboardDialog from "../RenameDashboardDialog";
import ResetDashboardDialog from "../ResetDashboardDialog";
import {Theme} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import {Dashboard} from "../../../hooks/useDashboards";
import {ExpandMore} from "@mui/icons-material";
import DeleteDialogContent from "@cuda-react/core/lib/components/dialog/DeleteDialogContent/DeleteDialogContent";
import {StyledComponentProps} from "@cuda-react/theme";
import {AddDashboardDialogProps} from "../AddDashboardDialog/AddDashboardDialog";
import {TabsProps} from '@cuda-react/core/lib/components/tabs/Tabs/Tabs';

const styles = (theme: Theme) => createStyles({
    container: {
        width: "100%",
    },
    optionsMenuButton: {
        marginLeft: "8px"
    },
    addButton: {
        marginLeft: "8px"
    },
    singleDashboardActions: {
        display: "flex",
        width: "100%",
        justifyContent: "right"
    }
});

export interface DashboardMenuProps extends StyledComponentProps<typeof styles> {
    /**
     * A list of Tabs, will be rendered under the menu
     */
    children: TabsProps["children"],
    /**
     * Callback function called whenever the active tab/dashboard changes
     * @param index
     */
    onChange: (index: number | string) => void,
    /**
     * Function that updates a dashboard and saves its state
     */
    addUpdateDashboard: AddDashboardDialogProps["addUpdateDashboard"],
    /**
     * Function that deletes the dashbard at the index
     * @param index
     */
    deleteDashboard: (activeDashboard: Dashboard) => void,
    /**
     *  The dashboard that is currently active must be matching the active tab index
     */
    activeDashboard: Dashboard,
    /** templates to select for new dashboard. **/
    templates?: Dashboard[]
    /** disables tabs and action buttons, used when in edit mode */
    disabled?: boolean
    /** if true the maximum number of customizable dashboards has been reached */
    maxDashboardsReached?: boolean
    /** the message to show when the add dashboard button is showed */
    maxDashboardsReachedMessage?: string
}
const useStyles = makeStyles(styles);

export const DashboardMenu = (props: DashboardMenuProps) => {
    const {
        children,
        onChange,
        activeDashboard,
        addUpdateDashboard,
        deleteDashboard,
        templates,
        disabled,
        maxDashboardsReached,
        maxDashboardsReachedMessage
    } = props;
    const classes = useStyles(props);

    return (
        <div className={classes.container}>
            <Tabs
                onChange={onChange}
                activeTab={activeDashboard?.name}
                actions={[
                    <ButtonDialog
                        buttonEndIcon={<ExpandMore/>}
                        buttonProps={{
                            className: classes.optionsMenuButton,
                            dashboardMenu: true,
                            iconButton: false,
                            bdsType: "interactiveNeutral",
                            id: "open-dashboard-menu"
                        }}
                        buttonText="stratosReporting.dashboardPage.options"
                        disabled={disabled}
                        key="menu"
                        useMenu
                    >
                        <DeleteDialogContent
                            title="stratosReporting.dashboardPage.delete"
                            message="stratosReporting.dashboardPage.confirmDeletion"
                            optionText="name"
                            // @ts-ignore label is needed for ButtonDialog
                            label="stratosReporting.dashboardPage.delete"
                            data={activeDashboard}
                            onDelete={() => deleteDashboard(activeDashboard)}
                        />
                        <RenameDashboardDialog
                            label="stratosReporting.dashboardPage.rename"
                            dashboard={activeDashboard}
                            addUpdateDashboard={addUpdateDashboard}
                        />
                        <ResetDashboardDialog
                            label="stratosReporting.dashboardPage.reset"
                            dashboard={activeDashboard}
                            addUpdateDashboard={addUpdateDashboard}
                            templates={templates}
                        />
                    </ButtonDialog>,
                    <ButtonDialog
                        buttonText="stratosReporting.dashboardPage.add"
                        buttonProps={{
                            bdsType: "interactiveEmphasis",
                        }}
                        disabled={disabled || maxDashboardsReached}
                        disabledMessage={maxDashboardsReachedMessage}
                        buttonClasses={{button: classes.addButton}}
                        key="add"
                    >
                        <AddDashboardDialog addUpdateDashboard={addUpdateDashboard} templates={templates}/>
                    </ButtonDialog>
                ]}
                condensedMenu
            >
                {children as any}
            </Tabs>
        </div>
    );
};

export default DashboardMenu;