import React from "react";
import {ButtonDialog} from "@cuda-react/core";
import {Edit} from "@barracuda-internal/bds-core/dist/Icons/Core";

interface CreateEditButtonDialogProps {
    create?: boolean;
    data?: Record<string, any> | null;
    disabled?: boolean | ((data: Record<string, any>) => boolean);
    disabledMessage?: string | ((data: Record<string, any>) => string);
    label?: string;
    component: React.ComponentType<any>;
    onSuccess?: () => void;
    dataProps?: (data?: Record<string, any> | null) => Record<string, any> | undefined | null;
    [key: string]: any; // You can adjust this to allow any other additional props
}

const CreateEditButtonDialog: React.FC<CreateEditButtonDialogProps> = ({
    create,
    data,
    label,
    component: Component,
    onSuccess,
    disabled,
    disabledMessage,
    dataProps,
    ...props
}) => {
    const internalDisabled = typeof disabled === "function" ? disabled(data ?? {}) : disabled;
    const internalDisabledMessage = typeof disabledMessage === "function" ? disabledMessage(data ?? {}) : disabledMessage;
    const propsFromData = dataProps?.(data) || {} ;

    return (
        <ButtonDialog
            buttonText={create ? label : undefined}
            buttonIcon={create ? undefined : <Edit id="edit-rule-icon" />}
            disabled={internalDisabled}
            disabledMessage={internalDisabledMessage}
            stopPropagation
        >
            {React.createElement(Component, {create, id: data?.id, onSuccess, ...propsFromData, ...props})}
        </ButtonDialog>
    );
};

CreateEditButtonDialog.defaultProps = {
    label: "tesseract.security.addRuleButtonText"
};

export default CreateEditButtonDialog;