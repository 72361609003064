import {SvgIcon, useTokens} from "@barracuda-internal/bds-core";
import React from "react";
import classNames from "classnames";

const EventSuccessIcon = ({className}: { className?: string }) => {
    const tokens = useTokens();
    return (
        <SvgIcon
            className={classNames(className, "cuda-react-icon")}
            id="cuda-icon-event-success"
            viewBox="0 0 60 60"
        >
            <path style={{fill: tokens.aliasesColorTextStaticNeutral, strokeWidth: 0}}
                  d="M49.7,5.4H10.3c-2.7,0-4.9,2.2-4.9,4.9v44.2l9.8-9.8h34.4c2.7,0,4.9-2.2,4.9-4.9V10.3c0-2.7-2.2-4.9-4.9-4.9ZM49.7,39.8H15.3l-4.9,4.9V10.3h39.3v29.5Z"/>
            <path style={{fill: tokens.aliasesColorIconSuccessNeutral, strokeWidth: 0}} d="M38.8,16.5l-12.7,12.7-5-5-2.7,2.7,7.7,7.7,15.4-15.4-2.7-2.7Z"/>
        </SvgIcon>
    );
};

export default EventSuccessIcon;