import React, {useState} from "react";
import {BaseCard} from "../../baseCards";
import {CardSizeOptions, CardSizes} from "../../../typesAndConstants";
import {PreviewDialogContent, PreviewDialogContentProps} from "../PreviewDialogContent";
import {Theme} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import {Button, Dialog, Grid, Typography} from "@barracuda-internal/bds-core";
import {useTranslation} from "react-i18next";
import {WidgetAddIcon} from "@cuda-react/icons";

const styles = (theme: Theme) => createStyles({
    card: {
        boxShadow: "none",
        backgroundColor: "#E9E9E9",
        transition: "transform 0.3s",
        "&:hover $addIcon": {
            transform: "scale(1.2)"
        },
        "&:hover $labelText": {
            transform: "scale(1.2)"
        }
    },
    button: {
        width: CardSizeOptions.small.width,
        height: `calc(${CardSizeOptions.small.height}px - 4px)`,
        "&:disabled": {
            opacity: 0.5
        }
    },
    icon: {
        alignFont: "center"
    },
    addIcon: {
        width: 64,
        height: 64,
        fill: "#5f5f5f",
        transition: "transform 0.3s",
    },
    label: {},
    labelText: {
        textAlign: "center",
        color: theme.palette.text.primary,
        alignFont: "center",
        transition: "transform 0.3s",
    },
    dialog: {},
    dialogPaper: {
        minWidth: "fit-content"
    },
});
const useStyles = makeStyles(styles);

export interface AddWidgetCardProps extends Pick<PreviewDialogContentProps, "onAdd" | "cards" | "forceSmall"> {
}

/**
 * Button for adding a new widget (card) to a dashboard.
 */
export const AddWidgetCard = (props: AddWidgetCardProps) => {
    const {cards, onAdd, forceSmall} = props;
    const classes = useStyles(props);
    const [translate] = useTranslation();
    const [open, setOpen] = useState(false);

    return (
        <BaseCard size={CardSizes.small} className={classes.card}>
            <Button
                size="small"
                className={classes.button}
                bdsType="interactiveSubtle"
                onClick={() => setOpen(true)}
                disabled={Object.values(cards).length < 1}
            >
                <Grid container justifyContent="center" direction="column" spacing={1}>
                    <Grid item xs={12} className={classes.icon}>
                        <WidgetAddIcon className={classes.addIcon}/>
                    </Grid>
                    <Grid item xs={12} className={classes.label}>
                        <Typography variant="h6" className={classes.labelText}>
                            {translate("stratosReporting.previewDialog.addWidgetCard")}
                        </Typography>
                    </Grid>
                </Grid>
            </Button>
            <Dialog
                open={open}
                className={classes.dialog}
                maxWidth={false}
                classes={{paper: classes.dialogPaper}}
                disableEnforceFocus
            >
                <PreviewDialogContent
                    onClose={() => setOpen(false)}
                    onAdd={onAdd}
                    cards={cards}
                    forceSmall={forceSmall}
                />
            </Dialog>
        </BaseCard>
    );
};