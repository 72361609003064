import React from "react";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import {useTranslation} from "react-i18next";
import StepIcon from "../StepIcon/StepIcon";
import {Divider, Step, StepLabel, Stepper, StepperProps} from "@barracuda-internal/bds-core";
import {StepButton, Theme} from "@mui/material";
import {createStyles} from "@mui/styles";

export const styles = (theme: Theme) => createStyles({
    root: {
        backgroundColor: theme.palette.background.paper,
        padding: 12
    },
    stepLabel: {
        marginTop: "6px !important"
    },
    subStepper: {
        paddingTop: 8,
        paddingBottom: 12,
        marginTop: -6
    },
    stepperButton: {
        marginBottom: 0,
        paddingBottom: 0
    },
    subStepperButton: {
        marginTop: 0,
        paddingTop: 0
    },
    stepLabelCompleted: {
        // @ts-ignore it complains about the !important flag, but we need it here to properly override material-ui
        fontWeight: "400 !important"
    },
    divider: {
        margin: "0 16px"
    }
});

const useStyles = makeOverrideableStyles("WizardStepper", styles);


export interface SubStepDefinition {
    /** label to display for the step. */
    label?: string,
    /** if true, error state will be ignored for this step. */
    hideErrors?: boolean,
    /** if true, then direct navigation to steps before this step will be disabled when the active step is at or after this step. */
    preventBackNavigation?: boolean,
    /** if true, the sub-step stepper will not be rendered. */
    hideSubNavigation?: boolean,
    /** if true, step is marked as completed. */
    completed?: boolean,
    /** if true, step will be displayed with error icon. */
    error?: boolean,
    /** if true, step is available for direct navigation. */
    visited?: boolean,
    /** index number for the step. */
    index: number
}

export interface StepDefinition extends SubStepDefinition {
    /**
     * an array of sub steps for this step. These will be rendered in a sub-step stepper.
     * Each step should consist of the same set of properties as the main steps.
     */
    subSteps?: StepDefinition[]
}

export interface WizardStepperProps extends StyledComponentProps<typeof styles>, Omit<Partial<StepperProps>, "classes"> {
    /**
     * the current active step.
     */
    activeStep: number,
    /**
     * the current active sub step.
     */
    activeSubStep: number,
    /**
     * if true, direct navigation via clicking visited steps is disabled.
     */
    disableDirectNavigation?: boolean,
    /**
     * callback called when a step is clicked for direct navigation.
     * @function
     * @param {number} stepIndex the index value of the step that was clicked.
     */
    onStepChange?: (stepIndex: number) => void,
    /**
     * callback called when a sub-step is clicked for direct navigation.
     * @function
     * @param {number} stepIndex the index value of the step that was clicked.
     */
    onSubStepChange?: (stepIndex: number) => void,
    /**
     * definition of the available steps, and their current state.
     */
    steps: StepDefinition[]
}

/**
 * Stepper component for a wizard. Used internally within the [Wizard](/?path=/docs/core-components-wizard-wizard--wizard) component.
 *
 * This stepper allows for "direct navigation" to a previously visited step.
 */
export const WizardStepper = (props: WizardStepperProps) => {
    const {activeStep, activeSubStep, disableDirectNavigation, steps, onStepChange, onSubStepChange, ...stepperProps} = props;
    const classes = useStyles(props);
    const [translate] = useTranslation();
    const subSteps = steps[activeStep] && steps[activeStep].subSteps;

    return (
        <React.Fragment>
            <Stepper
                className={classes.root}
                activeStep={activeStep}
                nonLinear
                alternativeLabel
                id="cuda-wizard-stepper"
                {...stepperProps}
            >
                {steps.map((step) => {
                    const canDirectNavigate = step.visited && !disableDirectNavigation && !steps.slice(step.index + 1, activeStep + 1).some((step) => step.preventBackNavigation);

                    return (
                        <Step key={step.label} completed={step.completed && step.index !== activeStep}>
                            {canDirectNavigate ? (
                                <StepButton
                                    onClick={() => onStepChange?.(step.index)}
                                    // classes={{root: classes.stepperButton}}
                                    // error={step.error && !step.hideErrors}
                                    icon={<StepIcon
                                        active={step.index === activeStep}
                                        completed={step.completed && step.index !== activeStep}
                                        icon={step.index + 1}
                                        error={step.error && !step.hideErrors}
                                    />}
                                >
                                    {step.label && translate(step.label)}
                                </StepButton>
                            ) : (
                                <StepLabel
                                    // @ts-ignore don't know why it wont accept this, even though it is a component...
                                    StepIconComponent={StepIcon}
                                    classes={{
                                        label: classes.stepLabel,
                                        completed: classes.stepLabelCompleted
                                    }}
                                    error={step.error && !step.hideErrors}
                                    active={step.visited}
                                >
                                    {step.label && translate(step.label)}
                                </StepLabel>
                            )}
                        </Step>
                    );
                })}
            </Stepper>
            {subSteps && !steps[activeStep].hideSubNavigation && (
                <Stepper
                    nonLinear
                    activeStep={activeSubStep}
                    alternativeLabel
                    id="cuda-wizard-sub-stepper"
                    classes={{root: classes.subStepper}}
                    {...stepperProps}
                >
                    {subSteps.map((step) => {
                        const stepError = !steps[activeStep].hideErrors && !step.hideErrors && step.error;
                        const canDirectNavigate = !disableDirectNavigation && step.visited;
                        return (
                            <Step key={step.index} completed={step.completed && step.index !== activeSubStep}>
                                {canDirectNavigate ? (
                                    <StepButton
                                        onClick={() => onSubStepChange?.(step.index)}
                                        // classes={{root: classes.stepperButton}}
                                        // error={step.error && !step.hideErrors}
                                        icon={<StepIcon
                                            completed={step.completed && step.index !== activeSubStep}
                                            icon={step.index + 1}
                                            error={step.error && !step.hideErrors}
                                            active={step.index === activeSubStep}
                                        />}
                                    >
                                        {step.label && translate(step.label)}
                                    </StepButton>
                                ) : (
                                    <StepLabel
                                        // @ts-ignore don't know why it wont accept this, even though it is a component...
                                        StepIconComponent={StepIcon}
                                        classes={{
                                            label: classes.stepLabel,
                                            completed: classes.stepLabelCompleted
                                        }}
                                        error={stepError}
                                        active={step.visited}
                                    >
                                        {step.label && translate(step.label)}
                                    </StepLabel>
                                )}
                            </Step>
                        );
                    }) || null}
                </Stepper>
            )}
            <Divider className={classes.divider}/>
        </React.Fragment>
    );
};

WizardStepper.defaultProps = {
    activeSubStep: 0
};

export default WizardStepper;