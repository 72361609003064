import {Chip, FormControl, IconButton, TextField} from "@barracuda-internal/bds-core";
import {Expand} from "@barracuda-internal/bds-core/dist/Icons/Core";
import classNames from "classnames";
import {isArray, isEmpty} from "lodash";
import React, {ChangeEvent, useState} from "react";
import {formatErrorMessage} from "../../../utils";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import {InputAdornment, Theme} from "@mui/material";
import {createStyles} from "@mui/styles";

const DELIMITER_REGEX = RegExp("[;,\\s]+");

const styles = (theme: Theme) => createStyles({
    formControl: {
    },
    textField: {
        width: 256,
        "& input": {
            fontSize: 16
        }
    },
    chip: {
        margin: "0.1rem",
        pointerEvents: "all"
    },
    chipError: {
        border: `1px solid ${theme.palette.error.main}`
    },
    addButton: {
        padding: 0
    },
    chipContainer: {
        maxWidth: 256,
        marginBottom: "0.1rem"
    }
});
export const useStyles = makeOverrideableStyles("TextArray", styles);

export interface TextArrayProps extends StyledComponentProps<typeof styles> {
    /**
     * if true, the input is disabled.
     */
    disabled?: boolean,
    /**
     * max number of characters for each chip.
     */
    maxChipLength?: number,
    /**
     * max number of selected chips. Input will get disabled in case this number is hit.
     */
    maxInputs?: number,
    /**
     * error to display.
     * can be either:
     * - string
     * - object
     * - array
     */
    error?: any,
    /**
     * onBlur callback to called when component stops being interacted with.
     */
    onBlur?: (value: string[]) => void,
    /**
     * onChange callback to call when input's value wants to be updated.
     */
    onChange?: (value: string[]) => void,
    /**
     * additional props to pass to the underlying TextField.
     */
    options?: Partial<React.ComponentProps<typeof TextField>>,
    /**
     *  used to generate unique id for the component "text-array-input-$(id)"
     */
    id: string,
    /**
     * passed to the TextField component to set it's type.
     */
    type?: React.ComponentProps<typeof TextField>["type"],
    /**
     * disable pasting bulk input with delimiter.
     */
    bulkInputDisabled?: boolean,
    /**
     * the current value
     */
    value?: string[]
}

/**
 * Based on the TextField component, but modified to use the default [Input](/?path=/docs/core-components-inputs-input) format. Works natively with redux form "input" prop.
 * This component renders a TextField from where you can add chips to a list.
 */
export const TextArray = (props: TextArrayProps) => {
    const {
        options = {},
        id,
        value,
        onChange,
        onBlur,
        error,
        type = "text",
        disabled,
        maxInputs,
        bulkInputDisabled = false,
        maxChipLength = 30
    } = props;
    const classes = useStyles(props);
    const [currentString, setCurrentString] = useState<string>("");

    if (value && !isArray(value)) {
        return null;
    }

    const addChips = (chips: string[], currentString?: string) => {
        const currString = currentString ? currentString : "";
        let newChips = value ? [...value].concat(chips) : chips;
        newChips = newChips.map((chip) => chip.trim()).filter((chip) => !!chip);
        newChips = [...new Set(newChips)];
        onChange?.(newChips);
        setCurrentString(currString);
    };

    const onEnter = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            addChips([currentString]);
        }
    };

    const onTextFieldChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newValue = event.target.value;
        if (!bulkInputDisabled && checkPaste(newValue) && hasDelimiter(newValue)) {
            handlePaste(newValue);
        } else {
            setCurrentString(newValue);
        }
    };

    const checkPaste = (value: string) => value.trim().length - currentString.length > 5;

    const hasDelimiter = (value: string) => value.search(DELIMITER_REGEX) > 0;

    const handlePaste = (value: string) => {
        const valueSplit = value.split(DELIMITER_REGEX);
        const chips = valueSplit.slice(0, valueSplit.length - 1);
        addChips(chips, valueSplit[valueSplit.length - 1]);
    };

    const handleRequestDelete = (index: number) => {
        const newValue = (value || []).filter((val, itt) => itt !== index);
        onChange && onChange(newValue);
    };

    const inputLimitReached = (maxInputs && value && (value.length >= maxInputs)) || false;
    const addButtonDisabled = !(currentString.length > 0) || inputLimitReached;

    return (
        <FormControl className={classes.formControl}>
            <div className={classes.chipContainer}>
                {value && value.map((val: string, index: number) => (
                    <Chip
                        key={index}
                        onDelete={disabled ? undefined : () => handleRequestDelete(index)}
                        className={classNames(
                            classes.chip,
                            !disabled && isArray(error) && !isEmpty(error[index]) && classes.chipError
                        )}
                        disabled={disabled}
                        label={typeof val === "string" && val.length > maxChipLength ? val.substring(0, maxChipLength - 3) + "..." : val}
                    />
                ))}
            </div>
            <TextField
                value={currentString}
                onKeyDown={onEnter}
                onChange={onTextFieldChange}
                onBlur={() => {
                    onBlur?.(value || []);
                    currentString && addChips([currentString]);
                }}
                type={type}
                helperText={(!disabled && formatErrorMessage(error) || undefined) as any}
                error={!!(!disabled && error)}
                {...options}
                id={"text-array-input-" + id}
                disabled={disabled || inputLimitReached}
                className={classes.textField}
                variant="outlined"
                InputProps={{
                    endAdornment: <InputAdornment position="end">
                        <IconButton
                            id="add-to-text-array-button"
                            className={classes.addButton}
                            onClick={() => addChips([currentString])}
                            disabled={addButtonDisabled}
                            color="primary"
                        >
                            <Expand />
                        </IconButton>
                    </InputAdornment>
                }}
            />
        </FormControl>
    );
};

export default TextArray;