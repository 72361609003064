import {TFunction} from "i18next";
import {Theme} from "@mui/material";
import {get, isEqual} from "lodash";
import {
    ChartRef,
    LocationMapSeriesDefinition,
    SetLocationTooltip
} from "./UseLocationsMap";
import {handleLinkedPointClick} from "./handleLinkedPointClick";
import {isLinkSeries} from "./clearLinkedSeries";
import {CustomMapPoint} from "./getLinkedPoints";

/**
 * Adds the provided points as a new series in the map chart
 */
export const drawLinkedPoints = (chart: ChartRef, sourceType: LocationMapSeriesDefinition, points: CustomMapPoint[], setTooltip: SetLocationTooltip, translate: TFunction, theme: Theme) => {
    const label = sourceType.linkedSeries && sourceType.linkedSeries.label;
    const seriesConfig = {
        id: "linked-points-point-series",
        type: "mappoint",
        cursor: "pointer",
        data: points,
        name: label ? translate(label) : undefined,
        visible: true,
        showInLegend: !!label,
        zIndex: (sourceType.linkedSeries && sourceType.linkedSeries.zIndex) || 9999,
        color: (sourceType.linkedSeries && sourceType.linkedSeries.color) || sourceType.color,
        borderColor: theme.palette.background.paper,
        marker: {
            symbol: "circle",
            lineWidth: 1,
            radius: 6
        },
        states: {
            hover: {
                enabled: false
            },
            inactive: {
                enabled: false
            }
        },
        allowPointSelect: false,
        point: {
            events: {
                click: handleLinkedPointClick(chart, setTooltip, translate, theme)
            }
        },
        events: {
            hide: () => {
                const lineSeries = chart.current?.series.find((series) => get(series, "userOptions.id") === "linked-points-line-series");
                lineSeries && lineSeries.hide && lineSeries.hide();
            },
            show: () => {
                const lineSeries = chart.current?.series.find((series) => get(series, "userOptions.id") === "linked-points-line-series");
                lineSeries && lineSeries.show && lineSeries.show();
            }
        }
    } as any;

    const existingPointSeries = chart.current?.series.find((series) => series.userOptions.id === seriesConfig.id);
    if (existingPointSeries) {
        existingPointSeries.update(seriesConfig, false);
    } else {
        chart.current?.addSeries(seriesConfig, false);
    }

    // Hide original points by removing them
    chart.current?.series
        .filter((series) => !isLinkSeries(series) && get(series, "userOptions.originalData"))
        .forEach((series) => {
            const data = (get(series, "userOptions.originalData") as any)
                .filter(
                    (dataItem: any) => points.every(({source}: any) => {
                        const {pointData: {linkedSeriesParent, linkedSeriesSources, series, ...values}} = dataItem;
                        return !isEqual(source, values);
                    })
                );
            series.setData(data, false);
        });
};