import React from 'react';
import classNames from "classnames";
import {SvgIcon, useTokens} from "@barracuda-internal/bds-core";

export const Small = ({className}: { className?: string })=> {
    const tokens = useTokens();
    return (
        <SvgIcon
            className={classNames(className, "cuda-react-graphic")}
            id="cuda-gridsize-small"
            viewBox="0 0 60 60"
        >
            <path style={{fill: tokens.aliasesColorTextStaticNeutral, strokeWidth: 0}}
                  d="M7.7,7.7v19.8h19.8V7.7H7.7ZM7.7,32.5v19.8h19.8v-19.8H7.7ZM22.6,47.3h-9.9v-9.9h9.9v9.9ZM32.5,7.7v19.8h19.8V7.7h-19.8ZM47.3,22.6h-9.9v-9.9h9.9v9.9ZM32.5,32.5v19.8h19.8v-19.8h-19.8ZM47.3,47.3h-9.9v-9.9h9.9v9.9Z"/>
        </SvgIcon>
    );
};