import {colors, SvgIcon, useTokens} from "@barracuda-internal/bds-core";
import React from "react";
import classNames from "classnames";

export const NothingFoundBackgroundGraphic = ({className}: { className?: string }) => {
    const tokens = useTokens();
    return (
        <SvgIcon
            className={classNames(className, "cuda-react-graphic")}
            id="cuda-graphic-nothingfound"
            viewBox="0 0 128 128"
        >
            <path style={{fill: tokens.aliasesColorIconInfoNeutral, strokeWidth: 0}} d="M93.8,11.9l3.3-.2-2,2.6.2,3.3-2.6-2-3.3.2,2-2.6-.2-3.3,2.6,2Z"/>
            <path style={{fill: colors.magenta600, strokeWidth: 0}}
                  d="M87.1,16.4h1.6c0,0-1,1.2-1,1.2v1.6c0,0-1.2-1-1.2-1h-1.6c0,0,1-1.2,1-1.2v-1.6c0,0,1.2,1,1.2,1Z"/>
            <path style={{fill: tokens.globalBlue50, strokeWidth: 0}}
                  d="M82.9,18.9h1.1c0,0-.7.8-.7.8v1.1c0,0-.8-.7-.8-.7h-1.1c0,0,.6-.8.6-.8v-1.1c0,0,.8.7.8.7Z"/>
            <path style={{fill: tokens.globalBlue50, strokeWidth: 0}}
                  d="M95.2,126.5c0-.6,0-1.1,0-1.6,0-1,.5-1.4,1.4-1.4,1.1,0,2.3,0,3.4,0h7.8c.9,0,1.4.4,1.5,1.2,0,.6,0,1.3,0,1.9h-14.1Z"/>
            <path style={{fill: tokens.globalBlue50, strokeWidth: 0}}
                  d="M106.7,105.7c0,2.4-2,4.4-4.4,4.4-2.4,0-4.4-1.9-4.4-4.4,0-2.5,2-4.4,4.4-4.4,2.4,0,4.4,2,4.4,4.4Z"/>
            <path style={{fill: tokens.globalBlue50, strokeWidth: 0}}
                  d="M86.5,92.8c0-1.6,1.3-2.9,2.9-2.9,1.6,0,2.9,1.3,2.9,2.9,0,1.6-1.3,2.9-2.9,2.9-1.6,0-2.9-1.3-2.9-2.9"/>
            <g>
                <path style={{fill: tokens.globalBlue50, strokeWidth: 0}}
                      d="M102.3,111.6c-1.2,0-2.4-.4-3.3-1.1-.5,3.8-1.1,7.6-1.6,11.5.5,0,1,0,1.6,0h8.2c-.5-3.8-1.1-7.7-1.6-11.5-.9.7-2.1,1-3.3,1.1Z"/>
                <path style={{fill: tokens.globalBlue50, strokeWidth: 0}}
                      d="M93.4,94.1c-.4,1.3-1.4,2.4-2.8,2.8,2,2.3,4,4.7,6.1,7.1.3-.9.7-1.7,1.4-2.3.7-.7,1.5-1.1,2.3-1.4-2.4-2.1-4.7-4.1-7.1-6.1Z"/>
            </g>
            <path style={{fill: tokens.aliasesColorTextStaticNeutral, strokeWidth: 0}}
                  d="M60.1,69.2c-.5,0-.9-.5-1.1-1.5l1.6-.3c0,.3.2.5.2.5l-.7.5h0s0,.9,0,.9c0,0,0,0,0,0ZM60.6,65.7l-1.6-.3c.2-.9.5-2,1.1-3.1l1.5.7c-.5,1-.8,1.9-.9,2.7ZM62.5,61.2l-1.5-.8c.5-.9,1-1.8,1.4-2.7l1.5.7c-.4.9-.9,1.9-1.4,2.8ZM62.3,56.8l-.2-1.6c.5,0,1-.2,1.5-.3.2-.6.4-1.2.6-1.8l1.6.4c-.2.6-.4,1.2-.6,1.8l.3.5c-.2.1-.4.2-.6.3,0,.1,0,.2-.1.3h-.4c-.7.1-1.4.3-2.2.3ZM60,56.7c-1.1-.2-2.1-.5-3.2-.9l.7-1.5c.9.4,1.8.7,2.7.8l-.2,1.6ZM54.9,54.6c-.8-.7-1.4-1.7-1.9-2.9l1.5-.6c.4.9.9,1.7,1.5,2.3l-1.1,1.2ZM67.4,54.6l-1.1-1.2c.7-.6,1.3-1.3,1.9-2.2l1.4.9c-.7,1-1.4,1.8-2.2,2.5ZM66.3,51.4l-1.6-.3c.1-.8.2-1.5.2-2.2s0-.4,0-.7h1.6c0,.2,0,.4,0,.7,0,.8,0,1.6-.2,2.5ZM70.7,50.3l-1.5-.8c.5-.9.8-1.8,1.1-2.7l1.6.4c-.3,1-.7,2-1.2,3ZM52.4,49.5c0-.4,0-.7,0-1.1,0-.8.1-1.6.4-2.3l1.5.6c-.2.5-.3,1.1-.3,1.8s0,.6,0,.9l-1.6.2ZM64.6,46.3c-.2-.9-.7-1.7-1.3-2.2l1.1-1.2c.8.8,1.4,1.8,1.8,3l-1.6.4ZM55.2,45.2l-1.2-1.1c.3-.4.7-.7,1.1-1,.5-.4,1.1-.7,1.7-.9l.7,1.5c-.5.2-1,.5-1.4.8-.3.2-.6.5-.9.7ZM72.2,45h-1.6c0-.4,0-.6,0-.8,0-.7,0-1.4-.2-2.1l1.6-.3c.2.8.2,1.6.2,2.4s0,.6,0,.8ZM61.8,43.2c-.8-.3-1.7-.3-2.6-.1l-.3-1.6c1.2-.2,2.4-.2,3.5.2l-.5,1.6ZM70,40.1c-.2-.6-.4-1.2-.6-1.8l-.4-1.1,1.6-.5.4,1.1c.2.6.4,1.2.6,1.8l-1.6.5ZM68.4,35.1c-.1-.6-.2-1.2-.2-1.7s0-.8.1-1.3c0-.1,0-.3,0-.4l1.6.3v.4c-.1.3-.2.7-.2,1s0,.9.1,1.4l-1.6.3ZM70.6,30.2l-1.6-.6c.4-1,.8-2,1.4-2.9l1.4.9c-.5.8-.9,1.7-1.3,2.6ZM73,26l-1.3-1c.7-.8,1.4-1.6,2.3-2.3l1.1,1.3c-.8.6-1.5,1.3-2.1,2.1ZM76.6,22.8l-.9-1.4c1.6-1,2.9-1.5,2.9-1.5l.5,1.6s-1.1.4-2.6,1.3Z"/>
            <g>
                <path style={{fill: tokens.aliasesColorTextStaticNeutral, strokeWidth: 0}}
                      d="M67.9,71.1c-.3,0-.6-.1-.8-.4-.3-.4-.2-1,.2-1.3l1.7-1.2c.4-.3,1-.2,1.3.2.3.4.2,1-.2,1.3l-1.7,1.2c-.2.1-.4.2-.5.2Z"/>
                <path style={{fill: tokens.aliasesColorTextStaticNeutral, strokeWidth: 0}}
                      d="M65.5,69.4c0,0-.1,0-.2,0-.5,0-.8-.6-.8-1.1l.3-2c0-.5.6-.8,1.1-.8.5,0,.8.6.8,1.1l-.3,2c0,.5-.5.8-.9.8Z"/>
                <path style={{fill: tokens.aliasesColorTextStaticNeutral, strokeWidth: 0}}
                      d="M70.6,74.4c0,0-.1,0-.2,0l-2-.3c-.5,0-.8-.6-.8-1.1,0-.5.6-.8,1.1-.8l2,.3c.5,0,.8.6.8,1.1,0,.5-.5.8-.9.8Z"/>
            </g>
            <path style={{fill: tokens.aliasesColorTextStaticNeutral, strokeWidth: 0}}
                  d="M72.3,81.6l-6.2-3c-.3.4-.6.8-1,1.1s-.9.6-1.4.8c-1.3.5-2.6.4-3.8-.2s-2.1-1.5-2.5-2.9c-.5-1.3-.4-2.6.2-3.8.6-1.2,1.5-2.1,2.9-2.5,1.3-.5,2.6-.4,3.8.2s2.1,1.5,2.5,2.9.3,1.1.3,1.6c0,.5,0,1-.3,1.5l6.2,3-.7,1.4ZM63.2,79.1c.9-.3,1.6-.9,2-1.8s.5-1.7.1-2.6c-.3-.9-.9-1.6-1.8-2s-1.7-.5-2.6-.1-1.6.9-2,1.8-.5,1.7-.1,2.6.9,1.6,1.8,2c.9.4,1.7.5,2.6.1Z"/>
            <path style={{fill: tokens.globalBlue50, strokeWidth: 0}}
                  d="M86.8,88.6c-2.7-1.7-5.4-3.5-8.2-5.2.2-.2.3-.4.5-.6.2-.3.5-.5.7-.8l.2-.2-2-1.4-.2.2c-.1.2-.3.4-.4.5v-.2c-.3-.6-.4-1.1-.6-1.7-.1-.5-.4-.8-1-.8-.5,0-1.1,0-1.6-.1-1,0-1.9-.1-2.9-.2h0c0,0-.2,0-.3,0l3,1.5c.4,0,.7,0,1,0,.3,0,.5.1.5.4.1.5.3.9.4,1.4l.2.6c0,.2,0,.6-.3,1-.2.3-.5.6-.7.6-1,0-1.8,0-2.4,0,0,0-.2-.1-.3-.3-.1-.3-.3-.7-.4-1l-1.9-.9c.1.3.3.7.4,1,.3.6.5,1.2.8,1.8.3.7.8.7,1.2.7.5,0,1,0,1.5,0,.1,0,.2,0,.4,0l-.5.8.6.4c.3.2.7.5,1.1.8l.2.2v-.3c.4-.5.7-1,1-1.6l8.1,6.5c.2-1.3,1.1-2.4,2.3-3l-.5-.3Z"/>
            <path style={{fill: tokens.aliasesColorTextStaticNeutral, strokeWidth: 0}}
                  d="M62,78.5c.2,0,.3,0,.4-.2.1-.1.2-.2.2-.4s0-.3-.2-.4c-.1-.1-.2-.2-.4-.2s-.3,0-.4.2-.2.2-.2.4,0,.3.2.4c.1.1.2.2.4.2ZM61.6,76.8h.8c0-.2,0-.4,0-.6s.2-.3.5-.6c.2-.2.3-.4.4-.5s.2-.4.2-.6c0-.4-.1-.7-.4-.9s-.7-.3-1.1-.3-.8.1-1,.3-.4.5-.5.8l.7.3c0-.1.1-.3.2-.4s.3-.2.6-.2.4,0,.5.2.2.3.2.4,0,.3-.1.4-.2.2-.3.4c-.3.3-.5.5-.6.6s-.1.4-.1.8Z"/>
        </SvgIcon>
    );
};

export default NothingFoundBackgroundGraphic;