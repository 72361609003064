import React from "react";
import {Typography} from "@barracuda-internal/bds-core";
import {getArrayDataContent, TextInput, useCrudProps} from "@cuda-react/core";
import {useSiteInputValidations} from "../../../../../hooks/createEditSiteHooks";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@mui/styles";
import {useWatch} from "react-hook-form";
import apiResources from "../../../../../apiResources";

const useStyles = makeStyles((theme) => ({
    message: {
        marginTop: "8px",
        marginBottom: "8px"
    }
}));

interface SelectLanWanLengthStepProps {
    applianceType?: "site" | "gateway",
    type?: "wans" | "lans",
    portsData?: [],
    wanTotal?: number
}

const SelectLanWanLengthStep: React.FC<SelectLanWanLengthStepProps> = ({applianceType, type}) => {
    const inputValidations = useSiteInputValidations();
    const classes = useStyles();
    const [translate] = useTranslation();
    const [model, wanTotal = 1, wans = []] = useWatch({name: ["serials.0.model", "wanTotal", "wans"]});
    const hasWanBridge = wans.slice(0, wanTotal).some((wan: any) => wan.mode === "bridge");
    const portsData = getArrayDataContent(useCrudProps(apiResources.modelPorts, {id: model})[0]?.data);

    return (
        <React.Fragment>
            <Typography className={classes.message} variant="body1">
                {translate(`tesseract.appliances.dialog.${type}.message`, {
                    portsAvailable: portsData && portsData.length,
                    wans: wanTotal
                })}
            </Typography>
            <Typography className={classes.message} variant="body1">
                {translate(`tesseract.appliances.dialog.${type}.message2`, {context: applianceType})}
            </Typography>
            <TextInput
                label={`tesseract.appliances.dialog.${type}.size`}
                source={type === "wans" ? "wanTotal" : "lanTotal"}
                isRequired
                validate={type === "wans" ? inputValidations.validateWansLength : inputValidations.validateLansLength}
                type="integer"
                options={{
                    type: "number",
                    InputProps: {
                        inputProps: {
                            min: type === "lans" && hasWanBridge ? 0 : 1,
                            max: 16,
                            step: 1
                        }
                    }
                }}
            />
        </React.Fragment>
    );
};


export default SelectLanWanLengthStep;