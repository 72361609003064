import React, {useCallback, useState} from "react";
import apiResources from "../../../../apiResources";
import {
    BooleanInput,
    ConnectedFormPage,
    DownloadButton,
    FormSection,
    getArrayDataContent,
    PropGateway,
    SelectInput,
    TextInput,
    useCrudProps,
    useGlobalParam,
    useMixpanel,
    usePreview,
    validateInt,
    validateMaxValueMemo,
    validatePrivateIpv4CIDR
} from "@cuda-react/core";
import {Typography} from "@barracuda-internal/bds-core";
import {Trans, useTranslation} from "react-i18next";
import {makeStyles} from "@mui/styles";
import {get, isString} from "lodash";

const useStyles = makeStyles((theme) => ({
    helpText: {
        padding: "8px 8px 32px 24px",
        //@ts-ignore TODO: this theme entry does exist. We should extend DefaultTheme to fix this properly (or BDS should do that).
        color: theme.palette.text.secondary
    },
    downloadButton: {
        margin: "0 16px 16px 32px"
    },
    deviceLimitSelect: {
        width: 96
    },
    deviceLimitInfo: {
        marginLeft: 344,
        top: -30,
        position: "relative"
    }
}));

export const webFilterChoices = [
    {name: "tesseract.endpoint.zeroTrustSettings.enforce", key: true},
    {name: "tesseract.endpoint.zeroTrustSettings.disable", key: false}
];
const getNetworkMask = (network: any) => isString(network) && parseInt(network.substring(network.indexOf("/") + 1));
const getMask = (value: any) => (value && parseInt(value) > 0 && parseInt(value) < 32) ? parseInt(value) : undefined;

type networkState = {
    networkSize?: number | undefined,
    networkPoolSize?: number | undefined,
    clientNetworkSize?: number | undefined
};

const ZeroTrustSettings: React.FC = () => {
    const registerAction = useMixpanel("Endpoint Zero Trust Settings");
    const [translate] = useTranslation();

    const isCgfRegistered = getArrayDataContent(useCrudProps(apiResources.firewall)[0]).length > 0;
    const [virtualWanId] = useGlobalParam("filter.virtualWanId");
    const [{networkSize = 0, networkPoolSize, clientNetworkSize}, setFormValues] = useState<networkState>({});
    const validateMinPoolSize = useCallback((value) => value && parseInt(value) <= networkSize ? translate("tesseract.endpoint.zeroTrustSettings.validatePoolSize") : undefined, [networkSize]);
    const classes = useStyles();
    const tpmEnforcement = usePreview("tpm");

    return (
        <ConnectedFormPage
            resource={apiResources.zeroTrustSettings}
            title="tesseract.endpoint.pageTitle"
            subtitle="tesseract.endpoint.zeroTrustSettingsTitle"
            onSubmitSuccess={() => {
                registerAction("Update");
            }}
            formatResourceData={(data) => ({
                ...data,
                dnsSuffix: data?.dnsSuffix ? data?.dnsSuffix.toString() : "",
                fecLevel: data?.fecLevel === "medium",
                manualConfigurationEnabled: !!data?.clientNetworkConfiguration,
                clientNetworkConfiguration: data?.clientNetworkConfiguration ? {
                    ...data?.clientNetworkConfiguration,
                    poolSize: (data?.clientNetworkConfiguration?.poolSize)?.toString()
                } : {range: "", poolSize: ""}
            })}
            formatRequestData={(data) => ({
                ...data,
                fecLevel: data?.fecLevel ? "medium" : "off",
                clientNetworkConfiguration: data?.manualConfigurationEnabled ? data.clientNetworkConfiguration : null,
                virtualWanId
            })}
            onChange={(newValues) => {
                const networkSize = getMask(getNetworkMask(newValues?.clientNetworkConfiguration?.range));
                const networkPoolSize = getMask(newValues?.clientNetworkConfiguration?.poolSize);
                const clientNetworkSize = (Math.pow(2, 32 - (networkPoolSize || 0)) - 2);
                setFormValues({
                    networkSize,
                    networkPoolSize,
                    clientNetworkSize
                });
            }}
            canReset
        >
            <FormSection title="tesseract.endpoint.zeroTrustSettings.globalZtnaSettings" newStyle hideBorder>
                <BooleanInput
                    source="fecLevel"
                    label="tesseract.endpoint.zeroTrustSettings.fecLevel"
                    hideInput
                />
                <TextInput
                    source="dnsSuffix"
                    label="tesseract.endpoint.zeroTrustSettings.dnsSuffix"
                    additionalInfoLabel="tesseract.endpoint.zeroTrustSettings.descriptions.dnsSuffix"
                    newStyle
                />
                <BooleanInput
                    source="tamperProof"
                    label="tesseract.users.settings.tamperProof"
                    additionalInfoLabel="tesseract.users.settings.descriptions.tamperProof"
                    toggleEnabledText="tesseract.users.settings.enabled"
                    newStyle
                />
                <BooleanInput
                    source="devicePreConnect"
                    label="tesseract.users.settings.devicePreConnect"
                    toggleEnabledText="tesseract.users.settings.enabled"
                    additionalInfoLabel="tesseract.users.settings.descriptions.devicePreConnect"
                    newStyle
                />
                <SelectInput
                    label="tesseract.users.settings.deviceLimit"
                    source="deviceLimit"
                    choices={new Array(10).fill(null).map((item, index) => ({
                        key: index + 1,
                        name: `${index + 1}`
                    }))}
                    additionalInfoLabel="tesseract.users.settings.descriptions.deviceLimit"
                    inputLabelProps={{
                        classes: {
                            inputAdditionalInfo: classes.deviceLimitInfo
                        }
                    }}
                    inputProps={{
                        classes: {
                            formControl: classes.deviceLimitSelect
                        }
                    }}
                    newStyle
                />
                <BooleanInput
                    source="webFilter"
                    label="tesseract.users.settings.webFiltering"
                    toggleEnabledText="tesseract.users.settings.enabled"
                    newStyle
                />
                {tpmEnforcement ?
                    <BooleanInput
                        source="tpmEnforcement"
                        label="tesseract.users.settings.tpmEnforcement"
                        additionalInfoLabel="tesseract.users.settings.descriptions.tpmEnforcement"
                        toggleEnabledText="tesseract.users.settings.enabled"
                        toggleDisabledText="tesseract.users.settings.disabled"
                        newStyle
                    /> : null
                }
            </FormSection>
            <FormSection title="tesseract.endpoint.zeroTrustSettings.networkSectionTitle" newStyle>
                <PropGateway>
                    <Typography className={classes.helpText}>
                        <Trans i18nKey="tesseract.endpoint.zeroTrustSettings.clientNetworkDescription"/>
                    </Typography>
                </PropGateway>
                <PropGateway>
                    <Typography className={classes.helpText}>
                        <Trans i18nKey="tesseract.endpoint.zeroTrustSettings.clientNetworkSize"
                               values={{connections: networkPoolSize ? clientNetworkSize : "?"}}>
                            <strong/>
                        </Trans>
                    </Typography>
                </PropGateway>
                <BooleanInput
                    source="manualConfigurationEnabled"
                    label="tesseract.endpoint.zeroTrustSettings.manualConfigurationEnabled"
                    newStyle
                />
                <TextInput
                    source="clientNetworkConfiguration.range"
                    label="tesseract.endpoint.zeroTrustSettings.clientNetworkRange"
                    additionalInfoLabel="tesseract.endpoint.zeroTrustSettings.descriptions.clientNetworkRange"
                    disable={(value, data) => !get(data, "manualConfigurationEnabled")}
                    isRequired
                    validate={[validatePrivateIpv4CIDR]}
                    newStyle
                    inputLabelProps={{nested: true}}
                />
                <TextInput
                    source="clientNetworkConfiguration.poolSize"
                    label="tesseract.endpoint.zeroTrustSettings.clientNetworkPoolSize"
                    additionalInfoLabel="tesseract.endpoint.zeroTrustSettings.descriptions.clientNetworkPoolSize"
                    disable={(value, data) => !get(data, "manualConfigurationEnabled")}
                    isRequired
                    inputProps={{type: "number"}}
                    validate={[validateInt, validateMinPoolSize, validateMaxValueMemo(29)]}
                    newStyle
                    inputLabelProps={{nested: true}}
                />
            </FormSection>
            {isCgfRegistered ?
                <FormSection title="CloudGen Firewall Client Certificate" newStyle>
                    <PropGateway>
                        <Typography className={classes.helpText}>
                            <Trans i18nKey="tesseract.endpoint.zeroTrustSettings.downloadCertificateHelpText"/>
                        </Typography>
                    </PropGateway>
                    <DownloadButton
                        label="tesseract.endpoint.zeroTrustSettings.downloadCertificateLabel"
                        filename="certificate"
                        filetype="crt"
                        resource={apiResources.zeroTrustCertificateDownload}
                        params={{filter: virtualWanId, options: {headers: {"Accept": "application/x-x509-ca-cert"}}}}
                        classes={{downloadButton: classes.downloadButton}}
                        bdsType="interactiveEmphasis"
                        requestMethod="POST"
                    />
                </FormSection> : null}
        </ConnectedFormPage>
    );
};

export default ZeroTrustSettings;