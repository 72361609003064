import React, {useRef} from "react";
import {
    ActionButtonsField,
    ChipArrayField,
    CustomField,
    TablePage,
    ConnectedTableRefresh,
    CrudTypes,
    DeleteDialog,
    getArrayDataContent,
    PropGateway,
    StatusIconField,
    TextField,
    useCrudFetch,
    useCrudProps,
    useMixpanel
} from "@cuda-react/core";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import {makeStyles} from "@mui/styles";
import CreateEditButtonDialog from "../../../../components/CreateEditButtonDialog";
import CreateEditUrlExceptions from "./createEdit/CreateEditUrlExceptions";
import {useSourceDestinationField} from "../../../../hooks/sourceAndDestinationHooks";
import DestinationFilter from "../../../../components/sourceAndDestination/DestinationFilter";
import {
    formatSourceDestinationFilters,
    formatUserGroupInTableData
} from "../../../../components/sourceAndDestination/tableUtils";
import SourceFilter from "../../../../components/sourceAndDestination/SourceFilter";
import {settingsIcons} from "../../../../components/iconMapping";
import apiResources from "../../../../apiResources";
import {Theme} from "@mui/material";
import {get, sortBy} from "lodash";
import GroupingField from "../../networkAccess/tabs/GroupingField";
import DefaultActionSelector from "../../../../components/security/DefaultActionSelector";
import {useTranslation} from "react-i18next";

export const styles = (theme: Theme) => ({
    cardActions: {
        width: "100%",
        padding: "13px 0 13px 13px"
    },
    orderCell: {
        minWidth: 70
    },
    actionCell: {
        minWidth: 70,
        paddingLeft: 0
    },
    actionsCell: {
        paddingRight: "0px !important"
    }
});
const useStyles = makeStyles(styles);

const sourceDestinationMap = {
    network: "networks",
    userOrGroup: ["users", "groups"],
    location: "locations.ids",
    site: "sites",
    category: "categories",
    domain: "domains",
    customCategory: "customCategories"
};
const getSource = (source: any) => {
    const sourceName = get(sourceDestinationMap, source?.type);
    if (Array.isArray(sourceName)) {
        return sourceName.flatMap((name) => get(source, name, []));
    }
    return get(source, sourceName, []);
};
const getDestination = (destination: any) => get(destination, get(sourceDestinationMap, destination?.type), []);

export const ExplicitTable = () => {
    const registerAction = useMixpanel("Web Filter", false);
    const actionChoices = getArrayDataContent(useCrudProps(apiResources.urlFilterActions, {}, {}, true)[0]?.data);
    const categoryChoices = sortBy(getArrayDataContent(useCrudProps(apiResources.urlFilterCategories, {}, {}, true)[0]?.data), "key");
    const groupChoices = getArrayDataContent(useCrudProps(apiResources.urlFilterGroups, {}, {}, true)[0]?.data);
    const explicitTableRefreshRef = useRef<ConnectedTableRefresh | null>(null);
    const [, , callUrlExceptions] = useCrudFetch(CrudTypes.CREATE, apiResources.urlExceptions, {});
    const classes = useStyles();
    const handleExceptionsOrderClick = (id: string, direction: string, data: any) => callUrlExceptions({
        id,
        changeOrder: direction,
        data
    }).then(explicitTableRefreshRef.current);
    const generateChipArrayProps = useSourceDestinationField(categoryChoices);
    const customCategories = getArrayDataContent(useCrudProps(apiResources.customCategories)[0]?.data);
    const [translate] = useTranslation();

    return (
        <TablePage
            title="tesseract.securityAndAccess.pageTitle"
            subtitle={["tesseract.securityAndAccess.webFilter", "tesseract.securityAndAccess.urlFilter"]}
            resource={apiResources.urlExceptions}
            exportResource={{resource: apiResources.urlExceptionsExport, filename: "urlExceptions"}}
            titleActions={
                <CreateEditButtonDialog
                    create component={CreateEditUrlExceptions}
                    onSuccess={() => explicitTableRefreshRef.current?.()}
                />
            }
            actions={<DefaultActionSelector
                        defaultActionApi={apiResources.urlFilterDefault}
                        actionChoicesApi={apiResources.urlFilterDefaultActions}
                        registerAction={registerAction}
                        //@ts-ignore this is consumed by parent action bar
                        left
            />}
            params={(params) => {
                const formattedParams = formatSourceDestinationFilters(params);
                return {
                    ...formattedParams,
                    filter: {
                        ...(formattedParams.filter || {}),
                        // TODO: is this right?
                        rules: undefined,
                        rule: [...((params.filter && params.filter.rules && params.filter.rules.category || []))],
                        category: params.filter && params.filter.rules && params.filter.rules.category || [],
                        destinationType: params.filter && params.filter.rules && params.filter.rules.type || [],
                        domain: params.filter && params.filter.rules && params.filter.rules.domain || []
                    }
                };
            }}
            classes={{cardActions: classes.cardActions}}
            groupBy="scope"
            groupField={
                <GroupingField
                    createEditComponent={CreateEditUrlExceptions}
                    render={(value: string) => value === "siteEdgeService" ? "tesseract.security.urlFiltering.exceptions.siteEdgeService" : value === "allSources" ? "tesseract.security.urlFiltering.exceptions.allSources" : "tesseract.security.urlFiltering.exceptions.dnsLocation"}
                    // @ts-ignore
                    onSuccess={explicitTableRefreshRef.current}
                    noIcon
                />
            }
            formatData={(data) => formatUserGroupInTableData(data).map((rowData) => {
                const rowType = rowData?.destination?.type;
                return {
                    ...rowData,
                    details: {
                        categories: rowType === "category" ? groupChoices
                            .map((group) => ({
                                ...group,
                                active: categoryChoices.filter((category) => category.group === group.key && rowData?.destination?.categories?.includes(category.key))
                            }))
                            .filter((group) => group.active.length > 0) : [],
                        domains: rowType === "domain" ? rowData?.destination?.domains?.sort() : [],
                        customCategories: rowType === "customCategory" ? customCategories
                            .filter((category) => rowData?.destination?.customCategories?.includes(category.id)) : [],
                    }
                };
            })}
            minCellWidth={128}
            refreshRef={explicitTableRefreshRef}
            expandableFields={[
                <ChipArrayField
                    source="details.domains"
                    key="domains"
                    label="tesseract.security.urlFiltering.settings.domains"
                    skipMissing
                />,
                <ChipArrayField
                    arraySource="details.categories"
                    source="active"
                    key="categories"
                    sourceValue="name"
                    skipMissing
                />,
                <ChipArrayField
                    source="details.customCategories"
                    key="customCategories"
                    sourceValue="name"
                    label="tesseract.security.urlFiltering.settings.customCategories"
                    skipMissing
                />
            ]}
            rowId="id"
            flat
        >
            <ActionButtonsField
                left
                label="tesseract.security.urlFiltering.settings.order"
                cellClassName={classes.orderCell}
                width={96}
                source="id"
            >
                <TextField
                    source="order"
                />
                {/**@ts-ignore not sure why TS doesn't like this component*/}
                <ArrowUpwardIcon
                    onClick={(event: any, data: any) => handleExceptionsOrderClick(data.id, "increase", data)}
                    disabled={(data: any) => get(data, "order", 0) < 2}
                    id="cuda-icon-up"
                />
                {/**@ts-ignore not sure why TS doesn't like this component*/}
                <ArrowDownwardIcon
                    onClick={(event: any, data: any) => handleExceptionsOrderClick(data.id, "decrease", data)}
                    disabled={(data: any) => (get(data, "groupTotal", 0) - get(data, "order", 0)) < 1}
                    id="cuda-icon-down"
                />
            </ActionButtonsField>
            <TextField
                source="name"
                label="tesseract.security.urlFiltering.exceptions.name"
                filter="text"
            />
            <TextField
                source="description"
                label="tesseract.security.urlFiltering.exceptions.description"
                filter="text"
            />
            <StatusIconField
                source="action"
                text={(action) => (actionChoices.find((item) => item.key === action) || {}).name}
                label="tesseract.security.urlFiltering.exceptions.action"
                cellClassName={classes.actionCell}
                iconMap={settingsIcons}
                filter="select"
                filterProps={{
                    choices: actionChoices
                }}
                width={140}
            />
            <PropGateway
                source="source"
                label="tesseract.security.urlFiltering.exceptions.source"
                editProps={generateChipArrayProps("source")}
                filter="custom"
                filterProps={{
                    component: SourceFilter,
                    filterSources: ["network", "site", "userOrGroup", "location"]
                }}
            >
                <ChipArrayField source="source"/>
            </PropGateway>
            <CustomField
                source="destination"
                label="tesseract.security.urlFiltering.settings.rule"
                render={(destination, data) => {
                    const destinationArray = getDestination(destination);
                    return translate(
                        `tesseract.security.urlFiltering.settings.destination.${destination?.type}`,
                        {...data, context: destinationArray.length, additional: destinationArray.length - 1}
                    );
                }}
                filter="custom"
                filterProps={{
                    component: DestinationFilter,
                    filterSources: ["domain", "category", "customCategory"],
                    table: "WebFilter",
                    categoryChoices,
                    customCategories
                }}
            />
            <ActionButtonsField
                width={96}
                source="id"
                cellClassName={classes.actionsCell}
            >
                <CreateEditButtonDialog
                    component={CreateEditUrlExceptions}
                    onSuccess={() => explicitTableRefreshRef.current?.()}
                    dataProps={(data) => ({defaultScope: data?.scope})}
                />
                <DeleteDialog
                    resource={apiResources.urlExceptions}
                    title="tesseract.security.urlFiltering.settings.delete.title"
                    message="tesseract.security.urlFiltering.settings.delete.body"
                    onSuccess={() => {
                        registerAction("Explicit Delete");
                        explicitTableRefreshRef.current?.();
                    }}
                />
            </ActionButtonsField>
        </TablePage>
    );
};

export default ExplicitTable;