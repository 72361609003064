import {CardActions, Paper} from "@barracuda-internal/bds-core";
import {get, isEmpty} from "lodash";
import React, {MutableRefObject, ReactElement, ReactNode, useCallback} from "react";
import DownloadButton from "../../buttons/DownloadButton/DownloadButton";
import TableActionsAndFilters, {TableActionsAndFiltersProps} from "../TableActionsAndFilters/TableActionsAndFilters";
import {useTranslation} from "react-i18next";
import {
    ConnectedTableRefresh,
    PageStatus,
    useConnectedTable,
    useConnectedTableProps,
    useDataTableExpandableRows,
    useDataTableExpandableRowsProps,
    useDataTableFieldColumns,
    useDataTableFieldColumnsChildProps,
    useDataTableFieldColumnsProps,
    useDataTableGroups,
    useDataTableGroupsProps,
    useDataTablePersonalisedColumns,
    useDataTablePersonalisedColumnsProps,
    useDataTableRowDetails,
    useDataTableRowDetailsProps,
    useDataTableRowHighlighting,
    useDataTableRowHighlightingProps,
    useDataTableSelect,
    useDataTableSelectProps,
    useNoDataPage
} from "../../../hooks";
import {DateTime, Select, SelectArray, Text} from "../../inputs";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import Table, {TableProps} from "../Table/Table";
import {convertFromSimpleFilters, convertToSimpleFilters} from "../../../utils";
import classNames from "classnames";
import ErrorBoundary from "../../functional/ErrorBoundary/ErrorBoundary";
import {createStyles} from "@mui/styles";
import {Theme} from "@mui/material";
import TableFilter from "../TableActionsAndFilters/TableFilter";
import ConditionalWrapper from "../../functional/ConditionalWrapper/ConditionalWrapper";

const styles = (theme: Theme) => createStyles<string, ConnectedTableProps>({
    cardActions: (props) => !props.flat ? {
        paddingTop: 0,
        paddingRight: 0,
        paddingLeft: 0,
        paddingBottom: 0,
        width: "100%",
        flexDirection: "column"
    } : {
        paddingTop: 0,
        paddingRight: 0,
        paddingBottom: 0,
        flexDirection: "column"
    },
    buttonBarWithTitle: {
        marginTop: "-68px",
        marginBottom: "-8px"
    },
    filterBarWithTitle: {
        marginTop: "28px"
    },
    dataTable: {
        "& .k-grid-content": {
            overflowY: (props: ConnectedTableProps) => props.displayVerticalScroll ? undefined : "visible"
        },
        "& .k-grid-header": {
            padding: (props: ConnectedTableProps) => props.displayVerticalScroll ? undefined : "0 !important"
        },
        "& .k-sorted": {
            backgroundColor: theme.palette.background.paper
        },
        "& table": {
            minWidth: (props: ConnectedTableProps) => !props.resizable && !props.editColumns ? React.Children.toArray(props.children)
                .reduce((total: number, child) => {
                    const childWidth = get(child, "props.columnProps.width", get(child, "props.width", props.minCellWidth)) || 0;
                    return total + childWidth;
                }, 0) : "100%"
        },
    },
    flat: {}
});
const useStyles = makeOverrideableStyles("ConnectedTable", styles);

type TableFilter = "text" | "select" | "selectarray" | "datetime" | "custom";
const renderFilter = (
    filter: TableFilter,
    props: any, //SelectArrayProps | SelectProps | DateTimeProps | TextProps
    CustomFilter?: React.FC
) => {
    switch (filter) {
        case "selectarray":
            return <TableFilter {...props} component={SelectArray}/>;
        case "select":
            return <TableFilter {...props} component={Select}/>;
        case "datetime":
            return <TableFilter {...props} component={DateTime}/>;
        case "custom":
            return <TableFilter {...props} component={CustomFilter}/>;
        default:
            return <TableFilter {...props} component={Text}/>;
    }
};

const generateUniqueKey = (child: ConnectedTableChild, index: number) => {
    const key = "" + child.key;
    return key.startsWith(".$") ? key.replace(".$", "") : (child.props.source || index);
};

interface ConnectedTableChildProps extends useDataTableFieldColumnsChildProps {
    /**
     * additional props to provide to the rendered DataTableColumn component.
     */
    columnProps: { field: string },
    /**
     * if provided, a filter for this column is added, using the defined filter type.
     */
    filter: TableFilter,
    /**
     * additional props provided to the created filter (allowing passing in of "choices" to Select or SelectArray filters, for example).
     */
    filterProps?: {
        label?: string,
        component?: ReactElement
    },
    /**
     * the label to use in the column header.
     */
    label: string,
    /**
     * If sorting or filtering is enabled for this column, this is the property name used when applying the sort or filter. It is also used by *Field components to define the field within the row data that should be used when rendering content.
     */
    source: string
}

interface ConnectedTableChild extends ReactElement<ConnectedTableChildProps> {
}

export interface ConnectedTableProps extends useConnectedTableProps,
    useDataTableSelectProps,
    Omit<useDataTablePersonalisedColumnsProps, "minCellWidth">,
    Omit<useDataTableRowHighlightingProps, "classes">,
    Omit<useDataTableRowDetailsProps, "classes">,
    Omit<useDataTableFieldColumnsProps, "classes">,
    Omit<useDataTableGroupsProps, "classes">,
    Omit<useDataTableExpandableRowsProps, "classes">,
    Omit<TableProps, "classes" | "children" | "sortable">,
    StyledComponentProps<typeof styles> {
    /**
     * action/s to display above the table alongside any filters. See [TableActionsAndFilters](/?path=/docs/core-components-table-tableactionsandfilters--table-actions-and-filters) for more information on actions.
     */
    actions?: ReactNode | ReactNode[],
    /**
     * if true, and groupBy is configured, then all groups will initially be rendered in an expanded state. Default behaviour is to only have the first group expanded.
     */
    allGroupsExpanded?: boolean,
    /**
     * One column is rendered per child provided.
     * Each child is then cloned and rendered as the content to each cell in that column. It is provided with the prop "data", which holds the full data for that row.
     *
     * The *Field components in cuda-react are all made to seamlessly work with ConnectedTable.
     *
     * The behaviour of sort/filtering/column labels etc. is defined by props on each child.
     */
    children: ConnectedTableChild | (null | ConnectedTableChild)[],
    /**
     * Disables the ability for the user to edit the number of items per page.
     */
    disablePageSizes?: boolean,
    /**
     * Disables the ability for the user to edit the number of items per page.
     */
    displayVerticalScroll?: boolean,
    /**
     * if true, the visible columns can be edited by the user, to hide/show certain columns. When edit columns is true, you can use "hideByDefault" prop
     * on the children to flag which columns (if any) should not be shown initially.
     */
    editColumns?: boolean,
    /**
     * Props passed to the [DownloadButton](/?path=/docs/core-components-buttons-downloadbutton--download-button) that is rendered if exportResource is set (overriding the default CSV based values).
     */
    exportProps?: object, //Partial<DownloadButtonProps>
    /**
     * A [CRUD](/?path=/docs/key-concepts-crud) resource to use when exporting data.
     *
     * If this is provided, a [DownloadButton](/?path=/docs/core-components-buttons-downloadbutton--download-button) is added
     * to the actions (configured by default to be a CSV file), and uses the data collected from the provided resource (using the
     * same sort/filter params as the current table state).
     */
    exportResource?: {
        resource?: string,
        filename: string,
        directDownload?: boolean
    },  //Pick<DownloadButtonProps, "resource" | "filename" | "directDownload">
    /**
     * if true, the TableActionsAndFilters bar is set with a negative top margin, to align it with a BasicPage title.
     */
    hasTitle?: boolean,
    /**
     * the minimum width (in pixels) that the columns that have not got a defined width should be allowed to shrink to when the window is resized, or when a user is resizing a column.
     */
    minCellWidth?: number,
    /**
     * message to display when the table is empty.
     */
    noDataMessage?: ReactNode | string,
    /**
     * page content to display when the table is empty, instead of the usual table with a noDataMessage.
     */
    noDataPage?: ReactNode,
    /**
     * callback to catch a page status change
     */
    onPageStatusChange?: ((data: PageStatus) => void)
    /**
     * message to display when the table is empty, and there is a filter set.
     */
    noDataWithFilterMessage?: ReactNode | string,
    /**
     * When true, any stale data will be purged on first load
     */
    noStaleData?: boolean,
    /**
     * a react ref, that will be populated by this component with the tables "refresh" action.
     */
    refreshRef?: MutableRefObject<ConnectedTableRefresh | null>,
    /**
     * if true, functionality for resizing the columns will be added.
     */
    resizable?: boolean,
    /**
     * if true, wraps the table in a mui Paper container (used by TablePage).
     */
    paper?: boolean
    /**
     * props to pass through to the TableActionsAndFilters component
     */
    tableActionsAndFiltersProps?: TableActionsAndFiltersProps
}

/**
 * Fully implemented table, using a designated CRUD resource for data retrieval.
 *
 * This table handles pagination, filtering and sorting, as well as a host of other features such as resizing and row selection. It is implemented using a CRUD subscription,
 * so will regularly request new data, and automatically request new data if any state-changing request is successfully performed against the same resource (eg. CrudTypes.CREATE, CrudTypes.DELETE).
 *
 * See the [CRUD](/?path=/docs/key-concepts-crud) page for more information on how sorting/filtering/pagination is handled by the CRUD client.
 */
export const ConnectedTable = (props: ConnectedTableProps) => {
    const {
        actions,
        children,
        disablePageSizes,
        exportProps,
        exportResource,
        hasTitle,
        noDataMessage,
        noDataPage,
        onPageStatusChange,
        noDataWithFilterMessage,
        rowDetails,
        refreshRef,
        tableActionsAndFiltersProps,
        paper,
        ...dataTableProps
    } = props;
    delete dataTableProps.classes;

    const classes = useStyles(props);
    const [translate] = useTranslation();
    const childArray = React.Children.toArray(children).filter(Boolean) as ConnectedTableChild[];

    // Get DataTable props from hooks
    const dataTableFieldColumnsProps = useDataTableFieldColumns(childArray, dataTableProps);
    const {
        dataTableProps: connectedTableProps,
        filterState,
        setFilterState,
        crudParams,
        requestParams,
        refresh
    } = useConnectedTable({...dataTableProps, pageSizes: disablePageSizes ? undefined : props.pageSizes});

    if (refreshRef) {
        refreshRef.current = refresh;
    }

    const {
        children: personalisedColumnChildren,
        actions: personalisedColumnActions,
        ...dataTablePersonalisedColumnsProps
    } = useDataTablePersonalisedColumns(
        dataTableFieldColumnsProps.children,
        // minCellWidth is set in defaultProps, so it will never be undefined when passed into useDataTablePersonalisedColumns
        {...dataTableProps, minCellWidth: dataTableProps.minCellWidth as number}
    );
    const dataTableGroupProps = useDataTableGroups(connectedTableProps.data, dataTableProps);
    const [dataTableSelectProps, selectColumn] = useDataTableSelect(dataTableGroupProps.data, dataTableProps);
    const dataTableRowHighlightingProps = useDataTableRowHighlighting(dataTableProps);
    const [dataTableRowDetailsProps, rowDetailsDialog] = useDataTableRowDetails({rowDetails, ...dataTableProps});
    const {
        children: dataTableExpandableRowChildren,
        ...dataTableExpandableRowProps
    } = useDataTableExpandableRows(
        dataTableSelectProps.data,
        [selectColumn, ...personalisedColumnChildren].filter((item): item is ReactElement => Boolean(item)),
        dataTableProps
    );

    // Create actions and filters
    const downloadButton = (exportResource && (
        <DownloadButton
            resource={exportResource.resource || props.resource || ""}
            directDownload={exportResource?.directDownload || undefined}
            filename={exportResource.filename}
            filetype="csv"
            params={requestParams}
            key="download"
            {...(exportProps || {})}
        />
    ) || undefined);

    const updatedActions = Array.isArray(actions) ? [...actions] : (actions ? [actions] : []);
    downloadButton && updatedActions.unshift(downloadButton);
    personalisedColumnActions && updatedActions.push(personalisedColumnActions);

    const filters = childArray.filter((child) => child.props.filter)
        .map((child, index) => renderFilter(
            child.props.filter, {
                key: generateUniqueKey(child, index),
                source: get(child.props, "columnProps.field", child.props.source),
                id: get(child.props, "columnProps.field", child.props.source),
                label: get(child.props, "filterProps.label", child.props.label),
                ...get(child.props, "filterProps", {})
            },
            get(child.props, "filterProps.component") as (React.FC | undefined)
        ));
    const tableActions = (updatedActions.length > 0 || filters.length > 0) ? (
        <CardActions className={classes.cardActions} disableSpacing>
            <TableActionsAndFilters
                filters={filters}
                filterValues={convertToSimpleFilters(filterState)}
                onChange={(filterValue) => setFilterState(convertFromSimpleFilters(filterValue))}
                actions={updatedActions}
                {...tableActionsAndFiltersProps}
            />
        </CardActions>
    ) : undefined;

    const cellRender = useCallback((cell, cellProps) => dataTableGroupProps.cellRender(
        cell,
        cellProps,
        (cell, cellProps) => dataTableSelectProps.cellRender(
            cell,
            cellProps,
            (cell, cellProps) => dataTableExpandableRowProps.cellRender(
                cell,
                cellProps,
                dataTableFieldColumnsProps.cellRender
            )
        )
    ), [dataTableGroupProps.cellRender, dataTableSelectProps.cellRender, dataTableFieldColumnsProps.cellRender, dataTableExpandableRowProps.cellRender]);
    const headerCellRender = useCallback((cell, cellProps) => dataTableSelectProps.headerCellRender(
        cell,
        cellProps,
        dataTablePersonalisedColumnsProps.headerCellRender
    ), [dataTableSelectProps.headerCellRender, dataTablePersonalisedColumnsProps.headerCellRender]);
    const rowRender = useCallback((row, rowProps) => dataTableExpandableRowProps.rowRender(
        connectedTableProps.rowRender(
            dataTableRowDetailsProps.rowRender(
                dataTableRowHighlightingProps.rowRender(row, rowProps),
                rowProps
            ),
            rowProps
        ),
        rowProps
    ), [dataTableRowDetailsProps.rowRender, dataTableRowHighlightingProps.rowRender, dataTableExpandableRowProps.rowRender]);

    const filterActive = !isEmpty(get(crudParams, "filter"));
    const alternativePage = useNoDataPage(connectedTableProps.data, noDataPage, filterActive, onPageStatusChange);

    // Render table
    return (
        <ErrorBoundary>
            {alternativePage || (
                <ConditionalWrapper condition={paper} wrapper={(children) => <Paper>{children}</Paper>}>
                    {/* @ts-ignore BDS onChange events are incorrectly typed to expect an event rather than an event handler */}
                    <Table
                        {...connectedTableProps}
                        {...dataTableGroupProps}
                        {...dataTablePersonalisedColumnsProps}
                        {...dataTableSelectProps}
                        {...dataTableExpandableRowProps}
                        cellRender={cellRender}
                        headerCellRender={headerCellRender}
                        rowRender={rowRender}
                        className={classNames(classes.dataTable, props.flat && classes.flat)}
                        noRecordsMessage={filterActive ?
                            (typeof noDataWithFilterMessage !== "string" ? noDataWithFilterMessage : translate(noDataWithFilterMessage)) :
                            (typeof noDataMessage !== "string" ? noDataMessage : translate(noDataMessage))
                        }
                        tableActions={tableActions}
                        {...dataTableProps}
                        fitToScreen
                    >
                        {dataTableExpandableRowChildren}
                    </Table>
                </ConditionalWrapper>
            )}
            {rowDetailsDialog}
        </ErrorBoundary>
    );
};

ConnectedTable.defaultProps = {
    addFilterLabel: "cuda.table.addFilter",
    defaultFilter: {},
    defaultItemsPerPage: 40,
    defaultSort: {},
    minCellWidth: 128,
    noDataMessage: "cuda.table.noData",
    noDataWithFilterMessage: "cuda.table.noDataWithFilter",
    pageSizeAll: 1000,
    pageSizes: [5, 10, 20, 40, 80],
    pageMode: "all",
    pollInterval: 30000
};

export default ConnectedTable;