import React from "react";
import {Theme} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";
import {CardSizes} from "../../../../typesAndConstants";
import {formatDateTime} from "@cuda-react/core";
import {Grid, Skeleton} from "@barracuda-internal/bds-core";
import {StatusIcon} from "@cuda-react/icons";

const styles = (theme: Theme) => createStyles<string, RecentEventProps>({
    entryRow: {
        marginTop: ({size}) => size === CardSizes.large ? "16px" : "4px",
        cursor: ({onClick}) => onClick ? "pointer" : undefined
    },
    rowIcon: {
        width: 24,
        height: 24,
        margin: "6px 16px 6px 0",
        flexShrink: 0,
        fontSize: 14
    },
    rowText: {
        flexGrow: 1,
        fontSize: 14
    },
    rowTitle: {
        flexGrow: 1,
        fontSize: 14,
        fontWeight: 500
    },
    rowDescription: {
        fontSize: 14,
        fontWeight: "lighter"
    },
    fillGreen: {
        color: theme.palette.success.main
    },
    fillRed: {
        color: theme.palette.error.main
    },
    dateTime: {
        width: 200,
        flexShrink: 0,
        marginLeft: "8px",
        alignSelf: "flex-start",
        fontSize: 14,
        fontWeight: "lighter"
    },
    skeleton: {
        height: 24
    }
});

const useStyles = makeStyles(styles);

export interface RecentEventEntry {
    id: number | string,
    eventName: string,
    description: string,
    created: string,
    status: "successful" | "failed",
    type: string
}

export interface RecentEventProps extends Partial<RecentEventEntry> {
    size: CardSizes,
    loading?: boolean,
    onClick?: () => void;
}

export const RecentEvent = (props: RecentEventProps) => {
    const {loading, eventName, description, created, status, size, onClick} = props;
    const classes = useStyles(props);
    return (
        <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            wrap="nowrap"
            className={classes.entryRow}
            onClick={onClick}
        >
            <Grid item className={classes.rowIcon}>
                {loading && <Skeleton className={classes.skeleton}/>}
                {!loading && (
                    <StatusIcon status={status}/>
                )}
            </Grid>
            <Grid item container className={classes.rowText}>
                <Grid item xs={12} container wrap="nowrap">
                    <Grid item className={classes.rowTitle}>
                        {loading ? (<Skeleton className={classes.skeleton}/>) : (
                            <strong>{eventName}</strong>
                        )}
                    </Grid>
                    {size !== CardSizes.small &&
                        <Grid item className={classes.dateTime}>
                            {loading ? (<Skeleton className={classes.skeleton}/>) : formatDateTime(created)}
                        </Grid>
                    }
                </Grid>
                {size === CardSizes.large &&
                    <Grid item xs={12} className={classes.rowDescription}>
                        {loading ? (<Skeleton className={classes.skeleton}/>) : description}
                    </Grid>
                }
            </Grid>
        </Grid>
    );
};