import React from "react";
import {Typography} from "@barracuda-internal/bds-core";
import {Trans, useTranslation} from "react-i18next";
import {DateField, SelectSerialInput, TextField} from "@cuda-react/core";
import {makeStyles} from "@mui/styles";
import apiResources from "../../../../../apiResources";

interface SerialStepProps {
    allowHa?: boolean;
    mainMessage: string;
    series: string;
}

const useStyles = makeStyles((theme) => ({
    serialNoteText: {
        marginTop: "8px"
    }
}));

const filterToSelectedModel = (selectedAppliances: any) => selectedAppliances[0] && {model: selectedAppliances[0].model} || {};

export const SerialStep: React.FC<SerialStepProps> = ({allowHa, mainMessage, series}) => {
    const [translate] = useTranslation();
    const classes = useStyles();

    return (
        <React.Fragment>
            <Typography>
                {translate(mainMessage)}
            </Typography>
            {allowHa && (
                <Typography className={classes.serialNoteText} variant="body1">
                    <Trans i18nKey="tesseract.appliances.dialog.serials.haMessage">
                        Translate text <strong>Bold translate text</strong> Translate text
                    </Trans>
                </Typography>
            )}
            <SelectSerialInput
                claimResource={apiResources.claimAppliance}
                dynamicFilter={allowHa ? filterToSelectedModel : undefined}
                filter={{series}}
                expandSectionButtonText="tesseract.appliances.dialog.serials.addAppliance"
                linkingCodeInputLabel="tesseract.appliances.dialog.serials.linkingCode"
                maxSelectable={allowHa ? 2 : 1}
                searchField="serial"
                source="serials"
                tableResource={apiResources.serials}
                isRequired
            >
                <TextField label="tesseract.appliances.dialog.serials.table.serial" source="serial" sortable/>
                <TextField label="tesseract.appliances.dialog.serials.table.model" source="model" sortable/>
                <TextField label="tesseract.appliances.dialog.serials.table.location" source="location.summary"/>
                <TextField label="tesseract.appliances.dialog.serials.table.orderId" source="orderId"/>
                <DateField label="tesseract.appliances.dialog.serials.table.orderDate" source="ordered" dateOnly/>
            </SelectSerialInput>
        </React.Fragment>
    );
};


export default SerialStep;