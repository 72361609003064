import React from "react";
import SdWanPolicies from "./tabs/SdWanPolicies";
import ExplicitPolicies from "./tabs/ExplicitPolicies";
import {Route, Switch} from "react-router";

const Sdwan = () => (
    <Switch>
        <Route path="/:account/:workspace/policies/sdwan/categories">
            <SdWanPolicies/>
        </Route>
        <Route path="/:account/:workspace/policies/sdwan/explicit">
            <ExplicitPolicies/>
        </Route>
    </Switch>
);

export default Sdwan;